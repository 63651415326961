import { CancelTokenSource } from "axios";
import axiosWithAuth from "./axiosWithAuth";
import { getCategoriesEndpoint, XHRheader } from "./endpoints";
// Sends Category Data Get Request to Spryker Backend
const getCategories = async (
  cancelTokenSource: CancelTokenSource,
  stockName?: string
) => {
  return axiosWithAuth()
    .get(`${getCategoriesEndpoint}?stock=${stockName || "all"}`, {
      headers: {
        "Accept-Language": XHRheader.AcceptLanguage,
      },
      cancelToken: cancelTokenSource.token,
    })
    .then((res) => {
      const {
        data: { data },
        status,
      } = res;

      return {
        data,
        status,
      };
    });
};

export default getCategories;
