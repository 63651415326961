import React from "react";
import { Card } from "antd";

interface Props {
  title: string;
  publishDate?: string;
  children: any;
  // data?: any;
}

let noContentPadding = "";

function ContentCard({ children, title, publishDate }: Props) {
  if (children[0].children === null) {
    noContentPadding = "card-no-content";
  }

  return (
    <Card
      className={noContentPadding}
      title={
        <div>
          <p className="content-card-title">{title}</p>{" "}
          <span className="publish-date">{publishDate}</span>
        </div>
      }
    >
      {children}
    </Card>
  );
}

export default ContentCard;
