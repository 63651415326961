import { useSelector } from "react-redux";
import { useMemo, useRef } from "react";
import { RootState } from "../types/rootState";
import findNestedKeyInObject from "../utils/findNestedKeyInObject";
import aggregateCategoriesArray from "../utils/aggregateCategoriesArray";

interface GetCategoryIdsProps {
  categoryKey: string;
}

const useGetCategoryIds = (props: GetCategoryIdsProps) => {
  const { categoryKey } = props;

  const { categories = [] } = useSelector(
    (state: RootState) => state?.categoryNavigation || {}
  );

  const categoriesCopyRef = useRef<any>(categories);

  const categoryIds: string = useMemo(() => {
    const category = findNestedKeyInObject(
      categoriesCopyRef?.current?.children || [],
      categoryKey,
      "categoryKey",
      "children"
    );

    if (!category) {
      return "";
    }

    const aggregatedCategories = aggregateCategoriesArray(
      category,
      categoriesCopyRef?.current?.children || [],
      []
    );

    if (!aggregatedCategories?.length) {
      return "";
    }

    return aggregatedCategories?.map((cat: any) => cat?.categoryKey)?.join(",");
  }, [categoryKey]);

  return {
    categoryIds,
  };
};

export default useGetCategoryIds;
