import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { getCheapCartsMetadataEndpoint } from "../endpoints";
import getCatalogSearchAttributesData from "../products/getCatalogSearchAttributesData";

/**
 * fetch all carts but with reduced content as a cheap endpoint
 * @param {CancelTokenSource} cancelTokenSource
 */
const getAllCartsCheapMetadata = (cancelTokenSource: CancelTokenSource) => {
  return axiosWithAuth()
    .get(getCheapCartsMetadataEndpoint, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response?.data?.data || response.status > 200) {
        return Promise.reject(response);
      }

      const attributes = getCatalogSearchAttributesData(response?.data?.data);

      return attributes?.quotes || [];
    });
};

export default getAllCartsCheapMetadata;
