import moment from "moment";
import { RootState } from "../types/rootState";

import { datePickerRange } from "../appConfig";
import { apiDateFormat } from "./dateFormats";
import { store } from "../store";

/**
 * get the last valid delivery date within the date picker range
 * @return {moment.Moment}
 */
const getLastValidDeliveryDate = () => {
  const unit = "days";
  const topBoundaryDate = moment().add(datePickerRange.maxDays, unit);
  const lastValidDeliveryDate =
    topBoundaryDate.isoWeekday() === 7
      ? topBoundaryDate.clone().subtract(1, unit)
      : topBoundaryDate.clone();

  return lastValidDeliveryDate;
};

/**
 * get a valid delivery date based on a desired delivery date.
 * If no date is provided it returns the next valid delivery date
 * @param {moment.Moment} date
 * @return {moment.Moment}
 */
const getValidDeliveryDate = (
  date: moment.Moment = moment()
): moment.Moment => {
  const today = moment();
  const unit = "days";
  const tomorrow = today.clone().add(1, unit);
  const nextValidDeliveryDate =
    tomorrow.isoWeekday() === 7
      ? tomorrow.clone().add(1, unit)
      : tomorrow.clone();
  const lastValidDeliveryDate = getLastValidDeliveryDate();
  const state: RootState = store.getState();
  const holidays = state?.userData?.businessUnit?.holidays || [];

  let formattedDate = date;

  // if sunday add a day to formattedDate
  if (formattedDate.isoWeekday() === 7) {
    formattedDate.add(1, unit);
  }

  // if formattedDate is before the next valid delivery date, the next valid delivery date needs to be used
  if (formattedDate.isBefore(nextValidDeliveryDate)) {
    formattedDate = nextValidDeliveryDate.clone();
  }

  // if formattedDate is after the last valid delivery date, the last valid delivery date needs to be used
  if (formattedDate.isAfter(lastValidDeliveryDate)) {
    formattedDate = lastValidDeliveryDate.clone();
  }

  // If formattedDate is a holiday => find next valid delivery date
  if (holidays.includes(formattedDate.format(apiDateFormat))) {
    return getValidDeliveryDate(formattedDate.clone().add(1, unit));
  }

  return formattedDate;
};

/**
 * function to check if this day is a Sunday and if so jump to the day before/after -> Monday/Sunday
 * @param {number} isoWeekDay
 * @param {string} operand
 */
const getWeekdayUpdate = (isoWeekDay: number, operand: "-" | "+") => {
  if (operand === "+") {
    return isoWeekDay === 6 ? 2 : 1;
  }
  return isoWeekDay === 1 ? 2 : 1;
};

/**
 * set disabled state for buttons
 * @param {string} direction
 * @param {string} date
 * @param {string} unit
 * @return {boolean}
 */
const isButtonDisabled = ({
  date,
  direction,
  unit = "days",
}: {
  date: string;
  direction: "prev" | "next";
  unit?: "days" | "months" | "year";
}) => {
  const today = moment();
  const currentCartDate = moment(date);
  const currentIsoWeekday = currentCartDate.isoWeekday();

  if (direction === "prev") {
    const nextValidDeliveryDate = getValidDeliveryDate();

    if (unit === "months") {
      return (
        currentCartDate.month() <= nextValidDeliveryDate.month() &&
        currentCartDate.year() <= nextValidDeliveryDate.year()
      );
    }

    if (unit === "year") {
      return currentCartDate.year() <= nextValidDeliveryDate.year();
    }

    const prevDate = currentCartDate.subtract(
      getWeekdayUpdate(currentIsoWeekday, "-"),
      unit
    );

    return prevDate.diff(today) <= 0;
  }

  if (direction === "next") {
    const lastValidDeliveryDate = getLastValidDeliveryDate();

    if (unit === "months") {
      return (
        currentCartDate.month() >= lastValidDeliveryDate.month() &&
        currentCartDate.year() >= lastValidDeliveryDate.year()
      );
    }

    if (unit === "year") {
      return currentCartDate.year() >= lastValidDeliveryDate.year();
    }

    const nextDate = currentCartDate.add(
      getWeekdayUpdate(currentIsoWeekday, "+"),
      unit
    );
    const isGreaterThanTopBoundary = currentCartDate.isAfter(
      lastValidDeliveryDate
    );

    return nextDate.diff(today) > 0 || isGreaterThanTopBoundary;
  }

  return false;
};

/**
 * handle datepicker update based direction
 * @param {string} direction
 * @param {string} date
 * @param {function} callback
 * @return {Promise<AxiosResponse>}
 */
const updateCurrentDate = ({
  date,
  direction,
  callback,
}: {
  date: string;
  direction: "prev" | "next";
  callback: (date: moment.Moment) => void;
}) => {
  const currentCartDate = moment(date);
  const currentIsoWeekday = currentCartDate.isoWeekday();
  let newDate: string;

  if (direction === "prev") {
    newDate = currentCartDate
      .subtract(getWeekdayUpdate(currentIsoWeekday, "-"), "days")
      .format(apiDateFormat);
  }

  if (direction === "next") {
    newDate = currentCartDate
      .add(getWeekdayUpdate(currentIsoWeekday, "+"), "days")
      .format(apiDateFormat);
  }

  callback(moment(newDate));
};

export default isButtonDisabled;
export { updateCurrentDate, getValidDeliveryDate };
