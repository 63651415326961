import React from "react";

import { useSelector } from "react-redux";
import { Col, Result, Row, Skeleton } from "antd";

import { ContentBlock } from "../../atoms";
import PodcastPlayer from "../PodcastPlayer/index";
import useGetPodcasts from "../../../hooks/useGetPodcasts";

import { RootState } from "../../../types/rootState";
import { Episode } from "../../../types/podcast";

import { routePathNames } from "../../../appConfig";

const PodcastWidget: React.FC = () => {
  const { consumer: consumerPodcast } = useSelector(
    (state: RootState) => state.podcasts
  );
  const isLoading = useGetPodcasts();

  return (
    <ContentBlock
      title="Podcasts"
      linkHref={routePathNames.podcast}
      showLink={false}
      className="podcast-widget"
    >
      <Row gutter={[10, 10]}>
        {isLoading && (
          <Col span={12}>
            <Skeleton active />
          </Col>
        )}

        {!isLoading &&
          !!consumerPodcast?.episodes?.length &&
          consumerPodcast?.episodes?.slice(0, 4).map((episode: Episode) => (
            <Col key={episode.mp3Link} xs={12} lg={6}>
              <PodcastPlayer podcast={consumerPodcast} episode={episode} />
            </Col>
          ))}

        {!isLoading && !consumerPodcast?.episodes?.length && (
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <Result
              status="warning"
              className="widget-error pt-sm"
              title="Es konnten keine Podcast Episoden geladen werden."
            />
          </Col>
        )}
      </Row>
    </ContentBlock>
  );
};

export default PodcastWidget;
