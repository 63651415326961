import React, { useMemo } from "react";

import clsx from "clsx";
import { Carousel, Col, Row } from "antd";
import { useSelector } from "react-redux";

import useGetWebshopTeaser from "../../../hooks/useGetWebshopTeaser";
import { ContentBlock, PictureTile, QuickLink } from "../../atoms";
import { ContentfulWebshopTeaserEntry } from "../../../types/contentfulWebshopTeaserEntry";
import { RootState } from "../../../types/rootState";
import Picture from "../../picture/Picture";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";

interface WebshopTopTeaserWidgetProps {
  className?: string;
}

const WebshopTopTeaserWidget: React.FC<WebshopTopTeaserWidgetProps> = (
  props: WebshopTopTeaserWidgetProps
) => {
  const { className } = props;

  const isSmallScreen = useMedia(
    `(max-width: ${getCssVariable("screen-md-max")})`
  );

  const { stockName } = useSelector((state: RootState) => state?.userData);

  const { isLoading, hasError, entries } = useGetWebshopTeaser(
    3,
    0,
    "Oben",
    stockName
  );

  const largeTeaser = useMemo(() => entries?.[0], [entries]);
  const smallTeasers: ContentfulWebshopTeaserEntry[] = useMemo(
    () => entries.slice(1, 3),
    [entries]
  );

  if (!largeTeaser && !smallTeasers.length) {
    return <></>;
  }

  return (
    <ContentBlock
      className={clsx("webshop-top-teaser-widget", className)}
      showHeader={false}
    >
      <Row gutter={[20, 10]}>
        {!isSmallScreen && !isLoading && !hasError && !!largeTeaser && (
          <Col xs={12} lg={smallTeasers && smallTeasers.length ? 8 : 12}>
            <PictureTile
              title={null}
              picture={
                <Picture
                  imageScreenXs={`${largeTeaser.imageMobileUrl}?fit=fill&f=top&r=10&fm=webp&q=40`}
                  imageScreenSm={`${largeTeaser.imageMobileUrl}?fit=fill&f=top&r=10&fm=webp&q=60`}
                  imageScreenMd={`${largeTeaser.imageMobileUrl}?fit=fill&f=top&r=10&fm=webp&q=80`}
                  imageScreenLg={`${largeTeaser.imageMobileUrl}?fit=fill&f=top&r=10&fm=webp`}
                  imageScreenXl={`${largeTeaser.imageDesktopUrl}?fit=fill&f=top&r=10&fm=webp`}
                />
              }
              link={
                <QuickLink
                  label={largeTeaser.callToAction || "Mehr erfahren"}
                  href={largeTeaser.link}
                  linkType="primary--outlined"
                />
              }
              className="webshop-top-teaser-widget__picture-tile--large"
            />
          </Col>
        )}

        {!isSmallScreen && !isLoading && !hasError && !!smallTeasers && (
          <Col xs={12} lg={4}>
            {smallTeasers.map(
              (teaser: ContentfulWebshopTeaserEntry, i: number) => (
                <PictureTile
                  key={`teaser_${i}`}
                  title={null}
                  picture={
                    <Picture
                      imageScreenSm={`${teaser.imageMobileUrl}?fit=fill&f=top&r=10&fm=webp&q=40`}
                      imageScreenXl={`${teaser.imageDesktopUrl}?fit=fill&f=top&r=10&fm=webp&q=80`}
                    />
                  }
                  link={
                    <QuickLink
                      label={teaser.callToAction || "Mehr erfahren"}
                      href={teaser.link}
                      linkType="primary--outlined"
                    />
                  }
                  className="webshop-top-teaser-widget__picture-tile--small"
                />
              )
            )}
          </Col>
        )}

        {isSmallScreen &&
          !isLoading &&
          !hasError &&
          !!entries &&
          entries.length && (
            <Col span={12}>
              <Carousel>
                {entries.map(
                  (teaser: ContentfulWebshopTeaserEntry, i: number) => (
                    <PictureTile
                      key={`teaser_${i}`}
                      title={null}
                      picture={
                        <Picture
                          imageScreenXs={`${teaser.imageMobileUrl}?fit=fill&f=top&r=10&fm=webp&q=40`}
                          imageScreenSm={`${teaser.imageDesktopUrl}?fit=fill&f=top&r=10&fm=webp&q=40`}
                        />
                      }
                      link={
                        <QuickLink
                          label={teaser.callToAction || "Mehr erfahren"}
                          href={teaser.link}
                          linkType="primary--outlined"
                        />
                      }
                      className="webshop-top-teaser-widget__picture-tile--small"
                    />
                  )
                )}
              </Carousel>
            </Col>
          )}
      </Row>
    </ContentBlock>
  );
};

export default WebshopTopTeaserWidget;
