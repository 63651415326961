import { AxiosResponse, CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import storeDispatch from "../../state/actions/storeDispatch";
import { updateProductOnPriceTagListEndpoint } from "../endpoints";
import { PriceTagListApi } from "../../types/userLists";

interface DeleteProductFromPriceTagList {
  cancelTokenSource: CancelTokenSource;
  sku: PriceTagListApi["sku"];
}

/**
 * Remove products from the priceTagList by SKUs
 * @return {Promise<AxiosResponse>}
 */
const deleteProductFromPriceTagList = ({
  cancelTokenSource,
  sku,
}: DeleteProductFromPriceTagList): Promise<AxiosResponse> =>
  axiosWithAuth()
    .delete(`${updateProductOnPriceTagListEndpoint}/multi`, {
      data: {
        data: {
          type: "tags",
          attributes: {
            skuList: [...sku],
          },
        },
      },
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response || response.status !== 204) {
        return Promise.reject(response);
      }

      const didDeleteAll = sku?.length === 1 && sku?.[0] === "*";

      if (didDeleteAll) {
        storeDispatch("priceTagList/reset-price-tag-list", {});
      } else {
        storeDispatch("priceTagList/remove-sku", sku);
        storeDispatch("priceTagList/delete-selected-priceTag-item", sku?.[0]);
      }

      return response;
    });

export default deleteProductFromPriceTagList;
