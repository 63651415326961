import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { ReactComponent as Arrow } from "../../../../static/svg/arrow.svg";
import DynamicFilter from "../../../molecules/DynamicFilter";

import { RootState } from "../../../../types/rootState";
import { Filter, Slot } from "../../../../types/filters";

interface FilterSlotProps {
  slot: Slot;
  filters: Filter[];
  columnsToShow?: number;
  display?: "default" | "sidebar" | "weekplanner";
}

const FilterSlot: React.FC<FilterSlotProps> = (props) => {
  const { slot, filters, columnsToShow = 3, display = "default" } = props;

  const [isExpanded, setIsExpanded] = useState(display !== "default");

  const filterStates = useSelector(
    (state: RootState) => state.productsMetaData.filters.filterStates
  );

  const availableFilters = useMemo(() => {
    return filters.sort((a, b) => {
      const aActive = !!filterStates[a.name];
      const bActive = !!filterStates[b.name];

      if ((aActive && bActive) || (!aActive && !bActive)) {
        return a.config.priority > b.config.priority ? -1 : 1;
      }

      return bActive ? 1 : -1;
    });
  }, [filters, filterStates]);

  const displayedFilters = useMemo(() => {
    if (availableFilters.length === 0) {
      return [];
    }
    return availableFilters.filter((_, index) =>
      isExpanded === true ? true : index < columnsToShow
    );
  }, [columnsToShow, availableFilters, isExpanded]);

  if (display === "sidebar") {
    return (
      <div className={clsx("filter-slot--sidebar")}>
        <div className={clsx("filter-slot__title", "text-bold", "text-base")}>
          <span>{slot.captionOverride ?? slot.caption}</span>
          <button
            className={clsx("filter-slot__show-more-button")}
            type="button"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Arrow
              style={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        </div>
        <div className={clsx("filter-slot__filters")}>
          {displayedFilters.map((singleFilter, index) => (
            <div key={index} className={clsx("filter-slot__filter")}>
              <DynamicFilter {...singleFilter} display="sidebar" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (display === "weekplanner") {
    return (
      <div className={clsx("filter-slot--weekplanner")}>
        <div className={clsx("filter-slot__title", "text-bold", "text-base")}>
          <span>{slot.captionOverride ?? slot.caption}</span>
        </div>
        <div className={clsx("filter-slot__filters")}>
          {displayedFilters.map((singleFilter, index) => (
            <div key={index} className={clsx("filter-slot__filter")}>
              <DynamicFilter
                {...singleFilter}
                display="sidebar"
                view="weekplanner"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={clsx("filter-slot")}>
      <div className={clsx("filter-slot__title", "text-bold", "text-base")}>
        {slot.captionOverride ?? slot.caption}
      </div>
      <div
        className={clsx(
          "filter-slot__filters",
          `filter-slot__filters--${columnsToShow}-columns`
        )}
      >
        {displayedFilters.map((singleFilter, index) => (
          <div key={index} className={clsx("filter-slot__filter")}>
            <DynamicFilter {...singleFilter} />
          </div>
        ))}
      </div>

      {filters.length > columnsToShow && (
        <div className={clsx("filter-slot__show-more-container")}>
          <button
            className={clsx("filter-slot__show-more-button")}
            type="button"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded
              ? "weniger Filter"
              : `weitere Filter "${slot?.caption ?? ""}"`}
            <Arrow
              style={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default FilterSlot;
