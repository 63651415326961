import React from "react";
import clsx from "clsx";

interface LinkedThumbnailProps {
  href: string;
  thumbnailSrc: string;
  linkText: string;
  className?: string;
  buttonClassName?: string;
  altText?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const LinkedThumbnail: React.FC<LinkedThumbnailProps> = (props) => {
  const {
    href,
    thumbnailSrc,
    linkText,
    className,
    buttonClassName,
    altText,
    target = "_blank",
  } = props;
  return (
    <div className={clsx("linked-thumbnail", className)}>
      <a
        target={target}
        href={href}
        className={clsx("linked-thumbnail__thumbnail")}
      >
        <img alt={altText} src={thumbnailSrc} />
      </a>
      <a
        href={href}
        target={target}
        role="button"
        className={clsx("linked-thumbnail__button", buttonClassName)}
        rel="noreferrer noopener"
      >
        {linkText}
      </a>
    </div>
  );
};

export default LinkedThumbnail;
