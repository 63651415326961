import React from "react";

import clsx from "clsx";
import moment from "moment";
import { Tooltip } from "antd";

import { fullDateFormat } from "../../utils/dateFormats";

interface ProductAlreadyOrderedBatchProps {
  count: number;
  className?: string;
  deliveryDate?: string;
}

const CANCELLED = "Artikel wurde genullt.";
const ALREADY_ORDERED = "Diese Menge ist bereits bestellt.";
const ALREADY_ORDERED_DATE = "Diese Menge ist bereits für den %date% bestellt.";

const ProductAlreadyOrderedBatch: React.FC<ProductAlreadyOrderedBatchProps> = (
  props: ProductAlreadyOrderedBatchProps
) => {
  const { count, className, deliveryDate } = props;

  const title =
    // eslint-disable-next-line no-nested-ternary
    count === 0
      ? CANCELLED
      : !deliveryDate
      ? ALREADY_ORDERED
      : ALREADY_ORDERED_DATE.replace(
          "%date%",
          moment(deliveryDate).format(fullDateFormat)
        );

  if (!Number.isFinite(count) || count < 0) {
    return null;
  }

  return (
    <Tooltip title={title} trigger={["hover", "click"]} destroyTooltipOnHide>
      <div
        className={clsx(
          "badge-already-ordered",
          "color-white bgColor-secondary borderColor-secondary",
          className
        )}
      >
        {count}
      </div>
    </Tooltip>
  );
};

export default ProductAlreadyOrderedBatch;
