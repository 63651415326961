/**
 * helper to aggregate delivery date query strings for product requests
 * pass an Array if the request String should also be an Array
 * pass a String if the request String should be only a String
 * @param dates {String | Array<String>}
 * @param firstQueryParameter {boolean}
 * @param isDateRange {boolean}
 * @returns {string}
 */
const getDeliveryDateQuery = (
  dates: string | string[],
  firstQueryParameter = false,
  isDateRange = false
) => {
  let deliveryDateAppendix = "";

  if (Array.isArray(dates) && dates?.length && isDateRange) {
    // To use in Filter Orders by deliveryDate
    // RangePicker needs [undefinded, undefinded] as default value. So we can't check on length anymore.
    deliveryDateAppendix = `&deliveryDateTo=${dates[0]}&deliveryDateFrom=${
      dates[dates.length - 1]
    }`;
  } else if (Array.isArray(dates)) {
    // prevent undefined query request parameter
    dates.forEach((date: string) => {
      deliveryDateAppendix += `&deliveryDate[]=${date}`;
    });
  } else {
    deliveryDateAppendix = `&deliveryDate=${dates}`;
  }

  // replace the first ampersand with a question mark for correct request
  if (firstQueryParameter) {
    return deliveryDateAppendix.replace("&", "?");
  }

  return deliveryDateAppendix;
};

export default getDeliveryDateQuery;
