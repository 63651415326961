import React from "react";
import { Modal } from "antd";
import { ModalType } from "../../types/modal";
import YoutubeVideo from "../video/YoutubeVideo";
import Picture from "../picture/Picture";

interface Props {
  alt: string;
  isVisible: boolean;
  onCancel: () => void;
  type?: ModalType;
  url?: string;
}

/**
 * modal wrapper for image and video
 * @param alt {string}
 * @param isVisible {boolean}
 * @param onCancel {Function}
 * @param type {string}
 * @param url {string}
 * @constructor
 */
const MediaModal: React.FC<Props> = ({
  alt,
  isVisible,
  onCancel,
  type = "image",
  url,
}: Props) => (
  <Modal
    title={null}
    visible={isVisible}
    footer={null}
    closable
    onCancel={onCancel}
    width={1000}
  >
    {type === "video" ? (
      <YoutubeVideo title={alt} url={url} />
    ) : (
      <Picture alt={alt} imageScreenSm={url} />
    )}
  </Modal>
);

export default MediaModal;
