import { CancelTokenSource } from "axios";
import axiosWithAuth from "./axiosWithAuth";
import { switchBusinessUnitEndpoint } from "./endpoints";

/**
 * switch between Business Units
 * @param data {string}
 * @param cancelTokenSource {CancelTokenSource}
 */
const postSwitchBusinessUnit = async (
  uuid: string,
  cancelTokenSource: CancelTokenSource
) => {
  const requestBody = {
    data: {
      type: "company-user-switch",
      attributes: {
        uuid,
      },
    },
  };

  return axiosWithAuth().post(switchBusinessUnitEndpoint, requestBody, {
    cancelToken: cancelTokenSource.token,
  });
};

export default postSwitchBusinessUnit;
