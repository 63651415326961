import { Entry } from "contentful";
import moment from "moment/moment";

import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import { ContentfulImpulsaktionEntry } from "../../types/contentfulImpulsaktion";
import { apiDateFormat } from "../../utils/dateFormats";

interface ImpulsaktionenParams {
  limit: number;
  skip: number;
  withTotal?: boolean;
}

interface ImpulsaktionenResponse {
  items: Entry<ContentfulImpulsaktionEntry>[];
  total: number;
}

/**
 * @param limit
 * @param skip
 * @param withTotal
 *
 * @return Promise<any>
 */
const getImpulsaktionen = async ({
  limit,
  skip,
  withTotal = true,
}: ImpulsaktionenParams): Promise<ImpulsaktionenResponse> =>
  getContentfulContentType({
    ...contentfulContentTypes.impulsaktion,
    limit,
    skip,
    order: "fields.start_date",
    inclusion: {
      "fields.start_date[gte]": moment().startOf("month").format(apiDateFormat),
      include: "3",
    },
    withTotal,
  }).then((response: any) => {
    if (!response) {
      return Promise.reject(response);
    }

    return {
      items: response.items || [],
      total: response.total,
    } as ImpulsaktionenResponse;
  });

export default getImpulsaktionen;
