import axiosWithAuth from "../axiosWithAuth";
import { favouriteListEndpoint } from "../endpoints";
import { DeleteFavouriteList } from "../../types/favouriteList";

/**
 * delete a favourite list
 * @param {CancelTokenSource} cancelTokenSource
 * @param {string} listName
 * @return {Promise<AxiosResponse>}
 */
const deleteFavouriteList = ({
  cancelTokenSource,
  listName = "default",
}: DeleteFavouriteList) =>
  axiosWithAuth()
    .delete(`${favouriteListEndpoint}${listName}`, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response || response.status !== 204) {
        return Promise.reject(response);
      }

      return response;
    });

export default deleteFavouriteList;
