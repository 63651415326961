import { useEffect, useState } from "react";

import axios from "axios";

import getCart from "../api/cart/getCart";
import getCancelTokenSource from "../api/getCancelTokenSource";
import requestCatchHandler from "../api/requestCatchHandler";

import { UpdateCartData } from "../types/cartAPI";

const useGetCart = (deliveryDate: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [cart, setCart] = useState(null);

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();
    const cartData: UpdateCartData = { cancelTokenSource, deliveryDate };

    setIsLoading(true);

    getCart(cartData)
      .then((response) => {
        if (response.data) {
          setCart(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
        }
        requestCatchHandler(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [deliveryDate]);

  return {
    isLoading,
    hasError,
    cart,
  };
};

export default useGetCart;
