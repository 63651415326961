import { useMemo } from "react";

import getDeliveryDateBasedAttributes from "../components/product/getDeliveryDateBasedAttributes";
import { ProductAlternatives, ProductData } from "../types/productData";

interface UseHasDeliveryDateProductAlternativeProps {
  deliveryDate: string;
  productData: ProductData;
}

const useHasDeliveryDateProductAlternative = (
  props: UseHasDeliveryDateProductAlternativeProps
) => {
  const { deliveryDate, productData } = props;

  const { availabilities, prices, productAlternatives } = productData;

  const { isUnavailable, dailyProductAlternatives } = useMemo(
    () =>
      getDeliveryDateBasedAttributes({
        deliveryDate,
        prices,
        availabilities,
        productAlternatives,
      }),
    [deliveryDate, prices, availabilities, productAlternatives]
  );

  const hasDeliveryDateProductAlternative = useMemo(
    () =>
      !!dailyProductAlternatives &&
      productData?.productAlternatives?.filter(
        (productAlternative: ProductAlternatives) =>
          productAlternative?.deliveryDate === deliveryDate &&
          productAlternative?.productAlternatives?.length > 0 &&
          productAlternative?.productAlternatives.some(
            (productAlternativeData: any) =>
              productAlternativeData?.quantity > 0 ||
              productAlternativeData?.isNeverOutOfStock === true
          )
      )?.length > 0,
    [deliveryDate, dailyProductAlternatives, productData?.productAlternatives]
  );

  return {
    hasDeliveryDateProductAlternative,
    isUnavailable,
  };
};

export default useHasDeliveryDateProductAlternative;
