import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { stocktakingItemEndpoint } from "../endpoints";
import { StocktakingItemData } from "../../types/stocktaking";

interface PatchStocktakingItemProps {
  stocktakingItemId: number;
  label?: string;
  quantity: number;
  unitPrice?: number;
  taxRate?: number;
  producer?: string;
  sku?: string;
  cancelTokenSource: CancelTokenSource;
}

const patchStocktakingItem = async (props: PatchStocktakingItemProps) => {
  const {
    stocktakingItemId,
    label,
    quantity,
    unitPrice,
    taxRate,
    producer,
    sku,
    cancelTokenSource,
  } = props;

  const url = `${stocktakingItemEndpoint}/${stocktakingItemId}`;

  return axiosWithAuth()
    .patch(
      url,
      {
        data: {
          type: "stocktaking-item",
          attributes: {
            label,
            quantity,
            unitPrice,
            taxRate,
            producer,
            sku,
          },
        },
      },
      { cancelToken: cancelTokenSource.token }
    )
    .then((response): Promise<any> | StocktakingItemData => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data || {};

      return attributes as StocktakingItemData;
    });
};

export default patchStocktakingItem;
