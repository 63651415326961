import { Input, InputRef, Spin, Tooltip } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as Edit } from "../../static/svg/edit.svg";

interface EditableCaptionProps {
  caption: string;
  onSaveCaption: (value: string) => void;
  isUpdating?: boolean;
}

/**
 * editable caption for any pages
 * @param {string} caption
 * @param {function} onSaveCaption
 * @param {boolean} isUpdating
 * @constructor
 */
const EditableCaption = function EditableCaption({
  caption,
  onSaveCaption,
  isUpdating,
}: EditableCaptionProps) {
  const captionInputContainerRef = useRef<HTMLDivElement>(null);
  const captionInputRef = useRef<InputRef>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isEditModeEnabled, setIsEditModeEnabled] = useState<boolean>(false);

  /**
   * triggered when clicked the save button
   */
  const onPressSave = () => {
    if (inputValue) {
      onSaveCaption(inputValue);
    }
  };

  /**
   * triggered when clicked outside of the FoldoutMenu component
   * @param {any} event
   */
  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        captionInputContainerRef.current &&
        !captionInputContainerRef.current.contains(event.target)
      ) {
        setIsEditModeEnabled(false);
        setInputValue(caption);
      }
    },
    [caption]
  );

  useEffect(() => {
    setInputValue(caption);
  }, [caption]);

  useEffect(() => {
    // Bind the event listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      // Unbind the event listeners on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [captionInputContainerRef, handleClickOutside]);

  useEffect(() => {
    if (isEditModeEnabled) {
      captionInputRef?.current?.focus();
    }
  }, [isEditModeEnabled]);

  useEffect(() => {
    if (!isUpdating) {
      setIsEditModeEnabled(false);
    }
  }, [isUpdating]);

  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const textRef = useRef<any>();

  useEffect(() => {
    const element = textRef.current;

    setIsEllipsisActive(
      element
        ? element.offsetWidth < element.scrollWidth ||
            element.offsetHeight < element.scrollHeight
        : false
    );
  }, [caption]);

  return (
    <div className="editableCaption">
      {!isEditModeEnabled ? (
        <div className="captionWrapper">
          <Tooltip title={isEllipsisActive ? caption : ""}>
            <h1 className="caption" ref={textRef}>
              {caption}
            </h1>
          </Tooltip>
          <button
            className="button editButton"
            type="button"
            onClick={() => setIsEditModeEnabled(true)}
          >
            <Edit className="icon editIcon" />
          </button>
        </div>
      ) : (
        <div className="captionInput width-full" ref={captionInputContainerRef}>
          <Input
            size="large"
            placeholder="Name Favoritenliste"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            ref={captionInputRef}
            onPressEnter={onPressSave}
            onFocus={() => setInputValue(caption)}
          />
          <button
            type="button"
            className="button buttonPrimary"
            disabled={isUpdating || !inputValue}
            onClick={onPressSave}
          >
            <Spin spinning={isUpdating}>Übernehmen</Spin>
          </button>
        </div>
      )}
    </div>
  );
};

export default EditableCaption;
