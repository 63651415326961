import React, { useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";
import queryString from "query-string";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  message,
  RadioChangeEvent,
  Result,
  Row,
} from "antd";

import axios, { CancelTokenSource } from "axios";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import BackButton from "../../backButton/BackButton";
import OrderListHeader from "../../atoms/OrderListHeader";
import useGetOrders from "../../../hooks/useGetOrders";
import useUpdateUrlFragments from "../../../hooks/useUpdateUrlFragments";
import { ContentBlock, SearchFilter } from "../../atoms";
import {
  itemsPerPage,
  locationSearchQueryParameter,
  messageData,
  pageTitles,
  pageTitleSeparator,
  pageTitleSuffix,
} from "../../../appConfig";
import { apiDateFormat, fullDateFormat } from "../../../utils/dateFormats";
import OrdersTable from "./OrdersTable";
import { DocumentType, OnlyDocumentTypeAsKey } from "../../../types/orderAPI";
import { ReactComponent as Search } from "../../../static/svg/search.svg";
import ReorderModal from "../../order/OrderDetail/ReorderModal";
import { HistoryProductData } from "../../../types/HistoryProductData";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import getOrderOverviewDocument from "../../../api/order/getOrderOverviewDocument";
import requestCatchHandler from "../../../api/requestCatchHandler";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";

interface OrdersProps {
  className?: string;
}

const documentTypeMapping: OnlyDocumentTypeAsKey = {
  SHOPORDER: "Shopbestellungen",
  ORDER: "Aufträge",
  INVOICE: "Rechnungen",
};

const Orders: React.FC<OrdersProps> = (props: OrdersProps) => {
  const { className } = props;

  const { search } = useLocation();
  const parsedSearch = useMemo(() => queryString.parse(search), [search]);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const {
    [locationSearchQueryParameter.deliveryDateFrom]: deliveryDateFromQuery,
    [locationSearchQueryParameter.deliveryDateTo]: deliveryDateToQuery,
    [locationSearchQueryParameter.page]: pageQuery,
    [locationSearchQueryParameter.invoiceNumber]: invoiceNumberQuery,
    [locationSearchQueryParameter.documentType]: documentTypeQuery,
    [locationSearchQueryParameter.cartNote]: cartNoteQuery,
  } = parsedSearch;

  const defaultFromDate = moment().subtract(14, "days").format(apiDateFormat);
  const defaultToDate = moment().add(14, "days").format(apiDateFormat);

  const [deliveryDateFrom, setDeliveryDateFrom] = useState<string>(
    (deliveryDateFromQuery as string) || defaultFromDate
  );
  const [deliveryDateTo, setDeliveryDateTo] = useState<string>(
    (deliveryDateToQuery as string) || defaultToDate
  );
  const [page, setPage] = useState<number>(Number(pageQuery) || 1);
  const [invoiceNumber, setInvoiceNumber] = useState<any>(
    invoiceNumberQuery || ""
  );
  const [documentType, setDocumentType] = useState<DocumentType>(
    (documentTypeQuery as DocumentType) || "ORDER"
  );
  const [cartNote, setCartNote] = useState<string>(
    (cartNoteQuery as string) || ""
  );
  const [sorting, setSorting] = useState("-deliveryDate");

  const [
    isOrderOverviewDocumentDownloading,
    setIsOrderOverviewDocumentDownloading,
  ] = useState<boolean>(false);

  const isInvoiceView: boolean = useMemo(
    () => documentType === "INVOICE",
    [documentType]
  );

  const { isLoading, orders, total, hasError, setTotal, setOrders } =
    useGetOrders({
      page,
      limit: itemsPerPage.orderList,
      sorting,
      deliveryDateFrom,
      deliveryDateTo,
      documentType: documentType === "SHOPORDER" ? "ORDER" : documentType,
      invoiceNumber,
      cartNote,
      orderType: documentType === "SHOPORDER" ? "SHOP" : null,
    });
  const setUpdateUrlFragments = useUpdateUrlFragments();

  const { RangePicker } = DatePicker;

  /**
   * Handler that sets default values after changing document type
   *
   * @param event {RadioChangeEvent}
   */
  const onOrderListHeaderChange = (event: RadioChangeEvent) => {
    setTotal(0);
    setOrders([]);
    setDocumentType(event.target.value);
    setPage(1);
    setInvoiceNumber("");
    setCartNote("");
    setUpdateUrlFragments({
      context: "orders",
      parameters: {
        deliveryDateFrom: deliveryDateFrom as string,
        deliveryDateTo: deliveryDateTo as string,
        documentType: event.target.value,
      },
    });
  };

  /**
   * Handler that changes date URL params on date picker value change
   *
   * @param values {moment.Moment[]}
   */
  const onRangePickerChange = (values: moment.Moment[]) => {
    if (values?.length) {
      setDeliveryDateFrom(values[0].format(apiDateFormat));
      setDeliveryDateTo(values[1].format(apiDateFormat));
    }
  };

  const onRangePickerOpenChange = (open: any) => {
    if (!open) {
      setUpdateUrlFragments({
        context: "orders",
        parameters: {
          deliveryDateFrom: deliveryDateFrom as string,
          deliveryDateTo: deliveryDateTo as string,
          documentType,
          ...(isInvoiceView ? { invoiceNumber } : {}),
          ...(!isInvoiceView ? { cartNote } : {}),
        },
      });
    }
  };

  /**
   * Handler that changes state vars on table change
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    const { order, field } = sorter;

    if (order) {
      const dir = order === "descend" ? "-" : "";
      setSorting(`${dir}${field}`);
    }

    if (pagination.current) {
      setPage(pagination.current);
      setUpdateUrlFragments({
        context: "pagination",
        parameters: {
          page: pagination.current,
        },
      });
    }
  };

  // Change delivery date from state on url param change
  useEffect(() => {
    setDeliveryDateFrom((deliveryDateFromQuery as string) || defaultFromDate);
  }, [deliveryDateFromQuery, defaultFromDate]);

  // Change delivery date to state on url param change
  useEffect(() => {
    setDeliveryDateTo((deliveryDateToQuery as string) || defaultToDate);
  }, [deliveryDateToQuery, defaultToDate]);

  // Change invoice number state on url param change
  useEffect(() => {
    setInvoiceNumber(invoiceNumberQuery);
  }, [invoiceNumberQuery]);

  // Change document type state on url param change
  useEffect(() => {
    setDocumentType((documentTypeQuery as DocumentType) || "ORDER");
  }, [documentTypeQuery]);

  // Change cart note state on url param change
  useEffect(() => {
    setCartNote((cartNoteQuery as string) || "");
  }, [cartNoteQuery]);

  // Modal sichtbar machen
  const toggleReorderModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const historyProducts: HistoryProductData[] = useMemo(() => {
    return selectedRowKeys.map((sku: string) => {
      return { sku, quantity: 1 };
    });
  }, [selectedRowKeys]);

  const updateSelectedSkus = (rows: Record<any, any>[], selected: boolean) => {
    // get the keys of all rows, use SKU for later reuseability of list
    const interactedRowKeys = rows?.map((row) => row.sku);
    /*
     * If the row was selected, it is save to assume, that the keys should be added.
     * Make use of Set(), where every entry is automatically unique.
     * Make sure, the iterator of Set is parsed back to an array to assure type safety.
     * Otherwise filter all selected keys from the current selected array of strings and update with the delta.
     */
    if (selected) {
      const newRowKeySet = new Set([...selectedRowKeys, ...interactedRowKeys]);
      setSelectedRowKeys(Array.from(newRowKeySet));
    } else {
      const deltaRowKeys = selectedRowKeys.filter(
        (rowKey) => !interactedRowKeys.includes(rowKey)
      );

      setSelectedRowKeys(deltaRowKeys);
    }
  };

  const orderOverviewDocumentDownloadCancelTokenSourceRef =
    useRef<CancelTokenSource>(getCancelTokenSource());

  useCancelAxiosOnUnmount(
    orderOverviewDocumentDownloadCancelTokenSourceRef.current
  );

  const handleOrderOverviewDocumentDownload = () => {
    orderOverviewDocumentDownloadCancelTokenSourceRef.current =
      cancelAndRenewCancelToken(
        orderOverviewDocumentDownloadCancelTokenSourceRef.current
      );
    setIsOrderOverviewDocumentDownloading(true);
    getOrderOverviewDocument({
      cancelTokenSource:
        orderOverviewDocumentDownloadCancelTokenSourceRef.current,
    })
      .then(() => {
        message.success(
          messageData.success.orders.overviewDocumentDownloadSuccess
        );
        setIsOrderOverviewDocumentDownloading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error(messageData.error.orders.overviewDocumentDownloadError);
          setIsOrderOverviewDocumentDownloading(false);
        }
        requestCatchHandler(error);
      });
  };

  return (
    <>
      <TrackingHelmet
        title={`${documentTypeMapping[documentType]} ${pageTitleSeparator} ${pageTitles.orders}`}
        suffix={pageTitleSuffix}
      />

      <ReorderModal
        productItems={historyProducts}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        modalQuestion="Möchten Sie diese Artikel dem Warenkorb hinzufügen?"
      />

      <ShopCategoryNavigation />

      <ContentBlock
        showHeader={false}
        className={clsx("orders-page", className)}
      >
        <BackButton />
        <OrderListHeader
          type={documentType}
          onChange={onOrderListHeaderChange}
        />
        {/* Header */}
        <Row
          className="orders-page__header"
          justify="space-between"
          gutter={10}
        >
          <Col xs={12} md={6} className="flex flex-row">
            <h2 className="orders-page__header__title">
              {documentTypeMapping[documentType]}
            </h2>
            {total > 0 && (
              <span className="orders-page__header__num-results">
                {total} Gelistet
              </span>
            )}
          </Col>

          <Col xs={12} md={6} className="orders-page__header__inputs">
            {isInvoiceView && (
              <SearchFilter
                placeholder="Rechnungsnr. suchen"
                className="orders-page__header__inputs__invoice-search"
                searchFilterName={locationSearchQueryParameter.invoiceNumber}
                searchName={null}
                resetPagination
                onSearchBeforeNavigate={() => {
                  setPage(1);
                }}
                addonAfter={<Search />}
              />
            )}

            {!isInvoiceView && (
              <SearchFilter
                placeholder="Notiz suchen"
                className="orders-page__header__inputs__note-search"
                searchFilterName={locationSearchQueryParameter.cartNote}
                searchName={null}
                resetPagination
                onSearchBeforeNavigate={() => {
                  setPage(1);
                }}
                addonAfter={<Search />}
              />
            )}

            <Button
              className="cartButton"
              onClick={toggleReorderModal}
              disabled={!selectedRowKeys?.length}
            >
              markierte Artikel in Warenkorb
            </Button>

            <RangePicker
              className="datepicker"
              size="small"
              onChange={onRangePickerChange}
              onOpenChange={onRangePickerOpenChange}
              placeholder={["Datum Start", "Datum Ende"]}
              format={fullDateFormat}
              value={[moment(deliveryDateFrom), moment(deliveryDateTo)]}
            />
          </Col>

          {!isInvoiceView && (
            <Col xs={12}>
              <Button
                className="button buttonPrimary sm-with-full download-button"
                type="text"
                onClick={handleOrderOverviewDocumentDownload}
                loading={isOrderOverviewDocumentDownloading}
              >
                Vormerkungen PDF
              </Button>
            </Col>
          )}
        </Row>

        {/* Table */}
        <Row gutter={10}>
          <Col xs={12}>
            {!hasError && (
              <OrdersTable
                orders={orders}
                total={total}
                page={page}
                isLoading={isLoading}
                documentType={documentType}
                emptyDescription={
                  <span>
                    Keine {documentTypeMapping[documentType]} vorhanden
                  </span>
                }
                onTableChange={onTableChange}
                updateSelectedRows={updateSelectedSkus}
              />
            )}

            {!isLoading && !!hasError && (
              <Result
                status="warning"
                className="mb-3xl"
                title={`Deine ${documentTypeMapping[documentType]} konnten nicht abgerufen werden.`}
              />
            )}
          </Col>
        </Row>
        <Button type="primary" danger />
      </ContentBlock>
    </>
  );
};

export default Orders;
