import React from "react";
import { Layout, Row, Col, Result } from "antd";

function NotAllowed() {
  return (
    <Layout className="container-layout container-layout--inner">
      <Row>
        <Col span={12}>
          <Result title="Sie haben nicht die nötigen Rechte, diese Seite zu sehen." />
        </Col>
      </Row>
    </Layout>
  );
}

export default NotAllowed;
