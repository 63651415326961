import React from "react";

import { Result } from "antd";
import clsx from "clsx";

interface WeekplannerUnsupportedDeviceProps {
  className?: string;
}

const WeekplannerUnsupportedDevice: React.FC<
  WeekplannerUnsupportedDeviceProps
> = (props: WeekplannerUnsupportedDeviceProps) => {
  const { className } = props;

  return (
    <div className={clsx("weekplanner-unsupported-device", className)}>
      <Result title="Der Wochenplaner ist nur für größere Browserbreiten verfügbar. Nutzen Sie ein Tablet und halten Sie das Tablet im Quer-Format (Landscape)." />
    </div>
  );
};

export default WeekplannerUnsupportedDevice;
