import React, { BaseSyntheticEvent, forwardRef } from "react";

import clsx from "clsx";

import useGetContactPerson from "../../../../hooks/useGetContactPerson";
import { ContentBlock, QuickLink } from "../../../atoms";
import Picture from "../../../picture/Picture";
import SupportClientIconImage from "../../../../static/images/support-client-icon.png";

interface SupportClientProps {
  className?: string;
}

type Ref = HTMLDivElement;

const CLIENT_FILE =
  "rustdesk-host=rustdesk.weiling.de,key=doZPlr65u6xs0qrdrqKZR5yDJN6YZsKDSJcAxyDLjlg=.exe";

const SupportClient: React.FC<SupportClientProps> = forwardRef<
  Ref,
  SupportClientProps
>((props, ref) => {
  const { className } = props;

  const contactPerson = useGetContactPerson();

  return (
    <div ref={ref} className={clsx("support-client", className)}>
      <ContentBlock
        title="RustDesk-Support"
        icon={<Picture imageScreenSm={SupportClientIconImage} />}
      >
        <p>
          Damit wir Ihnen schnell und unkompliziert helfen können, bieten wir
          Ihnen den Support per RustDesk an. Sollten Sie Fragen dazu haben,
          kontaktieren Sie bitte Ihre Kundenbetreuung. Über den nachfolgenden
          Download Button können Sie sich die RustDesk Software ganz bequem
          herunterladen.
        </p>
        <p>
          {!!contactPerson?.email && (
            <QuickLink
              label="Kundenbetreuer*in kontaktieren"
              href={`mailto:${contactPerson.email}`}
              linkType="primary--outlined"
            />
          )}
          <QuickLink
            label="RustDesk downloaden"
            href={`${process.env.PUBLIC_URL}/${CLIENT_FILE}`}
            linkType="primary--outlined"
            onClick={(e: BaseSyntheticEvent) => {
              e.preventDefault();
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/${CLIENT_FILE}`;
              link.setAttribute("download", CLIENT_FILE);
              link.setAttribute("target", "_self");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          />
        </p>
      </ContentBlock>
    </div>
  );
});

export default SupportClient;
