import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import postAddFavouriteList from "../../api/favouritesList/postAddFavouriteList";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import requestCatchHandler from "../../api/requestCatchHandler";
import { messageData } from "../../appConfig";
import setFavouriteLists from "../../state/actions/setFavouriteLists";
import { FavouriteListWithNumberId } from "../../types/favouriteList";
import InputWithButton from "../inputWithButton/InputWithButton";

interface AddFavouriteListInputProps {
  onFinish: (favouriteList: FavouriteListWithNumberId) => void;
  focused?: boolean;
}

/**
 * Input Component to add a new favourite list
 * @param {(favouriteList: FavouriteListWithNumberId) => void} onFinish
 * @param {boolean} focused
 * @constructor
 */
const AddFavouriteListInput: React.FC<AddFavouriteListInputProps> =
  function AddFavouriteListInput({
    onFinish,
    focused,
  }: AddFavouriteListInputProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");

    // message content
    const {
      error: { unexpected: unexpectedMsg },
      success: {
        favouriteList: { addedLists: addedListMsg },
      },
    } = messageData;

    /**
     * create a new favourite list with a caption
     * @param {string} caption
     */
    const createFavouriteList = (caption: string) => {
      setIsLoading(true);
      const cancelTokenSource = getCancelTokenSource();

      postAddFavouriteList({
        cancelTokenSource,
        caption,
        notes: null,
      })
        .then((axiosResponse) =>
          setFavouriteLists(cancelTokenSource).then(() => axiosResponse)
        )
        .then((axiosResponse) => {
          onFinish(axiosResponse.data?.data?.attributes);
          message.success(addedListMsg);
          setValue("");
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);

          if (!axios.isCancel(error)) {
            message.error(unexpectedMsg);
          }

          requestCatchHandler(error);
        });
    };

    useEffect(() => {
      return () => {
        setIsLoading(false);
      };
    }, []);

    return (
      <InputWithButton
        onSubmitValue={createFavouriteList}
        buttonContent="Liste erstellen"
        inputPlaceholder="Name Favoritenliste"
        title="Neue Favoritenliste"
        isLoading={isLoading}
        focused={focused}
        value={value}
        onValueChange={(event) => setValue(event.target.value)}
      />
    );
  };

export default AddFavouriteListInput;
