import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { ProductData } from "../../../types/productData";
import { ReactComponent as Move } from "../../../static/svg/move.svg";
import CartReorderModalWrapper from "./CartReorderModalWrapper";

interface Props {
  sku: ProductData["sku"];
  withoutText?: boolean;
}

/**
 * move a single item to another cart
 * @param {string} sku
 * @param {boolean} withoutText
 * @constructor
 */
const MoveSingleItemToDateButton: React.FC<Props> =
  function MoveSingleItemToDateButton({ sku, withoutText }: Props) {
    const { cartItems } = useSelector((state: any) => state.currentCart);
    const [modalVisible, setModalVisible] = useState(false);

    const onClick = () => {
      setModalVisible(true);
    };

    useEffect(() => {
      return () => {
        setModalVisible(false);
      };
    }, []);

    return (
      <>
        <CartReorderModalWrapper
          cartItems={cartItems}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          singleSkuToMove={sku}
        />
        <Tooltip title={withoutText ? "" : "Artikel verschieben"}>
          <button
            type="button"
            className="productInfoIcon buttonWithSpin button buttonText buttonWithIcon buttonTextDecoration--inverted"
            onClick={onClick}
          >
            <Move className="icon" />
            {withoutText && (
              <span className="color-primary">Artikel verschieben</span>
            )}
          </button>
        </Tooltip>
      </>
    );
  };

export default MoveSingleItemToDateButton;
