import { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { Entry } from "contentful";

import handleError from "../utils/handleError";
import getAdWebshopDashboard from "../api/contentful/getAdWebshopDashboard";
import { StockType } from "../types/stock";
import { ContentfulAdWebshopDashboardEntry } from "../types/contentfulAdWebshopDashboardEntry";

interface GetWebshopAdWebshopDashboardProps {
  stockName: StockType;
}

const useGetAdWebshopDashboard = (props: GetWebshopAdWebshopDashboardProps) => {
  const { stockName } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<
    Entry<ContentfulAdWebshopDashboardEntry>[]
  >([]);
  const [total, setTotal] = useState<number>(null);

  const fetchAdWebshopDashboard = useCallback(async () => {
    setIsLoading(true);

    getAdWebshopDashboard({
      stockName,
    })
      .then((response) => {
        const items = response?.items || [];
        setEntries(items);
        setTotal(items?.length || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [stockName]);

  useEffect(() => {
    fetchAdWebshopDashboard().then();
  }, [fetchAdWebshopDashboard]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetAdWebshopDashboard;
