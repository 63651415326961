import { CancelTokenSource } from "axios";
import getCartsByDate from "./getCartsByDate";
import createWeekplannerCartDataSet from "../../utils/createWeekplannerCartDataSet";

/**
 * gets all carts for the given date Array, returns an Array of Objects, containing deliveryDate and id
 * @param currentCartsDates {string[]}
 * @param cancelTokenSource {CancelTokenSource}
 * @returns {Array<Record<string, any>>}
 */
const getAllCartsDataByDate = async (
  currentCartsDates: string[],
  cancelTokenSource: CancelTokenSource
) =>
  getCartsByDate(currentCartsDates, cancelTokenSource).then(
    (requestedCardsResponse: any) => {
      if (!requestedCardsResponse?.data?.data) {
        return Promise.reject(requestedCardsResponse);
      }

      const {
        data: { data },
      } = requestedCardsResponse;

      // Adds all the already existing carts for the currentCartsDates to the table data
      return data.map(createWeekplannerCartDataSet);
    }
  );

export default getAllCartsDataByDate;
