import React, { useState } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import MediaModal from "../news/MediaModal";
import { routePathNames } from "../../appConfig";
import YoutubeVideo from "../video/YoutubeVideo";

interface Props {
  alignment?: "left" | "right";
  fields: Record<string, any>;
  textBackground?: boolean;
}

function NewsContentBlock({ alignment, fields, textBackground }: Props) {
  const { title, text, media, youtube_url: ytUrl, pdf } = fields;
  const img = media?.fields?.file || {};

  const [popOutBool, setPopOutBool] = useState(false);
  const [popOutLink, setPopOutLink] = useState("");

  const articleOptions = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: ({ data, content }: any) => (
        <a
          href={`https:${data.target.fields.file.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content[0].value}
        </a>
      ),
      [INLINES.ENTRY_HYPERLINK]: ({ data, content }: any) => (
        <Link to={`${routePathNames.article}${data.target.sys.id}`}>
          {content[0].value}
        </Link>
      ),
      [BLOCKS.EMBEDDED_ASSET]: ({ data }: any) => (
        <img
          src={data?.target?.fields?.file?.url}
          alt={data?.target?.fields?.description}
        />
      ),
    },
  };

  return (
    <>
      <article
        className={clsx(
          "article-container",
          alignment && ` article-container--${alignment}`
        )}
      >
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 32, lg: 32 },
            { xs: 8, sm: 16, md: 32, lg: 32 },
          ]}
        >
          <Col xs={12} md={ytUrl ? 6 : 4} className="article-image">
            {ytUrl ? (
              <YoutubeVideo title={title} url={ytUrl} />
            ) : (
              <button
                type="button"
                className="article-content__image"
                onClick={() => {
                  if (pdf) {
                    window.open(
                      pdf?.fields?.file?.url,
                      "_blank",
                      "noopener, noreferrer"
                    );
                    return;
                  }

                  setPopOutLink(img?.url);
                  setPopOutBool(true);
                }}
              >
                {img && (
                  <img
                    src={`${img?.url}?fit=pad&w=500&h=300`}
                    width={img?.details?.image?.width}
                    height={img?.details?.image?.height}
                    alt=""
                  />
                )}
              </button>
            )}
          </Col>

          <Col xs={12} md={ytUrl ? 6 : 8} className="article-content-container">
            <div
              className={clsx(
                "article-content",
                textBackground && "article-content--grey"
              )}
            >
              <h3 className="article-content__title">{title}</h3>
              <div className="article-content__text">
                {documentToReactComponents(text, articleOptions)}
              </div>
            </div>
          </Col>
        </Row>
      </article>

      {popOutBool && (
        <MediaModal
          alt={title}
          isVisible={popOutBool}
          onCancel={() => setPopOutBool(false)}
          url={popOutLink}
          type="image"
        />
      )}
    </>
  );
}

export default NewsContentBlock;
