import { contentfulContentTypes } from "../../appConfig";
import getSingleContentTypeEntry from "./getSingleContentTypeEntry";

/**
 * retrieve privacy from contentful
 */
const getPrivacy = () =>
  getSingleContentTypeEntry(contentfulContentTypes.privacy);

export default getPrivacy;
