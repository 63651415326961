import axiosWithAuth from "../axiosWithAuth";
import { cartQueryString, cartsEndpoint } from "../endpoints";
import getDeliveryDateQuery from "../getDeliveryDateQuery";
import { UpdateCartData } from "../../types/cartAPI";

/**
 * get single cart by deliveryDate or ID
 * @param cartId {string}
 * @param deliveryDate {string}
 * @param cancelTokenSource {CancelTokenSource}
 */
const getCart = ({ deliveryDate, cancelTokenSource }: UpdateCartData) => {
  if (!deliveryDate) {
    return Promise.reject();
  }

  return axiosWithAuth().get(
    `${cartsEndpoint}/${deliveryDate}${cartQueryString}${getDeliveryDateQuery(
      deliveryDate
    )}`,
    { cancelToken: cancelTokenSource.token }
  );
};

export default getCart;
