import moment from "moment";

import { store } from "../store";
import { datePickerRange } from "../appConfig";
import { apiDateFormat } from "./dateFormats";
import { RootState } from "../types/rootState";

/**
 * define disabled dates
 * @param {moment.Moment} current
 */
const disabledDatePickerDates = (current: moment.Moment) => {
  const today = moment();
  const state: RootState = store.getState();
  const holidays = state?.userData?.businessUnit?.holidays || [];

  return (
    !current.isValid() ||
    current <= today ||
    current > today.add(datePickerRange.maxDays, "days") ||
    current.isoWeekday() === 7 ||
    holidays.includes(current.format(apiDateFormat))
  );
};

export default disabledDatePickerDates;
