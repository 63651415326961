const getProductBasePrice = (
  price: number,
  basePriceQuantity: number,
  basePriceFactor: number,
  basePriceUnit: string,
  productUnitMeasurement: string,
  weighingArticle = "0"
) => {
  const cleanProductUnitMeasurement = productUnitMeasurement
    ?.toLowerCase()
    ?.trim()
    // Remove everything between ()
    ?.replace(/ *\([^)]*\) */g, "");
  const cleanBasePriceUnit = basePriceUnit?.toLowerCase()?.trim();

  if (weighingArticle === "1") {
    return price;
  }

  const ignoreBasePriceFactor =
    !basePriceUnit ||
    cleanProductUnitMeasurement === cleanBasePriceUnit ||
    cleanBasePriceUnit === `1 ${cleanProductUnitMeasurement}` ||
    basePriceUnit === "STÜCK";

  return ignoreBasePriceFactor
    ? price / basePriceQuantity
    : (price / basePriceQuantity) * basePriceFactor;
};

export default getProductBasePrice;
