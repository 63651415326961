import { useSelector } from "react-redux";
import { AttributeName, productAttributes } from "../api/productAttributes";

/**
 * @param attributes {Object}
 * @param attribute {string}
 * @return string
 */
const useStockForAttribute = (
  attributes: Record<string, any>,
  attribute: string
) => {
  const { stockName } = useSelector((state: any) => state.userData);
  const advertAttribute = `${attribute}_${stockName}` as AttributeName;

  return attributes[productAttributes[advertAttribute]] || "";
};

export default useStockForAttribute;
