import React from "react";
import { useSelector } from "react-redux";
import { Alert } from "antd";
import createMinimumOrderLimitMessages from "./createMinimumOrderLimitMessages";
import { ReactComponent as Warning } from "../../../static/svg/warning.svg";

function MinimumOrderLimits() {
  const mlwGroupDeficits = useSelector(
    (state: any) => state.currentCartMetaData.mlwGroupDeficits
  );

  return (
    mlwGroupDeficits?.length > 0 && (
      <Alert
        message={createMinimumOrderLimitMessages(mlwGroupDeficits).map(
          (singleMessage: string) => (
            <span className="orderLimitWarningMessage" key={singleMessage}>
              {singleMessage}
            </span>
          )
        )}
        type="warning"
        showIcon
        icon={<Warning className="icon iconWarning" />}
        className="orderLimitWarning"
        ariaLive="polite"
      />
    )
  );
}

export default MinimumOrderLimits;
