import React, { useMemo, useState } from "react";

import clsx from "clsx";
import { Button, Col, Row, Tag } from "antd";
import moment from "moment";

import { ContentfulWebshopProductInformationEntry } from "../../../types/contentfulWebshopProductInformationEntry";
import Picture from "../../picture/Picture";
import WebshopProductInformationModal from "./WebshopProductInformationModal";
import { fullDateFormat } from "../../../utils/dateFormats";

interface WebshopProductInformationProps {
  productInformation: ContentfulWebshopProductInformationEntry;
  className?: string;
}

const WebshopProductInformation: React.FC<WebshopProductInformationProps> = (
  props: WebshopProductInformationProps
) => {
  const { productInformation, className } = props;

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const isNew = moment(productInformation.publishDate).isSame(moment(), "day");

  const publishDate = useMemo(
    () => moment(productInformation.publishDate).format(fullDateFormat),
    [productInformation.publishDate]
  );

  return (
    <>
      <Row
        gutter={[0, 20]}
        className={clsx("webshop-product-information", className)}
      >
        <Col xs={12} md={3}>
          <Picture
            imageScreenSm={`${productInformation.imageDesktopUrl}?fit=thumb&w=400&h=400&fm=webp&q=80`}
            imageScreenMd={`${productInformation.imageDesktopUrl}?fit=thumb&w=200&h=200&fm=webp&q=60`}
          />
        </Col>
        <Col xs={12} md={9} className="webshop-product-information__content">
          <h4>
            {isNew && <Tag closable={false}>Neu</Tag>}
            {productInformation.headline}
          </h4>
          <div className="webshop-product-information__content__date">
            {publishDate}
          </div>
          <p>{productInformation.contentPreview}</p>
        </Col>
        <Col span={12} className="webshop-product-information__buttons">
          <Button
            className="button buttonPrimary button--rounded"
            onClick={() => setIsModalVisible(true)}
          >
            Mehr lesen
          </Button>
        </Col>
      </Row>
      <WebshopProductInformationModal
        productInformation={productInformation}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
      />
    </>
  );
};

export default WebshopProductInformation;
