import React, { useMemo } from "react";

import clsx from "clsx";
import { ConfigProvider, Empty, Table } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AlignType } from "rc-table/lib/interface";

import moment from "moment";
import getDefaultImageUrlSet from "../../../../utils/getDefaultImageUrlSet";
import StocktakingItemPatchModal from "../StocktakingItemPatchModal";
import localizedNumberFormat, {
  parsePriceValueToLocalPrice,
} from "../../../../utils/localizedNumberFormat";
import { ProductImage } from "../../../product";
import { itemsPerPage } from "../../../../appConfig";
import { StocktakingItemData } from "../../../../types/stocktaking";
import { fullDateFormat, timeFormat } from "../../../../utils/dateFormats";
import ProductButtonMenu from "../../../cart/CartItem/ProductButtonMenu";

interface StocktakingItemTableProps {
  stocktakingItems: StocktakingItemData[];
  total: number;
  page: number;
  isLoading: boolean;
  canEdit?: boolean;
  className?: string;
  emptyDescription?: JSX.Element;
  onTableChange?: (pagination: any, filters: any, sorter: any) => void;
  onDataChange?: () => void;
}

const StocktakingItemsTable: React.FC<StocktakingItemTableProps> = (
  props: StocktakingItemTableProps
) => {
  const {
    stocktakingItems,
    total,
    page,
    isLoading,
    canEdit = true,
    className,
    emptyDescription,
    onTableChange,
    onDataChange = () => {},
  } = props;

  const columns: any[] = [
    {
      title: "",
      dataIndex: "productImageSets",
      width: 100,
      render: (productImageSets: any[]) => {
        return (
          <ProductImage
            imageSet={getDefaultImageUrlSet(productImageSets)}
            imageType="thumbnail"
          />
        );
      },
    },
    {
      title: "EAN",
      dataIndex: "eanCode",
      align: "left" as AlignType,
      width: 150,
      render: (eanCode: string) => eanCode || "-",
      responsive: ["md"],
      sorter: true,
    },
    {
      title: "Art.-Nr.",
      dataIndex: "sku",
      align: "left" as AlignType,
      width: 100,
      render: (sku: string) => sku || "-",
      sorter: true,
    },
    {
      title: "Bezeichnung",
      dataIndex: "label",
      width: 10,
      align: "left" as AlignType,
      render: (label: string) => label || "-",
      ellipsis: true,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
            maxWidth: 200,
          },
        };
      },
    },
    {
      title: "EK-Verp.",
      dataIndex: "unitVpe",
      align: "center" as AlignType,
      width: 100,
      render: (unitVpe: string) => unitVpe || "-",
      responsive: ["md"],
    },
    {
      title: "MWSt.",
      dataIndex: "taxRate",
      align: "center" as AlignType,
      width: 80,
      render: (taxRate: string) =>
        Number.isFinite(taxRate) ? `${taxRate} %` : "-",
      responsive: ["md"],
      sorter: true,
    },
    {
      title: "Menge",
      dataIndex: "quantity",
      align: "center" as AlignType,
      width: 150,
      render: (quantity: number) =>
        localizedNumberFormat({ value: quantity || 0 }) || "-",
      sorter: true,
    },
    {
      title: "Einzelpreis",
      dataIndex: "unitPrice",
      align: "right" as AlignType,
      width: 150,
      render: (unitPrice: string) => {
        return parsePriceValueToLocalPrice(Number(unitPrice) || 0);
      },
      responsive: ["md"],
    },
    {
      title: "Gesamtsumme",
      align: "right" as AlignType,
      width: 150,
      render: (_: any, record: any) => {
        const value = record.quantity * Number(record.unitPrice) || 0;
        return parsePriceValueToLocalPrice(value);
      },
      responsive: ["md"],
    },
    {
      title: "Benutzer",
      align: "left" as AlignType,
      width: 150,
      render: (_: any, record: any) => {
        const userName =
          `${record?.customer?.firstName} ${record?.customer?.lastName}`.trim();

        return userName || "Unbekannt";
      },
      ellipsis: true,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
            maxWidth: 200,
          },
        };
      },
      responsive: ["md"],
    },
    {
      title: "Hinzugefügt",
      dataIndex: "createdAt",
      align: "left" as AlignType,
      width: 150,
      render: (createdAt: string) => {
        return (
          <>
            {moment(moment.utc(createdAt).format()).format(fullDateFormat)}
            <br />
            {`${moment(moment.utc(`${createdAt}`).format()).format(
              timeFormat
            )} Uhr`}
          </>
        );
      },
      sorter: true,
    },
    {
      title: "",
      align: "right" as AlignType,
      width: 100,
      render: (_: any, stocktakingItem: StocktakingItemData) => (
        <>
          <ProductButtonMenu
            sku={stocktakingItem?.sku || ""}
            showMoveButton={false}
            showDeleteButton={false}
            disabled={!canEdit}
            customButton={
              <StocktakingItemPatchModal
                stocktakingItem={stocktakingItem}
                onDelete={onDataChange}
                onUpdate={onDataChange}
                canEdit={canEdit}
              />
            }
          />
        </>
      ),
    },
  ];

  const tableDataSource = useMemo(
    () =>
      stocktakingItems.map((stocktakingItemData: StocktakingItemData) => ({
        ...stocktakingItemData,
        key: stocktakingItemData.idStocktakingItem,
      })),
    [stocktakingItems]
  );

  const renderEmpty = () => (
    <Empty
      className="mb-3xl"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={emptyDescription}
    />
  );

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <Table
        className={clsx(
          "productItemTable",
          "stocktaking-items-table",
          className
        )}
        sticky
        showSorterTooltip={false}
        columns={columns}
        dataSource={tableDataSource}
        scroll={{ x: "max-content" }}
        tableLayout="fixed"
        pagination={{
          position: ["bottomCenter"],
          total,
          pageSize: itemsPerPage.stocktakingItems,
          current: page,
          showSizeChanger: false,
          showLessItems: true,
        }}
        onChange={onTableChange}
        loading={isLoading}
      />
    </ConfigProvider>
  );
};

export default StocktakingItemsTable;
