import React from "react";

import { PodcastWidget, SeminarWidget } from "../../molecules";

import { DashboardWidgetBlock } from "../../organisms";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";
import TrackingHelmet from "../../Matomo/TrackingHelmet";

const Dashboard: React.FC = () => {
  return (
    <>
      <TrackingHelmet title={pageTitles.dashboard} suffix={pageTitleSuffix} />

      <DashboardWidgetBlock />

      <PodcastWidget />

      <SeminarWidget />
    </>
  );
};

export default Dashboard;
