import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";
import { useSelector } from "react-redux";

import WeekplannerItemProduct from "./WeekplannerItemProduct";
import WeekplannerItemPrice from "./WeekplannerItemPrice";
import WeekplannerItemCarts from "./WeekplannerItemCarts";
import WeekplannerItemSku from "./WeekplannerItemSku";
import WeekplannerItemAttribute from "./WeekplannerItemAttribute";
import WeekplannerItemVolumePrice from "./WeekplannerItemVolumePrice";
import WeekplannerItemProductSimple from "./WeekplannerItemProductSimple";
import WeekplannerItemActions from "./WeekplannerItemActions";
import { ProductData } from "../../../../../types/productData";
import { RootState } from "../../../../../types/rootState";

interface WeekplannerItemProps {
  productData: ProductData;
  onClick: (sku: string) => void;
  style?: any;
  className?: string;
}

const WeekplannerItem: React.FC<WeekplannerItemProps> = (
  props: WeekplannerItemProps
) => {
  const { productData, onClick, style, className } = props;

  const { weekplannerUseSimpleMode: useSimpleMode = false } = useSelector(
    (state: RootState) => state?.userData || {}
  );

  return (
    <div
      className={clsx(
        "weekplanner-item",
        useSimpleMode && "weekplanner-item--simple",
        className
      )}
      style={style}
    >
      {useSimpleMode ? (
        <>
          <WeekplannerItemSku productData={productData} />
          <WeekplannerItemProductSimple
            productData={productData}
            onClick={onClick}
          />
          <WeekplannerItemCarts
            productData={productData}
            showUnavailableHeader={false}
          />
          <WeekplannerItemAttribute
            productData={productData}
            attributeName="origin"
          />
          <WeekplannerItemAttribute
            productData={productData}
            attributeName="quality"
          />
          <WeekplannerItemAttribute
            productData={productData}
            attributeName="unitQuantity"
            otherAttributeName="package"
          />
          <WeekplannerItemVolumePrice productData={productData} quantity={1} />
          <WeekplannerItemVolumePrice productData={productData} quantity={2} />
          <WeekplannerItemVolumePrice productData={productData} quantity={5} />
          <WeekplannerItemVolumePrice productData={productData} quantity={10} />
          <WeekplannerItemVolumePrice
            productData={productData}
            priceType="trolley"
          />
          <WeekplannerItemVolumePrice
            productData={productData}
            priceType="pallet"
          />
          <WeekplannerItemActions productData={productData} />
        </>
      ) : (
        <>
          <WeekplannerItemProduct productData={productData} onClick={onClick} />
          <WeekplannerItemPrice productData={productData} />
          <WeekplannerItemCarts productData={productData} />
        </>
      )}
    </div>
  );
};

export default React.memo(WeekplannerItem, areEqual);
