import React, { useCallback, useMemo } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { Tree } from "antd";

import findNestedKeyInObject from "../../../utils/findNestedKeyInObject";
import aggregateCategoriesArray from "../../../utils/aggregateCategoriesArray";
import CategoryTreeChild from "./CategoryTreeChild";
import { routePathNames } from "../../../appConfig";
import { ReactComponent as Arrow } from "../../../static/svg/arrow.svg";

interface CategoryTreeProps {
  showHeader?: boolean;
  className?: string;
}

const ROOT_CATEGORY_KEY = "1";

const CategoryTree: React.FC<CategoryTreeProps> = (props) => {
  const { showHeader = true, className } = props;

  const { categoryKey } = useParams<{
    categoryKey?: string;
  }>();

  const { categories } = useSelector((state: any) => state.categoryNavigation);

  const siblingCategoryTree = useCallback(
    (siblings: any[], excludeCategoryKey: string) => {
      return (
        siblings
          .filter((sibling) => sibling.categoryKey !== excludeCategoryKey)
          .map((sibling) => {
            return {
              title: (
                <NavLink
                  to={`${routePathNames.products}${routePathNames.category}${sibling.categoryKey}`}
                  title={sibling.name}
                >
                  {sibling.name}
                </NavLink>
              ),
              key: sibling.nodeId,
              order: sibling.order,
              children: [],
            };
          }) || []
      );
    },
    []
  );

  const createCategoryTree = useCallback(
    (categoryTreeArray: any[]) => {
      if (categoryTreeArray && categoryTreeArray.length >= 1) {
        const categoryTree: any = [
          {
            title: (
              <NavLink
                to={`${routePathNames.products}${routePathNames.category}${categoryTreeArray[0].categoryKey}`}
                title={categoryTreeArray[0].name}
              >
                {categoryTreeArray[0].name}
              </NavLink>
            ),
            key: categoryTreeArray[0].nodeId,
            order: categoryTreeArray[0].order,
            children:
              // eslint-disable-next-line no-nested-ternary
              categoryTreeArray.length > 1
                ? createCategoryTree(categoryTreeArray.slice(1))
                : CategoryTreeChild({ categoryTreeChild: categoryTreeArray }),
          },
          ...siblingCategoryTree(
            categoryTreeArray[0]?.parentCategoryKey === ROOT_CATEGORY_KEY
              ? categories?.children || []
              : findNestedKeyInObject(
                  categories.children,
                  categoryTreeArray[0].parentCategoryKey,
                  "categoryKey",
                  "children"
                )?.children || [],
            categoryTreeArray[0].categoryKey
          ),
        ];

        if (categoryTreeArray[0]?.parentCategoryKey !== ROOT_CATEGORY_KEY) {
          categoryTree.sort((a: any, b: any) => b.order - a.order);
        }

        return categoryTree;
      }
      return [];
    },
    [categories.children, siblingCategoryTree]
  );

  const categoryTree = useMemo(() => {
    if (categories?.children?.length === 0) {
      return [];
    }
    const foundCategory = findNestedKeyInObject(
      categories.children,
      categoryKey,
      "categoryKey",
      "children"
    );
    if (!foundCategory) {
      return [];
    }

    const categoryArray = aggregateCategoriesArray(
      foundCategory,
      categories.children,
      []
    ).reverse();

    return createCategoryTree(categoryArray);
  }, [categories.children, categoryKey, createCategoryTree]);

  if (!categoryTree?.length) {
    return null;
  }

  return (
    <aside className={clsx("category-tree-block-container", className)}>
      {showHeader && (
        <div className="category-tree-panel-header text-bold text-base">
          <NavLink to={routePathNames.shop}>Kategorien</NavLink>
        </div>
      )}
      <Tree.DirectoryTree
        className="folder-tree"
        treeData={categoryTree}
        showLine={{ showLeafIcon: false }}
        showIcon={false}
        switcherIcon={<Arrow className="icon iconTreeSwitch" />}
        defaultExpandAll
        blockNode
        defaultExpandParent
        expandAction={false}
      />
    </aside>
  );
};

export default CategoryTree;
