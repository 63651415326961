import { Drawer, Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routePathNames } from "../../appConfig";
import { ReactComponent as Close } from "../../static/svg/close.svg";
import {
  FavouriteList,
  FavouriteListWithNumberId,
} from "../../types/favouriteList";
import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";
import AddFavouriteListInput from "./AddFavouriteListInput";
import DeleteFavouriteListButton from "./DeleteFavouriteListButton";

interface HandleFavouriteListsModalProps {
  favouriteLists: Array<FavouriteList>;
  hideModal: () => void;
  isVisible: boolean;
  modalHeading: string;
}

/**
 * Modal Component to handle favourite list actions
 *
 * On DESKTOP the user can add a favourite list
 *
 * On MOBILE | TABLET the user can add or delete a favourite list and is able to switch between favourite lists
 * @param favouriteLists
 * @param {() => void} hideModal
 * @param {boolean} isVisible
 * @param {string} modalHeading
 * @constructor
 */
const HandleFavouriteListsModal: React.FC<HandleFavouriteListsModalProps> =
  function HandleFavouriteListsModal({
    favouriteLists = [],
    hideModal,
    isVisible,
    modalHeading,
  }) {
    const navigate = useNavigate();

    const isLargeScreen = useMedia(
      `(min-width: ${getCssVariable("screen-md")})`
    );

    // pathname for favourite lists route
    const { favouriteList: favouriteListPathname } = routePathNames;

    /**
     * called when a new favourite list was successfully added
     * @param {FavouriteListWithNumberId} favouriteList
     */
    const onFinish = (favouriteList: FavouriteListWithNumberId) => {
      navigate(`${favouriteListPathname}${favouriteList.idFavouriteList}`);
      hideModal();
    };

    return (
      <>
        {isLargeScreen ? (
          /* DESKTOP */
          <Modal
            title={modalHeading}
            visible={isVisible}
            footer={null}
            onCancel={hideModal}
            destroyOnClose
            className="addFavouriteListModal"
            centered
          >
            <div className="addFavouriteListWrapper">
              <AddFavouriteListInput onFinish={onFinish} focused />
            </div>
          </Modal>
        ) : (
          /* MOBILE | TABLET */
          <Drawer
            title="Favoritenlisten"
            placement="bottom"
            onClose={hideModal}
            visible={isVisible}
            key="bottom"
            className="popupMenu"
            // fix height to always display three favourite lists
            height={310}
            closable={false}
            extra={
              <div className="drawerExtraWrapper">
                <button
                  type="button"
                  title="Schließen"
                  className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText"
                  onClick={hideModal}
                >
                  <Close className="closeIcon closeIcon--large" />
                </button>
              </div>
            }
            footer={
              <div className="productAddFooterContainer">
                <AddFavouriteListInput onFinish={onFinish} />
              </div>
            }
          >
            <ul className="menuItemsList">
              {favouriteLists.map((favouriteList: FavouriteList) => (
                <li key={favouriteList.idFavouriteList} className="menuItem">
                  <button
                    type="button"
                    className="buttonPlain"
                    onClick={() => {
                      navigate(
                        `${favouriteListPathname}${favouriteList.idFavouriteList}`
                      );
                      hideModal();
                    }}
                  >
                    <span className="menuItemText ml-0">
                      <span className="itemName">{favouriteList.caption}</span>
                      <span className="itemNumber">
                        &nbsp;{`(${favouriteList.favourites?.length})`}
                      </span>
                    </span>
                  </button>
                  <DeleteFavouriteListButton
                    idFavouriteList={favouriteList.idFavouriteList}
                  />
                </li>
              ))}
            </ul>
          </Drawer>
        )}
      </>
    );
  };

export default HandleFavouriteListsModal;
