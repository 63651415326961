import { CancelTokenSource } from "axios";
import storeDispatch from "./storeDispatch";
import getFavouriteLists from "../../api/favouritesList/getFavouriteLists";

/**
 * get user's favourite list and update redux
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise}
 */
const setFavouriteLists = async (cancelTokenSource: CancelTokenSource) =>
  getFavouriteLists(cancelTokenSource).then((favouriteListResponse) => {
    storeDispatch("favouriteList/set-lists", favouriteListResponse);

    return favouriteListResponse;
  });

export default setFavouriteLists;
