import React from "react";
import moment from "moment";
import { ProductData } from "../../../types/productData";
import getDeliveryDateBasedAttributes from "../getDeliveryDateBasedAttributes";
import ProductBasketAvailable from "./ProductBasketAvailable";
import ProductBasketPreorder from "./ProductBasketPreorder";

interface Props {
  productData: ProductData;
  deliveryDate?: ProductData["deliveryDate"];
  setFocusOnSearchInput?: (value: boolean) => void;
  view?: string;
}

/**
 * ProductBasket Component
 * @param {ProductData} productData
 * @param {ProductData["deliveryDate"]} deliveryDate
 * @param {number} defaultQuantity
 * @param {String} view
 * @param {Function} setFocusOnSearchInput
 * @constructor
 */
const ProductBasket: React.FC<Props> = ({
  productData,
  deliveryDate,
  setFocusOnSearchInput,
  view = "",
}: Props) => {
  const { availabilities } = productData;

  const { isUnavailable, nextAvailability } = getDeliveryDateBasedAttributes({
    deliveryDate,
    availabilities,
  });

  // if the product is not available return no component
  if (isUnavailable && !nextAvailability) {
    return null;
  }

  // if it is not available but has next availabilities, add a modified version
  if (isUnavailable && nextAvailability) {
    const today = moment();

    // if the nextAvailability is deprecated, ignore it
    if (moment(nextAvailability).isSameOrBefore(today)) {
      return null;
    }
    return (
      <ProductBasketPreorder
        deliveryDate={deliveryDate}
        productData={productData}
        setFocusOnSearchInput={setFocusOnSearchInput}
        view={view}
      />
    );
  }

  // if it is available return the default product basket
  if (!isUnavailable) {
    return (
      <ProductBasketAvailable
        deliveryDate={deliveryDate}
        productData={productData}
        setFocusOnSearchInput={setFocusOnSearchInput}
        view={view}
      />
    );
  }

  return null;
};

export default ProductBasket;
