import { useMemo } from "react";

import { useSelector } from "react-redux";

import getDeliveryDates from "../utils/getDeliveryDates";
import { RootState } from "../types/rootState";

const useGetWeekplannerDaysToShow = () => {
  const { deliveryDates: customerDeliveryDates } = useSelector(
    (state: RootState) => state?.userData || {}
  );
  const { deliveryDates: businessUnitDeliveryDates } = useSelector(
    (state: RootState) => state?.userData?.businessUnit || {}
  );

  const deliveryDates = useMemo(
    () => getDeliveryDates(customerDeliveryDates, businessUnitDeliveryDates),
    [customerDeliveryDates, businessUnitDeliveryDates]
  );

  const daysToShow: any = useMemo(() => {
    return {
      Montag: deliveryDates.includes("1"),
      Dienstag: deliveryDates.includes("2"),
      Mittwoch: deliveryDates.includes("3"),
      Donnerstag: deliveryDates.includes("4"),
      Freitag: deliveryDates.includes("5"),
      Samstag: deliveryDates.includes("6"),
    };
  }, [deliveryDates]);

  const filteredDaysToShow = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(daysToShow).filter(([, value]) => value === true)
      ),
    [daysToShow]
  );

  return {
    daysToShow,
    filteredDaysToShow,
  };
};

export default useGetWeekplannerDaysToShow;
