import React from "react";
import { Spin } from "antd";

function LoaderComponent() {
  return (
    <div className="loader-component">
      <Spin className="antd-loader" size="large" color="white" />
    </div>
  );
}

export default LoaderComponent;
