import axiosWithAuth from "../axiosWithAuth";
import { customerEndpoint } from "../endpoints";
import { CompanyUserProfile } from "../../types/companyUserProfile";

/**
 * PATCH / update company User at spryker
 * return new user data
 * @param userData {Object}
 * @return {Promise<AxiosResponse>}
 */

const deleteCompanyUser = async (userData: CompanyUserProfile) => {
  const { uuid } = userData;
  const requestBody = {
    data: {
      type: "customers",
      attributes: {
        active: false,
      },
    },
  };

  return axiosWithAuth()
    .delete(`${customerEndpoint}/${uuid}`, requestBody)
    .then((response) => {
      if (response?.status !== 204) {
        return Promise.reject(response);
      }

      return response;
    });
};

export default deleteCompanyUser;
