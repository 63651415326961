import sumCartItems from "../../utils/sumCartItems";

export interface WeekplannerData {
  weekDates: any[];
  weekCarts: any[];
  minWeek: string | null;
  maxWeek: string | null;
}

const initialState: WeekplannerData = {
  weekDates: [],
  weekCarts: [],
  minWeek: null,
  maxWeek: null,
};

const weekplannerDataReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "weekplanner/update-dates":
      return {
        ...state,
        weekDates: payload,
        minWeek: payload?.[0]?.minWeek || null,
        maxWeek: payload?.[0]?.maxWeek || null,
      };
    // set all cart data regardless the previous state
    case "weekplanner/set-cart": {
      const newWeekCarts = [...payload];

      newWeekCarts.forEach((entry: any, index: number) => {
        newWeekCarts[index].itemsInCart = sumCartItems(entry.deliveryDateItems);
      });

      return {
        ...state,
        weekCarts: newWeekCarts,
      };
    }
    // filter the payload id and add the new cart data to state
    case "weekplanner/update-cart": {
      const newCart = {
        ...payload,
        itemsInCart: sumCartItems(payload.deliveryDateItems),
      };

      const newWeekCarts = state.weekCarts
        .filter((cartEntry: any) => cartEntry.id !== payload.id)
        .concat(newCart);

      return {
        ...state,
        weekCarts: newWeekCarts,
      };
    }
    // delete item of a corresponding cart
    case "weekplanner/delete-item": {
      // get matching cart
      const weekCartToUpdate: any = state.weekCarts.find(
        (weekCart: any) => weekCart.id === payload.id
      );

      // get new array of items in current cart
      const updatedWeekCartItems = weekCartToUpdate?.deliveryDateItems?.filter(
        (item: any) => item.sku !== payload.sku
      );

      // if no items for the cart could be found, we do not need an update
      if (!updatedWeekCartItems) {
        return state;
      }

      // new weekcart entry for new state
      const updatedWeekCart = {
        ...weekCartToUpdate,
        deliveryDateItems: updatedWeekCartItems,
        itemsInCart: sumCartItems(updatedWeekCartItems),
      };

      // generate new carts
      const newWeekCarts = state.weekCarts
        .filter((weekCart: any) => weekCart.id !== payload.id)
        .concat(updatedWeekCart);

      return {
        ...state,
        weekCarts: newWeekCarts,
      };
    }
    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default weekplannerDataReducer;
