import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { orderPriceTagsEndpoint } from "../endpoints";

interface PostOrderPriceTags {
  content: string;
  cancelTokenSource: CancelTokenSource;
}

/**
 * Post order price tags request
 *
 * @param {string} content
 * @param {cancelTokenSource} cancelTokenSource
 * @return {Promise<any>}
 */
const postOrderPriceTags = ({
  content,
  cancelTokenSource,
}: PostOrderPriceTags) => {
  const payload = {
    data: {
      type: "price-tags-order",
      attributes: {
        content,
      },
    },
  };
  return axiosWithAuth()
    .post(orderPriceTagsEndpoint, payload, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (response.status !== 204) {
        return Promise.reject(response);
      }

      return response;
    });
};

export default postOrderPriceTags;
