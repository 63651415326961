import { parsePriceValueToLocalPrice } from "../../../utils/localizedNumberFormat";
import { StocktakingItemData } from "../../../types/stocktaking";

const getTotalStocktakingValueLocalized = (
  stocktakingItems: StocktakingItemData[]
): string => {
  const totalValue = stocktakingItems
    .map(
      (stocktakingItem: StocktakingItemData) =>
        stocktakingItem.quantity * Number(stocktakingItem.unitPrice) || 0
    )
    .reduce((total: number, value: number) => total + value, 0);
  return parsePriceValueToLocalPrice(totalValue);
};

export { getTotalStocktakingValueLocalized };
