import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { getAllCartsEndpoint } from "../endpoints";

/**
 * retrieves all carts from spryker by given date range
 * @param currentCartsDates {string[]}
 * @param cancelTokenSource {CancelTokenSource}
 * @returns {Promise<AxiosResponse>}
 */
const getCartsByDate = (
  currentCartsDates: string[],
  cancelTokenSource: CancelTokenSource
) => {
  if (!currentCartsDates?.length) {
    return Promise.reject();
  }

  return axiosWithAuth()
    .get(
      `${getAllCartsEndpoint}&delivery_date_from=${
        currentCartsDates[0]
      }&delivery_date_to=${currentCartsDates[currentCartsDates.length - 1]}`,
      { cancelToken: cancelTokenSource.token }
    )
    .then((response) => {
      if (!response?.data?.data || response?.status >= 300) {
        return Promise.reject(response);
      }

      return response;
    });
};

export default getCartsByDate;
