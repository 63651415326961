import React, { useMemo } from "react";
import { Badge } from "antd";
import { CheckOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";

interface Props {
  filterStates: { [key: string]: string[] | boolean };
  dataSlotsName: string;
  dataSlotsValues: any;
  onClickHandler: (dataSlotsName: string, isSelected?: boolean) => void;
}

type FilterValue = {
  doc_count: number;
  label?: string;
  value: string;
};

type Filters = {
  filterKey: string;
  filterValues: FilterValue[];
}[];

const FiltersMobileEntry = function FiltersMobileEntry({
  filterStates,
  dataSlotsName,
  dataSlotsValues,
  onClickHandler,
}: Props) {
  /**
   * determines if one of the filters inside dataSlotsValues matches with the current active filters from filterStates
   * @returns {boolean}
   */
  const isSelected = useMemo(() => {
    let result = false;
    let filters: Filters = [];
    if (dataSlotsValues?.slotFilters) {
      filters = Object.entries(dataSlotsValues?.slotFilters).map(
        ([key, values]: [string, any]) => ({
          filterKey: key,
          filterValues: values.values ? values.values : [values.value],
        })
      );
    } else
      filters = [
        {
          filterKey: dataSlotsValues.name,
          filterValues: dataSlotsValues?.values || [dataSlotsValues?.value],
        },
      ];
    filters.forEach((filter) => {
      const filterValues = filter.filterValues?.map((filterValue) =>
        filterValue?.value ? filterValue.value : filterValue
      );
      const filterStateValues = filterStates?.[filter.filterKey];
      let matchingValues;
      if (typeof filterStateValues !== "boolean") {
        matchingValues = filterStateValues?.filter((filterStateValue) =>
          filterValues?.includes(filterStateValue)
        );
      } else matchingValues = [filterStateValues];
      if (matchingValues?.length) {
        result = true;
      }
    });
    return result;
  }, [filterStates, dataSlotsValues]);

  /**
   * Determines how many values/filters are selected for the current filter/slot
   * @returns {int}
   */
  const numSelectedFilters = useMemo(() => {
    if (!filterStates) {
      return 0;
    }

    const filterStateKeys = Object.keys(filterStates);

    // Return number of selected filters per slot
    if (dataSlotsValues?.slotFilters) {
      const slotFilterKeys = Object.keys(dataSlotsValues.slotFilters);
      return filterStateKeys.filter((filterKey: string) =>
        slotFilterKeys.includes(filterKey)
      ).length;
    }

    // Return number of selected values per filter
    if (
      filterStateKeys.includes(dataSlotsName) &&
      Array.isArray(filterStates[dataSlotsName])
    ) {
      return (filterStates[dataSlotsName] as Array<string>).length;
    }

    return 0;
  }, [filterStates, dataSlotsValues, dataSlotsName]);

  /**
   * Determines if current filter is a boolean filter
   * @return {boolean}
   */
  const isBooleanFilter = useMemo(() => {
    return typeof dataSlotsValues?.value === "boolean";
  }, [dataSlotsValues]);

  /**
   * Determines if current filter is a slot/group of filters
   * @return {boolean}
   */
  const isFilterGroup = useMemo(() => {
    return !!dataSlotsValues?.slot;
  }, [dataSlotsValues]);

  return (
    <li className="filters-mobile__option">
      <button
        type="button"
        className="filters-mobile__filter"
        onClick={() => onClickHandler(dataSlotsName, isSelected)}
      >
        <div className="filters-mobile__option--icon">
          {isSelected && <CheckOutlined className="mr-s color-primary" />}
        </div>
        <span className="filters-mobile__option--title">
          {dataSlotsValues?.localizedName || dataSlotsValues?.name}
        </span>
        {!!numSelectedFilters && (
          <Badge count={`${numSelectedFilters} aktiv`} />
        )}
        {!isBooleanFilter && !isFilterGroup && (
          <RightOutlined className="ml-s mr-s" />
        )}
        {!isBooleanFilter && isFilterGroup && (
          <PlusOutlined className="ml-s mr-s" />
        )}
      </button>
    </li>
  );
};

export default FiltersMobileEntry;
