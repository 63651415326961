import React, { useMemo } from "react";

import clsx from "clsx";
import { Space } from "antd";
import moment from "moment";

import getDeliveryDateBasedAttributes from "../../../product/getDeliveryDateBasedAttributes";
import useHasDeliveryDateProductAlternative from "../../../../hooks/useHasDeliveryDateProductAlternative";
import ProductPreorderModal from "../../../product/ProductModal/ProductPreorderModal";
import ProductAlternativesModal from "../../../product/ProductModal/ProductAlternativesModal";
import { ProductData } from "../../../../types/productData";

interface QuickOrderProductAlternativesProps {
  deliveryDate: string;
  productData: ProductData;
  className?: string;
}

const QuickOrderProductAlternatives: React.FC<
  QuickOrderProductAlternativesProps
> = (props: QuickOrderProductAlternativesProps) => {
  const { deliveryDate, productData, className } = props;

  const { sku, availabilities } = productData;

  const { nextAvailability } = useMemo(
    () =>
      getDeliveryDateBasedAttributes({
        deliveryDate,
        availabilities,
      }),
    [deliveryDate, availabilities]
  );

  const { hasDeliveryDateProductAlternative, isUnavailable } =
    useHasDeliveryDateProductAlternative({ deliveryDate, productData });

  if (!isUnavailable) {
    return null;
  }

  return (
    <div className={clsx("quick-order-product-alternatives", className)}>
      <Space className="quick-order-product-alternatives__buttons">
        <ProductAlternativesModal
          deliveryDate={deliveryDate}
          hasProductAlternatives={
            isUnavailable && hasDeliveryDateProductAlternative
          }
          sku={sku}
          buttonStyle="primary"
        />

        {nextAvailability !== null &&
          moment(nextAvailability).isAfter(deliveryDate) && (
            <ProductPreorderModal
              deliveryDate={deliveryDate}
              productData={productData}
              buttonStyle="primary"
            />
          )}
      </Space>
    </div>
  );
};

export default QuickOrderProductAlternatives;
