import React, { useEffect, useState } from "react";
import { Col, Layout, message, Row, Spin } from "antd";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import NotAllowed from "./NotAllowed";
import getCompanyUsersData from "../../api/userProfile/getCompanyUsersData";
import HrDivider from "../divider/Divider";
import { ReactComponent as Edit } from "../../static/svg/edit.svg";
import { ReactComponent as Plus } from "../../static/svg/plus.svg";
import { pageTitles, pageTitleSuffix, routePathNames } from "../../appConfig";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import BackButton from "../backButton/BackButton";
import TrackingHelmet from "../Matomo/TrackingHelmet";

interface UserProfileAdministrationState {
  message: Record<string, any>;
}

/**
 * user profile administration component with encapsulated logics
 */
function UserProfileAdministration() {
  const { isSuperAdmin } = useSelector((state: any) => state.userData);
  const { companyBusinessUnitKey } = useSelector(
    (state: any) => state.userData.businessUnit
  );
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();

    if (isSuperAdmin) {
      setIsLoading(true);

      getCompanyUsersData(cancelTokenSource)
        .then((companyUserData: any) => {
          setUsers(companyUserData);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setIsLoading(false);

            message.error({
              content:
                "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal",
              duration: 3,
            });
          }
        });
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [isSuperAdmin, companyBusinessUnitKey]);

  useEffect(() => {
    const locationState = location?.state as UserProfileAdministrationState;
    if (locationState?.message) {
      message.success({
        content: locationState?.message?.message,
        duration: 3,
        onClose: () => {},
      });
    }
  }, [location]);

  if (!isSuperAdmin) {
    return <NotAllowed />;
  }
  return (
    <>
      <TrackingHelmet
        title={pageTitles.userProfilesAdministration}
        suffix={pageTitleSuffix}
      />

      <Layout className="container-layout container-layout--inner">
        <BackButton />
        <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }} justify="space-between">
          <Col xs={12} md={8} className="">
            <header className="headingWithAddon">
              <h1 className="newsTitle">Nutzerverwaltung</h1>
              {users.length > 0 && (
                <span className="itemCount hidden-md-down">
                  {users.length} Nutzer
                </span>
              )}
            </header>
          </Col>

          <Col span={12} className="hidden-lg-up">
            <HrDivider size={2} spacingTop="s" className="mb-m" />
          </Col>

          <Col xs={6} className="flex flex-row items-center hidden-lg-up">
            {users.length > 0 && (
              <span className="itemCount">{users.length} Nutzer</span>
            )}
          </Col>

          <Col xs={6} md={4} className="flex justify-end items-center self-end">
            <button
              type="button"
              className="button buttonPrimary buttonWithIcon"
              onClick={() => navigate(routePathNames.userProfileAdd)}
            >
              <Plus className="icon" />
              Nutzer anlegen
            </button>
          </Col>

          <Col span={12}>
            <HrDivider size={2} spacingTop="s" className="hidden-md-down" />
          </Col>
        </Row>

        <Spin size="large" spinning={isLoading} wrapperClassName="mt-xl">
          <Row
            gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}
            justify="space-between"
          >
            {users.map((user) => {
              const { firstName, lastName, uuid, email } = user;

              return (
                <Col key={uuid} xs={12} lg={6} className="flex flex-col">
                  <div className="editableUserProfile">
                    <h4>
                      {email} ({firstName} {lastName})
                    </h4>

                    <button
                      type="button"
                      className="button buttonText buttonWithIcon"
                      onClick={() =>
                        navigate(`${routePathNames.userProfileEdit}${uuid}`)
                      }
                    >
                      <Edit />
                    </button>
                  </div>

                  <HrDivider size={1} spacingTop="s" />
                </Col>
              );
            })}
          </Row>
        </Spin>
      </Layout>
    </>
  );
}
export default UserProfileAdministration;
