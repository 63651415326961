import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import findNestedKeyInObject from "../../utils/findNestedKeyInObject";
import { routePathNames } from "../../appConfig";
import BreadCrumbItem from "./BreadcrumbItem";
import useGetCategories from "../../hooks/useGetCategories";
import { BreadcrumbEntry } from "../../types/breadcrumb";
import aggregateCategoriesArray from "../../utils/aggregateCategoriesArray";
import { RootState } from "../../types/rootState";

interface Props {
  breadcrumbs?: BreadcrumbEntry[];
  className?: string;
}

const Breadcrumbs = function Breadcrumbs({ breadcrumbs, className }: Props) {
  const { pathname } = useLocation();
  const { categoryKey } = useParams<{
    categoryKey?: string;
  }>();

  const dispatch = useDispatch();
  const { categories } = useSelector(
    (state: RootState) => state.categoryNavigation
  );
  const stockName = useSelector((state: RootState) => state.userData.stockName);

  useGetCategories({}, stockName);
  const [categoryArray, setCategoryArray] = useState([]);
  /**
   * Builds array with categories, based on the the current category.
   * @param category {object}
   * @param categoryList {array}
   * @param list {array}
   * @return {array}
   */

  useEffect(() => {
    dispatch({
      type: "categories/last-category",
      payload: categoryKey || "1",
    });

    // if data is passed use this
    if (breadcrumbs) {
      setCategoryArray(breadcrumbs);
    } else if (categories?.children?.length > 0) {
      const foundCategory = findNestedKeyInObject(
        categories.children,
        categoryKey,
        "categoryKey",
        "children"
      );

      // some ids may NOT be given on some servers -> appConfig -> routeProductCategoryKeys
      // in that case we just show the Home icon in breadcrumbs
      if (foundCategory) {
        setCategoryArray(
          aggregateCategoriesArray(
            foundCategory,
            categories.children,
            []
          ).reverse()
        );
      }
    }
  }, [breadcrumbs, categoryKey, categories.children, dispatch]);

  return (
    <nav className={clsx("breadcrumbs", className)}>
      <ul className="breadcrumbsList">
        <li className="breadcrumbsItem">
          <NavLink to={routePathNames.shop} className="breadcrumbsAnchor">
            <HomeOutlined className="icon" />
          </NavLink>
        </li>

        {Array.isArray(categoryArray) &&
          categoryArray.map((category: any, index: number) => (
            <BreadCrumbItem
              key={category.categoryKey}
              name={category.name}
              isLastItem={index === categoryArray.length - 1}
              isProductDetail={pathname.includes(routePathNames.product)}
              link={`${routePathNames.products}${routePathNames.category}${category.categoryKey}`}
            />
          ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
