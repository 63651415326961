import React, { Dispatch, SetStateAction } from "react";
import ReorderModal from "../../order/OrderDetail/ReorderModal";
import { ProductData } from "../../../types/productData";

interface Props {
  cartItems: ProductData[];
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  singleSkuToMove?: ProductData["sku"];
}

/**
 * wrapper for the modal just to save code and keep it maintainable
 * @param {ProductData[]} cartItems
 * @param {boolean} modalVisible
 * @param {Dispatch<SetStateAction<boolean>>} setModalVisible
 * @param {ProductData["sku"]} singleSkuToMove
 * @constructor
 */
const CartReorderModalWrapper = function CartReorderModalWrapper({
  cartItems,
  modalVisible,
  setModalVisible,
  singleSkuToMove,
}: Props) {
  return (
    <ReorderModal
      productItems={cartItems}
      isModalVisible={modalVisible}
      setIsModalVisible={setModalVisible}
      ignoreCurrentDeliveryDate
      modalQuestion="Möchten Sie diese Artikel dem Warenkorb hinzufügen?"
      reorderSingleSku={singleSkuToMove}
      updateCurrentCart
      headline="ARTIKEL VERSCHIEBEN"
    />
  );
};

export default CartReorderModalWrapper;
