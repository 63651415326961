import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { orderOverviewDocumentEndpoint } from "../endpoints";

interface GetOrderOverviewDocumentProps {
  cancelTokenSource: CancelTokenSource;
}

const getOrderOverviewDocument = async (
  props: GetOrderOverviewDocumentProps
) => {
  const { cancelTokenSource } = props;

  return axiosWithAuth()
    .get(`${orderOverviewDocumentEndpoint}`, {
      responseType: "blob",
      cancelToken: cancelTokenSource.token,
      validateStatus: (status: number) => {
        return status === 200;
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Vormerkungen.pdf");
      document.body.appendChild(link);
      link.click();
    });
};

export default getOrderOverviewDocument;
