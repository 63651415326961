import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Col, Layout, Row } from "antd";
import PodcastList from "./Podcast/PodcastList";
import Seminar from "./Seminars/Seminar";
import SeminarBannerMobile from "../../static/images/seminars-banner-mobile.png";
import SeminarBanner from "../../static/images/seminars-banner.jpg";
import PodcastBanner from "../../static/images/podcast.jpg";
import PodcastBannerMobile from "../../static/images/podcast_mobile.jpg";
import Picture from "../picture/Picture";
import HrDivider from "../divider/Divider";
import ShopCategoryNavigation from "../navigation/ShopCategoryNavigation";
import { pageTitles, pageTitleSuffix, routePathNames } from "../../appConfig";
import TrackingHelmet from "../Matomo/TrackingHelmet";

export default function ELearning() {
  const { learningOption } = useParams<{ learningOption: string }>();
  const { pathname } = useLocation();
  const [seminarsPath, setSeminarPath] = useState(true);

  useEffect(() => {
    if (pathname.includes(routePathNames.seminars)) {
      setSeminarPath(true);
    } else if (pathname.includes(routePathNames.podcast)) {
      setSeminarPath(false);
    }
  }, [learningOption, pathname]);

  return (
    <>
      <TrackingHelmet title={pageTitles.eLearning} suffix={pageTitleSuffix} />

      <ShopCategoryNavigation navItems="elearning" />

      {/* header image */}
      <Layout className="container-layout">
        <Row>
          <Col span={12}>
            {seminarsPath ? (
              <Picture
                className="header-img"
                imageScreenSm={SeminarBannerMobile}
                imageScreenLg={SeminarBanner}
              />
            ) : (
              <Picture
                className="header-img"
                imageScreenSm={PodcastBannerMobile}
                imageScreenLg={PodcastBanner}
              />
            )}
          </Col>
        </Row>
      </Layout>

      <Layout className="container-layout container-layout--inner elearning">
        <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }} justify="space-between">
          <Col span={12}>
            <h1>{seminarsPath ? "Seminare" : "Bio für die Ohren"}</h1>
            <HrDivider size={1} spacingTop="s" spacingBottom="s" />
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
          <Col span={12}>{seminarsPath ? <Seminar /> : <PodcastList />}</Col>
        </Row>
      </Layout>
    </>
  );
}
