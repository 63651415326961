import React, { useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";
import moment from "moment/moment";
import { Image, Modal, Skeleton, Slider, Spin, Tag } from "antd";
import {
  BackwardOutlined,
  CloudDownloadOutlined,
  ForwardOutlined,
  InfoCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

import { Episode, Podcast } from "../../../types/podcast";

interface PodcastPlayerProps {
  podcast: Podcast;
  episode: Episode;
  className?: string;
}

/**
 * Podcast Player
 *
 * @param props
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/
 */
const PodcastPlayer: React.FC<PodcastPlayerProps> = (props) => {
  const { episode, podcast, className } = props;

  const player = useRef(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(episode.duration);
  const [currentSpeed, setCurrentSpeed] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasImageError, setHasImageError] = useState<boolean>(false);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState<boolean>(false);

  const play = (): void => {
    player.current.play();
    setIsPlaying(true);
  };

  const pause = (): void => {
    player.current.pause();
    setIsPlaying(false);
  };

  const goBackwards = (step = 10): void => {
    player.current.currentTime = currentTime - step;
    setCurrentTime(player.current.currentTime);
  };

  const goForwards = (step = 30): void => {
    player.current.currentTime = currentTime + step;
    setCurrentTime(player.current.currentTime);
  };

  const setSpeed = (): void => {
    const speed = currentSpeed >= 2 ? 0.5 : currentSpeed + 0.5;
    player.current.playbackRate = speed;
    setCurrentSpeed(speed);
  };

  const timeLeftReadable: string = useMemo(() => {
    const str = moment.duration(duration - currentTime, "seconds");
    return `${str.minutes()}:${String(str.seconds()).padStart(2, "0")}`;
  }, [duration, currentTime]);

  const durationReadable: string = useMemo(() => {
    const str = moment.duration(duration, "seconds");
    return `${str.minutes()}:${String(str.seconds()).padStart(2, "0")}`;
  }, [duration]);

  useEffect(() => {
    const audioElement = player.current as HTMLAudioElement;
    const loadedMetaDataHandler = () => {
      setDuration(audioElement.duration);
    };

    if (audioElement) {
      audioElement.addEventListener("loadedmetadata", loadedMetaDataHandler);
    }

    return () => {
      if (audioElement) {
        audioElement.pause(); // make sure to mute player
        audioElement.removeEventListener(
          "loadedmetadata",
          loadedMetaDataHandler
        );
      }
    };
  }, []);

  return (
    <div className={clsx("podcast-player", className)}>
      <audio
        src={episode.mp3Link}
        ref={player}
        crossOrigin="anonymous"
        preload="none"
        onTimeUpdate={() => {
          setIsLoading(player.current.networkState === 2);
          setCurrentTime(player.current.currentTime);
        }}
        onLoadedData={() => {
          setIsLoading(false);
        }}
        onProgress={() => {
          if (player.current.readyState >= 2) {
            setIsLoading(false);
          }
        }}
        onWaiting={() => {
          if (player.current.readyState < 2) {
            setIsLoading(true);
          }
        }}
        onEnded={() => {
          setIsPlaying(false);
          setCurrentTime(0);
          player.current.currentTime = 0;
        }}
      >
        <track kind="captions" />
        Sie können den Podcast mit diesem Browser leider nicht abspielen.
      </audio>

      <div className="podcast-player__cover">
        {hasImageError && <Skeleton.Image />}
        {!hasImageError && (
          <Image
            src={episode.image}
            preview={false}
            alt={episode.title}
            onError={() => setHasImageError(true)}
            loading="lazy"
            decoding="async"
          />
        )}
      </div>

      <div className="podcast-player__content">
        <div className="podcast-player__header">
          <Spin spinning={isLoading} size="small" />
        </div>
        <div className="podcast-player__main">
          <div className="podcast-player__play-button">
            {!isPlaying && <PlayCircleOutlined onClick={play} title="Play" />}
            {isPlaying && <PauseCircleOutlined onClick={pause} title="Pause" />}
          </div>
          <div className="podcast-player__details">
            <span className="podcast-player__title">{episode.title}</span>
            <span className="podcast-player__subtitle">{episode.subtitle}</span>
            <span className="podcast-player__duration">
              {timeLeftReadable} Minuten
            </span>
            <div className="podcast-player__actions">
              <InfoCircleOutlined
                title="Mehr Infos zur Episode anzeigen"
                onClick={() => {
                  setIsInfoModalVisible(true);
                }}
              />
              <a
                title="Episode herunterladen"
                href={episode.mp3Link}
                target="_blank"
                rel="nofollow noopener noreferrer"
                download
              >
                <CloudDownloadOutlined />
              </a>
            </div>
          </div>
          <div className="podcast-player__controls">
            <button
              type="button"
              className="podcast-player__controls-speed"
              onClick={() => {
                setSpeed();
              }}
              title="Geschwindigkeit ändern"
            >
              {currentSpeed}x
            </button>
            <div>
              <BackwardOutlined
                onClick={() => goBackwards()}
                title="10 Sekunden zurück"
              />
              <ForwardOutlined
                onClick={() => goForwards()}
                title="30 Sekunden vor"
              />
            </div>
          </div>
        </div>
        <div className="podcast-player__footer">
          <Slider
            value={currentTime}
            onChange={(value: number) => {
              player.current.currentTime = value;
            }}
            max={episode.duration}
            tooltipVisible={false}
            handleStyle={{ display: "none" }}
            trackStyle={{ height: "8px" }}
          />
        </div>
      </div>
      <Modal
        visible={isInfoModalVisible}
        title={episode.title}
        okText="Schließen"
        onOk={() => setIsInfoModalVisible(false)}
        onCancel={() => setIsInfoModalVisible(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        wrapClassName="podcast-player-modal"
        width={1000}
      >
        <h5>{episode.subtitle}</h5>
        <p>{episode.description}</p>
        {episode.pubDate && <p>Veröffentlicht: {episode.pubDate} Uhr</p>}
        {durationReadable && <p>Dauer: {durationReadable} Minuten</p>}
        {episode.keywords?.split(",").map((keyword: string, i: number) => (
          <Tag closable={false} key={i}>
            {keyword}
          </Tag>
        ))}
        <div className="podcast-player-modal__podcast">
          {podcast.image && (
            <Image
              src={podcast.image}
              preview={false}
              alt={podcast.title}
              loading="lazy"
              decoding="async"
            />
          )}
          <div className="podcast-player-modal__podcast-details">
            <h5>{podcast.title}</h5>
            <p>{podcast.description}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PodcastPlayer;
