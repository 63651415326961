import React from "react";

interface VideoProps {
  title: string;
  url: string;
}

/**
 * Youtube video wrapper
 * @param title {string}
 * @param url {string}
 * @constructor
 */
const YoutubeVideo = function YoutubeVideo({ title, url }: VideoProps) {
  return (
    <div className="iframeContainer iframeContainer--16-9">
      <iframe
        src={url}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default YoutubeVideo;
