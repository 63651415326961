/**
 * helper to generate page offset
 * @param pageNumber {number}
 * @param itemLimitPerPage {number}
 * @returns {number}
 */
const getPageOffset = (pageNumber: number, itemLimitPerPage: number) =>
  (pageNumber - 1) * itemLimitPerPage;

export default getPageOffset;
