import React, { useCallback, useEffect, useRef } from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Layout, Menu, MenuProps, Space } from "antd";
import { ThunderboltFilled } from "@ant-design/icons";
import { ReactComponent as Arrow } from "../../static/svg/arrow.svg";

import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";
import useSetCategoryNavigationVisibility from "../../hooks/useSetCategoryNavigationVisibility";

import { NavigationIds } from "../../types/navigation";
import { ReactComponent as ProductList } from "../../static/svg/productlist.svg";
import {
  categoryPanelData,
  locationSearchQueryParameter,
  routePathNames,
  routeProductCategoryKeys,
} from "../../appConfig";

type MenuItem = Required<MenuProps>["items"][number];

interface ShopCategoryNavigationProps {
  navItems?: "default" | "elearning";
}

const ShopCategoryNavigation: React.FC<ShopCategoryNavigationProps> = (
  props
) => {
  const { navItems = "default" } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const deliveryDate = useSelector(getCartDeliveryDateSlice);
  const { isVisible, isHovered } = useSelector(
    (state: any) => state.categoryNavigation
  );
  const browserIsMobile = useMedia(
    `(max-width: ${getCssVariable("screen-sm")})`
  );
  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-xl")})`
  );
  const setCategoryNavigationVisibility = useSetCategoryNavigationVisibility();

  const isHoveredRef = useRef(false);

  const getRoute = useCallback(
    (type: NavigationIds) =>
      `${routePathNames.products}${routePathNames.category}${routeProductCategoryKeys[type]}?${locationSearchQueryParameter.deliveryDate}=${deliveryDate}&${locationSearchQueryParameter.page}=1`,
    [deliveryDate]
  );

  /**
   * set panel states
   * @param categoryTitle {string}
   * @param categoryRoute {string}
   */
  const setCategoryPanelData = (
    categoryTitle: string,
    categoryRoute: string
  ) => {
    dispatch({
      type: "categories/set-active-routing",
      payload: {
        categoryTitle,
        categoryRoute,
      },
    });

    setCategoryNavigationVisibility();
  };

  /**
   * handles the interaction event triggered by the categories button
   */
  const handleNavigationEvent = () => {
    if (pathname.includes(routePathNames.weekPlanner)) {
      setCategoryPanelData(
        categoryPanelData.weekPlanner[0],
        categoryPanelData.weekPlanner[1]
      );
    } else {
      setCategoryPanelData(
        categoryPanelData.products[0],
        categoryPanelData.products[1]
      );
    }
  };

  /**
   * triggered when the cursor leaves the categories button
   */
  const onMouseLeave = () => {
    setTimeout(() => {
      if (!isHoveredRef.current) {
        setCategoryNavigationVisibility(false);
      }
    }, 50);
  };

  useEffect(() => {
    isHoveredRef.current = isHovered;
  }, [isHovered]);

  /**
   * Get menu item
   *
   * @param key {string}
   * @param label {string}
   * @param route {string}
   * @param className {string}
   * @param activeClassName {string}
   * @param icon {React.ReactNode}
   */
  const getMenuItem = (
    key: string,
    label: string,
    route: string,
    className = "menuItem",
    activeClassName = "navlink-active",
    icon: React.ReactNode = null
  ): MenuItem => {
    return {
      key,
      label: (
        <Space align="baseline">
          {!!icon && icon}
          <NavLink
            caseSensitive
            to={route}
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
            {label}
          </NavLink>
        </Space>
      ),
      className,
    } as MenuItem;
  };

  const defaultMenuItems: MenuItem[] = [
    getMenuItem("novelty", "Neuprodukte", getRoute("novelty")),
    getMenuItem("promotions", "Aktionen", getRoute("promotion")),
    getMenuItem("displays", "Displays", getRoute("displays")),
    getMenuItem("preorder", "Vorbestellung", getRoute("preorder")),
    getMenuItem(
      "barbecue-promotion",
      "Grillen",
      `${routePathNames.products}${routePathNames.category}${routeProductCategoryKeys.barbecuePromotion}`
    ),
    /* getMenuItem(
      "christmas-meat-promotion",
      "Weihnachtsfleisch",
      `${routePathNames.weekPlanner}${routePathNames.category}${routeProductCategoryKeys.christmasMeatPromotion}?deliveryDateFrom=2023-12-18`
    ),
    getMenuItem(
      "easter-promotion",
      "Ostern",
      `${routePathNames.weekPlanner}${routePathNames.category}${routeProductCategoryKeys.easterPromotion}`
    ),
    getMenuItem(
      "easter-meat-promotion",
      "Osterfleisch und -fisch",
      `${routePathNames.weekPlanner}${routePathNames.category}${routeProductCategoryKeys.easterMeatPromotion}?deliveryDateFrom=2024-03-25&deliveryDateTo=2024-03-30&page=1&sortBy=warengruppe&sortDirection=asc`
    ), */
    getMenuItem(
      "quick-order",
      "Schnellbestellung",
      routePathNames.quickOrder,
      "menuItem menuItem--end",
      "navlink-active",
      <ThunderboltFilled />
    ),
  ];

  if (!browserIsMobile) {
    defaultMenuItems.unshift({
      key: "products",
      label: (
        <button
          type="button"
          className={clsx(
            "button buttonPrimary buttonPrimary--inverted buttonWithIcon mb-xs mt-xs",
            isVisible && "buttonPrimary--active"
          )}
          onClick={handleNavigationEvent}
          onMouseEnter={browserIsDesktop ? handleNavigationEvent : null}
          onFocus={browserIsDesktop ? handleNavigationEvent : null}
          onMouseLeave={browserIsDesktop ? onMouseLeave : null}
        >
          <ProductList className="productListIcon" />
          Sortiment
        </button>
      ),
      className: "menuItem sortiment-item",
    } as MenuItem);
  }

  const eLearningMenuItems: MenuItem[] = [
    getMenuItem("seminars", "Seminar Themen", routePathNames.seminars),
    getMenuItem("podcasts", "Podcast", routePathNames.podcast),
  ];

  return (
    <nav className="shopCategoryNavigation" id="shopCategoryNavigation">
      <Layout className="container-layout container-layout--inner">
        <Menu
          mode="horizontal"
          triggerSubMenuAction="click"
          overflowedIndicator={<Arrow className="icon iconArrow" />}
          className="shopCategoryNavigationMenu"
          items={navItems === "default" ? defaultMenuItems : eLearningMenuItems}
        />
      </Layout>
    </nav>
  );
};

export default ShopCategoryNavigation;
