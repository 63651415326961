import React, {
  ReactComponentElement,
  useEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  PfandpflichtIdType,
  ProductAttributeTypes,
  TransportType,
} from "../../types/productAttributes";
import { productAttributes } from "../../api/productAttributes";
import { ReactComponent as CO2 } from "../../static/svg/colored/co2-baum.svg";
import { ReactComponent as Samenfest } from "../../static/svg/colored/Samenfest.svg";
import { ReactComponent as TruckTrain } from "../../static/svg/transport-type-truck-train.svg";
import { ReactComponent as Ship } from "../../static/svg/transport-type-ship.svg";
import { ReactComponent as PlaneShip } from "../../static/svg/transport-type-plane-ship.svg";
import { ReactComponent as Plane } from "../../static/svg/transport-type-plane.svg";
import { ReactComponent as BioladenBasic } from "../../static/svg/bioladen-basic.svg";
import { ReactComponent as Frozen } from "../../static/svg/frozen.svg";
import { ReactComponent as TemperatureControlled } from "../../static/svg/temperature-controlled.svg";
import { ReactComponent as Mehrweg } from "../../static/svg/pfandpflicht-type-mehrweg.svg";
import { ReactComponent as Einweg } from "../../static/svg/pfandpflicht-type-einweg.svg";
import { ProductData } from "../../types/productData";
import getProcessedProductAttributeValue from "./getProcessedProductAttributeValue";
import useStockForAttribute from "../../hooks/useStockForAttribute";
import {
  brixUnit,
  locationSearchQueryParameter,
  routePathNames,
} from "../../appConfig";

interface Props {
  attributes: ProductAttributeTypes;
  sku: string;
  deliveryDate: ProductData["deliveryDate"];
  className?: string;
  view?: string;
  getContainer?: () => HTMLElement;
}

const transportIcons: Record<TransportType, any> = {
  Flug: <Plane />,
  Schiff: <Ship />,
  "Flug oder Schiff": <PlaneShip />,
  "LKW oder Zug": <TruckTrain />,
};

const pfandpflichtIcons: Record<PfandpflichtIdType, any> = {
  "Mehrweg-Pfand": <Mehrweg />,
  "Einweg-Pfand": <Einweg />,
};

const ProductAttributes: React.FC<Props> = ({
  attributes,
  className,
  deliveryDate,
  sku = "",
  view = "",
  getContainer,
}: Props) => {
  const productInfo = useStockForAttribute(attributes, "info");
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [isEllipsisActiveComposition, setIsEllipsisActiveComposition] =
    useState(false);
  const textRef = useRef<HTMLSpanElement>();
  const textRefer = useRef<HTMLSpanElement>();

  useEffect(() => {
    const element = textRef.current;
    const elementComposition = textRefer.current;

    setIsEllipsisActiveComposition(
      elementComposition
        ? elementComposition.offsetWidth < elementComposition.scrollWidth ||
            elementComposition.offsetHeight < elementComposition.scrollHeight
        : false
    );

    setIsEllipsisActive(
      element
        ? element.offsetWidth < element.scrollWidth ||
            element.offsetHeight < element.scrollHeight
        : false
    );
  }, []);

  let productBrandName: string;
  let productOrigin: string;
  let productCaliber: string;
  let productBrixDaily: string = useStockForAttribute(attributes, "brixDaily");
  let coCompensated: string;
  let seedProof: string;
  let varietyFeature: string;
  let transportIconName: string;
  let isNewArticle: string;
  let deprecatedSku: string;
  let articleComposition: string;
  let frozen: string;
  let temperatureControlled: string;
  let bioBasicIcon: string;
  let descriptionFruitVegetable: string;
  let pfandpflichtId: string;
  let eanCode: string;

  if (attributes) {
    ({
      [productAttributes.brand]: productBrandName,
      [productAttributes.origin]: productOrigin,
      [productAttributes.caliber]: productCaliber,
      [productAttributes.coCompensatedIcon]: coCompensated,
      [productAttributes.seedProofIcon]: seedProof,
      [productAttributes.varietyFeature]: varietyFeature,
      [productAttributes.transportTypeIcon]: transportIconName,
      [productAttributes.isNewArticle]: isNewArticle,
      [productAttributes.deprecatedSku]: deprecatedSku,
      [productAttributes.articleComposition]: articleComposition,
      [productAttributes.frozen]: frozen,
      [productAttributes.temperature]: temperatureControlled,
      [productAttributes.bioBasicIcon]: bioBasicIcon,
      [productAttributes.descriptionFruitVegetable]: descriptionFruitVegetable,
      [productAttributes.pfandpflichtId]: pfandpflichtId,
      [productAttributes.eanCode]: eanCode,
    } = attributes);
  }

  const deprecatedSkuAttribute = isNewArticle === "1" && deprecatedSku;
  const showCoCompensatedIcon = coCompensated === "1";
  const showVarietyIcon = varietyFeature?.toLowerCase() === "cms-hybrid";
  const showSeedProofIcon = seedProof === "1" && !showVarietyIcon;
  const transportIcon =
    transportIcons[transportIconName as TransportType] !== "undefined"
      ? transportIcons[transportIconName as TransportType]
      : null;
  const showFrozenIcon = frozen && frozen === "1";
  const showTemperatureControlledIcon =
    temperatureControlled && temperatureControlled === "1";
  const showBioBasicIcon = bioBasicIcon && bioBasicIcon === "1";
  const pfandpflichtIcon =
    pfandpflichtIcons[pfandpflichtId as PfandpflichtIdType] !== "undefined"
      ? pfandpflichtIcons[pfandpflichtId as PfandpflichtIdType]
      : null;

  // add unit
  productBrixDaily = productBrixDaily
    ? `${productBrixDaily} ${brixUnit}`
    : productBrixDaily;

  const productAttributeDetailList = [
    sku,
    deprecatedSkuAttribute,
    productBrandName,
    productOrigin,
    productCaliber,
    productBrixDaily,
  ];

  const getAttributeDetailIconWithTooltip = (
    title: string,
    icon: ReactComponentElement<any>
  ) => (
    <span className="productAttributeDetail productAttributeDetailIcon">
      {" "}
      |{" "}
      <Tooltip
        title={title}
        getTooltipContainer={getContainer}
        trigger={["hover", "click"]}
        destroyTooltipOnHide
      >
        {icon}
      </Tooltip>
    </span>
  );

  const gridAreas = ["sku", "", "brandname"];

  return (
    <div className={clsx("productAttributes", className)}>
      <div className="product-attribute-grid">
        {productAttributeDetailList.map((currentAttribute, index) =>
          currentAttribute ? (
            <Tooltip
              placement="top"
              title={
                gridAreas[index] === "brandname" && isEllipsisActive
                  ? currentAttribute
                  : ""
              }
              key={index}
              destroyTooltipOnHide
            >
              <span
                ref={gridAreas[index] === "brandname" ? textRef : ""}
                className={`productAttributeDetail ${
                  gridAreas?.[index] || ""
                } `}
                key={currentAttribute}
              >
                {index > 0 && " | "}

                {currentAttribute === deprecatedSku && (
                  <del>{currentAttribute}</del>
                )}

                {currentAttribute === productBrandName && (
                  <Link
                    to={`${routePathNames.products}${routePathNames.search}?${
                      locationSearchQueryParameter.brandName
                    }=${encodeURIComponent(productBrandName)}`}
                  >
                    {productBrandName}
                  </Link>
                )}

                {currentAttribute?.endsWith(brixUnit) && (
                  <Tooltip
                    title="Ist-Brixwert Lagerbestand"
                    getTooltipContainer={getContainer}
                    destroyTooltipOnHide
                  >
                    {getProcessedProductAttributeValue({
                      attribute: currentAttribute,
                      deliveryDate,
                    })}
                  </Tooltip>
                )}

                {currentAttribute !== deprecatedSku &&
                  currentAttribute !== productBrandName &&
                  !currentAttribute?.endsWith(brixUnit) &&
                  getProcessedProductAttributeValue({
                    attribute: currentAttribute,
                    deliveryDate,
                  })}
              </span>
            </Tooltip>
          ) : null
        )}

        {showCoCompensatedIcon &&
          getAttributeDetailIconWithTooltip("CO2-kompensiert", <CO2 />)}

        {showSeedProofIcon &&
          getAttributeDetailIconWithTooltip("Samenfest", <Samenfest />)}

        {showVarietyIcon && (
          <span className="productAttributeDetail"> | CMS</span>
        )}

        {!!transportIcon &&
          getAttributeDetailIconWithTooltip("Transportweg", transportIcon)}

        {showBioBasicIcon &&
          getAttributeDetailIconWithTooltip(
            "bioladen*Basissortiment - Für dich, für jeden Tag.",
            <BioladenBasic />
          )}

        {showFrozenIcon &&
          getAttributeDetailIconWithTooltip("Tiefkühl", <Frozen />)}

        {showTemperatureControlledIcon &&
          getAttributeDetailIconWithTooltip(
            "Temperaturgeführt",
            <TemperatureControlled />
          )}

        {!!pfandpflichtIcon &&
          getAttributeDetailIconWithTooltip(
            `${pfandpflichtId}`,
            pfandpflichtIcon
          )}

        {view === "detail" && eanCode?.length > 0 && <div>EAN: {eanCode}</div>}
      </div>

      {(articleComposition || descriptionFruitVegetable) && (
        <div className="productAttributeDetail productAttributeComposition">
          <span className="productCompositionDescription">
            {articleComposition ? (
              <Tooltip
                title={isEllipsisActiveComposition ? articleComposition : ""}
                destroyTooltipOnHide
              >
                <span
                  className="innerProductAttributeComposition"
                  ref={textRefer}
                >
                  {articleComposition}
                </span>
              </Tooltip>
            ) : (
              <></>
            )}
            {articleComposition?.length && descriptionFruitVegetable?.length ? (
              <span className="seperator">|</span>
            ) : (
              <></>
            )}
            {descriptionFruitVegetable ? (
              <span>{descriptionFruitVegetable}</span>
            ) : (
              <></>
            )}
          </span>
        </div>
      )}

      {productInfo && (
        <div>
          <span className="productAttributeDetail">
            {getProcessedProductAttributeValue({
              attribute: productInfo,
              deliveryDate,
            })}
          </span>
        </div>
      )}
    </div>
  );
};

export default ProductAttributes;
