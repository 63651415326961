import { cartsEndpoint } from "../endpoints";
import axiosWithAuth from "../axiosWithAuth";
import { UpdateCartData } from "../../types/cartAPI";
import getDeliveryDateQuery from "../getDeliveryDateQuery";

/**
 * DELETE item in spryker cart
 * @param cancelTokenSource {CancelTokenSource}
 * @param deliveryDate {string}
 * @param sku {string}
 * @return {Promise<AxiosResponse>}
 */
const deleteItemInCart = ({
  cancelTokenSource,
  deliveryDate,
  sku,
}: UpdateCartData) =>
  axiosWithAuth()
    .delete(
      `${cartsEndpoint}/${deliveryDate}/items/${sku}${getDeliveryDateQuery(
        deliveryDate,
        true
      )}`,
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (response?.status !== 204) {
        return Promise.reject(response);
      }

      return response;
    });

export default deleteItemInCart;
