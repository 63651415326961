import React from "react";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoginHeader from "../LoginHeader";
import ResetPasswordForm from "./ResetPasswordForm";
import FooterLegalLinks from "../../navigation/FooterLegalLinks";
import SetPasswordForm from "./SetPasswordForm";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";

function UpdatePassword() {
  const { token } = useParams<{ token?: string }>();
  // determine if the route should be used to setup a password
  const setUserPassword = !!token;

  return (
    <>
      <Helmet>
        <title>
          {pageTitles.login} {pageTitleSuffix}
        </title>
      </Helmet>

      <div className="reset-password-component">
        <Row
          xs={12}
          md={3}
          className="login-header-row"
          gutter={[
            { xs: 8, sm: 16, md: 32, lg: 32 },
            { xs: 8, sm: 16, md: 32, lg: 32 },
          ]}
        >
          <Col xs={12} md={2}>
            <LoginHeader />
          </Col>
        </Row>

        <Row className="login-welcome">
          <Col span={12}>
            <h1 className="text-uppercase">
              Passwort <br className="login-welcome-break" />
              {setUserPassword ? "setzen" : "vergessen"}
            </h1>
          </Col>
        </Row>

        {setUserPassword ? <SetPasswordForm /> : <ResetPasswordForm />}

        <FooterLegalLinks navClassName="login-footer-menu" />
      </div>
    </>
  );
}

export default UpdatePassword;
