import { useEffect, useState } from "react";

import axios from "axios";
import { Entry } from "contentful";

import getImpulsaktionen from "../api/contentful/getImpulsaktionen";
import handleError from "../utils/handleError";
import { ContentfulImpulsaktionEntry } from "../types/contentfulImpulsaktion";

const useGetImpulsaktionen = (limit: number, skip: number) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<Entry<ContentfulImpulsaktionEntry>[]>(
    []
  );
  const [total, setTotal] = useState<number>(null);

  useEffect(() => {
    setIsLoading(true);
    getImpulsaktionen({ limit, skip })
      .then((response) => {
        setEntries(response.items);
        setTotal(response.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [limit, skip]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetImpulsaktionen;
