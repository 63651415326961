import axiosWithAuth from "../axiosWithAuth";
import { favouriteListEndpoint } from "../endpoints";
import { FetchUserLists } from "../../types/userLists";

/**
 * add product skus to favouritesLists
 * @param {CancelTokenSource} cancelTokenSource
 * @param {string[]} listNames
 * @param {string[]} skuList
 * @return {Promise<AxiosResponse>}
 */
const postAddProductsToFavouritesLists = ({
  cancelTokenSource,
  listNames = ["default"],
  skuList,
}: FetchUserLists) =>
  axiosWithAuth()
    .post(
      `${favouriteListEndpoint}default/favourites`,
      {
        data: {
          type: "favourites",
          attributes: {
            idFavouriteListList: listNames,
            skuList,
          },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (!response || response.status > 201) {
        Promise.reject(response);
      }

      return response;
    });

export default postAddProductsToFavouritesLists;
