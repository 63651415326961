import React, { useState } from "react";
import { Divider } from "antd";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";
import MediaModal from "./MediaModal";
import YoutubeVideo from "../video/YoutubeVideo";
import { NewsGalleryEntry } from "../../types/newsGalleryEntry";

interface Props {
  media: any[];
}

interface ModalData extends NewsGalleryEntry {
  visible: boolean;
}

const NewsGallery: React.FC<Props> = ({ media }: Props) => {
  const initialModalData: ModalData = {
    subtitle: "",
    type: null,
    url: "",
    visible: false,
  };
  const [modalData, setModalData] = useState(initialModalData);

  return (
    <div className="news-slider">
      <Splide
        className="slider-main"
        options={{
          rewind: true,
          width: "100%",
          start: 1,
          gap: "3%",
          pagination: false,
          breakpoints: {
            576: {
              padding: {
                right: "0%",
                left: "0%",
              },
            },
          },
          padding: {
            right: "17%",
            left: "17%",
          },
        }}
      >
        {media.map((currentEntry: NewsGalleryEntry) => {
          const { subtitle, type, url } = currentEntry;

          return (
            <SplideSlide className="video-slide" key={`${type}_${url}`}>
              {type === "video" && <YoutubeVideo title={subtitle} url={url} />}

              {type === "image" && (
                <figure>
                  <button
                    type="button"
                    className="buttonPlain"
                    onClick={() => {
                      setModalData({
                        subtitle,
                        type,
                        url,
                        visible: true,
                      });
                    }}
                  >
                    <img src={url} width="100%" alt={subtitle} />
                  </button>

                  <figcaption className="slider-subtitle">
                    {subtitle || <span>&nbsp;</span>}
                  </figcaption>
                </figure>
              )}
            </SplideSlide>
          );
        })}
      </Splide>

      <MediaModal
        alt={modalData.subtitle}
        onCancel={() => setModalData(initialModalData)}
        isVisible={modalData.visible}
        type={modalData.type}
        url={modalData.url}
      />

      <Divider />
    </div>
  );
};

export default NewsGallery;
