import React from "react";
import { Col } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as Share } from "../../static/svg/share.svg";
import MailToLink from "../MailToLink/MailToLink";
import formatDashedDateToLocale from "../../utils/formatDate";
import createNewsEntryVariables, {
  NewsEntryContentfulStructure,
} from "../../api/contentful/createNewsEntryVariables";
import { routePathNames } from "../../appConfig";
import renderContentfulDocument from "../../utils/renderContentfulDocument";

interface Props {
  newsEntry: NewsEntryContentfulStructure;
}

function NewsItem({ newsEntry }: Props) {
  const { id, title, publishDate, teaserText, imageTitle, url } =
    createNewsEntryVariables(newsEntry);

  const articleLink = `${routePathNames.article}${id}`;

  return (
    <Col xs={12} md={4}>
      <article className="newsArticle">
        {imageTitle && (
          <Link to={articleLink}>
            <img
              src={`${url}?fit=pad&w=500&h=200`}
              alt={title}
              className="newsArticleImage"
            />
          </Link>
        )}
        <div className="newsArticleMeta">
          {/* add icons here if ready */}
          <MailToLink
            className="newsArticleShareButton"
            subject={`Weiling Nachrichten: ${title}`}
            body={`Dieser Beitrag von Weiling wird dir weiterempfohlen: `}
            url={`${window.location.origin}${articleLink}`}
          >
            <Share className="icon" />
            <span className="shareText">teilen</span>
          </MailToLink>

          <time className="newsArticleDate">
            {`${formatDashedDateToLocale(publishDate)}`}
          </time>
        </div>

        <h3 className="newsArticleTitle">
          <Link to={articleLink}>{title}</Link>
        </h3>

        <div className="newsArticleContent">
          {renderContentfulDocument(teaserText)}
        </div>
      </article>
    </Col>
  );
}

export default NewsItem;
