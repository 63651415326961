import React from "react";

import clsx from "clsx";
import { Card } from "antd";

const { Meta } = Card;

interface TutorialVideoProps {
  title: string;
  videoSrc: string;
  className?: string;
}

const TutorialVideo: React.FC<TutorialVideoProps> = (props) => {
  const { title, videoSrc, className } = props;

  return (
    <div className={clsx("tutorial-video", className)}>
      <Card
        cover={
          <>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video width="100%" controls preload="metadata">
              <source src={`${videoSrc}#t=0.1`} type="video/mp4" />
            </video>
          </>
        }
        bordered={false}
      >
        <Meta title={title} />
      </Card>
    </div>
  );
};

export default TutorialVideo;
