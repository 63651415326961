import React, { useState } from "react";
import { useSelector } from "react-redux";
import CartReorderModalWrapper from "./CartReorderModalWrapper";

/**
 * move all cart items to another day via ReorderModal
 * @constructor
 */
const MoveCartToDate = function MoveCartToDate() {
  const { cartItems } = useSelector((state: any) => state.currentCart);
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <CartReorderModalWrapper
        cartItems={cartItems}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />

      <button
        type="button"
        className="button buttonPrimary buttonPrimary--inverted buttonAddProductsToNewCart"
        disabled={!cartItems?.length}
        onClick={() => setModalVisible(!modalVisible)}
      >
        Alle Artikel verschieben
      </button>
    </>
  );
};

export default MoveCartToDate;
