import React from "react";

import clsx from "clsx";
import moment from "moment/moment";

import { Col, Result, Row, Skeleton } from "antd";
import { Link } from "react-router-dom";

import { ContentBlock, OpenCartLine, OpenCartLineDetails } from "../../atoms";
import { CheapCartMetadata } from "../../../types/cheapCartMetadata";
import { routePathNames } from "../../../appConfig";
import { ReactComponent as Cart } from "../../../static/svg/cart.svg";
import HrDivider from "../../divider/Divider";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";

interface OpenCartsWidgetProps {
  title?: string;
  emptyText?: string;
  openCarts: Array<any>;
  isLoading: boolean;
  hasError: boolean;
  showHeader?: boolean;
  showCartDetails?: boolean;
  showDividerOnTop?: boolean;
  footer?: React.ReactNode;
  filler?: React.ReactNode;
  className?: string;
  singleColMode?: boolean;
}

const cartHighlightSettingClasses: Array<"" | "danger" | "warning"> = [
  "danger",
  "warning",
];

const OpenCartsWidget: React.FC<OpenCartsWidgetProps> = (props) => {
  const {
    title = "Warenkörbe",
    emptyText = "Es sind noch keine Warenkörbe vorhanden",
    openCarts,
    isLoading,
    hasError,
    showHeader = true,
    showCartDetails = false,
    showDividerOnTop = false,
    footer,
    filler,
    className,
    singleColMode = true,
  } = props;
  const isMobile = useMedia(`(max-width: ${getCssVariable("screen-xs-max")})`);
  // eslint-disable-next-line no-nested-ternary
  const colSize = singleColMode ? 12 : isMobile ? 12 : 6;

  return (
    <ContentBlock
      className={clsx(
        "open-cart-widget",
        footer && openCarts?.length === 1 && "open-cart-widget--full-height",
        !!footer && "has-footer",
        !!filler && "has-filler",
        className
      )}
      title={title}
      linkHref={routePathNames.carts}
      showLink={!!openCarts?.length && !isMobile}
      showHeader={showHeader}
    >
      {showDividerOnTop && <HrDivider size={2} />}

      <Row gutter={[10, 10]} className="openCartsFlex">
        {isLoading && (
          <Col span={12}>
            <Skeleton active />
          </Col>
        )}

        {!isLoading &&
          !hasError &&
          !!openCarts &&
          openCarts.map((data: CheapCartMetadata, index: number) => (
            <Col
              span={openCarts?.length === 1 && !filler ? 12 : colSize}
              key={data?.deliveryDate}
            >
              <OpenCartLine
                deliveryDate={moment(data?.deliveryDate)}
                totalItems={data?.items?.length || 0}
                lineType={
                  showCartDetails &&
                  (cartHighlightSettingClasses?.[index] || "")
                }
                header={
                  showCartDetails && (
                    <OpenCartLineDetails
                      deliveryDate={moment(data?.deliveryDate)}
                      totalQuantity={data?.items?.length || 0}
                      detailsType={cartHighlightSettingClasses?.[index] || ""}
                    />
                  )
                }
                totalCarts={openCarts?.length || 0}
                className={className}
              />
            </Col>
          ))}

        {!isLoading && !hasError && !!openCarts && !!filler && (
          <Col span={colSize}>{filler}</Col>
        )}

        {!isLoading &&
          !hasError &&
          !!openCarts &&
          openCarts?.length === 1 &&
          !!footer && (
            <Col span={12} className="open-cart-widget__footer">
              {footer}
            </Col>
          )}

        {!isLoading && !hasError && !!openCarts && !openCarts?.length && (
          <Col span={12} className="open-cart-widget__empty">
            <div className="open-cart-widget__empty__content">
              <Cart />
              {emptyText}
              <Link
                className="button buttonPrimary mt-m"
                to={routePathNames.shop}
              >
                Bestellung anlegen
              </Link>
            </div>
          </Col>
        )}

        {!isLoading && hasError && (
          <Col span={12}>
            <Result
              status="warning"
              className="widget-error pt-sm"
              title="Es konnten leider keine offenen Warenkörbe geladen werden."
            />
          </Col>
        )}
      </Row>
    </ContentBlock>
  );
};

export default OpenCartsWidget;
