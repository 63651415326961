import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  data: {
    icon: any;
    title: string;
    link: string;
    external?: boolean;
  };
  onClick: () => void;
}

function FoldoutMenuItem({ data, onClick }: Props) {
  const { icon, title, link, external } = data;

  const itemContent = (
    <>
      <span className="foldoutMenuItemIcon">{icon}</span>
      <span className="foldoutMenuItemTitle">{title}</span>
    </>
  );

  return external ? (
    <a
      href={link}
      rel="noopener noreferrer"
      className="foldoutMenuItemLink"
      target="_blank"
    >
      {itemContent}
    </a>
  ) : (
    <NavLink
      caseSensitive
      to={link}
      className={({ isActive }) =>
        isActive ? "foldoutMenuItemLink isActive" : "foldoutMenuItemLink"
      }
      onClick={onClick}
    >
      {itemContent}
    </NavLink>
  );
}

export default FoldoutMenuItem;
