import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Arrow } from "../../static/svg/arrow.svg";

interface Props {
  name: string;
  link: string;
  isLastItem: boolean;
  isProductDetail: boolean;
}

function BreadCrumbItem({ name, link, isLastItem, isProductDetail }: Props) {
  return (
    <li className="breadcrumbsItem">
      <Arrow className="breadcrumbsArrow" />

      {!isLastItem || isProductDetail ? (
        <NavLink to={link} className="breadcrumbsAnchor">
          {name}
        </NavLink>
      ) : (
        <span className="breadcrumbsLast">{name}</span>
      )}
    </li>
  );
}

export default BreadCrumbItem;
