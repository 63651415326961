import React, { useState } from "react";

import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, RadioChangeEvent, Row } from "antd";

import BackButton from "../../backButton/BackButton";
import QuickOrderForm from "./QuickOrderForm";
import QuickOrderItem from "./QuickOrderItem";
import QuickOrderUpload from "./QuickOrderUpload";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { ContentBlock, QuickOrderHeader } from "../../atoms";
import {
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../../appConfig";
import { ProductData } from "../../../types/productData";

interface QuickOrderProps {
  className?: string;
}

const QuickOrder: React.FC<QuickOrderProps> = (props: QuickOrderProps) => {
  const { className } = props;

  const { type = "" } = useParams<{
    type: "upload" | "";
  }>();

  const navigate = useNavigate();

  const { deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );

  const [product, setProduct] = useState<ProductData>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  return (
    <>
      <TrackingHelmet title={pageTitles.quickOrder} suffix={pageTitleSuffix} />

      <ShopCategoryNavigation />

      <ContentBlock
        showHeader={false}
        className={clsx("quick-order-page", className)}
      >
        <BackButton />

        <QuickOrderHeader
          type={type}
          onChange={(event: RadioChangeEvent) => {
            navigate(`${routePathNames.quickOrder}/${event.target.value}`);
          }}
        />

        <Row gutter={[10, 10]} className="mt-m">
          {type === "upload" ? (
            <>
              <Col span={12} className="mb-s">
                <QuickOrderUpload deliveryDate={deliveryDate} />
              </Col>
            </>
          ) : (
            <>
              <Col span={12} className="mb-s">
                <QuickOrderForm
                  deliveryDate={deliveryDate}
                  product={product}
                  setProduct={setProduct}
                  isSearching={isSearching}
                  setIsSearching={setIsSearching}
                />
              </Col>
              <Col span={12} className="mt-s">
                <QuickOrderItem
                  deliveryDate={deliveryDate}
                  product={product}
                  isSearching={isSearching}
                />
              </Col>
            </>
          )}
        </Row>
      </ContentBlock>
    </>
  );
};

export default QuickOrder;
