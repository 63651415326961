import React, { useRef, useState } from "react";

import clsx from "clsx";
import axios, { CancelTokenSource } from "axios";
import { Button, Col, Form, Input, message, Row, Space } from "antd";

import { Link } from "react-router-dom";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import postLoginRequest from "../../../api/postLoginRequest";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import LoginHeader from "../../userAuth/LoginHeader";
import FooterLegalLinks from "../../navigation/FooterLegalLinks";
import handleError from "../../../utils/handleError";
import {
  messageData,
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../../appConfig";
import TrackingHelmet from "../../Matomo/TrackingHelmet";

interface LoginRequestProps {
  className?: string;
}

const LoginRequest: React.FC = (props: LoginRequestProps) => {
  const { className } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = Form.useForm();
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const handleFormSubmit = () => {
    setIsLoading(true);
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    postLoginRequest({
      companyBusinessUnitKey: form.getFieldValue("companyBusinessUnitKey"),
      zipCode: form.getFieldValue("zipCode"),
      cancelTokenSource: cancelTokenSource.current,
    })
      .then((data: any) => {
        setIsLoading(false);
        message.success({
          content: (
            <>
              Ihre Anfrage auf einen Zugang zum Weiling-Portal wurde erfolgreich
              versendet.
              <br />
              {`${
                data?.contactPerson?.name || "Unsere Kundenbetreuung"
              } wird sich in Kürze mit Ihnen in Verbindung setzen.`}
              <br />
              Für die Kommunikation mit Ihnen nutzen wir folgende E-Mail
              Adresse: <strong>{data?.customerEmailAddress || ""}</strong>
            </>
          ),
          duration: 12,
        });
        form.resetFields();
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          message.error(messageData.error.auth.loginRequest);
          handleError(error);
        }
      });
  };

  return (
    <>
      <TrackingHelmet
        title={pageTitles.loginRequest}
        suffix={pageTitleSuffix}
      />

      <div
        className={clsx(
          "reset-password-component",
          "login-request-page",
          className
        )}
      >
        <Row
          xs={12}
          md={3}
          className="login-header-row"
          gutter={[
            { xs: 8, sm: 16, md: 32, lg: 32 },
            { xs: 8, sm: 16, md: 32, lg: 32 },
          ]}
        >
          <Col xs={12} md={2}>
            <LoginHeader />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <h1 className="text-uppercase">Zugang anfordern</h1>
            <p className="text-large">
              Sie sind bereits Weiling Kunde und möchten Zugang zum
              Weiling-Portal haben?
              <br /> Dann füllen Sie einfach das folgende Formular aus:
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} xl={5} xxl={3}>
            <Form
              form={form}
              autoComplete="off"
              onFinish={handleFormSubmit}
              layout="vertical"
            >
              <Space direction="vertical">
                <Form.Item
                  name="companyBusinessUnitKey"
                  rules={[
                    {
                      required: true,
                      message: "Bitte Kundennummer eingeben",
                    },
                    {
                      min: 3,
                      message: "Kundennummer ungültig",
                    },
                    {
                      max: 15,
                      message: "Kundennummer ungültig",
                    },
                  ]}
                >
                  <Input
                    placeholder="Kundennummer *"
                    className="antd-password defaultInputHeight"
                    disabled={isLoading}
                  />
                </Form.Item>
                <Form.Item
                  name="zipCode"
                  rules={[
                    {
                      required: true,
                      message: "Bitte Postleitzahl eingeben",
                    },
                    {
                      min: 4,
                      message: "Postleitzahl ungültig",
                    },
                    {
                      max: 5,
                      message: "Postleitzahl ungültig",
                    },
                  ]}
                >
                  <Input
                    placeholder="Postleitzahl *"
                    className="antd-password defaultInputHeight"
                    disabled={isLoading}
                  />
                </Form.Item>
              </Space>
              <Space className="login-request-page__form__footer">
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className="button buttonSecondary"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Zugang anfordern
                  </Button>
                </Form.Item>
                <div className="formLinks">
                  <Link to={routePathNames.login}>Zurück zum Login</Link>
                </div>
              </Space>
            </Form>
          </Col>
        </Row>

        <FooterLegalLinks navClassName="login-footer-menu" />
      </div>
    </>
  );
};

export default LoginRequest;
