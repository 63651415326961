import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import useRestoreScrollPosition from "./useRestoreScrollPosition";
import elementScrollToPosition from "../utils/elementScrollToPosition";
import { timeouts } from "../appConfig";
import { RootState } from "../types/rootState";

const useScrollToTop = (element: any) => {
  const restoreScrollPosition = useRestoreScrollPosition();

  const { x: pageX, y: pageY } = useSelector(
    (state: RootState) => state?.page?.scroll
  );

  const [shouldScrollToTop, setShouldScrollToTop] = useState<boolean>(false);

  useEffect(() => {
    if (shouldScrollToTop === false) {
      return;
    }

    if (pageX === 0 && pageY === 0) {
      setTimeout(
        () =>
          elementScrollToPosition({
            elementToScroll: element,
          }),
        timeouts.scrollToTop
      );
    }

    if (pageX > 0 || pageY > 0) {
      setTimeout(() => restoreScrollPosition(), timeouts.scrollToTop);
    }

    setShouldScrollToTop(false);
  }, [shouldScrollToTop, pageX, pageY, element, restoreScrollPosition]);

  return {
    shouldScrollToTop,
    setShouldScrollToTop,
  };
};

export default useScrollToTop;
