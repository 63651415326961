import React from "react";
import moment from "moment";
import { OrderNoteContent } from "../../../types/orderData";
import { dateFormat } from "../../../utils/dateFormats";

interface Props {
  note: OrderNoteContent;
}

const OrderNote = function OrderNote({ note }: Props) {
  const { cartNote, date } = note;

  /**
   * create heading string for note
   * @param {OrderNoteContent["date"]} unixDate
   */
  const createOrderNoteHeadingByDate = (unixDate: OrderNoteContent["date"]) =>
    `Notiz vom ${moment(unixDate * 1000).format(dateFormat)}`;

  return (
    <div className="orderNote">
      <h3 className="orderNoteTitle">{createOrderNoteHeadingByDate(date)}</h3>
      <span className="orderNoteContent">{cartNote}</span>
    </div>
  );
};

export default OrderNote;
