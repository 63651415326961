import contentfulClient from "./contentfulClient";

/**
 * retrieve an asset from the current contentful instance
 * @param assetId {string}
 */
const getContentfulAsset = (assetId: string) =>
  contentfulClient()
    .getAsset(assetId)
    .then((asset) => {
      if (!asset) {
        return Promise.reject(asset);
      }

      /*
       * the returned asset matches the default
       * object contentful structure of <Entry>
       */
      return asset;
    });

export default getContentfulAsset;
