import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Row, Col, Radio, Skeleton } from "antd";

import useGetPodcasts from "../../../hooks/useGetPodcasts";

import { RootState } from "../../../types/rootState";
import { Episode, Podcast, PodcastType } from "../../../types/podcast";
import { PodcastPlayer } from "../../molecules";

const PodcastTypes = {
  Academy: "academy",
  Consumer: "consumer",
};

function PodcastList() {
  const { consumer: consumerPodcast, academy: academyPodcast } = useSelector(
    (state: RootState) => state.podcasts
  );
  const [podcastType, setPodcastType] = useState<PodcastType>(
    PodcastTypes.Consumer as PodcastType
  );
  const [podcast, setPodcast] = useState<Podcast | null>(consumerPodcast);
  const isLoading = useGetPodcasts();

  useEffect(() => {
    if (podcastType === PodcastTypes.Academy) {
      setPodcast(academyPodcast);
    } else {
      setPodcast(consumerPodcast);
    }
  }, [podcastType, academyPodcast, consumerPodcast]);

  return (
    <div className="podcast-list">
      <div className="podcast-list__header">
        <Radio.Group
          defaultValue={podcastType}
          buttonStyle="solid"
          className="podcast-type-selector"
          size="large"
        >
          <Radio.Button
            className="podcast-single-selector"
            onClick={() => setPodcastType(PodcastTypes.Academy as PodcastType)}
            value={PodcastTypes.Academy}
          >
            Akademie Podcasts
          </Radio.Button>
          <Radio.Button
            className="podcast-single-selector"
            onClick={() => setPodcastType(PodcastTypes.Consumer as PodcastType)}
            value={PodcastTypes.Consumer}
          >
            bioladen Podcasts
          </Radio.Button>
        </Radio.Group>
      </div>
      <Row gutter={[24, 16]}>
        {isLoading && (
          <Col span={12}>
            <Skeleton active />
          </Col>
        )}
        {!isLoading &&
          podcast?.episodes?.length &&
          podcast?.episodes.map((episode: Episode) => (
            <Col key={episode.mp3Link} xs={12} lg={6}>
              <PodcastPlayer podcast={podcast} episode={episode} />
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default PodcastList;
