import { useMemo } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import queryString from "query-string";

import createWeekplannerDates from "../utils/createWeekplannerDates";
import getDeliveryDates from "../utils/getDeliveryDates";
import { locationSearchQueryParameter } from "../appConfig";
import { RootState } from "../types/rootState";

interface WeekplannerRequestDates {
  productsRequestDates: Array<string>;
  cartsRequestDates: Array<string>;
}

const useGetWeekplannerRequestDates = (): WeekplannerRequestDates => {
  const { search } = useLocation();

  const parsedSearch = queryString.parse(search);
  const {
    [locationSearchQueryParameter.deliveryDateFrom]: deliveryDateFromQuery,
    [locationSearchQueryParameter.deliveryDateTo]: deliveryDateToQuery,
  } = parsedSearch;

  const weekplannerDates = useSelector(
    (state: RootState) => state.weekplannerData.weekDates
  );
  const { deliveryDates: customerDeliveryDates = [] } = useSelector(
    (state: RootState) => state?.userData || {}
  );
  const { deliveryDates: businessUnitDeliveryDates = [] } = useSelector(
    (state: RootState) => state?.userData?.businessUnit || {}
  );

  const deliveryDates = useMemo(
    () => getDeliveryDates(customerDeliveryDates, businessUnitDeliveryDates),
    [customerDeliveryDates, businessUnitDeliveryDates]
  );

  const requestDates = useMemo(() => {
    const deliveryDateFromQueryString = String(deliveryDateFromQuery);

    // if the url date is set and differs from the weekplanner dates
    if (
      deliveryDateFromQuery &&
      deliveryDateToQuery &&
      moment(weekplannerDates[0]?.apiDate) <=
        moment(deliveryDateFromQueryString)
    ) {
      return createWeekplannerDates(moment(deliveryDateFromQueryString)).map(
        (dates: any) => dates.apiDate
      );
    }

    return weekplannerDates.map((dates: any) => dates.apiDate);
  }, [weekplannerDates, deliveryDateFromQuery, deliveryDateToQuery]);

  const productsRequestDates = useMemo(() => {
    if (!deliveryDates?.length) {
      return requestDates;
    }
    return requestDates?.filter(
      (dateString: string) =>
        deliveryDates.includes(String(moment(dateString).day())) ||
        // always include saturday (to catch case when all delivery dates are in the past)
        String(moment(dateString).day()) === "6"
    );
  }, [requestDates, deliveryDates]);

  const cartsRequestDates = useMemo(() => {
    if (!deliveryDates?.length) {
      return requestDates;
    }

    const dateRange = [
      Math.min(...deliveryDates.map((day: string) => Number(day))),
      Math.max(...deliveryDates.map((day: string) => Number(day))),
    ];

    return requestDates?.filter((dateString: string) =>
      dateRange.includes(moment(dateString).day())
    );
  }, [deliveryDates, requestDates]);

  return {
    productsRequestDates,
    cartsRequestDates,
  };
};

export default useGetWeekplannerRequestDates;
