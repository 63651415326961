import React from "react";
import Price from "../components/price/Price";

interface PriceProps {
  currentProductGroupList: Record<string, any>;
  currentTotalPrice: number;
}

/**
 * calculates the total group price based on the current product group list and the increasing value
 * @param {Object} currentProductGroupList
 * @param {number} currentTotalPrice
 * @returns {Object}
 */
const calculateTotalGroupPrice = ({
  currentProductGroupList = {},
  currentTotalPrice,
}: PriceProps) => {
  const total = currentProductGroupList?.totalGroupSum || 0;
  const totalPrice = total + currentTotalPrice;

  return {
    ...currentProductGroupList,
    total: (
      <div className="sumPrice">
        <Price price={totalPrice} tag="span" className="text-bold totalPrice" />
      </div>
    ),
    totalGroupSum: totalPrice,
  };
};

export default calculateTotalGroupPrice;
