import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Result, Button, Row, Col, Skeleton } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import useWorkshops from "../../../hooks/useWorkshops";
import { RootState } from "../../../types/rootState";

import { ReactComponent as Additional } from "../../../static/svg/moodleSVGs/weiling_moodle_additional_qualifications.svg";
import { ReactComponent as ActiveService } from "../../../static/svg/moodleSVGs/weiling_moodle_active_service_sales.svg";
import { ReactComponent as Effective } from "../../../static/svg/moodleSVGs/weiling_moodle_effective_company_leadership.svg";
import { ReactComponent as MerchKnowledge } from "../../../static/svg/moodleSVGs/weiling_moodle_merchandise_knowledge.svg";

export default function Seminar() {
  const [title, setTitle] = useState<string>("");
  const [mapData, setMapData] = useState([]);
  const [hasChild, setHasChild] = useState<boolean>(false);
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const [redirectTarget, setRedirectTarget] = useState<string>("");

  const { courses, courseCategories } = useSelector(
    (state: RootState) => state.moodle || []
  );

  const filteredCourseCategories = useMemo(
    () =>
      courseCategories?.filter(
        (category: any) => category.parent === 0 && category.id !== 13
      ),
    [courseCategories]
  );

  const isLoading = useWorkshops();
  let svgMatch = false;

  useEffect(() => {
    setMapData(filteredCourseCategories);
    return () => {};
  }, [filteredCourseCategories]);

  const renderRedirect = () => {
    if (shouldRedirect) {
      window.location.href = redirectTarget;
    }
  };

  const seminarSVGs = [
    { id: 7, svg: <MerchKnowledge className="seminar-svg" /> },
    { id: 8, svg: <ActiveService className="seminar-svg" /> },
    { id: 9, svg: <Effective className="seminar-svg" /> },
    { id: 16, svg: <Additional className="seminar-svg" /> },
  ];

  renderRedirect();

  return (
    <Row>
      {isLoading ? (
        <Col span={12}>
          <Skeleton active />
        </Col>
      ) : (
        <Col className="seminar" span={12}>
          {!!hasChild && (
            <Row>
              <Button
                type="link"
                onClick={() => {
                  setMapData(filteredCourseCategories);
                  setHasChild(!hasChild);
                  setTitle("");
                }}
                className="back-button"
              >
                <ArrowLeftOutlined className="icon" />
                <p>Zurück</p>
              </Button>
            </Row>
          )}
          <Row>
            <h2>{title}</h2>
          </Row>
          <Row className="seminar-topic-list" gutter={[24, 16]}>
            {mapData.length > 0 ? (
              mapData.map((curr: any) => {
                const seminarTitle =
                  curr?.name?.replace("&amp;", "&") ||
                  curr?.displayname?.replace("&amp;", "&");

                return (
                  <Col xs={12} md={6} key={curr.id} style={{ width: "100%" }}>
                    <button
                      type="button"
                      className="seminar-tile like-h3 inverted"
                      onClick={() => {
                        if (curr.parent === 0) {
                          setHasChild(!hasChild);
                          const newData: any[] = [];
                          setTitle(seminarTitle);

                          if (curr.id === 16) {
                            courses.forEach((y: any) => {
                              if (y.categoryid === 16) {
                                newData.push(y);
                                setMapData(newData);
                              }
                            });
                          }

                          courseCategories.forEach((x: any) => {
                            if (x.parent === curr.id) {
                              newData.push(x);
                              setMapData(newData);
                            }
                          });
                        } else {
                          if (curr.categoryid) {
                            setRedirectTarget(
                              `https://e.weiling-akademie.de/course/index.php?categoryid=${curr.categoryid}`
                            );
                          } else {
                            setRedirectTarget(
                              `https://e.weiling-akademie.de/course/index.php?categoryid=${curr.id}`
                            );
                          }
                          setShouldRedirect(true);
                        }
                      }}
                    >
                      {seminarSVGs.map((svg: any) => {
                        if (svg.id === curr.id) {
                          svgMatch = true;
                          return (
                            <Fragment key={svg.id}>
                              <div className="seminar-tile-left">{svg.svg}</div>
                              <div className="seminar-tile-right">
                                {seminarTitle}
                              </div>
                            </Fragment>
                          );
                        }

                        return null;
                      })}
                      {!svgMatch ? (
                        <div className="seminar-tile-right tile-nosvg like-h3 inverted">
                          {seminarTitle}
                        </div>
                      ) : null}
                    </button>
                  </Col>
                );
              })
            ) : (
              <Result
                status="warning"
                title="Seminar Themen konnten nicht geladen werden."
                className="seminar-error"
                extra={
                  <Button type="primary" key="console">
                    Neu Laden
                  </Button>
                }
              />
            )}
          </Row>
        </Col>
      )}
    </Row>
  );
}
