// Library imports
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Popover, Row } from "antd";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Nutzer } from "../../static/svg/account.svg";
import { ReactComponent as Sortiment } from "../../static/svg/productlist.svg";
import { ReactComponent as WeilingBottom } from "../../static/svg/weiling_bottom_bar_aktiv.svg";
import { routePathNames, categoryPanelData } from "../../appConfig";
import ContactPerson from "../contactPerson/ContactPerson";
import { ReactComponent as Phone } from "../../static/svg/phone.svg";
import UserProfile from "../userProfile/UserProfile";
import useSetCategoryNavigationVisibility from "../../hooks/useSetCategoryNavigationVisibility";
import useGetContactPerson from "../../hooks/useGetContactPerson";
import HrDivider from "../divider/Divider";
import { ExpertAdviceNavLink } from "../atoms";
import { PricesSwitch } from "../molecules";

function MobileFooter() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const setCategoryNavigationVisibility = useSetCategoryNavigationVisibility();
  const contactPerson = useGetContactPerson();

  const isCart = pathname.includes(routePathNames.cart);

  const [isContactPersonVisible, setIsContactPersonVisible] = useState(false);
  const [isAccountVisisble, setIsAccountVisible] = useState(false);

  const { isVisible } = useSelector((state: any) => state.categoryNavigation);

  /**
   * set panel states
   * @param categoryTitle {string}
   * @param categoryRoute {string}
   */
  const setCategoryPanelData = (
    categoryTitle: string,
    categoryRoute: string
  ) => {
    dispatch({
      type: "categories/set-active-routing",
      payload: {
        categoryTitle,
        categoryRoute,
      },
    });
    setIsContactPersonVisible(false);
    setIsAccountVisible(false);
    setCategoryNavigationVisibility(!isVisible);
  };

  const handleNavigationClick = () => {
    if (pathname.includes(routePathNames.weekPlanner)) {
      setCategoryPanelData(
        categoryPanelData.weekPlanner[0],
        categoryPanelData.weekPlanner[1]
      );
    } else {
      setCategoryPanelData(
        categoryPanelData.products[0],
        categoryPanelData.products[1]
      );
    }
  };

  const handleContactPersonClick = (e: any) => {
    e.preventDefault();
    if (isVisible) {
      setCategoryNavigationVisibility(false);
    }
    setIsAccountVisible(false);
    setIsContactPersonVisible(!isContactPersonVisible);
  };

  const handleSortimentClick = (e: any) => {
    e.preventDefault();
    handleNavigationClick();
  };

  const handleAccountClick = (e: any) => {
    e.preventDefault();
    if (isVisible) {
      setCategoryNavigationVisibility(false);
    }
    setIsContactPersonVisible(false);
    setIsAccountVisible(!isAccountVisisble);
  };

  const handleOverlayClick = () => {
    if (isAccountVisisble) {
      setIsAccountVisible(!isAccountVisisble);
    }

    if (isContactPersonVisible) {
      setIsContactPersonVisible(!isContactPersonVisible);
    }
  };

  useEffect(() => {
    if (isAccountVisisble || isContactPersonVisible) {
      document.querySelector("body").classList.add("body-overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("body-overflow-hidden");
    }
  });

  return (
    <>
      {(isContactPersonVisible || isAccountVisisble) && (
        <div
          tabIndex={0}
          className="tooltipMask"
          role="button"
          onClick={handleOverlayClick}
          onKeyDown={handleOverlayClick}
        />
      )}

      <Row
        className={clsx(
          "mobileFooterWrapper",
          isCart && "mobileFooterWrapperHasCheckout"
        )}
      >
        <Col span={12}>
          <div
            className={clsx(
              "mobileFooter",
              !isContactPersonVisible &&
                !isAccountVisisble &&
                isVisible &&
                "mobileFooterCategoriesVisible"
            )}
          >
            <Popover
              overlayClassName="ant-popover-placement-bottomRight contactPersonFooter mt-sm hidden-sm-up"
              placement="topLeft"
              destroyTooltipOnHide
              content={
                <UserProfile
                  setIsAccountVisible={setIsAccountVisible}
                  isFooterComponent
                />
              }
              trigger="click"
              visible={isAccountVisisble}
            >
              <button
                type="button"
                className={clsx(
                  "buttonPlain",
                  "mobileFooterCol",
                  isAccountVisisble && "activeNavItem1"
                )}
                onClick={handleAccountClick}
              >
                <WeilingBottom className="backgroundItem" />
                <div className="footerIconDiv">
                  <div className="iconContainer">
                    <Nutzer className="icon iconUser" />
                  </div>
                </div>
                <p>Konto</p>
              </button>
            </Popover>

            <button
              type="button"
              className={clsx(
                "buttonPlain",
                "mobileFooterCol",
                !isContactPersonVisible &&
                  !isAccountVisisble &&
                  isVisible &&
                  "activeNavItem2"
              )}
              id="product-footer"
              onClick={handleSortimentClick}
            >
              <WeilingBottom className="backgroundItem" />
              <div className="footerIconDiv">
                <div className="iconContainer">
                  <Sortiment className="icon iconSortiment" />
                </div>
              </div>
              <p>Sortiment</p>
            </button>

            <Popover
              overlayClassName="ant-popover-placement-bottomRight contactPersonFooter mt-sm hidden-sm-up"
              placement="topRight"
              content={
                <>
                  <ContactPerson
                    contactPerson={contactPerson}
                    setIsContactPersonVisible={setIsContactPersonVisible}
                    isFooterComponent
                  />
                  <HrDivider className="divider" size={1} spacingTop="xs" />
                  <ExpertAdviceNavLink />
                </>
              }
              trigger="click"
              visible={isContactPersonVisible}
            >
              <button
                type="button"
                className={clsx(
                  "buttonPlain",
                  "mobileFooterCol",
                  isContactPersonVisible && "activeNavItem4"
                )}
                onClick={handleContactPersonClick}
              >
                <WeilingBottom className="backgroundItem" />
                <div className="footerIconDiv">
                  <div className="iconContainer">
                    <Phone className="icon iconConsultant" />
                  </div>
                </div>
                <p>Kontakt</p>
              </button>
            </Popover>

            <div className="mobileFooterCol">
              <div className="footerIconDiv">
                <div className="iconContainer">
                  <PricesSwitch />
                </div>
              </div>
              <p>Preise</p>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default MobileFooter;
