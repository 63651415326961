import React, { useEffect, useRef, useState } from "react";
import { Input, AutoComplete, Spin } from "antd";
import { useSelector } from "react-redux";
import { CancelTokenSource } from "axios";
import createSuggestions from "../../utils/suggest/createSuggestions";
import useCancelAxiosOnUnmount from "../../hooks/useCancelAxiosOnUnmount";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../api/getCancelTokenSource";
import { SuggestItem } from "../../types/suggestItem";

interface QuickSelectProductInputProps {
  isLoading: boolean;
  onSubmitValue: (value: string) => Promise<void>;
  checkIfAlreadyOnList?: (sku: string) => boolean;
  isAlreadyOnListWarning?: string;
  isDisabled?: boolean;
  type?: SuggestItem;
}

/**
 * quick selection input for adding products to a favourite list
 * @constructor
 */
const QuickSelectProductInput: React.FC<QuickSelectProductInputProps> = ({
  isLoading,
  onSubmitValue,
  checkIfAlreadyOnList,
  isAlreadyOnListWarning,
  isDisabled,
  type = "quickOrder",
}: QuickSelectProductInputProps) => {
  const { deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const preventClosingRef = useRef<boolean>(false);

  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [options, setOptions] = useState<null | any[]>(null);
  const [selectedItemSku, setSelectedItemSku] = useState<string>("");
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(true);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);

  /**
   * reset all states to initial values
   */
  const resetComponentStates = () => {
    inputRef?.current?.blur();
    buttonRef?.current?.blur();

    setSelectedItemSku("");
    setOptions(null);
    setButtonIsDisabled(true);
  };

  const onSubmit = (value?: any) => {
    const sku = typeof value === "string" ? value : String(selectedItemSku);
    onSubmitValue(sku).then(resetComponentStates);
  };

  /**
   * update input and button
   * @param {string} value
   */
  const updateComponentStates = (value: string) => {
    setSelectedItemSku(value);
  };

  /**
   * input change handler
   * @param {string} value
   */
  const onSearch = (value: string) => {
    updateComponentStates(value);
    setButtonIsDisabled(true);

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    if (value.length >= 3) {
      // create suggestions and use created result for states
      createSuggestions({
        query: value,
        deliveryDate,
        cancelTokenSource: cancelTokenSource.current,
        checkIfAlreadyOnList,
        type,
        isAlreadyOnListWarning,
      }).then((result: any) => {
        setOptions(result);

        // Skip disabling button on price tag list when user tries to add multiple products at once
        if (type === "priceTagList" && value.includes(",")) {
          setButtonIsDisabled(false);
          return;
        }

        /*
         * disable the button based on the following conditions:
         * if:
         * - all results are not available
         * - all entries do not match the input value
         * - isDisabled property is true
         */
        const isButtonDisabled = result
          ? result.every((resultEntry: any) => {
              return resultEntry.disabled || resultEntry.value !== value;
            }) || isDisabled
          : true;

        // set button disabled if no value as input or the options array has only 1 or zero entries
        setButtonIsDisabled(isButtonDisabled);
      });
    }
  };

  /**
   * handler for click event if user selects entry
   * @param {string} value
   */
  const onSelect = (value: string) => {
    updateComponentStates(value);

    onSubmit(value);
  };

  /**
   * event listener on scroll to close autocomplete list
   */
  useEffect(() => {
    const onScroll = () => {
      if (selectedItemSku.length > 0) {
        resetComponentStates();
      }
    };

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [selectedItemSku]);

  useEffect(() => {
    setIsClosing(false);
    preventClosingRef.current = false;
  }, [isInputFocused]);

  return (
    <div className="quickOrder" ref={containerRef}>
      <AutoComplete
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
        ref={inputRef}
        value={selectedItemSku}
        disabled={isLoading}
        open={selectedItemSku.length > 0 && isInputFocused}
        notFoundContent="Keine Produkte gefunden."
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        onMouseLeave={() => {
          if (isInputFocused) {
            setIsClosing(true);
          }
          setTimeout(() => {
            if (preventClosingRef.current === false) {
              setIsInputFocused(false);
              inputRef?.current?.blur();
            }
            preventClosingRef.current = false;
          }, 500);
        }}
        onMouseEnter={() => {
          if (isInputFocused && isClosing) {
            preventClosingRef.current = true;
          }
        }}
        // set the minWidth to the parent container width or 280 to display the product name inside the dropdown
        dropdownStyle={{
          minWidth: containerRef.current?.clientWidth || 280,
        }}
      >
        <Input size="large" placeholder="Artikelnr." />
      </AutoComplete>

      <button
        type="button"
        className="button buttonPrimary"
        disabled={buttonIsDisabled || isLoading}
        onClick={onSubmit}
        ref={buttonRef}
      >
        <Spin spinning={isLoading}>Hinzufügen</Spin>
      </button>
    </div>
  );
};

export default QuickSelectProductInput;
