import { Entry } from "contentful";
import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import { ContentfulWebshopCategoryEntry } from "../../types/contentfulWebshopCategoryEntry";

/**
 * wrapper to fetch all webshop categories
 * @returns {Promise<ContentfulCategoryEntry>}
 */
const getAllWebshopCategories = async (): Promise<
  ContentfulWebshopCategoryEntry[]
> => {
  return getContentfulContentType(contentfulContentTypes.webshopCategory).then(
    (response: any) => {
      if (!response) {
        Promise.reject(response);
      }

      return (
        response?.map((categoryEntry: Entry<any>) => {
          const {
            fields: { icon, ...rest },
            sys: { id },
          } = categoryEntry;

          return {
            icon,
            id,
            ...rest,
          } as ContentfulWebshopCategoryEntry;
        }) || []
      );
    }
  );
};

export default getAllWebshopCategories;
