import React, { BaseSyntheticEvent } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { Space } from "antd";
import { Entry } from "contentful";

import downloadContenfulFile from "../../../utils/downloadContenfulFile";
import useGetCategoryIds from "../../../hooks/useGetCategoryIds";
import useGetWebshopCategoryDownloads from "../../../hooks/useGetWebshopCategoryDownloads";
import { getTargetFromAsset } from "../../pages/Impulse/utils";
import { ContentfulWebshopCategoryDownloadEntry } from "../../../types/contentfulWebshopCategoryDownloadEntry";
import { RootState } from "../../../types/rootState";
import DefaultImage from "../../../static/images/default-product-image.jpg";

interface WebshopCategoryDownloadProps {
  categoryKey: string;
  limit?: number;
  className?: string;
}

const WebshopCategoryDownloads: React.FC<WebshopCategoryDownloadProps> = (
  props: WebshopCategoryDownloadProps
) => {
  const { categoryKey, limit = 3, className } = props;

  const { stockName = "HL" } = useSelector(
    (state: RootState) => state?.userData
  );

  const { categoryIds } = useGetCategoryIds({ categoryKey });

  const { entries: webshopCategoryDownloads, isLoading } =
    useGetWebshopCategoryDownloads({
      categoryIds,
      stockName,
      limit,
    });

  if (isLoading || !webshopCategoryDownloads?.length) {
    return null;
  }

  return (
    <Space className={clsx("webshop-category-downloads", className)}>
      {webshopCategoryDownloads.map(
        (
          webshopCategoryDownload: Entry<ContentfulWebshopCategoryDownloadEntry>
        ) => (
          <Space
            key={webshopCategoryDownload.sys.id}
            onClick={(e: BaseSyntheticEvent) => {
              downloadContenfulFile(
                e,
                webshopCategoryDownload?.fields?.file,
                getTargetFromAsset(webshopCategoryDownload?.fields?.file)
              );
            }}
            className="webshop-category-downloads__download"
          >
            <img
              src={
                webshopCategoryDownload?.fields?.preview_image?.fields?.file
                  ?.url || DefaultImage
              }
              alt={webshopCategoryDownload.fields.title}
            />
            <div>{webshopCategoryDownload.fields.title}</div>
          </Space>
        )
      )}
    </Space>
  );
};

export default React.memo(WebshopCategoryDownloads);
