/**
 * aggregate product data
 * iterates over the GLUE API return array to match and map the data based on the product id
 * tries to keep naming aligned to GLUE API
 * and manipulation to one function for all
 * @param productData {Array}
 * @returns {Array}
 */
const aggregateProductData = (productData: any) => {
  if (!Array.isArray(productData) || productData.length === 0) {
    return [];
  }

  // construct return Array
  return productData
    .filter(
      (productEntry: any) =>
        productEntry.type.toLowerCase() === "concrete-products"
    )
    .map((currentProduct: any) => {
      const {
        // save attributes from concrete products object
        attributes: concreteProductAttributes,
        id,
      } = currentProduct;

      /*
       * variables to aggregate product data
       * prices var for a more reliable and consistent data structure, the prices itself are rebuild based on the API data
       */
      let imageSets: any = [];
      let items: any = [];
      let availabilities: any = [];
      let prices: any = {};

      // get all entries belonging to the concrete product by id
      productData
        .filter(
          (filterProductEntry: any) =>
            filterProductEntry.id === id &&
            filterProductEntry.type.toLowerCase() !== "concrete-product"
        )
        .forEach((relatedProductEntry: any) => {
          const {
            type: relatedProductType,
            attributes: relatedProductAttributes,
          } = relatedProductEntry;

          const relatedProductTypeLower = relatedProductType.toLowerCase();

          // get the availabilities object
          if (relatedProductTypeLower === "concrete-product-availabilities") {
            availabilities =
              relatedProductAttributes?.concreteProductAvailabilityAttributes ||
              relatedProductAttributes;
          }

          if (relatedProductTypeLower === "concrete-product-image-sets") {
            // save imageSets by destructuring method
            ({ imageSets } = relatedProductAttributes);
          }

          if (relatedProductTypeLower === "items") {
            // save attributes from items object
            items = relatedProductAttributes;
          }

          if (relatedProductTypeLower === "concrete-product-prices") {
            ({ prices } = relatedProductAttributes);
          }
        });

      // create product entry
      return {
        id,
        ...concreteProductAttributes,
        imageSets,
        availabilities,
        deadline: items?.deadline,
        quantity: parseInt(items?.quantity, 10) || null,
        calculations: items?.calculations,
        prices,
      };
    });
};

export default aggregateProductData;
