import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { getScannerProductEndpoint } from "../endpoints";
import getCatalogSearchAttributesData from "../products/getCatalogSearchAttributesData";
import storeDispatch from "../../state/actions/storeDispatch";

/**
 * get product by an ean code
 * @param eanCode {string}
 * @param deliveryDate {string}
 * @param cancelTokenSource {CancelTokenSource}
 */
const getProductByEan = (
  eanCode: string,
  deliveryDate: string,
  cancelTokenSource: CancelTokenSource
) => {
  const url = getScannerProductEndpoint(deliveryDate, eanCode);

  return axiosWithAuth()
    .get(url, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response?.data?.data || response?.status > 200) {
        return Promise.reject(response);
      }

      const attributes = getCatalogSearchAttributesData(response?.data?.data);

      /*
       * store attributeNames to redux in case the user was never on a products listing before
       * prevents failure of mapping attributes
       */
      storeDispatch("products/set-attribute-names", attributes?.attributeNames);

      // return only the product array itself
      return attributes?.concreteProducts;
    });
};

export default getProductByEan;
