/**
 * aggregate  CompanyUserData
 * iterates over the user Data  array and adds includes
 * @param userData {Array}
 * @param includesData {Array}
 * @returns {Array}
 */
const aggregateCompanyUserData = (userData: any, includesData: any) => {
  if (!Array.isArray(userData) || userData.length === 0) {
    return [];
  }
  // construct return Array
  return userData.map((currentUser: any) => {
    const { attributes, id } = currentUser;
    let moreAttributes = [];
    if (includesData.length > 0) {
      moreAttributes = includesData?.filter(
        (include: Record<string, any>) =>
          include.id === currentUser.relationships.customers.data[0].id
      )[0]?.attributes;
    }

    // create user entry
    return {
      id,
      ...attributes,
      ...moreAttributes,
      uuid: currentUser?.relationships?.customers
        ? currentUser.relationships.customers.data[0].id
        : id,
    };
  });
};

export default aggregateCompanyUserData;
