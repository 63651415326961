import React, { useState } from "react";
import { Form, Input, Col, Row, message } from "antd";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Helmet } from "react-helmet";
import moment from "moment/moment";
import getAccessToken from "../../api/auth/getAccessToken";
import FormSubmitFooter from "./FormSubmitFooter";
import { messageData, pageTitles, pageTitleSuffix } from "../../appConfig";
import getUserData from "../../api/getUserData";
import requestCatchHandler from "../../api/requestCatchHandler";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import updateWeekplannerDates from "../../state/actions/updateWeekplannerDates";

const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  const [postLoading, setPostLoading] = useState(false);

  // cancelToken for consistency, but you do not want to cancel those calls
  const cancelTokenSource = getCancelTokenSource();

  // Function gets fired on Form Submit
  const onFinish = async (values: any) => {
    setPostLoading(true);

    return getAccessToken(values.username, values.password)
      .then((response) => {
        /*
         * store the access token data
         */
        dispatch({
          type: "auth/set-token",
          payload: response,
        });
        updateWeekplannerDates(moment());
        message.success(messageData.success.auth.login);
      })
      .then(() => getUserData(cancelTokenSource))
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setPostLoading(false);
          if (error.response.status === 401)
            message.error(messageData.error.auth.login);
          else message.error(messageData.error.auth.getBusinessUnit);
        }

        requestCatchHandler(error);
      });
  };

  return (
    <>
      <Helmet>
        <title>
          {pageTitles.login} {pageTitleSuffix}
        </title>
      </Helmet>

      <Row>
        <Col xs={12} md={6} xl={5} xxl={3}>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            role="form"
          >
            <Row gutter={[0, 8]}>
              <Col span={12}>
                <Form.Item
                  name="username"
                  hasFeedback
                  validateStatus=""
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Bitte gültige E-Mail Adresse eingeben!",
                    },
                  ]}
                >
                  <Input
                    placeholder="E-Mail *"
                    className="defaultInputHeight"
                    autoComplete="username"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="password"
                  hasFeedback
                  rules={[
                    { required: true, message: "Bitte Passwort eingeben!" },
                  ]}
                >
                  <Input.Password
                    placeholder="Passwort *"
                    className="antd-password defaultInputHeight"
                    autoComplete="current-password"
                  />
                </Form.Item>
              </Col>
            </Row>

            <FormSubmitFooter
              isLoading={postLoading}
              submitButtonText="anmelden"
              linkTo="password"
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default LoginForm;
