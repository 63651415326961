import React from "react";

import moment from "moment/moment";
import { Tag } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AlignType } from "rc-table/lib/interface";

import { Link } from "react-router-dom";
import localizedNumberFormat, {
  parsePriceValueToLocalPrice,
} from "../../../../utils/localizedNumberFormat";
import { fullDateFormat } from "../../../../utils/dateFormats";
import { routePathNames } from "../../../../appConfig";
import { ReactComponent as Edit } from "../../../../static/svg/edit.svg";

export const columns: any[] = [
  {
    title: "Inventurdatum",
    width: 150,
    dataIndex: "referenceDate",
    align: "left" as AlignType,
    render: (referenceDate: string) =>
      moment(referenceDate).format(fullDateFormat),
  },
  {
    title: "Status",
    width: 100,
    dataIndex: "status",
    align: "center" as AlignType,
    render: (status: string) => {
      const isActive = status === "active";
      return (
        <Tag color={isActive ? "success" : "default"}>
          {isActive ? "Laufend" : "Beendet"}
        </Tag>
      );
    },
  },
  {
    title: "Artikel",
    width: 100,
    dataIndex: "totalItems",
    align: "center" as AlignType,
    render: (totalItems: string) => totalItems,
    responsive: ["md"],
  },
  {
    title: "Notiz",
    width: 100,
    dataIndex: "note",
    align: "left" as AlignType,
    render: (note: number) => note || "-",
    ellipsis: true,
    onCell: () => {
      return {
        style: {
          whiteSpace: "nowrap",
          maxWidth: 200,
        },
      };
    },
  },
  {
    title: "",
    dataIndex: "idStocktaking",
    align: "right" as AlignType,
    width: 100,
    render: (idStocktaking: number) => {
      return (
        <>
          <Link
            to={`${routePathNames.stocktaking}/${idStocktaking}`}
            className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText"
            title="Details zur Inventur ansehen"
            role="button"
          >
            <Edit className="icon" />
          </Link>
        </>
      );
    },
  },
];

export const subColumns: any[] = [
  {
    title: "Benutzer",
    align: "left" as AlignType,
    render: (_: any, record: any) => {
      const userName =
        `${record?.customer?.firstName} ${record?.customer?.lastName}`.trim();

      return userName || "Unbekannt";
    },
    ellipsis: true,
    onCell: () => {
      return {
        style: {
          whiteSpace: "nowrap",
          maxWidth: 200,
        },
      };
    },
    responsive: ["md"],
  },
  {
    title: "EAN",
    dataIndex: "eanCode",
    align: "left" as AlignType,
    render: (eanCode: string) => eanCode || "-",
    responsive: ["md"],
  },
  {
    title: "Art.-Nr.",
    dataIndex: "sku",
    align: "left" as AlignType,
    render: (sku: string) => sku || "-",
  },
  {
    title: "Bezeichnung",
    dataIndex: "label",
    width: 10,
    align: "left" as AlignType,
    render: (label: string) => label || "-",
    ellipsis: true,
    onCell: () => {
      return {
        style: {
          whiteSpace: "nowrap",
          maxWidth: 200,
        },
      };
    },
  },
  {
    title: "EK-Verp.",
    dataIndex: "unitVpe",
    align: "left" as AlignType,
    render: (unitVpe: string) => unitVpe || "-",
    responsive: ["md"],
  },
  {
    title: "MWSt.",
    dataIndex: "taxRate",
    align: "left" as AlignType,
    render: (taxRate: string) =>
      Number.isFinite(taxRate) ? `${taxRate} %` : "-",
    responsive: ["md"],
  },
  {
    title: "Hersteller",
    dataIndex: "producer",
    align: "left" as AlignType,
    render: (producer: string) => producer || "-",
    ellipsis: true,
    onCell: () => {
      return {
        style: {
          whiteSpace: "nowrap",
          maxWidth: 200,
        },
      };
    },
    responsive: ["md"],
  },
  {
    title: "Menge",
    dataIndex: "quantity",
    align: "right" as AlignType,
    render: (quantity: number) =>
      localizedNumberFormat({ value: quantity || 0 }) || "-",
  },
  {
    title: "EK",
    dataIndex: "unitPrice",
    align: "right" as AlignType,
    render: (unitPrice: string) => {
      return parsePriceValueToLocalPrice(Number(unitPrice) || 0);
    },
    responsive: ["md"],
  },
  {
    title: "Inv.-Wert",
    align: "right" as AlignType,
    render: (_: any, record: any) => {
      const value = record.quantity * Number(record.unitPrice) || 0;
      return parsePriceValueToLocalPrice(value);
    },
  },
];
