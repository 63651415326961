import React from "react";

import { Link } from "react-router-dom";
import moment from "moment/moment";
import clsx from "clsx";

import { apiDateFormat, dateFormat } from "../../../utils/dateFormats";
import { routePathNames } from "../../../appConfig";
import { ReactComponent as Cart } from "../../../static/svg/cart.svg";

interface OpenCartLineProps {
  deliveryDate: moment.Moment;
  totalItems: number;
  className?: string;
  header?: React.ReactNode;
  lineType?: "" | "danger" | "warning";
  totalCarts?: number;
}

const OpenCartLine: React.FC<OpenCartLineProps> = (props) => {
  const {
    deliveryDate,
    totalItems = 0,
    className,
    header,
    lineType,
    totalCarts = 1,
  } = props;

  return (
    <div
      className={clsx(
        "open-cart-line",
        lineType && `open-cart-line--${lineType}`,
        className
      )}
    >
      <Link
        to={`${routePathNames.cart}?deliveryDate=${deliveryDate.format(
          apiDateFormat
        )}`}
        title="Warenkorb ansehen"
      >
        <div
          className={clsx(
            "open-cart-line__content",
            !!header && "has-header",
            className,
            totalCarts === 1 && "open-cart-line--single"
          )}
        >
          {header}
          <Cart />

          <div className="open-cart-line__delivery-date">
            <span className="open-cart-line__label">Lieferdatum</span>
            <span className="open-cart-line__value">
              {deliveryDate ? deliveryDate.format(dateFormat) : "-"}
            </span>
          </div>

          <div className="open-cart-line__amount">
            <span className="open-cart-line__label">Lieferumfang</span>
            <span className="open-cart-line__value">{totalItems} Artikel</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default OpenCartLine;
