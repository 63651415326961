import { BaseSyntheticEvent } from "react";
import { Asset } from "contentful";

const downloadContenfulFile = (
  e: BaseSyntheticEvent,
  file: Asset,
  target: "_blank" | "_self" = "_blank"
) => {
  e.preventDefault();

  if (!file?.fields?.file) {
    return;
  }

  const link = document.createElement("a");
  link.href = file?.fields?.file?.url;
  link.setAttribute("download", file?.fields?.file?.fileName);
  link.setAttribute("target", target);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadContenfulFile;
