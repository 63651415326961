import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";

interface WeekplannerGroupHeaderProps {
  title: string;
  style?: any;
  className?: string;
}

const WeekplannerGroupHeader: React.FC<WeekplannerGroupHeaderProps> = (
  props: WeekplannerGroupHeaderProps
) => {
  const { title, style, className } = props;

  return (
    <div className={clsx("weekplanner-group-header", className)} style={style}>
      {title}
    </div>
  );
};

export default React.memo(WeekplannerGroupHeader, areEqual);
