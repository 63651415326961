import React, { useRef, useState } from "react";
import { SuggestItem } from "../../types/suggestItem";
import ProductSuggestion from "./ProductSuggestion";
import HrDivider from "../divider/Divider";

interface Props {
  deliveryDate: string;
  foundProducts: Record<string, any>;
  checkIfAlreadyOnList?: (sku: string) => boolean;
  isAlreadyOnListWarning?: string;
  type?: SuggestItem;
}

const ProductSuggestionHeader = (props: Props): any => {
  const {
    deliveryDate,
    foundProducts,
    checkIfAlreadyOnList,
    isAlreadyOnListWarning,
    type,
  } = props;
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const [touchStartY, setTouchStartY] = useState(0);

  const onWheelEvent = (event: any) => {
    const { deltaY } = event;
    scrollableDivRef.current.scrollTop += deltaY;
  };

  const onTouchEvent = (event: any) => {
    const deltaY = touchStartY - event.touches[0].clientY;
    scrollableDivRef.current.scrollTop += deltaY;
    setTouchStartY(event.touches[0].clientY);
  };

  const handleTouchStart = (event: any) => {
    setTouchStartY(event.touches[0].clientY);
  };

  return (
    <div className="suggestItemWrapper">
      <div className="suggestItem__title suggest-title">
        <span>Artikel</span>
      </div>
      <div
        className="suggestItem__body suggest-body"
        onWheel={onWheelEvent}
        onTouchMove={onTouchEvent}
        onTouchStart={handleTouchStart}
        ref={scrollableDivRef}
      >
        {foundProducts.slice(0, 5).map((singleProductResponse: any) => {
          return (
            <ProductSuggestion
              deliveryDate={deliveryDate}
              productSuggestData={singleProductResponse}
              isAlreadyOnList={
                checkIfAlreadyOnList
                  ? checkIfAlreadyOnList(singleProductResponse.sku)
                  : false
              }
              isAlreadyOnListWarning={isAlreadyOnListWarning}
              type={type}
              key={singleProductResponse.sku}
            />
          );
        })}
      </div>
      <HrDivider />
    </div>
  );
};

export default ProductSuggestionHeader;
