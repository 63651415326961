import React from "react";
import { Col, Row } from "antd";
import { ReactComponent as FilePlaceholder } from "../../static/svg/blog.svg";

interface Props {
  assets: Record<string, any>[];
}

function InfoCenterAssets({ assets }: Props) {
  if (!assets?.length) {
    return null;
  }

  return (
    <Row gutter={[5, 5]} className="mb-sm">
      {assets.map(({ fields }: any) => {
        if (!fields?.document?.fields?.file) {
          return null;
        }

        const {
          description,
          document: {
            fields: { file },
          },
          preview_image: previewImage,
        } = fields;

        let previewImageUrl = "";
        let altTag = "";

        if (previewImage) {
          const {
            fields: {
              file: { url, fileName },
            },
          } = previewImage;

          previewImageUrl = url;
          altTag = fileName;
        }

        return (
          <Col xs={12} sm={6} xl={4} key={file.url}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={file.url}
              className="button buttonAsset"
            >
              <figure className="assetContent">
                {previewImageUrl ? (
                  <img
                    src={previewImageUrl}
                    alt={altTag}
                    className="assetImage"
                  />
                ) : (
                  <FilePlaceholder className="icon iconAssetPlaceholder" />
                )}

                <figcaption>
                  <span className="assetText assetTitle like-h5">
                    {file.fileName}
                  </span>
                  <span className="assetText">{description}</span>
                </figcaption>
              </figure>
            </a>
          </Col>
        );
      })}
    </Row>
  );
}

export default InfoCenterAssets;
