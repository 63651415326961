import axiosWithAuth from "../axiosWithAuth";
import { cartsEndpoint, productQueryString } from "../endpoints";
import { PostCartItemMulti } from "../../types/postCartItemMulti";

/**
 * POST item in spryker cart
 * @param deliveryDate {string}
 * @param items {array}
 * @param cancelTokenSource {CancelTokenSource}
 * @param sourceCart {Record<string, string>}
 * @return {Promise<AxiosResponse>}
 */
const postItemToCartMulti = async ({
  deliveryDate,
  items,
  cancelTokenSource,
  sourceCart,
}: PostCartItemMulti) => {
  return axiosWithAuth()
    .post(
      `${cartsEndpoint}/${deliveryDate}/items-multi?${productQueryString},items`,
      {
        data: {
          type: "items-multi",
          attributes: {
            items,
            sourceCart,
          },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (response?.status === 200 || response?.status === 201) {
        return response;
      }

      return Promise.reject(response);
    });
};

export default postItemToCartMulti;
