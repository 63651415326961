import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Dropdown, Radio, Space, Tooltip } from "antd";
import { routePathNames } from "../../appConfig";
import { FavouriteList } from "../../types/favouriteList";
import DeleteFavouriteListButton from "./DeleteFavouriteListButton";
import { RootState } from "../../types/rootState";
import { ReactComponent as Arrow } from "../../static/svg/arrow.svg";

interface FavouriteListsSelectorProps {
  title?: string;
  favouriteLists: Array<FavouriteList>;
}

/**
 * component for selecting a favourite list
 * entries are sorted asc by last edited favourite list
 * @param {string} title
 * @param favouriteLists
 * @constructor
 */
const FavouriteListsSelector: React.FC<FavouriteListsSelectorProps> =
  function FavouriteListsSelector({
    title = "Favoritenlisten",
    favouriteLists = [],
  }: FavouriteListsSelectorProps) {
    const dispatch = useDispatch();

    const { activeList } = useSelector((state: any) => state.favouriteList);
    const { favouriteListDefaultSorting } = useSelector(
      (state: RootState) => state?.userData
    );

    const [isSortDropdownOpen, setIsSortDropdownOpen] =
      useState<boolean>(false);

    const [isEllipsisActive, setIsEllipsisActive] = useState([]);
    const elementsRef = useRef(favouriteLists.map(React.createRef<null>));

    useEffect(() => {
      const nextEllipsis = elementsRef.current.map((element: any) => {
        return element.current.offsetWidth < element.current.scrollWidth;
      });
      setIsEllipsisActive(nextEllipsis);
    }, []);

    return (
      <div className="favouriteListsSelector hidden-sm-down">
        <div className="text-medium text-sm text-bold title">
          <Space className="flex justify-between">
            {title}
            <Dropdown
              visible={isSortDropdownOpen}
              onVisibleChange={setIsSortDropdownOpen}
              placement="bottomRight"
              overlayClassName="favouriteListsSelector-SortDropdown"
              overlay={
                <Radio.Group
                  name="favouriteListSorting"
                  defaultValue={favouriteListDefaultSorting || "updated_desc"}
                  onChange={(e) => {
                    dispatch({
                      type: "user/update-user",
                      payload: {
                        favouriteListDefaultSorting: e?.target?.value,
                      },
                    });
                  }}
                >
                  <Space direction="vertical">
                    <Radio value="name_asc">Name (A bis Z)</Radio>
                    <Radio value="name_desc">Name (Z bis A)</Radio>
                    <Radio value="updated_asc">
                      Änderungsdatum (Alte zuerst)
                    </Radio>
                    <Radio value="updated_desc">
                      Änderungsdatum (Neue zuerst)
                    </Radio>
                  </Space>
                </Radio.Group>
              }
            >
              <Button type="text" className="favouriteListsSelector-SortButton">
                <Space>
                  Sortieren nach
                  <Arrow
                    style={{
                      transform: isSortDropdownOpen
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                    }}
                  />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        </div>
        {favouriteLists.map((favouriteList: FavouriteList, index: any) => (
          <div className="favouriteList" key={favouriteList.idFavouriteList}>
            <div className="captionWrapper">
              <div className="name">
                <Link
                  to={`${routePathNames.favouriteList}${favouriteList.idFavouriteList}`}
                  key={favouriteList.idFavouriteList}
                  className={clsx(
                    "text-sm text-normal caption",
                    activeList?.idFavouriteList ===
                      favouriteList.idFavouriteList && "caption--active"
                  )}
                >
                  <Tooltip
                    title={isEllipsisActive[index] ? favouriteList.caption : ""}
                  >
                    <span
                      className={clsx("favoriteListName")}
                      key={index}
                      ref={elementsRef.current[index]}
                    >
                      {favouriteList.caption}
                    </span>
                  </Tooltip>
                </Link>
              </div>
              <div className="itemNumber">
                &nbsp;{`(${favouriteList.favourites?.length})`}
              </div>
              <div className="trash">
                <DeleteFavouriteListButton
                  idFavouriteList={favouriteList.idFavouriteList}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

export default FavouriteListsSelector;
