import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";

import useStockForAttribute from "../../../../../../hooks/useStockForAttribute";
import ProductInfoBadge from "../../../../../product/ProductInfoBadge";
import { productAttributes } from "../../../../../../api/productAttributes";
import { ProductData } from "../../../../../../types/productData";

interface WeekplannerItemSkuProps {
  productData: ProductData;
  className?: string;
}

const WeekplannerItemSku: React.FC<WeekplannerItemSkuProps> = (
  props: WeekplannerItemSkuProps
) => {
  const { productData, className } = props;

  const { attributes = {} } = productData || {};

  const {
    [productAttributes.isNewArticle]: isNewArticle,
    [productAttributes.articlePoster]: articlePoster,
  } = attributes;

  const advertisement = useStockForAttribute(attributes, "advert");

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-sku",
        className
      )}
    >
      {productData?.sku}
      <div className="flex ant-space-align-baseline justify-center">
        <ProductInfoBadge
          view="weekplanner"
          advertisement={advertisement}
          isNewArticle={isNewArticle}
          isArticlePosterAvailable={articlePoster}
        />
      </div>
    </div>
  );
};

export default React.memo(WeekplannerItemSku, areEqual);
