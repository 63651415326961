import React, { useMemo, useState } from "react";

import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Col, message, Result, Row } from "antd";
import queryString from "query-string";

import useGetStocktakings from "../../../hooks/useGetStocktakings";
import useUpdateUrlFragments from "../../../hooks/useUpdateUrlFragments";
import requestCatchHandler from "../../../api/requestCatchHandler";
import postStocktaking from "../../../api/stocktaking/postStocktaking";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import BackButton from "../../backButton/BackButton";
import HrDivider from "../../divider/Divider";
import StocktakingsTable from "./StocktakingsTable";
import { ContentBlock } from "../../atoms";
import {
  locationSearchQueryParameter,
  messageData,
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../../appConfig";
import { StocktakingData } from "../../../types/stocktaking";
import { ReactComponent as Plus } from "../../../static/svg/plus.svg";
import TrackingHelmet from "../../Matomo/TrackingHelmet";

interface StocktakingProps {
  className?: string;
}

const Stocktakings: React.FC<StocktakingProps> = (props: StocktakingProps) => {
  const { className } = props;

  const { search } = useLocation();
  const parsedSearch = useMemo(() => queryString.parse(search), [search]);

  const { [locationSearchQueryParameter.page]: pageQuery } = parsedSearch;

  const [isStocktakingCreating, setIsStocktakingCreating] =
    useState<boolean>(false);
  const [page, setPage] = useState<number>(Number(pageQuery) || 1);

  const { isLoading, hasError, total, stocktakings } = useGetStocktakings({
    page,
  });
  const setUpdateUrlFragments = useUpdateUrlFragments();
  const navigate = useNavigate();

  const onTableChange = (pagination: any) => {
    if (pagination.current) {
      setPage(pagination.current);
      setUpdateUrlFragments({
        context: "pagination",
        parameters: {
          page: pagination.current,
        },
      });
    }
  };

  const onCreateStocktaking = () => {
    const cancelTokenSource = getCancelTokenSource();
    setIsStocktakingCreating(true);

    postStocktaking({ cancelTokenSource })
      .then((response: StocktakingData) => {
        setIsStocktakingCreating(false);
        message.success({ ...messageData.success.stocktakings.createSuccess });
        navigate(`${routePathNames.stocktaking}/${response.idStocktaking}`);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsStocktakingCreating(false);
          message.error({ ...messageData.error.stocktakings.createError });
        }
        requestCatchHandler(error);
      });
  };

  return (
    <>
      <TrackingHelmet
        title={pageTitles.stocktakings}
        suffix={pageTitleSuffix}
      />

      <ShopCategoryNavigation />

      <ContentBlock
        showHeader={false}
        className={clsx("stocktakings-page", className)}
      >
        <BackButton />

        {/* Header */}
        <Row className="flex items-center mb-m" justify="space-between">
          <Col xs={12} md={8}>
            <h1 className="text-truncate">Inventurübersicht</h1>
          </Col>
          <Col xs={12} md={4} className="flex justify-start md-justify-end">
            <Button
              className="button buttonPrimary buttonWithIcon buttonWithSpin sm-with-full"
              icon={<Plus className="icon" />}
              loading={isStocktakingCreating}
              onClick={onCreateStocktaking}
            >
              Inventur anlegen
            </Button>
          </Col>
        </Row>

        <HrDivider spacingTop="xs" size={2} />

        {/* Sub-Header */}
        <Row className="mb-m mt-m" justify="space-between">
          <Col span={12} className="flex flex-row">
            <h2 className="mb-0 text-truncate">Inventuren</h2>
            {total > 0 && (
              <span className="m-0 pl-s items-center flex">
                {total} Gelistet
              </span>
            )}
          </Col>
        </Row>

        {/* Table */}
        <Row gutter={10}>
          <Col xs={12}>
            {!hasError && (
              <StocktakingsTable
                stocktakings={stocktakings}
                total={total}
                page={page}
                isLoading={isLoading}
                emptyDescription={<span>Keine Inventuren vorhanden.</span>}
                onTableChange={onTableChange}
              />
            )}

            {!isLoading && !!hasError && (
              <Result
                status="warning"
                className="mb-3xl"
                title="Deine Inventuren konnten nicht abgerufen werden."
              />
            )}
          </Col>
        </Row>
      </ContentBlock>
    </>
  );
};

export default Stocktakings;
