import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Entry } from "contentful";
import { messageData, routePathNames } from "../../appConfig";
import getUserTermsAndConditionsState from "../../api/termsAndConditions/getUserTermsAndConditionsState";
import setUserTermsAndConditionsState from "../../api/termsAndConditions/setUserTermsAndConditionsState";
import isTermsAndConditionsVersionNew from "./isTermsAndConditionsVersionNew";
import useGlobalNetworkState from "../../hooks/useGlobalNetworkState";
import getTermsAndConditions from "../../api/contentful/getTermsAndConditions";
import requestCatchHandler from "../../api/requestCatchHandler";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import { RootState } from "../../types/rootState";
import clearStore from "../../state/actions/clearStore";

/**
 * Terms and Conditions Modal for super admins
 * @constructor
 */
const TermsAndConditionModals: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [componentIsLoading] = useGlobalNetworkState("component");
  const { userMaySupersedeTaC } = useSelector(
    (state: RootState) => state.userData?.termsAndConditions || false
  );
  const [confirmationIsPending, setConfirmationIsPending] =
    useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [hasAccepted, setHasAccepted] = useState<boolean>(false);
  const [hasCanceled, setHasCanceled] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  // User accepts terms
  const onAccept = () => {
    setConfirmationIsPending(true);

    setUserTermsAndConditionsState(getCancelTokenSource())
      .then((response) => {
        // throw error, if the API call was not successful or the setter failed
        if (
          response?.status > 204 ||
          !response?.data?.data?.attributes?.isSuccess
        ) {
          return Promise.reject(response);
        }

        // hide modal if timestamp was successfully stored
        dispatch({
          type: "user/set-accepted-terms-and-conditions",
          payload: true,
        });

        setHasAccepted(true);
        setIsVisible(false);
        setConfirmationIsPending(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error(messageData.error.termsAndConditionsAcceptance);

          setHasError(true);
          setConfirmationIsPending(false);
        }

        requestCatchHandler(error);
      });
  };

  // User rejects terms
  const onReject = () => {
    setIsVisible(false);
    setHasCanceled(true);
  };

  // User agrees to logout after rejecting terms
  const onOk = () => {
    clearStore();
    navigate(routePathNames.login);
  };

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();

    /*
     * Only fetch data if:
     *  - The user has not accepted yet
     *  - The user has not declined yet
     *  - There was no error before
     *  - The component / global component state is still loading
     *  - The confirmation triggered by the user is still pending
     */
    if (
      !hasError &&
      (!hasAccepted || !hasCanceled) &&
      !componentIsLoading &&
      !confirmationIsPending
    ) {
      Promise.all([
        getUserTermsAndConditionsState(cancelTokenSource),
        getTermsAndConditions(),
      ])
        .then((response) => {
          if (!response) {
            return Promise.reject(response);
          }

          const [termsAcceptedResponse, contentfulTermsEntry] = response;

          if (termsAcceptedResponse && contentfulTermsEntry) {
            const { agbConsentDateTime, isSuccess } = termsAcceptedResponse;
            const { sys: contentfulMetadata } =
              contentfulTermsEntry as Entry<any>;

            /*
             * Compare user consent timestamp against terms and condition (change) date.
             * The user consent timestamp needs to be newer than terms and condition date.
             * If not there may be changes to the terms that a user needs to give consent to.
             * In this case or if no consent timestamp is present at all we need to show the
             * consent modal.
             *
             * Hint 1:
             *  - user consent timestamp is saved on our end
             *  - terms and condition (change) date is available at contentful
             *
             * Hint 2:
             *  Most of the times the "updatedAt" will be tested, but if the pages gets
             *  recreated on contentful, there is likely to be only a "createdAt" date available.
             */
            const showModal =
              !agbConsentDateTime ||
              isTermsAndConditionsVersionNew(
                contentfulMetadata,
                parseInt(agbConsentDateTime, 10)
              );

            /*
             * Do show modal if:
             *  - user consent timestamp is empty
             *  - terms and condition (change) date is newer than the one saved on the user
             *  - terms accepted API response is not marked as a success
             */
            setIsVisible(showModal || !isSuccess);
          } else {
            setHasError(true);
          }

          return response;
        })
        .catch(requestCatchHandler);
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [
    hasError,
    hasCanceled,
    hasAccepted,
    confirmationIsPending,
    componentIsLoading,
  ]);

  /*
   * Don't show any modal if:
   *  - a global component is still loading
   *  - the user is not allowed to interact with this modal
   *  - there is a temporary error
   */
  if (componentIsLoading || !userMaySupersedeTaC || hasError) {
    return null;
  }

  // Show logout modal if user rejected terms
  if (hasCanceled) {
    return (
      <Modal
        title="Sie werden nun ausgeloggt..."
        visible={hasCanceled}
        closable={false}
        maskClosable={false}
        keyboard={false}
        okText="Ok"
        onOk={onOk}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>
          Da Sie unsere AGB leider nicht akzeptiert haben, werden Sie nun
          ausgeloggt.
        </p>
      </Modal>
    );
  }

  // Show terms and conditions modal
  return (
    <Modal
      title="Allgemeine Geschäftsbedingungen akzeptieren"
      visible={isVisible}
      closable={false}
      maskClosable={false}
      keyboard={false}
      onOk={onAccept}
      confirmLoading={confirmationIsPending}
      okText="Akzeptieren"
      onCancel={onReject}
      cancelText="Ablehnen"
    >
      <p>
        Bitte bestätigen Sie unsere{" "}
        <Link
          to={routePathNames.termsAndConditions}
          target="_blank"
          rel="noopener noreferrer"
        >
          AGB
        </Link>
        .
      </p>
    </Modal>
  );
};

export default TermsAndConditionModals;
