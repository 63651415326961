import React, { useEffect, useMemo, useRef, useState } from "react";

import * as Sentry from "@sentry/browser";

import clsx from "clsx";
import { useSelector } from "react-redux";
import axios, { CancelTokenSource, AxiosResponse, Method } from "axios";
import { message, Modal } from "antd";
import ScanditBarcodeScanner from "scandit-sdk-react";
import { Barcode, BarcodePicker, ScanResult, ScanSettings } from "scandit-sdk";

import getProductByEan from "../../api/scanner/getProductByEan";
import getProductDetail from "../../api/products/getProductDetail";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import getDeliveryDateBasedAttributes from "../product/getDeliveryDateBasedAttributes";
import getCartItemQuantity from "../../utils/getCartItemQuantity";
import getCartErrorMessage from "../../utils/getCartErrorMessage";
import useAddScannedProduct from "./useAddScannedProduct";
import useUpdateCartItemQuantity from "../../hooks/useUpdateCartItemQuantity";
import requestCatchHandler from "../../api/requestCatchHandler";
import ScannerMessage from "./ScannerMessage";
import ProductTileWithModal from "../product/ProductTile/ProductTileWithModal";
import replaceVariableInMessage from "../../utils/replaceVariableInMessage";
import { messageData } from "../../appConfig";
import { ReactComponent as UnavailableIcon } from "../../static/svg/shopping-cart-unavailable.svg";
import { ProductData } from "../../types/productData";
import { ReactComponent as Delete } from "../../static/svg/delete.svg";
import { ProductDetailModal } from "../molecules";
import ScannerProductEditModalContext from "../../contexts/ScannerProductEditModalContext";

interface Props {
  keyOnReadyMessage: string | number;
}

interface RevealProductEditModal {
  allowRevert: boolean;
  key: string;
}

// Scandit License Key
const licenseKey = process.env.REACT_APP_SCANDIT;

/**
 * actual scanner
 * @param keyOnReadyMessage {string|number}
 * @constructor
 * @see {@link https://docs.scandit.com/stable/web/index.html}
 * @see {@link https://www.npmjs.com/package/scandit-sdk-react}
 */
const ScannerView: React.FC<Props> = ({ keyOnReadyMessage }: Props) => {
  const { id: cartId, deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );
  const { cartItems } = useSelector((state: any) => state.currentCart);

  const addScannedItem = useAddScannedProduct();
  const updateCartItemQuantity = useUpdateCartItemQuantity();

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  const scanButtonRef = useRef<HTMLButtonElement | null>(null);
  const messageKey = useRef<string>(String(keyOnReadyMessage));
  const scannedProductOriginalQuantity = useRef<number>(0);
  const cartItemsRef = useRef<any>(cartItems);

  const [isPaused, setIsPaused] = useState(true);
  const [activeProduct, setActiveProduct] = useState<ProductData>(null);
  const [productEditModalSettings, setProductEditModalSettings] = useState({
    allowRevertScan: true,
    isVisible: false,
  });
  const [isProductDetailModalVisible, setIsProductDetailModalVisible] =
    useState<boolean>(false);

  const viewFinderArea = useMemo(
    () => ({ x: 0, y: 0.3, width: 1, height: 0.35 }),
    []
  );
  const scanSettings = useMemo(
    () =>
      new ScanSettings({
        enabledSymbologies: [
          Barcode.Symbology.EAN8,
          Barcode.Symbology.EAN13,
          Barcode.Symbology.CODE39,
        ],
        searchArea: viewFinderArea,
      }),
    [viewFinderArea]
  );

  useEffect(() => {
    cartItemsRef.current = cartItems;
  }, [cartItems]);

  /**
   * test if a camera is active to show a warning to the user
   */
  useEffect(() => {
    navigator?.mediaDevices?.enumerateDevices().then((devices) => {
      const cameraAccessValid = devices.some(
        (device) => device.kind === "videoinput" && device.label !== ""
      );

      if (!cameraAccessValid) {
        message.warning({
          ...messageData.warning.scanner.accessDenied,
          key: keyOnReadyMessage,
        });
      }
    });

    // destroy initial message if present
    return () => {
      message.destroy(keyOnReadyMessage);
    };
  }, [keyOnReadyMessage]);

  /**
   * when scanner is ready
   */
  const onReady = () => {
    message.success({
      ...messageData.success.scanner.scannerReady,
      key: keyOnReadyMessage,
    });
  };

  /**
   * show message if a scan fails
   */
  const onScanError = (error: any) => {
    Sentry.captureException(error);

    message.error({
      ...messageData.error.scanner.scanError,
      key: messageKey.current,
    });
  };

  /**
   * activate scanner as long as a barcode is retrieved by the scanner
   * is triggered by a click / touch on button
   */
  const activateScanner = () => {
    scanButtonRef?.current?.blur();
    setIsPaused(false);
  };

  /**
   * show product edit modal
   * @param allowRevert {boolean}
   * @param key {string}
   */
  const revealProductEditModal = ({
    allowRevert,
    key,
  }: RevealProductEditModal) => {
    setProductEditModalSettings({
      allowRevertScan: allowRevert,
      isVisible: true,
    });

    message.destroy(key);
  };

  /**
   * show product detail modal
   * @param key {string}
   */
  const revealProductDetailModal = (key: string) => {
    setIsProductDetailModalVisible(true);

    message.destroy(key);
  };

  /**
   * close product edit modal
   */
  const closeProductEditModal = () => {
    setProductEditModalSettings({
      allowRevertScan: true,
      isVisible: false,
    });
  };

  /**
   * revert scan of product, which means: restore quantity of sku before scan
   * was successful
   * if quantity in cart is now 1: delete the item
   * if quantity in cart is more than 1: restore previous amount from ref
   */
  const revertProductScan = () => {
    const { sku } = activeProduct;
    const currentCartQuantity = getCartItemQuantity({
      cartItems,
      lookupSku: sku,
    });

    // restore original quantity for sku, only if values differ
    if (currentCartQuantity !== scannedProductOriginalQuantity.current) {
      // remove possible messages first
      message.destroy(messageKey.current);

      /*
       * show a new loading message
       * use the sku as separate key to prevent overwrite of possible new scan
       */
      message.loading({
        ...messageData.loading.scanner.revertScan,
        key: sku,
        duration: 0,
      });

      updateCartItemQuantity({
        cancelTokenSource: cancelTokenSource.current,
        cartId,
        deliveryDate,
        sku,
        previousQuantity: currentCartQuantity,
        quantity: scannedProductOriginalQuantity.current,
      })
        .then(() => {
          message.success({
            ...messageData.success.scanner.revertScan,
            duration: 3,
            key: sku,
          });
        })
        .catch((deleteError) => {
          // if an error occurs, show it
          if (!axios.isCancel(deleteError)) {
            message.error({
              ...messageData.error.scanner.revertScan,
              key: sku,
            });
          }
        });
    }

    // close the edit modal anyway
    closeProductEditModal();
  };

  /**
   * close the modal only if there is no matching cart message
   * @param {AxiosResponse} cartResponse
   * @param {Method} method
   */
  const productEditModalAddToCartCallback = ({
    cartResponse,
    method,
  }: {
    cartResponse: AxiosResponse;
    method: Method;
  }) => {
    const { content: updateContend, duration: updateDuration } =
      messageData.success.scanner.updateQuantity;
    const { content: deleteContend, duration: deleteDuration } =
      messageData.success.scanner.deleteQuantity;

    // show a hint, that the item was removed from the cart
    if (method.toLowerCase() === "delete") {
      message.warn({
        content: replaceVariableInMessage(deleteContend, activeProduct?.name),
        duration: deleteDuration,
      });
    } else {
      message.success({
        content: replaceVariableInMessage(updateContend, activeProduct?.name),
        duration: updateDuration,
      });
    }

    if (
      !getCartErrorMessage({
        response: cartResponse,
        sku: activeProduct?.sku,
      })
    ) {
      closeProductEditModal();
    }

    return true;
  };

  const getProductDataPromiseByEan = async (
    ean: string,
    currentCancelTokenSource: CancelTokenSource,
    onScanMessageKey: string
  ): Promise<ProductData> => {
    return getProductByEan(ean, deliveryDate, currentCancelTokenSource)
      .catch((error) => {
        // if request got canceled inform user
        if (axios.isCancel(error)) {
          message.warning({
            ...messageData.warning.cancelRequest,
            key: onScanMessageKey,
          });
        }

        return Promise.reject(error);
      })
      .then((response) => {
        // no product found, just move to the last catch
        if (!response?.length) {
          message.error({
            ...messageData.error.scanner.notFound,
            key: onScanMessageKey,
          });

          return Promise.reject();
        }

        // return the product data, which is the first array index
        return Promise.resolve(response?.[0]);
      });
  };

  const getProductDataPromiseBySku = async (
    sku: string,
    currentCancelTokenSource: CancelTokenSource,
    onScanMessageKey: string
  ): Promise<ProductData> => {
    return getProductDetail({
      productSku: sku,
      deliveryDate,
      cancelTokenSource: currentCancelTokenSource,
    })
      .catch((error) => {
        // if request got canceled inform user
        if (axios.isCancel(error)) {
          message.warning({
            ...messageData.warning.cancelRequest,
            key: onScanMessageKey,
          });
        }

        return Promise.reject(error);
      })
      .then((response) => {
        // no product found, just move to the last catch
        if (!response?.concreteProducts?.length) {
          message.error({
            ...messageData.error.scanner.notFound,
            key: onScanMessageKey,
          });

          return Promise.reject();
        }

        // return the product data, which is the first array index
        return Promise.resolve(response?.concreteProducts?.[0]);
      });
  };

  /**
   * if scan was successful
   * test if a product was found
   * if not show an error and end function
   * if a product was found show a loading message
   * determine if the product is available and could be added or
   * an unavailable message is shown
   * @param scanResult {ScanResult}
   */
  const onScan = async (scanResult: ScanResult): Promise<void> => {
    const barcode = scanResult.barcodes?.[0]?.data;

    if (!barcode) {
      return onScanError(barcode);
    }

    // stop scanning, so only one barcode gets recognized
    setIsPaused(true);

    const isEasyOrderBarcode = barcode.length < 8;
    // messageKey to identify messages
    const onScanMessageKey = `${barcode}_${Date.now()}`;

    const productDataPromise: Promise<ProductData> = !isEasyOrderBarcode
      ? getProductDataPromiseByEan(
          barcode,
          cancelTokenSource.current,
          onScanMessageKey
        )
      : getProductDataPromiseBySku(
          barcode,
          cancelTokenSource.current,
          onScanMessageKey
        );

    return productDataPromise
      .then((scannedProduct: ProductData) => {
        /*
         * show the spinning message icon,
         * check if the product should be added to the cart
         * or just the info, that it is unavailable should be shown
         * from where the user has to interact
         */
        const { name, sku, availabilities } = scannedProduct;

        const { isUnavailable } = getDeliveryDateBasedAttributes({
          deliveryDate,
          availabilities,
        });

        /*
         * if the product is not available just return it, so we
         * will show it as unavailable
         */
        if (isUnavailable) {
          return scannedProduct;
        }

        // save original quantity of item in cart
        scannedProductOriginalQuantity.current = getCartItemQuantity({
          cartItems: cartItemsRef.current,
          lookupSku: sku,
        });

        /*
         * show an info message, then add the icon or react to user click
         */
        return message
          .info({
            className: "scannerMessage",
            content: (
              <ScannerMessage name={name} sku={sku}>
                <div className="scannerMessageContentInteraction">
                  <button
                    type="button"
                    className="button buttonText"
                    onClick={() => {
                      setActiveProduct(scannedProduct);

                      revealProductEditModal({
                        allowRevert: true,
                        key: onScanMessageKey,
                      });
                    }}
                  >
                    Menge ändern
                  </button>
                  <button
                    type="button"
                    className="button buttonText ml-s"
                    onClick={() => {
                      setActiveProduct(scannedProduct);
                      revealProductDetailModal(onScanMessageKey);
                    }}
                  >
                    Details ansehen
                  </button>
                  {scannedProductOriginalQuantity.current > 0 && (
                    <div className="scannerMessage__original-quantity">{`Achtung! Artikel bereits mit Menge ${scannedProductOriginalQuantity.current} im Warenkorb.`}</div>
                  )}
                </div>
              </ScannerMessage>
            ),
            duration: 4,
            key: onScanMessageKey,
          })
          .then(async () => {
            /*
             * loading indicator during update of cart
             * duration is 0 to keep the message as long as it gets updated
             */
            message.loading({
              className: "scannerMessage",
              content: <ScannerMessage name={name} sku={sku} />,
              key: onScanMessageKey,
              duration: 0,
            });

            /*
             * if product is available, return the add request
             * which returns the product
             * or an error
             */
            return addScannedItem({
              deliveryDate,
              cartId,
              sku,
              cancelTokenSource: cancelTokenSource.current,
            }).then(() => scannedProduct);
          });
      })
      .then((scannedProduct: ProductData) => {
        const { availabilities, name, sku } = scannedProduct;
        const { isUnavailable, nextAvailability, dailyProductAlternatives } =
          getDeliveryDateBasedAttributes({
            deliveryDate,
            availabilities,
          });

        if (isUnavailable) {
          /*
           * if unavailable update the message
           * than show the modal, if alternatives or a future deliveryDate is available
           */
          message
            .warning({
              className: "scannerMessage",
              content: <ScannerMessage name={name} sku={sku} />,
              duration: 2,
              icon: <UnavailableIcon className="icon iconUnavailable" />,
              key: onScanMessageKey,
            })
            .then(() => {
              if (nextAvailability || dailyProductAlternatives) {
                setActiveProduct(scannedProduct);

                revealProductEditModal({
                  allowRevert: false,
                  key: onScanMessageKey,
                });
              }
            });
        } else {
          /*
           * if product is available, it got added to cart
           * update message to success
           */
          const { content, duration } = messageData.success.scanner.addedItem;

          message.success({
            className: "scannerMessage",
            content: replaceVariableInMessage(content, name),
            duration,
            key: onScanMessageKey,
          });
        }
      })
      .catch(requestCatchHandler);
  };

  return (
    <>
      <div className="scannerView">
        <ScanditBarcodeScanner
          accessCamera
          engineLocation="./static/scandit"
          licenseKey={licenseKey}
          guiStyle={BarcodePicker.GuiStyle.VIEWFINDER}
          onReady={onReady}
          onScan={onScan}
          onScanError={onScanError}
          paused={isPaused}
          playSoundOnScan
          preloadBlurryRecognition
          preloadEngine
          scanSettings={scanSettings}
          vibrateOnScan
          videoFit={BarcodePicker.ObjectFit.COVER}
          viewFinderArea={viewFinderArea}
          visible
        />

        <button
          type="button"
          className="button buttonPrimary scannerButtonScan"
          onClick={activateScanner}
          ref={scanButtonRef}
        >
          Scannen
        </button>
      </div>

      {activeProduct && productEditModalSettings.isVisible && (
        <Modal
          className="scannerProductEditModal"
          closable={false}
          destroyOnClose
          footer={
            <div
              className={clsx(
                "scannerProductEditModalFooter",
                productEditModalSettings.allowRevertScan
                  ? "scannerProductEditModalFooterWithButtons"
                  : "scannerProductEditModalFooterWithButton"
              )}
            >
              {productEditModalSettings.allowRevertScan && (
                <button
                  type="button"
                  className="button buttonText buttonTextDecoration--inverted buttonWithIcon color-primary"
                  onClick={revertProductScan}
                >
                  <Delete className="icon" />
                  Scan verwerfen
                </button>
              )}
            </div>
          }
          forceRender
          maskClosable
          onCancel={closeProductEditModal}
          title={null}
          visible={productEditModalSettings.isVisible}
          width={1000}
          wrapClassName="scannerProductEditModalWrapper"
        >
          <ScannerProductEditModalContext.Provider value>
            <ProductTileWithModal
              deliveryDate={deliveryDate}
              productData={activeProduct}
              addToCartCallback={productEditModalAddToCartCallback}
            />
          </ScannerProductEditModalContext.Provider>
        </Modal>
      )}

      <ProductDetailModal
        sku={activeProduct?.sku}
        type="scan"
        visible={activeProduct && isProductDetailModalVisible}
        onCancel={() => {
          setIsProductDetailModalVisible(false);
          revertProductScan();
        }}
        onOk={() => {
          setIsProductDetailModalVisible(false);
          revertProductScan();
        }}
      />
    </>
  );
};

export default ScannerView;
