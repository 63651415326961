import React, { useCallback, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { CancelTokenSource } from "axios";
import moment from "moment";

import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getAlternativeProducts from "../../../api/products/getAlternativeProducts";
import requestCatchHandler from "../../../api/requestCatchHandler";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import ProductViewer from "./ProductViewer";
import { fullDateFormat } from "../../../utils/dateFormats";
import { ModalButton } from "../../../types/button";
import { ProductData } from "../../../types/productData";

interface ProductAlternativesModalProps {
  deliveryDate: ProductData["deliveryDate"];
  sku: ProductData["sku"];
  hasProductAlternatives?: boolean;
  buttonStyle?: ModalButton;
  itemsInCart?: number;
}

const ProductAlternativesModal: React.FC<ProductAlternativesModalProps> = (
  props: ProductAlternativesModalProps
) => {
  const {
    deliveryDate,
    sku,
    hasProductAlternatives = true,
    buttonStyle = "primary",
    itemsInCart = 1,
  } = props;

  const dispatch = useDispatch();
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [alternativeProducts, setAlternativeProducts] = useState<ProductData[]>(
    []
  );

  const getProducts = useCallback(() => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    getAlternativeProducts(sku, deliveryDate, cancelTokenSource.current)
      .then((productsResponse) => {
        setAlternativeProducts(productsResponse);

        if (productsResponse?.length) {
          dispatch({
            type: "cart/set-alternatives",
            payload: { [sku]: productsResponse },
          });
          return;
        }

        dispatch({
          type: "cart/delete-alternative",
          payload: sku,
        });
      })
      .catch(requestCatchHandler);
  }, [dispatch, sku, deliveryDate]);

  if (!hasProductAlternatives) {
    return null;
  }

  return (
    <ProductViewer
      onButtonClick={getProducts}
      buttonClass="productAlternativesButton"
      buttonStyle={buttonStyle}
      buttonTitle="Alternativen finden"
      deliveryDate={deliveryDate}
      products={alternativeProducts}
      defaultQuantity={itemsInCart}
      title={`${
        alternativeProducts.length === 1
          ? "Alternatives Produkt"
          : "Alternative Produkte"
      } für ${moment(deliveryDate).format(fullDateFormat)}`}
    />
  );
};

export default ProductAlternativesModal;
