import React, { ReactNode, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { Button } from "antd";

import { ReactComponent as Arrow } from "../../../static/svg/arrow.svg";

type DirectionType = "left" | "right";

interface ScrollableContentProps {
  scrollStepSize?: number;
  children: ReactNode;
  className?: string;
}

const ScrollableContent: React.FC<ScrollableContentProps> = (
  props: ScrollableContentProps
) => {
  const { scrollStepSize = 150, children, className } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [showScrollLeftButton, setShowScrollLeftButton] =
    useState<boolean>(false);
  const [showScrollRightButton, setShowScrollRightButton] =
    useState<boolean>(false);

  const handleScroll = (direction: DirectionType = "right") => {
    const {
      scrollLeft = 0,
      scrollWidth = 0,
      clientWidth = 0,
    } = ref?.current || {};

    const x = direction === "right" ? scrollStepSize : -scrollStepSize;

    ref.current.scrollBy(x, 0);
    setShowScrollLeftButton(scrollLeft > 0);
    setShowScrollRightButton(Math.ceil(scrollLeft) + clientWidth < scrollWidth);
  };

  useEffect(() => {
    const {
      scrollLeft = 0,
      scrollWidth = 0,
      clientWidth = 0,
    } = ref?.current || {};

    setShowScrollLeftButton(scrollLeft > 0);
    setShowScrollRightButton(scrollWidth > clientWidth);
  }, [children]);

  if (!children) {
    return null;
  }

  return (
    <div className={clsx("scrollable-content", className)}>
      {showScrollLeftButton && (
        <Button
          type="text"
          className="scrollable-content__button-left"
          onClick={() => handleScroll("left")}
        >
          <Arrow />
        </Button>
      )}
      <div ref={ref} className="scrollable-content__content">
        {children}
      </div>
      {showScrollRightButton && (
        <Button
          type="text"
          className="scrollable-content__button-right"
          onClick={() => handleScroll("right")}
        >
          <Arrow />
        </Button>
      )}
    </div>
  );
};

export default ScrollableContent;
