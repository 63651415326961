import React, { useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Collapse, message, Spin } from "antd";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { ReactComponent as Arrow } from "../../../static/svg/arrow.svg";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import DeletedOrChangedCartItemEntry from "./DeletedOrChangedCartItemEntry";
import { CartMessageEntry } from "../../../types/cartMessageEntry";
import { messageData } from "../../../appConfig";
import deleteCartMessageAlternative from "../../../state/actions/deleteCartMessageAlternative";
import updateCartItemQuantityMulti from "../../../state/actions/updateCartItemQuantityMulti";
import getCancelTokenSource from "../../../api/getCancelTokenSource";

interface Type {
  type: string;
}

function DeletedOrChangedCartItemList({ type }: Type) {
  const { cartItemAlternatives } = useSelector(
    (state: any) => state.currentCart
  );
  const { deliveryDate, cartMessages } = useSelector(
    (state: any) => state.currentCartMetaData
  );
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());

  const [isLoading, setIsLoading] = useState(false);

  // test if the alternatives button and grid should be used
  const showAlternativesButton = useMemo(
    () => Object.keys(cartItemAlternatives)?.length > 0,
    [cartItemAlternatives]
  );

  let deletedOrModifiedProductMessages: Record<string, any>;
  let messageCount: number;

  if (type === "deletedItems") {
    deletedOrModifiedProductMessages =
      cartMessages?.filter(
        (cartMessage: CartMessageEntry) => cartMessage.isDeleted
      ) || [];
    messageCount = deletedOrModifiedProductMessages?.length;
  } else {
    deletedOrModifiedProductMessages =
      cartMessages?.filter(
        (cartMessage: CartMessageEntry) => !cartMessage.isDeleted
      ) || [];
    messageCount = deletedOrModifiedProductMessages?.length;
  }

  // test if at least one product has an preorderBasket
  const today = moment();
  const showPreorderBasket = deletedOrModifiedProductMessages.some(
    (cartMessage: CartMessageEntry) => {
      const cartMessageItemAvailabilityDate =
        cartMessage?.item?.nextAvailability?.deliveryDate;

      return (
        cartMessageItemAvailabilityDate &&
        !moment(cartMessageItemAvailabilityDate).isSameOrBefore(today)
      );
    }
  );

  const isLargeScreen = useMedia(
    `(min-width: ${getCssVariable("screen-md", false)})`
  );

  /**
   * add all alternatives to cart and remove the corresponding cartMessage
   * @param event
   */
  const addAllAlternatives = (event: React.MouseEvent<HTMLButtonElement>) => {
    // stop propagation to prevent <Collapse> Toggle
    event.stopPropagation();

    setIsLoading(true);

    /*
     * create array of item objects to update cart and messages
     * typescript only accepts any as type for alternatives
     * which is actually an Array of Records
     */
    const itemsToAdd = Object.entries(cartItemAlternatives).map(
      ([deletedSku, alternatives]: [string, any]) => {
        // retrieve original quantity
        const quantity = deletedOrModifiedProductMessages.find(
          (deletedProductMessage: CartMessageEntry) =>
            deletedProductMessage.sku === deletedSku
        )?.quantity;

        return {
          sku: alternatives[0].sku,
          quantity: parseInt(quantity, 10) || 1,
        };
      }
    );

    // the keys of the object are the none-available items
    const messageIdsToDelete = Object.keys(cartItemAlternatives);

    /*
     * post alternatives to cart
     * delete messages from spryker and redux, if post was successful
     */
    updateCartItemQuantityMulti({
      deliveryDate,
      items: itemsToAdd,
      cancelTokenSource: cancelTokenSource.current,
    })
      .then(() => {
        deleteCartMessageAlternative({
          deliveryDate,
          messageId: messageIdsToDelete,
          sku: messageIdsToDelete,
          cancelTokenSource: cancelTokenSource.current,
        });
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);

          message.error(messageData.error.cart.addAllAlternatives);
        }
      });
  };

  return (
    messageCount > 0 && (
      <Spin spinning={isLoading} size="large">
        <Collapse
          expandIcon={() => <Arrow className="icon iconCollapse" />}
          expandIconPosition="end"
          // open panel on larger screens by default
          defaultActiveKey={
            isLargeScreen && type === "deletedItems" ? "1" : null
          }
          className="collapseAlert"
        >
          <Collapse.Panel
            key="1"
            className="collapseAlertItem"
            header={
              <span className="collapseAlertHeading">
                <span className="collapseAlertHeadingText">
                  <span className="collapseAlertHeadingArticle">
                    {messageCount} Artikel
                  </span>{" "}
                  {type === "deletedItems"
                    ? `${
                        messageCount > 1 ? "sind" : "ist"
                      } im Warenkorb nicht mehr
                      verfügbar.`
                    : `${messageCount > 1 ? "haben" : "hat"} einen abweichenden
                      Bestand.`}
                </span>
              </span>
            }
            extra={
              showAlternativesButton &&
              type === "deletedItems" && (
                <button
                  type="button"
                  disabled={isLoading}
                  className="button buttonText collapseAlertButton"
                  onClickCapture={(e) => addAllAlternatives(e)}
                >
                  Alle Alternativen in den Warenkorb
                </button>
              )
            }
          >
            {deletedOrModifiedProductMessages.map(
              (deletedOrModifiedProductMessage: CartMessageEntry) => {
                return (
                  <DeletedOrChangedCartItemEntry
                    key={deletedOrModifiedProductMessage.id}
                    cartMessage={deletedOrModifiedProductMessage}
                    hasAlternativesButton={
                      type === "deletedItems" && showAlternativesButton
                    }
                    hasPreorderBasket={
                      type === "deletedItems" && showPreorderBasket
                    }
                    cancelTokenSource={cancelTokenSource.current}
                  />
                );
              }
            )}
          </Collapse.Panel>
        </Collapse>
      </Spin>
    )
  );
}

export default DeletedOrChangedCartItemList;
