import { ProductsMetaData } from "../../types/filters";

// This will be set to Redux an initialization
const initialState: ProductsMetaData = {
  attributeNames: {},
  filters: {
    availableFilters: [],
    filterStates: {},
    activeId: -1,
  },
};

const productsMetaDataReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "products/set-attribute-names": {
      const newAttributeNames =
        payload && Object.keys(payload)?.length ? payload : {};

      return {
        ...state,
        attributeNames: {
          ...state.attributeNames,
          ...newAttributeNames,
        },
      };
    }

    case "filter/set-product-filter": {
      if (JSON.stringify(payload) !== JSON.stringify(state.filters)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            ...payload,
          },
        };
      }

      return state;
    }

    case "filter/set-all":
      return {
        ...state,
        filters: {
          ...state.filters,
          filterStates: payload,
        },
      };

    case "filter/reset-all":
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      };

    case "filter/reset-without-defaults":
      return {
        ...state,
        filters: {
          ...initialState.filters,
          filterStates: payload,
        },
      };

    /*
     * update a single filter
     * @example: payload should look like { filterName: value }
     */
    case "filter/set-single":
      return {
        ...state,
        filters: {
          ...state.filters,
          filterStates: {
            ...state.filters.filterStates,
            ...payload,
          },
        },
      };

    case "filter/set-active-category-id":
      return {
        ...state,
        filters: {
          ...state.filters,
          activeId: payload,
        },
      };

    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default productsMetaDataReducer;
