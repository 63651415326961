import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { pageTitleSuffix, pageTitles, routePathNames } from "../../appConfig";
import { RootState } from "../../types/rootState";
import { isTokenExpired } from "../../utils/authTokens";
import getUrlReferrerString from "../../utils/getUrlReferrerString";
import TrackingHelmet from "../Matomo/TrackingHelmet";
import LoaderComponent from "../loader/loadingComponent";
import BusinessUnitModal from "../molecules/BusinessUnitModal";
import FooterLegalLinks from "../navigation/FooterLegalLinks";
import LoginForm from "./LoginForm";
import LoginHeader from "./LoginHeader";

interface LoginState {
  referrer: string;
}

/**
 * login component
 * checks if the users token is still valid
 * @constructor
 */
const Login: React.FC = () => {
  const location = useLocation();

  const { accessToken, expiresInMilliseconds } = useSelector(
    (state: RootState) => state.auth
  );
  const {
    id: userId,
    businessUnit,
    businessUnits,
  } = useSelector((state: RootState) => state.userData);

  const [redirectUrl, setRedirectUrl] = useState("");

  /**
   * redirect user if authed
   */
  useEffect(() => {
    if (
      accessToken &&
      !isTokenExpired(expiresInMilliseconds) &&
      userId &&
      businessUnit
    ) {
      const referrer = (location?.state as LoginState)?.referrer;

      // maybe it is not set, so check if a referrer query is set to the url
      const urlReferrerString = getUrlReferrerString(location.search);

      setRedirectUrl(referrer || urlReferrerString || routePathNames.dashboard);
    }
  }, [accessToken, businessUnit, expiresInMilliseconds, location, userId]);

  if (businessUnits && accessToken && !isTokenExpired(expiresInMilliseconds)) {
    return redirectUrl ? (
      <Navigate to={redirectUrl} />
    ) : (
      <>
        <LoaderComponent />
        <BusinessUnitModal visible />;
      </>
    );
  }
  return (
    <>
      <TrackingHelmet title={pageTitles.login} suffix={pageTitleSuffix} />

      <div className="login">
        <Row
          xs={12}
          md={3}
          className="login-header-row"
          gutter={[
            { xs: 8, sm: 16, md: 32, lg: 32 },
            { xs: 8, sm: 16, md: 32, lg: 32 },
          ]}
        >
          <Col xs={12} md={2}>
            <LoginHeader />
          </Col>
        </Row>
        <Row className="login-welcome">
          <Col xs={12}>
            <h1>
              HERZLICH&nbsp;
              <br className="login-welcome-break" />
              WILLKOMMEN!
            </h1>
          </Col>

          <Col xs={12}>
            <h2>IN IHREM WEILING-PORTAL</h2>
          </Col>
        </Row>
        <LoginForm />
        <FooterLegalLinks navClassName="login-footer-menu" />
      </div>
    </>
  );
};
export default Login;
