import React, { useEffect, useRef } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Splide, SplideSlide } from "@splidejs/react-splide";
import clsx from "clsx";
import getCssVariable from "../../utils/getCssVariable";
import { ProductImage } from "../product";

interface Props {
  defaultImages: Record<string, any>;
  name: string;
  containerClassName?: string;
  imageClassName?: string;
}

const imageSliderOptions = {
  type: "loop",
  pagination: true,
  arrows: false,
  gap: 0,
  padding: 0,
  lazyLoad: true,
};

const thumbnailSliderOptions = {
  type: "slide",
  gap: 50,
  padding: 0,
  isNavigation: true,
  arrows: true,
  pagination: false,
  focus: 0,
  perPage: 3,
  lazyLoad: true,
  breakpoints: {
    [getCssVariable("screen-sm-max", true)]: {
      destroy: true,
    },
    [getCssVariable("screen-md-max", true)]: {
      perPage: 2,
      gap: 10,
    },
  },
};

const ProductImageSlider: React.FC<Props> = ({
  defaultImages,
  name,
  containerClassName,
  imageClassName,
}: Props) => {
  const imageSlider = useRef(null);
  const thumbnailSlider = useRef(null);

  // make sure both are synced
  useEffect(() => {
    if (imageSlider.current && thumbnailSlider.current) {
      imageSlider.current.sync(thumbnailSlider.current.splide);
    }
  }, [imageSlider, thumbnailSlider]);

  // Set focus on thumbnailSlider when component is loaded
  useEffect(() => {
    const sliderRoot = thumbnailSlider.current?.splide?.root || false;
    if (sliderRoot) {
      (sliderRoot as HTMLElement).focus();
    }
  }, []);

  // if no images are present, dont initialize
  if (!defaultImages?.images?.length) {
    return null;
  }

  return (
    <div className={clsx("productImageSlider", containerClassName)}>
      <div className="productImageSliderMain">
        <Splide ref={imageSlider} options={imageSliderOptions}>
          {defaultImages.images.map((image: any) => (
            <SplideSlide key={image.externalUrlLarge}>
              <ProductImage
                imageSet={image}
                alt={name}
                imageType="detail"
                className={imageClassName}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>

      <div className="productImageSliderThumbnail hidden-sm-down">
        <Splide ref={thumbnailSlider} options={thumbnailSliderOptions}>
          {defaultImages.images.map((image: any) => (
            <SplideSlide key={image.externalUrlSmall}>
              <ProductImage
                imageSet={image}
                alt={name}
                imageType="thumbnail"
                className={imageClassName}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default ProductImageSlider;
