import React from "react";
import { productAttributes } from "../../api/productAttributes";
import YoutubeVideo from "../video/YoutubeVideo";
import { ProductData } from "../../types/productData";

interface Props {
  attributes: ProductData["attributes"];
  name: ProductData["name"];
}

/**
 * product video wrapper
 * @param attributes
 * @param name
 * @constructor
 */
function ProductVideo({ attributes, name }: Props) {
  const { [productAttributes.mediaVideo]: videoUrl } = attributes;

  return videoUrl ? <YoutubeVideo title={name} url={videoUrl} /> : null;
}

export default ProductVideo;
