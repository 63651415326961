const initialState: any = {
  sidebars: <Record<string, { activeIndex: number }>>{},
};

const sidebarReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "sidebar/set-active-index": {
      const { name, activeIndex } = payload;

      if (!name || !Number.isInteger(activeIndex)) {
        return state;
      }

      return {
        ...state,
        sidebars: { ...(state?.sidebars || {}), [name]: { activeIndex } },
      };
    }

    default:
      return state;
  }
};

export default sidebarReducer;
