import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { locationSearchQueryParameter } from "../appConfig";
import getCartDeliveryDateSlice from "../state/actions/getCartDeliveryDate";

/**
 * get either the deliveryDate passed by the URL or from store
 * should be used, if the view depends on the date but should not update
 * if one of the two dates changes, e.g. if a useEffect relies on the deliveryDate
 * @return {Array<string>}
 */
const useGetDeliveryDate = () => {
  const { search } = useLocation();
  const deliveryDateQuery =
    queryString.parse(search)?.[locationSearchQueryParameter.deliveryDate];
  const reduxDeliveryDate = useSelector(getCartDeliveryDateSlice);

  /*
   * return the query date as the "wanted" deliveryDate
   * updates only of the return value changes
   */
  const deliveryDate = useMemo(() => {
    return deliveryDateQuery ? String(deliveryDateQuery) : reduxDeliveryDate;
  }, [reduxDeliveryDate, deliveryDateQuery]);

  // return both variables to be able to make comparisons
  return [deliveryDate, reduxDeliveryDate];
};

export default useGetDeliveryDate;
