import getContentfulContentType from "./getContentfulContentType";
import { GetEntriesParameters } from "../../types/contentfulGetEntriesParameters";

/**
 * get a single contenful entry based on the passed config
 * @param contentfulEntryConfig {GetEntriesParameters}
 */
const getSingleContentTypeEntry = (
  contentfulEntryConfig: GetEntriesParameters
) =>
  getContentfulContentType(contentfulEntryConfig).then((response: any) => {
    if (!response?.length) {
      return Promise.reject(response);
    }

    /*
     * if there is a response, there is always just one hit
     * due to the filtering by inclusion
     * @see contentfulContentTypes in appConfig.ts
     */
    return response[0];
  });

export default getSingleContentTypeEntry;
