import React from "react";
import clsx from "clsx";
import { parsePriceValueToLocalPrice } from "../../utils/localizedNumberFormat";

interface Props {
  price: number;
  currency?: string;
  className?: string;
  tag?: string;
  baseUnit?: string;
}

const Price: React.FC<Props> = function Price({
  price,
  currency = "EUR",
  className,
  tag = "span",
  baseUnit,
}: Props) {
  const TagName = tag;

  return (
    <TagName className={clsx("price", className)}>
      {parsePriceValueToLocalPrice(price, currency)}
      {baseUnit && <span className="priceBaseUnit">&nbsp;/ {baseUnit}</span>}
    </TagName>
  );
};

export default Price;
