import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { RadioChangeEvent } from "antd";
import { Entry } from "contentful";
import moment from "moment/moment";

import { ContentBlock } from "../../atoms";
import useGetImpulsaktionen from "../../../hooks/useGetImpulsaktionen";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import BackButton from "../../backButton/BackButton";
import ImpulseTopMenu from "./ImpulseTopMenu";
import ButtonBackToTop from "../../buttons/ButtonBackToTop";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import ImpulseIntro from "./ImpulseIntro";
import ImpulseMainTeaser from "./ImpulseMainTeaser";
import ImpulseDownload from "./ImpulseDownload";
import ImpulseTeaser from "./ImpulseTeaser";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";
import { ContentfulImpulsaktionEntry } from "../../../types/contentfulImpulsaktion";

interface ImpulseProps {
  className?: string;
}

const Impulse: React.FC<ImpulseProps> = (props: ImpulseProps) => {
  const { className } = props;

  const { entries: impulsaktionen } = useGetImpulsaktionen(10, 0);

  const [impulsaktionIndex, setImpulsaktionIndex] = useState<number>(0);
  const [impulsaktion, setImpulsaktion] =
    useState<Entry<ContentfulImpulsaktionEntry>>(null);

  const activeMonth = useMemo(
    () => moment(impulsaktion?.fields?.start_date).format("MMMM"),
    [impulsaktion]
  );

  const otherTeasers = useMemo(
    () => impulsaktion?.fields?.other_teaser || [],
    [impulsaktion]
  );

  const opportunitiesTeasers = useMemo(
    () => impulsaktion?.fields?.opportunities_teaser || [],
    [impulsaktion]
  );

  const handleImpulsaktionChange = (e: RadioChangeEvent) => {
    const index = e?.target?.value || 0;
    setImpulsaktionIndex(index);
    setImpulsaktion(impulsaktionen?.[index] || null);
  };

  useEffect(() => {
    setImpulsaktion(impulsaktionen?.[impulsaktionIndex] || null);
  }, [impulsaktionen, impulsaktionIndex]);

  return (
    <>
      <TrackingHelmet title={pageTitles.impulse} suffix={pageTitleSuffix} />

      <ShopCategoryNavigation />

      <ContentBlock
        showHeader={false}
        className={clsx("impulse-page", className)}
      >
        <BackButton />

        <ImpulseTopMenu
          impulsaktionen={impulsaktionen}
          activeImpulsaktionIndex={impulsaktionIndex}
          onChange={handleImpulsaktionChange}
        />

        <ImpulseIntro impulsaktion={impulsaktion} />

        <ImpulseMainTeaser impulsaktion={impulsaktion} />

        <ImpulseDownload impulsaktion={impulsaktion} className="mb-l mt-2xl" />

        <ImpulseTeaser
          title={`Weitere Themen im ${activeMonth}`}
          teasers={otherTeasers}
          className="mb-l mt-2xl"
        />

        <ImpulseTeaser
          title="Ganzjährige Verkaufschancen"
          teasers={opportunitiesTeasers}
          className="mb-l mt-2xl"
        />
      </ContentBlock>

      <ButtonBackToTop />
    </>
  );
};

export default Impulse;
