import React from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";

import { RootState } from "../../../types/rootState";
import { ReactComponent as EuroIcon } from "../../../static/svg/euro.svg";
import { ReactComponent as EuroCrossedOutIcon } from "../../../static/svg/euro-crossed-out.svg";

interface PricesSwitchProps {
  className?: string;
}

const PricesSwitch: React.FC<PricesSwitchProps> = (
  props: PricesSwitchProps
) => {
  const { className } = props;

  const dispatch = useDispatch();

  const hidePrices = useSelector(
    (state: RootState) => state?.appSettings?.hidePrices || false
  );

  return (
    <div className={clsx("prices-switch", className)}>
      <Switch
        checked={hidePrices}
        checkedChildren={<EuroCrossedOutIcon />}
        unCheckedChildren={<EuroIcon />}
        onChange={(checked) =>
          dispatch({
            type: "app-settings/set-hide-prices",
            payload: { hidePrices: checked },
          })
        }
      />
    </div>
  );
};

export default PricesSwitch;
