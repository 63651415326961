const initialState = {
  component: false,
  get: false,
  post: false,
};

const networkActivityReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "network/loading-component":
      return {
        ...state,
        component: payload,
      };
    case "network/loading-get":
      return {
        ...state,
        get: payload,
      };
    case "network/loading-post":
      return {
        ...state,
        post: payload,
      };
    default:
      return state;
  }
};

export default networkActivityReducer;
