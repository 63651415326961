import React, { useMemo } from "react";

import clsx from "clsx";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

import { ContentBlock, PictureTile, QuickLink } from "../../atoms";
import { RootState } from "../../../types/rootState";
import useGetWebshopTeaser from "../../../hooks/useGetWebshopTeaser";
import HrDivider from "../../divider/Divider";
import Picture from "../../picture/Picture";

interface WebshopBottomTeaserWidgetProps {
  className?: string;
}

const WebshopBottomTeaserWidget: React.FC<WebshopBottomTeaserWidgetProps> = (
  props: WebshopBottomTeaserWidgetProps
) => {
  const { className } = props;

  const { stockName } = useSelector((state: RootState) => state?.userData);

  const { isLoading, hasError, entries } = useGetWebshopTeaser(
    3,
    0,
    "Unten",
    stockName
  );

  const teaser = useMemo(() => entries?.[0], [entries]);

  if (!teaser) {
    return <></>;
  }

  return (
    <ContentBlock
      className={clsx("webshop-bottom-teaser-widget", className)}
      showHeader={false}
    >
      <HrDivider size={2} />
      <Row>
        {!isLoading && !hasError && !!teaser && (
          <Col span={12}>
            <PictureTile
              title={null}
              picture={
                <Picture
                  imageScreenXs={`${teaser.imageMobileUrl}?fit=fill&f=top&r=10&fm=webp&q=40`}
                  imageScreenSm={`${teaser.imageMobileUrl}?fit=fill&f=top&r=10&fm=webp&q=60`}
                  imageScreenMd={`${teaser.imageDesktopUrl}?fit=fill&f=top&r=10&fm=webp&q=80`}
                  imageScreenLg={`${teaser.imageMobileUrl}?fit=fill&f=top&r=10&fm=webp`}
                  imageScreenXl={`${teaser.imageDesktopUrl}?fit=fill&f=top&r=10&fm=webp`}
                />
              }
              link={
                <QuickLink
                  label={teaser.callToAction || "Mehr erfahren"}
                  href={teaser.link}
                  linkType="primary--outlined"
                />
              }
              className="webshop-bottom-teaser-widget__picture-tile--large"
            />
          </Col>
        )}
      </Row>
    </ContentBlock>
  );
};

export default WebshopBottomTeaserWidget;
