import getNearestVolumePrice from "./getNearestVolumePrice";
import { PriceSet } from "../../types/productData";

/**
 * finds the price matching the total quantity
 * returns {Number}
 * @param price {Number}
 * @param volumePrices {Array}
 * @param totalOrderedQuantity {Number}
 */
const getCheapestPrice = (
  price: number,
  volumePrices: PriceSet[],
  totalOrderedQuantity: number
) => {
  const nearestVolumePrice: any = getNearestVolumePrice(
    volumePrices,
    totalOrderedQuantity
  );

  if (
    nearestVolumePrice &&
    totalOrderedQuantity >= nearestVolumePrice?.quantity
  ) {
    return nearestVolumePrice?.price;
  }

  return price;
};

export default getCheapestPrice;
