import React, { useMemo } from "react";

import clsx from "clsx";

import useGetWeekplannerDaysToShow from "../../../../../hooks/useGetWeekplannerDaysToShow";
import useGetWeekplannerWeekDaysData from "../../../../../hooks/useGetWeekplannerWeekDaysData";
import WeekplannerItemsHeaderCart from "./WeekplannerItemsHeaderCart";

interface WeekplannerItemsHeaderCartsProps {
  className?: string;
}

const WeekplannerItemsHeaderCarts: React.FC<
  WeekplannerItemsHeaderCartsProps
> = (props: WeekplannerItemsHeaderCartsProps) => {
  const { className } = props;

  const { filteredDaysToShow } = useGetWeekplannerDaysToShow();
  const { weekDaysData } = useGetWeekplannerWeekDaysData();

  const filteredWeekDaysData = useMemo(
    () =>
      weekDaysData.filter(
        (weekDayData: any) => !!filteredDaysToShow?.[weekDayData.day]
      ),
    [filteredDaysToShow, weekDaysData]
  );

  if (filteredWeekDaysData?.length < 1) {
    return null;
  }

  return (
    <div className={clsx("weekplanner-items-header-carts", className)}>
      {filteredWeekDaysData.map((weekDayData: any) => {
        const { apiDate, cartId } = weekDayData;

        return (
          <WeekplannerItemsHeaderCart
            key={`weekplanner-items-header-cart_${apiDate}_${cartId}`}
            weekdayData={weekDayData}
            deliveryDate={apiDate}
          />
        );
      })}
    </div>
  );
};

export default WeekplannerItemsHeaderCarts;
