import React from "react";
import { Layout, Pagination, Row, Col } from "antd";
import clsx from "clsx";

interface PaginationWrapperProps {
  className?: string;
  innerClassName?: string;
  current?: number;
  pageSize?: number;
  onChange?: any;
  size?: "default" | "small";
  showSizeChanger?: boolean;
  total?: number;
}

const PaginationWrapper: React.FC = ({
  className,
  innerClassName,
  current,
  pageSize,
  onChange,
  size = "default",
  showSizeChanger = false,
  total,
}: PaginationWrapperProps) => {
  return (
    <Layout className={clsx("container-layout", className)}>
      <Row>
        <Col span={12}>
          <div className={clsx("paginationWrapper", innerClassName)}>
            <Pagination
              onChange={onChange}
              current={current}
              total={total}
              pageSize={pageSize}
              size={size}
              showSizeChanger={showSizeChanger}
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default PaginationWrapper;
