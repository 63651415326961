import { store } from "../../store";

const setActiveInfoCenterFolder = (payload: any) => {
  store.dispatch({
    type: "infoCenter/set-active",
    payload,
  });
};

export default setActiveInfoCenterFolder;
