import { Badge } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import LinkToCart from "../../Router/LinkToCart";
import { ReactComponent as Cart } from "../../static/svg/cart.svg";
import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import { parsePriceValueToLocalPrice } from "../../utils/localizedNumberFormat";

interface Props {
  className?: string;
  badgeOverflowCount?: number;
}

const CartLinkButton: React.FC<Props> = ({
  className,
  badgeOverflowCount = 999,
}: Props) => {
  const deliveryDate = useSelector(getCartDeliveryDateSlice);
  const { cartItemsTotalValue = 0 } = useSelector(
    (state: any) => state.currentCart
  );

  return (
    <Badge
      showZero
      overflowCount={badgeOverflowCount}
      count={parsePriceValueToLocalPrice(cartItemsTotalValue, "EUR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
      title={`Zahlbetrag: ${parsePriceValueToLocalPrice(
        cartItemsTotalValue,
        "EUR",
        {
          maximumFractionDigits: 2,
        }
      )}`}
      scrollNumberPrefixCls="bgColor-secondary align-scroll-number ant-scroll-number"
      className={clsx(className)}
    >
      <LinkToCart deliveryDate={deliveryDate}>
        <Cart className="icon iconCart" />
      </LinkToCart>
    </Badge>
  );
};

export default CartLinkButton;
