import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import { message } from "antd";
import moment from "moment/moment";

import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../api/getCancelTokenSource";
import podcastMapper from "../utils/podcastMapper";
import useCancelAxiosOnUnmount from "./useCancelAxiosOnUnmount";
import handleError from "../utils/handleError";
import { cacheTtls, messageData } from "../appConfig";
import { RootState } from "../types/rootState";
import { Podcast } from "../types/podcast";

const useGetPodcasts = () => {
  const { expirationTimestamp = null } = useSelector(
    (state: RootState) => state.podcasts
  );
  const dispatch = useDispatch();

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [isLoading, setIsLoading] = useState(false);

  const fetchAndParsePodcast = useCallback(async () => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    return axios
      .get("https://biofuerdieohren.podigee.io/feed/mp3", {
        cancelToken: cancelTokenSource.current.token,
      })
      .then((response: any): Podcast => {
        const parser = new DOMParser();
        const { data } = response;
        const xmlDOM = parser.parseFromString(data, "application/xml");
        return podcastMapper(xmlDOM);
      })
      .then((podcast: Podcast) => {
        dispatch({
          type: "podcast/set-podcast-consumer",
          payload: {
            ...podcast,
            episodes: podcast?.episodes?.slice(0, 6) || [],
          },
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.warning(messageData.warning.podcast.missingContent);
          handleError(error);
        }
      });
  }, [dispatch]);

  const fetchPodcast = useCallback(async () => {
    setIsLoading(true);
    fetchAndParsePodcast()
      .then(() => {
        setIsLoading(false);
        dispatch({
          type: "podcast/set-expiration-timestamp",
          payload: moment().unix() + cacheTtls.podcast,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          message.warning(messageData.warning.podcast.missingContent);
          handleError(error);
        }
      });
  }, [fetchAndParsePodcast, dispatch]);

  useEffect(() => {
    if (expirationTimestamp > moment().unix()) {
      return;
    }

    fetchPodcast().then();
  }, [fetchPodcast, expirationTimestamp]);

  return isLoading;
};

export default useGetPodcasts;
