import { UserListEntry } from "../../types/userListProducts";
import {
  FavouriteList,
  FavouriteListWithNumberId,
} from "../../types/favouriteList";

const initialState = {
  activeList: <FavouriteList>null,
  favouriteLists: <FavouriteList[]>[],
  favourites: <UserListEntry[]>[],
};

const favouriteListReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "favouriteList/set-active-list": {
      return {
        ...state,
        activeList: payload?.activeList,
      };
    }

    case "favouriteList/set-lists": {
      if (!payload) {
        return state;
      }

      const favouriteLists =
        payload?.favouriteLists?.map(
          (favouriteList: FavouriteListWithNumberId) => ({
            ...favouriteList,
            idFavouriteList: favouriteList.idFavouriteList.toString(),
          })
        ) || [];

      return {
        ...state,
        favouriteLists,
        favourites: payload?.favourites || [],
      };
    }

    // remove a single sku from the list
    case "favouriteList/remove-sku": {
      const restSkuList = state.favourites?.filter((entry) =>
        payload.some((sku: string) => entry.sku !== sku)
      );

      return {
        ...state,
        favourites: restSkuList,
      };
    }
    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default favouriteListReducer;
