/**
 * fetch any url and return the content as plain text
 * @param url {string}
 */
const getPlainText = async (url: string) => {
  const plainString = await fetch(url).then((res) => res.text());

  return plainString || "";
};

export default getPlainText;
