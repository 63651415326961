import { PriceSet } from "../../types/productData";

/**
 * find the price object which is closest to the current total order quantity
 * @param volumePrices {PriceSet[]}
 * @param totalOrderQuantity {Number}
 * @param getQuantity {boolean}
 * @returns {Array|Number}
 */
const getNearestVolumePrice = (
  volumePrices: PriceSet[],
  totalOrderQuantity: number,
  getQuantity = false
) => {
  if (!volumePrices?.length) {
    return {};
  }

  const nearestVolumePriceObject = volumePrices.reduce(
    (prevVolumePrice, currVolumePrice) =>
      totalOrderQuantity >= prevVolumePrice.quantity &&
      totalOrderQuantity < currVolumePrice.quantity
        ? prevVolumePrice
        : currVolumePrice
  );

  return getQuantity
    ? nearestVolumePriceObject?.quantity
    : nearestVolumePriceObject;
};

export default getNearestVolumePrice;
