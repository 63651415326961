import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import useSetCategoryNavigationVisibility from "../../hooks/useSetCategoryNavigationVisibility";

interface Props {
  title: string;
  subTitle: boolean;
  className?: string;
  route?: string;
}

const NavNode: React.FC<Props> = function NavNode({
  title,
  subTitle,
  className,
  route,
}: Props) {
  const setCategoryNavigationVisibility = useSetCategoryNavigationVisibility();

  return subTitle ? (
    <>
      <span className="categoryTitle">{title}</span>
      <span>Übersicht</span>
    </>
  ) : (
    <Link
      to={route}
      className={clsx("categoryTitle", className)}
      onClick={() => {
        setCategoryNavigationVisibility(false);
      }}
    >
      {title}
    </Link>
  );
};

export default NavNode;
