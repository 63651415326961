const deleteCategoryFromCategoryData = (
  categoryData: any,
  deleteCategoryKey: string
) => {
  // eslint-disable-next-line no-param-reassign
  categoryData.children =
    categoryData?.children?.filter(
      (item: any) => item?.categoryKey !== deleteCategoryKey
    ) || [];

  categoryData.children?.forEach((_: any, i: number) =>
    deleteCategoryFromCategoryData(categoryData.children[i], deleteCategoryKey)
  );
};

export default deleteCategoryFromCategoryData;
