import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { Checkbox } from "antd";

import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { Filter } from "../../../types/filters";
import { RootState } from "../../../types/rootState";
import { updateFilterStates } from "../../products/ProductsFilter/filterFunctions";

interface CheckboxFilterProps extends Filter {
  display?: "inline" | "filter-bar" | "sidebar";
  view?: string;
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = (props) => {
  const { name, localizedName, display = "inline", view = "" } = props;

  const navigate = useNavigate();
  const { search } = useLocation();

  const checked = useSelector(
    (state: RootState) =>
      state.productsMetaData.filters.filterStates[name] ?? false
  );

  const onChange = () => {
    updateFilterStates(navigate, name, !checked, search);
  };

  const { trackEvent } = useMatomo();

  return (
    <div className={clsx("checkbox-filter", `checkbox-filter--${display}`)}>
      <Checkbox
        checked={checked}
        onChange={() => {
          if (!checked && view === "weekplanner") {
            trackEvent({
              category: "filter",
              action: `filter activity in ${display}`,
              name: `${localizedName}`,
            });
          }
          onChange();
        }}
      >
        <span className={clsx("checkbox-filter__label")}>{localizedName}</span>
      </Checkbox>
    </div>
  );
};

export default CheckboxFilter;
