import React, { useMemo, useState } from "react";
import clsx from "clsx";
import getCssVariable from "../../utils/getCssVariable";

interface Props {
  imageScreenSm: string;
  alt?: string;
  className?: string;
  imageScreenXs?: string;
  imageScreenMd?: string;
  imageScreenLg?: string;
  imageScreenXl?: string;
  fallbackImage?: string;
}

/**
 * Picture component to easily create sourcesets
 * imageScreenSm is required to guarantee a visual
 * @param imageScreenXs {string}
 * @param imageScreenSm {string}
 * @param imageScreenMd {string}
 * @param imageScreenLg {string}
 * @param imageScreenXl {string}
 * @param className {string}
 * @param alt {string}
 * @param fallback {string}
 * @constructor
 */
const Picture: React.FC<Props> = function Picture({
  imageScreenSm,
  imageScreenXs = "",
  imageScreenMd = "",
  imageScreenLg = "",
  imageScreenXl = "",
  className,
  alt = "",
  fallbackImage = null,
}: Props) {
  const defaultImgSrc =
    imageScreenXs ||
    imageScreenSm ||
    imageScreenMd ||
    imageScreenLg ||
    imageScreenXl;

  const [imgSrc, setImgSrc] = useState<string>(defaultImgSrc);
  const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);

  /**
   * Handle image loading error (=> set fallback image if provided)
   */
  const handleLoadingError = (): void => {
    if (!hasLoadingError) {
      setHasLoadingError(true);

      if (fallbackImage) {
        setImgSrc(fallbackImage);
      }
    }
  };

  /**
   * Determine if to use fallback image or not
   * @return {boolean}
   */
  const useFallbackImage = useMemo(
    (): boolean => hasLoadingError && !!fallbackImage,
    [hasLoadingError, fallbackImage]
  );

  return (
    <>
      {imgSrc && (
        <picture className={clsx("picture", className)}>
          {imageScreenXl && (
            <source
              srcSet={useFallbackImage ? fallbackImage : imageScreenXl}
              media={`(min-width: ${getCssVariable("screen-xl")})`}
            />
          )}

          {imageScreenLg && (
            <source
              srcSet={useFallbackImage ? fallbackImage : imageScreenLg}
              media={`(min-width: ${getCssVariable("screen-lg")})`}
            />
          )}

          {imageScreenMd && (
            <source
              srcSet={useFallbackImage ? fallbackImage : imageScreenMd}
              media={`(min-width: ${getCssVariable("screen-md")})`}
            />
          )}

          {imageScreenSm && (
            <source
              srcSet={useFallbackImage ? fallbackImage : imageScreenSm}
              media={`(min-width: ${
                parseInt(getCssVariable("screen-xs", true), 10) + 1
              }px)`}
            />
          )}

          {imageScreenXs && (
            <source
              srcSet={useFallbackImage ? fallbackImage : imageScreenXs}
              media={`(max-width: ${getCssVariable("screen-xs")})`}
            />
          )}

          <img
            src={imgSrc}
            alt={alt}
            loading="lazy"
            decoding="async"
            onError={handleLoadingError}
          />
        </picture>
      )}
    </>
  );
};

export default Picture;
