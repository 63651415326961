import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../types/rootState";

const QUICK_FILTERS_CLASS = ".filter-bar__quick-filters";
const QUICK_FILTERS_INNER_CLASS = `${QUICK_FILTERS_CLASS}__inner`;

const useGetFilterBarVisibleFilterNames = () => {
  const [visibleFilterNames, setVisibleFilterNames] = useState<string[]>([]);
  const { availableFilters, filterStates } = useSelector(
    (state: RootState) => state?.productsMetaData?.filters
  );

  const getFilterBarVisibleFilterNames = (): string[] => {
    const quickFilters = document.querySelector(QUICK_FILTERS_CLASS);
    const quickFiltersInner = document.querySelector(QUICK_FILTERS_INNER_CLASS);

    if (!quickFilters || !quickFiltersInner) {
      return [];
    }

    const quickFiltersClientWidth = quickFilters?.clientWidth || 0;
    const quickFiltersInnerStyle = window.getComputedStyle(quickFiltersInner);
    const quickFiltersInnerGap =
      Number(quickFiltersInnerStyle?.gap?.replace("px", "")) || 0;

    let usedWidth = 0;

    const visibleFilters = Array.from(
      document.querySelectorAll(`${QUICK_FILTERS_INNER_CLASS} .dynamic-filter`)
    )?.filter((filter) => {
      const filterStyles = window.getComputedStyle(filter);
      const filterWidth =
        Number(filterStyles?.width.replace("px", "")) ||
        filter?.clientWidth ||
        0;
      usedWidth += filterWidth + quickFiltersInnerGap;
      return usedWidth < quickFiltersClientWidth;
    });

    return (
      visibleFilters?.map(
        (filter: any) => filter?.dataset?.filtername as string
      ) || []
    );
  };

  useEffect(() => {
    const resizeHandler = () => {
      setVisibleFilterNames(getFilterBarVisibleFilterNames);
    };

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVisibleFilterNames(getFilterBarVisibleFilterNames);
    }, 50);
  }, [filterStates, availableFilters]);

  return {
    visibleFilterNames,
  };
};

export default useGetFilterBarVisibleFilterNames;
