import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { cartsEndpoint } from "../endpoints";

interface DeleteCartMessage {
  deliveryDate: string;
  messageId: string | string[];
  cancelTokenSource: CancelTokenSource;
}

/**
 * delete cart message for specific cart
 * @param deliveryDate {string}
 * @param messageId {string|string[]}
 * @param cancelTokenSource {CancelTokenSource}
 */
const deleteCartMessage = ({
  deliveryDate,
  messageId,
  cancelTokenSource,
}: DeleteCartMessage) => {
  const messageIds = Array.isArray(messageId) ? messageId.join(",") : messageId;

  return axiosWithAuth().delete(
    `${cartsEndpoint}/${deliveryDate}/messages/${messageIds}`,
    {
      cancelToken: cancelTokenSource.token,
    }
  );
};

export default deleteCartMessage;
