import queryString from "query-string";
import { sortArrayOfObjectsByObjectKey } from "../../../utils/sorting";
import { locationSearchQueryParameter } from "../../../appConfig";
import { getFilterStatesWithoutDefaults } from "../../../api/products/getProductsFilters";
import storeDispatch from "../../../state/actions/storeDispatch";
import { store } from "../../../store";
import { Filter } from "../../../types/filters";

/*
 * constant array to filter and sort the primary filters
 * used for construction filters
 * and for order of filters
 * based on the productAttributes, but due to a strange renaming by ElasticSearch
 * every underscore gets omitted
 */
const productTypeKey = "filterCategory";

/**
 * split filter data into max. 3 parts
 * @param facets {Array}
 * @param slots
 * return {Object}
 */
const createFilterDataObjects = (facets: Record<string, any>[], slots: any) => {
  let valueDataSlots: any = [];

  if (!facets || !facets.length) {
    return {
      valueDataSlots,
    };
  }

  slots?.forEach((currentSlot: any, slotKey: number) => {
    const { slot, caption, captionOverride } = currentSlot;
    let slotFiltersArr: any = [];

    facets?.forEach((currentFacet: any) => {
      const { config } = currentFacet;

      if (
        config.slot === slot &&
        currentFacet.min < 2 &&
        currentFacet.max < 2 &&
        currentFacet.min !== currentFacet.max
      ) {
        slotFiltersArr = {
          ...slotFiltersArr,
          [currentFacet.name]: {
            name: currentFacet.name,
            localizedName: currentFacet.localizedName,
            value: false,
            priority: config.priority,
          },
        };
      } else if (config.slot === slot) {
        slotFiltersArr = {
          ...slotFiltersArr,
          [currentFacet.name]: {
            name: currentFacet.name,
            localizedName: currentFacet.localizedName,
            values: currentFacet.values,
            priority: config.priority,
          },
        };
      }
    });

    // slot filter priority order
    slotFiltersArr = Object.values(slotFiltersArr)
      .sort((a: any, b: any) =>
        sortArrayOfObjectsByObjectKey({
          a,
          b,
          objectKey: "priority",
          sortOrder: "desc",
        })
      )
      .reduce(
        (accumulator: any, currentValue: any) => ({
          ...accumulator,
          [currentValue.name]: currentValue,
        }),
        {}
      );

    valueDataSlots = {
      ...valueDataSlots,
      [slotKey]: {
        slot,
        name: caption,
        nameOverride: captionOverride,
        slotFilters: slotFiltersArr,
      },
    };
  });

  return {
    valueDataSlots,
  };
};

/**
 * update the URl with query parameters
 * @param navigate
 * @param filterName {string}
 * @param value {Array<String> | boolean}
 * @param searchString
 */
const updateFilterStates = (
  navigate: any,
  filterName: string,
  value: string[] | boolean,
  searchString: string
) => {
  const parsedUrlSearchParameters = queryString.parse(searchString);
  let parameterValue;

  const { page, deliveryDate, filterConjunction } =
    locationSearchQueryParameter;

  // overwrite filterName to avoid name casting in attributes
  let filterNameOverWrite = filterName;
  if (filterName === "category") {
    filterNameOverWrite = "artikelartikelkategorienbeschreibung";
  }

  // typecast the bool from a checkbox to a string of "0" or "1"
  if (typeof value === "boolean") {
    parameterValue = Number(value).toString();
  } else {
    parameterValue = value.join(filterConjunction);
  }

  parsedUrlSearchParameters[filterNameOverWrite] = parameterValue;

  // Get current filter from available filters
  const availableFilters =
    store.getState()?.productsMetaData?.filters?.availableFilters || [];
  const currentFilter = availableFilters.find(
    (filter: Filter) => filter?.name === filterName
  );

  // If filter value is falsy, the query parameter gets omitted
  // Except it is part of a multi value field
  if (
    !parsedUrlSearchParameters[filterNameOverWrite] ||
    (parsedUrlSearchParameters[filterNameOverWrite] === "0" &&
      !currentFilter?.config?.isMultiValued)
  ) {
    delete parsedUrlSearchParameters[filterNameOverWrite];
  }

  if (filterNameOverWrite !== page && filterNameOverWrite !== deliveryDate) {
    // reset page when filters change
    parsedUrlSearchParameters[page] = "1";

    // store updated filter state
    storeDispatch("filter/set-single", { [filterNameOverWrite]: value });
  }

  navigate({
    search: queryString.stringify(parsedUrlSearchParameters),
  });
};

/**
 * reset search parameters
 * @param navigate {any}
 */
const resetFilterStates = (navigate: any) => {
  storeDispatch("filter/reset-all", null);

  navigate({ search: "" });
};

/**
 * reset search parameters, except the default parameters, that are required making a valid api call with filters
 * @param navigate
 * @param searchString
 */
const resetFilterStatesWithoutDefaults = (
  navigate: any,
  searchString: string
) => {
  const parsedUrlSearchParameters = queryString.parse(searchString);
  const newUrlSearchParameters = {
    deliveryDate: parsedUrlSearchParameters?.deliveryDate || null,
    deliveryDateFrom: parsedUrlSearchParameters?.deliveryDateFrom || null,
    deliveryDateTo: parsedUrlSearchParameters?.deliveryDateTo || null,
    page: 1,
    search: parsedUrlSearchParameters?.search || null,
    sortBy: parsedUrlSearchParameters?.sortBy || null,
    sortDirection: parsedUrlSearchParameters?.sortDirection || null,
  };

  const { page, search, ...rest } = newUrlSearchParameters;

  storeDispatch("filter/reset-without-defaults", rest);

  navigate({
    search: queryString.stringify(newUrlSearchParameters, { skipNull: true }),
  });
};

/**
 * checkbox handler
 * @param e {React.SyntheticEvent}
 * @param selectIdentifier {String}
 * @param navigate
 * @param searchString
 */
const checkboxFilterHandler = (
  e: any,
  selectIdentifier: string,
  navigate: any,
  searchString: string
) => {
  updateFilterStates(
    navigate,
    selectIdentifier,
    e.target.checked,
    searchString
  );
};

/**
 * Show or not "clear all filters" button.
 */
const showClearAllFiltersComponent = () =>
  Object.keys(getFilterStatesWithoutDefaults())?.length !== 0;

export {
  productTypeKey,
  createFilterDataObjects,
  updateFilterStates,
  resetFilterStates,
  resetFilterStatesWithoutDefaults,
  checkboxFilterHandler,
  showClearAllFiltersComponent,
};
