import { createStore, applyMiddleware } from "redux";
import { createMigrate, persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import * as Sentry from "@sentry/react";
import storage from "redux-persist/lib/storage";
import rootReducer from "./state/reducers/rootReducer";

const middlewares: any = [];

/**
 * use the enhancer to remove sensitive information
 * from being send to Sentry
 * @see {@link https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#redux-enhancer-options}
 */
// eslint-disable-next-line
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  /**
   * manipulate redux actions
   * currently, ignore all
   * //@param action
   */
  actionTransformer: () => {
    return null;
  },
  /**
   * manipulate redux state, which is passed as context
   * currently just pass user and cart ids
   * @param state
   */
  stateTransformer: (state) => {
    return {
      userData: {
        id: state.userData.id,
      },
      currentCartMetaData: {
        id: state.currentCartMetaData.id,
      },
    };
  },
});

/*
 * add migrations for future versions
 * version 1 returns the current state, so be aware of correct versioning
 * @see {@link https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md}
 */
const migrations = {
  1: (state: any) => state,
  2: (state: any) => {
    const { userData, termsAndConditions } = state;
    return {
      ...state,
      userData: {
        ...userData,
        termsAndConditions: {
          ...userData.termsAndConditions,
          prefillTaCCheckbox: undefined,
          acceptedTimestamp:
            userData?.termsAndConditions?.acceptedTimestamp || null,
        },
      },
      termsAndConditions: {
        timestamp: termsAndConditions?.timestamp || null,
      },
    };
  },
  3: (state: any) => ({
    ...state,
    podcasts: {
      akademie: undefined,
      verbraucher: undefined,
      academy: null,
      consumer: null,
      expirationTimestamp: null,
    },
  }),
  4: (state: any) => {
    const { userData } = state;
    return {
      ...state,
      userData: {
        ...userData,
        businessUnit: null,
        businessUnits: null,
      },
    };
  },
  5: (state: any) => {
    return {
      ...state,
      alreadyOrdered: {
        businessUnitKey: null,
        version: null,
        orderItemIndex: {},
      },
    };
  },
  6: (state: any) => {
    const { currentCart } = state;
    return {
      ...state,
      currentCart: {
        ...currentCart,
        cartItemsTotalValue: 0,
      },
    };
  },
  7: (state: any) => {
    const { priceTagList } = state;
    return {
      ...state,
      priceTagList: {
        ...priceTagList,
        selectedPriceTagItems: <string[]>[],
      },
    };
  },
  8: (state: any) => {
    const { sidebars } = state?.sidebar || {};
    return {
      ...state,
      sidebar: { sidebars: sidebars || {} },
    };
  },
  9: (state: any) => {
    const { userData } = state || {};
    const { businessUnit } = userData || {};

    return {
      ...state,
      userData: {
        ...userData,
        businessUnit: {
          ...businessUnit,
          deliveryDates: businessUnit?.deliveryDates || [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
          ],
        },
        deliveryDates: userData?.deliveryDates || [],
      },
    };
  },
};

const persistConfig = {
  key: "primary",
  version: Number(process.env?.REACT_APP_REDUX_MIGRATION) || -1,
  storage,
  migrate: createMigrate(migrations),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composedEnhancers = composeWithDevTools(
  applyMiddleware(...middlewares)
  // @TODO: Add Sentry
  // sentryReduxEnhancer
);

const store = createStore(persistedReducer, composedEnhancers);

const persistor = persistStore(store);

export { store, persistor };
