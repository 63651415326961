import { useState, useEffect } from "react";

/**
 * custom hook to listen for a given media query
 * @param mediaQuery {string}
 */
const useMedia = (mediaQuery: string) => {
  const [matches, setMatches] = useState(window.matchMedia(mediaQuery).matches);

  // Activity normally for componentDidMount + componentDidUpdate
  useEffect(() => {
    const media = window.matchMedia(mediaQuery);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);

    return () => media.removeEventListener("change", listener);
  }, [mediaQuery, matches]);

  // publish value for render
  return matches;
};

export default useMedia;
