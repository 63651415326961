import { useEffect, useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";
import getCancelTokenSource from "../api/getCancelTokenSource";
import requestCatchHandler from "../api/requestCatchHandler";

import getCartsByDate from "../api/cart/getCartsByDate";
import { ProductData } from "../types/productData";
import aggregateProductData from "../utils/aggregateProductData";
import { RootState } from "../types/rootState";

const useGetCarts = (
  deliveryDateFrom: string,
  deliveryDateTo: string,
  forceReload = false
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [carts, setCarts] = useState<any[]>([]);
  const [productData, setProductData] = useState<Array<ProductData>>(null);

  const { companyBusinessUnitKey } = useSelector(
    (state: RootState) => state?.userData?.businessUnit
  );

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();

    setIsLoading(true);

    getCartsByDate([deliveryDateFrom, deliveryDateTo], cancelTokenSource)
      .then((response) => {
        if (response.data) {
          const filteredCarts =
            response.data?.data?.filter(
              (cart: any) => cart?.attributes?.deliveryDateItems?.length > 0
            ) || [];
          setCarts(filteredCarts);
          setProductData(aggregateProductData(response.data?.included || []));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
        }
        requestCatchHandler(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [deliveryDateFrom, deliveryDateTo, companyBusinessUnitKey, forceReload]);

  return {
    isLoading,
    hasError,
    carts,
    productData,
  };
};

export default useGetCarts;
