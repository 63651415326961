import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { stocktakingEndpoint } from "../endpoints";
import { StocktakingData, StocktakingStatus } from "../../types/stocktaking";

interface PostStocktakingProps {
  referenceDate?: string;
  note?: string;
  status?: StocktakingStatus;
  cancelTokenSource: CancelTokenSource;
}

const postStocktaking = async (props: PostStocktakingProps) => {
  const { referenceDate, note, status, cancelTokenSource } = props;

  return axiosWithAuth()
    .post(
      stocktakingEndpoint,
      {
        data: {
          type: "stocktaking",
          attributes: { referenceDate, note, status },
        },
      },
      { cancelToken: cancelTokenSource.token }
    )
    .then((response): Promise<any> | StocktakingData => {
      if (!response?.data?.data || response.status !== 201) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data || {};

      return attributes as StocktakingData;
    });
};

export default postStocktaking;
