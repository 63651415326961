import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";
import { Space } from "antd";
import moment from "moment/moment";

import useHasDeliveryDateProductAlternative from "../../../../../../../hooks/useHasDeliveryDateProductAlternative";
import ProductPreorderModal from "../../../../../../product/ProductModal/ProductPreorderModal";
import ProductAlternativesModal from "../../../../../../product/ProductModal/ProductAlternativesModal";
import { ReactComponent as UnavailableIcon } from "../../../../../../../static/svg/shopping-cart-unavailable.svg";
import { ProductData } from "../../../../../../../types/productData";

interface WeekplannerItemUnavailableContentProps {
  deliveryDate: ProductData["deliveryDate"];
  nextAvailability: string;
  productData: ProductData;
  showHeader?: boolean;
  className?: string;
}

const WeekplannerItemUnavailableContent: React.FC<
  WeekplannerItemUnavailableContentProps
> = (props: WeekplannerItemUnavailableContentProps) => {
  const { deliveryDate, nextAvailability, productData, showHeader, className } =
    props;

  const { hasDeliveryDateProductAlternative, isUnavailable } =
    useHasDeliveryDateProductAlternative({ deliveryDate, productData });

  return (
    <div className={clsx("unavailableBox", className)}>
      <Space direction="vertical">
        {showHeader && (
          <header className="unavailableHeading">
            <UnavailableIcon className="icon iconUnavailable" />
            Nicht verfügbar
          </header>
        )}

        {nextAvailability !== null &&
          moment(nextAvailability).isAfter(deliveryDate) && (
            <ProductPreorderModal
              deliveryDate={deliveryDate}
              productData={productData}
              buttonStyle="primary"
            />
          )}

        <ProductAlternativesModal
          deliveryDate={deliveryDate}
          hasProductAlternatives={
            isUnavailable && hasDeliveryDateProductAlternative
          }
          sku={productData?.sku}
          buttonStyle="primary"
        />
      </Space>
    </div>
  );
};

export default React.memo(WeekplannerItemUnavailableContent, areEqual);
