const getPriceFromFormValue = (value: any) => {
  if (Number.isFinite(value)) {
    return parseInt((value * 100).toFixed(0), 10);
  }

  return parseInt(`${value}`.replace(/\D/g, ""), 10);
};

const getQuantityFromFormValue = (value: string): number => {
  return Number(`${value}`.replace(".", "").replace(",", "."));
};

export { getPriceFromFormValue, getQuantityFromFormValue };
