import React from "react";

import clsx from "clsx";
import { Col, Radio, RadioChangeEvent, Row } from "antd";

import HrDivider from "../../divider/Divider";
import { DocumentType } from "../../../types/orderAPI";

interface OrderListHeaderProps {
  type: DocumentType;
  className?: string;
  onChange: (e: RadioChangeEvent) => void;
}

const OrderListHeader: React.FC<OrderListHeaderProps> = (
  props: OrderListHeaderProps
) => {
  const { type, className, onChange } = props;

  return (
    <Row className={clsx("order-list-header", className)}>
      <Col xs={12} lg={7}>
        <h1>Meine Bestellungen</h1>
      </Col>

      <Col xs={12} lg={5}>
        <Radio.Group
          defaultValue={type}
          buttonStyle="solid"
          className="type-selector"
          size="large"
          onChange={onChange}
        >
          <Radio.Button className="single-selector" value="SHOPORDER">
            Shopbestellungen
          </Radio.Button>
          <Radio.Button className="single-selector" value="ORDER">
            Aufträge
          </Radio.Button>

          <Radio.Button className="single-selector" value="INVOICE">
            Rechnungen
          </Radio.Button>
        </Radio.Group>
      </Col>

      <Col span={12}>
        <HrDivider spacingTop="s" size={2} />
      </Col>
    </Row>
  );
};

export default OrderListHeader;
