import axios from "axios";
import { loginEndpoint, XHRheader } from "../endpoints";

// Sends Login Post Request to Spryker Backend
const getAccessToken = async (username: string, password: string) =>
  axios
    .post(
      loginEndpoint,
      {
        data: {
          type: "access-tokens",
          attributes: {
            username,
            password,
          },
        },
      },
      {
        headers: {
          "Accept-Language": XHRheader.AcceptLanguage,
        },
      }
    )
    .then((res) => {
      if (res.status !== 201 || !res?.data?.data?.attributes) {
        return Promise.reject(res);
      }

      return res?.data?.data?.attributes;
    });

export default getAccessToken;
