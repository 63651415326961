import { TermsAndConditions } from "../../types/termsAndConditions";

const initialState: TermsAndConditions = {
  timestamp: null,
};

const termsAndConditionsReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "terms-and-conditions/set-timestamp":
      return { ...state, timestamp: payload };
    default:
      return state;
  }
};

export default termsAndConditionsReducer;
