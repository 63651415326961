import { CombinedState, combineReducers, Reducer } from "redux";
// Import all Reducers here
import userDataReducer from "./userDataReducer";
import currentCartItemsReducer from "./currentCartItemsReducer";
import currentCartMetaDataReducer from "./currentCartMetaDataReducer";
import podcastReducer from "./podcastReducer";
import moodleReducer from "./moodleReducer";
import productsMetaDataReducer from "./productsMetaDataReducer";
import weekplannerDataReducer from "./weekplannerDataReducer";
import networkActivityReducer from "./networkActivityReducer";
import dailyOrdersReducer from "./dailyOrdersReducer";
import categoryNavigationReducer from "./categoryNavigationReducer";
import infoCenterReducer from "./infoCenterReducer";
import newsReducer from "./newsReducer";
import authReducer from "./authReducer";
import priceTagListReducer from "./priceTagListReducer";
import favouriteListReducer from "./favouriteListReducer";

import pageReducer from "./pageReducer";
import termsAndConditionsReducer from "./termsAndConditionsReducer";
import { RootState } from "../../types/rootState";
import alreadyOrderedReducer from "./alreadyOrderedReducer";
import sidebarReducer from "./sidebarReducer";
import notificationModalReducer from "./notificationModalReducer";
import appSettingsReducer from "./appSettingsReducer";

// Declare all reducers here
const appReducer: Reducer<CombinedState<RootState>, any> = combineReducers({
  auth: authReducer,
  userData: userDataReducer,
  currentCart: currentCartItemsReducer,
  dailyOrders: dailyOrdersReducer,
  alreadyOrdered: alreadyOrderedReducer,
  currentCartMetaData: currentCartMetaDataReducer,
  podcasts: podcastReducer,
  moodle: moodleReducer,
  productsMetaData: productsMetaDataReducer,
  weekplannerData: weekplannerDataReducer,
  networkActivity: networkActivityReducer,
  categoryNavigation: categoryNavigationReducer,
  infoCenter: infoCenterReducer,
  news: newsReducer,
  priceTagList: priceTagListReducer,
  favouriteList: favouriteListReducer,
  page: pageReducer,
  termsAndConditions: termsAndConditionsReducer,
  sidebar: sidebarReducer,
  notificationModal: notificationModalReducer,
  appSettings: appSettingsReducer,
});

const rootReducer = (state: any, action: any) => {
  // when an unauthorized action is dispatched it will reset redux state
  if (action.type === "store/clear") {
    // return initial states
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
