import { useMemo } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../types/rootState";

const useGetContactPerson = () => {
  const { contactPerson: contactPersonBusinessUnit = {} } = useSelector(
    (state: RootState) => state?.userData?.businessUnit || {}
  );
  const { contactPerson: contactPersonCustomer = {} } = useSelector(
    (state: RootState) => state?.userData || {}
  );

  return useMemo(() => {
    if (
      contactPersonBusinessUnit &&
      Object.keys(contactPersonBusinessUnit)?.length
    ) {
      return contactPersonBusinessUnit;
    }
    if (contactPersonCustomer && Object.keys(contactPersonCustomer)?.length) {
      return contactPersonCustomer;
    }
    return null;
  }, [contactPersonCustomer, contactPersonBusinessUnit]);
};

export default useGetContactPerson;
