import { productAttributes } from "../api/productAttributes";
import { ProductData } from "../types/productData";

/**
 * helper to determine if the product is a display product based on attributes
 * @param attributes {ProductData["attributes"]}
 * @return boolean
 */
const isProductADisplay = (attributes: ProductData["attributes"]) => {
  const {
    [productAttributes.isDisplayText]: isDisplayText,
    [productAttributes.isDisplayArticles]: isDisplayArticles,
  } = attributes;

  return isDisplayText && isDisplayArticles;
};

export default isProductADisplay;
