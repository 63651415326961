import { contentfulContentTypes } from "../../appConfig";
import getAllContentTypeEntriesGenerator from "./getAllContentTypeEntriesGenerator";

/**
 * get total frequency / occurrences of a string in another one
 * @param data
 * @param lookup
 */
const getTotalFrequency = (data: string, lookup: string) => {
  const lookupRegex = new RegExp(lookup, "gim");
  return data.match(lookupRegex)?.length;
};

/**
 * API types
 */
interface ContentfulFolderItem {
  fields: {
    assets: any[];
    folder_name: string;
    slug: string;
    sub_folders: ContentfulFolderItem[];
  };
  sys: {
    id: string;
  };
}

/**
 * TreeItem or Children types
 */
interface TreeNode {
  assets: any | any[];
  folderName: string;
  slug: string;
  id: string;
  title: string;
  key: string;
  isSingleLeaf: boolean;
  children?: TreeNode[];
}

/**
 * create a simplified tree entry based on the API input
 * @param item {Record<string, any>}
 * @param isChild {boolean}
 * @return {TreeNode}
 */
const createTreeEntry = (
  item: ContentfulFolderItem,
  isChild = false
): TreeNode => {
  const {
    fields: {
      assets,
      folder_name: folderName,
      slug,
      sub_folders: subFolders,
    } = {},
    sys: { id },
  } = item;

  let itemSet: TreeNode = {
    assets,
    folderName,
    slug,
    id,
    // title and key are for the Ant.Design <Tree/> Component
    title: folderName,
    key: `${id}_${Date.now()}`,
    children: [],
    isSingleLeaf: !subFolders && !isChild,
  };

  if (subFolders) {
    subFolders.forEach((subFolder: any) => {
      // ignore entry, if there is no fields data
      if (subFolder?.fields) {
        itemSet = {
          ...itemSet,
          children: [...itemSet.children, createTreeEntry(subFolder, true)],
        };
      }
    });
  }

  return itemSet;
};

/**
 * iteration function to call generator
 * @async
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/for-await...of}
 */
const getInfoCenterFolders = async () => {
  let allFolderItems: ContentfulFolderItem[];
  let folderTree: Record<string, any>[] = [];

  /**
   * fetch data as often as needed and use result
   * use in conjunction with await to create async iterations
   */
  // eslint-disable-next-line no-restricted-syntax
  for await (const entries of getAllContentTypeEntriesGenerator(
    contentfulContentTypes.infoCenterFolder
  )) {
    allFolderItems = [...entries];
  }

  const allFoldersString = JSON.stringify(allFolderItems);

  /*
   * iterate over all folder items and temporarily store single leafs to process at the end
   * initialValue is an empty array to make sure,
   * to even process only one leaf due to the internal working of Array.prototype.reduce()
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce#description}
   */
  allFolderItems.reduce((singleLeafsArray, currentEntry, index) => {
    // ignore entry, if there is no fields data
    if (!currentEntry?.fields) {
      return singleLeafsArray;
    }

    const isLastItem = allFolderItems.length - 1 === index;
    const treeStructure = createTreeEntry(currentEntry);
    const entryId = treeStructure.id;
    const totalIdFrequency = getTotalFrequency(allFoldersString, entryId);

    if (treeStructure.isSingleLeaf) {
      /*
       * entry is a leaf, which should be processed last
       * if it is a leaf, but the id is already in the tree, it is save to omit
       * because it is already there as a nested folder
       */
      if (totalIdFrequency === 1) {
        singleLeafsArray.push(currentEntry);
      }
    } else if (
      !JSON.stringify(folderTree).includes(entryId) &&
      totalIdFrequency <= 1
    ) {
      // entry is not in the tree and not a leaf, join them
      folderTree = [...folderTree, treeStructure];
    }

    // at the end of the reducer, process all remaining entries / leafs
    if (isLastItem) {
      /*
       * initialValue is an empty array to make sure,
       * to process even only one leaf due to the internal working of Array.prototype.reduce()
       * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce#description}
       */
      singleLeafsArray.reduce((prevLeaf: any, currentLeaf: any) => {
        const leafStructure = createTreeEntry(currentLeaf);
        const leafId = leafStructure.id;

        /*
         * if the leaf is already in the tree, ignore it,
         * else, it must be a root leaf
         */
        if (!JSON.stringify(folderTree).includes(leafId)) {
          folderTree = [...folderTree, leafStructure];
        }

        return currentLeaf;
      }, []);
    }

    // always take the single leafs with you!
    return singleLeafsArray;
  }, []);

  return folderTree;
};

export default getInfoCenterFolders;
