import queryString from "query-string";

/**
 * get referrer parameter from url and return as string
 * @param {Location.search} search
 */
const getUrlReferrerString = (search: Location["search"]) => {
  const urlReferrer = queryString.parse(search)?.referrer;

  return !!urlReferrer && String(urlReferrer);
};

export default getUrlReferrerString;
