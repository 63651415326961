import React, { useState } from "react";

import clsx from "clsx";
import { Button, message } from "antd";
import axios from "axios";

import getCancelTokenSource from "../../../api/getCancelTokenSource";
import getOrderPriceChange from "../../../api/order/getOrderPriceChange";
import requestCatchHandler from "../../../api/requestCatchHandler";
import postAddProductToPriceTagList from "../../../api/priceTagList/postAddProductToPriceTagList";
import { messageData } from "../../../appConfig";
import { ReactComponent as PriceTagIcon } from "../../../static/svg/barcode.svg";

interface CheckPriceChangeButtonProps {
  orderReference: string;
  className?: string;
}

const CheckPriceChangeButton: React.FC<CheckPriceChangeButtonProps> = (
  props: CheckPriceChangeButtonProps
) => {
  const { orderReference, className } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClick = async () => {
    let cancelTokenSource = getCancelTokenSource();
    let skus = [];

    try {
      setIsLoading(true);
      skus = await getOrderPriceChange({ orderReference, cancelTokenSource });
      setIsLoading(false);

      if (!Array.isArray(skus) || skus?.length < 1) {
        message.info(messageData.info.orders.noPriceChangeInfo);
        return;
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        message.error(messageData.error.orders.unavailablePriceChangeError);
      }
      requestCatchHandler(error);
      return;
    }

    cancelTokenSource = getCancelTokenSource();

    try {
      setIsLoading(true);
      await postAddProductToPriceTagList({ sku: skus, cancelTokenSource });
      setIsLoading(false);
      message.success(
        messageData.success.orders.addPriceTagsToListPriceChangeSuccess
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        message.error(
          messageData.error.orders.addPriceTagsToListPriceChangeError
        );
      }
      requestCatchHandler(error);
    }
  };

  return (
    <Button
      size="small"
      icon={<PriceTagIcon />}
      onClick={onClick}
      loading={isLoading}
      className={clsx(
        "button buttonSecondary buttonSecondary--inverted check-price-change-button",
        className
      )}
    >
      Artikel mit Preisänderung
    </Button>
  );
};

export default CheckPriceChangeButton;
