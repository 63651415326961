import React from "react";
import { NavLink } from "react-router-dom";
import { routePathNames } from "../../../../appConfig";

interface Props {
  categoryTreeChild?: any;
}

const CategoryTreeChild: React.FC<Props> = ({ categoryTreeChild }: Props) => {
  return categoryTreeChild[0]?.children.length === 0
    ? []
    : categoryTreeChild[0]?.children?.map((category: any) => {
        return {
          title: (
            <NavLink
              to={`${routePathNames.products}${routePathNames.category}${category?.categoryKey}`}
              title={category?.name}
            >
              {category?.name}
            </NavLink>
          ),
          key: category?.nodeId,
        };
      });
};

export default CategoryTreeChild;
