import React, { useMemo } from "react";

import clsx from "clsx";
import { areEqual } from "react-window";

import useStockForAttribute from "../../../../../../hooks/useStockForAttribute";
import getDeliveryDateBasedAttributes from "../../../../../product/getDeliveryDateBasedAttributes";
import ProductPrices from "../../../../../product/ProductPrices";
import ProductInfoVolumePricesPopup from "../../../../../product/ProductInfoVolumePricesPopup";
import { productAttributes } from "../../../../../../api/productAttributes";
import { ProductData } from "../../../../../../types/productData";

interface WeekplannerItemPriceProps {
  productData: ProductData;
  className?: string;
}

const WeekplannerItemPrice: React.FC<WeekplannerItemPriceProps> = (
  props: WeekplannerItemPriceProps
) => {
  const { productData, className } = props;

  const { attributes, prices } = productData;
  const deliveryDate = prices?.[0]?.deliveryDate;

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.basePriceFactor]: basePriceFactor,
    [productAttributes.basePriceUnit]: basePriceUnit,
    [productAttributes.weighingArticle]: weighingArticle,
  } = attributes;

  const advertisement = useStockForAttribute(attributes, "advert");
  const { volumePrices, strikePrice } = useMemo(
    () =>
      getDeliveryDateBasedAttributes({
        deliveryDate,
        prices,
      }),
    [deliveryDate, prices]
  );

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-price",
        className
      )}
    >
      <ProductPrices
        prices={prices}
        deliveryDate={deliveryDate}
        quantity={1}
        advertisement={advertisement}
        basePriceQuantity={productUnitQuantity}
        basePriceUnit={basePriceUnit}
        basePriceFactor={basePriceFactor}
        productUnitMeasurement={productUnitMeasurement}
        weighingArticle={weighingArticle}
        sku={productData?.sku}
        showProductUVP
      />

      {volumePrices?.length > 0 && (
        <ProductInfoVolumePricesPopup
          volumePrices={volumePrices}
          basePriceQuantity={weighingArticle === "1" ? 1 : productUnitQuantity}
          className={clsx(strikePrice > 0 && "has-strike-through-price")}
          view="weekplanner"
        />
      )}
    </div>
  );
};

export default React.memo(WeekplannerItemPrice, areEqual);
