import { ProductData } from "../types/productData";

/**
 * check if sku is already in cart
 * @param cartItems {ProductData[]}
 * @param lookupSku {string}
 */
const isItemInCart = (cartItems: ProductData[], lookupSku: string) =>
  cartItems?.some((cartItem: any) => cartItem.sku === lookupSku);

export default isItemInCart;
