import React, { useRef, useState } from "react";
import clsx from "clsx";
import { message, Spin } from "antd";
import axios, { AxiosError, CancelTokenSource } from "axios";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ProductData } from "../../types/productData";
import { ReactComponent as Heart } from "../../static/svg/bookmark.svg";
import { messageData, routePathNames } from "../../appConfig";
import requestCatchHandler from "../../api/requestCatchHandler";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import useCancelAxiosOnUnmount from "../../hooks/useCancelAxiosOnUnmount";
import deleteProductsFromFavouritesLists from "../../api/favouritesList/deleteProductsFromFavouritesLists";
import postAddProductsToFavouritesLists from "../../api/favouritesList/postAddProductsToFavouritesLists";
import { UserListEntry } from "../../types/userListProducts";
import ProductAddToFavouriteListModal from "./ProductAddToFavouriteListModal";
import { ChangedProductFavouriteLists } from "../../types/favouriteList";
import setFavouriteLists from "../../state/actions/setFavouriteLists";

interface Props {
  sku: ProductData["sku"];
  withText?: boolean;
  getContainer?: () => HTMLElement;
}

/**
 * Button Component to add or remove a product from the label list
 * @param {string} sku
 * @param {boolean} withText
 * @param getContainer
 * @constructor
 */
const ProductAddToFavouritesListButton = function ProductAddToPriceTagList({
  sku,
  withText = false,
  getContainer,
}: Props) {
  const isActive = useSelector(
    (state: any) =>
      state.favouriteList?.favourites?.some(
        (skuEntry: UserListEntry) => skuEntry?.sku === sku
      ) || false
  );
  const { pathname } = useLocation();
  const { id } = useParams<{ id?: string }>();

  const buttonRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());

  useCancelAxiosOnUnmount(cancelTokenSource.current);

  // message content
  const {
    error: {
      favouriteList: { selectedMessage: noSelectionMsg },
    },
    success: {
      favouriteList: { changedLists: changeListsMsg },
    },
  } = messageData;

  /**
   * catch handler for on click update of price tag list
   * @param {AxiosError} error
   */
  const rejectUpdateFavouriteListHandler = (error: AxiosError) => {
    if (!axios.isCancel(error)) {
      setIsLoading(false);
      buttonRef?.current?.blur();

      message.error(noSelectionMsg);

      // refresh current favourite lists
      setFavouriteLists(cancelTokenSource.current);
    }

    requestCatchHandler(error);
  };

  /**
   * submit the lists where to add/delete the selected product and fire the api calls
   * @param {string[]} listsToAddProduct
   * @param {string[]} listsToDeleteProduct
   */
  const onSubmit = ({
    listsToAddProduct,
    listsToDeleteProduct,
  }: ChangedProductFavouriteLists) => {
    setIsLoading(true);

    // axios payload
    const addPayload = {
      cancelTokenSource: cancelTokenSource.current,
      listNames: listsToAddProduct,
      skuList: [sku],
    };
    const deletePayload = {
      cancelTokenSource: cancelTokenSource.current,
      listNames: listsToDeleteProduct,
      skuList: [sku],
    };

    Promise.all([
      addPayload.listNames.length &&
        postAddProductsToFavouritesLists(addPayload),
      deletePayload.listNames.length &&
        deleteProductsFromFavouritesLists(deletePayload),
    ])
      .then(() => setFavouriteLists(cancelTokenSource.current))
      .then(() => {
        setIsLoading(false);
        setIsVisible(false);
        message.success(changeListsMsg);
      })
      .catch((error) => rejectUpdateFavouriteListHandler(error));
  };

  /**
   * when the user clicks the favourite button determine which favourite list action needs to be triggered next
   */
  const onClick = () => {
    if (pathname.includes(routePathNames.favouriteList) && isActive && id) {
      setIsLoading(true);

      const deletePayload = {
        cancelTokenSource: cancelTokenSource.current,
        listNames: [id],
        skuList: [sku],
      };
      deleteProductsFromFavouritesLists(deletePayload)
        .then(() => {
          setFavouriteLists(cancelTokenSource.current).then(() => {
            setIsLoading(false);
            message.success(changeListsMsg);
          });
        })
        .catch((error) => rejectUpdateFavouriteListHandler(error));
    } else {
      setIsVisible(true);
    }
  };

  return (
    <>
      <button
        type="button"
        className={clsx(
          "productInfoIcon buttonWithSpin",
          { isActive },
          withText &&
            "button buttonText buttonWithIcon buttonTextDecoration--inverted"
        )}
        onClick={onClick}
        ref={buttonRef}
      >
        <Spin size="small" spinning={isLoading}>
          <Heart className="icon iconTwoTone iconHeart" />
          {withText && (
            <span className="color-primary">
              {isActive
                ? "Von Favoritenliste löschen"
                : "Auf Favoritenliste speichern"}
            </span>
          )}
        </Spin>
      </button>
      <ProductAddToFavouriteListModal
        hideModal={() => setIsVisible(false)}
        isLoading={isLoading}
        isVisible={isVisible}
        modalHeading="Auswahl Favoritenliste"
        onSubmit={onSubmit}
        sku={sku}
        getContainer={getContainer}
      />
    </>
  );
};

export default ProductAddToFavouritesListButton;
