import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { stocktakingEndpoint } from "../endpoints";
import { StocktakingData } from "../../types/stocktaking";

interface GetStocktakingProps {
  stocktakingId: number;
  cancelTokenSource: CancelTokenSource;
}

const getStocktaking = async (props: GetStocktakingProps) => {
  const { stocktakingId, cancelTokenSource } = props;

  const url = `${stocktakingEndpoint}/${stocktakingId}`;

  return axiosWithAuth()
    .get(url, { cancelToken: cancelTokenSource.token })
    .then((response): Promise<any> | StocktakingData => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data || {};

      return attributes as StocktakingData;
    });
};

export default getStocktaking;
