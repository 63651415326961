// This will be set to Redux an initialization
const initialState: Record<string, any> = { courseCategories: [], courses: [] };

const moodleReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_COURSE_CATEGORIES":
      // Sets the User Object into State, overwrites old User State
      return { ...state, courseCategories: payload };
    case "SET_COURSES":
      // Sets the User Object into State, overwrites old User State
      return { ...state, courses: payload };
    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default moodleReducer;
