import {
  PriceTagListEntry,
  PriceTagListPrintType,
} from "../../types/priceTagList";

const initialState = {
  priceDate: <null | string>null,
  priceTags: <PriceTagListEntry[]>[],
  printTypes: <PriceTagListPrintType[]>[],
  selectedPriceTagItems: <string[]>[],
};

const priceTagListReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // set price date
    case "priceTagList/set-priceDate": {
      return {
        ...state,
        priceDate: payload?.priceDate || null,
      };
    }

    // set all available print types retrieved from backend
    case "priceTagList/set-printTypes": {
      return {
        ...state,
        printTypes: payload?.printTypes || [],
      };
    }

    // set response from server to redux
    case "priceTagList/set-list": {
      // keep existing entries to persist user prices
      const existingEntries =
        state?.priceTags?.filter((storePriceTagListEntry) =>
          payload?.priceTags?.some(
            (priceTagEntry: PriceTagListEntry) =>
              storePriceTagListEntry?.sku === priceTagEntry?.sku
          )
        ) || [];

      const newEntries =
        payload?.priceTags?.filter(
          (priceTagEntry: PriceTagListEntry) =>
            !existingEntries?.some(
              (existingEntry) => priceTagEntry.sku === existingEntry.sku
            )
        ) || [];

      return {
        ...state,
        priceTags: [...existingEntries, ...newEntries],
      };
    }

    // overwrite list with prefilled data without check if data is valid
    case "priceTagList/set-prefilled-list": {
      return {
        ...state,
        priceTags: payload?.priceTags || [],
      };
    }

    // remove a single sku from the list
    case "priceTagList/remove-sku": {
      const restSkuList = state.priceTags?.filter((entry) =>
        payload?.some((removeSku: string) => entry.sku !== removeSku)
      );

      return {
        ...state,
        priceTags: restSkuList || [],
      };
    }

    case "priceTagList/add-price-to-sku": {
      // filter out the entry to alter
      const restSkuList = state.priceTags?.filter(
        (entry) => entry.sku !== payload?.sku
      );

      // add a new set
      const newPriceTagList = [...restSkuList, { ...payload }];

      return {
        ...state,
        priceTags: newPriceTagList,
      };
    }

    // add a sku to the selectedPriceTagItems array
    case "priceTagList/add-selected-priceTag-item": {
      const selectedPriceTagItems = [...state.selectedPriceTagItems];

      if (selectedPriceTagItems.indexOf(payload) < 0) {
        selectedPriceTagItems.push(payload);
      }

      return {
        ...state,
        selectedPriceTagItems,
      };
    }

    // delete a sku from the selectedPriceTagItems array
    case "priceTagList/delete-selected-priceTag-item": {
      const remainingItemSkus = state.selectedPriceTagItems?.filter(
        (currentSku: string) => currentSku !== payload
      );

      return {
        ...state,
        selectedPriceTagItems: remainingItemSkus,
      };
    }

    // Reset data
    case "priceTagList/reset-price-tag-list": {
      return {
        ...state,
        selectedPriceTagItems: [],
        priceTags: [],
      };
    }

    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default priceTagListReducer;
