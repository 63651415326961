import moment from "moment/moment";

import { apiDateFormat } from "./dateFormats";

interface FutureWeekplannerDates {
  startDate: string;
  endDate: string;
}

const ensureFutureWeekplannerDates = (
  startDate: string,
  endDate: string
): FutureWeekplannerDates => {
  const today = moment();
  const isOutdatedData = moment(startDate).isBefore(today.day(1), "day");

  if (!startDate || !endDate || isOutdatedData) {
    return {
      startDate: today.day(1).format(apiDateFormat),
      endDate: today.day(6).format(apiDateFormat),
    };
  }

  return { startDate, endDate };
};

export default ensureFutureWeekplannerDates;
