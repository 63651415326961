import React from "react";

import clsx from "clsx";
import { Col, Row } from "antd";

import useGetDeliveryDate from "../../../hooks/useGetDeliveryDate";
import { QuickLink, ContentBlock } from "../../atoms";
import {
  locationSearchQueryParameter,
  routePathNames,
  routeProductCategoryKeys,
} from "../../../appConfig";
import { ReactComponent as FruitVegetables } from "../../../static/svg/newsCategories/fruit_vegetables.svg";
import { ReactComponent as FavoriteLists } from "../../../static/svg/bookmark.svg";
import { ReactComponent as MonthlySale } from "../../../static/svg/produkte.svg";
import { ReactComponent as NewArrivals } from "../../../static/svg/neuprodukte.svg";
import { ReactComponent as Invoices } from "../../../static/svg/graduated-prices.svg";
import { ReactComponent as Barcode } from "../../../static/svg/barcode.svg";
import { ReactComponent as WebshopIcon } from "../../../static/svg/webshop.svg";
import { ReactComponent as AcademyIcon } from "../../../static/svg/academy.svg";
import { ReactComponent as InfoCenterIcon } from "../../../static/svg/infothek.svg";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";

interface QuickLinksWidgetProps {
  className?: string;
}

const links = [
  {
    label: "Neuprodukte",
    icon: <NewArrivals />,
    href: `/produkte/kategorie/${routeProductCategoryKeys.novelty}`,
    linkType: "primary",
  },
  {
    label: "Favoritenliste",
    icon: <FavoriteLists />,
    href: routePathNames.favouriteList,
    linkType: "tertiary",
  },
  {
    label: "Aktuelle Impulsaktion",
    icon: <MonthlySale />,
    href: `/produkte/kategorie/${routeProductCategoryKeys.currentPromotion}`,
    linkType: "tertiary",
  },
  {
    label: "Rechnungen",
    icon: <Invoices />,
    href: `${routePathNames.orders}?documentType=INVOICE`,
    linkType: "secondary",
  },
  {
    label: "Etikettenliste",
    icon: <Barcode />,
    href: routePathNames.priceTagList,
    linkType: "default",
  },
];

const QuickLinksWidget: React.FC<QuickLinksWidgetProps> = (
  props: QuickLinksWidgetProps
) => {
  const { className } = props;

  const [deliveryDate, reduxDeliveryDate] = useGetDeliveryDate();

  const deliveryDateFrom = deliveryDate || reduxDeliveryDate || "";

  const { deliveryDateFrom: paramDeliveryDateFrom } =
    locationSearchQueryParameter;

  const isLargeScreenMin = useMedia(
    `(min-width: ${getCssVariable("screen-lg-min")})`
  );

  const isLargeScreenMax = useMedia(
    `(max-width: ${getCssVariable("screen-lg-max")})`
  );

  const isLargeScreen = isLargeScreenMin && isLargeScreenMax;

  return (
    <ContentBlock
      className={clsx("quick-links-widget", className)}
      title="Meine Quick-Links"
    >
      <Row gutter={[10, 10]}>
        <Col xs={12} lg={4}>
          <QuickLink
            label="Webshop"
            href={routePathNames.shop}
            icon={<WebshopIcon />}
            linkType="primary--inverted"
            className={clsx(isLargeScreen && "quick-link--large-screen")}
          />
        </Col>
        <Col xs={12} lg={4}>
          <QuickLink
            label="Akademie"
            href="https://www.weiling-akademie.de/"
            target="_blank"
            icon={<AcademyIcon />}
            linkType="primary--inverted"
            className={clsx(isLargeScreen && "quick-link--large-screen")}
          />
        </Col>
        <Col xs={12} lg={4}>
          <QuickLink
            label="Infothek"
            href={routePathNames.infoCenter}
            icon={<InfoCenterIcon />}
            linkType="primary--inverted"
            className={clsx(isLargeScreen && "quick-link--large-screen")}
          />
        </Col>
        <Col xs={12} lg={6}>
          <QuickLink
            label="Wochenplaner Obst & Gemüse"
            href={`/wochenplaner/kategorie/${routeProductCategoryKeys.fruitsAndVeggies}?${paramDeliveryDateFrom}=${deliveryDateFrom}`}
            icon={<FruitVegetables />}
            linkType="primary"
            className={clsx(isLargeScreen && "quick-link--large-screen")}
          />
        </Col>
        {links &&
          links.map((link: any, i: number) => (
            <Col xs={12} lg={6} key={`quick_link_${i}`}>
              <QuickLink
                {...link}
                className={clsx(isLargeScreen && "quick-link--large-screen")}
              />
            </Col>
          ))}
      </Row>
    </ContentBlock>
  );
};

export default QuickLinksWidget;
