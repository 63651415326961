import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Layout, message } from "antd";
import { useLocation, useParams } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import moment from "moment";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import HeaderButtons from "./HeaderButtons";
import ShopCategoryNavigation from "../navigation/ShopCategoryNavigation";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import PaginationWrapper from "../pagination/PaginationWrapper";
import FiltersMobile from "./ProductsFilter/FiltersMobile";
import getProductsData from "../../api/products/getProductsData";
import getAllCartsDataByDate from "../../api/cart/getAllCartsDataByDate";
import updateFilterStatesOnCategoryChange from "../../state/actions/updateFilterStatesOnCategoryChange";
import {
  contentfulContentTypes,
  datePickerRange,
  locationSearchQueryParameter,
  messageData,
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../appConfig";
import getContentfulContentType from "../../api/contentful/getContentfulContentType";
import requestCatchHandler from "../../api/requestCatchHandler";
import generatorNewsContentBlock from "../NewsContentBlock/generatorNewsContentBlock";
import useCancelAxiosOnUnmount from "../../hooks/useCancelAxiosOnUnmount";
import useGlobalNetworkState from "../../hooks/useGlobalNetworkState";
import createWeekplannerDates from "../../utils/createWeekplannerDates";
import useUpdateUrlFragments from "../../hooks/useUpdateUrlFragments";
import { getFilterStatesWithoutDefaults } from "../../api/products/getProductsFilters";
import useGetDeliveryDate from "../../hooks/useGetDeliveryDate";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../api/getCancelTokenSource";
import useShouldWeekplannerBeVisible from "../../hooks/useShouldWeekplannerBeVisible";
import BackButton from "../backButton/BackButton";
import { RootState } from "../../types/rootState";
import setAlreadyOrdered from "../../state/actions/setAlreadyOrdered";
import {
  AdProductListing,
  WebshopCategoryDownloads,
  WebshopCategoryVideos,
} from "../molecules";
import ReorderModal from "../order/OrderDetail/ReorderModal";
import { StockType } from "../../types/stock";
import stockNameMapping from "../../utils/stockNameMapping";
import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";
import getDeliveryDates from "../../utils/getDeliveryDates";
import useScrollToTop from "../../hooks/useScrollToTop";
import TrackingHelmet from "../Matomo/TrackingHelmet";
import AdProductListingTop from "../molecules/AdProductListingTop";

export interface ChildProps {
  deliveryDatesWithCartIds?: Record<string, any>[];
  isLoading: boolean;
  productData: any[];
  showFilters?: boolean;
  setShowFilters?: (value: boolean) => void;
  fullscreenRef?: any;
  webshopAdProductListing?: JSX.Element;
  pagination?: ReactElement;
  setFocusOnSearchInput?: (value: boolean) => void;
}

interface Props {
  WrappedComponent: React.FunctionComponent<ChildProps>;
  setFocusOnSearchInput?: (value: boolean) => void;
}

const ProductsViewWrapper: React.FC<Props> = function ProductsViewWrapper({
  WrappedComponent,
  setFocusOnSearchInput,
}: Props) {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { categoryKey } = useParams<{
    categoryKey?: string;
  }>();
  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-md")})`
  );

  const isProductList = pathname.includes(routePathNames.products);
  const isWeekplanner = pathname.includes(routePathNames.weekPlanner);
  const showWeekplanner = useShouldWeekplannerBeVisible();
  const isSearch = pathname.includes(routePathNames.search);

  const { trackSiteSearch } = useMatomo();

  // url parameters
  const parsedSearch = queryString.parse(search);
  const {
    [locationSearchQueryParameter.page]: pageQuery,
    [locationSearchQueryParameter.searchTerm]: searchQuery,
    [locationSearchQueryParameter.deliveryDateFrom]: deliveryDateFromQuery,
    [locationSearchQueryParameter.deliveryDateTo]: deliveryDateToQuery,
    [locationSearchQueryParameter.sortBy]: sortByQuery,
    [locationSearchQueryParameter.sortDirection]: sortDirectionQuery,
  } = parsedSearch;

  const sortBy = useMemo(
    () => (sortByQuery?.length ? String(sortByQuery) : null),
    [sortByQuery]
  );
  const sortDirection = useMemo(
    () => (sortDirectionQuery?.length ? String(sortDirectionQuery) : null),
    [sortDirectionQuery]
  );

  // redux stored dates
  const [deliveryDate] = useGetDeliveryDate();
  const weekplannerDates = useSelector(
    (state: RootState) => state.weekplannerData.weekDates
  );
  const orderItemIndexVersion = useSelector(
    (state: RootState) => state.alreadyOrdered.version
  );
  const { uuid: businessUnit } = useSelector(
    (state: RootState) => state.userData.businessUnit
  );
  const stockName: StockType = useSelector(
    (state: RootState) => state?.userData?.stockName || "HL"
  );
  const { deliveryDates: customerDeliveryDates = [] } = useSelector(
    (state: RootState) => state?.userData || {}
  );
  const { deliveryDates: businessUnitDeliveryDates = [] } = useSelector(
    (state: RootState) => state?.userData?.businessUnit || {}
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isComponentLoading, setComponentIsLoading] =
    useGlobalNetworkState("component");
  const [showFilters, setShowFilters] = useState(false);
  const [paginationData, setPaginationData] = useState<Record<string, any>>({
    numFound: 0,
    currentPage: Number(pageQuery) || 1,
  });
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const fullscreenRef = useRef(null);
  const [isInFullscreenMode, setIsInFullscreenMode] = useState<boolean>(false);

  const [productData, setProductData] = useState([]);
  const [contentfulEntries, setContentfulEntries] = useState([]);
  const [resultQuantity, setResultQuantity] = useState<number>(0);
  const [searchVersion, setSearchVersion] = useState<string>(
    orderItemIndexVersion
  );
  const isMonthlyCampaign = !!(
    categoryKey === "7540000" || categoryKey === "7550000"
  );

  const { setShouldScrollToTop } = useScrollToTop(
    isInFullscreenMode ? fullscreenRef?.current : window
  );

  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const setUpdateUrlFragments = useUpdateUrlFragments();

  const deliveryDates = useMemo(
    () => getDeliveryDates(customerDeliveryDates, businessUnitDeliveryDates),
    [customerDeliveryDates, businessUnitDeliveryDates]
  );

  // setup the request date
  const requestDates = useMemo(() => {
    if (!isWeekplanner) {
      return deliveryDate;
    }

    const deliveryDateFromQueryString = String(deliveryDateFromQuery);

    // if the url date is set and differs from the weekplanner dates
    if (
      deliveryDateFromQuery &&
      deliveryDateToQuery &&
      moment(weekplannerDates[0]?.apiDate) <=
        moment(deliveryDateFromQueryString)
    ) {
      return createWeekplannerDates(moment(deliveryDateFromQueryString)).map(
        (dates: any) => dates.apiDate
      );
    }

    return weekplannerDates.map((dates: any) => dates.apiDate);
  }, [
    isWeekplanner,
    weekplannerDates,
    deliveryDate,
    deliveryDateFromQuery,
    deliveryDateToQuery,
  ]);

  const productsRequestDates = useMemo(() => {
    if (!isWeekplanner || !deliveryDates?.length) {
      return requestDates;
    }
    return requestDates?.filter(
      (dateString: string) =>
        deliveryDates.includes(String(moment(dateString).day())) ||
        // always include saturday (to catch case when all delivery dates are in the past)
        String(moment(dateString).day()) === "6"
    );
  }, [requestDates, deliveryDates, isWeekplanner]);

  const cartsRequestDates = useMemo(() => {
    if (!isWeekplanner || !deliveryDates?.length) {
      return requestDates;
    }

    const dateRange = [
      Math.min(...deliveryDates.map((day: string) => Number(day))),
      Math.max(...deliveryDates.map((day: string) => Number(day))),
    ];

    return requestDates?.filter((dateString: string) =>
      dateRange.includes(moment(dateString).day())
    );
  }, [isWeekplanner, deliveryDates, requestDates]);

  /**
   * pagination handler
   * @param {number} page
   */
  const updateUrlSearchPageQuery = (page: number) => {
    setPaginationData({
      ...paginationData,
      currentPage: page,
    });

    setUpdateUrlFragments({
      context: "pagination",
      parameters: {
        page,
      },
    });
  };

  /**
   * determine if a date is disabled
   * @param {moment.Moment} current
   */
  const isDisabledDate = (current: moment.Moment) => {
    const today = moment();

    return (
      current <= today ||
      current > today.add(datePickerRange.maxDays, "days") ||
      current.isoWeekday() === 7
    );
  };

  /**
   * get products for ProductList
   */
  const getProducts = useCallback(async () => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    setIsLoading(true);
    const deliveryDateFromQueryString = String(deliveryDateFromQuery);

    if (
      isDisabledDate(moment(deliveryDate)) ||
      (isWeekplanner &&
        deliveryDateFromQuery &&
        moment(weekplannerDates[0]?.apiDate) >
          moment(deliveryDateFromQueryString))
    ) {
      Promise.reject().catch(() => {});
    }

    // check if valid date
    // defaults for product list
    const cartIdsReq = isWeekplanner
      ? getAllCartsDataByDate(cartsRequestDates, cancelTokenSource.current)
      : Promise.resolve();

    // request productsData
    const productsDataReq = getProductsData({
      page: Number(pageQuery) || 1,
      categoryKey: !isSearch ? categoryKey : null,
      query: isSearch && !!searchQuery ? String(searchQuery) : null,
      deliveryDates: productsRequestDates,
      requestFilters: getFilterStatesWithoutDefaults(),
      cancelTokenSource: cancelTokenSource.current,
      sortBy,
      sortDirection,
    });

    return Promise.all([cartIdsReq, productsDataReq])
      .then(([cartResponse, results]) => {
        if (results) {
          const {
            products,
            productsLength,
            pagination,
            version: catalogSearchVersion,
          } = results;

          setSearchVersion(catalogSearchVersion);

          setProductData(products);

          setResultQuantity(productsLength);

          if (isSearch && !!searchQuery) {
            trackSiteSearch({
              keyword: String(searchQuery),
              count: productsLength,
            });
          }
          // set min pagination to 1 to prevent another reload if there are no results
          setPaginationData({
            ...pagination,
            currentPage: pagination.currentPage || 1,
          });
        }
        if (cartResponse) {
          dispatch({
            type: "weekplanner/set-cart",
            payload: cartResponse,
          });
        }

        return results;
      })
      .then(() => {
        setIsLoading(false);
        setComponentIsLoading(false);
      })
      .catch((error) => {
        // Checks if request has been canceled
        if (!axios.isCancel(error)) {
          message.warning(messageData.warning.noProductResults);
          setIsLoading(false);
          setComponentIsLoading(false);
        }

        return error;
      });
  }, [
    deliveryDateFromQuery,
    deliveryDate,
    isWeekplanner,
    weekplannerDates,
    cartsRequestDates,
    pageQuery,
    isSearch,
    categoryKey,
    searchQuery,
    productsRequestDates,
    sortBy,
    sortDirection,
    trackSiteSearch,
    dispatch,
    setComponentIsLoading,
  ]);

  /**
   * unfortunately the contentful entry is not filterable
   * by the contentful API due to the fact, that the category id is stored
   * as an object type...
   */
  const getContenfulEntryForCategory = useCallback(() => {
    getContentfulContentType({
      ...contentfulContentTypes.webshopCategoryNews,
      inclusion: {
        "fields.stock_name[in]": `Alle,${stockNameMapping?.[stockName] || ""}`,
      },
    })
      .then((responseEntries: any) => {
        const categoryNewsContentBlocks = responseEntries
          // filter the entry by the current viewed category id
          .filter(
            (currentEntry: any) =>
              String(currentEntry?.fields?.category?.id) === categoryKey &&
              currentEntry.fields?.content_block
          )
          // then add only content blocks to the rendering array
          .map((filteredEntry: any) => filteredEntry?.fields?.content_block)
          /*
           * lastly, make sure, that the array is only one level deep,
           * because it is possible that an editor adds more than one entry
           * for the same category ID in contentful
           * side effect: Array.prototype.flat() removes empty entries from array
           * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat}
           */
          .flat();

        setContentfulEntries(categoryNewsContentBlocks);
      })
      .catch(requestCatchHandler);
  }, [categoryKey, stockName]);

  /**
   * check on product list pages if contentful news entries match this category id
   */
  useEffect(() => {
    let isMounted = true;

    if (isMounted && isProductList) {
      getContenfulEntryForCategory();
    }

    return () => {
      isMounted = false;
    };
  }, [isProductList, getContenfulEntryForCategory]);

  // Check if user is in Fullscreen-Mode
  useEffect(() => {
    function onFullscreenChange() {
      setIsInFullscreenMode(Boolean(document.fullscreenElement));
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  // reset filters if needed
  useEffect(() => {
    updateFilterStatesOnCategoryChange(
      categoryKey ? Number(categoryKey) : true
    );
  }, [categoryKey]);

  // update list on query change
  useEffect(() => {
    getProducts().then(() => setShouldScrollToTop(true));
  }, [
    getProducts,
    search,
    businessUnit,
    isInFullscreenMode,
    setShouldScrollToTop,
  ]);

  useEffect(() => {
    if (searchVersion !== null && searchVersion !== orderItemIndexVersion) {
      if (searchVersion === "0") {
        dispatch({
          type: "alreadyOrdered/empty-order-item-index",
        });
      } else {
        setAlreadyOrdered({
          cancelTokenSource: cancelTokenSource.current,
        });
      }
    }
  }, [dispatch, orderItemIndexVersion, searchVersion]);

  const toggleReorderModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div key={pathname}>
      <TrackingHelmet title={pageTitles.productList} suffix={pageTitleSuffix} />

      <ShopCategoryNavigation />

      <Layout className="container-layout container-layout--inner">
        <nav className="flex flex-row">
          <BackButton withBreadcrumbs={!isSearch} />
          {!isSearch && <Breadcrumbs />}
        </nav>
      </Layout>

      <Layout className="container-layout container-layout--inner">
        <AdProductListingTop categoryKey={categoryKey} />
      </Layout>

      {contentfulEntries.length > 0 && (
        <Layout className="container-layout container-layout--inner mb-4xl">
          {generatorNewsContentBlock(contentfulEntries)}
        </Layout>
      )}

      <HeaderButtons
        paginationData={paginationData}
        categoryKey={categoryKey}
        sortBy={sortBy}
        sortDirection={sortDirection}
        searchQuery={String(searchQuery || "")}
      >
        {isMonthlyCampaign && (
          <button
            type="button"
            className="button buttonPrimary buttonAddProductsToNewCart"
            disabled={!productData.length}
            onClick={toggleReorderModal}
          >
            Alles in den Warenkorb
          </button>
        )}
        <WebshopCategoryDownloads categoryKey={categoryKey} />
        <WebshopCategoryVideos categoryKey={categoryKey} />
      </HeaderButtons>

      <Layout
        className="container-layout container-layout--inner"
        ref={fullscreenRef}
      >
        {isWeekplanner && isInFullscreenMode && (
          <div className="flex tableWrapper__fullscreen-menu">
            <button
              type="button"
              className="buttonText buttonText--inverted"
              onClick={() => document.exitFullscreen()}
            >
              Vollbildmodus beenden
            </button>
          </div>
        )}
        <WrappedComponent
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          productData={productData}
          isLoading={isLoading || isComponentLoading}
          sortBy={sortBy}
          sortDirection={sortDirection}
          fullscreenRef={fullscreenRef.current}
          setFocusOnSearchInput={setFocusOnSearchInput}
          pagination={
            !!paginationData.numFound && (
              <PaginationWrapper
                total={paginationData.numFound}
                pageSize={paginationData.currentItemsPerPage}
                current={paginationData.currentPage}
                onChange={(page: number) => {
                  updateUrlSearchPageQuery(page);
                }}
                innerClassName="mt-0 mb-s"
              />
            )
          }
          webshopAdProductListing={
            <AdProductListing categoryKey={categoryKey} />
          }
        />

        {!!paginationData.numFound &&
          productData?.length > 0 &&
          !(isLoading || isComponentLoading) &&
          (isProductList || (isWeekplanner && showWeekplanner)) && (
            <PaginationWrapper
              total={paginationData.numFound}
              pageSize={paginationData.currentItemsPerPage}
              current={paginationData.currentPage}
              onChange={(page: number) => {
                updateUrlSearchPageQuery(page);
              }}
            />
          )}

        {showFilters && !browserIsDesktop && (
          <FiltersMobile
            quantity={resultQuantity}
            loading={isLoading || isComponentLoading}
            closeMobileFilters={() => setShowFilters(false)}
            className="hidden-md-up"
          />
        )}
      </Layout>
      <ReorderModal
        productItems={productData}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        modalQuestion="Möchten Sie diese Artikel dem Warenkorb hinzufügen?"
      />
    </div>
  );
};

export default ProductsViewWrapper;
