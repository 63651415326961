import React, { useEffect, useState, useCallback } from "react";
import { Layout, Row, Col, Spin, message, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import queryString from "query-string";
import PaginationWrapper from "../pagination/PaginationWrapper";
import NavigationCategoryIconRound from "../navigation/NavigationCategoryIconRound";
import getAllNewsByCategory from "../../api/contentful/getAllNewsByCategory";
import NewsItem from "./NewsItem";
import {
  defaultNewsCategoryTitle,
  itemsPerPage,
  locationSearchQueryParameter,
  messageData,
} from "../../appConfig";
import getPageOffset from "../../utils/getPageOffset";
import useGetNewsCategories from "../../hooks/useGetNewsCategories";
import { ContentfulCategoryEntry } from "../../types/contentfulCategoryEntry";
import NoNewsCategorySelected from "./NoNewsCategorySelected";

/**
 * helper to distinguish id
 * @param id {string}
 */
const getContentfulCategoryRequestId = (id: ContentfulCategoryEntry["id"]) => {
  if (id === "default") {
    return null;
  }

  return id;
};

function NewsOverview() {
  const navigate = useNavigate();
  const { search } = useLocation();

  // redux
  const dispatch = useDispatch();
  const {
    categories: newsCategories,
    items: newsItems,
    fetchState,
  } = useSelector((state: any) => state.news);

  const myNewsConf = newsCategories.find(
    (categoryEntry: ContentfulCategoryEntry) =>
      categoryEntry.title === defaultNewsCategoryTitle.userPreferences
  );

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [activeNewsCategory, setActiveNewsCategory] = useState({
    id: null,
    title: "",
  });
  const [activeNews, setActiveNews] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    offset: 0,
  });
  const [showNoCategorySelected, setShowNoCategorySelected] = useState(false);

  // limit for icons per page
  const limit = itemsPerPage.news;

  /**
   * get & set categories if not yet set
   */
  const isLoadingCategories = useGetNewsCategories();

  /**
   * fetch news and store them
   */
  const getNewsItems = useCallback(
    async (id: string, page: number, skip: number) => {
      const storedFetchState = fetchState[id];
      const fetchedTotal = storedFetchState?.total || -1;
      const fetchedLoaded = storedFetchState?.loaded || 0;
      const fetchedPages: string[] = storedFetchState?.pages || [];

      setIsLoading(true);

      // use stored news items, if all are loaded
      if (
        fetchedPages.includes(page.toString()) &&
        fetchedLoaded === fetchedTotal
      ) {
        /*
         * the actual newsArray
         * filtered by selected category
         * slided to match the pagination
         */
        const filteredNews = newsItems
          // filter visible news entries
          .filter((newsEntry: any) => {
            // default, show all news
            if (id === "default") {
              return true;
            }

            const {
              fields: { category },
            } = newsEntry;

            /*
             * test if the id string matches
             * for custom categories, it is essential, to test, that the id is included
             * .indexOf() works for all cases here
             */
            return category?.some(
              (categoryListEntry: any) =>
                id.indexOf(categoryListEntry.sys.id) > -1
            );
          })
          // slice the array to match the pagination
          .slice(limit * (page - 1), limit * page);

        setTotalItems(fetchedTotal);
        setActiveNews(filteredNews);
        setIsLoading(false);
      } else {
        const requestId = getContentfulCategoryRequestId(id);

        // compare total vs fetched for id
        getAllNewsByCategory({
          id: requestId,
          limit,
          skip,
        })
          .then((response) => {
            const { items, total } = response;

            dispatch({
              type: "news/add-category-items",
              payload: {
                id,
                page,
                items,
                total,
              },
            });

            setTotalItems(total);
            setActiveNews(items);

            setIsLoading(false);
          })
          .catch(() => {
            message.error(messageData.error.unexpected);
            setIsLoading(false);
          });
      }
    },
    [dispatch, setIsLoading, setTotalItems, fetchState, limit, newsItems]
  );

  /**
   * updates pagination and fetches new items based on pagination
   * @param id {strong}
   * @param page {number}
   */
  const paginationHandler = useCallback(
    (id: string, page: number) => {
      // offset is zero based, but page is always >= 1
      const offset = getPageOffset(page, limit);

      // update pagination state
      setPagination({
        current: page,
        offset,
      });

      // update url parameters
      const pageAppendix =
        page > 1 ? { [locationSearchQueryParameter.page]: page } : {};
      const requestId = getContentfulCategoryRequestId(id);
      const idAppendix = requestId !== "default" ? { id: requestId } : {};
      const searchQuery = {
        ...pageAppendix,
        ...idAppendix,
      };

      // update url with page param
      navigate({
        search: queryString.stringify(searchQuery, {
          skipNull: true,
          skipEmptyString: true,
        }),
      });

      // get items with possible offset
      getNewsItems(id, page, offset);
    },
    [navigate, getNewsItems, limit]
  );

  /**
   * sets active category
   * clickHandler for navigation
   */
  const updateActiveCategory = useCallback(
    (categoryEntry: Record<string, any>, page = 1) => {
      const { id, title } = categoryEntry;

      if (title === myNewsConf?.title && !myNewsConf.id?.length) {
        setShowNoCategorySelected(true);
      } else {
        setActiveNewsCategory({
          id,
          title,
        });

        paginationHandler(id, page);
      }
    },
    [paginationHandler, myNewsConf]
  );

  const triggerNewsFetch = useCallback(() => {
    if (newsCategories?.length) {
      // fetch items based on possible query strings (deep linking)
      const query = queryString.parse(search);
      const parsedIdQuery = query.id ? String(query.id) : "default";
      const parsedPageQuery = query?.[locationSearchQueryParameter.page]
        ? Number(query?.[locationSearchQueryParameter.page])
        : 1;

      if (
        parsedIdQuery !== activeNewsCategory.id ||
        parsedPageQuery !== pagination.current
      ) {
        const category = newsCategories.find(
          (categoryEntry: any) => categoryEntry.id === parsedIdQuery
        );

        // set only, if a category was found
        if (category) {
          updateActiveCategory(category, parsedPageQuery);
        }
      }
    }
  }, [
    activeNewsCategory.id,
    newsCategories,
    pagination,
    search,
    updateActiveCategory,
  ]);

  useEffect(() => {
    triggerNewsFetch();
  }, [triggerNewsFetch]);

  return (
    <Layout className="container-layout container-layout--inner">
      <Row>
        <Col span={12}>
          <header className="headingWithAddon">
            <h1 className="newsTitle">WEILING NACHRICHTEN</h1>

            {totalItems > 0 && (
              <span className="itemCount">
                {totalItems} {totalItems === 1 ? "Beitrag" : "Beiträge"}
              </span>
            )}
          </header>
        </Col>
        {/* navigation for news, uses generic classes */}
        {newsCategories?.length > 0 && (
          <Col span={12}>
            <Spin size="small" spinning={isLoadingCategories}>
              <nav className="iconNavigation">
                <ul className="iconNavigationList">
                  {newsCategories.map(
                    (categoryEntry: ContentfulCategoryEntry) => (
                      <li
                        className={clsx(
                          "iconNavigationEntry",
                          categoryEntry.id === activeNewsCategory.id &&
                            "iconNavigationEntryActive"
                        )}
                        key={`${categoryEntry.id}_${categoryEntry.title}`}
                      >
                        <button
                          type="button"
                          className="iconNavigationButton"
                          onClick={() => updateActiveCategory(categoryEntry)}
                        >
                          <NavigationCategoryIconRound
                            categoryEntry={categoryEntry}
                          />
                        </button>
                      </li>
                    )
                  )}
                </ul>
              </nav>
            </Spin>
          </Col>
        )}
      </Row>

      {showNoCategorySelected ? (
        <NoNewsCategorySelected />
      ) : (
        <>
          <Row className="newsList">
            <Col span={12}>
              <h2
                className="categoryHeading"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: activeNewsCategory.title }}
              />
            </Col>

            <Spin
              size="large"
              spinning={isLoading}
              wrapperClassName="newsListEntries"
            >
              <Col span={12}>
                <Row
                  gutter={[
                    { xs: 8, sm: 16, md: 32, lg: 32 },
                    { xs: 8, sm: 16, md: 32, lg: 32 },
                  ]}
                >
                  {isLoading ? (
                    <>
                      <Col xs={12} md={4}>
                        <Skeleton paragraph={{ rows: 3 }} active />
                      </Col>{" "}
                      <Col xs={12} md={4}>
                        <Skeleton paragraph={{ rows: 3 }} active />
                      </Col>{" "}
                      <Col xs={12} md={4}>
                        <Skeleton paragraph={{ rows: 3 }} active />
                      </Col>
                    </>
                  ) : (
                    <>
                      {activeNews?.length > 0 ? (
                        activeNews.map((newsEntry: any) => (
                          <NewsItem
                            newsEntry={newsEntry}
                            key={newsEntry.sys.id}
                          />
                        ))
                      ) : (
                        <Col span={12}>
                          <p>
                            <i>
                              Diese Kategorie enthält aktuell keine
                              Newseinträge.
                            </i>
                          </p>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </Col>
            </Spin>
          </Row>

          {/* Pagination */}
          {totalItems > 0 && !isLoading && (
            <PaginationWrapper
              total={totalItems}
              current={pagination.current}
              pageSize={limit}
              onChange={(page: number) =>
                paginationHandler(activeNewsCategory.id, page)
              }
              showSizeChanger={false}
            />
          )}
        </>
      )}
    </Layout>
  );
}

export default NewsOverview;
