import React from "react";

import { useSelector } from "react-redux";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import clsx from "clsx";
import { Carousel, Col, Row } from "antd";
import { Entry } from "contentful";

import useGetAdWebshopDashboard from "../../../hooks/useGetAdWebshopDashboard";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import Picture from "../../picture/Picture";
import { ContentBlock, PictureTile, QuickLink } from "../../atoms";
import { RootState } from "../../../types/rootState";
import { ContentfulAdWebshopDashboardEntry } from "../../../types/contentfulAdWebshopDashboardEntry";

interface AdWebshopDashboardProps {
  className?: string;
}

const AD_TYPE = "ad webshop dashboard";
const DEFAULT_AD_TEXT = "Mehr erfahren";

const AdWebshopDashboard: React.FC<AdWebshopDashboardProps> = (
  props: AdWebshopDashboardProps
) => {
  const { className } = props;

  const isSmallScreen = useMedia(
    `(max-width: ${getCssVariable("screen-md-max")})`
  );

  const { stockName } = useSelector((state: RootState) => state?.userData);

  const { isLoading, hasError, entries } = useGetAdWebshopDashboard({
    stockName,
  });

  const { trackEvent } = useMatomo();

  if (isLoading || !entries?.length || hasError) {
    return null;
  }

  return (
    <ContentBlock
      className={clsx("ad-webshop-dashboard", className)}
      title="Lieferantenaktionen"
    >
      <Row gutter={[20, 10]}>
        {!isSmallScreen && !isLoading && !hasError && !!entries && (
          <>
            {entries.map(
              (ad: Entry<ContentfulAdWebshopDashboardEntry>, i: number) => (
                <Col
                  span={3}
                  key={`ad_webshop_dashboard_${i}`}
                  onLoad={() =>
                    trackEvent({
                      category: "ads",
                      action: `${AD_TYPE} impression`,
                      name: ad.fields?.internal_name || "",
                    })
                  }
                >
                  <PictureTile
                    title={null}
                    picture={
                      <Picture
                        imageScreenSm={`${ad.fields.banner.fields.file.url}?fit=fill&f=top&r=10&fm=webp&q=40`}
                        imageScreenXl={`${ad.fields.banner.fields.file.url}?fit=fill&f=top&r=10&fm=webp&q=80`}
                      />
                    }
                    link={
                      ad.fields?.banner_link ? (
                        <QuickLink
                          label={ad.fields?.banner_text || DEFAULT_AD_TEXT}
                          href={ad.fields.banner_link}
                          linkType="primary--outlined"
                          onClick={() =>
                            trackEvent({
                              category: "ads",
                              action: `${AD_TYPE} click`,
                              name: ad.fields?.internal_name || "",
                            })
                          }
                        />
                      ) : null
                    }
                    className="ad-webshop-dashboard__picture-tile"
                  />
                </Col>
              )
            )}
          </>
        )}

        {isSmallScreen &&
          !isLoading &&
          !hasError &&
          !!entries &&
          entries.length && (
            <Col span={12}>
              <Carousel>
                {entries.map(
                  (ad: Entry<ContentfulAdWebshopDashboardEntry>, i: number) => (
                    <div
                      key={`ad_webshop_dashboard_${i}`}
                      onLoad={() =>
                        trackEvent({
                          category: "ads",
                          action: `${AD_TYPE} impression`,
                          name: ad.fields?.internal_name || "",
                        })
                      }
                    >
                      <PictureTile
                        key={`ad_webshop_dashboard_${i}`}
                        title={null}
                        picture={
                          <Picture
                            imageScreenXs={`${ad.fields.banner.fields.file.url}?fit=fill&f=top&r=10&fm=webp&q=40`}
                            imageScreenSm={`${ad.fields.banner.fields.file.url}?fit=fill&f=top&r=10&fm=webp&q=40`}
                          />
                        }
                        link={
                          ad.fields?.banner_link ? (
                            <QuickLink
                              label={ad.fields?.banner_text || DEFAULT_AD_TEXT}
                              href={ad.fields.banner_link}
                              linkType="primary--outlined"
                              onClick={() =>
                                trackEvent({
                                  category: "ads",
                                  action: `${AD_TYPE} click`,
                                  name: ad.fields?.internal_name || "",
                                })
                              }
                            />
                          ) : null
                        }
                        className="ad-webshop-dashboard__picture-tile"
                      />
                    </div>
                  )
                )}
              </Carousel>
            </Col>
          )}
      </Row>
    </ContentBlock>
  );
};

export default AdWebshopDashboard;
