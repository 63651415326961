import i18n from "i18next";

interface LocalizedTypes {
  locales?: string | string[];
  options?: Record<string, any>;
  value: number;
}

/**
 * wrapper for parsing localized numbers
 * @param locales {string | string[]}
 * @param options {Object}
 * @param value {number}
 * @return {string}
 */
const localizedNumberFormat = ({
  locales = i18n.language,
  options = {},
  value,
}: LocalizedTypes) => Intl.NumberFormat(locales, options).format(value);

/**
 * wrapper function to simplify call for parsing
 * @param value {number}
 * @param currency {string}
 * @param options {Object}
 */
const parsePriceValueToLocalPrice = (
  value: number,
  currency = "EUR",
  options = {}
) =>
  localizedNumberFormat({
    options: { ...{ style: "currency", currency }, ...options },
    value: value / 100,
  });

export default localizedNumberFormat;
export { parsePriceValueToLocalPrice };
