import React from "react";

import clsx from "clsx";
import { Space } from "antd";
import { areEqual } from "react-window";

import ProductAddToPriceTagListButton from "../../../../../product/ProductAddToPriceTagListButton";
import ProductAddToFavouritesListButton from "../../../../../product/ProductAddToFavouritesListButton";
import { ProductData } from "../../../../../../types/productData";

interface WeekplannerItemActionsProps {
  productData: ProductData;
  className?: string;
}

const WeekplannerItemActions: React.FC<WeekplannerItemActionsProps> = (
  props: WeekplannerItemActionsProps
) => {
  const { productData, className } = props;

  const { sku } = productData || {};

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-actions",
        className
      )}
    >
      <Space>
        <ProductAddToPriceTagListButton sku={sku} />
        <ProductAddToFavouritesListButton sku={sku} />
      </Space>
    </div>
  );
};

export default React.memo(WeekplannerItemActions, areEqual);
