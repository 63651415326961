import { AxiosResponse, CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { termsAndConditionsConsentEndpoint } from "../endpoints";

/**
 * sanitize response from spryker
 * @param response {AxiosResponse}
 */
const getSanitizedTermsAndConditionResponse = (response: AxiosResponse) => {
  if (!response?.data?.data) {
    return {
      agbConsentDateTime: null,
      isSuccess: false,
    };
  }

  const {
    data: { data },
  } = response;

  const {
    attributes: { agbConsentDateTime, isSuccess },
  } = data[0];

  return { agbConsentDateTime, isSuccess };
};

/**
 * get for the current user the state of accepted terms and conditions
 * is used e.g. for prefilled checkbox in cart / checkout
 * @param cancelTokenSource {CancelTokenSource}
 */
const getUserTermsAndConditionsState = (cancelTokenSource: CancelTokenSource) =>
  axiosWithAuth()
    .get(termsAndConditionsConsentEndpoint, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response?.data?.data) {
        return Promise.reject(response);
      }

      return getSanitizedTermsAndConditionResponse(response);
    });

export default getUserTermsAndConditionsState;
