import { CancelTokenSource } from "axios";
import { allOrdersEndpoint } from "../endpoints";
import axiosWithAuth from "../axiosWithAuth";

/**
 * get all details of an order
 * @param orderId {string}
 * @param cancelTokenSource {CancelTokenSource}
 * @returns {Promise}
 */
const getOrderDetail = (
  orderId: string,
  cancelTokenSource: CancelTokenSource
) => {
  if (!orderId) {
    Promise.reject(new Error("No orderId given."));
  }

  return axiosWithAuth()
    .get(
      `${allOrdersEndpoint}/${orderId}?include=items,abstract-products,concrete-products,concrete-product-image-sets`,
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((res) => {
      if (res.status !== 200) {
        return Promise.reject(res);
      }

      return res.data;
    });
};

export default getOrderDetail;
