import { message, Spin } from "antd";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import requestCatchHandler from "../../../api/requestCatchHandler";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import useUpdateCartItemQuantity from "../../../hooks/useUpdateCartItemQuantity";
import { messageData } from "../../../appConfig";
import { ReactComponent as Delete } from "../../../static/svg/delete.svg";
import MoveSingleItemToDateButton from "../MoveProductItemsToDate/MoveSingleItemToDateButton";

interface Props {
  sku: string;
}

const CartMoveOrDeleteItemButtonCombination: React.FC<Props> =
  function CartMoveOrDeleteItemButtonCombination({ sku }: Props) {
    const { id: cartId, deliveryDate } = useSelector(
      (state: any) => state.currentCartMetaData
    );
    const [isLoading, setIsLoading] = useState(false);
    const updateCartItemQuantity = useUpdateCartItemQuantity();

    // store token in reference to persist it over the lifecycles
    const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
    useCancelAxiosOnUnmount(cancelTokenSource.current);

    /*
     * delete cart item, but only if the response is successful
     */
    const deleteItem = async () => {
      setIsLoading(true);

      updateCartItemQuantity({
        deliveryDate,
        cartId,
        sku,
        quantity: 0,
        cancelTokenSource: cancelTokenSource.current,
      })
        .then(() => {
          message.success(messageData.success.cart.deleteItem);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setIsLoading(false);

            message.error(messageData.error.cart.deleteItem);
          }

          requestCatchHandler(error);
        });
    };

    return (
      <div className="cartMoveItemDeleteItemCombination">
        <Spin size="small" spinning={isLoading}>
          <button
            type="button"
            onClick={() => deleteItem()}
            className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText"
          >
            <Delete className="icon" />
          </button>
        </Spin>
        <MoveSingleItemToDateButton sku={sku} />
      </div>
    );
  };

export default CartMoveOrDeleteItemButtonCombination;
