import React from "react";

import clsx from "clsx";
import { Affix } from "antd";

interface SidebarContentProps {
  content: React.ReactNode;
  withAffix?: boolean;
  className?: string;
}

const SidebarContent: React.FC<SidebarContentProps> = (props) => {
  const { content, withAffix = false, className } = props;

  if (!withAffix) {
    return <div className={clsx("sidebar__content", className)}>{content}</div>;
  }

  return (
    <Affix offsetTop={120}>
      <div
        className={clsx(
          "sidebar__content",
          "sidebar__content--with-affix",
          className
        )}
      >
        {content}
      </div>
    </Affix>
  );
};

export default SidebarContent;
