import axiosWithAuth from "../axiosWithAuth";
import { getProductDataFromPriceTagListEndpoint } from "../endpoints";
import getDeliveryDateQuery from "../getDeliveryDateQuery";
import getCatalogSearchAttributesData from "../products/getCatalogSearchAttributesData";
import { GetUserListProducts } from "../../types/userListProducts";
import storeDispatch from "../../state/actions/storeDispatch";
import { ProductData } from "../../types/productData";
import getPaginationParams from "../../utils/getPaginationParams";

/**
 * retrieve concrete product data for products on priceTagList
 * @param cancelTokenSource {CancelTokenSource}
 * @param deliveryDate {string}
 * @param page {number}
 * @param pageSize {number}
 * @return {Promise<AxiosResponse|Array<ProductData|empty>>}
 */
const getProductsOnPriceTagList = async ({
  cancelTokenSource,
  deliveryDate,
  page,
  pageSize,
}: GetUserListProducts) => {
  const deliveryDateAppendix = getDeliveryDateQuery(deliveryDate, true);
  const paginationAppendix = getPaginationParams(page, pageSize);

  return axiosWithAuth()
    .get(
      `${getProductDataFromPriceTagListEndpoint}${deliveryDateAppendix}${paginationAppendix}`,
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (!response?.data?.data || response.status > 200) {
        Promise.reject(response);
      }

      const { concreteProducts, pagination, printTypes } =
        getCatalogSearchAttributesData(response?.data?.data);

      // update redux with new list
      storeDispatch("priceTagList/set-list", {
        priceTags: concreteProducts?.map(({ sku }: ProductData) => ({ sku })),
      });

      // save printTypes (info for print buttons to choose labels)
      storeDispatch("priceTagList/set-printTypes", {
        printTypes,
      });

      return {
        concreteProducts: concreteProducts || [],
        pagination: pagination || {},
      };
    });
};

export default getProductsOnPriceTagList;
