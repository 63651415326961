import moment from "moment";

/**
 * define disabled dates
 * @param {moment.Moment} current
 * @param {moment.Moment} lastDay
 */
const disabledDatesAfter = (current: moment.Moment, lastDay: moment.Moment) => {
  const dayAfter = lastDay.add(1, "days");

  return current && current >= dayAfter;
};

export default disabledDatesAfter;
