import { PodcastState } from "../../types/podcast";

const initialState: PodcastState = {
  academy: null,
  consumer: null,
  expirationTimestamp: null,
};

const podcastReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "podcast/set-podcast-academy":
      return { ...state, academy: payload };
    case "podcast/set-podcast-consumer":
      return { ...state, consumer: payload };
    case "podcast/set-expiration-timestamp":
      return { ...state, expirationTimestamp: payload };
    default:
      return state;
  }
};

export default podcastReducer;
