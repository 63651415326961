import moment from "moment";
import { GetProductAttributeValues } from "../../types/productAttributes";

/**
 * helper to test if the value is of type number or string
 * @param {*} value
 * @returns boolean
 */
const isStringOrNumber = (value: any) =>
  ["string", "number"].includes(typeof value);

/**
 * retrieve an attributes value as the string from the attribute
 * or the objects key name as a string, if a date range matches
 * @param attribute {string}
 * @param deliveryDate {string}
 */
const getAttributeValueFromObject = ({
  attribute,
  deliveryDate,
}: GetProductAttributeValues) => {
  // the given attribute is a string or number, we just want that
  if (isStringOrNumber(attribute)) {
    return String(attribute);
  }

  // the attribute is an object
  if (typeof attribute === "object") {
    let defaultFallbackValue = "";
    const deliveryDateFormatted = moment(deliveryDate);

    // check if a value of the attribute set is in date range
    const foundValue = Object.keys(attribute).reduce(
      (accumulator: string, currentKey: string) => {
        const currentAttributeValue = attribute[currentKey];

        /*
         * if the value is not an array of objects, there is no timestamp
         * so it is safe to assume that it is data garbage or corrupted and could be ignored
         */
        if (isStringOrNumber(currentAttributeValue)) {
          return accumulator;
        }

        // check if the deliveryDate is at least within the range of one date set
        const isDateInDataSet = currentAttributeValue?.some(
          ({ from, to }: Record<string, string>) => {
            /*
             * if the from and to value are empty strings
             * save the value to the fallback
             */
            if (!from && !to) {
              defaultFallbackValue = currentKey;
            }

            /*
             * test if the current cart date
             * is in the range of the provided values
             */
            return deliveryDateFormatted.isBetween(
              new Date(from),
              new Date(to)
            );
          }
        );

        /*
         * if the date matches, return the current object key
         * which will be used as the value to render
         */
        if (isDateInDataSet) {
          return currentKey;
        }

        // if nothing found return the reducer accumulator
        return accumulator;
      },
      defaultFallbackValue
    );

    return String(foundValue || defaultFallbackValue);
  }

  // none of the above, ignore it
  return "";
};

export default getAttributeValueFromObject;
