import React from "react";
import moment from "moment";
import { ReactComponent as Arrow } from "../static/svg/arrow.svg";
import { fullDateFormat, timeFormat } from "./dateFormats";

interface CompareProps {
  productGroup: Record<string, any>;
  lookupGroup: string;
  groupType: string;
  deliveryDate?: string;
}

/**
 * if no matching group by curent group is available
 * create a new,
 * otherwise use the latest
 * » key is used as react & table identifier
 * » and therefore generated of the first matching sku and the current index
 * @param {Object} productGroup
 * @param {string} lookupGroup
 * @param {string} groupType
 * @param {string} deliveryDate
 * @returns {Object}
 */
const compareProductCollectionGroups = ({
  productGroup = {},
  lookupGroup,
  groupType,
  deliveryDate,
}: CompareProps) => {
  /*
   * create a new set if there is no matching group and no lookupGroup, so it is save to assume, its null | undefined
   * or create a new set, if the key of the matching group does not contain the current group, so it is new
   */
  if (
    productGroup[groupType] !== lookupGroup ||
    (!lookupGroup && !Object.keys(productGroup).length)
  ) {
    if (groupType === "deadline") {
      const timeStamp = parseInt(lookupGroup, 10);
      const deadlineText = timeStamp ? (
        <>
          Bis <time>{moment.unix(timeStamp).format(timeFormat)}</time> Uhr am{" "}
          <time>{moment.unix(timeStamp).format(fullDateFormat)}</time>
        </>
      ) : (
        "Bis zu einem Werktag vorher"
      );

      return {
        key: `deadline-${lookupGroup}`,
        deadline: lookupGroup,
        // render a text at the same position as the product info
        articleElement: (
          <h3>
            {deadlineText} für den{" "}
            <time>{moment(deliveryDate).format(fullDateFormat)}</time> bestellen
          </h3>
        ),
        children: [],
      };
    }

    return {
      key: `productgroup-${lookupGroup}`,
      productGroup: lookupGroup,
      // render a text at the same position as the product info
      articleElement: (
        <span className="cartItemGroupHeading">
          {lookupGroup || "Keine Warengruppe"}
        </span>
      ),
      children: [],
      // add an expand icon
      interactionIcon: <Arrow className="icon iconToggle" />,
    };
  }

  // if none of the above fits, just return the matched group, which is the correct one
  return productGroup;
};

export default compareProductCollectionGroups;
