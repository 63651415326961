import React, { forwardRef } from "react";

import clsx from "clsx";
import { Layout } from "antd";

import { ContentBlock, QuickLink } from "../../../atoms";
import Picture from "../../../picture/Picture";
import { emailAddresses } from "../../../../appConfig";
import SupportContactImage from "../../../../static/images/support-kontakt.png";

interface SupportContactProps {
  className?: string;
}

type Ref = HTMLDivElement;

const SupportContact: React.FC<SupportContactProps> = forwardRef<
  Ref,
  SupportContactProps
>((props, ref) => {
  const { className } = props;

  return (
    <div ref={ref} className={clsx("support-contact", className)}>
      <Layout className="container-layout">
        <ContentBlock
          title="Kontaktieren Sie uns"
          icon={<Picture imageScreenSm={SupportContactImage} />}
        >
          <p>
            Sie möchten direkt mit uns sprechen? Kein Problem, melden Sie sich
            doch ganz einfach per Mail bei uns. Wir kümmern uns das so schell es
            geht um ihr Anliegen und finden gemeinsam die passende Lösung für
            Ihr Problem. Klicken Sie dazu bei Bedarf einfach auf den
            nachfolgenden Button.
          </p>
          <p>
            <QuickLink
              label={emailAddresses.feedback}
              href={`mailto:${emailAddresses.feedback}`}
              linkType="primary--outlined"
            />
          </p>
        </ContentBlock>
      </Layout>
    </div>
  );
});

export default SupportContact;
