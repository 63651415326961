import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { orderInvoiceFileEndpoint } from "../endpoints";

interface GetOrderInvoiceFileProps {
  invoiceNumber: string;
  cancelTokenSource: CancelTokenSource;
}

const getOrderInvoiceFile = async (props: GetOrderInvoiceFileProps) => {
  const { invoiceNumber, cancelTokenSource } = props;

  return axiosWithAuth()
    .get(`${orderInvoiceFileEndpoint}/${invoiceNumber}`, {
      responseType: "blob",
      cancelToken: cancelTokenSource.token,
      validateStatus: (status: number) => {
        return status === 200;
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${invoiceNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
};

export default getOrderInvoiceFile;
