import React from "react";
import { BackTop } from "antd";
import { ReactComponent as Arrow } from "../../static/svg/arrow.svg";

const ButtonBackToTop = function ButtonBackToTop() {
  return (
    <BackTop visibilityHeight={250} duration={0} className="customBackTop">
      <div className="buttonBackToTop">
        <Arrow className="icon" />
      </div>
    </BackTop>
  );
};

export default ButtonBackToTop;
