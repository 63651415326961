import { cartQueryString, cartsEndpoint } from "../endpoints";
import axiosWithAuth from "../axiosWithAuth";
import getDeliveryDateQuery from "../getDeliveryDateQuery";
import { UpdateCartData } from "../../types/cartAPI";

/**
 * PATCH item in spryker cart
 * @param cancelTokenSource {CancelTokenSource}
 * @param deliveryDate {string}
 * @param quantity {string | number}
 * @param sku {string}
 * @return {Promise<AxiosResponse>}
 */
const patchItemInCart = ({
  cancelTokenSource,
  deliveryDate,
  quantity,
  sku,
}: UpdateCartData) =>
  axiosWithAuth()
    .patch(
      `${cartsEndpoint}/${deliveryDate}/items/${sku}${cartQueryString}${getDeliveryDateQuery(
        deliveryDate
      )}`,
      {
        data: {
          type: "items",
          attributes: {
            quantity: String(quantity),
          },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (response?.status !== 200) {
        return Promise.reject(response);
      }

      return response;
    });

export default patchItemInCart;
