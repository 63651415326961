import contentfulClient from "./contentfulClient";

/**
 * retrieve a single entry from the current contentful instance
 * @param entryId {string}
 */
const getContentfulEntry = (entryId: string) =>
  contentfulClient()
    .getEntry(entryId)
    .then((contentfulEntry: any) => {
      if (!contentfulEntry) {
        return Promise.reject(contentfulEntry);
      }

      /*
       * return only the inner first matched Entry
       * which matches the default contentful structure of <Entry>
       */
      if (contentfulEntry?.items?.length) {
        return contentfulEntry.items.find(
          (entry: any) => entry?.sys?.id === entryId
        )[0];
      }

      return contentfulEntry;
    });

export default getContentfulEntry;
