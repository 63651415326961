import { message, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { ReactComponent as Delete } from "../../static/svg/delete.svg";
import deleteProductFromPriceTagList from "../../api/priceTagList/deleteProductFromPriceTagList";
import { messageData } from "../../appConfig";
import requestCatchHandler from "../../api/requestCatchHandler";
import { ProductData } from "../../types/productData";
import getCancelTokenSource from "../../api/getCancelTokenSource";

interface Props {
  sku: ProductData["sku"];
}

/**
 * removal button for priceTagList
 * @param {ProductData["sku"]} sku
 * @constructor
 */
const PriceTagListRemoveItemButton: React.FC<Props> =
  function PriceTagListRemoveItemButton({ sku }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());

    /**
     * remove item from list
     */
    const deleteItem = async () => {
      setIsLoading(true);

      return deleteProductFromPriceTagList({
        sku: [sku],
        cancelTokenSource: cancelTokenSource.current,
      })
        .then(() => {
          setIsLoading(false);
          message.success(messageData.success.priceTagList.removeFromList);
        })
        .catch((error) => {
          setIsLoading(false);

          if (!axios.isCancel(error)) {
            // revert the state change again on failure
            message.error(messageData.error.unexpected);
          }

          requestCatchHandler(error);
        });
    };

    useEffect(() => {
      const currentCancelTokenSource = cancelTokenSource.current;

      return () => {
        currentCancelTokenSource.cancel();
      };
    }, [cancelTokenSource]);

    return (
      <Spin spinning={isLoading} size="small">
        <button
          type="button"
          onClick={() => deleteItem()}
          className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText color-primary"
        >
          <Delete className="icon" />
        </button>
      </Spin>
    );
  };

export default PriceTagListRemoveItemButton;
