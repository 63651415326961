import React from "react";
import clsx from "clsx";
import UserNavigation from "../molecules/UserNavigation";
import { ReactComponent as Close } from "../../static/svg/close.svg";

interface Props {
  setIsAccountVisible: (value: boolean) => void;
  isFooterComponent?: boolean;
}

const UserProfile: React.FC<Props> = ({
  setIsAccountVisible,
  isFooterComponent = false,
}: Props) => {
  return (
    <div className="contactPerson">
      <div
        className={clsx(
          "headerContactPerson",
          isFooterComponent && "headerContactPersonMobile",
          "alignRight"
        )}
      >
        <button
          onClick={() => {
            setIsAccountVisible(false);
          }}
          className="button buttonPrimary buttonPrimary--inverted closeContactPerson"
          type="button"
        >
          <Close className="closeIcon" />
        </button>
      </div>
      <UserNavigation
        onListItemClick={() => setIsAccountVisible(false)}
        className="user-navigation-mobile"
      />
    </div>
  );
};

export default UserProfile;
