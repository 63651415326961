import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { routePathNames } from "../../appConfig";
import ShowCookiesTrigger from "../atoms/ShowCookiesTrigger";
import { RootState } from "../../types/rootState";

interface FooterLegalLinksProps {
  navClassName?: string;
  listClassName?: string;
  entryClassName?: string;
}

interface FooterLegalLink {
  name: string;
  target: string;
  isPrivate: boolean;
}

const legalLinks: FooterLegalLink[] = [
  { name: "Impressum", target: routePathNames.imprint, isPrivate: false },
  { name: "Datenschutz", target: routePathNames.privacy, isPrivate: false },
  {
    name: "Bestellinformationen",
    target: routePathNames.orderingInformation,
    isPrivate: true,
  },
  {
    name: "AGB",
    target: routePathNames.termsAndConditions,
    isPrivate: false,
  },
];

const FooterLegalLinks: React.FC<FooterLegalLinksProps> = (
  props: FooterLegalLinksProps
) => {
  const { navClassName, listClassName, entryClassName } = props;

  const { accessToken } = useSelector((state: RootState) => state.auth);

  const isLoggedIn = useMemo(() => !!accessToken, [accessToken]);

  return (
    <nav className={navClassName}>
      <ul className={listClassName}>
        <li className={entryClassName}>
          <ShowCookiesTrigger underline={false} />
        </li>
        {legalLinks.map((legalLink: FooterLegalLink) => {
          if (legalLink.isPrivate && !isLoggedIn) {
            return null;
          }
          return (
            <li className={entryClassName} key={legalLink.name}>
              <Link to={legalLink.target}>{legalLink.name}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default FooterLegalLinks;
