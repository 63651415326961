import ensureFutureWeekplannerDates from "./ensureFutureWeekplannerDates";
import { locationSearchQueryParameter, routePathNames } from "../appConfig";
import { WeekdayData } from "./createWeekplannerDates";

interface ProductViewsUrlParameterData {
  pathname: string;
  deliveryDate: string;
  weekDates: WeekdayData[];
  categoryKey?: string;
  filters?: string;
  isSearch?: boolean;
  page?: number;
  searchTerm?: string | string[];
  sortBy?: string | null;
  sortDirection?: string | null;
}

/**
 * construct url with page and delivery date for multiple routes
 * used for "weekPlanner", "productList"
 * @param {string} pathname
 * @param {string} deliveryDate
 * @param {WeekdayData[]} weekDates
 * @param {string | null} categoryKey
 * @param {string} filters
 * @param {boolean} isSearch
 * @param {number} page
 * @param {string | string []} searchTerm
 * @param {string | null} sortBy
 * @param {string | null} sortDirection
 * @return {string}
 */
const createProductViewUrl = ({
  pathname,
  deliveryDate,
  weekDates,
  categoryKey,
  filters = "",
  isSearch,
  page = 1,
  searchTerm,
  sortBy,
  sortDirection,
}: ProductViewsUrlParameterData) => {
  const {
    deliveryDate: paramDeliveryDate,
    deliveryDateFrom: paramDeliveryDateFrom,
    page: paramPage,
    searchTerm: paramSearchTerm,
  } = locationSearchQueryParameter;

  const pageParam = `&${paramPage}=${page}`;
  const filterParams = filters ? `&${filters}` : "";

  // test if the route should direct to the weekplanner
  const isWeekPlannerRoute = pathname.includes(
    routePathNames.weekPlanner.replace(/\//g, "")
  );
  let deliveryDateParam = `${paramDeliveryDate}=${deliveryDate}`;

  // if it is a weekplanner route, add different parameters
  if (isWeekPlannerRoute) {
    if (deliveryDate) {
      deliveryDateParam = `${paramDeliveryDateFrom}=${deliveryDate}`;
    } else if (weekDates.length) {
      const { startDate } = ensureFutureWeekplannerDates(
        weekDates[0]?.apiDate,
        weekDates[weekDates.length - 1]?.apiDate
      );
      deliveryDateParam = `${paramDeliveryDateFrom}=${startDate}`;
    }
  }
  const sortParams =
    sortBy && sortDirection
      ? `&sortBy=${sortBy}&sortDirection=${sortDirection}`
      : "";

  const subPath =
    !isSearch && categoryKey
      ? `${routePathNames.category}${categoryKey}`
      : routePathNames.search;

  const searchParam = searchTerm?.length
    ? `&${paramSearchTerm}=${searchTerm.toString()}`
    : "";

  return `${pathname}${subPath}?${deliveryDateParam}${pageParam}${filterParams}${sortParams}${searchParam}`;
};

export default createProductViewUrl;
