import React, { useCallback, useEffect, useState } from "react";
import getPlainText from "../../api/getPlainText";
import { defaultNewsCategoryTitle } from "../../appConfig";
import { MyNews, Placeholder } from "../../static/svg";
import { ContentfulCategoryEntry } from "../../types/contentfulCategoryEntry";

interface CategoriesIconProps {
  categoryEntry: ContentfulCategoryEntry;
  className?: string;
}

const CategoriesIcon: React.FC<CategoriesIconProps> = function CategoriesIcon({
  categoryEntry,
  className,
}: CategoriesIconProps) {
  const { icon, title } = categoryEntry;
  const [iconVisual, setIconVisual] = useState(null);
  const [svgMarkup, setSvgMarkup] = useState<string>("");

  /**
   * get an icon for the component if none is set
   * @param iconName {string}
   */
  const getIcon = (iconName: string) => {
    const { default: allCategory, userPreferences } = defaultNewsCategoryTitle;

    switch (iconName) {
      // hard coded icon for all category
      case allCategory: {
        return <Placeholder className="icon" />;
      }

      // hard coded icon for custom user preferences
      case userPreferences: {
        return <MyNews className="icon" />;
      }

      // fallback icon
      default: {
        return <Placeholder className="icon" />;
      }
    }
  };

  /**
   * if an icon is provided, this should be used
   * if the icon url seems to be a SVG, try to fetch the content and set it as inner value
   * >> if an svg icon is retrieved, the div may not have children!
   * if none, there is always a fallback
   */
  const setIcon = useCallback(
    async function setIcon() {
      if (icon?.fields) {
        const {
          file: { url = "" },
          title: alt,
        } = icon.fields;

        if (url.indexOf(".svg") > 0) {
          const html = await getPlainText(url);
          setSvgMarkup(html);
        } else {
          setIconVisual(<img src={url} alt={alt} className="icon" />);
        }
      } else {
        const iconComponent = getIcon(title);

        setIconVisual(iconComponent);
      }
    },
    [icon?.fields, title]
  );

  useEffect(() => {
    setIcon();
  }, [setIcon]);

  // disable warning for dangerouslySetInnerHTML for this component
  /* eslint-disable react/no-danger */
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={svgMarkup ? { __html: svgMarkup } : null}
    >
      {svgMarkup ? null : iconVisual}
    </div>
  );
  /* eslint-enable react/no-danger */
};

export default CategoriesIcon;
