import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { routePathNames } from "../appConfig";
import useGetDeliveryDate from "./useGetDeliveryDate";
import setCartAndUserListsToStore from "../state/actions/setCartAndUserListsToStore";
import useGlobalNetworkState from "./useGlobalNetworkState";
import useUpdateUrlFragments from "./useUpdateUrlFragments";
import { PageTypes } from "../types/pageTypes";
import getCancelTokenSource from "../api/getCancelTokenSource";
import useSetDefaultDeliveryDate from "./useSetDefaultDeliveryDate";

interface UseSetCartAndUserListsProps {
  onSuccess?: () => void;
}

/**
 * setup cart and orders
 * if possible set correct deliveryDate to the URL
 */
const useSetCartAndUserLists = (props: UseSetCartAndUserListsProps) => {
  const { onSuccess } = props;

  const { pathname } = useLocation();
  const [, setComponentIsLoading] = useGlobalNetworkState("component", false);
  const [deliveryDate] = useGetDeliveryDate();
  const requestDate = useSetDefaultDeliveryDate();
  const setUpdateUrlFragments = useUpdateUrlFragments();
  const cancelTokenSource = useRef(getCancelTokenSource());
  const { product, products, search } = routePathNames;
  const dispatch = useDispatch();

  /**
   * determine the context for the url fragments
   */
  const context: PageTypes = useMemo(() => {
    if (pathname.includes(search)) {
      if (pathname.includes(products)) {
        return "searchProductList";
      }
      return "searchWeekPlanner";
    }

    if (pathname.includes(products)) {
      if (pathname.includes(product)) {
        return "detailPage";
      }

      return "productList";
    }

    return "default";
  }, [pathname, products, product, search]);

  useEffect(() => {
    dispatch({
      type: "cartMeta/set-deliveryDate",
      payload: requestDate,
    });
  }, [dispatch, requestDate]);

  /**
   * update deliveryDate and setup cart and orders
   */
  return useCallback(() => {
    // update query if dates differ
    if (requestDate !== deliveryDate) {
      setUpdateUrlFragments({
        context,
        targetPathname: pathname,
        parameters: {
          deliveryDate: requestDate,
        },
      });
    }

    setCartAndUserListsToStore({
      deliveryDate: requestDate,
      cancelTokenSource: cancelTokenSource.current,
      setComponentIsLoading,
    }).then(() => {
      if (typeof onSuccess === "function") onSuccess();
    });
  }, [
    cancelTokenSource,
    context,
    deliveryDate,
    pathname,
    requestDate,
    setComponentIsLoading,
    setUpdateUrlFragments,
    onSuccess,
  ]);
};

export default useSetCartAndUserLists;
