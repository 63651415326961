import { CancelTokenSource } from "axios";
import getAlreadyOrdered from "../../api/alreadyOrdered/getAlreadyOrdered";
import storeDispatch from "./storeDispatch";

interface ProductsOrderRequest {
  cancelTokenSource: CancelTokenSource;
}
/**
 * gets all orders for a specific product
 * than stores the order data to the redux
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise}
 */
const setAlreadyOrdered = async ({
  cancelTokenSource,
}: ProductsOrderRequest): Promise<void | any> =>
  getAlreadyOrdered(cancelTokenSource).then((response: any) => {
    if (response)
      storeDispatch("alreadyOrdered/set-order-item-index", response);
    else storeDispatch("alreadyOrdered/empty-order-item-index");

    return response;
  });

export default setAlreadyOrdered;
