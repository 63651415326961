import { Entry } from "contentful";

import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import { ContentfulSupportFaqEntry } from "../../types/contentfulSupportFaqEntry";

interface SupportFaqParams {
  limit: number;
  skip: number;
  withTotal?: boolean;
}

interface SupportFaqResponse {
  items: ContentfulSupportFaqEntry[];
  total: number;
}

/**
 * @param limit
 * @param skip
 * @param withTotal
 *
 * @return Promise<any>
 */
const getSupportFaq = async ({
  limit,
  skip,
  withTotal = true,
}: SupportFaqParams): Promise<SupportFaqResponse> =>
  getContentfulContentType({
    ...contentfulContentTypes.supportFaq,
    limit,
    skip,
    order: "-sys.createdAt",
    withTotal,
  }).then((response: any) => {
    if (!response) {
      return Promise.reject(response);
    }
    return {
      items:
        response.items?.map((supportFaqEntry: Entry<any>) => {
          const { question, answer } = supportFaqEntry?.fields || {};

          return {
            id: supportFaqEntry.sys.id,
            question: question || "",
            answer: answer || "",
          } as ContentfulSupportFaqEntry;
        }) || [],
      total: response.total,
    } as SupportFaqResponse;
  });

export default getSupportFaq;
