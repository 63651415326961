import React from "react";

import { message } from "antd";

/**
 * @param reason
 * @return JSX.Element
 */
const getCartErrorMessageContent = (
  reason = "Unbekannter Fehler."
): JSX.Element => (
  <>
    Die Bestellung kann nicht abgeschlossen werden.
    <br />
    <strong>{reason}</strong>
    <br />
    Stellen Sie sicher, dass eine Internetverbindung besteht und{" "}
    <strong>laden Sie die Seite neu</strong>.<br />
    Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an Ihre
    Kundenbetreuung.
  </>
);

/**
 * @param reason
 */
const showCartErrorMessage = (reason: string) => {
  message.error({ content: getCartErrorMessageContent(reason), duration: 6 });
};

export default showCartErrorMessage;
