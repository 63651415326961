import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { cartsEndpoint } from "../endpoints";

/**
 * DELETE spryker cart
 * @param cartId {string}
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise<AxiosResponse>}
 */
const deleteCart = (cartId: string, cancelTokenSource: CancelTokenSource) =>
  axiosWithAuth()
    .delete(`${`${cartsEndpoint}/${cartId}`}`, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (response?.status !== 204) {
        return Promise.reject(response);
      }
      return response;
    });

export default deleteCart;
