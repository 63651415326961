import { useMemo } from "react";
import moment from "moment";
import { apiDateFormat } from "../utils/dateFormats";
import useGetDeliveryDate from "./useGetDeliveryDate";

/**
 * set deliveryDate to store
 * or return date from url or store
 */
const useSetDefaultDeliveryDate = () => {
  const [deliveryDate] = useGetDeliveryDate();
  return useMemo(() => {
    const today = moment();

    if (!deliveryDate || moment(deliveryDate).isSameOrBefore(today)) {
      const todayIsoDay = today.isoWeekday();

      const dayShift = todayIsoDay < 7 ? 1 : 2;

      const tomorrow = today.add(dayShift, "days").format(apiDateFormat);

      return tomorrow;
    }

    return deliveryDate;
  }, [deliveryDate]);
};

export default useSetDefaultDeliveryDate;
