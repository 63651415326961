import React, { useEffect, useMemo, useRef, useState } from "react";

import axios, { CancelTokenSource } from "axios";
import { message } from "antd";
import ScanditBarcodeScanner from "scandit-sdk-react";
import { Barcode, BarcodePicker, ScanResult, ScanSettings } from "scandit-sdk";

import getProductByEan from "../../../../api/scanner/getProductByEan";
import getProductDetail from "../../../../api/products/getProductDetail";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import useCancelAxiosOnUnmount from "../../../../hooks/useCancelAxiosOnUnmount";
import StocktakingItemPostModal from "../StocktakingItemPostModal";
import { getValidDeliveryDate } from "../../../../utils/datePicker";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../../api/getCancelTokenSource";
import { messageData } from "../../../../appConfig";
import { apiDateFormat } from "../../../../utils/dateFormats";
import { ProductData } from "../../../../types/productData";

// Scandit License Key
const licenseKey = process.env.REACT_APP_SCANDIT;

interface StocktakingScannerViewProps {
  stocktakingId: number;
}

const StocktakingScannerView: React.FC<StocktakingScannerViewProps> = (
  props: StocktakingScannerViewProps
) => {
  const { stocktakingId } = props;

  const deliveryDate = getValidDeliveryDate().format(apiDateFormat);

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const scanButtonRef = useRef<HTMLButtonElement | null>(null);

  const [eanCode, setEanCode] = useState<string>(null);
  const [productData, setProductData] = useState<ProductData>(null);

  const [isPaused, setIsPaused] = useState(true);
  const [isPostModalVisible, setIsPostModalVisible] = useState<boolean>(false);

  const viewFinderArea = useMemo(
    () => ({ x: 0, y: 0.3, width: 1, height: 0.35 }),
    []
  );

  const scanSettings = useMemo(
    () =>
      new ScanSettings({
        enabledSymbologies: [
          Barcode.Symbology.EAN8,
          Barcode.Symbology.EAN13,
          Barcode.Symbology.CODE39,
        ],
        searchArea: viewFinderArea,
      }),
    [viewFinderArea]
  );

  const activateScanner = () => {
    scanButtonRef?.current?.blur();
    setIsPaused(false);
  };

  const deactivateScanner = () => {
    scanButtonRef.current.focus();
    setIsPaused(true);
  };

  const getProductDataByEan = async (
    ean: string,
    currentCancelTokenSource: CancelTokenSource,
    messageKey: string
  ) => {
    const data = await getProductByEan(
      ean,
      deliveryDate,
      currentCancelTokenSource
    )
      .then((response: any) => {
        if (!response?.length) {
          return null;
        }

        return response?.[0] as ProductData;
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) {
          message.warning({
            ...messageData.warning.cancelRequest,
            key: messageKey,
          });
        }
        requestCatchHandler(error);
        return {} as ProductData;
      });
    return data;
  };

  const getProductDataBySku = async (
    sku: string,
    currentCancelTokenSource: CancelTokenSource,
    messageKey: string
  ) => {
    const data = await getProductDetail({
      productSku: sku,
      deliveryDate,
      cancelTokenSource: currentCancelTokenSource,
    })
      .then((response: any) => {
        if (!response?.concreteProducts) {
          return null;
        }

        return response?.concreteProducts?.[0] as ProductData;
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) {
          message.warning({
            ...messageData.warning.cancelRequest,
            key: messageKey,
          });
        }
        requestCatchHandler(error);
        return {} as ProductData;
      });
    return data;
  };

  const onReady = () => {
    message.success({
      ...messageData.success.scanner.scannerReady,
    });
  };

  const onScanError = () => {
    message.error({
      ...messageData.error.scanner.scanError,
    });
  };

  const onScan = async (scanResult: ScanResult): Promise<void> => {
    const barcode = scanResult.barcodes?.[0]?.data;

    if (!barcode) {
      return onScanError();
    }

    const isEasyOrderBarcode = barcode.length < 8;
    const onScanMessageKey = `${barcode}_${Date.now()}`;

    setIsPaused(true);

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    const scannedProductData: ProductData = !isEasyOrderBarcode
      ? await getProductDataByEan(
          barcode,
          cancelTokenSource.current,
          onScanMessageKey
        )
      : await getProductDataBySku(
          barcode,
          cancelTokenSource.current,
          onScanMessageKey
        );

    setEanCode(barcode);
    setProductData(scannedProductData);
    setIsPostModalVisible(true);

    return Promise.resolve();
  };

  useEffect(() => {
    navigator?.mediaDevices?.enumerateDevices().then((devices) => {
      const cameraAccessValid = devices.some(
        (device) => device.kind === "videoinput" && device.label !== ""
      );

      if (!cameraAccessValid) {
        message.warning({
          ...messageData.warning.scanner.accessDenied,
        });
      }
    });
  }, []);

  return (
    <>
      <div className="scannerView">
        <ScanditBarcodeScanner
          accessCamera
          engineLocation="./static/scandit"
          licenseKey={licenseKey}
          guiStyle={BarcodePicker.GuiStyle.VIEWFINDER}
          onReady={onReady}
          onScan={onScan}
          onScanError={onScanError}
          paused={isPaused}
          playSoundOnScan
          preloadBlurryRecognition
          preloadEngine
          scanSettings={scanSettings}
          vibrateOnScan
          videoFit={BarcodePicker.ObjectFit.COVER}
          viewFinderArea={viewFinderArea}
          visible
        />

        <button
          type="button"
          className="button buttonPrimary scannerButtonScan"
          onClick={isPaused ? activateScanner : deactivateScanner}
          ref={scanButtonRef}
        >
          {isPaused ? "Artikel erfassen" : "Erfassung stoppen"}
        </button>
      </div>
      <StocktakingItemPostModal
        stocktakingId={stocktakingId}
        eanCode={eanCode}
        productData={productData}
        isVisible={isPostModalVisible}
        setVisibleCallback={setIsPostModalVisible}
        onSave={() => {
          setEanCode(null);
          setProductData(null);
        }}
        onError={() => {
          setEanCode(null);
          setProductData(null);
        }}
      />
    </>
  );
};

export default StocktakingScannerView;
