import { AxiosResponse } from "axios";

/**
 * get api level header
 * @param {AxiosResponse.headers} headers
 */
const getApiLevelHeader = (headers: AxiosResponse["headers"]) =>
  headers?.["x-api-level"];

export default getApiLevelHeader;
