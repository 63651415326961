import React, { useMemo, useRef } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import { message } from "antd";
import { ProductImage } from "../product";
import { messageData, routePathNames } from "../../appConfig";
import { ProductData } from "../../types/productData";
import getDeliveryDateBasedAttributes from "../product/getDeliveryDateBasedAttributes";
import getDefaultImageUrlSet from "../../utils/getDefaultImageUrlSet";
import { SuggestItem } from "../../types/suggestItem";
import ButtonAddToCart from "../buttons/ButtonAddToCart";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import useCancelAxiosOnUnmount from "../../hooks/useCancelAxiosOnUnmount";
import getCartErrorMessage from "../../utils/getCartErrorMessage";
import requestCatchHandler from "../../api/requestCatchHandler";
import useUpdateCartItemQuantity from "../../hooks/useUpdateCartItemQuantity";

interface Props {
  productSuggestData: ProductData;
  type?: SuggestItem;
  deliveryDate: string;
  isAlreadyOnList?: boolean;
  isAlreadyOnListWarning?: string;
}

const ProductSuggestion = ({
  productSuggestData,
  deliveryDate,
  isAlreadyOnList,
  isAlreadyOnListWarning,
  type = "search",
}: Props) => {
  const { availabilities, name, sku, imageSets, brand, vpeQuantity, vpeText } =
    productSuggestData;

  const { isUnavailable, nextAvailability } = getDeliveryDateBasedAttributes({
    availabilities,
    deliveryDate,
  });
  // redux
  const { cartItems } = useSelector((state: any) => state.currentCart);

  const { id: cartId } = useSelector((state: any) => state.currentCartMetaData);
  const updateCartItemQuantity = useUpdateCartItemQuantity();
  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);
  const isProductUnavailable = isUnavailable && type !== "priceTagList";

  const key = `${name}_${sku}`;
  const quickOrderIsOnList = isAlreadyOnList && type !== "search";

  const suggestItemProductBrand = `${brand ? ` | ${brand}` : ""}`;
  const suggestItemProductVpe = `${
    vpeQuantity || vpeText
      ? ` | ${vpeQuantity ? `${vpeQuantity}x ` : ""}${vpeText || ""}`
      : ""
  }`;
  // product quantities
  const quantity = useMemo(
    () =>
      parseInt(
        cartItems?.find((cartEntry: ProductData) => cartEntry.sku === sku)
          ?.quantity,
        10
      ) || 0,
    [cartItems, sku]
  );
  /**
   * handler to add item to cart or display short hint that item is already in cart
   */
  const addItemToCart = () => {
    updateCartItemQuantity({
      deliveryDate,
      cartId,
      sku,
      quantity: 1,
      cancelTokenSource: cancelTokenSource.current,
    })
      .then((cartResponse) => {
        if (cartResponse.status > 201) {
          return Promise.reject(cartResponse);
        }

        const cartErrorMessage = getCartErrorMessage({
          response: cartResponse,
          sku,
        });

        if (cartErrorMessage?.reason) {
          message.error({
            content: cartErrorMessage.reason,
          });
        }

        return cartResponse;
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error({
            content:
              error?.response?.data?.errors[0]?.detail ||
              messageData.error.unexpected.content,
          });
        }

        requestCatchHandler(error);
      });
  };

  const innerSuggestItem = (
    <div className="suggestItemProductInfo">
      {quickOrderIsOnList ? (
        <span className="suggestItemCartInfo">
          {sku} {isAlreadyOnListWarning}
        </span>
      ) : (
        <>
          <span className="suggestItemProductName">{name}</span>
          <span className="suggestItemProductDetails">
            {`${sku}${suggestItemProductBrand}${suggestItemProductVpe}`}
          </span>
        </>
      )}
    </div>
  );

  return (
    <div className="suggestItem" key={key} aria-disabled={isProductUnavailable}>
      {type === "search" ? (
        <Link
          to={`${routePathNames.product}${sku}`}
          className="suggestItemLink"
        >
          <ProductImage
            imageSet={getDefaultImageUrlSet(imageSets)}
            className={clsx({
              isUnavailable: isProductUnavailable && !nextAvailability,
            })}
            imageType="suggestion"
          />

          {innerSuggestItem}
          <span className="suggestAddToCart">
            <ButtonAddToCart
              type={quantity ? "filled" : "default"}
              badgeCount={quantity}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                addItemToCart();
              }}
              withBadge={!!quantity}
            />
          </span>
        </Link>
      ) : (
        <span className="suggestItemLink">
          <ProductImage
            imageSet={getDefaultImageUrlSet(imageSets)}
            className={clsx({
              isUnavailable: isProductUnavailable && !nextAvailability,
            })}
            imageType="suggestion"
          />

          {innerSuggestItem}
        </span>
      )}
    </div>
  );
};

export default ProductSuggestion;
