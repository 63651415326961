import React from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";

import getCartItemsTotalValue from "../../../../../../utils/getCartItemsTotalValue";
import { parsePriceValueToLocalPrice } from "../../../../../../utils/localizedNumberFormat";
import LinkToCart from "../../../../../../Router/LinkToCart";
import ButtonAddToCart from "../../../../../buttons/ButtonAddToCart";
import { WeekdayData } from "../../../../../../utils/createWeekplannerDates";
import { ReactComponent as Cart } from "../../../../../../static/svg/cart.svg";

interface WeekplannerItemsHeaderCartProps {
  deliveryDate: string;
  weekdayData: WeekdayData;
  className?: string;
}

const WeekplannerItemsHeaderCart: React.FC<WeekplannerItemsHeaderCartProps> = (
  props: WeekplannerItemsHeaderCartProps
) => {
  const { deliveryDate, weekdayData, className } = props;

  const { dateString, apiDate, isPastDate, isToday } = weekdayData;

  const cartItemsTotalValue = useSelector((state: any) =>
    getCartItemsTotalValue(
      state.weekplannerData.weekCarts.find(
        (entry: any) => entry.deliveryDate === apiDate
      )?.deliveryDateItems || []
    )
  );

  const splitDateString = dateString?.split(",") ?? "";
  const monthDayString = apiDate?.split("-")?.[2] ?? "";
  const monthNumberString = apiDate?.split("-")?.[1] ?? "";

  return (
    <div
      className={clsx(
        "weekplanner-items-header-cell",
        "weekplanner-items-header-cart",
        isToday && "weekplanner-items-header-cart--today",
        className
      )}
    >
      <div>
        {`${
          splitDateString?.[0] ?? ""
        } ${monthDayString}.${monthNumberString}.`}
      </div>
      {!isPastDate && deliveryDate && (
        <LinkToCart deliveryDate={deliveryDate}>
          <ButtonAddToCart
            type="default"
            withBadge={cartItemsTotalValue > 0}
            badgeCount={parsePriceValueToLocalPrice(
              cartItemsTotalValue,
              "EUR",
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            )}
            title={`Zahlbetrag: ${parsePriceValueToLocalPrice(
              cartItemsTotalValue,
              "EUR",
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }
            )}`}
          />
        </LinkToCart>
      )}
      {isPastDate && (
        <button
          type="button"
          className="buttonAddToCart buttonActionForInput"
          disabled
        >
          <Cart className="icon iconCart iconCart--expired" />
        </button>
      )}
    </div>
  );
};

export default WeekplannerItemsHeaderCart;
