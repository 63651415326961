import React, { useState } from "react";

import { Button, Modal } from "antd";

import StocktakingScannerView from "./StocktakingScannerView";
import { ReactComponent as Back } from "../../../../static/svg/back.svg";
import { ReactComponent as Scan } from "../../../../static/svg/scan.svg";

interface StocktakingScannerProps {
  stocktakingId: number;
  isDisabled?: boolean;
  onClose?: () => void;
}

const StocktakingScanner: React.FC<StocktakingScannerProps> = (
  props: StocktakingScannerProps
) => {
  const { stocktakingId, isDisabled, onClose = () => {} } = props;

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button
        className="button buttonSecondary buttonWithIcon buttonWithSpin sm-with-full"
        icon={<Scan className="icon" />}
        disabled={isDisabled}
        onClick={() => {
          setIsVisible(true);
        }}
      >
        Artikel scannen
      </Button>

      {isVisible && (
        <Modal
          className="scannerOverlay"
          visible
          width={1000}
          footer={null}
          maskClosable={false}
          closable={false}
          destroyOnClose
          keyboard
          onCancel={() => {
            setIsVisible(false);
            onClose();
          }}
          title={
            <header className="scannerOverlayHeading">
              <button
                type="button"
                className="button buttonText buttonText--inverted"
                onClick={() => {
                  setIsVisible(false);
                  onClose();
                }}
              >
                <Back />
                zurück
              </button>
            </header>
          }
        >
          <StocktakingScannerView stocktakingId={stocktakingId} />
        </Modal>
      )}
    </>
  );
};

export default StocktakingScanner;
