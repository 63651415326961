import { useCallback, useRef } from "react";

import { debounce } from "debounce";
import { CancelTokenSource } from "axios";

import requestCatchHandler from "../api/requestCatchHandler";
import useUpdateCartItemQuantity from "./useUpdateCartItemQuantity";
import { delays } from "../appConfig";

interface Props {
  initialQuantity?: number;
  deliveryDate: string;
  cartId: string;
  sku: string;
  setIsLoading?: () => void;
  resolve?: (response: any) => any;
  reject?: (error: any) => any;
  cancelTokenSource: CancelTokenSource;
}

/**
 * hook to update item quantity at api and handle api response
 * @param {number} initialQuantity
 * @param {string} deliveryDate
 * @param {string} cartId
 * @param {string} sku
 * @param {Function} setIsLoading
 * @param {Function} resolve
 * @param {Function} reject
 * @param {CancelTokenSource} cancelTokenSource
 */
const useDebounceUpdateItemQuantity = ({
  initialQuantity = 0,
  deliveryDate,
  cartId,
  sku,
  setIsLoading = () => true,
  resolve = (args) => Promise.resolve(args),
  reject = (error) => requestCatchHandler(error),
  cancelTokenSource,
}: Props) => {
  const debounceTime = delays.updateCartItemQuantity;
  const prevValue = useRef(initialQuantity);
  const updateCartItemQuantity = useUpdateCartItemQuantity();

  // memoize debounced functions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateItemAmount = useCallback(
    debounce((value: number) => {
      setIsLoading();

      updateCartItemQuantity({
        deliveryDate,
        cartId,
        sku,
        quantity: value,
        previousQuantity: prevValue.current,
        cancelTokenSource,
      })
        .then((response: any) => {
          // store value to use for next update as before value
          prevValue.current = value;

          return resolve(response);
        })
        .catch((error) => reject(error));
    }, debounceTime),
    [updateCartItemQuantity]
  );

  return (value: number) => {
    updateItemAmount(value);
  };
};

export default useDebounceUpdateItemQuantity;
