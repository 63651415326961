import React, { useEffect, useState } from "react";
import { Layout, Row, Col } from "antd";
import handleError from "../../utils/handleError";
import PublicPageLayoutWrapper from "../pages/PublicPageLayoutWrapper";
import getImprint from "../../api/contentful/getImprint";
import renderContentfulDocument from "../../utils/renderContentfulDocument";
import { pageTitles, pageTitleSuffix } from "../../appConfig";
import TrackingHelmet from "../Matomo/TrackingHelmet";

function Imprint() {
  const [contentfulData, setContentfulData] = useState<any>({});

  useEffect(() => {
    getImprint()
      .then((tacTextContent: any) => {
        if (tacTextContent?.fields) {
          setContentfulData({ ...tacTextContent.fields });
        }

        return tacTextContent;
      })
      .catch(handleError);
  }, []);

  return (
    <>
      <TrackingHelmet title={pageTitles.imprint} suffix={pageTitleSuffix} />

      <PublicPageLayoutWrapper>
        <Layout className="container-layout--inner">
          <Row>
            <Col xs={12} lg={{ span: 8, offset: 2 }}>
              {contentfulData?.content &&
                renderContentfulDocument(contentfulData.content)}
            </Col>
          </Row>
        </Layout>
      </PublicPageLayoutWrapper>
    </>
  );
}

export default Imprint;
