import objectHasKey from "./objectHasKey";
import { ProductData } from "../types/productData";

const getDefaultImageSet = (imageSets: ProductData["imageSets"]) =>
  imageSets.find(
    (imageEntry: any) =>
      objectHasKey(imageEntry, "images") && imageEntry.name === "default"
  );

export default getDefaultImageSet;
