import React, { useMemo, useState } from "react";

import clsx from "clsx";
import axios from "axios";
import { ConfigProvider, Empty, Table } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import getStocktakingItems from "../../../../api/stocktaking/getStocktakingItems";
import getCancelTokenSource from "../../../../api/getCancelTokenSource";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import { columns, subColumns } from "./columns";
import { itemsPerPage } from "../../../../appConfig";
import {
  GetStocktakingItemsAttributesResponse,
  StocktakingData,
  StocktakingItemData,
} from "../../../../types/stocktaking";

interface StocktakingsTableProps {
  stocktakings: StocktakingData[];
  total: number;
  page: number;
  isLoading: boolean;
  className?: string;
  emptyDescription?: JSX.Element;
  onTableChange?: (pagination: any, filters: any, sorter: any) => void;
}

const StocktakingsTable: React.FC<StocktakingsTableProps> = (
  props: StocktakingsTableProps
) => {
  const {
    stocktakings,
    total,
    page,
    isLoading,
    className,
    emptyDescription,
    onTableChange,
  } = props;

  const [subTableLoadingStates, setSubTableLoadingStates] = useState<string[]>(
    []
  );
  const [subTableDataSources, setSubTableDataSources] = useState<{
    [key: number]: StocktakingItemData[];
  }>({});

  const tableDataSource = useMemo(
    () =>
      stocktakings.map((stocktakingData: StocktakingData) => ({
        ...stocktakingData,
        key: stocktakingData.idStocktaking,
      })),
    [stocktakings]
  );

  const renderEmpty = () => (
    <Empty
      className="mb-3xl"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={emptyDescription}
    />
  );

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <Table
        className={clsx("productItemTable", "stocktaking-table", className)}
        sticky
        showSorterTooltip={false}
        columns={columns}
        dataSource={tableDataSource}
        scroll={{ x: "max-content" }}
        tableLayout="fixed"
        pagination={{
          position: ["bottomCenter"],
          total,
          pageSize: itemsPerPage.stocktakings,
          current: page,
          showSizeChanger: false,
          showLessItems: true,
        }}
        onChange={onTableChange}
        loading={isLoading}
        expandable={{
          rowExpandable: (record: any) => record?.totalItems > 0,
          expandedRowRender: (record: any) => {
            return (
              <Table
                loading={subTableLoadingStates.includes(record?.idStocktaking)}
                className="stocktaking-table__sub-table"
                scroll={{ x: "max-content" }}
                tableLayout="fixed"
                columns={subColumns}
                dataSource={
                  subTableDataSources?.[record?.idStocktaking] &&
                  subTableDataSources?.[record?.idStocktaking].map(
                    (stocktakingItemData: StocktakingItemData, i: number) => ({
                      ...stocktakingItemData,
                      // We need a unique key on DOM elements
                      key: `${record?.idStocktaking}_${stocktakingItemData?.idStocktakingItem}_${i}`,
                    })
                  )
                }
                pagination={false}
                size="small"
              />
            );
          },
          expandIcon: ({ expanded, onExpand, record }) => {
            if (!record?.totalItems) {
              return null;
            }
            return expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            );
          },
          onExpand: (expanded: boolean, record: any) => {
            if (expanded) {
              const cancelTokenSource = getCancelTokenSource();

              setSubTableLoadingStates((prevState: string[]) => [
                ...prevState,
                record?.idStocktaking,
              ]);

              getStocktakingItems({
                stocktakingId: record.idStocktaking,
                cancelTokenSource,
              })
                .then((response: GetStocktakingItemsAttributesResponse) => {
                  setSubTableDataSources((prevState) => {
                    const foo: { [key: number]: any } = {};
                    foo[record.idStocktaking] = response.stocktakingItems;
                    return {
                      ...prevState,
                      ...foo,
                    };
                  });

                  setSubTableLoadingStates((prevState: string[]) =>
                    prevState.filter((id) => id !== record.idStocktaking)
                  );
                })
                .catch((error) => {
                  if (!axios.isCancel(error)) {
                    setSubTableLoadingStates((prevState: string[]) =>
                      prevState.filter((id) => id !== record.idStocktaking)
                    );
                  }
                  requestCatchHandler(error);
                });
            }

            if (!expanded) {
              setSubTableDataSources((prevState) => {
                const newState = prevState;
                delete newState[record.idStocktaking];
                return newState;
              });
            }
          },
        }}
      />
    </ConfigProvider>
  );
};

export default StocktakingsTable;
