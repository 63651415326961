import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Layout, Col, Row, Spin } from "antd";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Entry } from "contentful";
import { ReactComponent as Download } from "../../static/svg/download.svg";
import ContentCard from "../ContentCard";
import NewsGallery from "./NewsGallery";
import NewsContentBlock from "../NewsContentBlock/NewsContentBlock";
import NewsBanner from "../NewsBanner/NewsBanner";
import DownloadModal from "./DownloadModal";
import { getProductsEndpoint } from "../../api/endpoints";
import axiosWithAuth from "../../api/axiosWithAuth";
import handleError from "../../utils/handleError";
import ProductTileWithModal from "../product/ProductTile/ProductTileWithModal";
import HrDivider from "../divider/Divider";
import formatDashedDateToLocale from "../../utils/formatDate";
import useGlobalNetworkState from "../../hooks/useGlobalNetworkState";
import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import requestCatchHandler from "../../api/requestCatchHandler";
import { routePathNames } from "../../appConfig";
import getContentfulAsset from "../../api/contentful/getContentfulAsset";
import getContentfulEntry from "../../api/contentful/getContentfulEntry";
import { ReactComponent as Share } from "../../static/svg/share.svg";
import MailToLink from "../MailToLink/MailToLink";
import getCatalogSearchAttributesData from "../../api/products/getCatalogSearchAttributesData";
import { NewsGalleryEntry } from "../../types/newsGalleryEntry";
import BackButton from "../backButton/BackButton";
import renderContentfulDocument from "../../utils/renderContentfulDocument";

/**
 *
 * @constructor
 */
function ArticlePage() {
  const { articleId } = useParams<{ articleId: string }>();
  const [isDownloadsOpened, setDownload] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [downloadAllUrl, setDownloadAllUrl] = useState("");
  const [galleryItems, setGalleryItems] = useState([]);
  const [ArticleElements, setArticleElements] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productsLoading, setProductsLoading] = useGlobalNetworkState("get");
  const [productsCategoryLink, setProductsCategoryLink] = useState();
  const [mailHeader, setMailHeader] = useState("");
  const navigate = useNavigate();
  const cartDate = useSelector(getCartDeliveryDateSlice);
  const changeDownload = (isOpened: boolean) => {
    setDownload(isOpened);
  };
  useEffect(() => {
    getContentfulEntry(articleId).then((articleResponse: Entry<any>) => {
      if (articleResponse?.fields?.downloads) {
        setDownloadData(articleResponse.fields.downloads);
      }

      if (articleResponse?.fields?.download_all) {
        setDownloadAllUrl(
          articleResponse.fields.download_all?.fields?.file?.url
        );
      }

      const Articles = [articleResponse].map((element: any) => {
        /* eslint-disable @typescript-eslint/naming-convention */
        const {
          title,
          publish_date,
          intro_text,
          content_block,
          content,
          product_sku,
          subtitle,
        } = element.fields;

        setMailHeader(title);

        if (product_sku) {
          let queryString = product_sku || "";

          if (Array.isArray(product_sku)) {
            queryString = product_sku.join("+");
          }

          setProductsLoading(true);

          axiosWithAuth()
            .get(
              getProductsEndpoint({
                categoryKey: "1",
                deliveryDates: cartDate,
                query: queryString,
              })
            )
            .then((response) => {
              const { concreteProducts } = getCatalogSearchAttributesData(
                response?.data?.data
              );

              if (concreteProducts) {
                setProductsCategoryLink(articleResponse?.fields?.shop_link);
                setProductData(concreteProducts);
              }

              setProductsLoading(false);
            })
            .catch((err3) => {
              if (err3.response?.status === 401) {
                // console.error("Token invalid or expired, please login again!");
                requestCatchHandler(err3);
              }
              setProductsLoading(false);
            });
        }

        const contentBlocks: any[] = [];

        content_block?.forEach((curr: any) => {
          let galleryItemsArray: NewsGalleryEntry[] = [];

          if (curr?.fields?.gallery_items) {
            curr.fields.gallery_items.forEach((el: Entry<any>) => {
              getContentfulEntry(el.sys.id)
                .then((contentfulGalleryEntry: any) => {
                  if (contentfulGalleryEntry.fields.youtube_link) {
                    galleryItemsArray = [
                      ...galleryItemsArray,
                      {
                        type: "video",
                        url: contentfulGalleryEntry.fields.youtube_link,
                        subtitle: contentfulGalleryEntry.fields.caption,
                      },
                    ];

                    setGalleryItems(galleryItemsArray);
                  } else if (contentfulGalleryEntry?.fields?.image?.sys?.id) {
                    getContentfulAsset(
                      contentfulGalleryEntry?.fields?.image?.sys?.id
                    )
                      .then((contentfulImageAssetResponse: any) => {
                        galleryItemsArray = [
                          ...galleryItemsArray,
                          {
                            type: "image",
                            url: contentfulImageAssetResponse?.fields?.file
                              ?.url,
                            subtitle: contentfulGalleryEntry?.fields?.caption,
                          },
                        ];

                        setGalleryItems(galleryItemsArray);
                      })
                      .catch(handleError);
                  }

                  return contentfulGalleryEntry;
                })
                .catch(handleError);
            });
          } else {
            contentBlocks.push(curr);
          }
        });

        const showHeadingInfoBox = (subtitle && content) || product_sku;
        const formattedDate = formatDashedDateToLocale(publish_date);

        return (
          <Fragment key={title}>
            <header className="article-head">
              <Row
                gutter={[
                  { xs: 8, sm: 16, md: 32, lg: 32 },
                  { xs: 8, sm: 16, md: 32, lg: 32 },
                ]}
              >
                <Col span={12} order={1}>
                  <h1 className="container-news__title">{title}</h1>
                </Col>

                <Col xs={12} md={4} order={showHeadingInfoBox ? 2 : 3}>
                  {showHeadingInfoBox ? (
                    <div className="container-news__card">
                      <ContentCard
                        title={subtitle || "Veröffentlicht:"}
                        publishDate={formattedDate}
                        data={null}
                      >
                        <span className="card-content">
                          {renderContentfulDocument(content)}
                        </span>

                        {productsCategoryLink && (
                          <a
                            href="#scroll-products"
                            className="info-card--button inline-block"
                          >
                            <button
                              type="button"
                              className="button buttonPrimary buttonPrimary--inverted"
                            >
                              Zu den Produkten
                            </button>
                          </a>
                        )}
                      </ContentCard>
                    </div>
                  ) : (
                    <p className="text-medium publish-date-no-card hidden-sm-down">
                      {formattedDate}
                    </p>
                  )}
                </Col>

                <Col xs={12} md={8} order={showHeadingInfoBox ? 3 : 2}>
                  <div className="container-news__content">
                    <h1 className="container-news__title--desktop">{title}</h1>

                    {!showHeadingInfoBox && (
                      <p className="hidden-md-up text-medium publish-date-no-card">
                        {formattedDate}
                      </p>
                    )}

                    {renderContentfulDocument(intro_text)}
                  </div>
                </Col>
              </Row>
            </header>

            <HrDivider size={2} spacingTop="l" spacingBottom="l" />

            {contentBlocks.map((block: Entry<any>, index) => {
              if (block.sys.contentType.sys.id === "news-content-block") {
                return (
                  <NewsContentBlock
                    key={block.sys.id}
                    alignment={index % 2 === 0 ? "left" : "right"}
                    fields={block.fields}
                    textBackground={!index}
                  />
                );
              }

              if (block.sys.contentType.sys.id === "news-banner") {
                return <NewsBanner key={block.sys.id} banner={block?.fields} />;
              }

              return null;
            })}
          </Fragment>
        );
      });

      setArticleElements(Articles);
    });
  }, [articleId, navigate, cartDate, productsCategoryLink, setProductsLoading]);

  return (
    <>
      <Layout className="container-layout container-layout--inner">
        <Row>
          <Col span={12}>
            <nav className="container-navigation">
              <div className="container-navigation__back">
                <BackButton to={routePathNames.news} />
              </div>

              <div className="container-navigation__controls">
                {!!(downloadData.length || downloadAllUrl) && (
                  <button
                    type="button"
                    className="button buttonText buttonTextDecoration--inverted buttonWithIcon"
                    onClick={() => changeDownload(true)}
                  >
                    <Download
                      width="22"
                      height="22"
                      className="icon iconDownload"
                    />
                    Alle Downloads
                  </button>
                )}

                <MailToLink
                  className="button buttonText buttonTextDecoration--inverted buttonWithIcon"
                  subject={`Weiling Nachrichten: ${mailHeader}`}
                  body="Dieser Beitrag von Weiling wird dir weiterempfohlen: "
                  url={window.location.href}
                >
                  <Share className="icon" />
                  teilen
                </MailToLink>
              </div>
            </nav>

            {ArticleElements}
          </Col>
        </Row>

        {galleryItems.length > 0 ? (
          <Row>
            <Col span={12}>
              <div className="container-slider news">
                <NewsGallery media={galleryItems} />
              </div>
            </Col>
          </Row>
        ) : null}

        <DownloadModal
          downloads={downloadData}
          downloadAllUrl={downloadAllUrl}
          isVisible={isDownloadsOpened}
          onCancel={() => changeDownload(false)}
        />
      </Layout>

      {/* products */}
      {productData.length > 0 && (
        <Layout className="container-layout container-layout--inner">
          <Row>
            <Col span={12}>
              <HrDivider size={2} spacingTop="2xl" spacingBottom="2xl" />

              <div
                className="productList productListArticle"
                id="scroll-products"
              >
                <Row
                  className="article__productListHeader"
                  justify="space-between"
                  align="middle"
                >
                  <Col xs={7} sm={6} md={3}>
                    <h2>Produkte</h2>
                  </Col>
                  <Col xs={5} sm={6} md={6} xl={7}>
                    {productsCategoryLink && (
                      <a href={productsCategoryLink}>Zum Webshop</a>
                    )}
                  </Col>
                </Row>
                {productsLoading ? (
                  <Spin size="large" className="mt-2xl" />
                ) : (
                  productData.map((curr: any) => (
                    <ProductTileWithModal
                      key={curr.sku}
                      deliveryDate={cartDate}
                      productData={curr}
                    />
                  ))
                )}
              </div>
            </Col>
          </Row>
        </Layout>
      )}
    </>
  );
}

export default ArticlePage;

/* eslint-enable @typescript-eslint/naming-convention */
