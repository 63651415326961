import React from "react";
import { Link } from "react-router-dom";
import { Empty } from "antd";
import { locationSearchQueryParameter, routePathNames } from "../../appConfig";
import getDeliveryDateQuery from "../../api/getDeliveryDateQuery";
import useGetDeliveryDate from "../../hooks/useGetDeliveryDate";

interface Props {
  description: string;
  withRedirectButton?: boolean;
}

/**
 * Wrapper to harmonize Empty Component and possible ProductList Link
 * @param {string} description
 * @param {boolean} withRedirectButton
 * @constructor
 */
const EmptyListContent: React.FC<Props> = function EmptyListContent({
  description,
  withRedirectButton = false,
}: Props) {
  const [deliveryDate] = useGetDeliveryDate();
  const categoryId = "10000";
  const deliveryDateAppendix = getDeliveryDateQuery(deliveryDate, true);
  const pageParameter = `&${locationSearchQueryParameter.page}=1`;

  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description}>
      {withRedirectButton && (
        <Link
          className="button buttonPrimary"
          to={`${routePathNames.products}${routePathNames.category}${categoryId}${deliveryDateAppendix}${pageParameter}`}
        >
          Zur Produktliste
        </Link>
      )}
    </Empty>
  );
};

export default EmptyListContent;
