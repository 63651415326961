import React from "react";

import { Modal, ModalProps } from "antd";

import ProductDetail from "../../productDetail/ProductDetail";
import ProductDetailModalContext from "../../../contexts/ProductDetailModalContext";

interface ProductDetailModalProps extends ModalProps {
  sku: string;
  type: "scan";
}

const ProductDetailModal: React.FC<ProductDetailModalProps> = (props) => {
  const { sku } = props;

  return (
    <Modal
      {...props}
      width="95%"
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ className: "button buttonPrimary button--rounded" }}
      wrapClassName="product-detail-modal"
      okText="Schließen"
      destroyOnClose
    >
      <ProductDetailModalContext.Provider value>
        <ProductDetail sku={sku} />
      </ProductDetailModalContext.Provider>
    </Modal>
  );
};

export default ProductDetailModal;
