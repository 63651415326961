import React from "react";
import { Col, Row } from "antd";

interface Props {
  folders: Record<string, any>[];
  onClick: (node: Record<string, any>) => void;
}

function InfoCenterFolders({ folders, onClick }: Props) {
  if (!folders?.length) {
    return null;
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
      {folders.map((node: any) => {
        const { folderName, id } = node;

        return (
          <Col xs={12} sm={6} xl={4} key={`${id}_${folderName}`}>
            <button
              type="button"
              className="button buttonPrimary buttonFolder"
              onClick={() => onClick(node)}
            >
              {folderName}
            </button>
          </Col>
        );
      })}
    </Row>
  );
}

export default InfoCenterFolders;
