import React, { useState } from "react";
import { message, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Delete } from "../../static/svg/delete.svg";
import { CompanyUserProfile } from "../../types/companyUserProfile";
import { routePathNames } from "../../appConfig";
import deleteCompanyUser from "../../api/userProfile/deleteCompanyUser";

interface Props {
  userData: CompanyUserProfile;
}

/**
 * user delete component with encapsulated logics
 * @param userData {object}
 */
function DeleteUser({ userData }: Props) {
  const navigate = useNavigate();

  const { firstName, lastName } = userData;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const deleteUser = () => {
    setIsLoading(true);
    deleteCompanyUser({ ...userData })
      .then(() => {
        setIsLoading(false);

        navigate(routePathNames.userProfilesAdministration);
        message.success({
          content: "Die Benutzerdaten wurden erfolgreich gelöscht",
          duration: 3,
        });
        setIsModalVisible(false);
      })
      .catch(() => {
        message.error({
          content:
            "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal",
          duration: 3,
        });
        setIsModalVisible(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <button
        type="button"
        className="button buttonText buttonWithIcon"
        onClick={showModal}
      >
        <Delete />
        Nutzer löschen
      </button>

      <Modal
        title="ACHTUNG!"
        visible={isModalVisible}
        closable
        maskClosable={false}
        confirmLoading={isLoading}
        onOk={deleteUser}
        okText={
          isLoading ? (
            <>
              <Spin size="small" /> Benutzer löschen
            </>
          ) : (
            "Benutzer löschen"
          )
        }
        onCancel={handleCancel}
        cancelText="Abbrechen"
      >
        <p>
          Soll der Benutzer{" "}
          <strong>
            {firstName} {lastName}
          </strong>{" "}
          wirklich gelöscht werden?
        </p>
      </Modal>
    </>
  );
}

export default DeleteUser;
