import React from "react";
import { SuggestItem } from "../../types/suggestItem";
import ProductSuggestionHeader from "./ProductSuggestionHeader";
import CompletionSuggestion from "./CompletionSuggestion";
import BrandSuggestion from "./BrandSuggestion";
import ProductGroupSuggestion from "./ProductGroupSuggestion";

interface CreateSuggestions {
  suggestions: any[] | any;
  deliveryDate: string;
  query: string;
  checkIfAlreadyOnList?: (sku: string) => boolean;
  isAlreadyOnListWarning?: string;
  type?: SuggestItem;
  context?: "productlist" | "weekplanner";
  showBrand?: boolean;
  showProductGroup?: boolean;
  showCompletion?: boolean;
  updateComponentStates?: (query: string) => void;
}

const SuggestionContainer: React.FC<CreateSuggestions> = ({
  suggestions,
  checkIfAlreadyOnList,
  deliveryDate,
  isAlreadyOnListWarning,
  query,
  type,
  context,
  showBrand,
  showCompletion,
  showProductGroup,
  updateComponentStates,
}: CreateSuggestions) => {
  let foundProducts: Record<string, any>[] = [];
  let brand: Record<string, any> = [];
  let productGroup: Record<string, any> = [];
  let completion: Record<string, any> = [];

  if (type === "priceTagList") {
    foundProducts = suggestions;
  } else {
    foundProducts = suggestions ? suggestions.products : [];
    brand = suggestions ? suggestions.brand : null;
    productGroup = suggestions ? suggestions.productGroup : null;
    completion = suggestions ? suggestions.completion : null;
  }
  return (
    <>
      {foundProducts.length > 0 && (
        <ProductSuggestionHeader
          deliveryDate={deliveryDate}
          checkIfAlreadyOnList={checkIfAlreadyOnList}
          foundProducts={foundProducts}
          isAlreadyOnListWarning={isAlreadyOnListWarning}
          type={type}
        />
      )}
      {brand.length > 0 && showBrand && (
        <BrandSuggestion query={query} context={context} brand={brand} />
      )}
      {productGroup.length > 0 && showProductGroup && (
        <ProductGroupSuggestion
          query={query}
          context={context}
          productGroup={productGroup}
        />
      )}
      {completion.length > 0 && showCompletion && (
        <CompletionSuggestion
          completion={completion}
          context={context}
          updateComponentStates={updateComponentStates}
        />
      )}
    </>
  );
};

export default SuggestionContainer;
