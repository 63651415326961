import React from "react";
import { useSelector } from "react-redux";
import { ProductData } from "../../types/productData";
import { RootState } from "../../types/rootState";

const ALLERGY_NAME_PREFIX = "Allergen";
const GUARANTEED_TYPE = "Ja";
const PARTIALLY_TYPE = "Kann";

interface Props {
  attributes: ProductData["attributes"];
}

const ProductAllergyList: React.FC<Props> = ({ attributes }: Props) => {
  const { attributeNames } = useSelector(
    (state: RootState) => state.productsMetaData
  );

  const getIncludedIngredientsByType = (prefix: string) => {
    return Object.keys(attributes)
      .filter((currentAttribute: any) =>
        attributes?.[currentAttribute].startsWith(prefix)
      )
      .map(
        (currentAttribute: any) =>
          `${attributeNames?.[currentAttribute].replace(
            `${ALLERGY_NAME_PREFIX}`,
            ""
          )}`
      )
      .join(", ");
  };

  const guaranteedIncludedIngredients =
    getIncludedIngredientsByType(GUARANTEED_TYPE);

  const partiallyIncludedIngredients =
    getIncludedIngredientsByType(PARTIALLY_TYPE);

  return (
    <ul className="productInfoList allergyList flex">
      {!!guaranteedIncludedIngredients.length && (
        <li className="productInfoListItem allergyItem">
          <span className="text-bold text-base">
            Ja (laut Rezeptur enthalten)
          </span>
          <span className="text-base boxSize">
            {guaranteedIncludedIngredients}
          </span>
          {!!partiallyIncludedIngredients.length && (
            <div className="breakLine mt-s" />
          )}
        </li>
      )}

      {!!partiallyIncludedIngredients.length && (
        <li className="productInfoListItem allergyItem">
          <span className="text-bold text-base">
            Kann in Spuren enthalten sein
          </span>
          <span className="text-base boxSize">
            {partiallyIncludedIngredients}
          </span>
        </li>
      )}
    </ul>
  );
};

export default ProductAllergyList;
