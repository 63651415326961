import { parsePriceValueToLocalPrice } from "../../../utils/localizedNumberFormat";
import formatDashedDateToLocale from "../../../utils/formatDate";

interface MlwGroupData {
  deficit: number;
  threshold: number;
  fee: number;
  sumPriceTotal: number;
  deliveryDate: string;
  label: string;
  isWildcard: boolean;
}

/**
 * create a single message string
 * @param deficit {number}
 * @param threshold {number}
 * @param fee {number}
 * @param sumPriceTotal {number}
 * @param deliveryDate {string}
 * @param label {string}
 * @param isWildcard {boolean}
 * @param currency {string}
 */
const createSingleOrderLimitMessage = (
  {
    deficit,
    threshold,
    fee,
    sumPriceTotal,
    deliveryDate,
    label,
    isWildcard,
  }: MlwGroupData,
  currency: string
) => {
  const deliveryDateFormatted = formatDashedDateToLocale(deliveryDate);
  const thresholdWithUnit = parsePriceValueToLocalPrice(threshold, currency);
  const deficitWithUnit = parsePriceValueToLocalPrice(deficit, currency);
  const sumPriceTotalWithUnit = parsePriceValueToLocalPrice(sumPriceTotal);
  if (isWildcard) {
    // if a fee is greater than 0 it is a delivery fee based message
    if (fee) {
      return `Euer derzeitiger Lieferwert zum ${deliveryDateFormatted} liegt bei ${sumPriceTotalWithUnit}. Ab ${thresholdWithUnit} liefern wir frachtfrei, dazu fehlen noch ${deficitWithUnit}.`;
    }

    // total limit for order
    return `Zum Liefertag ${deliveryDateFormatted} habt Ihr den aktuellen Mindestlieferwert von ${thresholdWithUnit} noch nicht erreicht. Euer derzeitiger Lieferwert liegt bei ${sumPriceTotalWithUnit} Euro. Bitte fügt weitere Waren im Wert von ${deficitWithUnit} hinzu.`;
  }

  // limit message for a concrete product group
  return `Zum Liefertag ${deliveryDateFormatted} ist der Mindestlieferwert der Warengruppe ${label} in Höhe von ${thresholdWithUnit} noch nicht erreicht. Bitte fügt weitere Waren im Wert von ${deficitWithUnit} aus dieser Warengruppe hinzu.`;
};

/**
 * create an array of messages to display in components
 * @param mlwGroupDeficits {MlwGroupData[]}
 * @param currency {string}
 */
const createMinimumOrderLimitMessages = (
  mlwGroupDeficits: MlwGroupData[],
  currency = "EUR"
) => {
  if (!Array.isArray(mlwGroupDeficits)) {
    return [];
  }

  return (
    mlwGroupDeficits
      // simple math operation due to parsing of false = 0 and true = 1, to have the overall mlw first
      .sort((a, b) => {
        return Number(b.isWildcard) - Number(a.isWildcard);
      })
      .map((groupDataSet) =>
        createSingleOrderLimitMessage(groupDataSet, currency)
      )
  );
};

export default createMinimumOrderLimitMessages;
