import { contentfulContentTypes } from "../../appConfig";
import getSingleContentTypeEntry from "./getSingleContentTypeEntry";

/**
 * retrieve terms and conditions from contentful
 */
const getTermsAndConditions = () =>
  getSingleContentTypeEntry(contentfulContentTypes.termsAndConditions);

export default getTermsAndConditions;
