import React from "react";
import clsx from "clsx";
import { productAttributes } from "../../../api/productAttributes";
import getDefaultImageUrlSet from "../../../utils/getDefaultImageUrlSet";
import {
  ProductImage,
  ProductTitle,
  ProductInsignias,
  ProductAttributes,
  ProductPackageInfo,
} from "../../product";
import Price from "../../price/Price";
import CartItemQuantity from "./CartItemQuantity";
import { ProductData } from "../../../types/productData";
import { CartItemColumns } from "../../../types/cartItemColumns";
import ProductInfoVolumePricesPopup from "../../product/ProductInfoVolumePricesPopup";
import getDeliveryDateBasedAttributes from "../../product/getDeliveryDateBasedAttributes";
import HrDivider from "../../divider/Divider";
import getProductUVP from "../../../utils/getProductUVP";
import ProductAddToFavouritesListButton from "../../product/ProductAddToFavouritesListButton";
import ProductAddToPriceTagListButton from "../../product/ProductAddToPriceTagListButton";
import CartMoveOrDeleteItemButtonCombination from "./CartMoveOrDeleteItemButtonCombination";

/**
 * create single row for CartItemsList
 * @param cartItem {ProductData}
 * @param deliveryDate {string}
 * @param stockName {string}
 * @param dayShort {string}
 * @param isSmallScreen {boolean}
 * @constructor
 * @returns {CartItemColumns}
 */
const CartItemTableChildRow = (
  cartItem: ProductData,
  deliveryDate: string,
  stockName: string,
  dayShort: string,
  isSmallScreen = false
): CartItemColumns => {
  const {
    name,
    sku,
    attributes,
    availabilities,
    quantity,
    imageSets,
    calculations: { unitPrice, sumPrice },
    prices,
    sortIndex,
  } = cartItem;
  const { volumePrices, strikePrice, isUnavailable, rrPrice, price } =
    getDeliveryDateBasedAttributes({
      deliveryDate,
      prices,
    });

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.quality]: qualitySign,
    [productAttributes.bioIcon]: bioladenLogo,
    [productAttributes.bioFairIcon]: bioFairLogo,
    [productAttributes.qualityText]: qualityText,
    [productAttributes.basePriceFactor]: basePriceFactor,
    [productAttributes.basePriceUnit]: basePriceUnit,
    [productAttributes.weighingArticle]: weighingArticle,
  } = attributes;

  const getOrderNoteByStockAndDay = (shortDay: string, stock: string) => {
    const orderNoteEnding = () => {
      switch (shortDay) {
        case "Mo":
          return `montag_${stock}`;
        case "Di":
          return `dienstag_${stock}`;
        case "Mi":
          return `mittwoch_${stock}`;
        case "Do":
          return `donnerstag_${stock}`;
        case "Fr":
          return `freitag_${stock}`;
        case "Sa":
          return `samstag_${stock}`;
        case "So":
          return `sonntag_${stock}`;
        default:
          return "";
      }
    };
    return attributes?.[`artikel_bestellhinweis_${orderNoteEnding()}`] || "";
  };

  const orderNote = getOrderNoteByStockAndDay(dayShort, stockName);

  const icons = String(qualitySign).split(" ");

  const productUVP = getProductUVP(
    rrPrice,
    productUnitQuantity,
    weighingArticle === "1"
  );

  /*
   * order of object keys:
   * internal identifier
   * > key
   * > sku
   * > sortIndex
   * order of the rest follows the visual representation
   */
  return {
    key: sku, // react identifier
    sku,
    sortIndex,
    articleElement: isSmallScreen ? (
      <div className="itemInfo--small" id={`cartItem-${sku}`}>
        <ProductImage
          imageSet={getDefaultImageUrlSet(imageSets)}
          className="product-img"
          sku={sku}
        />

        <ProductTitle
          attributes={attributes}
          availabilities={availabilities}
          deliveryDate={deliveryDate}
          sku={sku}
          title={name}
          titleClassName={clsx({ isUnavailable })}
        />

        <HrDivider className="divider-top" spacingTop="s" />

        <ProductInsignias
          width={{ xs: 12 }}
          icons={icons}
          bioladenLogo={bioladenLogo}
          bioladenFairLogo={bioFairLogo}
          qualityText={qualityText}
        />

        <ProductAttributes
          attributes={attributes}
          deliveryDate={deliveryDate}
          sku={sku}
        />

        <HrDivider
          className="divider-bottom"
          spacingTop="s"
          spacingBottom="s"
        />

        <div className="single-price">
          <span>
            <Price price={unitPrice} tag="span" className="text-bold" />
            <span className="text-s">&nbsp;/VPE</span>
          </span>

          <span className="text-xs">
            (
            <Price
              price={
                weighingArticle === "1"
                  ? price
                  : unitPrice / parseFloat(productUnitQuantity)
              }
              tag="span"
              className="text-xs"
            />{" "}
            / {productUnitMeasurement})
          </span>
        </div>

        <div className="vpe">
          <ProductPackageInfo
            productUnitMeasurement={productUnitMeasurement}
            productUnitQuantity={productUnitQuantity}
            basePriceFactor={basePriceFactor}
            basePriceUnit={basePriceUnit}
            deliveryDate={deliveryDate}
            prices={prices}
            weighingArticle={weighingArticle}
            sku={sku}
            className="cart-package-info"
          />
        </div>

        <div className="quantity">
          <CartItemQuantity sku={sku} quantity={quantity} />
        </div>

        <div className="actions">
          <span className="favouriteListPriceTagListCombination">
            <ProductAddToFavouritesListButton sku={sku} />
            <ProductAddToPriceTagListButton sku={sku} />
          </span>
          <span className="cartMoveOrDeleteItemButtonCombination">
            <CartMoveOrDeleteItemButtonCombination sku={sku} />
          </span>
        </div>

        <div className="recommended-price">
          {!!productUVP && (
            <span className="no-Wrap">
              <span>UVP:</span>{" "}
              <Price price={productUVP} tag="span" className="text-bold" />
              <span className="text-s">&nbsp; / {productUnitMeasurement}</span>
            </span>
          )}
        </div>

        {orderNote && orderNote?.length > 0 && (
          <span className="productOrderNotes">{orderNote}</span>
        )}
      </div>
    ) : (
      <div className="itemInfo" id={`cartItem-${sku}`}>
        <ProductImage
          imageSet={getDefaultImageUrlSet(imageSets)}
          className="product-img"
          sku={sku}
        />

        <ProductTitle
          attributes={attributes}
          availabilities={availabilities}
          deliveryDate={deliveryDate}
          sku={sku}
          title={name}
        />

        <ProductAttributes
          attributes={attributes}
          deliveryDate={deliveryDate}
          sku={sku}
        />

        <ProductInsignias
          icons={icons}
          bioladenLogo={bioladenLogo}
          bioladenFairLogo={bioFairLogo}
          orientation="start"
          qualityText={qualityText}
        />

        {orderNote && orderNote?.length > 0 && (
          <span className="productOrderNotes">{orderNote}</span>
        )}
      </div>
    ),
    addToListButtons: (
      <div className="addToListsButtons">
        <ProductAddToFavouritesListButton sku={sku} />
        <ProductAddToPriceTagListButton sku={sku} />
      </div>
    ),
    quantity: <CartItemQuantity sku={sku} quantity={quantity} />,
    packaging: (
      <div className="vpe">
        <ProductPackageInfo
          productUnitMeasurement={productUnitMeasurement}
          productUnitQuantity={productUnitQuantity}
          basePriceFactor={basePriceFactor}
          basePriceUnit={basePriceUnit}
          deliveryDate={deliveryDate}
          prices={prices}
          weighingArticle={weighingArticle}
          sku={sku}
          className="cart-package-info"
        />
      </div>
    ),
    unitPrice: (
      <div className="singlePrice">
        <span className="no-Wrap">
          <Price price={unitPrice} tag="span" className="text-bold" />
          <span className="text-s">&nbsp;/&nbsp;VPE</span>
        </span>

        <span className="no-Wrap text-s">
          (
          <Price
            price={
              weighingArticle === "1"
                ? price
                : unitPrice / parseFloat(productUnitQuantity)
            }
            tag="span"
            className="text-s"
          />{" "}
          / {productUnitMeasurement})
        </span>
        {volumePrices?.length > 0 && (
          <ProductInfoVolumePricesPopup
            volumePrices={volumePrices}
            basePriceQuantity={
              weighingArticle === "1" ? 1 : productUnitQuantity
            }
            className={clsx(
              "mt-s",
              strikePrice > 0 && "hasStrikePriceVolumePrices"
            )}
          />
        )}
      </div>
    ),
    rrp: (
      <div className="singlePrice">
        {!!productUVP && (
          <span className="no-Wrap">
            <Price price={productUVP} tag="span" className="text-bold" />
            <span className="text-s">&nbsp; / {productUnitMeasurement}</span>
          </span>
        )}
      </div>
    ),
    total: (
      <div className="sumPrice">
        <Price tag="span" className="text-bold totalPrice" price={sumPrice} />
      </div>
    ),
    interactionIcon: <CartMoveOrDeleteItemButtonCombination sku={sku} />,
  };
};

export default CartItemTableChildRow;
