import React from "react";
import { Link } from "react-router-dom";
import { Col, Layout, Row } from "antd";
import { ReactComponent as WeilingLogo } from "../../static/svg/weiling.svg";

function HeaderLogoOnly() {
  return (
    <div className="header__wrapper">
      <Layout className="container-layout container-layout--inner">
        <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
          <Col span={12} className="header__logo-container">
            <Link to="/">
              <WeilingLogo className="main-logo" />
            </Link>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}

export default HeaderLogoOnly;
