import React, { BaseSyntheticEvent } from "react";

import clsx from "clsx";
import { Button, Col, Divider, Row, Tooltip } from "antd";
import { Entry } from "contentful";
import { useNavigate } from "react-router-dom";

import downloadContenfulFile from "../../../../utils/downloadContenfulFile";
import { getTargetFromAsset, getTooltipTextFromAsset } from "../utils";
import { ContentfulImpulsaktionEntry } from "../../../../types/contentfulImpulsaktion";
import { ReactComponent as PlanIcon } from "../../../../static/svg/plan.svg";
import { ReactComponent as FlyerIcon } from "../../../../static/svg/flyer.svg";
import { ReactComponent as VKFIcon } from "../../../../static/svg/vkf.svg";
import { ReactComponent as SocialMediaIcon } from "../../../../static/svg/social-media.svg";
import { ReactComponent as StoreIcon } from "../../../../static/svg/store.svg";
import { ReactComponent as DeliveryIcon } from "../../../../static/svg/delivery.svg";
import { ReactComponent as DownloadAltIcon } from "../../../../static/svg/download-alternative.svg";
import { ReactComponent as OrderIcon } from "../../../../static/svg/my-order.svg";

interface ImpulseIntroProps {
  impulsaktion: Entry<ContentfulImpulsaktionEntry>;
  className?: string;
}

type IntroButton = {
  name: string;
  icon: JSX.Element;
  className: string;
  title: string;
  type: "download" | "link";
};

const INTRO_BUTTONS: IntroButton[] = [
  {
    name: "plan_file",
    icon: <PlanIcon />,
    className: "buttonPrimary",
    title: "Aktionsplan & Medienübersicht",
    type: "download",
  },
  {
    name: "flyer_file",
    icon: <FlyerIcon />,
    className: "buttonPrimary",
    title: "Impulsflyer downloaden",
    type: "download",
  },
  {
    name: "vkf_file",
    icon: <VKFIcon />,
    className: "buttonPrimary",
    title: "Übersicht der VKF-Materialien",
    type: "download",
  },
  {
    name: "social_media_file",
    icon: <SocialMediaIcon />,
    className: "buttonPrimary",
    title: "Social-Media Material",
    type: "download",
  },
  {
    name: "presentation_retail_file",
    icon: <StoreIcon />,
    className: "buttonGrey",
    title: "Präsentationen für Ladner",
    type: "download",
  },
  {
    name: "presentation_delivery_service_file",
    icon: <DeliveryIcon />,
    className: "buttonGrey",
    title: "Präsentationen für Lieferdienste",
    type: "download",
  },
  {
    name: "bnn_data_delivery_service_file",
    icon: <DownloadAltIcon />,
    className: "buttonGrey",
    title: "BNN & Datenpaket für Lieferdienste",
    type: "download",
  },
  {
    name: "product_link",
    icon: <OrderIcon />,
    className: "buttonSecondary",
    title: "product_link_text",
    type: "link",
  },
];

const DEFAULT_LINK_TEXT = "Zu den Produkten";

const ImpulseIntro: React.FC<ImpulseIntroProps> = (
  props: ImpulseIntroProps
) => {
  const { impulsaktion, className } = props;

  const navigate = useNavigate();

  if (!impulsaktion) {
    return null;
  }

  return (
    <div className={clsx("impulse-intro", className)}>
      <h2>{impulsaktion?.fields?.name}</h2>
      <Divider />
      <Row gutter={[40, 30]}>
        {INTRO_BUTTONS.map((button: IntroButton) => {
          if (button.type === "link") {
            const {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              [button.name]: linkTarget,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              [button.title]: linkText = DEFAULT_LINK_TEXT,
            } = impulsaktion.fields || {};

            return (
              <Col xs={12} md={6} lg={3} key={`intro_button_${button.name}`}>
                <Button
                  className={clsx("button width-full", button.className)}
                  icon={button.icon}
                  onClick={() => navigate(linkTarget)}
                  disabled={!linkTarget}
                >
                  {linkText}
                </Button>
              </Col>
            );
          }

          const {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            [button.name]: asset,
          } = impulsaktion.fields || {};
          const isDisabled = !asset?.fields?.file?.url;

          return (
            <Col xs={12} md={6} lg={3} key={`intro_button_${button.name}`}>
              {!isDisabled ? (
                <Tooltip title={getTooltipTextFromAsset(asset)}>
                  <Button
                    className={clsx("button width-full", button.className)}
                    icon={button.icon}
                    disabled={isDisabled}
                    onClick={(e: BaseSyntheticEvent) =>
                      downloadContenfulFile(e, asset, getTargetFromAsset(asset))
                    }
                  >
                    {button.title}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  className={clsx("button width-full", button.className)}
                  icon={button.icon}
                  disabled={isDisabled}
                  onClick={(e: BaseSyntheticEvent) =>
                    downloadContenfulFile(e, asset, getTargetFromAsset(asset))
                  }
                >
                  {button.title}
                </Button>
              )}
            </Col>
          );
        })}
      </Row>
      <Divider className="mt-l" />
    </div>
  );
};

export default ImpulseIntro;
