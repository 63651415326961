import React from "react";
import clsx from "clsx";
import { ContentfulCategoryEntry } from "../../types/contentfulCategoryEntry";
import CategoriesIcon from "../categories/CategoriesIcon";

interface NavigationCategoryIcon {
  categoryEntry: ContentfulCategoryEntry;
}

const NavigationCategoryIconRound: React.FC<NavigationCategoryIcon> = ({
  categoryEntry,
}: NavigationCategoryIcon) => {
  const { title, mainCategory } = categoryEntry;

  // disable warning for dangerouslySetInnerHTML for this component
  /* eslint-disable react/no-danger */
  return (
    <>
      <CategoriesIcon
        className={clsx(
          "iconNavigationEntryIcon",
          !mainCategory && "iconNavigationEntryIconInverted"
        )}
        categoryEntry={categoryEntry}
      />

      <span
        className="iconNavigationEntryTitle"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </>
  );
  /* eslint-enable react/no-danger */
};

export default NavigationCategoryIconRound;
