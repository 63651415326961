import React from "react";

import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Menu, MenuProps } from "antd";
import * as Sentry from "@sentry/react";

import clearStore from "../../../state/actions/clearStore";
import { routePathNames } from "../../../appConfig";
import { ReactComponent as MyAccount } from "../../../static/svg/my-account.svg";
import { ReactComponent as MyOrder } from "../../../static/svg/my-order.svg";
import { ReactComponent as UserManagement } from "../../../static/svg/user-management.svg";
import { ReactComponent as Logout } from "../../../static/svg/logout.svg";
import { ReactComponent as Barcode } from "../../../static/svg/barcode.svg";
import { ReactComponent as Heart } from "../../../static/svg/bookmark.svg";
import { ReactComponent as Stocktaking } from "../../../static/svg/productlist.svg";
import { ReactComponent as Support } from "../../../static/svg/support.svg";
import { ReactComponent as Auftraege } from "../../../static/svg/auftraege_neutral.svg";
import { ReactComponent as Rechnungen } from "../../../static/svg/Rechnungen_NEUTRAL.svg";
import { ReactComponent as Konto } from "../../../static/svg/account.svg";
import { ReactComponent as Cart } from "../../../static/svg/cart.svg";

interface UserNavigationProps {
  onListItemClick?: () => void;
}

const UserNavigation: React.FC<UserNavigationProps> = (props) => {
  const { onListItemClick } = props;
  const { isSuperAdmin, id } = useSelector((state: any) => state.userData);
  const navigate = useNavigate();

  const logout = () => {
    Sentry.setUser(null);
    clearStore();
    navigate(routePathNames.login);
  };

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    if (!isSuperAdmin && key === "sub-2") {
      return null as MenuItem;
    }

    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem(
      "Mein Konto",
      "1",
      <Konto className="link-icon menu-Item-hover-focus-click" />,
      [
        getItem(
          <Link
            onClick={() => {
              onListItemClick();
            }}
            to={`${routePathNames.userProfileEdit}${id}`}
          >
            Meine Daten
          </Link>,
          "sub-1",
          <MyAccount className="link-icon menu-Item-hover-focus-click" />
        ),
        getItem(
          <Link
            onClick={onListItemClick}
            to={routePathNames.userProfilesAdministration}
          >
            Nutzerverwaltung
          </Link>,
          "sub-2",
          <UserManagement className="link-icon menu-Item-hover-focus-click" />
        ),
      ]
    ),
    getItem("Meine Bestellungen", "2", <MyOrder className="link-icon" />, [
      getItem(
        <Link onClick={onListItemClick} to={routePathNames.carts}>
          Offene Warenkörbe
        </Link>,
        "sub-3",
        <Cart className="link-icon" />
      ),
      getItem(
        <Link
          onClick={onListItemClick}
          to={`${routePathNames.orders}?documentType=SHOPORDER`}
        >
          Shopbestellungen
        </Link>,
        "sub-4",
        <MyOrder className="link-icon" />
      ),
      getItem(
        <Link
          onClick={onListItemClick}
          to={`${routePathNames.orders}?documentType=ORDER`}
        >
          Aufträge
        </Link>,
        "sub-5",
        <Auftraege className="link-icon" />
      ),
      getItem(
        <Link
          onClick={onListItemClick}
          to={`${routePathNames.orders}?documentType=INVOICE`}
        >
          Rechnungen
        </Link>,
        "sub-6",
        <Rechnungen className="link-icon" />
      ),
    ]),
    getItem("Listen", "3", <MyAccount className="link-icon" />, [
      getItem(
        <Link onClick={onListItemClick} to={routePathNames.favouriteList}>
          Favoriten
        </Link>,
        "sub-8",
        <Heart className="link-icon" />
      ),
      getItem(
        <Link onClick={onListItemClick} to={routePathNames.priceTagList}>
          Etiketten
        </Link>,
        "sub-9",
        <Barcode className="link-icon" />
      ),
    ]),
    getItem(
      <Link onClick={onListItemClick} to={routePathNames.stocktakings}>
        Inventur
      </Link>,
      "4",
      <Stocktaking className="link-icon" />
    ),
    getItem(
      <Link onClick={onListItemClick} to={routePathNames.support}>
        Hilfe & Support
      </Link>,
      "5",
      <Support className="link-icon" />
    ),
    getItem(
      <Button onClick={logout} className="logoutButton">
        <Logout className="button-icon" />
        Logout
      </Button>,
      "6"
    ),
  ];

  return (
    <div className="user-navigation">
      <Menu
        mode="inline"
        items={items}
        className="user-navigation-widescreen"
      />
    </div>
  );
};

export default UserNavigation;
