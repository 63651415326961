import { Col } from "antd";
import clsx from "clsx";
import React from "react";
import NavNode from "./NavNode";
import { ReactComponent as Arrow2 } from "../../static/svg/arrow.svg";
import { CategoryLevel } from "../../types/categoryLevel";
import { Category } from "../../types/category";

interface SubCategoriesProps {
  categoryRoute: string;
  createLinkTo: (pathname: string, categoryKey: string) => string;
  currentCategories: Category[];
  determineCategoryActiveStatus: (
    currentCategory: Category,
    level: CategoryLevel
  ) => boolean;
  handleMouseOnItem: (
    level?: CategoryLevel,
    currentCategory?: Category
  ) => void;
  level?: CategoryLevel;
}

const SubCategoriesColumn = function SubCategoriesColumn({
  categoryRoute,
  createLinkTo,
  currentCategories,
  determineCategoryActiveStatus,
  handleMouseOnItem,
  level,
}: SubCategoriesProps) {
  return (
    <Col span={3} className="subCategoriesColumn">
      <ul className="categoriesContent">
        {currentCategories?.length > 0 &&
          currentCategories.map((currentCategory) => (
            <li
              className="category category--submenu"
              key={currentCategory.categoryKey}
              role="menuitem"
              onMouseMove={() => handleMouseOnItem(level, currentCategory)}
            >
              <NavNode
                title={currentCategory.name}
                route={createLinkTo(categoryRoute, currentCategory.categoryKey)}
                subTitle={false}
                className={clsx(
                  "categoryTitle--hasFullHeight",
                  determineCategoryActiveStatus(currentCategory, level) &&
                    "categoryTitle--active"
                )}
              />

              <div className="categoriesArrowNextContainer">
                {currentCategory?.children?.length > 0 && (
                  <button
                    className={clsx(
                      "button",
                      "categoriesArrowNext",
                      determineCategoryActiveStatus(currentCategory, level) &&
                        "categoriesArrowNext--active"
                    )}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      handleMouseOnItem(level, currentCategory);
                    }}
                  >
                    <Arrow2 className="icon arrowNext" />
                  </button>
                )}
              </div>
            </li>
          ))}
      </ul>
    </Col>
  );
};

export default SubCategoriesColumn;
