import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import PublicPageLayoutWrapper from "../pages/PublicPageLayoutWrapper";

function Error404() {
  return (
    <PublicPageLayoutWrapper>
      <div className="error404">
        <h1 className="head-404">404</h1>
        <h5>
          Die Web-Adresse, die Sie eingegeben haben, gibt es auf unserer Website
          nicht.
        </h5>
        <Button className="return-button" size="large">
          <Link to="/">Zur Startseite</Link>
        </Button>
      </div>
    </PublicPageLayoutWrapper>
  );
}

export default Error404;
