export interface CartData {
  id: string;
  deliveryDate: string;
  deliveryDateItems: Record<string, any>[];
}

/**
 * create a set of data for weekplanner redux
 * @param currentCartData {Record<string, any>}
 * @return {Object<CartData>}
 */
const createWeekplannerCartDataSet = (
  currentCartData: Record<string, any>
): CartData => ({
  id: currentCartData.id,
  deliveryDate: currentCartData?.attributes?.deliveryDate,
  deliveryDateItems: currentCartData?.attributes?.deliveryDateItems || [],
});

export default createWeekplannerCartDataSet;
