import React, { BaseSyntheticEvent } from "react";

import clsx from "clsx";
import { Entry } from "contentful";
import { Button, Tooltip } from "antd";

import downloadContenfulFile from "../../../../utils/downloadContenfulFile";
import { getTargetFromAsset, getTooltipTextFromAsset } from "../utils";
import { ContentfulImpulsaktionEntry } from "../../../../types/contentfulImpulsaktion";
import { ReactComponent as DownloadAlt2Icon } from "../../../../static/svg/download-alternative-2.svg";

interface ImpulseDownloadProps {
  impulsaktion: Entry<ContentfulImpulsaktionEntry>;
  className?: string;
}

const ImpulseDownload: React.FC<ImpulseDownloadProps> = (
  props: ImpulseDownloadProps
) => {
  const { impulsaktion, className } = props;

  return (
    <div className={clsx("impulse-download", className)}>
      {impulsaktion?.fields?.full_package_file?.fields?.file?.url ? (
        <Tooltip
          title={getTooltipTextFromAsset(
            impulsaktion?.fields?.full_package_file
          )}
        >
          <Button
            className="button buttonPrimary width-full"
            icon={<DownloadAlt2Icon />}
            onClick={(e: BaseSyntheticEvent) =>
              downloadContenfulFile(
                e,
                impulsaktion?.fields?.full_package_file,
                getTargetFromAsset(impulsaktion?.fields?.full_package_file)
              )
            }
          >
            Gesamtpaket downloaden
          </Button>
        </Tooltip>
      ) : (
        <Button
          className="button buttonPrimary width-full"
          icon={<DownloadAlt2Icon />}
          disabled
          onClick={(e: BaseSyntheticEvent) =>
            downloadContenfulFile(
              e,
              impulsaktion?.fields?.full_package_file,
              getTargetFromAsset(impulsaktion?.fields?.full_package_file)
            )
          }
        >
          Gesamtpaket downloaden
        </Button>
      )}
    </div>
  );
};

export default ImpulseDownload;
