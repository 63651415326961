/**
 * Get product UVP for usage in Price component
 *
 * @param rrPrice
 * @param unitQuantity
 * @param isWeighingArticle
 */
const getProductUVP = (
  rrPrice: number,
  unitQuantity: number | string = 1,
  isWeighingArticle = false
): number => {
  if (!rrPrice || rrPrice === 0) {
    return 0;
  }

  return isWeighingArticle ? rrPrice : rrPrice / Number(unitQuantity || 1);
};

export default getProductUVP;
