import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { concreteProductsEndpoint, productQueryString } from "../endpoints";
import getDeliveryDateQuery from "../getDeliveryDateQuery";
import aggregateProductData from "../../utils/aggregateProductData";

/**
 * get alternative products to given sku
 * @param sku {string}
 * @param deliveryDate {string}
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise<AxiosResponse<any>>}
 */

const getAlternativeProducts = async (
  sku: string,
  deliveryDate: string,
  cancelTokenSource: CancelTokenSource
) => {
  if (!sku || !deliveryDate) {
    Promise.reject(new Error("No SKU and/or deliveryDate given."));
  }

  const deliveryDateQuery = getDeliveryDateQuery(deliveryDate);

  return axiosWithAuth()
    .get(
      `${concreteProductsEndpoint}/${sku}/concrete-alternative-products?${productQueryString},concrete-alternative-products${deliveryDateQuery}`,
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response: any) => {
      if (
        !response?.data?.data ||
        !response?.data?.included ||
        response.status > 201
      ) {
        return [];
      }

      const {
        data: { data, included },
      } = response;

      if (data && included) {
        // create a new array which mimics the typical catalog-search request
        return aggregateProductData([...data, ...included]);
      }

      return [];
    });
};

export default getAlternativeProducts;
