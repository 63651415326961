import React, { useState } from "react";
import { Navigate, useParams, useLocation } from "react-router-dom";
import { Form, Input, Col, Row, message } from "antd";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import {
  messageData,
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../../appConfig";
import patchSetNewUserPassword from "../../../api/password/patchSetNewUserPassword";
import patchSetInitialUserPassword from "../../../api/password/patchSetInitialUserPassword";
import { UserPassword } from "../../../types/userPassword";
import clearStore from "../../../state/actions/clearStore";
import FormSubmitFooter from "../FormSubmitFooter";

function SetPasswordForm() {
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // get location info from router
  const { pathname, search } = useLocation();
  /*
   * check if the route is the initial password of a newly registered user
   * which is a slightly different API call
   */
  const isPasswordConfirmation = pathname.includes(
    routePathNames.confirmRegister
  );
  // get email from query parameters, if existing
  const email = queryString.parse(search)?.email?.toString();

  // get token from url
  const params = useParams<{ token: string }>();
  const token = params?.token;

  // Function gets fired on Form Submit
  const onFinish = async (values: any) => {
    // Starts button loading animation
    setLoading(true);

    // data object for the patch call
    const apiData: UserPassword = {
      token,
      email,
      password: values.password,
      confirmPassword: values.confirm,
    };

    const response = isPasswordConfirmation
      ? await patchSetInitialUserPassword(apiData)
      : await patchSetNewUserPassword(apiData);

    // Stops button loading animation
    setLoading(false);

    // Checks for Success or failure of request
    if (response === 200 || response === 204) {
      message.success(messageData.success.auth.setPassword);

      // if response was successful, remove stored data on client to force new userAuth
      clearStore();

      window.setTimeout(() => {
        setRedirect(true);
      }, 3000);
    } else {
      message.error(messageData.error.auth.setPassword);
    }
  };

  // redirect to login if state changes
  if (redirect) {
    return <Navigate to={routePathNames.login} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {pageTitles.login} {pageTitleSuffix}
        </title>
      </Helmet>

      <Row>
        <Col xs={12} md={6} xl={5} xxl={3}>
          <Form
            name="reset-passwort-confirm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onSubmit={() => {
              setLoading(true);
            }}
          >
            <Row gutter={[0, 8]}>
              <Col span={12}>
                <Form.Item
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      pattern:
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d\W_]{8,}$/,
                      message:
                        "Das Passwort benötigt min. 8 Zeichen, einen Groß- und Kleinbuchstaben und eine Zahl!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Neues Passwort *"
                    className="antd-password defaultInputHeight"
                    autoComplete="new-password"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Bitte Passwort bestätigen",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Die Passwörter stimmen nicht überein!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Passwort wiederholen *"
                    className="defaultInputHeight"
                    autoComplete="new-password"
                  />
                </Form.Item>
              </Col>
            </Row>

            <FormSubmitFooter
              isLoading={loading}
              submitButtonText="bestätigen"
              linkTo="login"
            />
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default SetPasswordForm;
