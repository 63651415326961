import { CancelTokenSource } from "axios";
import {
  PriceTagListEntry,
  PriceTagListPrintType,
} from "../../types/priceTagList";
import axiosWithAuth from "../axiosWithAuth";
import { printPriceTagListEndpoint } from "../endpoints";
import { ProductData } from "../../types/productData";

interface PostPrintPriceTagList {
  cancelTokenSource: CancelTokenSource;
  deliveryDate: ProductData["deliveryDate"];
  printType: PriceTagListPrintType["type"];
  priceTagsList: PriceTagListEntry[];
}

/**
 * post print request
 * returns pure blob object
 * @param {cancelTokenSource} cancelTokenSource
 * @param {ProductData["deliveryDate"]} deliveryDate
 * @param {PriceTagListPrintType["id"]} printType
 * @param {PriceTagListEntry[]} priceTagsList
 * @return {Promise<any|blob>}
 */
const postPrintPriceTagList = ({
  cancelTokenSource,
  deliveryDate,
  printType,
  priceTagsList,
}: PostPrintPriceTagList) => {
  return axiosWithAuth()
    .post(
      printPriceTagListEndpoint,
      {
        data: {
          type: "price-tags-print",
          attributes: {
            printType,
            pricingDate: deliveryDate,
            priceTags: priceTagsList,
          },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
        responseType: "blob",
      }
    )
    .then((response) => {
      if (!response?.data || response.status > 200) {
        return Promise.reject(response);
      }

      return response;
    });
};

export default postPrintPriceTagList;
