import { store } from "../../store";
import updateLocalCartData from "./updateLocalCartData";
import storeDispatch from "./storeDispatch";
import getCartDeliveryDateSlice from "./getCartDeliveryDate";
import { PostCartItemMulti } from "../../types/postCartItemMulti";
import postItemToCartMulti from "../../api/cart/postItemToCartMulti";

/**
 * handler to update api & redux cart items
 * @param deliveryDate {string}
 * @param items {array}
 * @param cancelTokenSource {CancelTokenSource}
 * @param sourceCart {Record<string, string>}
 */
const updateCartItemQuantityMulti = async ({
  deliveryDate,
  items,
  cancelTokenSource,
  sourceCart,
}: PostCartItemMulti) => {
  const cartResponse = await postItemToCartMulti({
    deliveryDate,
    items,
    cancelTokenSource,
    sourceCart,
  });

  const { status, data } = cartResponse;

  // if not valid response, ignore
  if (status >= 300) {
    return Promise.reject(cartResponse);
  }

  // only update redux on current selected delivery date
  const currentSelectedCartDeliveryDate = getCartDeliveryDateSlice(
    store.getState()
  );
  if (currentSelectedCartDeliveryDate === data?.data?.attributes.deliveryDate) {
    // cart is updated, make sure to check if the update concerns the current redux cart
    if (
      (status === 200 || status === 201) &&
      data?.included &&
      data?.data?.attributes
    ) {
      const { included } = data;
      updateLocalCartData(data.data, cartResponse?.headers?.etag);

      // set new cart with new items
      storeDispatch("cart/set-cart", {
        cartId: data?.data?.id,
        included: included || [],
        companyBusinessUnitKey:
          data?.data?.attributes?.companyBusinessUnitKey || "",
      });
    }
  }
  return cartResponse;
};

export default updateCartItemQuantityMulti;
