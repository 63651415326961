import React from "react";

import clsx from "clsx";
import { Col, Result, Row, Skeleton } from "antd";
import moment from "moment/moment";

import { ContentBlock, ExpiredCartLine } from "../../atoms";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import { routePathNames } from "../../../appConfig";
import { ReactComponent as Cart } from "../../../static/svg/cart.svg";
import { ProductData } from "../../../types/productData";

interface ExpiredCartsWidgetProps {
  title?: string;
  emptyText?: string;
  carts: Array<any>;
  productData: Array<ProductData>;
  isLoading: boolean;
  hasError: boolean;
  showHeader?: boolean;
  className?: string;
  singleColMode?: boolean;
  onDeleteCart?: () => void;
}

const ExpiredCartsWidget: React.FC<ExpiredCartsWidgetProps> = (props) => {
  const {
    title = "Abgelaufene Warenkörbe",
    emptyText = "Es sind keine abgelaufenen Warenkörbe vorhanden",
    carts,
    productData = [],
    isLoading,
    hasError,
    showHeader = true,
    className,
    singleColMode = true,
    onDeleteCart,
  } = props;
  const isMobile = useMedia(`(max-width: ${getCssVariable("screen-sm-max")})`);
  // eslint-disable-next-line no-nested-ternary
  const colSize = singleColMode ? 12 : isMobile ? 12 : 6;

  return (
    <ContentBlock
      className={clsx("expired-cart-widget", className)}
      title={title}
      linkHref={routePathNames.carts}
      showLink={!!carts?.length && !isMobile}
      showHeader={showHeader}
    >
      <Row gutter={[10, 10]} className="expired-carts-flex">
        {isLoading && (
          <Col span={12}>
            <Skeleton active />
          </Col>
        )}

        {!isLoading &&
          !hasError &&
          !!carts &&
          carts.map((data: any) => (
            <Col
              span={carts?.length === 1 ? 12 : colSize}
              key={data?.attributes?.deliveryDate}
            >
              <ExpiredCartLine
                cartId={data?.id}
                items={data?.attributes?.deliveryDateItems || []}
                productData={productData}
                deliveryDate={moment(data?.attributes?.deliveryDate)}
                totalItems={data?.attributes?.deliveryDateItems?.length || 0}
                totalCarts={carts?.length || 0}
                className={className}
                lineType={
                  data?.attributes?.deliveryDateItems?.length ? "not-empty" : ""
                }
                onDeleteCart={onDeleteCart}
              />
            </Col>
          ))}

        {!isLoading && !hasError && !!carts && !carts?.length && (
          <Col span={12} className="expired-cart-widget__empty">
            <div className="expired-cart-widget__empty__content">
              <Cart />
              {emptyText}
            </div>
          </Col>
        )}

        {!isLoading && hasError && (
          <Col span={12}>
            <Result
              status="warning"
              className="widget-error pt-sm"
              title="Es konnten leider keine abgelaufenen Warenkörbe geladen werden."
            />
          </Col>
        )}
      </Row>
    </ContentBlock>
  );
};

export default ExpiredCartsWidget;
