import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { stocktakingEndpoint } from "../endpoints";

interface DeleteStocktakingProps {
  stocktakingId: number;
  cancelTokenSource: CancelTokenSource;
}

const deleteStocktaking = async (props: DeleteStocktakingProps) => {
  const { stocktakingId, cancelTokenSource } = props;

  const url = `${stocktakingEndpoint}/${stocktakingId}`;

  return axiosWithAuth()
    .delete(url, { cancelToken: cancelTokenSource.token })
    .then((response): Promise<any> | boolean => {
      if (response.status !== 204) {
        return Promise.reject(response);
      }

      return true;
    });
};

export default deleteStocktaking;
