import React from "react";

import { Link } from "react-router-dom";
import { routePathNames } from "../appConfig";

interface LinkToCartProps {
  children: React.ReactNode;
  deliveryDate: string;
}

const LinkToCart: React.FC<LinkToCartProps> = (props) => {
  const { children, deliveryDate } = props;

  return (
    <Link
      to={{
        pathname: routePathNames.cart,
        search: `deliveryDate=${deliveryDate}`,
      }}
      state={{ deliveryDate }}
    >
      {children}
    </Link>
  );
};

export default LinkToCart;
