import React, { useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Tag,
  Tooltip,
} from "antd";
import {
  QuestionCircleOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import axios, { CancelTokenSource } from "axios";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

import patchCompanyBusinessUnit from "../../../api/companyBusinessUnit/patchCompanyBusinessUnit";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getCompanyBusinessUnit from "../../../api/companyBusinessUnit/getCompanyBusinessUnit";
import requestCatchHandler from "../../../api/requestCatchHandler";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import { deliverDateOptions, messageData } from "../../../appConfig";
import { CompanyBusinessUnitData } from "../../../types/companyBusinessUnit";
import { RootState } from "../../../types/rootState";

interface BusinessUnitPatchFormProps {
  className?: string;
}

const BusinessUnitPatchForm: React.FC<BusinessUnitPatchFormProps> = (
  props: BusinessUnitPatchFormProps
) => {
  const { className } = props;

  const dispatch = useDispatch();
  const emailAddressInputRef = useRef(null);

  const { companyBusinessUnitKey } = useSelector(
    (state: RootState) => state?.userData?.businessUnit
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [shouldSendCopy, setShouldSendCopy] = useState<boolean>(undefined);
  const [deliveryDates, setDeliveryDates] = useState<string[]>([]);
  const [initialData, setInitialData] = useState<any>(undefined);

  const [form] = Form.useForm();
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const shouldDisableFormElement = isLoading || isUpdating || hasError;

  const loadCompanyBusinessUnit = useCallback(() => {
    if (!companyBusinessUnitKey) {
      return;
    }

    setIsLoading(true);
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    getCompanyBusinessUnit({
      companyBusinessUnitKey,
      cancelTokenSource: cancelTokenSource.current,
    })
      .then((companyBusinessUnitData: CompanyBusinessUnitData) => {
        setIsLoading(false);
        setHasError(false);
        setInitialData({
          shouldSendOrderConfirmationToPurchaser:
            companyBusinessUnitData?.shouldSendOrderConfirmationToPurchaser,
          orderConfirmationEmailAddresses:
            companyBusinessUnitData?.orderConfirmationEmailAddresses,
          deliveryDates: companyBusinessUnitData?.deliveryDates,
        });
        setShouldSendCopy(
          companyBusinessUnitData?.shouldSendOrderConfirmationToPurchaser
        );
        form.setFieldValue(
          "shouldSendCopy",
          companyBusinessUnitData?.shouldSendOrderConfirmationToPurchaser
        );
        setEmailAddresses(
          companyBusinessUnitData?.orderConfirmationEmailAddresses || []
        );
        setDeliveryDates(companyBusinessUnitData?.deliveryDates || []);
        form.setFieldValue(
          "deliveryDates",
          companyBusinessUnitData?.deliveryDates || []
        );
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          setHasError(true);
          setShouldSendCopy(undefined);
          setEmailAddresses([]);
          setDeliveryDates([]);
          setInitialData(undefined);
          message.error(messageData.error.companyBusinessUnit.readError);
        }
        requestCatchHandler(error);
      });
  }, [companyBusinessUnitKey, form]);

  useEffect(() => {
    loadCompanyBusinessUnit();
  }, [companyBusinessUnitKey, loadCompanyBusinessUnit]);

  useEffect(() => {
    const initialEmailAddresses = initialData?.orderConfirmationEmailAddresses;
    const initialShouldSendCopy =
      initialData?.shouldSendOrderConfirmationToPurchaser;
    const initialDeliveryDates = initialData?.deliveryDates;

    setCanUpdate(
      JSON.stringify(emailAddresses) !==
        JSON.stringify(initialEmailAddresses) ||
        shouldSendCopy !== initialShouldSendCopy ||
        JSON.stringify(deliveryDates) !== JSON.stringify(initialDeliveryDates)
    );
  }, [initialData, emailAddresses, shouldSendCopy, deliveryDates]);

  const handleAddEmailAddress = () => {
    form
      .validateFields(["emailAddress"])
      .then(() => {
        setEmailAddresses((prevState) => {
          const emailAddress = emailAddressInputRef?.current?.input?.value;

          if (!prevState) {
            return [emailAddress];
          }

          if (emailAddress && !prevState?.includes(emailAddress)) {
            return [...prevState, emailAddress];
          }

          return prevState;
        });
        form.resetFields(["emailAddress"]);
        setTimeout(() => emailAddressInputRef?.current?.focus(), 10);
      })
      .catch(() => {});
  };

  const handleDeleteEmailAddress = (emailAddress: string) => {
    setEmailAddresses((prevState) => [
      ...prevState.filter(
        (prevEmailAddress) => emailAddress !== prevEmailAddress
      ),
    ]);
  };

  const handleFormSubmit = () => {
    setIsUpdating(true);
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    patchCompanyBusinessUnit({
      cancelTokenSource: cancelTokenSource.current,
      payload: {
        orderConfirmationEmailAddresses:
          emailAddresses?.length < 1 ? undefined : emailAddresses,
        shouldSendOrderConfirmationToPurchaser: shouldSendCopy,
        deliveryDates,
      },
    })
      .then((companyBusinessUnitData: CompanyBusinessUnitData) => {
        message.success(messageData.success.companyBusinessUnit.updateSuccess);
        setIsUpdating(false);
        setInitialData({
          shouldSendOrderConfirmationToPurchaser:
            companyBusinessUnitData?.shouldSendOrderConfirmationToPurchaser,
          orderConfirmationEmailAddresses:
            companyBusinessUnitData?.orderConfirmationEmailAddresses,
          deliveryDates: companyBusinessUnitData?.deliveryDates,
        });
        setShouldSendCopy(
          companyBusinessUnitData?.shouldSendOrderConfirmationToPurchaser
        );
        setEmailAddresses(
          companyBusinessUnitData?.orderConfirmationEmailAddresses || []
        );
        setDeliveryDates(companyBusinessUnitData?.deliveryDates || []);

        dispatch({
          type: "user/update-business-unit",
          payload: {
            deliveryDates: companyBusinessUnitData?.deliveryDates || [],
          },
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsUpdating(false);
          message.error(messageData.error.companyBusinessUnit.updateError);
        }
      });
  };

  return (
    <div className={clsx("company-business-unit-patch-form", className)}>
      <Form
        form={form}
        autoComplete="off"
        onFinish={handleFormSubmit}
        layout="vertical"
      >
        <div className="company-business-unit-patch-form__tags">
          {!emailAddresses?.length && (
            <div className="hint">
              Bestellbestätigungen werden nur an den/die Besteller*in gesendet
              (Standard)
            </div>
          )}

          {!!emailAddresses?.length && (
            <span className="mr-xs hint">
              Empfänger*innen für Bestellbestätigungen:
            </span>
          )}

          {!!emailAddresses?.length &&
            emailAddresses.map((emailAddress: string) => (
              <Tag
                key={emailAddress}
                closable={!shouldDisableFormElement}
                icon={<UserOutlined />}
                onClose={() => handleDeleteEmailAddress(emailAddress)}
              >
                {emailAddress}
              </Tag>
            ))}
        </div>

        <Row gutter={[40, 10]}>
          <Col xs={12} lg={6}>
            <Input.Group compact className="mb-s">
              <Form.Item
                name="emailAddress"
                rules={[
                  {
                    type: "email",
                    message: "Bitte eine gültige E-Mail Adresse eingeben",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input
                  ref={emailAddressInputRef}
                  disabled={shouldDisableFormElement}
                  placeholder="E-Mail Adresse"
                  onPressEnter={(e) => {
                    e.preventDefault();
                    handleAddEmailAddress();
                  }}
                  style={{ height: "40px" }}
                />
              </Form.Item>
              <Button
                type="primary"
                icon={<UserAddOutlined />}
                onClick={(e) => {
                  e.preventDefault();
                  handleAddEmailAddress();
                }}
              >
                <span className="hidden-sm-down">Hinzufügen</span>
              </Button>
            </Input.Group>
          </Col>
        </Row>

        <Form.Item name="shouldSendCopy" valuePropName="checked">
          <Checkbox
            disabled={emailAddresses?.length < 1 || shouldDisableFormElement}
            onChange={() => setShouldSendCopy((prevValue) => !prevValue)}
          >
            Kopie an Besteller*in senden?
            <Tooltip title="Der/die Besteller*in (die Person, die eine Bestellung bzw. den Warenkorb abgeschickt hat) erhält eine Kopie der Bestellbestätigung.">
              <QuestionCircleOutlined className="ml-xs" />
            </Tooltip>
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="deliveryDates"
          label="Liefertage im Wochenplaner"
          rules={[
            {
              required: true,
              message: "Bitte min. einen Liefertag auswählen.",
            },
          ]}
          tooltip={
            <span>
              In der Wochenplaner Tabelle werden nur die ausgewählten Liefertage
              als Spalten angezeigt. Nutzer können die Anzeige für sich selbst
              weiter einschränken.
            </span>
          }
        >
          <Checkbox.Group
            disabled={shouldDisableFormElement}
            className="company-business-unit-patch-form__delivery-dates"
            options={deliverDateOptions}
            onChange={(checkedValues: CheckboxValueType[]) =>
              setDeliveryDates(checkedValues as [])
            }
          />
        </Form.Item>

        <Form.Item className="company-business-unit-patch-form__submit">
          <Button
            type="primary"
            htmlType="submit"
            className="button buttonPrimary"
            loading={isUpdating}
            disabled={!canUpdate || hasError}
          >
            Einstellungen speichern
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BusinessUnitPatchForm;
