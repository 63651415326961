import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";
import { useSelector } from "react-redux";

import Price from "../../../../../price/Price";
import { productAttributes } from "../../../../../../api/productAttributes";
import { ProductData } from "../../../../../../types/productData";
import { RootState } from "../../../../../../types/rootState";

interface WeekplannerItemVolumePriceProps {
  productData: ProductData;
  quantity?: number;
  priceType?: "default" | "trolley" | "pallet";
  className?: string;
}

const WeekplannerItemVolumePrice: React.FC<WeekplannerItemVolumePriceProps> = (
  props: WeekplannerItemVolumePriceProps
) => {
  const { productData, quantity = 1, priceType = "default", className } = props;

  const hidePrices = useSelector(
    (state: RootState) => state?.appSettings?.hidePrices || false
  );

  const { attributes = {}, prices = [] } = productData || {};

  const { [productAttributes.unitQuantity]: unitQuantity } = attributes;

  const volumePrices = prices?.[0]?.volumePrices || [];

  let price;

  if (priceType === "default") {
    price = volumePrices?.find((vp: any) => vp?.quantity === quantity)?.price;
  } else {
    const otherPriceTypes = volumePrices.filter((vp: any) => vp?.quantity > 10);
    price =
      priceType === "trolley"
        ? otherPriceTypes?.[0]?.price
        : otherPriceTypes?.[1]?.price;
  }

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-volume-price",
        hidePrices && "blurred",
        className
      )}
    >
      {!price || !Number.isFinite(price) ? (
        "-"
      ) : (
        <Price price={price / parseFloat(unitQuantity)} />
      )}
    </div>
  );
};

export default React.memo(WeekplannerItemVolumePrice, areEqual);
