import React from "react";
import { Modal } from "antd";
import { Entry } from "contentful";
import { ReactComponent as Download } from "../../static/svg/download.svg";

interface Props {
  downloads: Entry<any>[];
  isVisible: boolean;
  onCancel: () => void;
  downloadAllUrl?: string;
}

/**
 * download modal wrapper for contentful files
 * @param {Array<Entry<any>>} downloads
 * @param {string} downloadAllUrl
 * @param {Function} onCancel
 * @param {boolean} isVisible
 * @constructor
 */
const DownloadModal = function DownloadModal({
  downloads,
  onCancel,
  isVisible,
  downloadAllUrl,
}: Props) {
  /**
   * get format of download
   * @param {Entry<any>} contentfulFile
   */
  const getFormat = (contentfulFile: Entry<any>) => {
    const format = contentfulFile?.fields?.file?.contentType;
    const formatType = /(application|image)\//gi;

    if (!format || !format.match(formatType)) {
      return "unbekannt";
    }

    return format.replace(formatType, "").toUpperCase();
  };

  return (
    <Modal
      className="downloadModal"
      title={
        <header className="downloadModalHeader">
          <span className="text-uppercase text-bold">Downloads</span>

          {downloadAllUrl && (
            <a
              className="button buttonText"
              href={downloadAllUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Alles downloaden
            </a>
          )}
        </header>
      }
      visible={isVisible}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <ul className="downloadModalList">
        {downloads.length > 0 &&
          downloads.map((download) => (
            <li className="downloadModalListEntry" key={download?.sys?.id}>
              <div className="downloadModalListEntryTitle">
                <strong className="text-medium">
                  {download?.fields?.title}
                </strong>

                <br />

                <span>
                  {" "}
                  (Format {getFormat(download?.fields?.file)} -{" "}
                  {(
                    (download?.fields?.file?.fields?.file?.details?.size || 0) /
                    1000000
                  ).toFixed(1) || "< 0.1"}{" "}
                  MB)
                </span>
              </div>

              <button
                type="button"
                className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText"
                onClick={() => {
                  window.open(download?.fields?.file?.fields?.file?.url);
                }}
              >
                <Download
                  width="22"
                  height="22"
                  className="icon iconDownload"
                />
                <span className="color-primary">Download</span>
              </button>
            </li>
          ))}
      </ul>
    </Modal>
  );
};

export default DownloadModal;
