/**
 * addition of all item quantities in a cart / delivery list
 * @param itemsList {array}
 * @return {number}
 */
const sumCartItems = (itemsList: any) => {
  let itemsInCart = 0;

  if (itemsList?.length > 0) {
    itemsList.forEach((curr: any) => {
      itemsInCart += parseInt(curr?.quantity || 0, 10);
    });
  }

  return itemsInCart;
};

export default sumCartItems;
