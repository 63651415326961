import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

/**
 * hook for setting the category visibility
 * @returns {function}
 */
const useSetCategoryNavigationVisibility = () => {
  const dispatch = useDispatch();
  const { isVisible } = useSelector((state: any) => state.categoryNavigation);

  /**
   *  callback that sets the new redux state when setting the category visibility
   *  @param visibility {boolean}
   */
  return useCallback(
    (visibility?: boolean) => {
      const visibilityState =
        typeof visibility !== "undefined" ? visibility : !isVisible;

      // toggle body class the legacy way
      if (visibilityState) {
        document.querySelector("body").classList.add("body-overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("body-overflow-hidden");
      }

      dispatch({
        type: "categories/set-visibility",
        payload: visibilityState,
      });
    },
    [isVisible, dispatch]
  );
};

export default useSetCategoryNavigationVisibility;
