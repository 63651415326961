import React, { useEffect, useState } from "react";
import { Input } from "antd";

const { TextArea } = Input;

interface Props {
  onChange: (value: any) => void;
  text?: string;
  title: string;
  disabled?: boolean;
  maxLength?: number;
  showCount?: boolean;
}

const Note: React.FC<Props> = ({
  onChange,
  title,
  text = "",
  disabled = false,
  maxLength = null,
  showCount = false,
}: Props) => {
  const paragraphContent = text ? String(text) : "";
  const [textLocal, setTextLocal] = useState(paragraphContent);

  /**
   * change text local on change
   * @param e {React.ChangeEvent}
   */
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextLocal(e.target.value);
  };
  /**
   * change text permanent on blur
   */
  const handleBlur = () => {
    onChange(textLocal);
  };

  useEffect(() => {
    setTextLocal(paragraphContent);
  }, [paragraphContent]);

  return (
    <div className="noteWrapper">
      <p className="text-medium text-sm text-bold title">{title}</p>

      <TextArea
        rows={2}
        value={textLocal}
        className="text-medium antd-no-animation noteText"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabled}
        maxLength={maxLength}
        showCount={showCount}
      />
    </div>
  );
};
export default Note;
