import React from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Col, Layout, message, Row, Spin } from "antd";
import UserProfileForm from "./form/UserProfileForm";
import NotAllowed from "./NotAllowed";
import postCreateCompanyUser from "../../api/userProfile/postCreateCompanyUser";
import { CompanyUserProfile } from "../../types/companyUserProfile";
import useGlobalNetworkState from "../../hooks/useGlobalNetworkState";
import { routePathNames } from "../../appConfig";

/**
 * add company user component with encapsulated logics
 */
function UserProfileAdd() {
  const { isSuperAdmin } = useSelector((state: any) => state.userData);
  const [isLoading, setIsLoading] = useGlobalNetworkState("get");
  const navigate = useNavigate();

  const setUserData = (userData: CompanyUserProfile) => {
    setIsLoading(true);

    postCreateCompanyUser(userData)
      .then(() => {
        setIsLoading(false);
        navigate(routePathNames.userProfilesAdministration);
        message.success({
          content: "Der Benutzer wurde erfolgreich erstellt",
          duration: 3,
        });
      })
      .catch((error) => {
        switch (error.response?.data?.errors[0]?.status) {
          case 422:
            message.error({
              content:
                "Ein Nutzer mit dieser E-Mail-Adresse existiert bereits.",
              duration: 3,
            });
            setIsLoading(false);
            navigate(routePathNames.userProfileAdd);
            break;
          default:
            message.error({
              content:
                "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal",
              duration: 3,
            });
            setIsLoading(false);
            navigate(routePathNames.userProfilesAdministration);
            break;
        }
      });
  };

  if (!isSuperAdmin) {
    return <NotAllowed />;
  }

  return (
    <Layout className="container-layout container-layout--inner">
      {isLoading ? (
        <Row gutter={{ xs: 16, lg: 32 }} justify="space-between">
          <Col span={12} className="">
            <Spin size="large" style={{ display: "block" }} />
          </Col>
        </Row>
      ) : (
        <UserProfileForm userData={{}} setUserData={setUserData} type="add" />
      )}
    </Layout>
  );
}
export default UserProfileAdd;
