import React, {
  useState,
  useEffect,
  useMemo,
  SyntheticEvent,
  ChangeEvent,
} from "react";
import { Input, Empty } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { parseBooleanFilterNumberToString } from "./typecastBooleanFilters";
import { Filter } from "../../../types/filters";
import { RootState } from "../../../types/rootState";

type FilterSelectOption = {
  doc_count: number;
  value: string;
  label?: string;
};

interface Props {
  currentView: FilterSelectOption[];
  selectedFilterData: any[];
  updateFilterStatesClickHandler: (item: any) => void;
  filterName: string;
}

/**
 * build submenu
 * @param filterData
 * @param selectedFilterData
 * @param goBack
 * @param resetFilterStatesClickHandler
 * @param viewEnvironment
 * @param subMenuClickHandler
 * @constructor
 */
const FiltersMobileSubmenu = function FiltersMobileSubmenu({
  currentView,
  selectedFilterData,
  updateFilterStatesClickHandler,
  filterName,
}: Props) {
  const availableFilters = useSelector(
    (state: RootState) => state.productsMetaData?.filters?.availableFilters
  );
  const [items, setItems] = useState<FilterSelectOption[]>(currentView);
  const [inactiveValues, setInactiveValues] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  /**
   * Determine if to show select all option or not
   * @return {boolean}
   */
  const showSelectAllFilterOptions = useMemo(
    (): boolean =>
      availableFilters?.find((filter: Filter) => filter.name === filterName)
        ?.config?.isMultiValued || false,
    [availableFilters, filterName]
  );

  /**
   * Select all unselected filter options
   * @param {SyntheticEvent} e
   */
  const selectAllFilterOptions = (e: SyntheticEvent): void => {
    e.preventDefault();
    items.forEach((option: FilterSelectOption) => {
      if (!selectedFilterData?.includes(option.value)) {
        updateFilterStatesClickHandler(option);
      }
    });
  };

  /**
   * Change search query
   * @param {ChangeEvent<HTMLInputElement>} e
   */
  const changeSearchQuery = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const query = e.target?.value || "";
    setSearchQuery(query);
  };

  /**
   * Determine if value is selected
   * @return {boolean}
   */
  const isSelected = (value: any): boolean => {
    if (!selectedFilterData) {
      return false;
    }

    return selectedFilterData.includes(value);
  };

  /**
   * Set/filter items depending on search query
   */
  useEffect((): void => {
    setItems(
      currentView.filter((option: FilterSelectOption) => {
        return (
          option.value.toLowerCase().includes(searchQuery.toLowerCase()) ||
          option.label?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      })
    );
  }, [searchQuery, currentView]);

  /**
   * Set inactive filter values
   */
  useEffect((): void => {
    const activeFilter = availableFilters?.find(
      (filter: Filter) => filter.name === filterName
    );

    let activeValues = activeFilter?.activeValue;
    if (!activeValues) {
      return;
    }

    activeValues = Array.isArray(activeValues) ? activeValues : [activeValues];
    if (!activeValues.length) {
      return;
    }

    const possibleValues = activeFilter?.values?.map(
      (value: any) => value?.value
    );

    setInactiveValues(
      activeValues.filter((value: any) => !possibleValues.includes(value))
    );
  }, [availableFilters, filterName]);

  return (
    <>
      {/* Show search */}
      <div className="filters-mobile__search">
        <Input
          placeholder="Durchsuchen"
          onChange={changeSearchQuery}
          allowClear
        />
      </div>

      {/* Show items / search results */}
      <ul className="filters-mobile__main">
        {/* Show/hide select all option */}
        {!!items &&
          !!items.length &&
          showSelectAllFilterOptions &&
          !searchQuery && (
            <li className="filters-mobile__option" key="select_all_option">
              <button
                type="button"
                className="filters-mobile__filter ml-m mr-m mt-m mb-s"
                onClick={selectAllFilterOptions}
              >
                <span className="filters-mobile__option--title">
                  Alles auswählen
                </span>
              </button>
            </li>
          )}

        {/* Show/hide inactive values */}
        {!!inactiveValues &&
          !searchQuery &&
          inactiveValues.map((value: any) => (
            <li
              className="filters-mobile__option filters-mobile__option--disabled"
              key={value}
            >
              <button type="button" className="filters-mobile__filter" disabled>
                <div className="filters-mobile__option--icon">
                  {isSelected(value) && (
                    <CheckOutlined className="mr-s text-m" />
                  )}
                </div>
                <span className="filters-mobile__option--title">
                  {parseBooleanFilterNumberToString(value)}
                </span>
              </button>
            </li>
          ))}

        {/* Show items / search results */}
        {!!items &&
          !!items.length &&
          items.map((item: FilterSelectOption) => (
            <li className="filters-mobile__option" key={item.value}>
              <button
                type="button"
                className="filters-mobile__filter"
                onClick={() => updateFilterStatesClickHandler(item)}
              >
                <div className="filters-mobile__option--icon">
                  {isSelected(item?.value) && (
                    <CheckOutlined className="mr-s text-m color-primary" />
                  )}
                </div>

                <span className="filters-mobile__option--title">
                  {item.label ||
                    `${parseBooleanFilterNumberToString(item.value)}`}{" "}
                  ({item.doc_count})
                </span>
              </button>
            </li>
          ))}
      </ul>

      {/* No search results */}
      {!items.length && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Keine Übereinstimmung gefunden."
          className="filters-mobile__main mt-m"
        />
      )}
    </>
  );
};

export default FiltersMobileSubmenu;
