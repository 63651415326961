import { Input, InputRef, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";

interface InputWithButtonProps {
  buttonContent: any;
  onSubmitValue: (value: string) => void;
  buttonRef?: React.MutableRefObject<HTMLButtonElement>;
  focused?: boolean;
  inputPlaceholder?: string;
  inputRef?: React.MutableRefObject<InputRef>;
  isLoading?: boolean;
  onValueChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ref?: React.MutableRefObject<HTMLDivElement>;
  title?: string;
  value?: string;
}

/**
 * custom component with input and submit button
 * @param {any} buttonContent
 * @param {value: string) => void} onSubmitValue
 * @param {React.MutableRefObject<HTMLButtonElement>} buttonRef
 * @param {boolean} focused
 * @param {string} inputPlaceholder
 * @param {React.MutableRefObject<Input>} inputRef
 * @param {boolean} isLoading
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} onValueChange
 * @param {React.MutableRefObject<HTMLDivElement>} ref
 * @param {string} title
 * @param {string} value
 * @constructor
 */
const InputWithButton: React.FC<InputWithButtonProps> = ({
  buttonContent,
  onSubmitValue,
  buttonRef,
  focused = true,
  inputPlaceholder,
  inputRef,
  isLoading = false,
  onValueChange,
  ref,
  title,
  value,
}: InputWithButtonProps) => {
  const inputComponentRef = useRef<InputRef>(inputRef?.current || null);

  const [inputValue, setInputValue] = useState("");

  /**
   * submit the current input value
   */
  const onSubmit = () => {
    if (inputValue) {
      onSubmitValue(inputValue);
    }
  };

  /**
   * on input value change
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (onValueChange) {
      onValueChange(event);
    }
  };

  useEffect(() => {
    if (focused) {
      inputComponentRef?.current.focus();
    }
  }, [focused]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className="inputWithButton" ref={ref}>
      {title && <p className="text-medium text-bold title">{title}</p>}
      <div className="inputWithButtonWrapper width-full">
        <Input
          size="large"
          placeholder={inputPlaceholder}
          value={inputValue}
          onChange={onInputValueChange}
          ref={inputComponentRef}
          onPressEnter={onSubmit}
        />
        <button
          type="button"
          className="button buttonPrimary"
          disabled={isLoading || !inputValue}
          onClick={onSubmit}
          ref={buttonRef}
        >
          <Spin spinning={isLoading}>{buttonContent}</Spin>
        </button>
      </div>
    </div>
  );
};

export default InputWithButton;
