import { AxiosResponse } from "axios";

import clearStore from "../../state/actions/clearStore";
import handleError from "../../utils/handleError";
import { routePathNames } from "../../appConfig";

interface MinimalResponseSet {
  status: number;
  statusText: string;
}

/**
 * redirect handler
 * @param response {AxiosResponse|MinimalResponseSet}
 */
const redirectIfUnauthorized = (
  response: AxiosResponse | MinimalResponseSet
) => {
  const { status, statusText } = response;

  /*
   * reroute to remain
   * spotify:track:3YDUqJknavFEZbCTN1cjsB
   */
  if (status === 401 || statusText.toLowerCase() === "unauthorized") {
    clearStore();
    if (!/login/.test(window.location.href))
      window.location.href = `${routePathNames.login}?referrer=${window.location.pathname}${window.location.search}`;
  } else {
    handleError(statusText);
  }
};

export default redirectIfUnauthorized;
