import React from "react";
import clsx from "clsx";
import {
  ProductAttributes,
  ProductTitle,
  ProductInsignias,
  ProductImage,
} from "../product";
import { productAttributes } from "../../api/productAttributes";
import getDefaultImageSet from "../../utils/getDefaultImageSet";
import getDefaultImageUrlSet from "../../utils/getDefaultImageUrlSet";
import DetailText from "../detailText/DetailText";
import ProductBasket from "../product/ProductBasket/ProductBasket";
import { ProductData } from "../../types/productData";
import ProductImageSlider from "./ProductImageSlider";
import useStockForAttribute from "../../hooks/useStockForAttribute";
import ProductInfoBadge from "../product/ProductInfoBadge";
import getDeliveryDateBasedAttributes from "../product/getDeliveryDateBasedAttributes";
import isProductADisplay from "../../utils/isProductADisplay";

// TypeScript Props interface
interface Props {
  deliveryDate: ProductData["deliveryDate"];
  productData: ProductData;
}

const ProductDetailView: React.FC<Props> = ({
  deliveryDate,
  productData,
}: Props) => {
  const { name, attributes, imageSets, sku, availabilities } = productData;
  const { isUnavailable, nextAvailability } = getDeliveryDateBasedAttributes({
    availabilities,
    deliveryDate,
  });

  const {
    [productAttributes.quality]: qualitySign,
    [productAttributes.qualityText]: qualityText,
    [productAttributes.bioIcon]: bioladenLogo,
    [productAttributes.bioFairIcon]: bioFairLogo,
    [productAttributes.shoppingArguments]: shoppingArguments,
    [productAttributes.salesArguments]: salesArguments,
    [productAttributes.isDisplayText]: isDisplayText,
    [productAttributes.isNewArticle]: isNewArticle,
    [productAttributes.deprecatedSku]: deprecatedSku,
    [productAttributes.displayText]: displayText,
    [productAttributes.articleStb]: articleStb,
    [productAttributes.articlePoster]: articlePoster,
  } = attributes;
  const advertisement = useStockForAttribute(attributes, "advert");
  const isDisplayProduct = isProductADisplay(attributes);
  const icons = String(qualitySign).split(" ");

  // get the default image set
  const defaultImages = getDefaultImageSet(imageSets);

  const imageCount = defaultImages?.images?.length || 0;

  return (
    <div
      className={clsx("productDetailView", {
        isUnavailable,
        hasNextAvailability: isUnavailable && nextAvailability,
      })}
    >
      <ProductInsignias
        width={{ xs: 12 }}
        icons={icons}
        bioladenLogo={bioladenLogo}
        bioladenFairLogo={bioFairLogo}
        qualityText={qualityText}
        steinofenbaeckerLogo={articleStb}
      />

      <ProductInfoBadge
        view="detail"
        advertisement={advertisement}
        isNewArticle={isNewArticle}
        deprecatedSku={deprecatedSku}
        isArticlePosterAvailable={articlePoster}
      />

      {imageCount <= 1 ? (
        <ProductImage
          imageSet={getDefaultImageUrlSet(imageSets)}
          className={clsx(
            { isUnavailable: isUnavailable && !nextAvailability },
            "productImageSingle"
          )}
          sku={sku}
          imageType="detail"
        />
      ) : (
        <ProductImageSlider
          defaultImages={defaultImages}
          name={name}
          imageClassName={clsx({
            isUnavailable: isUnavailable && !nextAvailability,
          })}
        />
      )}

      <ProductTitle
        availabilities={availabilities}
        attributes={attributes}
        deliveryDate={deliveryDate}
        sku={sku}
        isDetail
        title={name}
        titleClassName={clsx({ isUnavailable }, "detailProductTitle")}
      />

      <ProductAttributes
        attributes={attributes}
        deliveryDate={deliveryDate}
        sku={sku}
        view="detail"
      />

      {/* currently empty containers */}
      <div className="productInfoIcons" />

      {(!isUnavailable || nextAvailability) && (
        <ProductBasket deliveryDate={deliveryDate} productData={productData} />
      )}

      {(isDisplayProduct || shoppingArguments || salesArguments) && (
        <DetailText
          deliveryDate={deliveryDate}
          detailText={shoppingArguments}
          isDisplayProduct={isDisplayProduct}
          isDisplayText={isDisplayText}
          salesArguments={salesArguments}
          sku={sku}
          displayText={displayText}
        />
      )}
    </div>
  );
};

export default ProductDetailView;
