import { useEffect, useState } from "react";

import axios from "axios";

import getWebshopDashboardTeaser from "../api/contentful/getWebshopDashboardTeaser";
import handleError from "../utils/handleError";
import { ContentfulWebshopTeaserEntry } from "../types/contentfulWebshopTeaserEntry";
import { StockType } from "../types/stock";

const useGetWebshopTeaser = (
  limit: number,
  skip: number,
  displayPosition: "Oben" | "Unten",
  stockName: StockType
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<ContentfulWebshopTeaserEntry[]>([]);
  const [total, setTotal] = useState<number>(null);

  useEffect(() => {
    setIsLoading(true);
    getWebshopDashboardTeaser({ limit, skip, displayPosition, stockName })
      .then((response) => {
        setEntries(response.items);
        setTotal(response.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [limit, skip, displayPosition, stockName]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetWebshopTeaser;
