import { contentfulContentTypes } from "../../appConfig";
import getSingleContentTypeEntry from "./getSingleContentTypeEntry";

/**
 * retrieve imprint from contentful
 */
const getImprint = () =>
  getSingleContentTypeEntry(contentfulContentTypes.imprint);

export default getImprint;
