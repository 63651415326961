/**
 * menuItems contains all selectable sortBy arguments. The key property is equal to the key that is used all backend functions
 */
const sortCriteria = [
  {
    title: "Empfohlen",
    key: "default",
  },
  {
    title: "Artikelnummer",
    key: "sku",
  },
  {
    title: "Hersteller",
    key: "hersteller",
  },
  {
    title: "Artikelname",
    key: "name",
  },
  {
    title: "Verband",
    key: "verband",
  },
  {
    title: "Ursprungsland",
    key: "land",
  },
  {
    title: "Warengruppe",
    key: "warengruppe",
  },
];

export default sortCriteria;
