import React from "react";
import moment, { Moment } from "moment";
import { Tooltip } from "antd";
import { CheapCartMetadata } from "../../../types/cheapCartMetadata";
import { ProductData } from "../../../types/productData";
import { CartIncludingSkuInfo } from "../../../types/productModal";

interface ProductPreorderCalenderCellTooltipProps {
  sku: ProductData["sku"];
  cellDate: Moment;
  openCarts?: Array<CheapCartMetadata>;
}

const ProductPreorderCalenderCellTooltip: React.FC<
  ProductPreorderCalenderCellTooltipProps
> = (props) => {
  const { sku, cellDate, openCarts } = props;
  const cartsIncludingSku = openCarts
    .filter((currentCart) =>
      currentCart?.items.find((currentItem) => currentItem?.sku === sku)
    )
    .map((currentCart) => {
      const currentCartSKUInfo = currentCart?.items.find(
        (currentItem) => currentItem?.sku === sku
      );
      const cartIncludingSkuInfo: CartIncludingSkuInfo = {
        quantity: currentCartSKUInfo?.quantity,
        deliveryDate: currentCart?.deliveryDate,
      };
      return cartIncludingSkuInfo;
    });

  const cartInfoCurrentCell = cartsIncludingSku.filter((cart) =>
    moment(cellDate).isSame(cart?.deliveryDate)
  );

  return cartInfoCurrentCell.length === 1 ? (
    <Tooltip title={`${cartInfoCurrentCell[0]?.quantity}x`}>
      <div className="ant-picker-cell-inner hasCart">{cellDate.date()}</div>
    </Tooltip>
  ) : (
    <div className="ant-picker-cell-inner">{cellDate.date()}</div>
  );
};

export default ProductPreorderCalenderCellTooltip;
