import { useEffect, useState } from "react";

import axios from "axios";

import getWebshopDashboardOffers from "../api/contentful/getWebshopDashboardOffers";
import handleError from "../utils/handleError";
import { ContentfulWebshopOfferEntry } from "../types/contentfulWebshopOfferEntry";
import { StockType } from "../types/stock";

const useGetWebshopOffers = (
  limit: number,
  skip: number,
  stockName: StockType
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<ContentfulWebshopOfferEntry[]>([]);
  const [total, setTotal] = useState<number>(null);

  useEffect(() => {
    setIsLoading(true);
    getWebshopDashboardOffers({ limit, skip, stockName })
      .then((response) => {
        setEntries(response?.items || []);
        setTotal(response.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [limit, skip, stockName]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetWebshopOffers;
