import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

/**
 * custom hook for setting if the user hoveres the category dropdown menu
 * @returns {function}
 */
const useSetCategoriesMenuHovered = () => {
  const dispatch = useDispatch();
  const { isHovered } = useSelector((state: any) => state.categoryNavigation);

  /**
   * callback that sets the new redux state when hovering the category dropdown menu
   * @param {boolean} hovered
   */
  return useCallback(
    (hovered?: boolean) => {
      const hoveredState =
        typeof hovered !== "undefined" ? hovered : !isHovered;

      dispatch({
        type: "categories/set-categories-hovered",
        payload: hoveredState,
      });
    },
    [isHovered, dispatch]
  );
};

export default useSetCategoriesMenuHovered;
