import React, { useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { RootState } from "../../../types/rootState";
import { BusinessUnit } from "../../../types/businessUnits";
import { ReactComponent as Store } from "../../../static/svg/store.svg";
import BusinessUnitModal from "../../molecules/BusinessUnitModal";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";

interface BusinessUnitBoxProps {
  className: string;
}

const BusinessUnitBox: React.FC<BusinessUnitBoxProps> = ({
  className,
}: BusinessUnitBoxProps) => {
  const {
    businessUnit,
    businessUnits,
  }: { businessUnit: BusinessUnit; businessUnits: BusinessUnit[] } =
    useSelector((state: RootState) => state?.userData);

  const { firstName, lastName }: { firstName: string; lastName: string } =
    useSelector((state: RootState) => state?.userData);

  const fullNameExists = !!firstName && !!lastName;
  const isLargeScreen = useMedia(`(min-width: ${getCssVariable("screen-lg")})`);

  function setGreetingText() {
    const currentHour = Number(
      new Date().toLocaleTimeString().toString().split(":")[0]
    );

    let greetingText = "";
    if (currentHour >= 6 && currentHour < 12) {
      greetingText = "Guten Morgen";
    } else if (currentHour >= 12 && currentHour < 18) {
      greetingText = "Guten Tag";
    } else if (currentHour >= 18 && currentHour < 24) {
      greetingText = "Guten Abend";
    } else {
      greetingText = "Gute Nacht";
    }
    if (fullNameExists) {
      greetingText += ",";
      return greetingText;
    }
    return greetingText;
  }

  let userNameGreetingStyle;
  if (fullNameExists) {
    userNameGreetingStyle = "business-unit-userNameGreetingContainer";
  } else {
    userNameGreetingStyle = null;
  }

  let userName;
  if (fullNameExists) {
    if (isLargeScreen) {
      userName = `${firstName} ${lastName}`;
    } else {
      userName = `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    }
  } else {
    userName = <Store className="icon icon-business-unit" />;
  }

  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <div className={clsx(className)}>
      <button
        type="button"
        className={clsx(
          "business-unit-box-container",
          businessUnits?.length > 1 && "can-switch"
        )}
        id="business-unit-tooltip-container"
        onClick={() => {
          setIsVisible(true);
        }}
        title={`Kundennr.: ${
          businessUnit?.companyBusinessUnitKey || "-"
        } \nBenutzername: ${fullNameExists ? userName : "-"}`}
      >
        <div>
          <div className="business-unit-name-wrapper">
            <p className="business-unit-paragraph">Kunde</p>
          </div>
          <div className="business-unit-key-wrapper">
            <p className="business-unit-key">
              {businessUnit.companyBusinessUnitKey}
            </p>
          </div>
        </div>
        <div className="business-unit-breakline" />
        <div className={`${userNameGreetingStyle}`}>
          <div className="business-unit-greeting-wrapper">
            <p className="business-unit-greeting-paragraph">
              {setGreetingText()}
            </p>
          </div>
          <div className="business-unit-username-wrapper">
            <p className="business-unit-username-paragraph">{userName}</p>
          </div>
        </div>
      </button>

      <BusinessUnitModal
        hideModal={() => {
          setIsVisible(false);
        }}
        visible={isVisible}
        businessUnit={businessUnit}
        closable
      />
    </div>
  );
};

export default BusinessUnitBox;
