import { Document } from "@contentful/rich-text-types";
import { Entry } from "contentful";

export interface NewsEntryContentfulStructure extends Entry<any> {
  fields: {
    title: string;
    publish_date: string;
    teaser_text: Document;
    title_image: {
      fields: {
        title: string;
        file: { url: string };
      };
    };
  };
}

/**
 * wrapper helper to destruct news entry to simplified data set
 * @param newsEntry {NewsEntryContentfulStructure}
 */
const createNewsEntryVariables = (newsEntry: NewsEntryContentfulStructure) => {
  const {
    sys: { id },
    fields: {
      title,
      publish_date: publishDate,
      teaser_text: teaserText,
      title_image: titleImage,
    },
  } = newsEntry;

  // an image is not required by default
  let imageTitle = "";
  let url = "";

  if (titleImage) {
    ({
      fields: {
        title: imageTitle,
        file: { url },
      },
    } = titleImage);
  }

  return {
    id,
    title,
    publishDate,
    teaserText,
    imageTitle,
    url,
  };
};

export default createNewsEntryVariables;
