import { sprykerCompareValues } from "../../appConfig";

const initialState: any = {
  firstName: "",
  id: "",
  businessUnit: "",
  lastName: "",
  email: "",
  dateOfBirth: "",
  gender: "",
  salutation: "",
  idStock: 1,
  stockName: "",
  phone: "",
  isSuperAdmin: "",
  isImported: "",
  termsAndConditions: {
    userMaySupersedeTaC: false,
    acceptedTaC: false,
    acceptedTimestamp: null,
  },
  favouriteListDefaultSorting: null,
  deliveryDates: [],
  weekplannerUseSimpleMode: false,
};

const fallbackAddress: any = {
  attributes: {
    address1: "Keine Adresse angegeben",
    address2: "",
    address3: "",
    city: "",
    company: "",
    country: "",
    isDefaultBilling: false,
    isDefaultShipping: false,
    zipCode: "",
  },
};

const userDataReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "user/set-data": {
      const {
        attributes,
        included,
        termsAndConditionsState: { agbConsentDateTime, isSuccess },
        id,
      } = payload;
      const addresses = included
        .filter((value: any) => value.type === "company-unit-addresses")
        .map((addressData: any) => addressData?.attributes);

      const businessUnits = included
        .filter((value: any) => value.type === "company-user-candidates")
        .map((addressData: any) => addressData?.attributes);

      return {
        ...state,
        addresses: addresses || [fallbackAddress],
        businessUnits: businessUnits || [],
        firstName: attributes?.firstName,
        lastName: attributes?.lastName,
        email: attributes?.email,
        dateOfBirth: attributes?.dateOfBirth,
        gender: attributes?.gender,
        salutation: attributes?.salutation,
        idStock: attributes?.idStock,
        stockName: attributes?.stockName,
        phone: attributes?.phone,
        id,
        customerNumber: attributes?.customerNumber,
        isSuperAdmin: attributes?.isSuperAdmin,
        isImported: attributes?.isImported,
        contactPerson: { ...attributes.contactPerson },
        termsAndConditions: {
          userMaySupersedeTaC: attributes?.permissionKeys.includes(
            sprykerCompareValues.termsAndConditionsConsentPermission
          ),
          acceptedTaC: agbConsentDateTime && isSuccess,
          acceptedTimestamp: parseInt(agbConsentDateTime, 10),
        },
        favouriteListDefaultSorting:
          attributes?.favouriteListDefaultSorting || null,
        deliveryDates: attributes?.deliveryDates || [],
        weekplannerUseSimpleMode: attributes?.weekplannerUseSimpleMode || false,
      };
    }

    case "user/set-business-unit": {
      return {
        ...state,
        ...payload,
      };
    }
    // super admin accepts Terms and Conditions
    case "user/set-accepted-terms-and-conditions":
      return {
        ...state,
        termsAndConditions: {
          ...state.termsAndConditions,
          acceptedTaC: payload,
          acceptedTimestamp: Math.floor(Date.now() / 1000),
        },
      };

    case "user/update-user":
      return { ...state, ...payload };

    case "user/update-business-unit":
      return { ...state, businessUnit: { ...state?.businessUnit, ...payload } };

    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default userDataReducer;
