import React from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

import useGetAdProductListing from "../../../hooks/useGetAdProductListing";
import Picture from "../../picture/Picture";
import { PictureTile, QuickLink } from "../../atoms";
import { RootState } from "../../../types/rootState";

interface AdProductListingProps {
  categoryKey: string;
  className?: string;
}

const AD_TYPE = "ad product listing";
const DEFAULT_AD_TEXT = "Mehr erfahren";

const AdProductListing: React.FC<AdProductListingProps> = (
  props: AdProductListingProps
) => {
  const { categoryKey, className } = props;

  const { stockName } = useSelector((state: RootState) => state?.userData);

  const { isLoading, hasError, entries } = useGetAdProductListing({
    categoryIds: categoryKey,
    stockName,
  });

  const { trackEvent } = useMatomo();

  const adName = entries?.[0]?.fields?.internal_name || "";
  const desktopAdUrl = entries?.[0]?.fields?.banner_desktop?.fields?.file?.url;
  const mobileAdUrl = entries?.[0]?.fields?.banner_mobile?.fields?.file?.url;
  const adText = entries?.[0]?.fields?.banner_text;
  const adUrl = entries?.[0]?.fields?.banner_link;

  if (isLoading || hasError || !desktopAdUrl) {
    return null;
  }

  return (
    <div
      className={clsx("ad-product-listing", className)}
      onLoad={() =>
        trackEvent({
          category: "ads",
          action: `${AD_TYPE} impression`,
          name: adName,
        })
      }
    >
      <PictureTile
        picture={
          <Picture
            imageScreenXs={`${
              mobileAdUrl || desktopAdUrl
            }?fit=fill&f=top&fm=webp&q=40`}
            imageScreenSm={`${
              mobileAdUrl || desktopAdUrl
            }?fit=fill&f=top&fm=webp&q=60`}
            imageScreenMd={`${
              mobileAdUrl || desktopAdUrl
            }?fit=fill&f=top&fm=webp&q=80`}
            imageScreenLg={`${desktopAdUrl}?fit=fill&f=top&fm=webp`}
            imageScreenXl={`${desktopAdUrl}?fit=fill&f=top&fm=webp`}
          />
        }
        link={
          adUrl ? (
            <QuickLink
              label={adText || DEFAULT_AD_TEXT}
              href={adUrl}
              linkType="primary--outlined"
              onClick={() =>
                trackEvent({
                  category: "ads",
                  action: `${AD_TYPE} click`,
                  name: adName,
                })
              }
            />
          ) : null
        }
        title={null}
      />
    </div>
  );
};

export default AdProductListing;
