import { CancelTokenSource } from "axios";
import { getProductsEndpoint } from "../endpoints";
import axiosWithAuth from "../axiosWithAuth";
import getCatalogSearchAttributesData from "./getCatalogSearchAttributesData";

interface GetProductDetail {
  cancelTokenSource: CancelTokenSource;
  deliveryDate: string;
  productSku: string;
  breadcrumbReference?: string;
  skipAvailabilityCheck?: boolean;
}

/**
 * get all details of a product
 * @param {CancelTokenSource} cancelTokenSource
 * @param {string} deliveryDate
 * @param {string} productSku
 * @param {string} breadcrumbReference
 * @returns {Promise}
 */
const getProductDetail = async ({
  cancelTokenSource,
  deliveryDate,
  productSku,
  breadcrumbReference,
  skipAvailabilityCheck,
}: GetProductDetail) => {
  if (!productSku || !deliveryDate) {
    return Promise.reject(new Error("No SKU or deliveryDate given."));
  }

  const queryString = productSku || "";

  return axiosWithAuth()
    .get(
      getProductsEndpoint({
        page: 1,
        pageSize: 24,
        categoryKey: "1",
        deliveryDates: deliveryDate,
        sku: queryString,
        breadcrumbReference,
        skipAvailabilityCheck,
      }),
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((res) => {
      if (res.status !== 200) {
        return Promise.reject(res);
      }

      return getCatalogSearchAttributesData(res?.data?.data);
    });
};

export default getProductDetail;
