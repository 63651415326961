import React from "react";
import clsx from "clsx";

interface BubbleProps {
  value: string | number;
  className: string;
}

const Bubble: React.FC<BubbleProps> = (props) => {
  const { value, className } = props;
  return <div className={clsx("bubble", className)}>{value}</div>;
};

export default Bubble;
