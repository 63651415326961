import React from "react";
import { ProductData } from "../../../types/productData";
import ProductViewer from "./ProductViewer";
import { ModalButton } from "../../../types/button";

interface Props {
  deliveryDate: ProductData["deliveryDate"];
  productData: ProductData;
  buttonStyle?: ModalButton;
  getContainer?: () => HTMLElement;
}

/**
 * Product modal with preorder functionality, but is dependent of correct data
 * has to get the productData of the whole product to not fetch the data again!
 * @param deliveryDate {string}
 * @param productData {ProductData}
 * @param buttonStyle {ModalButton}
 * @param getContainer
 * @constructor
 */
const ProductPreorderModal = function ProductPreorderModal({
  deliveryDate,
  productData,
  buttonStyle,
  getContainer,
}: Props) {
  return (
    <ProductViewer
      deliveryDate={deliveryDate}
      products={[productData]}
      buttonTitle="Nächster Liefertermin"
      title="Nächster Liefertermin"
      buttonStyle={buttonStyle}
      getContainer={getContainer}
    />
  );
};

export default ProductPreorderModal;
