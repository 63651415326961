import React, { useCallback, useEffect, useRef } from "react";

import clsx from "clsx";

import FoldoutMenuItem from "./FoldoutMenuItem";
import { routePathNames } from "../../appConfig";
import { ReactComponent as WeilingLogo } from "../../static/svg/weiling.svg";
import { ReactComponent as WebshopIcon } from "../../static/svg/webshop.svg";
import { ReactComponent as AcademyIcon } from "../../static/svg/academy.svg";
import { ReactComponent as InfoCenterIcon } from "../../static/svg/infothek.svg";
import { ReactComponent as ImpulseIcon } from "../../static/svg/impulse.svg";

const entries = [
  {
    icon: <WeilingLogo className="icon iconFoldoutMenu" />,
    title: "Portalübersicht",
    link: "/",
  },
  {
    icon: <WebshopIcon className="icon iconFoldoutMenu" />,
    title: "Webshop",
    link: routePathNames.shop,
  },
  {
    icon: <ImpulseIcon className="icon iconFoldoutMenu" />,
    title: "Impulse",
    link: routePathNames.impulse,
  },
  {
    icon: <InfoCenterIcon className="icon iconFoldoutMenu" />,
    title: "Infothek",
    link: routePathNames.infoCenter,
  },
  {
    icon: <AcademyIcon className="icon iconFoldoutMenu" />,
    title: "Akademie",
    link: "https://www.weiling-akademie.de/",
    external: true,
  },
];

interface FoldoutMenuProps {
  onClick: () => void;
  className?: string;
}

const FoldoutMenu: React.FC<FoldoutMenuProps> = (props: FoldoutMenuProps) => {
  const { onClick, className } = props;

  const foldoutMenuRef = useRef(null);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        foldoutMenuRef.current &&
        !foldoutMenuRef.current.contains(event.target) &&
        typeof onClick === "function"
      ) {
        onClick();
      }
    },
    [onClick]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [foldoutMenuRef, handleClickOutside]);

  return (
    <nav className={clsx("foldoutMenu", className)} ref={foldoutMenuRef}>
      <ul className="foldoutMenuList foldoutMenuArrow">
        {entries.map((curr) => (
          <li key={curr.title} className="foldoutMenuListEntry">
            <FoldoutMenuItem data={curr} onClick={onClick} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FoldoutMenu;
