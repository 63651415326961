import React from "react";
import { Divider, Col, Row } from "antd";
import { ReactComponent as Logo } from "../../static/svg/weiling.svg";

function LoginHeader() {
  return (
    <div className="login-header">
      <Row className="logo-row">
        <Col xs={7} md={12}>
          <Divider className="header-desktop-divider" />
          <h3>
            <span className="login-header-breaks">BIO-PIONIER</span> SEIT 1975
            <br />
            <Divider className="header-desktop-divider" />
            <span className="login-header-breaks">GROSSHANDEL</span> & SERVICE
          </h3>
        </Col>
        <Col xs={4} md={12}>
          <div className="color-white">
            <Logo />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Divider className="login-header-divider" />
        </Col>
      </Row>
    </div>
  );
}

export default LoginHeader;
