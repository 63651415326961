import objectHasKey from "./objectHasKey";
import { ImageSet, ProductData } from "../types/productData";

const imageDefaultObject = {
  externalUrlSmall: "",
  externalUrlLarge: "",
};

/**
 * extract the image url if possible
 * @param imageSets
 * @returns {Object}
 */
const getDefaultImageUrlSet = (imageSets: ProductData["imageSets"]): ImageSet =>
  Array.isArray(imageSets)
    ? imageSets.reduce((accumulator: any, currentImageSet) => {
        const defaultImageSet = currentImageSet?.images.find(
          (currentImage) =>
            objectHasKey(currentImage, "externalUrlSmall") &&
            currentImageSet.name === "default"
        );

        return defaultImageSet || imageDefaultObject;
      }, 0)
    : imageDefaultObject;

export default getDefaultImageUrlSet;
