import { useMemo } from "react";

import { useSelector } from "react-redux";

import { RootState } from "../types/rootState";

const useGetWeekplannerWeekDaysData = () => {
  const { weekDates, weekCarts } = useSelector(
    (state: RootState) => state.weekplannerData
  );

  const weekDaysData = useMemo(
    () =>
      weekDates.map((weekdayEntry: any) => {
        const cartId = weekCarts
          ? weekCarts.find(
              (entry: any) => entry.deliveryDate === weekdayEntry.apiDate
            )?.id
          : null;

        return {
          ...weekdayEntry,
          cartId,
        };
      }),
    [weekDates, weekCarts]
  );

  return {
    weekDaysData,
  };
};

export default useGetWeekplannerWeekDaysData;
