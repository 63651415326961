import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { stocktakingEndpoint, stocktakingItemEndpoint } from "../endpoints";
import { StocktakingItemData } from "../../types/stocktaking";

interface PostStocktakingItemProps {
  stocktakingId: number;
  eanCode: string;
  sku?: string;
  quantity: number;
  label?: string;
  unitVpe?: string;
  unitPrice?: number;
  taxRate?: number;
  producer?: string;
  idProduct?: number;
  cancelTokenSource: CancelTokenSource;
}

const postStocktakingItem = async (props: PostStocktakingItemProps) => {
  const {
    stocktakingId,
    eanCode,
    sku,
    quantity,
    label,
    unitVpe,
    unitPrice,
    taxRate,
    producer,
    idProduct,
    cancelTokenSource,
  } = props;

  const url = `${stocktakingEndpoint}/${stocktakingId}${stocktakingItemEndpoint}`;

  return axiosWithAuth()
    .post(
      url,
      {
        data: {
          type: "stocktaking-item",
          attributes: {
            eanCode,
            sku,
            quantity,
            label,
            unitVpe,
            unitPrice,
            taxRate,
            producer,
            idProduct: idProduct || undefined,
          },
        },
      },
      { cancelToken: cancelTokenSource.token }
    )
    .then((response): Promise<any> | StocktakingItemData => {
      if (!response?.data?.data || response.status !== 201) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data || {};

      return attributes as StocktakingItemData;
    });
};

export default postStocktakingItem;
