import { store } from "../../store";

/**
 * update redux filter states if category id changes
 * @param newFilters {number|string}
 */
const updateFilterStatesOnCategoryChange = (newFilters: number | boolean) => {
  const { activeId } = store.getState().productsMetaData.filters;

  if (
    activeId !== newFilters ||
    (typeof newFilters === "boolean" && newFilters)
  ) {
    // reset filters
    store.dispatch({
      type: "filter/reset-all",
      payload: null,
    });
  }

  if (activeId !== newFilters && typeof newFilters === "number") {
    store.dispatch({
      type: "filter/set-active-category-id",
      payload: newFilters,
    });
  }
};

export default updateFilterStatesOnCategoryChange;
