import React from "react";
import { Col, Typography } from "antd";
import clsx from "clsx";

const { Title } = Typography;

interface Props {
  title: string;
  children: React.ReactNode;
  size?: Record<string, unknown>;
  className?: string;
  headlineLevel?: 1 | 2 | 3 | 4 | 5;
}

function Teaser({
  title,
  children,
  size = { md: 12, xs: 12 },
  className,
  headlineLevel = 1,
}: Props) {
  return (
    <Col md={size.md} className={clsx("teaser bgColor-light-grey", className)}>
      <div className="teaserInner">
        <Title level={headlineLevel} className={`like-${headlineLevel}`}>
          {title}
        </Title>
        <div>{children}</div>
      </div>
    </Col>
  );
}

export default Teaser;
