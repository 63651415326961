import moment from "moment";
import { store } from "../../store";
import createWeekplannerDates, {
  WeekdayData,
} from "../../utils/createWeekplannerDates";
import { apiDateFormat } from "../../utils/dateFormats";

/**
 * store new set of weekdays data based on passed date
 * @param {moment.Moment} startDate
 * @param {boolean} requiresWeekDatesUpdate
 * @returns {boolean|WeekDayData[]}
 */
const updateWeekplannerDates = (
  startDate: moment.Moment,
  requiresWeekDatesUpdate?: boolean
) => {
  const { weekDates, minWeek, maxWeek } = store.getState().weekplannerData;
  const formattedStartDate = startDate.format(apiDateFormat);

  /*
   * update cart dates only, if the week is not correct
   * or isToday is deprecated
   * otherwise prevent dispatch, due to the fact that a new object triggers rerender.
   * If not necessary, it is recommended to prevent
   */
  const isCorrectWeek = weekDates.some(
    (weekDayEntry: WeekdayData) => weekDayEntry.apiDate === formattedStartDate
  );
  const storedToday = weekDates.find(
    (weekDayEntry: WeekdayData) => weekDayEntry.isToday
  )?.apiDate;
  const isCorrectToday = moment(storedToday).isSame(moment(), "day");

  if (isCorrectToday && isCorrectWeek && !requiresWeekDatesUpdate) {
    return false;
  }

  const newWeekData = createWeekplannerDates(startDate);
  const newCalenderWeek = newWeekData[0].apiDate;
  // update only if in active range of weeks
  if (
    !minWeek ||
    !maxWeek ||
    (newCalenderWeek >= minWeek && newCalenderWeek <= maxWeek)
  ) {
    store.dispatch({
      type: "weekplanner/update-dates",
      payload: newWeekData,
    });
  }

  return newWeekData;
};

export default updateWeekplannerDates;
