import React from "react";

import { Link } from "react-router-dom";
import { BLOCKS, Document, INLINES, Node } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import ShowCookiesTrigger from "../components/atoms/ShowCookiesTrigger";

const options = {
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: ({ data, content }: any) => (
      <a
        href={`https:${data.target.fields.file.url}`}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {content[0].value}
      </a>
    ),
    [INLINES.HYPERLINK]: ({ data }: Node, children: any) => {
      let isInternalLink: boolean;

      try {
        const url = new URL(data.uri);
        isInternalLink = url.host === window.location.host;
      } catch (error) {
        isInternalLink = true;
      }

      if (isInternalLink) {
        return <Link to={data.uri}>{children}</Link>;
      }

      return (
        <a href={data.uri} target="_blank" rel="nofollow noopener noreferrer">
          {children}
        </a>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data }: any) => (
      <img
        src={data?.target?.fields?.file?.url}
        alt={data?.target?.fields?.description}
        className="cf-embedded-asset"
        loading="lazy"
        decoding="async"
      />
    ),
  },
  renderText: (text: string): any[] =>
    text
      // Transform "\n" to <br>
      .split("\n")
      .reduce((children: any[], textSegment: string, index: number) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, [])
      // Transform "___COOKIE_CONSENT___" to <ShowCookiesTrigger />
      .map((value: any) => {
        if (typeof value !== "string") {
          return value;
        }
        return value
          .split("___COOKIE_CONSENT___")
          .reduce((children: any[], textSegment: string, index: number) => {
            return [
              ...children,
              index > 0 && <ShowCookiesTrigger key={index} display="button" />,
              textSegment,
            ];
          }, []);
      }),
};

const renderContentfulDocument = (document: Document): React.ReactNode => {
  return documentToReactComponents(document, options);
};

export default renderContentfulDocument;
