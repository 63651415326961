import React from "react";
import { Row, Col, Layout } from "antd";
import FooterLegalLinks from "./FooterLegalLinks";

function Footer() {
  return (
    <Layout className="container-layout container-layout--inner">
      <Row>
        <Col span={12}>
          <FooterLegalLinks
            navClassName="footer-nav"
            listClassName="footer-menu"
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default Footer;
