import React from "react";
import clsx from "clsx";

type Sizes = "0" | "xs" | "s" | "sm" | "m" | "l" | "xl" | "2xl" | "3xl";

interface Props {
  size?: number;
  className?: string;
  spacingTop?: Sizes;
  spacingBottom?: Sizes;
}

const HrDivider: React.FC<Props> = ({
  className,
  spacingTop = "0",
  spacingBottom = "0",
  size = 1,
}: Props) => (
  <hr
    className={clsx(
      "divider",
      className,
      `mb-${spacingBottom}`,
      `mt-${spacingTop}`
    )}
    style={{ height: size }}
  />
);

export default HrDivider;
