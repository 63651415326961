const dateFormat = "dd, DD. MMM";
const dayFormat = "dddd";
const monthYearFormat = "MMM YY";
const fullDateFormat = "DD.MM.YYYY";
const apiDateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export {
  dateFormat,
  dayFormat,
  fullDateFormat,
  apiDateFormat,
  monthYearFormat,
  timeFormat,
  dateTimeFormat,
};
