import { message, Modal, Spin } from "antd";
import axios from "axios";
import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import deleteFavouriteList from "../../api/favouritesList/deleteFavouriteList";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import requestCatchHandler from "../../api/requestCatchHandler";
import { messageData } from "../../appConfig";
import setFavouriteLists from "../../state/actions/setFavouriteLists";
import { FavouriteList } from "../../types/favouriteList";
import { ReactComponent as Delete } from "../../static/svg/delete.svg";

interface DeleteFavouriteListButtonProps {
  idFavouriteList: string;
}

/**
 * component for deleting a favourite list
 * @param {string} idFavouriteList
 * @constructor
 */
const DeleteFavouriteListButton: React.FC<DeleteFavouriteListButtonProps> =
  function DeleteFavouriteListButton({
    idFavouriteList,
  }: DeleteFavouriteListButtonProps) {
    const { favouriteLists } = useSelector((state: any) => state.favouriteList);

    const cancelTokenSource = useRef(getCancelTokenSource());

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] =
      useState<boolean>(false);

    // selected favourite list for deletion process
    const favouriteListToDelete: FavouriteList = useMemo(
      () =>
        favouriteLists.find(
          (favouriteList: FavouriteList) =>
            favouriteList.idFavouriteList === idFavouriteList
        ),
      [idFavouriteList, favouriteLists]
    );

    // message content
    const {
      error: {
        favouriteList: { deleteMessage: noDeleteMsg },
      },
      success: {
        favouriteList: {
          deletedLists: deletedListMsg,
          createdStandardList: newStandardListMsg,
        },
      },
    } = messageData;

    /**
     * deletion function for the selected favourite list
     */
    const deleteSelectedFavouriteList = () => {
      setIsLoading(true);

      deleteFavouriteList({
        cancelTokenSource: cancelTokenSource.current,
        listName: idFavouriteList,
      })
        .then(() => {
          let successMessage;
          if (favouriteLists.length > 1) {
            successMessage = deletedListMsg;
          } else {
            successMessage = newStandardListMsg;
          }
          message.success(successMessage);

          setFavouriteLists(cancelTokenSource.current);
          setIsConfirmModalVisible(false);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsConfirmModalVisible(false);

          if (!axios.isCancel(error)) {
            message.error(noDeleteMsg);
            setFavouriteLists(cancelTokenSource.current);
          }

          requestCatchHandler(error);
        });
    };

    /**
     * check if a selected list has entries and warn the user before deleting the list
     */
    const checkIfListHasEntries = () => {
      if (favouriteListToDelete) {
        if (favouriteListToDelete?.favourites.length) {
          setIsConfirmModalVisible(true);
        } else {
          deleteSelectedFavouriteList();
        }
      }
    };

    return (
      <>
        <div className="deleteFavouriteListButtonWrapper">
          <Spin size="small" spinning={isLoading}>
            <button
              type="button"
              onClick={checkIfListHasEntries}
              className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText deleteFavouriteListButton"
            >
              <Delete className="icon" />
            </button>
          </Spin>
        </div>

        <Modal
          className="deleteFavouriteListConfirmModal"
          title="ACHTUNG!"
          visible={isConfirmModalVisible}
          closable
          maskClosable={false}
          confirmLoading={isLoading}
          onOk={deleteSelectedFavouriteList}
          okText="Ja, löschen"
          onCancel={() => {
            setIsConfirmModalVisible(false);
          }}
          cancelText="Abbrechen"
          destroyOnClose
          zIndex={9999}
        >
          <p>
            Möchtest du die Favoritenliste wirklich löschen? Es befinden sich
            aktuell {favouriteListToDelete?.favourites.length} Artikel auf
            dieser Liste.
          </p>
        </Modal>
      </>
    );
  };

export default DeleteFavouriteListButton;
