import axios, { CancelTokenSource } from "axios";

import { loginRequestEndpoint, XHRheader } from "./endpoints";

interface PostLoginRequestProps {
  companyBusinessUnitKey: string;
  zipCode: string;
  cancelTokenSource: CancelTokenSource;
}

const postLoginRequest = async (props: PostLoginRequestProps) => {
  const { companyBusinessUnitKey, zipCode, cancelTokenSource } = props;

  return axios
    .post(
      loginRequestEndpoint,
      {
        data: {
          type: "customer-login-request",
          attributes: { companyBusinessUnitKey, zipCode },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": XHRheader.AcceptLanguage,
        },
      }
    )
    .then((response): Promise<any> => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data || {};

      return attributes;
    });
};

export default postLoginRequest;
