import React, { forwardRef, useRef, useState } from "react";
import { message, Modal } from "antd";
import { useSelector } from "react-redux";
import { ReactComponent as ScanIcon } from "../../static/svg/scan.svg";
import { ReactComponent as Back } from "../../static/svg/back.svg";
import ScannerView from "./ScannerView";
import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import formatDashedDateToLocale from "../../utils/formatDate";
import CartLinkButton from "../cart/CartLinkButton";

interface Props {
  isDisabled?: boolean;
}

type Ref = React.LegacyRef<HTMLButtonElement>;

/**
 * the scanner button and the modal housing the scanner component
 * @param isDisabled {boolean}
 * @param buttonRef {Ref}
 * @constructor
 */
function Scanner({ isDisabled }: Props, buttonRef: Ref) {
  const deliveryDate = useSelector(getCartDeliveryDateSlice);
  const [showScanner, setShowScanner] = useState(false);

  /*
   * this ref is used to destroy the init message of the scanner
   * if the user closes the scanner immediately and the scanner was loaded,
   * this message is not relevant anymore
   */
  const messageKeyRef = useRef(Date.now());

  /**
   * close the scanner and the wrapping modal
   */
  const closeScanner = () => {
    setShowScanner(false);

    message.destroy(messageKeyRef.current);
  };

  return (
    <>
      <button
        type="button"
        title="Scanner starten"
        className="buttonActionForInput buttonInput scanButton"
        disabled={isDisabled}
        onClick={() => setShowScanner(true)}
        ref={buttonRef}
      >
        <ScanIcon className="icon iconButtonInput withoutOpacity iconScan" />
      </button>

      {showScanner && (
        <Modal
          className="scannerOverlay"
          visible
          width={1000}
          footer={null}
          closable={false}
          maskClosable={false}
          title={
            <header className="scannerOverlayHeading">
              <button
                type="button"
                className="button buttonText buttonText--inverted"
                onClick={closeScanner}
              >
                <Back />
                zurück
              </button>

              <span className="scannerOverlayDay">
                Scan für den{" "}
                <time>{formatDashedDateToLocale(deliveryDate)}</time>
              </span>

              <button
                type="button"
                className="buttonActionForInput"
                onClick={closeScanner}
              >
                <CartLinkButton />
              </button>
            </header>
          }
        >
          <ScannerView keyOnReadyMessage={messageKeyRef.current} />
        </Modal>
      )}
    </>
  );
}

export default forwardRef(Scanner);
