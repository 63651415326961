import React from "react";
import { Col, Collapse, Row } from "antd";
import { OrderData } from "../../../types/orderData";
import OrderNote from "./OrderNote";
import { ReactComponent as Arrow } from "../../../static/svg/arrow.svg";
import HrDivider from "../../divider/Divider";

interface Props {
  orderNotes: OrderData["attributes"]["orderNotes"];
}

const OrderNotes = function OrderNotes({ orderNotes }: Props) {
  if (!orderNotes?.length) {
    return null;
  }

  const createOrderNotes = () =>
    orderNotes.map((note) => <OrderNote note={note} key={note.date} />);

  return (
    <>
      <Row>
        <Col span={12}>
          {orderNotes.length === 1 ? (
            <>{createOrderNotes()}</>
          ) : (
            <Collapse
              expandIcon={() => <Arrow className="icon iconCollapse" />}
              expandIconPosition="end"
              defaultActiveKey="1"
              className="orderNotes"
            >
              <Collapse.Panel
                key="1"
                header={
                  <header className="orderNotesHeading">
                    <h2 className="orderNotesHeadline">Notizen</h2>
                    <span className="orderNotesCount">
                      {orderNotes.length} Notizen
                    </span>
                  </header>
                }
              >
                {createOrderNotes()}
              </Collapse.Panel>
            </Collapse>
          )}
        </Col>
      </Row>

      <HrDivider size={2} spacingTop="l" spacingBottom="l" />
    </>
  );
};

export default OrderNotes;
