import localizedNumberFormat from "../../utils/localizedNumberFormat";

/**
 * parse product attribute values for visual representations
 * @param value {string}
 */
const parseProductAttributes = (value: string) => {
  /*
   * parse values of the format [0-9]{1,n}.[0-9]{2},
   * meaning any amount of digits but at least one single character
   * followed by an exact match for a dot "."
   * followed by exactly two more digits and NO Unit
   * If this is the case, the value get transformed to a localized number string
   */
  if (/^\d+\.\d+\s\w+$/gim.test(value)) {
    // value + unit
    const parts = value.split(" ");
    return `${localizedNumberFormat({ value: parseFloat(parts[0]) })} ${
      parts[1]
    }`;
  }

  if (/^\d+\.\d+$/gim.test(value)) {
    return localizedNumberFormat({ value: parseFloat(value) });
  }

  return value;
};

export default parseProductAttributes;
