import i18n from "i18next";

export interface TimeStamp {
  unixTime: number;
  localeTimeString: string;
}

/**
 * create timeStamp object
 * @param locale {string}
 * @returns {Object}
 */
const getLocaleTimeStamp = (locale = i18n.language) => {
  const now = Date.now();
  const localeTimeString = new Date(now).toLocaleTimeString(locale);

  return {
    timeStamp: {
      unixTime: Math.floor(now / 1000),
      jsTime: now,
      localeTimeString,
    },
  };
};

export default getLocaleTimeStamp;
