import { AuthToken } from "../state/reducers/authReducer";

/**
 * compares the delta of given time and the current one to a limit in ms
 * @param {AuthToken["expiresInMilliseconds"]} expiryTime
 * @param {number} limit
 */
const tokenCompareReturn = (
  expiryTime: AuthToken["expiresInMilliseconds"],
  limit = 0
) => (expiryTime ? expiryTime - new Date().getTime() <= limit : true);

/**
 * test if the token is expired at the time of calling
 * export to test in different places
 * pass the store token
 * @param {AuthToken["expiresInMilliseconds"]} expiryTime
 */
const isTokenExpired = (expiryTime: AuthToken["expiresInMilliseconds"]) =>
  tokenCompareReturn(expiryTime);

/**
 * test if the token needs to be refreshed,
 * currently the token will be refreshed, if the current one expires in the next hour
 * @param {AuthToken["expiresInMilliseconds"]} expiryTime
 */
const isTokenExpiring = (expiryTime: AuthToken["expiresInMilliseconds"]) => {
  // milliseconds * seconds * minutes = hour
  const expireLimit = 1000 * 60 * 60; // 1 hour

  return tokenCompareReturn(expiryTime, expireLimit);
};

export { isTokenExpired, isTokenExpiring };
