import queryString from "query-string";
import { store } from "../../store";
import objectHasKey from "../../utils/objectHasKey";
import { locationSearchQueryParameter } from "../../appConfig";
import { Filter, FilterValue } from "../../types/filters";

/**
 * get the filters from an URL to e.g. preselect filters and request only products matching the filter
 * @param {string} query
 * @param {boolean} parseBooleanValues
 * @return {Record<string, any>}
 */
const getFiltersFromUrl = (
  query = window.location.search,
  parseBooleanValues = true
) => {
  const params: any = queryString.parse(query);
  const paramFilters: any = {};
  const { filterConjunction, searchTerm, page } = locationSearchQueryParameter;

  Object.entries(params).forEach(([paramName, paramValue]: [string, any]) => {
    // typecheck for stringified bool
    if (paramName !== page && paramName !== searchTerm) {
      if (paramValue === "1") {
        paramFilters[paramName] = parseBooleanValues
          ? !!paramValue
          : paramValue;
      } else if (paramValue === "0") {
        paramFilters[paramName] = parseBooleanValues ? !paramValue : paramValue;
      } else if (paramValue?.length > 0) {
        paramFilters[paramName] = paramValue.split(filterConjunction);
      }
    }
  });

  return paramFilters;
};

/**
 * retrieve filterStates from redux and current url.
 * url filters are higher valued
 * @param {boolean} parseBooleanValues
 * @return {Object}
 */
const getFilterStates = (parseBooleanValues = true) => {
  const storedFilterStates =
    store.getState().productsMetaData.filters.filterStates;
  const availableFilters =
    store.getState()?.productsMetaData?.filters?.availableFilters || [];
  const urlFilters = getFiltersFromUrl(
    window.location.search,
    parseBooleanValues
  );

  // ignore stored filter if url differs (omitted)
  Object.keys(storedFilterStates).forEach((key) => {
    if (!objectHasKey(urlFilters, key)) {
      delete storedFilterStates[key];
    }
  });

  // Edge-case: handle multivalue boolean filters
  Object.entries(urlFilters).forEach(([key, value]) => {
    if (typeof value !== "boolean") {
      return;
    }

    const availableFilter = availableFilters.find(
      (filter: Filter) => filter?.name === key
    );

    if (!availableFilter?.config?.isMultiValued) {
      return;
    }

    urlFilters[key] = [value ? "1" : "0"];
  });

  return { ...storedFilterStates, ...urlFilters };
};

/**
 * get filterset of passed record without the currently default filters
 * @param {Record<string, string|string[]>} filterData
 */
const getFiltersWithoutDefaultParams = (
  filterData: Record<string, string | string[]>
) => {
  const {
    deliveryDate,
    deliveryDateFrom,
    deliveryDateTo,
    page,
    sortBy,
    sortDirection,
  } = locationSearchQueryParameter;

  const {
    [deliveryDate]: tempDeliveryDate,
    [deliveryDateFrom]: tempDeliveryDateFrom,
    [deliveryDateTo]: tempDeliveryDateTo,
    [page]: tempPage,
    [sortBy]: tempSortBy,
    [sortDirection]: tempSortDirection,
    ...restFilters
  } = filterData;

  return restFilters;
};

/**
 * wrapper to reduce code for multiple calling instances
 * @param {boolean} parseBooleanValues
 * @returns {Function<getFilterStatesWithoutDefaults>}
 */
const getFilterStatesWithoutDefaults = (parseBooleanValues = true) =>
  getFiltersWithoutDefaultParams(getFilterStates(parseBooleanValues));

/**
 * create a set of available filters and active filters
 * @param {Array} valueFacets
 * @param {Array} rangeFacets
 * @param {Array} slots
 * @return {Object}
 * @return {Object}
 */
const getProductsFilters = (
  valueFacets: any[] = [],
  rangeFacets: any[] = [],
  slots: any[] = []
) => {
  const booleanFilters = rangeFacets.filter(
    (currentRangeFilter: any) =>
      currentRangeFilter.min < 2 && currentRangeFilter.max < 2
  );

  // Sort filter values by label or value asc
  valueFacets.forEach((filter: Filter) => {
    const field =
      filter?.values?.length &&
      Object.keys(filter?.values?.[0] || {}).includes("label")
        ? "label"
        : "value";
    filter.values.sort((a: FilterValue, b: FilterValue) => {
      // eslint-disable-next-line no-nested-ternary
      return (a?.[field] || "")?.toLowerCase() <
        (b?.[field] || "")?.toLowerCase()
        ? -1
        : (a?.[field] || "").toLowerCase() > (b?.[field] || "").toLowerCase()
        ? 1
        : 0;
    });
  });

  const availableFilters = [...valueFacets, ...booleanFilters];

  return {
    availableFilters,
    filterStates: getFilterStates(),
    slots,
  };
};

export { getFilterStates, getProductsFilters, getFilterStatesWithoutDefaults };
