import axiosWithAuth from "../axiosWithAuth";
import { getProductsDataFromFavouriteListEndpoint } from "../endpoints";
import getDeliveryDateQuery from "../getDeliveryDateQuery";
import getCatalogSearchAttributesData from "../products/getCatalogSearchAttributesData";
import { GetUserListProducts } from "../../types/userListProducts";
import getPaginationParams from "../../utils/getPaginationParams";
import storeDispatch from "../../state/actions/storeDispatch";
import { getProductsFilters } from "../products/getProductsFilters";
import createFilterString from "../../utils/createFilterString";

/**
 * retrieve concrete product data for products on favouritesList
 * @param {CancelTokenSource} cancelTokenSource
 * @param {string} deliveryDate
 * @param {string} favouriteListId
 * @param {Record<string, any>} requestFilters
 * @param {number} page
 * @param {number} pageSize
 * @param {string} sortBy
 * @param {string} sortDirection
 * @return {Promise<AxiosResponse|Array<ProductData|empty>>}
 */
const getProductsOnFavouritesList = async ({
  cancelTokenSource,
  deliveryDate,
  favouriteListId,
  requestFilters,
  page,
  pageSize,
  sortBy,
  sortDirection,
}: GetUserListProducts) => {
  const deliveryDateAppendix = getDeliveryDateQuery(deliveryDate, true);
  const favouriteListAppendix = favouriteListId
    ? `&favouriteList=${favouriteListId}`
    : "";
  const paginationAppendix = getPaginationParams(page, pageSize);

  const filterString = createFilterString(requestFilters);

  const sortString =
    sortBy && sortDirection ? `&sort=${sortBy}_${sortDirection}` : "";

  return axiosWithAuth()
    .get(
      `${getProductsDataFromFavouriteListEndpoint}${deliveryDateAppendix}${filterString}${favouriteListAppendix}${paginationAppendix}${sortString}`,
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (!response?.data?.data || response.status > 200) {
        Promise.reject(response);
      }

      const {
        concreteProducts,
        favouriteList,
        pagination,
        attributeNames,
        rangeFacets,
        slots,
        valueFacets,
      } = getCatalogSearchAttributesData(response?.data?.data);

      // store attribute names to redux
      storeDispatch("products/set-attribute-names", attributeNames);

      const filterData = getProductsFilters(valueFacets, rangeFacets, slots);

      // write new filters to Redux
      storeDispatch("filter/set-product-filter", filterData);

      return {
        concreteProducts: concreteProducts || [],
        favouriteList: favouriteList || [],
        pagination: pagination || {},
      };
    });
};

export default getProductsOnFavouritesList;
