import React, { useCallback, useMemo, useState } from "react";

import clsx from "clsx";
import { Button, ConfigProvider, Empty, message, Table, Tooltip } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { columns, subColumns } from "./columns";
import { itemsPerPage, messageData } from "../../../../appConfig";
import { DocumentType } from "../../../../types/orderAPI";
import getCancelTokenSource from "../../../../api/getCancelTokenSource";
import getOrderInvoiceFile from "../../../../api/order/getOrderInvoiceFile";
import { ReactComponent as Download } from "../../../../static/svg/download.svg";
import Price from "../../../price/Price";
import HrDivider from "../../../divider/Divider";

interface OrdersTableProps {
  orders: Array<any>;
  total: number;
  page: number;
  isLoading: boolean;
  documentType: DocumentType;
  className?: string;
  emptyDescription?: JSX.Element;
  onTableChange?: (pagination: any, filters: any, sorter: any) => void;
  updateSelectedRows?: (rows: Record<any, any>[], selected: boolean) => void;
}

const OrdersTable: React.FC<OrdersTableProps> = (props: OrdersTableProps) => {
  const {
    orders,
    total,
    page,
    isLoading,
    documentType,
    className,
    emptyDescription,
    onTableChange,
    updateSelectedRows,
  } = props;

  const [downloadStates, setDownloadStates] = useState<string[]>([]);
  const [cartNoteOpenStates, setCartNoteOpenStates] = useState<string[]>([]);

  const tableDataSource = useMemo(
    () =>
      orders.map((orderData: any) => ({
        ...orderData,
        key: orderData.orderId,
      })),
    [orders]
  );

  /**
   * fires on every single row, even grouping rows
   * @param record {Record<any, any>}
   * @param selected {boolean}
   */
  const onSelect = (record: Record<any, any>, selected: boolean) => {
    /*
     * if the record has children, its a wrapping row, so pass the children
     * otherwise it is a single item
     */
    if (record?.children?.length) {
      updateSelectedRows(record.children, selected);
    } else {
      updateSelectedRows([record], selected);
    }
  };

  /**
   * clear or set all items
   * @param selected {boolean}
   * @param selectedRows {Record<any, any>>[]}
   * @param changeRows {Record<any, any>>[]}
   */
  const onSelectAll = (
    selected: boolean,
    selectedRows: Record<any, any>[],
    changeRows: Record<any, any>[]
  ) => {
    const itemRows = changeRows.filter((row: any) => !row?.children);

    updateSelectedRows(itemRows, selected);
  };

  const renderOrderNumberColumn = useCallback(
    (invoiceNumber: string) => {
      const cancelTokenSource = getCancelTokenSource();
      return (
        <h3 style={{ margin: 0 }} className="flex">
          {invoiceNumber}
          <Tooltip
            title="Rechnung als PDF Datei herunterladen"
            placement="right"
          >
            <Button
              icon={<Download />}
              loading={downloadStates.includes(invoiceNumber)}
              className="button buttonText download-button"
              type="text"
              onClick={() => {
                setDownloadStates((prevState: string[]) => [
                  ...prevState,
                  invoiceNumber,
                ]);
                getOrderInvoiceFile({
                  invoiceNumber,
                  cancelTokenSource,
                })
                  .then(() => {
                    setDownloadStates((prevState: string[]) =>
                      prevState.filter((item: string) => item !== invoiceNumber)
                    );
                    message.success(
                      messageData.success.orders.invoiceDownloadSuccess
                    );
                  })
                  .catch(() => {
                    setDownloadStates((prevState: string[]) =>
                      prevState.filter((number) => number !== invoiceNumber)
                    );
                    message.error(
                      messageData.error.orders.invoiceNotAvailableError
                    );
                  });
              }}
            />
          </Tooltip>
        </h3>
      );
    },
    [downloadStates]
  );

  const renderCartNoteColumn = useCallback(
    (cartNote: string, row: any) => {
      if (row?.orderNumber || !cartNote) {
        return "-";
      }

      const orderId = row?.orderId || "";
      const cartNotePreview =
        cartNote?.length >= 25 ? `${cartNote.slice(0, 25)}...` : cartNote;

      return (
        <Tooltip
          className="cart-note-column"
          title={
            <>
              <h4>Notiz an mich</h4>
              <HrDivider spacingBottom="s" />
              {cartNote}
              <p className="flex flex-col">
                <Button
                  className="button buttonText self-end"
                  onClick={() => {
                    setCartNoteOpenStates((prevState: string[]) =>
                      prevState.filter((item: string) => item !== orderId)
                    );
                  }}
                >
                  Schließen
                </Button>
              </p>
            </>
          }
          visible={cartNoteOpenStates.includes(orderId)}
          onClick={() => {
            setCartNoteOpenStates((prevState: string[]) => [
              ...prevState,
              orderId,
            ]);
          }}
        >
          {cartNotePreview}
        </Tooltip>
      );
    },
    [cartNoteOpenStates]
  );

  const tableColumns = useMemo(
    () =>
      columns[documentType].map((column: any) => ({
        ...column,
        ...(documentType === "INVOICE" && column?.dataIndex === "orderNumber"
          ? {
              render: (invoiceNumber: string) =>
                renderOrderNumberColumn(invoiceNumber),
            }
          : {}),
        ...(documentType === "SHOPORDER" && column?.dataIndex === "cartNote"
          ? {
              render: (cartNote: string, row: any) =>
                renderCartNoteColumn(cartNote, row),
            }
          : {}),
        ...(documentType === "ORDER" && column?.dataIndex === "cartNote"
          ? {
              render: (cartNote: string, row: any) =>
                renderCartNoteColumn(cartNote, row),
            }
          : {}),
      })),
    [documentType, renderOrderNumberColumn, renderCartNoteColumn]
  );

  const renderEmpty = () => (
    <Empty
      className="mb-3xl"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={emptyDescription}
    />
  );

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <Table
        className={clsx("productItemTable", "order-table", className)}
        rowClassName={(record: any) =>
          clsx(
            !record.orderNumber && !record?.orderItems?.length && "is-merged"
          )
        }
        sticky
        showSorterTooltip={false}
        columns={tableColumns}
        dataSource={tableDataSource}
        scroll={{ x: "max-content" }}
        tableLayout="fixed"
        pagination={{
          position: ["bottomCenter"],
          total,
          pageSize: itemsPerPage.orderList,
          current: page,
          showSizeChanger: false,
        }}
        onChange={onTableChange}
        loading={isLoading && { size: "large" }}
        expandable={{
          showExpandColumn:
            documentType === "ORDER" || documentType === "SHOPORDER",
          rowExpandable: (order: any) => !!order?.orderItems?.length,
          expandedRowRender: (order) => {
            return (
              <Table
                className="order-table__sub-table"
                scroll={{ x: "max-content" }}
                tableLayout="fixed"
                columns={subColumns}
                rowSelection={{
                  onSelect,
                  onSelectAll,
                  checkStrictly: false,
                }}
                dataSource={order?.orderItems.map(
                  (orderItemData: any, i: number) => ({
                    ...orderItemData,
                    // We need a unique key on DOM elements
                    key: `${order?.orderId}_${orderItemData?.sku}_${i}`,
                  })
                )}
                pagination={false}
                size="small"
                footer={() => (
                  <div className="order-table__sub-table__footer">
                    <h5>Gesamtsumme</h5>
                    <Price
                      price={
                        order?.totals?.discountTotal ||
                        order?.totals?.subtotal ||
                        0
                      }
                    />
                  </div>
                )}
              />
            );
          },
          expandIcon: ({ expanded, onExpand, record }) => {
            if (!record?.orderItems?.length) {
              return null;
            }
            return expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            );
          },
        }}
      />
    </ConfigProvider>
  );
};

export default OrdersTable;
