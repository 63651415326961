import { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { Entry } from "contentful";

import getWebshopCategoryVideos from "../api/contentful/getWebshopCategoryVideos";
import handleError from "../utils/handleError";
import { ContentfulWebshopCategoryVideoEntry } from "../types/contentfulWebshopCategoryVideoEntry";
import { StockType } from "../types/stock";

interface GetWebshopCategoryVideosProps {
  categoryIds: string;
  stockName: StockType;
  fallbackCategoryIds?: string;
  limit?: number;
}

const useGetWebshopCategoryVideos = (props: GetWebshopCategoryVideosProps) => {
  const { categoryIds, stockName, limit = 1 } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<
    Entry<ContentfulWebshopCategoryVideoEntry>[]
  >([]);
  const [total, setTotal] = useState<number>(null);

  const fetchCategoryVideos = useCallback(async () => {
    if (categoryIds === null) {
      return;
    }

    setIsLoading(true);

    getWebshopCategoryVideos({
      categoryIds,
      stockName,
    })
      .then((response) => {
        const categoryIdsForSorting = categoryIds.split(",");
        const items =
          response?.items
            ?.sort(
              (
                a: Entry<ContentfulWebshopCategoryVideoEntry>,
                b: Entry<ContentfulWebshopCategoryVideoEntry>
              ) => {
                return (
                  categoryIdsForSorting.indexOf(a?.fields?.category) -
                  categoryIdsForSorting.indexOf(b?.fields?.category)
                );
              }
            )
            ?.slice(0, limit) || [];
        setEntries(items);
        setTotal(items?.length || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [categoryIds, limit, stockName]);

  useEffect(() => {
    fetchCategoryVideos().then();
  }, [fetchCategoryVideos]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetWebshopCategoryVideos;
