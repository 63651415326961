import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { favouriteListEndpoint } from "../endpoints";

interface PatchFavouriteListNote {
  cancelTokenSource: CancelTokenSource;
  notes: string;
  listName: string;
}

/**
 * patch the note of a favourite list
 * @param {CancelTokenSource} cancelTokenSource
 * @param {string} notes
 * @param {string} listName
 * @returns {Promise<any>}
 */
const patchFavouriteListNote = ({
  cancelTokenSource,
  notes,
  listName,
}: PatchFavouriteListNote) => {
  return axiosWithAuth()
    .patch(
      `${favouriteListEndpoint}${listName}`,
      {
        data: {
          type: "favourite-lists",
          attributes: {
            notes,
          },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (!response?.data || response.status > 201) {
        Promise.reject(response);
      }

      return response?.data?.data?.attributes?.notes;
    });
};

export default patchFavouriteListNote;
