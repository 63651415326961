import React, { forwardRef } from "react";

import clsx from "clsx";

import { Collapse, Empty, Result, Spin } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { ContentBlock } from "../../../atoms";
import useGetSupportFaq from "../../../../hooks/useGetSupportFaq";
import Picture from "../../../picture/Picture";
import renderContentfulDocument from "../../../../utils/renderContentfulDocument";
import { itemsPerPage } from "../../../../appConfig";
import { ContentfulSupportFaqEntry } from "../../../../types/contentfulSupportFaqEntry";
import { ReactComponent as Arrow } from "../../../../static/svg/arrow.svg";
import SupportFAQImage from "../../../../static/images/support-faq.png";

const { Panel } = Collapse;

interface SupportFaqProps {
  className?: string;
}

type Ref = HTMLDivElement;

const SupportFaq: React.FC<SupportFaqProps> = forwardRef<Ref, SupportFaqProps>(
  (props, ref) => {
    const { className } = props;

    const { isLoading, hasError, entries } = useGetSupportFaq(
      itemsPerPage.supportFaq,
      0
    );

    return (
      <div ref={ref} className={clsx("support-faq", className)}>
        <Spin spinning={isLoading} tip="Lade FAQ...">
          <ContentBlock
            title="Fragen & Antworten (FAQ)"
            icon={<Picture imageScreenSm={SupportFAQImage} />}
            className="support-page__faq"
          >
            {!isLoading &&
              !hasError &&
              Array.isArray(entries) &&
              !!entries?.length && (
                <Collapse
                  accordion
                  expandIcon={({ isActive }) => (
                    <Arrow
                      style={{
                        transform: isActive
                          ? "rotate(180deg)"
                          : "rotate( 0deg)",
                      }}
                    />
                  )}
                  expandIconPosition="end"
                  ghost
                >
                  {entries?.map((entry: ContentfulSupportFaqEntry) => (
                    <Panel
                      header={entry.question || ""}
                      key={`support-faq_${entry.id}`}
                      extra={<QuestionCircleOutlined />}
                    >
                      {renderContentfulDocument(entry.answer)}
                    </Panel>
                  ))}
                </Collapse>
              )}

            {!isLoading &&
              !hasError &&
              (!Array.isArray(entries) || !entries?.length) && (
                <Empty
                  description="Aktuell sind keine FAQ verfügbar"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}

            {!isLoading && !!hasError && (
              <Result status="error" title="Fehler beim Laden der FAQ" />
            )}
          </ContentBlock>
        </Spin>
      </div>
    );
  }
);

export default SupportFaq;
