import { Entry } from "contentful";
import moment from "moment/moment";

import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import stockNameMapping from "../../utils/stockNameMapping";
import { ContentfulAdProductListingEntry } from "../../types/contentfulAdProductListingEntry";
import { StockType } from "../../types/stock";

interface AdProductListingParams {
  categoryIds: string;
  limit?: number;
  skip?: number;
  withTotal?: boolean;
  stockName?: StockType;
}

interface AdProductListingResponse {
  items: Entry<ContentfulAdProductListingEntry>[];
  total: number;
}

/**
 * @param categoryIds
 * @param limit
 * @param skip
 * @param withTotal
 * @param stockName
 * @return Promise<any>
 */
const getAdProductListing = async ({
  categoryIds,
  limit = 1,
  skip = 0,
  withTotal = true,
  stockName = "HL",
}: AdProductListingParams): Promise<AdProductListingResponse> =>
  getContentfulContentType({
    ...contentfulContentTypes.adProductListing,
    limit,
    skip,
    order: "sys.createdAt",
    inclusion: {
      "fields.category[in]": categoryIds,
      "fields.start_date[lte]": moment().toISOString(),
      "fields.end_date[gte]": moment().toISOString(),
      "fields.stock_name[in]": `Alle,${stockNameMapping?.[stockName] || ""}`,
    },
    withTotal,
  }).then((response: any) => {
    if (!response) {
      return Promise.reject(response);
    }

    return {
      items: response?.items || [],
      total: response.total,
    } as AdProductListingResponse;
  });

export default getAdProductListing;
