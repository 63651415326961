import React, { ReactNode } from "react";
import clsx from "clsx";
import HrDivider from "../../divider/Divider";
import { ProductData } from "../../../types/productData";
import getDeliveryDateBasedAttributes from "../getDeliveryDateBasedAttributes";

interface Props {
  children?: ReactNode;
  deliveryDate: ProductData["deliveryDate"];
  productData: ProductData;
}
/**
 * Product Tile wrapper to have consistency over classes etc.
 * @param children {ReactNode}
 * @param deliveryDate {string}
 * @param productData {ProductData}
 * @constructor
 */
const ProductTileWrapper: React.FC<Props> = ({
  children,
  deliveryDate,
  productData,
}: Props) => {
  const { availabilities } = productData;
  const { isUnavailable, nextAvailability } = getDeliveryDateBasedAttributes({
    deliveryDate,
    availabilities,
  });

  return (
    <div
      className={clsx("productTile", {
        isUnavailable,
        hasNextAvailability: nextAvailability,
      })}
    >
      {children}

      <HrDivider className="self-end" spacingTop="xs" />
    </div>
  );
};

export default ProductTileWrapper;
