import React from "react";
import clsx from "clsx";

import { CheckboxFilter, SelectFilter } from "../../atoms";

import { Filter as FilterInterface } from "../../../types/filters";

interface DynamicFilterProps extends FilterInterface {
  display?: "inline" | "filter-bar" | "sidebar";
  view?: string;
}

const DynamicFilter: React.FC<DynamicFilterProps> = (props) => {
  const { name, values, display = "inline", view = "" } = props;

  function FilterContent() {
    if (Array.isArray(values)) {
      return <SelectFilter {...props} display={display} view={view} />;
    }

    if (!Array.isArray(values)) {
      return <CheckboxFilter {...props} display={display} view={view} />;
    }

    return <div>No FilterFormat available</div>;
  }

  return (
    <div className={clsx("dynamic-filter")} data-filtername={name || ""}>
      <FilterContent />
    </div>
  );
};

export default DynamicFilter;
