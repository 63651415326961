import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import getDefaultImageUrlSet from "../../utils/getDefaultImageUrlSet";
import { productAttributes } from "../../api/productAttributes";
import { ProductImage, ProductPackageInfo, ProductTitle } from "../product";
import { ProductData } from "../../types/productData";
import ProductPrices from "../product/ProductPrices";
import getDeliveryDateBasedAttributes from "../product/getDeliveryDateBasedAttributes";
import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import ProductAttributes from "../product/ProductAttributes";

interface Props {
  productData: ProductData;
  classNames?: string;
}

const ProductSliderImage: React.FC<Props> = ({
  productData,
  classNames,
}: Props) => {
  const { attributes, availabilities, prices, sku, name, imageSets } =
    productData;

  const { [productAttributes.caliber]: productCaliber } = attributes;

  // find sku in orders and cart and sum quantity
  const totalQuantity = useSelector((state: any) => {
    let orderedAndCartQuantity = 0;
    const {
      currentCartMetaData,
      currentCart: { cartItems },
      alreadyOrdered,
    } = state;

    const { deliveryDate } = currentCartMetaData;

    orderedAndCartQuantity +=
      alreadyOrdered?.orderItemIndex?.[sku]?.[deliveryDate] || 0;
    orderedAndCartQuantity +=
      cartItems.find((cartEntry: any) => cartEntry.sku === sku)?.quantity || 0;

    return orderedAndCartQuantity;
  });

  const deliveryDate = useSelector(getCartDeliveryDateSlice);
  const { isUnavailable, nextAvailability } = getDeliveryDateBasedAttributes({
    deliveryDate,
    availabilities,
  });
  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.weighingArticle]: weighingArticle,
  } = attributes;

  return (
    <article
      className={clsx("productSliderItem", {
        classNames,
        isUnavailable,
      })}
    >
      <ProductImage
        imageSet={getDefaultImageUrlSet(imageSets)}
        sku={sku}
        alt={name}
        imageType="productSlider"
        className={clsx({ isUnavailable: isUnavailable && !nextAvailability })}
      />

      <ProductTitle
        availabilities={availabilities}
        deliveryDate={deliveryDate}
        sku={sku}
        title={name}
      />

      <div className="productInfo">
        <ProductAttributes
          attributes={attributes}
          sku={sku}
          deliveryDate={deliveryDate}
        />

        <ProductPackageInfo
          productUnitMeasurement={productUnitMeasurement}
          productUnitQuantity={productUnitQuantity}
          sku={sku}
        />

        <div className="productAttributes">{productCaliber}</div>
      </div>

      <div className="productPriceInfo">
        <ProductPrices
          prices={prices}
          deliveryDate={deliveryDate}
          quantity={totalQuantity}
          basePriceQuantity={productUnitQuantity}
          weighingArticle={weighingArticle}
          sku={sku}
        />
      </div>
    </article>
  );
};

export default ProductSliderImage;
