import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { stocktakingItemEndpoint } from "../endpoints";

interface DeleteStocktakingItemProps {
  stocktakingItemId: number;
  cancelTokenSource: CancelTokenSource;
}

const deleteStocktakingItem = async (props: DeleteStocktakingItemProps) => {
  const { stocktakingItemId, cancelTokenSource } = props;

  const url = `${stocktakingItemEndpoint}/${stocktakingItemId}`;

  return axiosWithAuth()
    .delete(url, { cancelToken: cancelTokenSource.token })
    .then((response): Promise<any> | boolean => {
      if (response.status !== 204) {
        return Promise.reject(response);
      }

      return true;
    });
};

export default deleteStocktakingItem;
