import moment from "moment/moment";
import { Episode, Podcast } from "../types/podcast";

const CDATA_REGEXP = /<!\[CDATA\[(.*)\]\]>/is;

const episodesMapper = (items: NodeList): Episode[] => {
  const episodes: Episode[] = [];
  items.forEach((item: any) => {
    const pubDate = moment(item.querySelector("pubDate")?.innerHTML || "");
    const content = item.querySelector("encoded").innerHTML || "";
    const match = content.match(CDATA_REGEXP)?.[1] || "";
    const parts = match.split("\n");
    const subtitle = parts?.[0];
    // const description = parts.slice(1).join().trim();

    const episode: Episode = {
      title: item.querySelector("title")?.innerHTML || "",
      subtitle: subtitle || item.querySelector("subtitle")?.innerHTML || "",
      description: item.querySelector("description")?.innerHTML || "",
      image: item.querySelector("image")?.getAttribute("href") || "",
      link: item.querySelector("link")?.innerHTML || "",
      keywords: item.querySelector("keywords")?.innerHTML || "",
      mp3Link: item.querySelector("enclosure")?.getAttribute("url") || "",
      duration: parseInt(item.querySelector("duration")?.innerHTML || 0, 10),
      pubDate: pubDate.isValid() ? pubDate.format("DD.MM.YYYY HH:mm") : null,
    };
    episodes.push(episode);
  });
  return episodes;
};

const podcastMapper = (data: Document): Podcast => {
  const podcast: Podcast = {
    title: data.querySelector("title")?.innerHTML || "",
    description: data.querySelector("description")?.innerHTML || "",
    image: data.querySelector("image url")?.innerHTML || "",
    link: data.getElementsByTagName("link")[0]?.innerHTML || "",
    keywords: data.querySelector("keywords")?.innerHTML || "",
    episodes: episodesMapper(data.querySelectorAll("item")),
  };
  return podcast;
};

export default podcastMapper;
