import React, { useMemo } from "react";

import clsx from "clsx";
import moment from "moment";

import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import useGetDeliveryDate from "../../hooks/useGetDeliveryDate";
import { apiDateFormat } from "../../utils/dateFormats";
import { RootState } from "../../types/rootState";
import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";

interface IProductInfoBadge {
  view: "list" | "weekplanner" | "detail";
  className?: string;
  advertisement?: string;
  isNewArticle?: string;
  deprecatedSku?: string;
  isArticlePosterAvailable?: string;
  getContainer?: () => HTMLElement;
}

interface NewArticleInterval {
  from: string;
  to: string;
}

/**
 * component determines which badge will be shown
 * @param view {"list" | "weekplanner" | "detail"}
 * @param className {string}
 * @param advertisement {string}
 * @param isNewArticle {string}
 * @param deprecatedSku {string}
 * @param articlePoster {string};
 * @param getContainer
 * @constructor
 */
const ProductInfoBadge: React.FC<IProductInfoBadge> = ({
  view,
  className,
  advertisement = "",
  isNewArticle,
  deprecatedSku,
  isArticlePosterAvailable = "0",
  getContainer,
}: IProductInfoBadge) => {
  const todayString = moment().format(apiDateFormat);
  const [deliveryDate] = useGetDeliveryDate();
  const weekDates = useSelector(
    (state: RootState) => state?.weekplannerData?.weekDates || []
  );
  const { weekplannerUseSimpleMode } = useSelector(
    (state: RootState) => state?.userData || {}
  );

  const isSmallWeekplanner = useMedia(
    `(max-width: ${getCssVariable("screen-lg-max")})`
  );

  const referenceDate: moment.Moment = useMemo(() => {
    if (view !== "weekplanner") {
      return moment(deliveryDate);
    }

    const startDate = moment(weekDates?.[0]?.apiDate || null);

    if (!startDate.isValid()) {
      return moment(deliveryDate);
    }

    return startDate;
  }, [view, deliveryDate, weekDates]);

  const advertisementText: string = useMemo(() => {
    let parsedAdvertisement: any = null;

    try {
      parsedAdvertisement =
        typeof advertisement === "object"
          ? advertisement
          : JSON.parse(advertisement);
    } catch (e) {
      return advertisement;
    }

    if (!Array.isArray(parsedAdvertisement)) {
      parsedAdvertisement = [parsedAdvertisement];
    }

    const activeAdvertisement: object =
      (parsedAdvertisement as Array<object>).find((obj: object) => {
        const advertisementPeriod = Object.values(obj)?.[0]?.[0] || {};
        const { from: activeFrom, to: activeTo } = advertisementPeriod;

        const isReferenceDateInActiveDateRange = referenceDate.isBetween(
          moment(activeFrom),
          moment(activeTo),
          "day",
          "[]"
        );

        if (view !== "weekplanner") {
          return isReferenceDateInActiveDateRange;
        }

        return (
          isReferenceDateInActiveDateRange ||
          moment(activeFrom).week() === referenceDate.week() ||
          moment(activeTo).week() === referenceDate.week()
        );
      }) || {};

    return (Object.keys(activeAdvertisement)?.[0] as string) || "";
  }, [advertisement, referenceDate, view]);

  const advertisementTextParts =
    advertisementText.length > 0 ? advertisementText.split("\n") : [];

  const shouldShowAdvertisement = advertisementTextParts?.length > 0;

  const newCondition = useMemo((): boolean => {
    if (typeof isNewArticle === "object") {
      const newInterval: NewArticleInterval = isNewArticle?.[1]?.[0];

      if (!newInterval || !newInterval?.from || !newInterval?.to) {
        return false;
      }

      return moment(todayString).isBetween(
        moment(newInterval.from),
        moment(newInterval.to),
        "day",
        "[]"
      );
    }
    return isNewArticle === "1";
  }, [todayString, isNewArticle]);

  // advertisement badge
  const advertBadge = shouldShowAdvertisement ? (
    <div
      className={clsx(
        "overlayInfoBadge productAdvertBadge",
        `${view}InfoBadge`,
        view === "weekplanner" &&
          !isSmallWeekplanner &&
          "weekplanner-advert-badge--bottom",
        (view === "detail" || view === "list") && "product-poster-badge",
        className
      )}
    >
      {view === "detail" && (
        <div className="product-detail-view">
          <p>
            <span className="text-bold">
              {advertisementTextParts?.[0] || ""}
            </span>
            <br />
            <span>{advertisementTextParts?.[1] || ""}</span>
          </p>
          {isArticlePosterAvailable === "1" && (
            <div className="poster-badge text-bold">Poster</div>
          )}
        </div>
      )}

      {view === "list" && (
        <>
          <p>
            <span className="text-bold">
              {advertisementTextParts?.[0] || ""}
            </span>
            <br />
            <span>{advertisementTextParts?.[1] || ""}</span>
          </p>
          {isArticlePosterAvailable === "1" && (
            <div className="product-list-view text-bold">Poster</div>
          )}
        </>
      )}

      {view === "weekplanner" && (
        <Tooltip
          placement={
            isSmallWeekplanner && !weekplannerUseSimpleMode ? "top" : "right"
          }
          getTooltipContainer={getContainer}
          title={
            <p>
              <span className="text-bold">
                {advertisementTextParts?.[0] || ""}
              </span>
              <br />
              <span>{advertisementTextParts?.[1] || ""}</span>
            </p>
          }
          trigger={["hover", "click"]}
          destroyTooltipOnHide
        >
          <div
            className={clsx(
              "product-advert-badge__weekplanner--default",
              isSmallWeekplanner &&
                !weekplannerUseSimpleMode &&
                "badge__small-screen",
              weekplannerUseSimpleMode && "weekplanner__simple-view"
            )}
          >
            Aktion
          </div>
        </Tooltip>
      )}
    </div>
  ) : null;

  const weekplannerPosterBadge =
    view === "weekplanner" &&
    shouldShowAdvertisement &&
    isArticlePosterAvailable === "1" ? (
      <div
        className={clsx(
          "overlayInfoBadge",
          !weekplannerUseSimpleMode &&
            "product-poster-badge default-weekplanner-view",
          `${view}InfoBadge`,
          className
        )}
      >
        <span
          className={clsx(
            "weekplanner-view",
            isSmallWeekplanner &&
              !weekplannerUseSimpleMode &&
              "badge__small-screen text-bold",
            weekplannerUseSimpleMode && "poster-badge"
          )}
        >
          Poster
        </span>
      </div>
    ) : null;

  // newArticleBadge
  const newArticleBadge =
    newCondition && !deprecatedSku ? (
      <div
        className={clsx(
          "overlayInfoBadge productNewBadge",
          `${view}InfoBadge`,
          isSmallWeekplanner &&
            !weekplannerUseSimpleMode &&
            view === "weekplanner" &&
            "badge__small-screen",
          className
        )}
      >
        <p>NEU</p>
      </div>
    ) : null;

  // followUpBadge
  const followUpBadge =
    newCondition && deprecatedSku ? (
      <div
        className={clsx(
          "overlayInfoBadge productFollowupBadge",
          `${view}InfoBadge`,
          isSmallWeekplanner &&
            !weekplannerUseSimpleMode &&
            view === "weekplanner" &&
            "badge__small-screen",
          className
        )}
      >
        <p>FOLGE</p>
      </div>
    ) : null;

  if (weekplannerUseSimpleMode && view === "weekplanner") {
    return (
      <div className="weekplanner__simple-mode--badges">
        {advertBadge}
        {weekplannerPosterBadge}
        {newArticleBadge}
        {followUpBadge}
      </div>
    );
  }

  if (
    view === "weekplanner" &&
    !weekplannerUseSimpleMode &&
    isSmallWeekplanner
  ) {
    return (
      <div className="weekplanner--small-screen">
        {advertBadge}
        {weekplannerPosterBadge}
        {newArticleBadge}
        {followUpBadge}
      </div>
    );
  }

  return (
    <>
      {advertBadge}
      {weekplannerPosterBadge}
      {newArticleBadge}
      {followUpBadge}
    </>
  );
};

export default ProductInfoBadge;
