import { CancelTokenSource } from "axios";
import * as Sentry from "@sentry/react";

import axiosWithAuth from "./axiosWithAuth";
import getUserTermsAndConditionsState from "./termsAndConditions/getUserTermsAndConditionsState";
import storeDispatch from "../state/actions/storeDispatch";
import { getCustomerEndpoint } from "./endpoints";

/**
 * retrieve all needed user data and store them
 * if the terms and conditions endpoint does not work properly
 * this function mimics a default response to fail gracefully
 * and do not hinder the user
 * @param cancelTokenSource {CancelTokenSource}
 * @param promises {any[]}
 */
const getUserData = (
  cancelTokenSource: CancelTokenSource,
  promises: any[] = []
) => {
  // retrieve customer
  const getCustomer = axiosWithAuth().get(getCustomerEndpoint, {
    cancelToken: cancelTokenSource.token,
  });

  const userTermsAndConditionsState =
    getUserTermsAndConditionsState(cancelTokenSource);

  return Promise.all([
    getCustomer,
    userTermsAndConditionsState,
    ...promises,
  ]).then((responses: any) => {
    const [customerResponse, userTaCStateResponse] = responses;

    if (customerResponse?.status !== 200) {
      return Promise.reject(customerResponse);
    }

    const {
      data: { data, included },
    } = customerResponse;

    const attributes = data?.length > 0 && data[0]?.attributes;

    const { email } = attributes;

    if (email) {
      Sentry.setUser({ email });
    }

    // update stored preferences
    storeDispatch("news/set-user-preferences", attributes?.newsPreferences);

    storeDispatch("user/set-data", {
      attributes,
      id: data[0]?.id,
      included: included?.length > 0 && included,
      termsAndConditionsState: userTaCStateResponse || {
        agbConsentDateTime: null,
        isSuccess: true,
      },
    });

    return customerResponse;
  });
};

export default getUserData;
