import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Tabs } from "antd";
import { CancelTokenSource } from "axios";
import { Link } from "react-router-dom";
import { ProductData } from "../../types/productData";
import getDisplayProducts from "../../api/products/getDisplayProducts";
import requestCatchHandler from "../../api/requestCatchHandler";
import { routePathNames } from "../../appConfig";
import getCancelTokenSource from "../../api/getCancelTokenSource";

const { TabPane } = Tabs;
interface Props {
  deliveryDate: ProductData["deliveryDate"];
  detailText: string;
  isDisplayProduct: boolean;
  isDisplayText: string;
  salesArguments: string;
  sku: ProductData["sku"];
  className?: string;
  displayText?: string;
}
const DetailText: React.FC<Props> = ({
  deliveryDate,
  detailText,
  isDisplayProduct,
  isDisplayText,
  salesArguments,
  sku,
  className,
  displayText,
}: Props) => {
  const [productData, setProductData] = useState<ProductData[]>([]);

  /**
   * get products data to show content of display
   */
  const getProduct = useCallback(
    async (cancelTokenSource: CancelTokenSource) => {
      getDisplayProducts(sku, deliveryDate, cancelTokenSource)
        .then((result) => {
          if (result && result?.length > 0) {
            setProductData(result);
          }
        })
        .catch((err) => {
          requestCatchHandler(err);
        });
    },
    [deliveryDate, sku]
  );

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();

    if (isDisplayProduct) {
      getProduct(cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [isDisplayProduct, getProduct]);

  return (
    <div className={clsx("detailText", className)}>
      <div className="detailTextInner">
        {detailText && (
          <span className="detailTextTitleShort text-medium">{detailText}</span>
        )}

        <Tabs defaultActiveKey="1">
          {isDisplayText && (
            <TabPane tab="Inhalt" key="1">
              <p className="text-medium">{isDisplayText}</p>
              {!!displayText && (
                <>
                  <br />
                  <div className="breakline" />
                  <br />
                  <span className="text-medium">{displayText}</span>
                </>
              )}
            </TabPane>
          )}

          {salesArguments && (
            <TabPane tab="Produktbeschreibung" key="2">
              <p className="text-medium">{salesArguments}</p>
            </TabPane>
          )}

          {productData?.length > 0 && (
            <TabPane tab="Displays" key="3">
              <ul className="productBundleList">
                {productData.map((product: any) => (
                  <li
                    className="productBundleItem text-medium"
                    key={product.sku}
                  >
                    <Link to={`${routePathNames.product}${product.sku}`}>
                      {product.description}
                    </Link>
                  </li>
                ))}
              </ul>
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default DetailText;
