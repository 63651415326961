import React from "react";

import clsx from "clsx";

import getDeliveryDateBasedAttributes from "../product/getDeliveryDateBasedAttributes";
import getDefaultImageUrlSet from "../../utils/getDefaultImageUrlSet";
import getProductUVP from "../../utils/getProductUVP";
import HrDivider from "../divider/Divider";
import Price from "../price/Price";
import PriceTagListPriceInput from "./PriceTagListPriceInput";
import PriceTagListRemoveItemButton from "./PriceTagListRemoveItemButton";
import ProductImage from "../product/ProductImage";
import { ProductTitle, ProductAttributes, ProductInsignias } from "../product";
import { productAttributes } from "../../api/productAttributes";
import { PriceTagListColumns } from "../../types/priceTagList";
import { ProductData } from "../../types/productData";
import { ReactComponent as Edit } from "../../static/svg/edit.svg";
import ProductPrices from "../product/ProductPrices";

interface PriceTagListChildRowProps {
  deliveryDate: ProductData["deliveryDate"];
  productItem: ProductData;
  isSmallScreen?: boolean;
}

/**
 * create single row for CartItemsList
 * @param deliveryDate {string}
 * @param productItem {ProductData}
 * @param isSmallScreen {boolean}
 * @constructor
 * @returns {CartItemColumns}
 */
const PriceTagListChildRow = function PriceTagListChildRow({
  deliveryDate,
  productItem,
  isSmallScreen = false,
}: PriceTagListChildRowProps): PriceTagListColumns {
  const {
    attributes,
    availabilities,
    imageSets,
    name,
    prices,
    sku,
    sortIndex,
  } = productItem;

  const { rrCalcPrice, rrPrice, isUnavailable } =
    getDeliveryDateBasedAttributes({
      deliveryDate,
      prices,
    });

  const {
    [productAttributes.quality]: qualitySign,
    [productAttributes.bioIcon]: bioladenLogo,
    [productAttributes.bioFairIcon]: bioFairLogo,
    [productAttributes.qualityText]: qualityText,
    [productAttributes.package]: basePriceUnit,
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.weighingArticle]: weighingArticle,
  } = attributes;

  const icons = String(qualitySign).split(" ");

  const productUVP = getProductUVP(
    rrPrice,
    productUnitQuantity,
    weighingArticle === "1"
  );

  const productCalcUVP = getProductUVP(
    rrCalcPrice,
    productUnitQuantity,
    weighingArticle === "1"
  );

  /*
   * order of object keys:
   * internal identifier
   * > key
   * > sku
   * > sortIndex
   * order of the rest follows the visual representation
   */
  return {
    key: sku, // react identifier
    sku,
    sortIndex,
    articleElement: isSmallScreen ? (
      <div className="itemInfo--small" id={`productItem-${sku}`}>
        <ProductImage
          imageSet={getDefaultImageUrlSet(imageSets)}
          className="product-img"
          sku={sku}
        />

        <ProductTitle
          attributes={attributes}
          availabilities={availabilities}
          deliveryDate={deliveryDate}
          sku={sku}
          title={name}
          titleClassName={clsx({ isUnavailable })}
        />

        <HrDivider className="divider-top" spacingTop="s" />

        <ProductInsignias
          width={{ xs: 12 }}
          icons={icons}
          bioladenLogo={bioladenLogo}
          bioladenFairLogo={bioFairLogo}
          qualityText={qualityText}
        />

        <ProductAttributes
          attributes={attributes}
          deliveryDate={deliveryDate}
          sku={sku}
        />

        <HrDivider
          className="divider-bottom"
          spacingTop="s"
          spacingBottom="s"
        />

        <div className="single-price">
          <span>EK&nbsp;</span>
          <div className="productPrice">
            <ProductPrices
              basePriceQuantity={productUnitQuantity}
              className="productPrice text-bold"
              deliveryDate={deliveryDate}
              prices={prices}
              weighingArticle={weighingArticle}
              basePriceUnit={productUnitMeasurement}
              sku={sku}
            />
            <span className="priceBaseUnit">
              &nbsp;/ {productUnitMeasurement}
            </span>
          </div>
        </div>

        <div className="vpe">
          <span>UVP&nbsp;</span>
          <Price
            className="productPrice text-bold"
            price={productCalcUVP}
            baseUnit={basePriceUnit}
            tag="div"
          />
        </div>

        <div className="quantity">
          <div className="quantity__title">Ihr Etikett</div>
          <div className="quantity__content">
            <PriceTagListPriceInput sku={sku} />
            <div className="quantity__content__append">
              <Edit />
            </div>
          </div>
        </div>

        <div className="actions">
          <PriceTagListRemoveItemButton sku={sku} />
        </div>
      </div>
    ) : (
      <div className="itemInfo" id={`productItem-${sku}`}>
        <ProductImage
          imageSet={getDefaultImageUrlSet(imageSets)}
          className="product-img"
          sku={sku}
        />
        <ProductTitle
          attributes={attributes}
          availabilities={availabilities}
          deliveryDate={deliveryDate}
          sku={sku}
          title={name}
        />
        <ProductAttributes
          attributes={attributes}
          deliveryDate={deliveryDate}
          sku={sku}
        />
        <ProductInsignias
          icons={icons}
          bioladenLogo={bioladenLogo}
          bioladenFairLogo={bioFairLogo}
          orientation="start"
          qualityText={qualityText}
        />
      </div>
    ),
    purchasePrice: (
      <div className="productPrice">
        <ProductPrices
          basePriceQuantity={productUnitQuantity}
          className="productPrice text-bold"
          deliveryDate={deliveryDate}
          prices={prices}
          weighingArticle={weighingArticle}
          basePriceUnit={productUnitMeasurement}
          sku={sku}
        />
        <span className="priceBaseUnit">&nbsp;/ {productUnitMeasurement}</span>
      </div>
    ),
    price: <PriceTagListPriceInput sku={sku} />,
    unitPrice: (
      <Price
        className="productPrice text-bold"
        price={productUVP}
        baseUnit={basePriceUnit}
        tag="div"
      />
    ),
    rrp: (
      <Price
        className="productPrice text-bold"
        price={productCalcUVP}
        baseUnit={basePriceUnit}
        tag="div"
      />
    ),
    interactionIcon: <PriceTagListRemoveItemButton sku={sku} />,
  };
};

export default PriceTagListChildRow;
