import { GetProductAttributeValues } from "../../types/productAttributes";
import getAttributeValueFromObject from "./getAttributeValueFromObject";
import parseProductAttributes from "./parseProductAttributes";

/**
 * process product attributes
 * wrapper function for multiple function calls, to keep components clean
 * @param attribute {string}
 * @param deliveryDate {string}
 * @return {string}
 */
const getProcessedProductAttributeValue = ({
  attribute,
  deliveryDate,
}: GetProductAttributeValues) => {
  // get the string value or a value from an date based object
  let value = getAttributeValueFromObject({
    attribute,
    deliveryDate,
  });

  // parse some special cases
  value = parseProductAttributes(value);

  return value || "";
};

export default getProcessedProductAttributeValue;
