import axios, { CancelTokenSource } from "axios";
import { message } from "antd";
import { messageData } from "../../appConfig";
import requestCatchHandler from "../../api/requestCatchHandler";
import setLocalCart from "./setLocalCart";
import setUserLists from "./setUserLists";
import setAlreadyOrdered from "./setAlreadyOrdered";

/**
 * update cart and order on date change
 * @param deliveryDate {string}
 * @param setComponentIsLoading {function}
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise<AxiosResponse>}
 */
const setCartAndUserListsToStore = ({
  deliveryDate,
  setComponentIsLoading = () => true,
  cancelTokenSource,
}: {
  deliveryDate: string;
  setComponentIsLoading?: (isLoading: boolean) => void;
  cancelTokenSource: CancelTokenSource;
}) => {
  setComponentIsLoading(true);

  return Promise.all([
    setLocalCart({
      deliveryDate,
      cancelTokenSource,
    }),
    setUserLists({ cancelTokenSource }),
  ])
    .then(() => {
      setAlreadyOrdered({
        cancelTokenSource,
      });
    })
    .then(() => {
      setComponentIsLoading(false);
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        setComponentIsLoading(false);
        message.error(messageData.error.unexpected);
      }

      requestCatchHandler(error);
    });
};

export default setCartAndUserListsToStore;
