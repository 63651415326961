import i18n from "i18next";

/**
 * format dash separated date to local german one
 * @param date {string}
 * @param locale {string}
 * @param options {Record<string, any>}
 * @returns {string|*}
 */
const formatDashedDateToLocale = (
  date: string,
  locale = i18n.language,
  options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }
) => {
  const dateFormat = new Date(date);

  if (Number.isNaN(dateFormat)) {
    return date || "";
  }

  return dateFormat.toLocaleDateString(
    locale,
    options as Intl.DateTimeFormatOptions
  );
};

export default formatDashedDateToLocale;
