import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { companyBusinessUnitEndpoint } from "../endpoints";
import { CompanyBusinessUnitData } from "../../types/companyBusinessUnit";

interface GetCompanyBusinessUnitProps {
  companyBusinessUnitKey: string;
  cancelTokenSource: CancelTokenSource;
}

const getCompanyBusinessUnit = async (props: GetCompanyBusinessUnitProps) => {
  const { companyBusinessUnitKey, cancelTokenSource } = props;

  const url = `${companyBusinessUnitEndpoint}/mine?key=${companyBusinessUnitKey}`;

  return axiosWithAuth()
    .get(url, { cancelToken: cancelTokenSource.token })
    .then((response): Promise<any> | CompanyBusinessUnitData => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data?.[0] || {};

      return attributes as CompanyBusinessUnitData;
    });
};

export default getCompanyBusinessUnit;
