import { deliverDateOptions } from "../appConfig";

const getDeliveryDates = (
  customerDeliveryDates: string[] = [],
  businessUnitDeliveryDates: string[] = []
): string[] => {
  if (!customerDeliveryDates?.length && !businessUnitDeliveryDates?.length) {
    return deliverDateOptions
      .filter((option) => !option?.disabled)
      .map((option) => option.value);
  }

  if (!customerDeliveryDates?.length) {
    return businessUnitDeliveryDates;
  }

  const validCustomerDeliveryDates = customerDeliveryDates.filter(
    (deliveryDate: string) => businessUnitDeliveryDates.includes(deliveryDate)
  );

  if (!validCustomerDeliveryDates?.length) {
    return businessUnitDeliveryDates;
  }

  return businessUnitDeliveryDates.filter((deliveryDate: string) =>
    customerDeliveryDates.includes(deliveryDate)
  );
};

export default getDeliveryDates;
