import { Asset } from "contentful";
import getFileExtensionFromAsset from "../../../utils/getFileExtensionFromAsset";

const DOWNLOADABLE_FILE_EXTENSIONS = ["ZIP"];
const FILE_DOWNLOAD = "Datei herunterladen";
const FILE_OPEN = "Datei öffnen";

const getTooltipTextFromAsset = (asset: Asset): string => {
  const fileExtension = getFileExtensionFromAsset(asset);

  if (DOWNLOADABLE_FILE_EXTENSIONS.includes(fileExtension)) {
    return FILE_DOWNLOAD;
  }

  return FILE_OPEN;
};

const getTargetFromAsset = (asset: Asset): "_blank" | "_self" => {
  const fileExtension = getFileExtensionFromAsset(asset);

  if (DOWNLOADABLE_FILE_EXTENSIONS.includes(fileExtension)) {
    return "_self";
  }

  return "_blank";
};

export { getTooltipTextFromAsset, getTargetFromAsset };
