import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import elementScrollToPosition from "../utils/elementScrollToPosition";

/**
 * scroll window to previous position
 * after that reset the state
 */
const useRestoreScrollPosition = (ref: any = window) => {
  const dispatch = useDispatch();
  const { x, y } = useSelector((state: any) => state.page.scroll);

  return useCallback(() => {
    if (x > 0 || y > 0) {
      // scroll
      elementScrollToPosition({
        top: y,
        left: x,
        elementToScroll: ref,
      });

      dispatch({
        type: "page/set-scroll",
        payload: {
          x: 0,
          y: 0,
        },
      });
    }
  }, [dispatch, x, y, ref]);
};

export default useRestoreScrollPosition;
