import React, { useState } from "react";

import { Col, Row, Table } from "antd";

import OrderItemChildRow from "./OrderItemChildRow";
import ReorderModal from "./ReorderModal";
import sortProductsBySortIndex from "../../../utils/sortProductsBySortIndex";
import useGetDeliveryDate from "../../../hooks/useGetDeliveryDate";
import { OrderData } from "../../../types/orderData";
import { OrderProductData } from "../../../types/orderProductData";

interface Props {
  orderItems: OrderData["items"];
}

/*
 * columns for table
 */
const columns = [
  {
    title: "",
    dataIndex: "product",
    key: "product",
    width: "min-content",
  },
  {
    title: "Lieferdatum",
    dataIndex: "deliveryDate",
    key: "deliveryDate",
    width: 150,
  },
  {
    title: "Anzahl",
    dataIndex: "quantity",
    key: "quantity",
    width: 50,
  },
  {
    title: "Einzelpreis",
    dataIndex: "unitPrice",
    key: "unitPrice",
    width: 100,
  },
  {
    title: "Gesamtsumme",
    dataIndex: "total",
    key: "total",
    width: 120,
  },
];

/**
 * table component wrapping cart items, here for the orderItems
 * @constructor
 */
function OrderDetailList({ orderItems = [] }: Props) {
  const [deliveryDate] = useGetDeliveryDate();

  const itemRows = orderItems
    .sort(sortProductsBySortIndex)
    .map((orderItem: OrderProductData) =>
      OrderItemChildRow({ deliveryDate, orderItem })
    );

  const [isModalVisible, setIsModalVisible] = useState(false);

  /**
   * adds the cartItem className to single items
   * @return {string}
   */
  const rowClassName = () => "cartItem";

  const toggleReorderModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <>
      <ReorderModal
        productItems={orderItems}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        modalQuestion="Möchten Sie diese Bestellung dem Warenkorb hinzufügen?"
      />

      <Row className="orderDetailList" gutter={2}>
        <Col xs={12} sm={4}>
          <header className="orderDetailListHeading">
            <h2>Artikel</h2>
          </header>
        </Col>
        <Col
          xs={12}
          sm={{ span: 6, offset: 2 }}
          className="flex justify-end mb-m"
        >
          <button
            type="button"
            className="button buttonPrimary buttonPrimary--inverted buttonAddProductsToNewCart"
            onClick={toggleReorderModal}
          >
            Bestellung in neuen Warenkorb
          </button>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Table
            className="productItemTable"
            rowClassName={rowClassName}
            columns={columns}
            scroll={{
              x: "max-content",
            }}
            dataSource={itemRows}
            indentSize={0}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
}

export default OrderDetailList;
