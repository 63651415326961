import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { Button, Col, Row, Skeleton } from "antd";
import { useSelector } from "react-redux";

import { DownOutlined } from "@ant-design/icons";
import { ContentBlock, WebshopProductInformation } from "../../atoms";
import { RootState } from "../../../types/rootState";
import useGetWebshopProductInformation from "../../../hooks/useGetWebshopProductInformation";
import { ContentfulWebshopProductInformationEntry } from "../../../types/contentfulWebshopProductInformationEntry";
import { itemsPerPage } from "../../../appConfig";

interface WebshopProductInformationWidgetProps {
  perPage?: number;
  className?: string;
}

const WebshopProductInformationWidget: React.FC<
  WebshopProductInformationWidgetProps
> = (props: WebshopProductInformationWidgetProps) => {
  const { perPage = itemsPerPage.productInformation, className } = props;

  const { stockName } = useSelector((state: RootState) => state?.userData);

  const [allEntries, setAllEntries] = useState<
    ContentfulWebshopProductInformationEntry[]
  >([]);
  const [skip, setSkip] = useState<number>(0);

  const { isLoading, entries, total } = useGetWebshopProductInformation(
    perPage,
    skip,
    stockName
  );

  const hasMore = useMemo(
    () => total > allEntries?.length || false,
    [total, allEntries]
  );

  useEffect(() => {
    if (entries && entries.length) {
      setAllEntries((prevState: ContentfulWebshopProductInformationEntry[]) => {
        return [...prevState, ...entries];
      });
    }
  }, [entries]);

  useEffect(() => {
    setAllEntries([]);
    setSkip(0);
  }, [stockName]);

  return (
    <ContentBlock
      className={clsx("webshop-product-information-widget", className)}
      title="Aktuelle Informationen"
    >
      <Row gutter={[0, 0]}>
        {isLoading && !allEntries?.length && (
          <Col span={12}>
            <Skeleton active />
          </Col>
        )}

        {!!allEntries &&
          allEntries.map(
            (
              productInformation: ContentfulWebshopProductInformationEntry,
              i: number
            ) => {
              return (
                <Col xs={12} xl={6} key={`product_info_${i}`}>
                  <WebshopProductInformation
                    productInformation={productInformation}
                  />
                </Col>
              );
            }
          )}

        {hasMore && (
          <Col span={12} className="webshop-product-information-widget__more">
            <Button
              className="button buttonPrimary button--rounded"
              onClick={() => {
                setSkip((prevState: number) => prevState + perPage);
              }}
              loading={isLoading}
              icon={<DownOutlined />}
            >
              Mehr laden
            </Button>
          </Col>
        )}
      </Row>
    </ContentBlock>
  );
};

export default WebshopProductInformationWidget;
