import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { favouriteListEndpoint } from "../endpoints";
import getCatalogSearchAttributesData from "../products/getCatalogSearchAttributesData";
import { FavouriteList } from "../../types/favouriteList";

/**
 * retrieves the user based favourite lists and their favourites and returns it
 * @param {CancelTokenSource} cancelTokenSource
 * @return {Promise<Array|undefined>|Promise<AxiosResponse<any>>}
 */
const getFavouriteLists = async (cancelTokenSource: CancelTokenSource) => {
  return axiosWithAuth()
    .get(favouriteListEndpoint, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response?.data?.data || response.status > 200) {
        Promise.reject(response);
      }

      const attributes = getCatalogSearchAttributesData(response?.data?.data);

      return {
        favourites:
          attributes?.favouriteLists
            ?.map((favouriteList: FavouriteList) => favouriteList.favourites)
            .flat(1) || [],
        favouriteLists: (attributes?.favouriteLists as FavouriteList[]) || [],
      };
    });
};

export default getFavouriteLists;
