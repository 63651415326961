import { resendRegisterConfirmationEndpoint } from "../endpoints";
import axiosWithAuth from "../axiosWithAuth";
import handleError from "../../utils/handleError";

/**
 * Trigger resending of registration confirmation for given customer
 *
 * @param {string} customerReference
 */
const postResendRegisterConfirmation = async (
  customerReference: string
): Promise<number | void> => {
  const payload = {
    data: {
      type: "customer-registration-resend-confirmation",
      attributes: {
        customerReference,
      },
    },
  };

  return axiosWithAuth()
    .post(resendRegisterConfirmationEndpoint, payload)
    .then((res) => {
      return res.status;
    })
    .catch(handleError);
};

export default postResendRegisterConfirmation;
