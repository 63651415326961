import { Entry } from "contentful";
import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import { ContentfulCategoryEntry } from "../../types/contentfulCategoryEntry";

/**
 * wrapper to fetch all news categories
 */
const getAllNewsCategories = async () => {
  return getContentfulContentType(contentfulContentTypes.newsCategory).then(
    (response: any) => {
      if (!response) {
        Promise.reject(response);
      }

      return response.map((categoryEntry: Entry<any>) => {
        const {
          fields: { icon, title, mainCategory },
          sys: { id },
        } = categoryEntry;

        return {
          icon,
          title,
          id,
          mainCategory,
        } as ContentfulCategoryEntry;
      });
    }
  );
};

export default getAllNewsCategories;
