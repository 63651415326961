import { message, Tooltip, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import requestCatchHandler from "../../../api/requestCatchHandler";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import useUpdateCartItemQuantity from "../../../hooks/useUpdateCartItemQuantity";
import { messageData } from "../../../appConfig";
import ProductAddToPriceTagListButton from "../../product/ProductAddToPriceTagListButton";
import { ReactComponent as Delete } from "../../../static/svg/delete.svg";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import PopupMenu from "../../navigation/PopupMenu";
import ProductAddToFavouritesListButton from "../../product/ProductAddToFavouritesListButton";
import MoveSingleItemToDateButton from "../MoveProductItemsToDate/MoveSingleItemToDateButton";
import elementScrollToPosition from "../../../utils/elementScrollToPosition";

interface Props {
  sku: string;
  showDeleteButton?: boolean;
  showMoveButton?: boolean;
  showAddToFavoriteListButton?: boolean;
  showAddToPriceTagListButton?: boolean;
  disabled?: boolean;
  customButton?: React.ReactNode;
}

const ProductButtonMenu: React.FC<Props> = function ProductButtonMenu({
  sku,
  showDeleteButton = true,
  showMoveButton = true,
  showAddToFavoriteListButton = true,
  showAddToPriceTagListButton = true,
  disabled = false,
  customButton,
}: Props) {
  const { id: cartId, deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );
  const [isLoading, setIsLoading] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const updateCartItemQuantity = useUpdateCartItemQuantity();
  const isLargeScreen = useMedia(`(min-width: ${getCssVariable("screen-md")})`);

  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  /*
   * delete cart item, but only if the response is successful
   */
  const deleteItem = async () => {
    setIsLoading(true);

    updateCartItemQuantity({
      deliveryDate,
      cartId,
      sku,
      quantity: 0,
      cancelTokenSource: cancelTokenSource.current,
    })
      .then(() => {
        message.success(messageData.success.cart.deleteItem);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);

          message.error(messageData.error.cart.deleteItem);
        }

        requestCatchHandler(error);
      });
  };

  const main = document.querySelector("main");
  const html = document.querySelector("html");

  useEffect(() => {
    const newPosition = parseFloat(main.style.top);

    if (!mobileMenuVisible && newPosition) {
      main.style.removeProperty("top");
      main.style.removeProperty("position");
      elementScrollToPosition({ top: Math.abs(newPosition) });
      html.classList.remove("no-scroll-behavior");
    }
  }, [mobileMenuVisible, main, html]);

  const toggleMobileMenuVisibility = () => {
    if (!mobileMenuVisible) {
      const scrollPosition = window.pageYOffset;

      main.style.top = `-${scrollPosition}px`;
      main.style.position = "relative";
      html.classList.add("no-scroll-behavior");
    }

    setMobileMenuVisible(!mobileMenuVisible);
  };

  return (
    <div className="productButtonMenu">
      {!!showDeleteButton && !!sku && !disabled && (
        <Spin size="small" spinning={isLoading}>
          <button
            type="button"
            onClick={() => deleteItem()}
            className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText"
          >
            <Delete className="icon" />
          </button>
        </Spin>
      )}

      {customButton}

      {!!isLargeScreen && !!sku && !disabled && (
        <Tooltip
          placement="left"
          overlayClassName="productButtonTooltipListWrapper"
          arrowPointAtCenter
          title={
            <ul className="list list--unstyled">
              {!!showMoveButton && (
                <li className="listEntry">
                  <MoveSingleItemToDateButton sku={sku} />
                </li>
              )}

              {!!showAddToFavoriteListButton && (
                <li className="listEntry">
                  <ProductAddToFavouritesListButton sku={sku} withText />
                </li>
              )}

              {!!showAddToPriceTagListButton && (
                <li className="listEntry">
                  <ProductAddToPriceTagListButton sku={sku} withText />
                </li>
              )}
            </ul>
          }
          trigger="click"
        >
          <button
            type="button"
            className="button buttonText buttonText--menu color-primary"
          >
            &middot;&middot;&middot;
          </button>
        </Tooltip>
      )}

      {!isLargeScreen && !!sku && !disabled && (
        <>
          <button
            type="button"
            className="button buttonText buttonText--menu color-primary"
            onClick={toggleMobileMenuVisibility}
          >
            &middot;&middot;&middot;
          </button>

          <PopupMenu
            onClose={toggleMobileMenuVisibility}
            title="Menü"
            visible={mobileMenuVisible}
          >
            {!!showMoveButton && (
              <li className="menuItem">
                <MoveSingleItemToDateButton sku={sku} />
              </li>
            )}

            {!!showAddToFavoriteListButton && (
              <li className="menuItem">
                <ProductAddToFavouritesListButton sku={sku} withText />
              </li>
            )}

            {!!showAddToPriceTagListButton && (
              <li className="menuItem">
                <ProductAddToPriceTagListButton sku={sku} withText />
              </li>
            )}
          </PopupMenu>
        </>
      )}
    </div>
  );
};

export default ProductButtonMenu;
