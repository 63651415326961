import { Button, Col, Form, Row, Space } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import { routePathNames } from "../../appConfig";

interface FormSubmitFooterData {
  linkTo: "login" | "password";
  isLoading: boolean;
  submitButtonText: string;
}

function FormSubmitFooter({
  linkTo,
  isLoading,
  submitButtonText,
}: FormSubmitFooterData) {
  return (
    <Row>
      <Col xs={5}>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="button buttonSecondary"
            loading={isLoading}
          >
            {submitButtonText}
          </Button>
        </Form.Item>
      </Col>

      <Col span={7}>
        <div className="formLinks">
          {linkTo === "login" ? (
            <Link to={routePathNames.login}>Zurück zum Login</Link>
          ) : (
            <Space wrap direction="vertical">
              <Link to={routePathNames.passwordReset}>Passwort vergessen?</Link>
              <a
                href="https://www.weiling.de/kontakt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Neukunde werden?
              </a>
              <Link to={routePathNames.loginRequest}>Zugang anfordern?</Link>
            </Space>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default FormSubmitFooter;
