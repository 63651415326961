import React, { useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";
import {
  Button,
  Col,
  DatePicker,
  message,
  Popconfirm,
  Row,
  Spin,
  Tag,
} from "antd";
import moment from "moment";
import queryString from "query-string";

import BackButton from "../../backButton/BackButton";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import HrDivider from "../../divider/Divider";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import deleteStocktaking from "../../../api/stocktaking/deleteStocktaking";
import patchStocktaking from "../../../api/stocktaking/patchStocktaking";
import getStocktakingItems from "../../../api/stocktaking/getStocktakingItems";
import getStocktakingFile, {
  StocktakingFileFormat,
} from "../../../api/stocktaking/getStocktakingFile";
import StocktakingItemsTable from "./StocktakingItemsTable";
import requestCatchHandler from "../../../api/requestCatchHandler";
import getStocktaking from "../../../api/stocktaking/getStocktaking";
import Note from "../../note/Note";
import StocktakingProductSearch from "./StocktakingProductSearch";
import StocktakingScanner from "./StocktakingScanner/StocktakingScanner";
import useUpdateUrlFragments from "../../../hooks/useUpdateUrlFragments";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { getTotalStocktakingValueLocalized } from "./utils";
import { ContentBlock } from "../../atoms";
import { apiDateFormat, fullDateFormat } from "../../../utils/dateFormats";
import {
  locationSearchQueryParameter,
  messageData,
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../../appConfig";
import {
  GetStocktakingItemsAttributesResponse,
  StocktakingData,
  StocktakingItemData,
  StocktakingItemsSorting,
  StocktakingStatus,
} from "../../../types/stocktaking";
import { ReactComponent as Delete } from "../../../static/svg/delete.svg";
import { ReactComponent as Download } from "../../../static/svg/download.svg";

interface StocktakingProps {
  className?: string;
}

type StocktakingDownloadState = {
  [key in StocktakingFileFormat]: boolean;
};

const DEFAULT_SORTING: StocktakingItemsSorting = "-updatedAt";

const Stocktaking: React.FC<StocktakingProps> = (props: StocktakingProps) => {
  const { className } = props;

  const { id: stocktakingId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const parsedSearch = useMemo(() => queryString.parse(search), [search]);

  const { [locationSearchQueryParameter.page]: pageQuery } = parsedSearch;

  const [stocktaking, setStocktaking] = useState<StocktakingData>(null);
  const [status, setStatus] = useState<StocktakingStatus>(null);
  const [note, setNote] = useState<string>("");
  const [referenceDate, setReferenceDate] = useState<moment.Moment>(null);
  const [isStocktakingLoading, setIsStocktakingLoading] =
    useState<boolean>(false);
  const [isStocktakingDeleting, setIsStocktakingDeleting] =
    useState<boolean>(false);
  const [isStocktakingUpdating, setIsStocktakingUpdating] =
    useState<boolean>(false);
  const [stocktakingDownloadState, setStocktakingDownloadState] =
    useState<StocktakingDownloadState>({
      excel: false,
      biooffice: false,
    });
  const [stocktakingItems, setStocktakingItems] = useState<
    StocktakingItemData[]
  >([]);
  const [numStocktakingItems, setNumStocktakingItems] = useState<number>(0);
  const [areStocktakingItemsLoading, setAreStocktakingItemsLoading] =
    useState<boolean>(false);
  const [stocktakingItemsReloadTrigger, setStocktakingItemsReloadTrigger] =
    useState<boolean>(false);
  const [page, setPage] = useState<number>(Number(pageQuery) || 1);
  const [sorting, setSorting] =
    useState<StocktakingItemsSorting>(DEFAULT_SORTING);

  const setUpdateUrlFragments = useUpdateUrlFragments();
  const navigate = useNavigate();

  const isActive = useMemo(() => status === "active", [status]);

  const statusAsTag = useMemo((): React.ReactNode => {
    if (!status) {
      return <></>;
    }
    return (
      <Tag color={isActive ? "success" : "default"}>
        {isActive ? "Laufend" : "Beendet"}
      </Tag>
    );
  }, [status, isActive]);

  const totalStocktakingValueLocalized = useMemo((): string => {
    return getTotalStocktakingValueLocalized(stocktakingItems);
  }, [stocktakingItems]);

  const fetchStocktaking = useCallback(
    async (cancelTokenSource: CancelTokenSource) => {
      setIsStocktakingLoading(true);
      getStocktaking({
        stocktakingId: Number(stocktakingId),
        cancelTokenSource,
      })
        .then((stocktakingData: StocktakingData) => {
          setIsStocktakingLoading(false);
          setStocktaking(stocktakingData);
          setStatus(stocktakingData.status);
          setNote(stocktakingData.note || "");
          setReferenceDate(moment(stocktakingData.referenceDate));
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setIsStocktakingLoading(false);
            message.error({ ...messageData.error.stocktakings.readError });
          }
          requestCatchHandler(error);
        });
    },
    [stocktakingId]
  );

  const fetchStocktakingItems = useCallback(
    async (cancelTokenSource: CancelTokenSource) => {
      setAreStocktakingItemsLoading(true);
      getStocktakingItems({
        stocktakingId: Number(stocktakingId),
        page,
        sorting,
        cancelTokenSource,
      })
        .then((response: GetStocktakingItemsAttributesResponse) => {
          setAreStocktakingItemsLoading(false);
          setStocktakingItems(response?.stocktakingItems || []);
          setNumStocktakingItems(response.totalResults);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setAreStocktakingItemsLoading(false);
            message.error({ ...messageData.error.stocktakingItems.readError });
          }
          requestCatchHandler(error);
        });
    },
    [stocktakingId, page, sorting]
  );

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    const { order, field } = sorter;

    if (order) {
      const dir = order === "descend" ? "-" : "";
      setSorting(`${dir}${field}` as StocktakingItemsSorting);
    } else {
      setSorting(DEFAULT_SORTING);
    }

    if (pagination.current) {
      setPage(pagination.current);
      setUpdateUrlFragments({
        context: "pagination",
        parameters: {
          page: pagination.current,
        },
      });
    }
  };

  const onDeleteStocktaking = () => {
    const cancelTokenSource = getCancelTokenSource();
    setIsStocktakingDeleting(true);

    deleteStocktaking({
      stocktakingId: Number(stocktakingId),
      cancelTokenSource,
    })
      .then(() => {
        setIsStocktakingDeleting(false);
        message.success({ ...messageData.success.stocktakings.deleteSuccess });
        navigate(routePathNames.stocktakings, { replace: true });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsStocktakingDeleting(false);
          message.error({ ...messageData.error.stocktakings.deleteError });
        }
        requestCatchHandler(error);
      });
  };

  const onUpdateStocktaking = (payload: object) => {
    const cancelTokenSource = getCancelTokenSource();
    setIsStocktakingUpdating(true);

    patchStocktaking({
      stocktakingId: Number(stocktakingId),
      cancelTokenSource,
      ...payload,
    })
      .then((stocktakingData: StocktakingData) => {
        setIsStocktakingUpdating(false);
        setStocktaking(stocktakingData);
        setStatus(stocktakingData.status);
        setNote(stocktakingData.note || "");
        setReferenceDate(moment(stocktakingData.referenceDate));
        message.success({ ...messageData.success.stocktakings.updateSuccess });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsStocktakingUpdating(false);
          message.error({ ...messageData.error.stocktakings.updateError });
        }
        requestCatchHandler(error);
      });
  };

  const onDownloadStocktaking = (format: StocktakingFileFormat) => {
    const cancelTokenSource = getCancelTokenSource();
    setStocktakingDownloadState((prevState) => {
      return { ...prevState, [format]: true };
    });

    getStocktakingFile({
      stocktakingId: Number(stocktakingId),
      referenceDate: referenceDate.format(fullDateFormat),
      format,
      cancelTokenSource,
    })
      .then(() => {
        setStocktakingDownloadState((prevState) => {
          return { ...prevState, [format]: false };
        });
        message.success({
          ...messageData.success.stocktakings.downloadSuccess,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setStocktakingDownloadState((prevState) => {
            return { ...prevState, [format]: false };
          });
          message.error({ ...messageData.error.stocktakings.downloadError });
        }
        requestCatchHandler(error);
      });
  };

  // Fetch stocktaking
  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();
    fetchStocktaking(cancelTokenSource).catch();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [stocktakingId, fetchStocktaking]);

  // Fetch stocktaking items
  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();
    fetchStocktakingItems(cancelTokenSource).catch();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [stocktakingId, fetchStocktakingItems, stocktakingItemsReloadTrigger]);

  return (
    <>
      <TrackingHelmet title={pageTitles.stocktaking} suffix={pageTitleSuffix} />

      <ShopCategoryNavigation />

      <ContentBlock
        showHeader={false}
        className={clsx("stocktaking-page", className)}
      >
        <BackButton to={routePathNames.stocktakings} />

        {/* Stocktaking */}
        <Spin spinning={isStocktakingLoading || isStocktakingUpdating}>
          <Row className="flex items-center mb-m" justify="space-between">
            <Col xs={12} md={4}>
              <h1 className="text-truncate">Inventurliste</h1>
            </Col>
            <Col
              xs={12}
              md={8}
              className="flex justify-start md-justify-end sm-flex-col"
            >
              <Popconfirm
                title="Soll diese Inventur wirklich unwiderruflich gelöscht werden?"
                onConfirm={onDeleteStocktaking}
                okText="Ja"
                cancelText="Nein"
                arrowPointAtCenter
                placement="bottom"
                overlayClassName="stocktaking-popconfirm"
              >
                <Button
                  className="button buttonPrimary buttonWithIcon buttonWithSpin sm-with-full sm-mb-m mb-0 mr-s sm-mr-0"
                  icon={<Delete className="icon" />}
                  loading={isStocktakingDeleting}
                >
                  Inventur löschen
                </Button>
              </Popconfirm>
              <Button
                className="button buttonPrimary buttonWithIcon buttonWithSpin sm-with-full sm-mb-m mb-0 mr-s sm-mr-0"
                icon={<Download className="icon" />}
                loading={stocktakingDownloadState?.excel || false}
                onClick={() => onDownloadStocktaking("excel")}
                disabled={!numStocktakingItems}
              >
                Download Excel
              </Button>
              <Button
                className="button buttonPrimary buttonWithIcon buttonWithSpin sm-with-full"
                icon={<Download className="icon" />}
                loading={stocktakingDownloadState?.biooffice || false}
                onClick={() => onDownloadStocktaking("biooffice")}
                disabled={!numStocktakingItems}
              >
                Download BioOffice
              </Button>
            </Col>
          </Row>

          <HrDivider spacingTop="xs" spacingBottom="m" size={2} />

          <Row justify="space-between">
            <Col xs={12} md={6} xl={7} className="pr-m sm-pr-0 mb-m">
              <p className="mb-m text-medium flex justify-between items-center">
                <span className="text-bold">Kundennr.</span>{" "}
                {stocktaking?.companyBusinessUnitKey}
              </p>
              <p className="mb-m text-medium flex justify-between items-center">
                <span className="text-bold">Status</span> {statusAsTag}
              </p>
              <Note
                onChange={(noteText: string) =>
                  onUpdateStocktaking({ note: noteText })
                }
                title="Notiz"
                maxLength={255}
                showCount
                text={note}
                disabled={isStocktakingLoading || !isActive}
              />
            </Col>
            <Col xs={12} md={6} xl={5} className="p-s bg-light-grey">
              <p className="flex justify-between items-center">
                <span>Anzahl Artikel</span>
                <span className="text-bold text-medium">
                  {numStocktakingItems}
                </span>
              </p>
              <p className="mb-m text-medium flex justify-between items-center">
                <span className="like-h3 text-dark">Inventurwert</span>
                <span className="text-bold text-medium">
                  {totalStocktakingValueLocalized}
                </span>
              </p>
              <HrDivider spacingTop="s" size={2} className="mb-sm" />
              <div className="mb-sm date-picker-wrapper">
                <span className="like-h4 text-bold">Inventurdatum</span>
                <DatePicker
                  disabled={!isActive}
                  onChange={(date) => {
                    setReferenceDate(date);
                    onUpdateStocktaking({
                      referenceDate: date?.format(apiDateFormat) || "",
                    });
                  }}
                  format={fullDateFormat}
                  value={referenceDate}
                  allowClear={false}
                  inputReadOnly
                />
              </div>
              <div className="flex flex-row">
                {!!isActive && (
                  <Popconfirm
                    title="Soll diese Inventur wirklich beendet werden?"
                    onConfirm={() => onUpdateStocktaking({ status: "closed" })}
                    okText="Ja"
                    cancelText="Nein"
                    arrowPointAtCenter
                    placement="bottom"
                    overlayClassName="stocktaking-popconfirm"
                  >
                    <Button className="button buttonPrimary width-full sm-with-full">
                      Inventur beenden
                    </Button>
                  </Popconfirm>
                )}
                {!isActive && (
                  <Popconfirm
                    title="Soll diese Inventur wirklich fortgesetzt werden?"
                    onConfirm={() => onUpdateStocktaking({ status: "active" })}
                    okText="Ja"
                    cancelText="Nein"
                    arrowPointAtCenter
                    placement="bottom"
                    overlayClassName="stocktaking-popconfirm"
                  >
                    <Button className="button buttonPrimary buttonPrimary--inverted width-full sm-with-full">
                      Inventur fortsetzen
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </Col>
          </Row>
        </Spin>

        <HrDivider spacingTop="m" spacingBottom="m" size={2} />

        {/* Stocktaking items */}
        <Row className="flex items-center mb-m" justify="space-between">
          <Col xs={12} md={6}>
            <h2 className="text-truncate">Artikel</h2>
          </Col>
          <Col
            xs={12}
            md={6}
            className="flex justify-start md-justify-end sm-flex-col flex-row"
          >
            <div className="sm-pr-0 pr-m sm-with-full quick-order-wrapper">
              <StocktakingProductSearch
                canAdd={isActive}
                stocktakingId={Number(stocktakingId)}
                setLoadingCallback={setAreStocktakingItemsLoading}
                onAdded={() => {
                  setStocktakingItemsReloadTrigger(
                    (prevState: boolean) => !prevState
                  );
                  message.success({
                    ...messageData.success.stocktakingItems.createSuccess,
                  });
                }}
              />
            </div>
            <StocktakingScanner
              stocktakingId={Number(stocktakingId)}
              isDisabled={!isActive}
              onClose={() => {
                setSorting(DEFAULT_SORTING);
                setStocktakingItemsReloadTrigger(
                  (prevState: boolean) => !prevState
                );
              }}
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xs={12}>
            <StocktakingItemsTable
              stocktakingItems={stocktakingItems}
              total={numStocktakingItems}
              page={page}
              isLoading={areStocktakingItemsLoading}
              canEdit={isActive}
              onDataChange={() => {
                setStocktakingItemsReloadTrigger(
                  (prevState: boolean) => !prevState
                );
              }}
              onTableChange={onTableChange}
              emptyDescription={
                <span>
                  Keine Artikel vorhanden. <br />
                  Scannen Sie Artikel, um diese zur Inventur hinzuzufügen.
                </span>
              }
            />
          </Col>
        </Row>
      </ContentBlock>
    </>
  );
};

export default Stocktaking;
