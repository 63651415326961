import React from "react";
import clsx from "clsx";
import { Tooltip } from "antd";

import { ReactComponent as Info } from "../../static/svg/info.svg";
import ProductInfoList from "./ProductInfoList";
import { ProductData } from "../../types/productData";

interface Props {
  attributes?: ProductData["attributes"];
  className?: string;
  getContainer?: () => HTMLElement;
}

/**
 * This component handles what gets rendered when people click on the info button in ProductNode
 * @param attributes {ProductData["attributes]}
 * @param className {string}
 * @param getContainer
 * @constructor
 */
const ProductInfoPopUp = function ProductInfoPopUp({
  attributes,
  className,
  getContainer,
}: Props) {
  const tooltipContent = (
    <div className={clsx("productInfoPopup", className)}>
      <ProductInfoList
        attributes={attributes}
        className="productInfoPopup__list"
        hasColon
        filterType="infoPopup"
      />

      {/* currently empty container for icons */}
      <div className="productInfoPopup__icons" />
    </div>
  );

  return (
    <Tooltip
      placement="topLeft"
      title={tooltipContent}
      getTooltipContainer={getContainer}
      trigger={["hover", "click"]}
      destroyTooltipOnHide
    >
      <button type="button" className={clsx("productInfoIcon", className)}>
        <Info className="icon iconTwoTone iconInfo" />
      </button>
    </Tooltip>
  );
};

export default ProductInfoPopUp;
