import { useEffect, useState } from "react";

import moment from "moment/moment";

import axios from "axios";
import { useSelector } from "react-redux";
import getAllCartsCheapMetadata from "../api/cart/getAllCartsCheapMetadata";
import getCancelTokenSource from "../api/getCancelTokenSource";

import { CheapCartMetadata } from "../types/cheapCartMetadata";
import requestCatchHandler from "../api/requestCatchHandler";
import { RootState } from "../types/rootState";

const useGetAllCartsCheapMetadata = (count?: number) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [openCarts, setOpenCarts] = useState<Array<CheapCartMetadata>>([]);

  const currentCart = useSelector((state: RootState) => state.currentCart);

  useEffect(() => {
    const cancelToken = getCancelTokenSource();

    setIsLoading(true);
    setOpenCarts([]);
    getAllCartsCheapMetadata(cancelToken)
      .then((response): CheapCartMetadata[] => {
        const cartMetaData: CheapCartMetadata[] =
          response as Array<CheapCartMetadata>;
        return cartMetaData?.filter(
          (data: CheapCartMetadata) =>
            data.totalQuantity > 0 &&
            moment(data.deliveryDate).isAfter(moment(), "day")
        );
      })
      .then((carts: CheapCartMetadata[]): void => {
        if (carts && carts.length) {
          carts.sort((a: CheapCartMetadata, b: CheapCartMetadata) => {
            return moment(a.deliveryDate).isSameOrAfter(moment(b.deliveryDate))
              ? 1
              : -1;
          });
          setOpenCarts(count ? carts.slice(0, count) : carts);
        }
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
        }
        requestCatchHandler(error);
      });

    return () => {
      cancelToken.cancel();
    };
  }, [count, currentCart]);

  return {
    isLoading,
    hasError,
    openCarts,
  };
};

export default useGetAllCartsCheapMetadata;
