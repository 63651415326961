import getDeliveryDateQuery from "./getDeliveryDateQuery";
import getPageOffset from "../utils/getPageOffset";
import { itemsPerPage, locationSearchQueryParameter } from "../appConfig";
import getPaginationParams from "../utils/getPaginationParams";
import { OrderType, DocumentType } from "../types/orderAPI";
import createFilterString from "../utils/createFilterString";

const { REACT_APP_API, REACT_APP_API_LEVEL, REACT_APP_MATOMO_SIDE_ID } =
  process.env;
export const apiLevel = REACT_APP_API_LEVEL;
export const mainURL = REACT_APP_API;
export const matomoSideID: number = parseInt(REACT_APP_MATOMO_SIDE_ID, 10);

// endpoint without auth
export const loginEndpoint = `${mainURL}/access-tokens`;
export const refreshTokenEndpoint = `${mainURL}/refresh-tokens`;
export const statusEndpoint = `${mainURL}/status`;
export const passwordResetEndpoint = `${mainURL}/customer-forgotten-password`;
export const passwordResetConfirmEndpoint = `${mainURL}/customer-restore-password/`;
export const passwordRegisterConfirmEndpoint = `${mainURL}/customer-registration-confirm/`;
export const loginRequestEndpoint = `${mainURL}/customer-login-request`;

// endpoints which require auth
export const XHRheader = { AcceptLanguage: "de" };
export const termsAndConditionsConsentEndpoint = `/agb-consent`;
export const customerEndpoint = `/customers`;
export const getCustomerEndpoint = `${customerEndpoint}?include=company-unit-addresses,company-user-candidates`;
export const switchBusinessUnitEndpoint = `/company-user-switch`;
export const getCompanyUserEndpoint = `/company-users?include=customers,company-roles`;
export const getCategoriesEndpoint = `/category-nodes/1`;
export const productDetailEndpoint = `/catalog-search`;
export const productDnrFileEndpoint = `/products-dnr`;
export const concreteProductsEndpoint = `/concrete-products`;
export const resendRegisterConfirmationEndpoint = `${mainURL}/customer-registration-resend-confirmation`;
export const getAlreadyOrderedProductsEndpoint = `/order-item-index`;

/**
 * get category id query filter
 * @param {string} categoryKey
 * @returns {string}
 */
const getCategoryQuery = (categoryKey: number | string) =>
  categoryKey && categoryKey !== "null" ? `&category=${categoryKey}` : "";

const defaultIncludes =
  "abstract-products,concrete-products,concrete-product-image-sets,concrete-product-prices,concrete-product-availabilities";

// default query string for products with all parameters needed
export const productQueryString = `include=${defaultIncludes}`;

export const cartQueryString = `?include=items,${defaultIncludes}`;

/*
 * types for getProductsEndpoint
 */
interface ProductsApiEndpoint {
  categoryKey?: string;
  deliveryDates?: string | string[];
  page?: number;
  pageSize?: number;
  query?: string;
  requestFilters?: any;
  sku?: string;
  sortBy?: string;
  sortDirection?: string;
  breadcrumbReference?: string;
  skipAvailabilityCheck?: boolean;
}

/**
 * get products but filtered
 * @param {number} page
 * @param {number} pageSize
 * @param {string} categoryKey
 * @param {string} filters
 * @param {string} deliveryDates
 * @param {string} query
 * @param {string} breadcrumbReference
 * @returns {string}
 */
export const getProductsEndpoint = ({
  categoryKey = "1",
  deliveryDates,
  page = 0,
  pageSize = itemsPerPage.products,
  query,
  requestFilters,
  sku,
  sortBy,
  sortDirection,
  breadcrumbReference,
  skipAvailabilityCheck,
}: ProductsApiEndpoint): string => {
  const datesArray = Array.isArray(deliveryDates)
    ? deliveryDates
    : [deliveryDates];
  const deliveryDateAppendix = getDeliveryDateQuery(datesArray);

  /*
   * see WEL-3389: sku + query are used with an AND.
   * so use sku to get a single result, like in ProductDetail
   * otherwise there can be multiple results, like a search for "24112" with multiple result [24112,241123,241129,... ]
   */
  let encodedQuery = "q=";
  if (sku) {
    if (sku.includes("+")) {
      encodedQuery = `${encodedQuery}&sku[]=${sku.split("+").join("&sku[]=")}`;
    } else {
      encodedQuery = `${encodedQuery}&sku[]=${sku}`;
    }
  } else if (query) {
    encodedQuery = `${encodedQuery}${encodeURIComponent(query)}`;
  }

  const breadcrumbReferenceAppendix = breadcrumbReference
    ? `&${locationSearchQueryParameter.breadcrumbReference}=${breadcrumbReference}`
    : "";

  // ToDo: TBD Do we still need this OR should we check pageOffset the same way as categoryFilter
  if (query && !requestFilters && !page && !pageSize) {
    return `/catalog-search?${encodedQuery}${deliveryDateAppendix}${breadcrumbReferenceAppendix}`;
  }

  const paginationAppendix = getPaginationParams(page, pageSize);
  const categoryFilter = getCategoryQuery(categoryKey);

  const filterString = createFilterString(requestFilters);

  const sortString =
    sortBy && sortDirection ? `&sort=${sortBy}_${sortDirection}` : "";

  const skipAvailabilityCheckString = skipAvailabilityCheck
    ? `&${locationSearchQueryParameter.skipAvailabilityCheck}=1`
    : "";

  /*
   * @example {https://backoffice.weil-dev.kernarea.de/catalog-search?q=&page[offset]=-24&page[limit]=24&category=2&include=abstract-products,concrete-products,concrete-product-prices,concrete-product-availabilities&deliveryDate[]=2021-02-24&sort=sku_asc}
   */
  return `/catalog-search?${encodedQuery}${paginationAppendix}${categoryFilter}${filterString}${deliveryDateAppendix}${sortString}${breadcrumbReferenceAppendix}${skipAvailabilityCheckString}`;
};

export const catalogSearchSuggestEndpoint = `/catalog-search-delivery-date-suggestions?q=`;

/**
 * create url to request product data by ean code
 * which e.g. is scanned by the user
 * @param {string} deliveryDate
 * @param {string} eanCode
 */
export const getScannerProductEndpoint = (
  deliveryDate: string,
  eanCode: string
) => {
  const deliveryDateAppendix = getDeliveryDateQuery(deliveryDate, true);

  return `/catalog-search-ean${deliveryDateAppendix}&ean=${eanCode}`;
};

// CARTS
export const cartsEndpoint = `/carts`;
export const getAllCartsEndpoint = `${cartsEndpoint}?include=items,concrete-products`;
export const getCheapCartsMetadataEndpoint = `/poll`;

// MOODLE, TODO refactor token
export const moodleTokenEndpoint =
  "https://e.weiling-akademie.de/login/token.php?username=sberg&password=RQDN834AzEaMsLep-&service=moodle_mobile_app";

// NEWS
export const getAllNews = `/contentful-news`;

// Checkout
export const checkoutEndpoint = `/checkout`;

// display products
export const displayProductsEndpoint = `/display-product-collection`;

/*
 * types for getAllOrders
 */
interface OrdersApiEndpoint {
  limit: number;
  page: number;
  sorting?: string;
  orderType?: OrderType;
  documentType?: DocumentType;
  invoiceNumber?: string;
  deliveryDates?: Array<string>;
  cartNote?: string;
}

export const allOrdersEndpoint = `/orders`;
/**
 *
 * @param {number} limit
 * @param {number} page
 * @param {string} sorting
 * @param {string} orderType
 * @param {string} documentType
 * @param {string} invoiceNumber
 * @param {array} deliveryDates
 * @param cartNote
 * @returns {string}
 */
export const allOrdersEndpointWithParameters = ({
  limit,
  page,
  sorting,
  orderType,
  documentType,
  invoiceNumber,
  deliveryDates,
  cartNote,
}: OrdersApiEndpoint): string => {
  const limitAppendix = limit ? `&limit=${limit}` : "";
  const sortingAppendix = sorting ? `&sort=${sorting}` : "";
  const orderTypeAppendix = orderType ? `&orderType=${orderType}` : "";
  const documentTypeAppendix = documentType
    ? `&documentType=${documentType}`
    : "";
  const invoiceNumberAppendix = invoiceNumber
    ? `&orderNumber=${invoiceNumber.toUpperCase()}`
    : "";
  const deliveryDateAppendix = getDeliveryDateQuery(deliveryDates, false, true);
  const cartNoteAppendix = cartNote
    ? `&${locationSearchQueryParameter.cartNote}=*${cartNote}*`
    : "";

  return `${allOrdersEndpoint}?page[limit]=${limit}&page[offset]=${getPageOffset(
    page,
    limit
  )}${limitAppendix}${sortingAppendix}${orderTypeAppendix}${documentTypeAppendix}${invoiceNumberAppendix}${deliveryDateAppendix}${cartNoteAppendix}`;
};
export const orderInvoiceFileEndpoint = `/orders-print`;
export const orderOverviewDocumentEndpoint = "/sales-order-overview-document";
export const orderPriceChangeEndpoint = "/order-price-change";

/*
 * price tag list
 */
export const allProductsOnPriceTagListEndpoint = `/price-tag-lists/`;
export const getProductDataFromPriceTagListEndpoint = `/price-tag-list-search`;
export const updateProductOnPriceTagListEndpoint = `/price-tag-lists/default/tags`;
export const printPriceTagListEndpoint = `/price-tags-print`;
export const getPriceTagListSuggestionsEndpoint = `/price-tag-list-suggestions`;
export const orderPriceTagsEndpoint = `/price-tags-order`;

/*
 * favourite list
 */
export const favouriteListEndpoint = `/favourite-lists/`;
export const getProductsDataFromFavouriteListEndpoint = `/favourite-list-search`;

/*
 * Stocktaking
 */
export const stocktakingEndpoint = "/stocktaking";
export const stocktakingItemEndpoint = "/stocktaking-item";
export const stocktakingFileEndpoint = "/stocktaking-print";

/*
 * Company Business Unit
 */
export const companyBusinessUnitEndpoint = "/company-business-units";

/*
 * Quick Order
 */
export const quickOrderFileEndpoint = "/quick-order-file";
