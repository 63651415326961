import { itemsPerPage } from "../appConfig";
import getPageOffset from "./getPageOffset";

/**
 * create a url parameter string for spryker pagination
 * @param {number} page
 * @param {number} pageSize
 */
const getPaginationParams = (page = 1, pageSize = itemsPerPage.products) => {
  const pageOffset = getPageOffset(page, pageSize);

  return `&page[offset]=${pageOffset}&page[limit]=${pageSize}`;
};

export default getPaginationParams;
