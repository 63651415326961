import { ContentfulCategoryEntry } from "../../types/contentfulCategoryEntry";
import { defaultNewsCategoryTitle } from "../../appConfig";

const initialState = {
  categories: [
    {
      icon: {},
      id: "default",
      title: defaultNewsCategoryTitle.default,
      mainCategory: true,
      isDefault: true,
    },
    {
      icon: {},
      id: "",
      title: defaultNewsCategoryTitle.userPreferences,
      mainCategory: true,
      isDefault: true,
    },
  ] as ContentfulCategoryEntry[],
  items: <Record<string, any>[]>[],
  fetchState: <Record<string, any>>{
    default: {
      total: -1,
      loaded: 0,
      pages: <string[]>[],
    },
  },
};

const newsReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // set user preferences
    case "news/set-user-preferences": {
      const userPreferenceTitle = defaultNewsCategoryTitle.userPreferences;
      const userPreferenceNewsIds = [...payload].toString();

      const userPreference = state.categories.find(
        (categoryEntry) => categoryEntry.title === userPreferenceTitle
      );
      const restCategories = state.categories.filter(
        (categoryEntry) => categoryEntry.title !== userPreferenceTitle
      );

      return {
        ...state,
        categories: [
          ...restCategories,
          {
            ...userPreference,
            id: userPreferenceNewsIds,
          },
        ],
      };
    }

    case "news/set-categories": {
      // do not alter the systems defaults
      const defaultCategories = state.categories.filter(
        (categoryEntry) => categoryEntry.isDefault
      );

      return {
        ...state,
        categories: [...defaultCategories, ...payload],
      };
    }

    case "news/add-category-items": {
      const { id, page, items, total } = payload;
      const pageStr = page.toString();

      // test if each unique item id is already in the store, otherwise filter array to add only new items
      const currentState = JSON.stringify(state.items);
      const newItemsToAdd = items.filter(
        (newsItem: any) => !currentState.includes(newsItem.sys.id)
      );

      // make sure, all items are sorted by date descending
      const newItems = [...state.items, ...newItemsToAdd].sort(
        (a: any, b: any) => {
          const dateA = new Date(a?.fields?.publish_date);
          const dateB = new Date(b?.fields?.publish_date);

          // sort descending, turn around to sort ascending
          return Number(dateB) - Number(dateA);
        }
      );

      // update fetchState
      const storedCategoryInfo = state.fetchState[id];
      const loaded = storedCategoryInfo?.loaded || 0;
      const pages: string[] = storedCategoryInfo?.pages || [];

      /*
       * store total loaded amount and fetched pages
       * these information could be verified before next fetch
       ** total: if total is false (e.g. 0), store -1 to make sure to try  a new fetch next time
       ** loaded: aggregate loaded items, only increment, if the loaded items are not stored yet
       ** pages: aggregate pagination states
       */
      const newCategoryState = {
        [id]: {
          total: total || -1,
          loaded: loaded + (newItemsToAdd.length ? items.length : 0),
          pages: pages.includes(pageStr) ? pages : [...pages, pageStr],
        },
      };

      return {
        ...state,
        items: newItems,
        fetchState: {
          ...state.fetchState,
          ...newCategoryState,
        },
      };
    }
    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default newsReducer;
