import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { orderPriceChangeEndpoint } from "../endpoints";

interface GetOrderPriceChangeProps {
  orderReference: string;
  cancelTokenSource: CancelTokenSource;
}

const getOrderPriceChange = async (props: GetOrderPriceChangeProps) => {
  const { orderReference, cancelTokenSource } = props;

  const url = `${orderPriceChangeEndpoint}/${orderReference}`;

  return axiosWithAuth()
    .get(url, { cancelToken: cancelTokenSource.token })
    .then((response): Promise<any> | Array<string> => {
      if (!response?.data?.data?.attributes?.skus || response.status !== 200) {
        return Promise.reject(response);
      }

      const { skus = [] } = response?.data?.data?.attributes || {};

      return skus;
    });
};

export default getOrderPriceChange;
