import { CancelTokenSource } from "axios";

import aggregateProductData from "../../utils/aggregateProductData";
import axiosWithAuth from "../axiosWithAuth";
import { displayProductsEndpoint, productQueryString } from "../endpoints";
import getDeliveryDateQuery from "../getDeliveryDateQuery";
import { locationSearchQueryParameter } from "../../appConfig";

/**
 * get alternative products to given sku
 * @param sku {string}
 * @param deliveryDate {string}
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise<AxiosResponse<any>>}
 */
const getDisplayProducts = async (
  sku: string,
  deliveryDate: string,
  cancelTokenSource: CancelTokenSource
) => {
  if (!sku || !deliveryDate) {
    Promise.reject(new Error("No SKU and/or deliveryDate given."));
  }

  const deliveryDateAppendix = getDeliveryDateQuery(deliveryDate);

  return axiosWithAuth()
    .get(
      `${displayProductsEndpoint}/?sku=${sku}&${productQueryString}${deliveryDateAppendix}&${locationSearchQueryParameter.skipAvailabilityCheck}=1`,
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response: any) => {
      if (
        !response?.data?.data ||
        !response?.data?.included ||
        response.status > 201
      ) {
        return [];
      }

      const { data, included } = response.data;

      if (data && included) {
        // create a new array which mimics the typical catalog-search request
        return aggregateProductData([...data, ...included]);
      }

      return [];
    });
};

export default getDisplayProducts;
