import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import SidebarSwitch from "./SidebarSwitch";
import SidebarContent from "./SidebarContent";
import { SidebarItem } from "../../../types/sidebar";
import { RootState } from "../../../types/rootState";

interface SidebarProps {
  name?: string;
  items: SidebarItem[];
  className?: string;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const dispatch = useDispatch();

  const { name = "default", items, className } = props;

  const { activeIndex: storedActiveIndex } = useSelector(
    (state: RootState) => state?.sidebar?.sidebars?.[name] || {}
  );

  const [activeIndex, setActiveIndex] = useState<number>(
    storedActiveIndex || 0
  );

  const statefulItems = useMemo(
    () =>
      items?.map((item: SidebarItem, index: number) => {
        return { ...item, isActive: index === activeIndex };
      }) || [],
    [items, activeIndex]
  );

  const activeEntry = useMemo(
    () => statefulItems?.[activeIndex] || null,
    [statefulItems, activeIndex]
  );

  useEffect(() => {
    dispatch({
      type: "sidebar/set-active-index",
      payload: { name, activeIndex },
    });
  }, [dispatch, name, activeIndex]);

  return (
    <div className={clsx("sidebar", className)}>
      <SidebarSwitch
        items={statefulItems}
        setActiveIndexCallback={setActiveIndex}
      />
      <SidebarContent
        content={activeEntry?.content}
        withAffix={activeEntry?.withAffix}
      />
    </div>
  );
};

export default Sidebar;
