import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";

import useGetDeliveryDate from "../../../../../../hooks/useGetDeliveryDate";
import getAvailabilityState from "../../../../../../utils/getAvailabilityState";
import { ProductInfoPopUp, ProductTitle } from "../../../../../product";
import { productAttributes } from "../../../../../../api/productAttributes";
import { ProductData } from "../../../../../../types/productData";

interface WeekplannerItemProductSimpleProps {
  productData: ProductData;
  onClick: (sku: string) => void;
  className?: string;
}

const WeekplannerItemProductSimple: React.FC<
  WeekplannerItemProductSimpleProps
> = (props: WeekplannerItemProductSimpleProps) => {
  const { productData, onClick, className } = props;

  const [deliveryDate] = useGetDeliveryDate();

  const {
    brand,
    name,
    sku,
    attributes = {},
    availabilities = [],
  } = productData || {};

  const { [productAttributes.caliber]: caliber } = attributes;

  const { isUnavailable, explanation } = getAvailabilityState(
    availabilities,
    []
  );

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-product-simple",
        className
      )}
    >
      <ProductTitle
        deliveryDate={deliveryDate}
        sku={sku}
        showAsUnavailable={isUnavailable}
        attributes={attributes}
        availabilities={availabilities}
        title={
          <div>
            <strong>{name}</strong>, {brand}&nbsp;
            <span className="text-disabled">{caliber}</span>
          </div>
        }
        unavailableHint={explanation}
        onClick={() => {
          if (typeof onClick === "function") {
            onClick(sku);
          }
        }}
      />
      <ProductInfoPopUp
        attributes={attributes}
        sku={sku}
        className="infoIcon"
      />
    </div>
  );
};

export default React.memo(WeekplannerItemProductSimple, areEqual);
