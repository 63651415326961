import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { stocktakingEndpoint } from "../endpoints";
import { StocktakingData, StocktakingStatus } from "../../types/stocktaking";

interface PatchStocktakingProps {
  stocktakingId: number;
  referenceDate?: string;
  note?: string;
  status?: StocktakingStatus;
  cancelTokenSource: CancelTokenSource;
}

const patchStocktaking = async (props: PatchStocktakingProps) => {
  const { stocktakingId, referenceDate, note, status, cancelTokenSource } =
    props;

  const url = `${stocktakingEndpoint}/${stocktakingId}`;

  return axiosWithAuth()
    .patch(
      url,
      {
        data: {
          type: "stocktaking",
          attributes: { referenceDate, note, status },
        },
      },
      { cancelToken: cancelTokenSource.token }
    )
    .then((response): Promise<any> | StocktakingData => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data || {};

      return attributes as StocktakingData;
    });
};

export default patchStocktaking;
