import { Entry } from "contentful";

import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import { ContentfulSupportTutorialEntry } from "../../types/contentfulSupportTutorialEntry";

interface SupportTutorialParams {
  limit: number;
  skip: number;
  withTotal?: boolean;
}

interface SupportTutorialResponse {
  items: ContentfulSupportTutorialEntry[];
  total: number;
}

/**
 * @param limit
 * @param skip
 * @param withTotal
 *
 * @return Promise<any>
 */
const getSupportTutorials = async ({
  limit,
  skip,
  withTotal = true,
}: SupportTutorialParams): Promise<SupportTutorialResponse> =>
  getContentfulContentType({
    ...contentfulContentTypes.supportTutorial,
    limit,
    skip,
    order: "-sys.createdAt",
    withTotal,
  }).then((response: any) => {
    if (!response) {
      return Promise.reject(response);
    }
    return {
      items:
        response.items?.map((supportTutorialEntry: Entry<any>) => {
          const { name, description, videos } =
            supportTutorialEntry?.fields || {};

          return {
            id: supportTutorialEntry.sys.id,
            name: name || "",
            description: description || "",
            videos,
          } as ContentfulSupportTutorialEntry;
        }) || [],
      total: response.total,
    } as SupportTutorialResponse;
  });

export default getSupportTutorials;
