import React, { useMemo } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { areEqual } from "react-window";

import useGetWeekplannerDaysToShow from "../../../../../../hooks/useGetWeekplannerDaysToShow";
import useGetWeekplannerWeekDaysData from "../../../../../../hooks/useGetWeekplannerWeekDaysData";
import WeekplannerItemCart from "./WeekplannerItemCart";
import WeekplannerItemExpired from "./WeekplannerItemExpired";
import WeekplannerItemEmpty from "./WeekplannerItemEmpty";
import { ProductData } from "../../../../../../types/productData";
import { RootState } from "../../../../../../types/rootState";

interface WeekplannerItemCartsProps {
  productData: ProductData;
  className?: string;
  showUnavailableHeader?: boolean;
}

const WeekplannerItemCarts: React.FC<WeekplannerItemCartsProps> = (
  props: WeekplannerItemCartsProps
) => {
  const { productData, className, showUnavailableHeader = true } = props;

  const { filteredDaysToShow } = useGetWeekplannerDaysToShow();
  const { weekDaysData } = useGetWeekplannerWeekDaysData();

  const alreadyOrderedItemsForDate = useSelector(
    (state: RootState) => state.alreadyOrdered?.orderItemIndex || {}
  );

  const filteredWeekDaysData = useMemo(
    () =>
      weekDaysData.filter(
        (weekDayData: any) => !!filteredDaysToShow?.[weekDayData.day]
      ),
    [filteredDaysToShow, weekDaysData]
  );

  if (filteredWeekDaysData?.length < 1) {
    return null;
  }

  return (
    <div className={clsx("weekplanner-item-carts", className)}>
      {filteredWeekDaysData.map((weekDayData: any) => {
        const { apiDate, cartId, isPastDate } = weekDayData;
        const { sku, prices } = productData || {};

        const pastOrderedCount = alreadyOrderedItemsForDate?.[sku]?.[apiDate];

        if (sku && isPastDate && !!pastOrderedCount) {
          return (
            <WeekplannerItemExpired
              key={`weekplanner-item-cart_${apiDate}_${cartId}_${sku}`}
              count={pastOrderedCount}
            />
          );
        }

        if (sku && !isPastDate && prices) {
          return (
            <WeekplannerItemCart
              key={`weekplanner-item-cart_${apiDate}_${cartId}_${sku}`}
              cartId={cartId}
              deliveryDate={apiDate}
              productData={productData}
              showCurrentVolumePrice
              pastOrderedProduct={pastOrderedCount}
              showUnavailableHeader={showUnavailableHeader}
            />
          );
        }

        return (
          <WeekplannerItemEmpty
            key={`weekplanner-item-cart_${apiDate}_${cartId}_${sku}`}
          />
        );
      })}
    </div>
  );
};

export default React.memo(WeekplannerItemCarts, areEqual);
