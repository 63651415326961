import React from "react";
import { Entry } from "contentful";
import NewsContentBlock from "./NewsContentBlock";

/**
 * wrapper to create news content blocks
 * and to avoid always the same props logic
 * @param contentBlocks
 */
const generatorNewsContentBlock = (contentBlocks: Entry<any>[]) => {
  if (!contentBlocks || !Array.isArray(contentBlocks)) {
    return null;
  }

  return contentBlocks.map(
    (contentBlockEntry: Entry<any>, index: number) =>
      contentBlockEntry?.sys?.contentType?.sys?.id === "news-content-block" && (
        <NewsContentBlock
          key={contentBlockEntry?.sys?.id}
          alignment={index % 2 === 0 ? "left" : "right"}
          fields={contentBlockEntry?.fields}
        />
      )
  );
};

export default generatorNewsContentBlock;
