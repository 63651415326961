import axiosWithAuth from "../axiosWithAuth";
import { favouriteListEndpoint } from "../endpoints";
import { FetchUserLists } from "../../types/userLists";
import storeDispatch from "../../state/actions/storeDispatch";

/**
 * remove a product sku from favourite lists
 * @param {CancelTokenSource} cancelTokenSource
 * @param {string[]} listNames
 * @param {string} skuList
 * @return {Promise<AxiosResponse>}
 */
const deleteProductsFromFavouritesLists = ({
  cancelTokenSource,
  listNames = ["default"],
  skuList,
}: FetchUserLists) =>
  axiosWithAuth()
    .delete(`${favouriteListEndpoint}default/favourites/multi`, {
      data: {
        data: {
          type: "favourites",
          attributes: {
            idFavouriteListList: listNames,
            skuList,
          },
        },
      },
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response || response.status !== 204) {
        return Promise.reject(response);
      }

      // remove the given sku from the redux list to update UI
      storeDispatch("favouriteList/remove-skus", skuList);

      return response;
    });

export default deleteProductsFromFavouritesLists;
