import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Result, Spin } from "antd";
import clsx from "clsx";
import {
  createFilterDataObjects,
  resetFilterStatesWithoutDefaults,
  updateFilterStates,
} from "./filterFunctions";
import FiltersMobileHeader from "./FiltersMobileHeader";
import FiltersMobileEntry from "./FiltersMobileEntry";
import FiltersMobileSubmenu from "./FiltersMobileSubmenu";
import { getFilterStates } from "../../../api/products/getProductsFilters";
import { messageData } from "../../../appConfig";

interface Props {
  closeMobileFilters: () => void;
  loading: boolean;
  quantity: number;
  className?: string;
}

const FiltersMobile = function FiltersMobile({
  closeMobileFilters,
  loading,
  quantity = 0,
  className = "",
}: Props) {
  const { availableFilters, filterStates, slots } = useSelector(
    (state: any) => state.productsMetaData.filters
  );
  const valueDataSlots = useMemo(
    () => createFilterDataObjects(availableFilters, slots)?.valueDataSlots,
    [availableFilters, slots]
  );

  const navigate = useNavigate();
  const { search } = useLocation();

  const [parentView, setParentView] = useState<any>(valueDataSlots);
  const [currentView, setCurrentView] = useState<any>(valueDataSlots);
  const [selectedViews, setSelectedViews] = useState<string[]>([]);
  const [expandedSlotName, setExpandedSlotName] = useState<string>("0");

  /**
   * determines the current view.
   * @param path {string[]} Path to the view that is to be determined
   * @returns {any} current view
   */
  const getCurrentView = useCallback(
    (path: string[]) => {
      let nextView = { ...valueDataSlots };
      path.forEach((view: string) => {
        if (!nextView) {
          return;
        }
        nextView = nextView[view];
      });
      return nextView;
    },
    [valueDataSlots]
  );

  /**
   * determines the parent view
   * @param childrenPath {string[]} Path to the children view for which the parent view is to be determined
   * @returns {any} parent view
   */
  const getParentView = useCallback(
    (childrenPath: string[]) => {
      const parentPath = [...childrenPath];
      parentPath.pop();
      return getCurrentView(parentPath);
    },
    [getCurrentView]
  );

  /**
   * determines the views
   *
   * Will be executed every time the selectedViews array has changed
   */
  const determineViews = useCallback(() => {
    setCurrentView(getCurrentView(selectedViews));
    setParentView(getParentView(selectedViews));
  }, [selectedViews, getCurrentView, getParentView]);

  /**
   * executed every time the goBack button has been triggered
   *
   * Splices the last two items of the selectedViews array and sets the new array to selectedViews to update the view path.
   * If selectedViews has no entries, it will close the FiltersMobile component
   */
  const goBack = () => {
    const selectedView =
      typeof selectedViews[0] !== "undefined" ? selectedViews[0] : null;
    const numViewsToDelete = selectedView === expandedSlotName ? 4 : 2;

    if (selectedView === null) {
      closeMobileFilters();
    } else {
      setSelectedViews((prevSelectedViews) => {
        const copy = [...prevSelectedViews];
        copy.splice(copy.length - numViewsToDelete, numViewsToDelete);
        return copy;
      });
    }
  };

  /**
   * create array for updateFilterStates
   *
   * This mimics the default behavior of an <select> value array
   * which excepts only Array<string> as value
   * @param filterName {string}
   * @param value {string|number}
   * @returns {any[]} updated filter values
   */
  const createArrayForUpdateFilters = (filterName: any, value: any): any[] => {
    const queriedFilter = getFilterStates()[filterName];
    const strValue = value.toString();
    // update the value of existing entries
    if (Array.isArray(queriedFilter)) {
      let newFilterValues: any[];

      if (!queriedFilter.includes(strValue)) {
        newFilterValues = [...queriedFilter, strValue];
      } else {
        newFilterValues = queriedFilter.filter(
          (currentValue: any) => currentValue !== strValue
        );
      }

      return newFilterValues;
    }

    return [strValue];
  };

  /**
   * reset all filter parameters
   */
  const resetFilterStatesClickHandler = () => {
    resetFilterStatesWithoutDefaults(navigate, search);
  };

  /**
   * update the filters in the submenus
   * @param item
   * @param selectIdentifier {string}
   */
  const updateFilterStatesClickHandler = (
    item: any,
    selectIdentifier: string = parentView.name
  ) => {
    updateFilterStates(
      navigate,
      selectIdentifier,
      createArrayForUpdateFilters(selectIdentifier, item.value),
      search
    );
  };

  /**
   * set the view for the submenu depending on the selected key
   * @param key {String}
   * @param isSelected {boolean}
   * @param viewsToSelect {String[]}
   * @param isSlotExpanded {boolean}
   */
  const setNewSelectedViews = (
    key: string,
    isSelected?: boolean,
    viewsToSelect: string[] = selectedViews,
    isSlotExpanded = false
  ) => {
    let selectedViewsWithKey = [...viewsToSelect, key];
    const nextView = getCurrentView(selectedViewsWithKey);
    if (nextView?.slotFilters) {
      selectedViewsWithKey.push("slotFilters");
    } else if (nextView?.values) {
      selectedViewsWithKey.push("values");
    } else {
      // boolean value with no children -> change the selected filter
      if (isSlotExpanded) {
        selectedViewsWithKey = [];
      } else {
        selectedViewsWithKey.pop();
      }
      updateFilterStates(navigate, key, !isSelected, search);
    }
    setSelectedViews(selectedViewsWithKey);
  };

  /**
   * Wrapper for setNewSelectedViews to alter default behavior
   * Enables us to force a slot to render its filters on root level (expand filters)
   * @param key {String}
   * @param isSelected {boolean}
   */
  const setNewSelectedViewsExpandedSlot = (
    key: string,
    isSelected?: boolean
  ): void => {
    setNewSelectedViews(
      key,
      isSelected,
      [expandedSlotName, "slotFilters"],
      true
    );
  };

  useEffect(() => {
    determineViews();
  }, [selectedViews.length, determineViews]);

  useEffect(() => {
    if (slots) {
      setExpandedSlotName(Object.keys(slots)[0]);
    }
  }, [slots]);

  return (
    <div className={clsx("filters-mobile", className)}>
      <div className="filters-mobile__content">
        {/* Header */}
        <FiltersMobileHeader
          goBack={goBack}
          currentView={currentView}
          parentView={parentView}
          resetFilterStatesClickHandler={resetFilterStatesClickHandler}
          closeHandler={closeMobileFilters}
        />

        {/* View */}

        {/* If view contains slots and current slot should be expanded => show all slot filters at root level */}
        {/* If view contains slots and current slot is a regular slot => just render slot */}
        {!loading && !!quantity && currentView && !currentView.length && (
          <ul className="filters-mobile__main">
            {Object.entries(currentView).map(
              ([dataSlotsName, dataSlotsValues]: [string, any]) => {
                if (dataSlotsName === expandedSlotName) {
                  return Object.entries(dataSlotsValues.slotFilters).map(
                    ([filterName, filterValues]: [string, any]) => (
                      <FiltersMobileEntry
                        key={filterName}
                        filterStates={filterStates}
                        dataSlotsName={filterName}
                        dataSlotsValues={filterValues}
                        onClickHandler={setNewSelectedViewsExpandedSlot}
                      />
                    )
                  );
                }

                return (
                  dataSlotsValues && (
                    <FiltersMobileEntry
                      key={dataSlotsName}
                      filterStates={filterStates}
                      dataSlotsName={dataSlotsName}
                      dataSlotsValues={dataSlotsValues}
                      onClickHandler={setNewSelectedViews}
                    />
                  )
                );
              }
            )}
          </ul>
        )}

        {/*  If view contains a submenu => render submenu */}
        {!loading && !!quantity && currentView && currentView.length && (
          <FiltersMobileSubmenu
            currentView={currentView}
            selectedFilterData={filterStates[parentView?.name] || []}
            updateFilterStatesClickHandler={updateFilterStatesClickHandler}
            filterName={parentView.name}
          />
        )}

        {/* No results available => show reset filters option */}
        {!loading && !quantity && (
          <p className="filters-mobile__main">
            <Result
              title={messageData.warning.noProductResults.content}
              extra={
                <button
                  type="button"
                  className="buttonText buttonClearAllFilters"
                  onClick={() => resetFilterStatesClickHandler()}
                >
                  Filter <span className="text-underline">zurücksetzen</span>
                </button>
              }
            />
          </p>
        )}

        {/* Loading */}
        {loading && (
          <div className="filters-mobile__main">
            <Spin spinning={loading} />
          </div>
        )}

        {/* Footer */}
        {!loading && !!quantity && (
          <div className="filters-mobile__footer">
            <div className="filters-mobile__footer--button">
              <button
                type="button"
                className="button buttonPrimary"
                onClick={() => {
                  closeMobileFilters();
                }}
              >
                <Spin spinning={loading}>{`${quantity} Artikel ansehen`}</Spin>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FiltersMobile;
