import React from "react";

import clsx from "clsx";
import { Badge, Button } from "antd";

import { ReactComponent as FilterIcon } from "../../../static/svg/filter_offen.svg";

interface SelectedFiltersButtonProps {
  type: "primary" | "default";
  count?: number;
  onClick: () => void;
  className?: string;
}

const SelectedFiltersButton: React.FC<SelectedFiltersButtonProps> = (props) => {
  const { type = "default", count = 0, onClick, className } = props;

  return (
    <Button
      shape="round"
      type={type}
      onClick={onClick}
      icon={<FilterIcon className="icon" />}
      className={clsx(
        "selected-filters-button",
        `selected-filters-button--${type}`,
        className
      )}
    >
      Alle Filter
      {!!count && Number(count) > 0 && <Badge count={count} />}
    </Button>
  );
};

export default SelectedFiltersButton;
