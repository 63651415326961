import React, { useCallback, useEffect, useState } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { Affix } from "antd";

import useMedia from "../../../hooks/useMedia";
import findNestedKeyInObject from "../../../utils/findNestedKeyInObject";
import aggregateCategoriesArray from "../../../utils/aggregateCategoriesArray";
import getCssVariable from "../../../utils/getCssVariable";
import DynamicFilter from "../DynamicFilter";
import SortDropdown from "../SortDropdown";
import { SearchFilter, SelectedFiltersButton } from "../../atoms";
import { routeProductCategoryKeys } from "../../../appConfig";
import { Filter } from "../../../types/filters";
import { RootState } from "../../../types/rootState";

interface FilterBarProps {
  showFilters?: boolean;
  setShowFilters?: (value: boolean) => void;
  maxQuickFilters?: number;
  showSearchFilter?: boolean;
  showFiltersToggle?: boolean;
  selectedFiltersCount?: number;
  withAffix?: boolean;
  view?: string;
}

/*
 * Use this config array to prevent filters to be shown in filter bar
 *
 * Structure of config array:
 *  Item: string representing a filter name
 */
const hiddenFilters = ["category", "filterCategory"];

/*
 * Use this config object to overwrite default filter bar filters per category
 *
 * Structure of config object:
 *  Key: category id of category to overwrite filters for as string
 *  Value: array of arrays of strings each representing a filter name
 *
 * Hint:
 *  Order of filter names in config arrays is used as-is when displaying filters
 *
 * Examples:
 *
 *  1.) Replace filters for all child categories of a parent
 *    {"parentCategoryKey": [["filterName1", "filterName2"]]}
 *
 *  2.) Replace filters for all child categories of a parent but use a different filter set from second level
 *    {"parentCategoryKey": [["filterName1", "filterName2"], ["filterName1", "filterName3", "filter4"]]}
 *    Hint: 3-rd level uses filters of second level and so on
 *
 *  3.) Replace filters for all child categories of a parent but use a different filter set from third level
 *    {"parentCategoryKey": [["filterName1"], [], ["filterName3", "filter4"]]}
 *    Hint: 2-nd level uses filters of  first level
 */
const filterOverwrites: { [key: string]: any } = {
  [routeProductCategoryKeys.cheese]: [
    ["artikelaktionsprodukt", "artikelbioladenprodukt", "kaesetierartmilch"],
    ["artikelaktionsprodukt", "kaesetierartmilch", "artikelbioladenprodukt"],
    [
      "artikelaktionsprodukt",
      "kaesetierartmilch",
      "herstellermarkenpreislistentextfuerausgabe",
      "qualitaetenbezeichnung",
    ],
  ],
  [routeProductCategoryKeys.fruitsAndVeggies]: [
    [
      "artikelwarengruppe",
      "artikelaktionsprodukt",
      "artikelbioladenprodukt",
      "artikelbioladenfair",
      "artikelgv",
      "herstellermarkenpreislistentextfuerausgabe",
      "herstellermarkenname",
      "qualitaetenname",
    ],
    ["*"],
  ],
};

const FilterBar: React.FC<FilterBarProps> = (props) => {
  const {
    showFilters,
    setShowFilters,
    maxQuickFilters = 7,
    showSearchFilter = false,
    showFiltersToggle = true,
    selectedFiltersCount = 0,
    withAffix = false,
    view = "",
  } = props;

  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-md")})`
  );

  const { availableFilters } = useSelector(
    (state: RootState) => state.productsMetaData.filters
  );

  const { categories, lastVisitedCategory: categoryKey } = useSelector(
    (state: RootState) => state.categoryNavigation
  );

  const [quickFilters, setQuickFilters] = useState<Filter[]>([]);

  const [overwriteFilterNames, setOverwriteFilterNames] = useState<string[]>(
    []
  );

  const getDefaultQuickFilters = useCallback((): Filter[] => {
    return availableFilters
      .filter((filter: Filter) => {
        if (filter.config.priority === null) {
          return false;
        }
        return !hiddenFilters.includes(filter.name);
      })
      .sort((a, b) => (b?.config?.priority || 0) - (a?.config?.priority || 0))
      .sort((a, b) => (b?.config?.slot || 0) - (a?.config?.slot || 0))
      .filter((_, index) => index < maxQuickFilters);
  }, [availableFilters, maxQuickFilters]);

  const getQuickFilters = useCallback((): Filter[] => {
    return availableFilters
      .filter((filter: Filter) => overwriteFilterNames.includes(filter.name))
      .sort(
        (a, b) =>
          overwriteFilterNames.indexOf(a.name) -
          overwriteFilterNames.indexOf(b.name)
      );
  }, [overwriteFilterNames, availableFilters]);

  useEffect(() => {
    const children = categories?.children || [];

    if (!children.length) {
      setOverwriteFilterNames([]);
      return;
    }

    const category = findNestedKeyInObject(
      children,
      categoryKey,
      "categoryKey",
      "children"
    );

    if (!category) {
      setOverwriteFilterNames([]);
      return;
    }

    const categoryPath = aggregateCategoriesArray(category, children, []);
    const overwriteCategoryKeys = Object.keys(filterOverwrites);
    const overwriteCategoryKey = categoryPath.find((cat: any) =>
      overwriteCategoryKeys.includes(cat.categoryKey)
    )?.categoryKey;

    if (!overwriteCategoryKey) {
      setOverwriteFilterNames([]);
      return;
    }

    const categoryFilterOverwrites = filterOverwrites[overwriteCategoryKey];
    const filterNames = categoryFilterOverwrites
      .slice(0, categoryPath.length)
      .reverse()
      .find((names: string[]) => names && names?.length);

    if (
      !Array.isArray(filterNames) ||
      !filterNames.length ||
      filterNames?.[0] === "*"
    ) {
      setOverwriteFilterNames([]);
      return;
    }
    setOverwriteFilterNames(filterNames);
  }, [categoryKey, categories?.children]);

  useEffect(() => {
    setQuickFilters(
      overwriteFilterNames?.length ? getQuickFilters : getDefaultQuickFilters
    );
  }, [overwriteFilterNames, getQuickFilters, getDefaultQuickFilters]);

  const content = (
    <>
      <div id="filter-bar__dropdown" className={clsx("filter-bar__dropdown")} />
      <div
        className={clsx(
          "filter-bar",
          !browserIsDesktop && "filter-bar--mobile",
          showSearchFilter && "filter-bar--with-search",
          showFiltersToggle && "filter-bar--with-toggle"
        )}
      >
        <SortDropdown
          className={clsx(
            "filter-bar__select-sort",
            "filter-bar__col",
            !browserIsDesktop && "filter-bar__col--mobile"
          )}
          type={browserIsDesktop ? "default" : "mobile"}
        />

        {!!showSearchFilter && (
          <SearchFilter
            className={clsx(
              "filter-bar__search",
              "filter-bar__col",
              !browserIsDesktop && "filter-bar__col--mobile"
            )}
            resetPagination
          />
        )}

        <div
          className={clsx(
            "filter-bar__quick-filters",
            "hidden-sm-down",
            "filter-bar__col"
          )}
        >
          <div className={clsx("filter-bar__quick-filters__inner")}>
            {quickFilters.map((singleQuickFilter, index) => (
              <DynamicFilter
                key={index}
                display="filter-bar"
                {...singleQuickFilter}
                view={view}
              />
            ))}
          </div>
        </div>

        {showFiltersToggle && (
          <div
            className={clsx(
              "filter-bar__filters-toggle",
              "filter-bar__col",
              "filter-bar__col--mobile"
            )}
          >
            <SelectedFiltersButton
              type={showFilters ? "primary" : "default"}
              onClick={() => setShowFilters(!showFilters)}
              count={selectedFiltersCount}
            />
          </div>
        )}
      </div>
    </>
  );

  if (!withAffix) {
    return content;
  }

  return (
    <Affix offsetTop={120} className="filter-bar__affix">
      <div>{content}</div>
    </Affix>
  );
};

export default FilterBar;
