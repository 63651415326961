import React, { useState } from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";
import { Col, Row, Skeleton } from "antd";
import moment from "moment/moment";

import useGetAllCartsCheapMetadata from "../../../hooks/useGetAllCartsCheapMetadata";
import useGetCarts from "../../../hooks/useGetCarts";
import { ContentBlock, PictureTile } from "../../atoms";
import { ExpiredCartsWidget, OpenCartsWidget } from "../../molecules";
import Picture from "../../picture/Picture";
import {
  pageTitles,
  pageTitleSuffix,
  routePathNames,
  routeProductCategoryKeys,
} from "../../../appConfig";
import { apiDateFormat } from "../../../utils/dateFormats";
import BioladenOpenCartTeaser from "../../../static/images/bioladen-teaser-open-cart-@x1.jpg";
import TrackingHelmet from "../../Matomo/TrackingHelmet";

const Carts: React.FC = () => {
  const [forceExpiredCartsReload, setForceExpiredCartsReload] =
    useState<boolean>(false);

  const { isLoading, hasError, openCarts } = useGetAllCartsCheapMetadata();

  const expiredCartsFrom = moment().subtract(14, "days").format(apiDateFormat);
  const expiredCartsTo = moment().format(apiDateFormat);
  const {
    isLoading: isLoadingExpiredCarts,
    hasError: hasErrorExpiredCarts,
    carts: expiredCarts,
    productData: expiredProductData,
  } = useGetCarts(expiredCartsFrom, expiredCartsTo, forceExpiredCartsReload);

  const onDeleteExpiredCart = () => {
    setForceExpiredCartsReload(!forceExpiredCartsReload);
  };

  return (
    <>
      <TrackingHelmet title={pageTitles.carts} suffix={pageTitleSuffix} />

      <ContentBlock
        className={clsx("carts-page")}
        title="Offene Warenkörbe"
        showBackButton
      >
        <Row gutter={[10, 10]}>
          {isLoading && (
            <Col span={12}>
              <Skeleton active />
            </Col>
          )}

          {!isLoading && !!openCarts && (
            <Col span={12}>
              <OpenCartsWidget
                openCarts={openCarts}
                isLoading={isLoading}
                hasError={hasError}
                showCartDetails
                showHeader={false}
                singleColMode={false}
                filler={
                  openCarts.length &&
                  openCarts.length % 2 !== 0 && (
                    <Link
                      to={`${routePathNames.products}${routePathNames.category}${routeProductCategoryKeys.fruitsAndVeggies}`}
                    >
                      <PictureTile
                        title={null}
                        overlayPosition="hidden"
                        picture={
                          <Picture imageScreenSm={BioladenOpenCartTeaser} />
                        }
                        link={null}
                      />
                    </Link>
                  )
                }
              />
            </Col>
          )}
        </Row>
      </ContentBlock>

      <ContentBlock
        className={clsx("carts-page-expired-carts")}
        title="Abgelaufene Warenkörbe"
      >
        <Row gutter={[10, 10]}>
          {isLoadingExpiredCarts && (
            <Col span={12}>
              <Skeleton active />
            </Col>
          )}

          {!isLoadingExpiredCarts && !!expiredCarts && (
            <Col span={12}>
              <ExpiredCartsWidget
                carts={expiredCarts}
                productData={expiredProductData}
                isLoading={isLoadingExpiredCarts}
                hasError={hasErrorExpiredCarts}
                showHeader={false}
                onDeleteCart={onDeleteExpiredCart}
              />
            </Col>
          )}
        </Row>
      </ContentBlock>
    </>
  );
};

export default Carts;
