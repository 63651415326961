import React from "react";
import ProductAlternativesModal from "../ProductModal/ProductAlternativesModal";
import ProductTileInner from "./ProductTileInner";
import ProductTileWrapper from "./ProductTileWrapper";
import AddToCartDispatch from "./ProductTileAddToCartContext";
import { ProductTileProps } from "../../../types/productTileProps";
import useHasDeliveryDateProductAlternative from "../../../hooks/useHasDeliveryDateProductAlternative";

/**
 * Product Tile with an alternatives Modal
 * has to be a seperated component to prevent import loops
 * @param deliveryDate {string}
 * @param productData {ProductData}
 * @param addToCartCallback {Function}
 * @param setFocusOnSearchInput
 * @constructor
 */
const ProductTileWithModal: React.FC<ProductTileProps> =
  function ProductTileWithModal({
    deliveryDate,
    productData,
    addToCartCallback = () => true,
    setFocusOnSearchInput,
  }: ProductTileProps) {
    const { sku } = productData;

    const { hasDeliveryDateProductAlternative, isUnavailable } =
      useHasDeliveryDateProductAlternative({ deliveryDate, productData });

    return (
      <AddToCartDispatch.Provider value={addToCartCallback}>
        <ProductTileWrapper
          deliveryDate={deliveryDate}
          productData={productData}
        >
          <ProductTileInner
            deliveryDate={deliveryDate}
            productData={productData}
            setFocusOnSearchInput={setFocusOnSearchInput}
          />

          <ProductAlternativesModal
            deliveryDate={deliveryDate}
            sku={sku}
            hasProductAlternatives={
              isUnavailable && hasDeliveryDateProductAlternative
            }
          />
        </ProductTileWrapper>
      </AddToCartDispatch.Provider>
    );
  };

export default ProductTileWithModal;
