import { ProductData } from "../types/productData";
import { OrderProductData } from "../types/orderProductData";
import { locationSearchQueryParameter } from "../appConfig";
import { getFilterStates } from "../api/products/getProductsFilters";
import { sortArrayOfObjectsByObjectKey } from "./sorting";

/**
 * wrapper for simplified product sorting
 * if a sort filter is set in url search param, ignore default
 * @param a {ProductData | OrderProductData}
 * @param b {ProductData | OrderProductData}
 * @return {Function<SortArrayOfObjectsByObjectKey> | 0}
 */
const sortProductsBySortIndex = (
  a: ProductData | OrderProductData,
  b: ProductData | OrderProductData
) => {
  const { [locationSearchQueryParameter.sortBy]: isSortedByUser } =
    getFilterStates();

  // do not sort, if url parameter is set
  if (isSortedByUser) {
    return 0;
  }

  // sort by default based on the sortIndex, from high to low (desc)
  return sortArrayOfObjectsByObjectKey({
    a,
    b,
    objectKey: "sortIndex",
    sortOrder: "desc",
  });
};

export default sortProductsBySortIndex;
