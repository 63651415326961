import React, { useEffect, useState } from "react";
import { InputNumber, Spin, Tooltip } from "antd";
import clsx from "clsx";
import { ReactComponent as Close } from "../../static/svg/close.svg";
import ProductAlreadyOrderedBatch from "./ProductAlreadyOrderedBatch";

interface Props {
  value: number;
  highlightThreshold?: number | false;
  className?: string;
  containerClassName?: string;
  alertMessage?: string;
  setAlertMessage?: (alertMessage: string) => void;
  tabIndex?: number;
  handleChange: any;
  isLoading: boolean;
  controls?: boolean;
  pastOrderedProduct?: any;
  decimalSeparator?: string;
  max?: number;
  maxLength?: number;
  precision?: number;
  stringMode?: boolean;
  onPressEnter?: () => void;
  getContainer?: () => HTMLElement;
  useAddons?: boolean;
}

/**
 * wrapper for numbered input for product quantity and user price values
 * @param {number} value
 * @param {string} className
 * @param {string} containerClassName
 * @param {number | false} highlightThreshold
 * @param {string} alertMessage
 * @param {(alertMessage: string) => void} setAlertMessage
 * @param {number} handleChange
 * @param {any} tabIndex
 * @param {boolean} isLoading
 * @param {boolean} controls
 * @param {any} pastOrderedProduct
 * @param {string} decimalSeparator
 * @param {number} max
 * @param {number} maxLength
 * @param {number} precision
 * @param {boolean} stringMode
 * @param {() => void} onPressEnter
 * @param getContainer
 * @param {boolean} useAddons
 * @constructor
 */
const ProductCount: React.FC<Props> = function ProductCount({
  value: defaultValue,
  className,
  containerClassName,
  highlightThreshold = -1,
  alertMessage = "",
  setAlertMessage,
  handleChange,
  tabIndex = 3, // to have the posibility to start with 0 at an more important input (search, f.e.)
  isLoading,
  controls = true,
  decimalSeparator = "",
  max = 999,
  pastOrderedProduct,
  maxLength = 3,
  precision = 0,
  stringMode = false,
  onPressEnter,
  getContainer,
  useAddons = false,
}: Props) {
  const handleClose = () => {
    if (typeof setAlertMessage === "function") {
      setAlertMessage("");
    }
  };

  useEffect(() => {
    let alertTimer: number;

    if (typeof setAlertMessage === "function") {
      alertTimer = window.setTimeout(() => {
        setAlertMessage("");
      }, 4000);
    }

    return () => {
      if (alertTimer) {
        window.clearTimeout(alertTimer);
      }
    };
  }, [alertMessage, setAlertMessage]);

  const [value, setValue] = useState<number>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={clsx(
        "productCount",
        useAddons && "with-addons",
        containerClassName
      )}
    >
      <Spin size="small" color="white" spinning={isLoading} indicator={null}>
        <Tooltip
          arrowPointAtCenter
          visible={alertMessage.length > 0}
          overlayClassName="productCountMsg"
          title={
            <>
              {alertMessage}
              {alertMessage.length > 0 && (
                <button
                  type="button"
                  className="productCountMsgClose"
                  onClick={handleClose}
                >
                  <Close className="icon" />
                </button>
              )}
            </>
          }
          getTooltipContainer={getContainer}
        >
          <InputNumber
            {...(!stringMode
              ? {
                  type: "number",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }
              : {})}
            min={0}
            maxLength={maxLength}
            max={max}
            tabIndex={tabIndex}
            value={value}
            disabled={isLoading}
            className={clsx(
              "productCountInner",
              highlightThreshold > -1 &&
                value > highlightThreshold &&
                "isHighlighted",
              isLoading && "disabledInput",
              className
            )}
            onChange={(newValue) => {
              setValue(newValue);
              handleChange(newValue);
            }}
            onClick={(e: React.MouseEvent<HTMLInputElement>): void => {
              e.currentTarget.select();
            }}
            controls={useAddons ? false : controls}
            decimalSeparator={decimalSeparator}
            precision={precision}
            stringMode={stringMode}
            onPressEnter={onPressEnter}
            {...(useAddons
              ? {
                  addonAfter: (
                    <button
                      disabled={isLoading || value >= max}
                      type="button"
                      onClick={() => {
                        setValue((prevValue: number) => {
                          const newValue = prevValue + 1;
                          handleChange(newValue);
                          return newValue;
                        });
                      }}
                    >
                      +
                    </button>
                  ),
                  addonBefore: (
                    <button
                      disabled={isLoading || value < 1}
                      type="button"
                      onClick={() => {
                        setValue((prevValue: number) => {
                          const newValue = prevValue - 1;
                          handleChange(newValue);
                          return newValue;
                        });
                      }}
                    >
                      -
                    </button>
                  ),
                }
              : {})}
          />
        </Tooltip>
      </Spin>
      <ProductAlreadyOrderedBatch count={pastOrderedProduct} />
    </div>
  );
};

export default ProductCount;
