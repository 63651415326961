import { Col, Form, Input, Row, message } from "antd";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import postResetUserPassword from "../../../api/password/postResetUserPassword";
import {
  messageData,
  pageTitleSuffix,
  pageTitles,
  routePathNames,
} from "../../../appConfig";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import FormSubmitFooter from "../FormSubmitFooter";

function ResetPasswordForm() {
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // Function gets fired on Form Submit
  const onFinish = async (values: any) => {
    // Starts button loading animation
    setLoading(true);

    // Executes login function that sends an axios request to the login endpoint
    const response = await postResetUserPassword(values.username);

    // Stops button loading animation
    setLoading(false);

    // Checks for Success or failure of request
    if (response === 200 || response === 204) {
      message.success(messageData.success.auth.resetPassword);

      setTimeout(() => {
        setRedirect(true);
      }, 3000);
    } else {
      message.error(messageData.error.auth.resetPassword);
    }
  };

  if (redirect) {
    return <Navigate to={routePathNames.login} />;
  }

  return (
    <>
      <TrackingHelmet
        title={pageTitles.resetPassword}
        suffix={pageTitleSuffix}
      />

      <Row>
        <Col xs={12} md={6} xl={5} xxl={3}>
          <Form
            name="passwort-vergessen"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onSubmit={() => {
              setLoading(true);
            }}
          >
            <Row gutter={[0, 8]}>
              <Col span={12}>
                <Form.Item
                  name="username"
                  hasFeedback
                  validateStatus=""
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Bitte gültige E-Mail Adresse eingeben!",
                    },
                  ]}
                >
                  <Input
                    placeholder="E-Mail *"
                    className="defaultInputHeight"
                    autoComplete="username"
                  />
                </Form.Item>
              </Col>
            </Row>

            <FormSubmitFooter
              isLoading={loading}
              submitButtonText="abschicken"
              linkTo="login"
            />
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default ResetPasswordForm;
