import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Picture from "../picture/Picture";
import { ImageSet } from "../../types/productData";
import useGetProductDetailLink from "../../hooks/useGetProductDetailLink";
import DefaultProductImage from "../../static/images/default-product-image.jpg";

/**
 * image variants
 *
 * @property "XXS" = 75px * 56px
 * @property "XS" = 90px * 68px
 * @property "S" = 105px * 79px
 * @property "SM" = 120px * 90px
 * @property "M" = 160px * 120px
 * @property "ML" = 220px * 165px
 * @property "L" = 300px * 225px
 * @property "XL" = 440px * 330px
 * @property "2XL" = 720px * 540px
 * @property "3XL" = 753px * 551px
 */
type ImageVariant =
  | "XXS"
  | "XS"
  | "S"
  | "SM"
  | "M"
  | "ML"
  | "L"
  | "XL"
  | "2XL"
  | "3XL";

/**
 * replaces image dimension
 * @param {string} imagePath
 * @param {ImageVariant} imageVariant
 * @returns {string}
 */
const imageDimensionReplacer = (
  imagePath: string,
  imageVariant: ImageVariant
) => {
  const variant = imagePath?.endsWith("/") ? imageVariant : `/${imageVariant}`;
  return `${imagePath}${variant}`;
};

/**
 * wrapper for product detail image
 * this image visual is highly dynamical
 * use both image resources, to preserve possible parameters intrinsic to viewport
 * @param {ImageSet} imageSet
 * @returns {object}
 */
const getProductDetailImages = (imageSet: ImageSet) => {
  const { externalUrlSmall, externalUrlLarge } = imageSet;

  return {
    imageScreenXs: imageDimensionReplacer(externalUrlLarge, "XL"),
    imageScreenSm: imageDimensionReplacer(externalUrlLarge, "2XL"),
    imageScreenMd: imageDimensionReplacer(externalUrlSmall, "ML"),
    imageScreenLg: imageDimensionReplacer(externalUrlSmall, "XL"),
    imageScreenXl: imageDimensionReplacer(externalUrlLarge, "3XL"),
  };
};

interface Props {
  imageSet: ImageSet | string;
  alt?: string;
  className?: string;
  imageType?:
    | "detail"
    | "thumbnail"
    | "weekplanner"
    | "productSlider"
    | "suggestion";
  sku?: string;
  onClick?: () => void;
}

/**
 * product image component for all components which display a product image
 * @param {ImageSet | string} imageSet
 * @param {string} alt
 * @param {string} sku
 * @param {string} className
 * @param {string} imageType
 * @param onClick
 * @constructor
 */
const ProductImage: React.FC<Props> = function ProductImage({
  imageSet,
  alt = "",
  sku,
  className,
  imageType,
  onClick,
}: Props) {
  const dispatch = useDispatch();
  const getProductDetailLink = useGetProductDetailLink();
  const productDetailLink = getProductDetailLink(sku);

  let externalUrlSmall: string;
  let externalUrlLarge: string;
  let imageScreenXs: string;
  let imageScreenSm: string;
  let imageScreenMd: string;
  let imageScreenLg: string;
  let imageScreenXl: string;
  let componentClass = "";
  let altTag = alt;

  if (typeof imageSet === "string") {
    externalUrlSmall = imageSet;
    externalUrlLarge = imageSet;
  } else {
    ({ externalUrlSmall, externalUrlLarge } = imageSet);
  }

  switch (imageType) {
    // detail page without a slider
    case "detail":
      ({
        imageScreenXs,
        imageScreenSm,
        imageScreenMd,
        imageScreenLg,
        imageScreenXl,
      } = getProductDetailImages({ externalUrlSmall, externalUrlLarge }));

      componentClass = "productImageDetail";
      break;
    // detail page with slider
    case "productSlider":
      ({
        imageScreenXs,
        imageScreenSm,
        imageScreenMd,
        imageScreenLg,
        imageScreenXl,
      } = getProductDetailImages({ externalUrlSmall, externalUrlLarge }));

      componentClass = "productSliderImage";
      break;
    // thumnail, e.g. for slider
    case "thumbnail":
      imageScreenSm = imageDimensionReplacer(externalUrlSmall, "M");

      componentClass = "productImageThumbnail";
      altTag += " Thumbnail";
      break;
    // product image in weekplanner view
    case "weekplanner":
      imageScreenSm = imageDimensionReplacer(externalUrlSmall, "XXS");

      componentClass = "productImageList";
      break;
    // search suggistion thumnail
    case "suggestion":
      imageScreenSm = imageDimensionReplacer(externalUrlSmall, "XXS");

      componentClass = "productImageSuggest";
      break;
    // default case in productListing
    default:
      imageScreenXs = imageDimensionReplacer(externalUrlSmall, "XXS");

      imageScreenSm = imageDimensionReplacer(externalUrlSmall, "M");

      componentClass = "productImageList";
      break;
  }

  if (sku && imageType !== "detail") {
    return (
      <Link
        to={productDetailLink}
        className={clsx("productImage", className, componentClass)}
        onClick={(e: React.BaseSyntheticEvent) => {
          if (typeof onClick === "function") {
            e.preventDefault();
            onClick();
            return;
          }
          dispatch({
            type: "page/set-scroll",
            payload: {
              x: window?.pageXOffset || 0,
              y: window?.pageYOffset || 0,
            },
          });
        }}
      >
        <Picture
          imageScreenXs={imageScreenXs}
          imageScreenSm={imageScreenSm}
          imageScreenMd={imageScreenMd}
          imageScreenLg={imageScreenLg}
          imageScreenXl={imageScreenXl}
          alt={altTag}
          fallbackImage={DefaultProductImage}
        />
      </Link>
    );
  }

  return (
    <div className={clsx("productImage", className, componentClass)}>
      <Picture
        imageScreenXs={imageScreenXs}
        imageScreenSm={imageScreenSm}
        imageScreenMd={imageScreenMd}
        imageScreenLg={imageScreenLg}
        imageScreenXl={imageScreenXl}
        alt={altTag}
        fallbackImage={DefaultProductImage}
      />
    </div>
  );
};

export default ProductImage;
