import React from "react";
import { ReactComponent as Back } from "../../../static/svg/back.svg";
import { ReactComponent as Cross } from "../../../static/svg/close.svg";
import { showClearAllFiltersComponent } from "./filterFunctions";

interface Props {
  currentView: any;
  goBack: () => void;
  parentView: any;
  resetFilterStatesClickHandler: () => void;
  closeHandler: () => void;
}

const FiltersMobileHeader = function FiltersMobileHeader({
  currentView,
  goBack,
  parentView,
  resetFilterStatesClickHandler,
  closeHandler,
}: Props) {
  const showClearAll =
    showClearAllFiltersComponent() &&
    JSON.stringify(currentView) === JSON.stringify(parentView);

  return (
    <div className="filters-mobile__header">
      <button
        type="button"
        className="filters-mobile__header--back"
        onClick={goBack}
      >
        <Back />
      </button>

      <div className="filters-mobile__header--title">
        {parentView?.localizedName || parentView?.name || "Filter"}
      </div>

      {showClearAll && (
        <div className="filters-mobile__header--apply">
          <button
            type="button"
            className="filters-mobile__header--default"
            onClick={resetFilterStatesClickHandler}
          >
            Alle zurücksetzen
          </button>
        </div>
      )}

      <button
        type="button"
        className="filters-mobile__header--close"
        onClick={closeHandler}
      >
        <Cross />
      </button>
    </div>
  );
};

export default FiltersMobileHeader;
