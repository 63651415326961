import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductData } from "../../types/productData";
import { PriceTagListEntry } from "../../types/priceTagList";
import ProductCount from "../product/ProductCount";

interface Props {
  sku: ProductData["sku"];
}

/**
 * price input in priceTagList
 * @param {ProductData["sku"]} sku
 * @constructor
 */
const PriceTagListPriceInput = function PriceTagListPriceInput({ sku }: Props) {
  const dispatch = useDispatch();

  const defaultValue = useSelector(
    (state: any) =>
      state.priceTagList.priceTags?.find(
        (entry: PriceTagListEntry) => entry.sku === sku
      )?.price || null
  );

  /**
   * store the given value to the redux list
   * @param {string} value
   */
  const onChange = (value: string) => {
    dispatch({
      type: "priceTagList/add-price-to-sku",
      payload: {
        sku,
        price: value,
      },
    });
  };

  return (
    <ProductCount
      containerClassName="priceTagListPriceInput"
      controls={false}
      decimalSeparator=","
      handleChange={onChange}
      isLoading={false}
      max={999.99}
      maxLength={null}
      precision={2}
      stringMode
      value={defaultValue}
    />
  );
};

export default PriceTagListPriceInput;
