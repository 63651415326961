import moment from "moment";
import "moment/locale/de";
import { apiDateFormat, dayFormat } from "../../utils/dateFormats";

interface CartMessage {
  id: string;
  sku: string;
  quantity: string | number;
  quantityAdjusted: string | number;
  reason: string;
  createdAt: string;
  isDeleted: boolean;
  item: Record<string, any>[];
}

const initialState: any = {
  id: null,
  etag: "",
  deliveryDate: "",
  cartMessages: <CartMessage[]>[],
  cartNote: "",
  cartNoteKube: "",
  name: "",
  dayShort: "",
  mlwGroupDeficits: [],
};

const currentCartMetaDataReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "cartMeta/set-data":
      return {
        ...payload,
        dayShort: moment(payload.deliveryDate, apiDateFormat)
          .format(dayFormat)
          .slice(0, 2),
      };
    // only update date
    case "cartMeta/set-deliveryDate":
      return {
        ...state,
        deliveryDate: payload,
      };
    // update cartnote
    case "cartMeta/set-new-cart-note":
      return { ...state, ...payload };

    // delete single cartMessage
    case "cartMeta/delete-message": {
      const cartMessages = state.cartMessages.filter((message: CartMessage) => {
        if (Array.isArray(payload)) {
          return !payload.includes(message.sku);
        }

        return message.sku !== payload;
      });

      return {
        ...state,
        cartMessages,
      };
    }

    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default currentCartMetaDataReducer;
