import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Button, DatePicker, message, Spin } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { ReactComponent as Calendar } from "../../static/svg/deliverydate.svg";
import { apiDateFormat, fullDateFormat } from "../../utils/dateFormats";
import {
  locationSearchQueryParameter,
  messageData,
  routePathNames,
} from "../../appConfig";
import useGetDeliveryDateAndUpdateCartAccordingly from "../../hooks/useGetDeliveryDateAndUpdateCartAccordingly";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import useUpdateUrlFragments from "../../hooks/useUpdateUrlFragments";
import requestCatchHandler from "../../api/requestCatchHandler";
import { PageTypes } from "../../types/pageTypes";
import updateWeekplannerDates from "../../state/actions/updateWeekplannerDates";
import getAllCartsCheapMetadata from "../../api/cart/getAllCartsCheapMetadata";
import { CheapCartMetadata } from "../../types/cheapCartMetadata";
import disabledDatePickerDates from "../../utils/disabledDatePickerDates";
import { getValidDeliveryDate } from "../../utils/datePicker";
import setLocalCart from "../../state/actions/setLocalCart";

interface DeliveryDateProps {
  className: string;
  isDisabled?: boolean;
}

/**
 * component for selecting the delivery date
 * @param {string} className
 * @param {boolean} disabled
 */
const DeliveryDate: React.FC<DeliveryDateProps> = function DeliveryDate({
  className,
  isDisabled,
}: DeliveryDateProps) {
  const { pathname, search } = useLocation();
  const requestDate = useGetDeliveryDateAndUpdateCartAccordingly();
  const setUpdateUrlFragments = useUpdateUrlFragments();

  const [isLoading, setIsLoading] = useState(false);
  const [datesWidthCarts, setDatesWidthCarts] = useState([]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const weekplannerDates = useSelector(
    (state: any) => state.weekplannerData.weekDates
  );
  const reduxDeliveryDateFrom = weekplannerDates[0]?.apiDate;

  const isWeekplanner = pathname.includes(routePathNames.weekPlanner);
  const parsedSearch = queryString.parse(search);
  const {
    [locationSearchQueryParameter.deliveryDateFrom]: deliveryDateFromQuery,
  } = parsedSearch;

  // message content
  const {
    warning: { invalidDate: invalidDateMsg },
  } = messageData;

  const nextValidDeliveryDate = useMemo(() => getValidDeliveryDate(), []);

  /**
   * update cart and order on date and route change
   * @param {moment.Moment} date
   */
  const getAllCartDates = useCallback(() => {
    setIsLoading(true);

    return getAllCartsCheapMetadata(getCancelTokenSource())
      .then((cheapCartMetadataResponse: CheapCartMetadata[]) => {
        const dates = cheapCartMetadataResponse
          .filter((cartData: CheapCartMetadata) => cartData?.totalQuantity > 0)
          .map((cartData: CheapCartMetadata) => cartData.deliveryDate);

        setDatesWidthCarts(dates);
        setIsLoading(false);

        return cheapCartMetadataResponse;
      })
      .catch((error) => {
        setIsLoading(false);

        if (!axios.isCancel(error)) {
          message.error(messageData.error.unexpected);
        }

        requestCatchHandler(error);
      });
  }, []);

  /**
   * update cart and order on date change
   * only change URL in cart route,
   * because the components needs to call the cart itself
   * @param {moment.Moment} newDate
   */
  const updateWithNewDate = useCallback(
    (newDate: moment.Moment) => {
      const cancelTokenSource = getCancelTokenSource();
      const newApiDate = newDate.format(apiDateFormat);
      let context: PageTypes;
      let searchTerm: string;

      if (pathname.includes(routePathNames.cart)) {
        context = "cart";
      } else if (pathname.includes(routePathNames.product)) {
        context = "detailPage";
      } else if (pathname.includes(routePathNames.search)) {
        context = pathname.includes(routePathNames.products)
          ? "searchProductList"
          : "searchWeekPlanner";
        const querySearch = queryString.parse(search)?.search;
        searchTerm = querySearch?.length ? String(querySearch) : null;
      } else if (
        pathname.includes(routePathNames.weekPlanner) &&
        pathname.includes(routePathNames.category)
      ) {
        context = "weekPlanner";
      } else {
        context = "productList";
      }

      setUpdateUrlFragments({
        context,
        parameters: {
          deliveryDate: newApiDate,
          searchTerm,
        },
      });
      setDatePickerOpen(false);

      setLocalCart({
        deliveryDate: newApiDate,
        cancelTokenSource,
      });
    },
    [pathname, search, setUpdateUrlFragments, setDatePickerOpen]
  );

  /**
   * on change handler
   * @param {moment.Moment} date
   */
  const onChange = (date: moment.Moment) => {
    if (date?.isValid() && !date.isSame(moment(requestDate))) {
      updateWithNewDate(date);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => setDatePickerOpen(false));
    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", () => setDatePickerOpen(false));
    };
  }, [setDatePickerOpen]);

  /**
   * set the next valid delivery date range for weekplanner
   */
  const setNextValidWeekplannerWeek = useCallback(() => {
    // update redux weekdays
    const newWeekData = updateWeekplannerDates(nextValidDeliveryDate, true);

    const { search: paramSearch } = queryString.parse(search);
    const searchTerm = paramSearch?.length ? paramSearch.toString() : null;
    const context = pathname.includes(routePathNames.search)
      ? "searchWeekPlanner"
      : "weekPlanner";

    if (newWeekData) {
      setUpdateUrlFragments({
        context,
        parameters: {
          searchTerm,
          from: newWeekData[0]?.apiDate,
          to: newWeekData[newWeekData.length - 1]?.apiDate,
        },
      });
    }
  }, [pathname, search, nextValidDeliveryDate, setUpdateUrlFragments]);

  useEffect(() => {
    const momentRequestDate = moment(requestDate);
    const deliveryDateFromQueryString = String(deliveryDateFromQuery);

    if (disabledDatePickerDates(momentRequestDate)) {
      updateWithNewDate(nextValidDeliveryDate);
      message.info(invalidDateMsg);
    } else if (
      isWeekplanner &&
      deliveryDateFromQuery &&
      moment(reduxDeliveryDateFrom) > moment(deliveryDateFromQueryString)
    ) {
      setNextValidWeekplannerWeek();
      message.info(invalidDateMsg);
    }
  }, [
    requestDate,
    deliveryDateFromQuery,
    isWeekplanner,
    setNextValidWeekplannerWeek,
    updateWithNewDate,
    nextValidDeliveryDate,
    invalidDateMsg,
    reduxDeliveryDateFrom,
  ]);

  return (
    <div className={clsx(className)}>
      <button
        type="button"
        title="Lieferdatum auswählen"
        className={clsx(
          "deliveryDateContainer",
          datePickerOpen && "deliveryDateContainer-open"
        )}
        disabled={isDisabled}
      >
        <div className="dateWrapper">
          <p className="deliveryDateParagraph">Lieferdatum</p>
        </div>
        <Calendar className="icon iconDeliveryDate" />
        <DatePicker
          picker="week"
          id="datepicker"
          className={clsx("datepicker", isDisabled && "disabled")}
          panelRender={(panelNode) => (
            <Spin spinning={isLoading}>{panelNode}</Spin>
          )}
          disabledDate={disabledDatePickerDates}
          value={moment(requestDate)}
          defaultValue={moment(requestDate)}
          dateRender={(current) => (
            <div
              className={clsx(
                "ant-picker-cell-inner",
                datesWidthCarts.includes(current.format(apiDateFormat)) &&
                  "hasCart"
              )}
            >
              {current.date()}
            </div>
          )}
          inputReadOnly
          size="small"
          onChange={onChange}
          format={fullDateFormat}
          allowClear={false}
          disabled={isDisabled}
          dropdownClassName="datePickerCalendar"
          renderExtraFooter={() => (
            <Button
              onClick={() => updateWithNewDate(nextValidDeliveryDate)}
              type="link"
            >
              Nächstmögliches Lieferdatum
            </Button>
          )}
          open={datePickerOpen}
          onOpenChange={(open) => {
            setDatePickerOpen(open);

            // fetch carts on open
            if (open) {
              getAllCartDates();
            }
          }}
        />
      </button>
    </div>
  );
};

export default DeliveryDate;
