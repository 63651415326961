import React, { ReactText, useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Col, Layout, Row, Spin, Tree } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import moment from "moment";
import queryString from "query-string";

import getInfoCenterFolderTree from "../../api/contentful/getInfoCenterFolderTree";
import InfoCenterContent from "./InfoCenterContent";
import setActiveInfoCenterFolder from "../../state/actions/setActiveInfoCenterFolder";
import findNestedKeyInObject from "../../utils/findNestedKeyInObject";
import { pageTitles, pageTitleSuffix, routePathNames } from "../../appConfig";
import { ReactComponent as Arrow } from "../../static/svg/arrow.svg";
import { RootState } from "../../types/rootState";
import TrackingHelmet from "../Matomo/TrackingHelmet";

const INFO_CENTER_CACHE_SECONDS = 300;

function InfoCenter() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { slug } = useParams<{ slug?: string }>();

  const dispatch = useDispatch();
  const {
    tree: folderTree,
    activeFolder,
    expirationTimestamp = null,
  } = useSelector((state: RootState) => state.infoCenter);

  const [isLoading, setIsLoading] = useState(false);
  const [autoExpendParent, setAutoExpendParent] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState([]);

  /**
   * find the node matching the slug and set it to active
   */
  const setActiveTreeNodeOnLoad = useCallback(() => {
    // default opened tree based on url slug
    if (folderTree?.length && slug) {
      const matchingTreeNode = findNestedKeyInObject(
        folderTree,
        slug,
        "slug",
        "children"
      );

      if (!matchingTreeNode?.key) {
        return;
      }

      setExpandedKeys([matchingTreeNode.key]);
      setActiveInfoCenterFolder(matchingTreeNode);
    }
  }, [slug, folderTree]);

  useEffect(() => {
    const refreshQuery = queryString.parse(search)?.refresh;
    let isMounted = true;

    // dump the redux if called with refresh param
    if (refreshQuery === "1") {
      dispatch({
        type: "infoCenter/set-folders",
        payload: [],
      });

      navigate({
        search: "refresh=0",
      });
    }

    // if there is a no-cache parameter, the tree is not yet in the store or data is expired => fetch data
    const shouldFetchData =
      !folderTree?.length ||
      !expirationTimestamp ||
      expirationTimestamp < moment().unix();

    if (shouldFetchData) {
      setIsLoading(true);

      getInfoCenterFolderTree().then((apiFolderTree) => {
        if (isMounted) {
          dispatch({
            type: "infoCenter/set-folders",
            payload: apiFolderTree,
          });

          // Set expiration timestamp only if we have data
          if (apiFolderTree?.length) {
            dispatch({
              type: "infoCenter/set-expiration-timestamp",
              payload: moment().unix() + INFO_CENTER_CACHE_SECONDS,
            });
          }

          // if no slug, start at root otherwise find the tree node matching the slug
          if (!slug) {
            setActiveInfoCenterFolder({
              children: apiFolderTree,
            });
          } else {
            setActiveTreeNodeOnLoad();
          }

          setIsLoading(false);
        }
      });
    } else if (isMounted) {
      setActiveTreeNodeOnLoad();
    }

    return () => {
      isMounted = false;

      // reset redux stored active folder
      setActiveInfoCenterFolder([]);
    };
  }, [
    dispatch,
    folderTree,
    navigate,
    slug,
    setActiveTreeNodeOnLoad,
    search,
    expirationTimestamp,
  ]);

  /**
   * function wrapper to set local active node to redux
   * and update history
   * @param node {TreeNode}
   */
  const setActiveTreeNodeAndHistory = (node: any) => {
    setActiveInfoCenterFolder(node);
    navigate(`${routePathNames.infoCenter}/${node.slug}`);
  };

  /**
   * on select handler
   * @param selectedKeys {ReactText[]}
   * @param node {Record<string, any>}
   */
  const onSelect = (
    selectedKeys: ReactText[],
    { node }: Record<string, any>
  ) => {
    setAutoExpendParent(true);
    setActiveTreeNodeAndHistory(node);
  };

  /**
   *
   * @param currentExpandedKeys
   * @param expandData
   */
  const onExpand = (
    currentExpandedKeys: ReactText[],
    { expanded, node }: any
  ) => {
    setAutoExpendParent(expanded);

    setExpandedKeys([...currentExpandedKeys, expanded ? node.key : ""]);
  };

  /**
   * click handler for InfoCenterContent folders
   * the order of the calls is important due to the rendering pace of the tree
   * don't change order!
   * @param node {TreeNode}
   */
  const onContentSelect = (node: any) => {
    setAutoExpendParent(true);

    setExpandedKeys([node.key]);

    setActiveTreeNodeAndHistory(node);
  };

  return (
    <>
      <TrackingHelmet title={pageTitles.infoCenter} suffix={pageTitleSuffix} />

      <Layout className="container-layout container-layout--inner container-layout--fullWidth">
        <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
          <Col xs={12} md={5} xl={4}>
            <nav className="infoCenterFolders">
              <Tree.DirectoryTree
                className="infoCenterTree"
                treeData={folderTree}
                showLine={{ showLeafIcon: false }}
                showIcon={false}
                switcherIcon={<Arrow className="icon iconTreeSwitch" />}
                blockNode
                multiple
                expandAction="doubleClick"
                defaultExpandParent
                defaultExpandedKeys={[`${activeFolder?.key}`]}
                autoExpandParent={autoExpendParent}
                expandedKeys={expandedKeys}
                onExpand={onExpand}
                defaultSelectedKeys={[`${activeFolder?.key}`]}
                selectedKeys={[`${activeFolder?.key}`]}
                onSelect={onSelect}
              />
            </nav>
          </Col>

          <Col xs={12} md={7} xl={8}>
            <section className="infoCenterContent">
              <header className="infoCenterTitle">
                <h1>Weiling Infothek</h1>
              </header>

              {isLoading ? (
                <Spin size="large" />
              ) : (
                <InfoCenterContent onClick={onContentSelect} />
              )}
            </section>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default InfoCenter;
