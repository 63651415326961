import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

type LoadingType = "component" | "get" | "post";

/**
 * custom react hook to use with store for
 * global loading states
 * - get requests
 * - post requests
 * - loading component
 * @param loadingType {string}
 * @param initialState {boolean}
 * @deprecated DO NOT USE IN NEW CODE! this codes injects to many side effects
 */
const useGlobalNetworkState = (
  loadingType: LoadingType,
  initialState = false
) => {
  const dispatch = useDispatch();
  /*
   * get state from redux to have consistent states
   * for all components using a special type
   */
  const storeState = useSelector(
    (state: any) => state.networkActivity[loadingType]
  );

  // store state to redux
  const setStoreState = useCallback(
    (newState: any) =>
      dispatch({
        type: `network/loading-${loadingType}`,
        payload: newState,
      }),
    [dispatch, loadingType]
  );

  useEffect(() => {
    setStoreState(initialState);
  }, [initialState, setStoreState]);

  return [storeState, setStoreState];
};

export default useGlobalNetworkState;
