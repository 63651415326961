const initialState = {
  categoryTitle: "",
  categoryRoute: "",
  isVisible: false,
  isHovered: false,
  lastVisitedCategory: "",
  categories: {},
};

const categoryNavigationReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "categories/set-active-routing":
      return {
        ...state,
        ...payload,
      };
    case "categories/set-visibility":
      return {
        ...state,
        isVisible: payload,
      };
    case "categories/set-categories-hovered":
      return {
        ...state,
        isHovered: payload,
      };
    case "categories/set-categories":
      return {
        ...state,
        categories: payload,
      };
    case "categories/last-category":
      return {
        ...state,
        lastVisitedCategory: payload,
      };
    default:
      return state;
  }
};

export default categoryNavigationReducer;
