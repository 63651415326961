import React, { useCallback, useEffect, useState } from "react";

import axios, { CancelTokenSource } from "axios";
import { Col, Modal, Row } from "antd";
import { useSelector } from "react-redux";

import {
  ContentfulWebshopProductInformationEntry,
  PdfAttachment,
} from "../../../../types/contentfulWebshopProductInformationEntry";
import renderContentfulDocument from "../../../../utils/renderContentfulDocument";
import ProductTileWithModal from "../../../product/ProductTile/ProductTileWithModal";
import getCancelTokenSource from "../../../../api/getCancelTokenSource";
import getCartDeliveryDateSlice from "../../../../state/actions/getCartDeliveryDate";
import axiosWithAuth from "../../../../api/axiosWithAuth";
import { getProductsEndpoint } from "../../../../api/endpoints";
import getCatalogSearchAttributesData from "../../../../api/products/getCatalogSearchAttributesData";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import Picture from "../../../picture/Picture";
import { ReactComponent as Info } from "../../../../static/svg/info.svg";

interface WebshopProductInformationModalProps {
  productInformation: ContentfulWebshopProductInformationEntry;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const WebshopProductInformationModal: React.FC<
  WebshopProductInformationModalProps
> = (props: WebshopProductInformationModalProps) => {
  const { productInformation, isVisible, setIsVisible } = props;

  const [isProductDataLoading, setIsProductDataLoading] =
    useState<boolean>(false);
  const [productData, setProductData] = useState<Array<any>>(null);

  const cartDeliveryDate = useSelector(getCartDeliveryDateSlice);

  const loadProductData = useCallback(
    (cancelTokenSource: CancelTokenSource) => {
      axiosWithAuth()
        .get(
          getProductsEndpoint({
            categoryKey: "1",
            deliveryDates: cartDeliveryDate,
            sku: productInformation.productSkus.join("+"),
          }),
          { cancelToken: cancelTokenSource.token }
        )
        .then((response) => {
          const { concreteProducts } = getCatalogSearchAttributesData(
            response?.data?.data
          );
          setProductData(concreteProducts || []);
          setIsProductDataLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setIsProductDataLoading(false);
          }
          requestCatchHandler(error);
        });
    },
    [productInformation?.productSkus, cartDeliveryDate]
  );

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();
    const shouldLoadProductData =
      productInformation?.productSkus &&
      isVisible &&
      productData === null &&
      !isProductDataLoading;

    if (shouldLoadProductData) {
      loadProductData(cancelTokenSource);
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [
    productInformation?.productSkus,
    isVisible,
    productData,
    cartDeliveryDate,
    isProductDataLoading,
    loadProductData,
  ]);

  return (
    <Modal
      visible={isVisible}
      title={productInformation.headline}
      okText="Schließen"
      onOk={() => setIsVisible(false)}
      onCancel={() => setIsVisible(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ className: "button buttonPrimary button--rounded" }}
      destroyOnClose
      wrapClassName="webshop-product-information-modal"
      width={1000}
    >
      <Row gutter={[20, 10]} className="mt-xl">
        <Col xs={12} md={3}>
          <Picture
            imageScreenSm={`${productInformation.imageDesktopUrl}?fit=thumb&w=400&h=400&fm=webp&q=80`}
            imageScreenMd={`${productInformation.imageDesktopUrl}?fit=thumb&w=200&h=200&fm=webp&q=60`}
          />
        </Col>
        <Col xs={12} md={9}>
          <h4>{productInformation.headline}</h4>
          <div className="webshop-product-information-modal__body">
            {renderContentfulDocument(productInformation.content)}
          </div>
          {!!productInformation.pdfFiles &&
            productInformation.pdfFiles.length > 0 && (
              <div className="webshop-product-information-modal__attachments">
                {productInformation.pdfFiles.map(
                  (attachment: PdfAttachment, i: number) => (
                    <a
                      key={`attachment_${i}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={attachment.downloadUrl}
                      download
                      type="button"
                    >
                      <Info />
                      <span>{attachment.fileName}</span>
                    </a>
                  )
                )}
              </div>
            )}
        </Col>

        {!isProductDataLoading && !!productData && productData.length > 0 && (
          <Col span={12} className="mt-l">
            {productData.map((curr: any) => (
              <ProductTileWithModal
                key={curr.sku}
                deliveryDate={cartDeliveryDate}
                productData={curr}
              />
            ))}
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default WebshopProductInformationModal;
