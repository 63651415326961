import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Col, Row, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TableLocale } from "antd/lib/table/interface";
import DateSwitcher from "../DateSwitcher/DateSwitcher";
import { itemsPerPage } from "../../appConfig";

interface ProductItemTableProps {
  columns: ColumnsType<any>;
  expandableRowKeys: Array<any>;
  itemRowClassName: string;
  locale: TableLocale;
  productItemRows: Array<any>;
  selectedRowKeys: Array<any>;
  updateSelectedRows: (rows: Record<any, any>[], selected: boolean) => void;
  childrenBetweenHeaderAndTable?: JSX.Element;
  dateSwitchCallback?: (newDate: string) => any;
  dateSwitchLabel?: string;
  deliveryDate?: string;
  isLoading?: boolean;
  pagination?: TablePaginationConfig;
  rightHeaderComponent?: JSX.Element;
  tableClassName?: string;
}

/**
 * view wrapper to simplify use of table layout for
 * > cart list
 * > priceTag list
 * @param {ColumnsType} columns
 * @param {Array<any>} expandableRowKeys
 * @param {string} itemRowClassName
 * @param {TableLocale} locale
 * @param {Array<any>} productItemRows
 * @param { Array<any>} selectedRowKeys
 * @param {(rows: Record<any, any>[], selected: boolean) => void} updateSelectedRows
 * @param {JSX.Element} childrenBetweenHeaderAndTable
 * @param {boolean} isLoading
 * @param {Function} dateSwitchCallback
 * @param {string} dateSwitchLabel
 * @param {string} deliveryDate
 * @param {TablePaginationConfig} pagination
 * @param {JSX.Element} rightHeaderComponent
 * @param {string} tableClassName
 * @constructor
 */
const ProductItemTable = function ProductItemTable({
  columns,
  expandableRowKeys,
  itemRowClassName,
  locale,
  productItemRows,
  selectedRowKeys,
  updateSelectedRows,
  childrenBetweenHeaderAndTable = null,
  isLoading = false,
  dateSwitchCallback,
  dateSwitchLabel = "",
  deliveryDate,
  pagination,
  rightHeaderComponent,
  tableClassName = "",
}: ProductItemTableProps) {
  const withDateSwitcher = dateSwitchLabel && dateSwitchCallback;

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [allRowsExpanded, setAllRowsExpanded] = useState(true);

  /**
   * set expanded keys of the created groups
   * is used to setup the table component on render
   * to show all expanded groups
   */
  useEffect(() => {
    setExpandedRowKeys(expandableRowKeys);
  }, [expandableRowKeys]);

  /** ********************
   * <Table /> handlers
   ******************** */

  /**
   * state logic for toggle all groups button
   */
  const expandButtonToggleHandler = () => {
    setExpandedRowKeys(allRowsExpanded ? [] : expandableRowKeys);
    setAllRowsExpanded(!allRowsExpanded);
  };

  /**
   * fires on every single row, even grouping rows
   * @param record {Record<any, any>}
   * @param selected {boolean}
   */
  const onSelect = (record: Record<any, any>, selected: boolean) => {
    /*
     * if the record has children, its a wrapping row, so pass the children
     * otherwise it is a single item
     */
    if (record?.children?.length) {
      updateSelectedRows(record.children, selected);
    } else {
      updateSelectedRows([record], selected);
    }
  };

  /**
   * clear or set all items
   * @param selected {boolean}
   * @param selectedRows {Record<any, any>>[]}
   * @param changeRows {Record<any, any>>[]}
   */
  const onSelectAll = (
    selected: boolean,
    selectedRows: Record<any, any>[],
    changeRows: Record<any, any>[]
  ) => {
    const itemRows = changeRows.filter((row: any) => !row?.children);

    updateSelectedRows(itemRows, selected);
  };

  /**
   * adds the cartItem className to single items
   * @param record {Record<any, any>}
   * @return {string}
   */
  const rowClassName = (record: Record<any, any>) => {
    if (!record?.children) {
      return itemRowClassName;
    }

    return "isExpandableRow";
  };

  /**
   * set classNames to expandable rows
   * @param record {Record<any, any>}
   */
  const onRow = (record: Record<any, any>) => {
    const { key, children } = record;
    const rowIsExpanded = expandedRowKeys.includes(key);

    if (children?.length) {
      return {
        className: clsx(rowIsExpanded ? "isExpanded" : "isCollapsed"),
      };
    }

    return {};
  };

  /**
   * update list of expanded keys when rows expand
   * @param expanded {boolean}
   * @param record {Record<any, any>}
   */
  const onExpand = (expanded: boolean, record: Record<any, any>) => {
    const { key, children } = record;

    if (children?.length) {
      const newExpandedRowKeys = expanded
        ? [...expandedRowKeys, key]
        : expandedRowKeys.filter((currentKey: string) => currentKey !== key);

      setExpandedRowKeys(newExpandedRowKeys);
      setAllRowsExpanded(
        newExpandedRowKeys.length === expandableRowKeys.length
      );
    }
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
        <Col span={12}>
          <header
            className={clsx(
              "productItemTableHeading",
              withDateSwitcher && "productItemTableHeadingWithDateSwitch"
            )}
          >
            <section className="productItemTableHeadingWrapper">
              <h2 className="productItemTableHeadline">Artikel</h2>

              {productItemRows?.length > 0 && (
                <button
                  type="button"
                  className="button buttonText"
                  onClick={expandButtonToggleHandler}
                >
                  {!allRowsExpanded
                    ? "Alle Gruppierungen aufklappen"
                    : "Alle Gruppierungen zuklappen"}
                </button>
              )}
            </section>

            {(withDateSwitcher || rightHeaderComponent) && (
              <aside className="productItemTableHeadingAside">
                {withDateSwitcher && (
                  <DateSwitcher
                    deliveryDate={deliveryDate}
                    dateSwitchCallback={dateSwitchCallback}
                    label={dateSwitchLabel}
                  />
                )}

                {rightHeaderComponent}
              </aside>
            )}
          </header>
        </Col>
      </Row>

      {childrenBetweenHeaderAndTable}

      <Row>
        <Col span={12}>
          <Table
            className={clsx("productItemTable", tableClassName)}
            locale={locale}
            loading={isLoading && { size: "large" }}
            columns={columns}
            scroll={{
              x: "max-content",
            }}
            rowClassName={rowClassName}
            rowSelection={{
              onSelect,
              onSelectAll,
              checkStrictly: false,
              // set default selected rows on render
              selectedRowKeys,
            }}
            expandable={{
              columnWidth: "100%",
              expandedRowKeys,
              // hide default expand icon
              expandIconColumnIndex: -1,
              expandRowByClick: true,
              rowExpandable: (record) => record.key.startsWith("productgroup-"),
            }}
            onRow={onRow}
            onExpand={onExpand}
            dataSource={productItemRows}
            indentSize={0}
            pagination={
              pagination
                ? {
                    position: ["bottomCenter"],
                    pageSize: itemsPerPage.products,
                    showSizeChanger: false,
                    size: "default",
                    ...pagination,
                  }
                : false
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default ProductItemTable;
