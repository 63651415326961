import React from "react";

import clsx from "clsx";

import ProductPrices from "../../../product/ProductPrices";
import { ProductPackageInfo } from "../../../product";
import { ProductData } from "../../../../types/productData";
import { productAttributes } from "../../../../api/productAttributes";

interface QuickOrderProductPricesProps {
  deliveryDate: ProductData["deliveryDate"];
  productData: ProductData;
  className?: string;
}

const QuickOrderProductPrices: React.FC<QuickOrderProductPricesProps> = (
  props: QuickOrderProductPricesProps
) => {
  const { deliveryDate, productData, className } = props;

  const { attributes, sku, prices } = productData;

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.basePriceUnit]: basePriceUnit,
    [productAttributes.basePriceFactor]: basePriceFactor,
    [productAttributes.weighingArticle]: weighingArticle,
    [productAttributes.priceUnitText]: priceUnitText,
    [productAttributes.vatGroup]: vatGroup,
  } = attributes;

  return (
    <div className={clsx("quick-order-product-prices", className)}>
      <ProductPrices
        prices={prices}
        deliveryDate={deliveryDate}
        quantity={1}
        basePriceQuantity={productUnitQuantity}
        productUnitMeasurement={productUnitMeasurement}
        sku={productData?.sku}
      />
      <ProductPackageInfo
        productUnitMeasurement={
          weighingArticle === "1" ? priceUnitText : productUnitMeasurement
        }
        productUnitQuantity={productUnitQuantity}
        basePriceFactor={basePriceFactor}
        basePriceUnit={basePriceUnit}
        prices={prices}
        deliveryDate={deliveryDate}
        weighingArticle={weighingArticle}
        showProductUVP
        sku={sku}
        vatGroup={vatGroup}
        basePriceQuantity={productUnitQuantity}
      />
    </div>
  );
};

export default QuickOrderProductPrices;
