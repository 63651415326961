import React, { useMemo } from "react";
import { Col } from "antd";
import clsx from "clsx";
import Price from "../price/Price";
import HrDivider from "../divider/Divider";

interface Props {
  totalSelectedItems: number;
  selectedItemsTotal: number;
  id?: string;
  className?: string;
  width?: Record<string, number>;
  itemsTotal?: number;
  totalItems?: number;
  children?: React.ReactNode;
}

const TotalSum: React.FC<Props> = ({
  selectedItemsTotal,
  totalSelectedItems,
  id,
  className,
  width = {},
  itemsTotal,
  totalItems,
  children,
}: Props) => {
  const { xs, sm, md, lg, xl, xxl } = width;

  const showTotalDetails: boolean = useMemo(
    () =>
      !!itemsTotal &&
      !!totalItems &&
      itemsTotal > 0 &&
      itemsTotal !== selectedItemsTotal,
    [itemsTotal, totalItems, selectedItemsTotal]
  );

  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
      <div className={clsx("totalSum", className)} id={id}>
        <dl className="cart-total-row">
          <dt className="text-normal">Artikel</dt>
          <dd>{totalSelectedItems}</dd>
        </dl>

        {showTotalDetails && (
          <dl className="cart-total-row">
            <dt className="text-normal">Warenkorbwert</dt>
            <Price price={itemsTotal} tag="dd" />
          </dl>
        )}

        <dl className="cart-total-row">
          <dt className="text-bold">Zahlbetrag</dt>
          <Price
            price={selectedItemsTotal}
            tag="dd"
            className="text-ml text-bold"
          />
        </dl>

        {showTotalDetails && (
          <div className="text-s mb-sm">
            {totalSelectedItems > 0 ? (
              <>
                <u>Anmerkung</u>: Es {totalSelectedItems === 1 ? "ist" : "sind"}{" "}
                nur {totalSelectedItems} von {totalItems} Artikeln im Warenkorb
                für eine Bestellung ausgewählt. Eine Teilbestellung wird
                durchgeführt. Aus diesem Grund weicht der Zahlbetrag vom
                Warenkorbwert ab.
              </>
            ) : (
              <>
                <u>Achtung</u>: Es ist kein Artikel im Warenkorb für eine
                Bestellung ausgewählt. Bitte Artikel auswählen, um die
                Bestellung fortsetzen zu können.
              </>
            )}
          </div>
        )}

        <HrDivider size={2} spacingBottom="sm" />

        {children}
      </div>
    </Col>
  );
};

export default TotalSum;
