import moment from "moment";
import { Entry } from "contentful";

import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import stockNameMapping from "../../utils/stockNameMapping";
import { ContentfulWebshopOfferEntry } from "../../types/contentfulWebshopOfferEntry";
import { StockType } from "../../types/stock";

interface WebshopDashboardOffersParams {
  limit: number;
  skip: number;
  stockName?: StockType;
}

interface WebshopDashboardOffersResponse {
  items: ContentfulWebshopOfferEntry[];
  total: number;
}

/**
 * @param limit
 * @param skip
 * @param stockName
 *
 * @return Promise<any>
 */
const getWebshopDashboardOffers = async ({
  limit,
  skip,
  stockName = "HL",
}: WebshopDashboardOffersParams): Promise<WebshopDashboardOffersResponse> =>
  getContentfulContentType({
    ...contentfulContentTypes.webshopDashboardOffer,
    limit,
    skip,
    order: "-sys.createdAt",
    inclusion: {
      "fields.valid_from_date[lte]": moment().toISOString(),
      "fields.valid_to_date[gte]": moment().toISOString(),
      "fields.stock_name[in]": `Alle,${stockNameMapping?.[stockName] || ""}`,
    },
    withTotal: true,
  }).then((response: any) => {
    if (!response) {
      return Promise.reject(response);
    }
    return {
      items:
        response.items?.map((webshopOfferEntry: Entry<any>) => {
          const {
            image,
            link,
            call_to_action: callToAction,
            valid_from_date: validFromDate,
            valid_to_date: validToDate,
          } = webshopOfferEntry.fields;

          return {
            id: webshopOfferEntry.sys.id,
            imageUrl: image.fields?.file?.url || "",
            link,
            callToAction,
            validFromDate,
            validToDate,
          } as ContentfulWebshopOfferEntry;
        }) || [],
      total: response.total,
    } as WebshopDashboardOffersResponse;
  });

export default getWebshopDashboardOffers;
