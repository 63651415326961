import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { companyBusinessUnitEndpoint } from "../endpoints";
import { CompanyBusinessUnitData } from "../../types/companyBusinessUnit";

interface PatchCompanyBusinessUnitProps {
  cancelTokenSource: CancelTokenSource;
  payload: CompanyBusinessUnitData;
}

const patchCompanyBusinessUnit = async (
  props: PatchCompanyBusinessUnitProps
) => {
  const { payload, cancelTokenSource } = props;

  const url = `${companyBusinessUnitEndpoint}/mine`;

  return axiosWithAuth()
    .patch(
      url,
      { data: { type: "company-business-units", attributes: payload } },
      { cancelToken: cancelTokenSource.token }
    )
    .then((response): Promise<any> | CompanyBusinessUnitData => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data?.[0] || {};

      return attributes as CompanyBusinessUnitData;
    });
};

export default patchCompanyBusinessUnit;
