import React from "react";

import clsx from "clsx";

import { Badge, Button } from "antd";
import { SidebarItem } from "../../../../types/sidebar";

interface SidebarSwitchProps {
  items: SidebarItem[];
  setActiveIndexCallback: (index: number) => void;
  className?: string;
}

const SidebarSwitch: React.FC<SidebarSwitchProps> = (props) => {
  const { items, setActiveIndexCallback = () => {}, className } = props;

  return (
    <div className={clsx("sidebar-switch", className)}>
      {items?.map((item: SidebarItem, index: number) => (
        <Button
          type={item?.isActive ? "primary" : "default"}
          key={item.title}
          className={clsx("sidebar-switch__entry", {
            "sidebar-switch__entry--is-active": item?.isActive,
          })}
          onClick={() => setActiveIndexCallback(index)}
          shape="round"
          icon={item?.icon || null}
        >
          <span className={clsx("sidebar-switch__entry__title")}>
            {item.title}
            {!!item?.count && Number(item.count) > 0 && (
              <Badge count={item.count} />
            )}
          </span>
        </Button>
      ))}
    </div>
  );
};

export default SidebarSwitch;
