import contentfulClient from "./contentfulClient";
import { GetEntriesParameters } from "../../types/contentfulGetEntriesParameters";

/**
 * contentful wrapper to retrieve entries based on content type
 * @param contentType {string}
 * @param limit {number}
 * @param skip {number}
 * @param select {string}
 * @param order {string}
 * @param inclusion {*}
 * @param withTotal {boolean}
 */
const getContentfulContentType = ({
  contentType,
  limit,
  skip,
  select,
  order,
  inclusion = {},
  withTotal = false,
}: GetEntriesParameters) =>
  contentfulClient()
    .getEntries({
      content_type: contentType,
      limit,
      skip,
      select,
      order,
      ...inclusion,
    })
    .then((response) => {
      if (!response?.items) {
        return Promise.reject(response);
      }

      const { items, total } = response;

      if (withTotal) {
        return { items, total };
      }

      return items;
    });

export default getContentfulContentType;
