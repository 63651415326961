import { Asset } from "contentful";

const getFileExtensionFromAsset = (asset: Asset): null | string => {
  const fileName = asset?.fields?.file?.fileName;

  if (!fileName) {
    return null;
  }

  const fileExtension = fileName
    .substring(fileName.indexOf(".") + 1)
    .toUpperCase();

  if (!fileExtension) {
    return null;
  }

  return fileExtension;
};

export default getFileExtensionFromAsset;
