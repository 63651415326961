import moment from "moment";
import storeDispatch from "./storeDispatch";
import updateWeekplannerDates from "./updateWeekplannerDates";

/**
 * update cart data & weeklanner dates
 * @param cartData {Record<string, any>}
 * @param etag {string}
 */
const updateLocalCartData = (cartData: any, etag = "") => {
  const {
    id,
    attributes: {
      name,
      cartMessages,
      cartNote,
      cartNoteKube,
      deliveryDate,
      mlwGroupDeficits,
      companyBusinessUnitKey = "",
    },
  } = cartData;

  storeDispatch("cartMeta/set-data", {
    id,
    name,
    deliveryDate,
    cartMessages,
    cartNote,
    cartNoteKube,
    mlwGroupDeficits,
    etag,
    companyBusinessUnitKey,
  });

  // update active weekplanner dates due to changed cart delivery date
  updateWeekplannerDates(moment(deliveryDate));
};

export default updateLocalCartData;
