import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { getPriceTagListSuggestionsEndpoint } from "../endpoints";
import requestCatchHandler from "../requestCatchHandler";
import getDeliveryDateQuery from "../getDeliveryDateQuery";
import { locationSearchQueryParameter } from "../../appConfig";

/**
 * get product suggestions for the price tag list
 * @param {string} query
 * @param {string} deliveryDate
 * @param {CancelTokenSource} cancelTokenSource
 */
const getPriceTagListSuggestions = async (
  query: string,
  deliveryDate: string,
  cancelTokenSource: CancelTokenSource
) => {
  const deliveryDateAppendix = getDeliveryDateQuery(deliveryDate);

  return axiosWithAuth()
    .get(
      `${getPriceTagListSuggestionsEndpoint}?q=${query}${deliveryDateAppendix}&${locationSearchQueryParameter.skipAvailabilityCheck}=1`,
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (!response?.data?.data) {
        return Promise.reject(response);
      }

      const { data } = response.data;

      return { products: data?.[0]?.attributes?.concreteProducts };
    })
    .catch((error) => {
      requestCatchHandler(error);
    });
};

export default getPriceTagListSuggestions;
