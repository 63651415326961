import React, { useState, useEffect, useRef } from "react";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import NavNode from "./NavNode";
import { getNavigationNodeByIdKey } from "./setAndGetNavigationNodeIds";
import { ReactComponent as Close } from "../../static/svg/close.svg";
import { ReactComponent as Arrow2 } from "../../static/svg/arrow.svg";
import { ReactComponent as Back } from "../../static/svg/back.svg";
import useGlobalNetworkState from "../../hooks/useGlobalNetworkState";
import useGetCategories from "../../hooks/useGetCategories";
import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import createProductViewUrl from "../../utils/createProductViewUrl";
import { routePathNames } from "../../appConfig";
import CategoriesDropdown from "./CategoriesDropdown";
import useSetCategoryNavigationVisibility from "../../hooks/useSetCategoryNavigationVisibility";
import CategoriesIcon from "../categories/CategoriesIcon";
import { RootState } from "../../types/rootState";

const Categories = function Categories() {
  const categoriesContainer = useRef(null);
  const location = useLocation();
  const [isLoading, setIsLoading]: any = useGlobalNetworkState("get");
  const setCategoryNavigationVisibility = useSetCategoryNavigationVisibility();

  const { weekDates } = useSelector(
    (state: RootState) => state.weekplannerData
  );
  const deliveryDate = useSelector(getCartDeliveryDateSlice);
  const { categoryRoute, categories, isVisible } = useSelector(
    (state: RootState) => state.categoryNavigation
  );
  const stockName = useSelector((state: RootState) => state.userData.stockName);

  const [showBackArrow, setShowBackArrow]: any = useState(false);
  const [activeCategoryData, setActiveCategoryData]: any = useState({
    name: "Sortiment",
    children: [],
  });

  useGetCategories({ setIsLoading, setActiveCategoryData }, stockName);

  /**
   * function that handles returning to higher categories
   */
  const backToParentNode = () => {
    const getParentNode = getNavigationNodeByIdKey(
      categories,
      activeCategoryData?.parentCategoryKey
    );

    setActiveCategoryData(getParentNode);

    if (getParentNode?.parentCategoryKey) {
      setShowBackArrow(true);
    } else {
      setShowBackArrow(false);
    }
  };

  /**
   * construct url pathname
   * @param {string} pathname
   * @param {string} categoryKey
   * @returns {string}
   */
  const createLinkTo = (pathname: string, categoryKey: string) => {
    const targetPathname = pathname.includes(
      routePathNames.products.replace(/\//g, "")
    )
      ? routePathNames.products
      : routePathNames.weekPlanner;

    return createProductViewUrl({
      pathname: targetPathname,
      categoryKey,
      deliveryDate,
      weekDates,
    });
  };

  useEffect(() => {
    /**
     * document event handler to detect clicks
     * @param {MouseEvent} event
     */
    const closeOutsideClickHandler = (event: MouseEvent) => {
      event.stopImmediatePropagation();

      if (event.target === categoriesContainer.current) {
        setCategoryNavigationVisibility(false);
      }
    };

    document.addEventListener("click", closeOutsideClickHandler);

    return () => {
      document.removeEventListener("click", closeOutsideClickHandler);
    };
  }, [setCategoryNavigationVisibility]);

  useEffect(() => {
    setActiveCategoryData(categories);
    setShowBackArrow(false);
  }, [categories, isVisible]);

  return (
    <>
      <section
        className={clsx(
          "contentCategoriesContainer",
          location.pathname.includes(routePathNames.cart) && "topAdjustmenCart",
          isVisible && "contentCategoriesContainer--isVisible"
        )}
        ref={categoriesContainer}
      >
        <aside className="categoriesComponent">
          <div
            className={clsx(
              "categoriesContainer",
              showBackArrow && "categoriesContainer--subMenu"
            )}
          >
            <header
              className={clsx(
                "categoriesHeading",
                showBackArrow && "categoriesHeading--has-arrow"
              )}
            >
              {showBackArrow && (
                <button
                  onClick={backToParentNode}
                  type="button"
                  className="categoriesArrowBack"
                >
                  <Back className="icon arrowBack" />
                </button>
              )}

              <h3 className="categoriesHeadingTitle">
                {activeCategoryData.name}
              </h3>

              <button
                onClick={() => {
                  setCategoryNavigationVisibility(false);
                }}
                className="button categoriesContainerCloseButton"
                type="button"
              >
                <Close className="icon closeIcon" />
              </button>
            </header>
            {isLoading ? (
              <Spin size="large" className="spin categoriesContent__spin" />
            ) : (
              <div className="categoriesColumn">
                <ul className="categoriesContent">
                  {activeCategoryData?.children?.length > 0 &&
                    activeCategoryData.children.map((currentCategory: any) => (
                      <li
                        className="category"
                        key={currentCategory.categoryKey}
                        role="menuitem"
                      >
                        {!showBackArrow && (
                          <Link
                            to={createLinkTo(
                              categoryRoute,
                              currentCategory.categoryKey
                            )}
                            className="categoryIconContainer"
                          >
                            <CategoriesIcon
                              className="icon categoryIcon"
                              categoryEntry={
                                currentCategory.webshopCategoryEntry
                              }
                            />
                          </Link>
                        )}
                        <NavNode
                          className={clsx(!showBackArrow && "hasMarginLeft")}
                          title={currentCategory.name}
                          route={createLinkTo(
                            categoryRoute,
                            currentCategory.categoryKey
                          )}
                          subTitle={false}
                        />

                        <div className="categoriesArrowNextContainer">
                          {currentCategory?.children?.length > 0 && (
                            <button
                              className="button categoriesArrowNext"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                setShowBackArrow(true);
                                setActiveCategoryData(currentCategory);
                              }}
                            >
                              <Arrow2 className="icon arrowNext" />
                            </button>
                          )}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </aside>
      </section>
      <CategoriesDropdown createLinkTo={createLinkTo} />
    </>
  );
};

export default Categories;
