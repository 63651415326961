import React, { useRef, useState } from "react";
import { message, Modal } from "antd";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import deleteCart from "../../api/cart/deleteCart";
import { ReactComponent as Delete } from "../../static/svg/delete.svg";
import { messageData, routePathNames } from "../../appConfig";
import setLocalCart from "../../state/actions/setLocalCart";
import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";

interface Props {
  showCartButton?: boolean;
  itemCount: number;
}

/**
 * Button with Confirmation dialog for deleting the entire cart
 * @param itemCount {number}
 * @constructor
 * @returns {JSX.Element}
 */
function CartDeleteModal({ showCartButton = false, itemCount = 0 }: Props) {
  const navigate = useNavigate();

  const { id: cartId, deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );

  const isMediumScreen = useMedia(`(min-width: ${getCssVariable("screen-md")}`);

  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const showModal = () => setIsVisible(true);
  const hideModal = () => setIsVisible(false);

  /**
   * DELETE spryker cart
   * @returns {Promise<AxiosResponse>}
   */
  const deleteCartFunction = async () => {
    setIsLoading(true);

    /**
     * delete cart
     * then make sure a new cart is fetched and set to redux
     * then close modal, show message
     * then return to shop page
     * if an error occurs
     * then show a message and fetch the cart for the date again
     * then return to shop page
     */
    return deleteCart(cartId, cancelTokenSource.current)
      .then(() =>
        setLocalCart({
          deliveryDate,
          cancelTokenSource: cancelTokenSource.current,
        })
      )
      .then(() => {
        setIsLoading(false);
        setIsVisible(false);

        return message.success(messageData.success.cart.deleteCart);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsVisible(false);

        if (!axios.isCancel(error)) {
          // show an other message if the cart didn't exist
          const messageContent =
            error?.response?.status === 404
              ? messageData.error.cart.notFound
              : messageData.error.cart.deleteCart;

          message.error(messageContent);

          return setLocalCart({
            deliveryDate,
            cancelTokenSource: cancelTokenSource.current,
          });
        }

        return Promise.resolve();
      })
      .then(() => {
        navigate(routePathNames.shop);
      });
  };

  const cartDeleteButtonText = () => {
    if (showCartButton) {
      if (isMediumScreen) {
        return <span>Warenkorb löschen</span>;
      }
      return <></>;
    }
    return <span>Warenkorb löschen</span>;
  };

  return (
    <>
      <button
        type="button"
        className="button buttonText buttonWithIcon buttonTextDecoration--inverted buttonCartDelete"
        onClick={showModal}
      >
        <Delete className="icon" />
        {cartDeleteButtonText()}
      </button>

      <Modal
        title="ACHTUNG!"
        className="cartDeleteModal"
        width={530}
        keyboard
        closable
        maskClosable={false}
        confirmLoading={isLoading}
        visible={isVisible}
        cancelText="Abbrechen"
        onCancel={hideModal}
        okText="Ja, löschen"
        onOk={deleteCartFunction}
      >
        Möchtest du den Warenkorb für den Liefertag am{" "}
        {moment(deliveryDate).format("L")} wirklich löschen? Es{" "}
        {itemCount === 1 ? "befindet" : "befinden"} sich aktuell {itemCount}{" "}
        Artikel im Warenkorb.
      </Modal>
    </>
  );
}

export default CartDeleteModal;
