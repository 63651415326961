import { Divider, Drawer, Modal, Spin } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import { ReactComponent as Close } from "../../static/svg/close.svg";
import {
  ChangedProductFavouriteLists,
  FavouriteList,
  FavouriteListWithNumberId,
} from "../../types/favouriteList";
import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";
import AddFavouriteListInput from "../FavouriteList/AddFavouriteListInput";

interface ProductAddToFavouriteListModalProps {
  hideModal: () => void;
  isLoading: boolean;
  isVisible: boolean;
  modalHeading: string;
  onSubmit: ({
    listsToAddProduct,
    listsToDeleteProduct,
  }: ChangedProductFavouriteLists) => void;
  sku: string;
  getContainer?: () => HTMLElement;
}

/**
 * Modal Component to add or remove a product from the favourite lists and create a new list
 * @param {() => void)} hideModal
 * @param {boolean} isLoading
 * @param {boolean} isVisible
 * @param {string} modalHeading
 * @param {(payload: ChangedProductFavouriteLists) => void} onSubmit
 * @param {string} sku
 * @param getContainer
 * @constructor
 */
const ProductAddToFavouriteListModal: React.FC<ProductAddToFavouriteListModalProps> =
  function ProductAddToFavouriteListModal({
    hideModal,
    isLoading,
    isVisible,
    modalHeading,
    onSubmit,
    sku,
    getContainer,
  }) {
    const { favouriteLists } = useSelector((state: any) => state.favouriteList);

    const isLargeScreen = useMedia(
      `(min-width: ${getCssVariable("screen-md")})`
    );

    // previous determined favourites before user changes
    const previousDeterminedLists: string[] = useMemo(() => {
      return favouriteLists
        .filter((favouriteList: FavouriteList) =>
          favouriteList.favourites.some((entry) => entry.sku === sku)
        )
        .map((matchingList: FavouriteList) => matchingList.idFavouriteList);
    }, [favouriteLists, sku]);

    const [selectedLists, setSelectedLists] = useState<string[]>(
      previousDeterminedLists
    );

    // determine if selectedList and previousDeterminedLists are containing the same values
    const isSelectionEqual = useMemo(() => {
      const selecetedListsSorted = [...selectedLists].sort();
      const previousListsSorted = [...previousDeterminedLists].sort();
      return (
        previousDeterminedLists.length === selectedLists.length &&
        previousListsSorted.every((value, index) => {
          return value === selecetedListsSorted[index];
        })
      );
    }, [previousDeterminedLists, selectedLists]);

    /**
     * close the modal ans reset component to previous state
     */
    const onClose = () => {
      setSelectedLists(previousDeterminedLists);
      hideModal();
    };

    /**
     * push or remove a selected value to selectedLists
     * @param {string} idFavouriteList
     */
    const onSelectFavouriteList = useCallback(
      (idFavouriteList: string) => {
        const afterSelectedLists = [...selectedLists];
        const listIndex = afterSelectedLists.findIndex(
          (entry) => entry === idFavouriteList
        );

        if (listIndex < 0) {
          afterSelectedLists.push(idFavouriteList);
        } else {
          afterSelectedLists.splice(listIndex, 1);
        }

        setSelectedLists(afterSelectedLists);
      },
      [selectedLists]
    );

    /**
     * add a newly created favourite list to selectedLists
     * @param {FavouriteList} favouriteList
     */
    const onFinishAddFavouriteList = (
      favouriteList: FavouriteListWithNumberId
    ) => {
      onSelectFavouriteList(favouriteList.idFavouriteList.toString());
    };

    /**
     * determine the lists where the product should be added and removed and submit the result
     */
    const onSaveSelectedLists = () => {
      const listsToAddProduct = selectedLists.filter(
        (selectedList) =>
          !previousDeterminedLists.some(
            (previousList) => selectedList === previousList
          )
      );

      const listsToDeleteProduct = previousDeterminedLists.filter(
        (previousList) =>
          !selectedLists.some((selectedList) => selectedList === previousList)
      );

      onSubmit({ listsToAddProduct, listsToDeleteProduct });
    };

    /**
     * favourite list component with the available lists
     */
    const FavouriteListComponent = useMemo(
      () => (
        <ul className="menuItemsList">
          {favouriteLists.map((favouriteList: FavouriteList) => (
            <li key={favouriteList.idFavouriteList} className="menuItem">
              <button
                type="button"
                className="buttonPlain"
                onClick={() =>
                  onSelectFavouriteList(favouriteList.idFavouriteList)
                }
              >
                <div className="checkIconWrapper">
                  {selectedLists.some(
                    (selectedList) =>
                      selectedList === favouriteList.idFavouriteList
                  ) && <CheckOutlined className="mr-s text-m color-primary" />}
                </div>
                <span className="menuItemText">{favouriteList.caption}</span>
              </button>
            </li>
          ))}
        </ul>
      ),
      [favouriteLists, selectedLists, onSelectFavouriteList]
    );

    return (
      <>
        {isLargeScreen ? (
          /* DESKTOP */
          <Modal
            bodyStyle={{ maxHeight: 320 }}
            title={modalHeading}
            visible={isVisible}
            footer={
              <>
                <div className="productAddFooterContainer">
                  <AddFavouriteListInput
                    onFinish={onFinishAddFavouriteList}
                    focused
                  />
                </div>
                <Divider />
                <div className="productAddFooterContainer">
                  <div className="productAddSubmitButtons">
                    <button
                      type="button"
                      title="Schließen"
                      className="button buttonPlain buttonText"
                      onClick={() => setSelectedLists([])}
                      disabled={!selectedLists.length}
                    >
                      Von allen Listen entfernen
                    </button>
                    <button
                      type="button"
                      className="button buttonPrimary"
                      disabled={isLoading || isSelectionEqual}
                      onClick={onSaveSelectedLists}
                    >
                      <Spin spinning={isLoading}>Auswahl bestätigen</Spin>
                    </button>
                  </div>
                </div>
              </>
            }
            onCancel={onClose}
            destroyOnClose
            className="addFavouriteListModal addProductsToFavouriteLists"
            centered
            getContainer={getContainer}
          >
            {FavouriteListComponent}
          </Modal>
        ) : (
          /* MOBILE | TABLET */
          <Drawer
            title="Favoritenlisten"
            placement="bottom"
            onClose={onClose}
            visible={isVisible}
            // fix height to always display three favourite lists
            height={425}
            key="bottom"
            className="popupMenu"
            closable={false}
            extra={
              <div className="drawerExtraWrapper">
                <button
                  type="button"
                  title="Schließen"
                  className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText"
                  onClick={onClose}
                >
                  <Close className="closeIcon closeIcon--large" />
                </button>
              </div>
            }
            footer={
              <>
                <div className="productAddFooterContainer">
                  <AddFavouriteListInput onFinish={onFinishAddFavouriteList} />
                </div>
                <Divider />
                <div className="productAddFooterContainer">
                  <div className="productAddSubmitButtons">
                    <button
                      type="button"
                      className="button buttonPrimary"
                      disabled={isLoading || isSelectionEqual}
                      onClick={onSaveSelectedLists}
                    >
                      <Spin spinning={isLoading}>Auswahl bestätigen</Spin>
                    </button>
                    <button
                      type="button"
                      title="Schließen"
                      className="button buttonPlain buttonText"
                      onClick={() => setSelectedLists([])}
                      disabled={!selectedLists.length}
                    >
                      Von allen Listen entfernen
                    </button>
                  </div>
                </div>
              </>
            }
            getContainer={getContainer}
          >
            {FavouriteListComponent}
          </Drawer>
        )}
      </>
    );
  };

export default ProductAddToFavouriteListModal;
