import moment from "moment/moment";
import { Entry } from "contentful";

import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import stockNameMapping from "../../utils/stockNameMapping";
import { StockType } from "../../types/stock";
import { ContentfulWebshopCategoryDownloadEntry } from "../../types/contentfulWebshopCategoryDownloadEntry";

interface WebshopCategoryDownloadsParams {
  categoryIds: string;
  stockName?: StockType;
}

interface WebshopCategoryDownloadsResponse {
  items: Entry<ContentfulWebshopCategoryDownloadEntry>[];
  total: number;
}

/**
 * @param categoryId
 * @param stockName
 *
 * @return Promise<any>
 */
const getWebshopCategoryDownloads = async ({
  categoryIds,
  stockName = "HL",
}: WebshopCategoryDownloadsParams): Promise<WebshopCategoryDownloadsResponse> =>
  getContentfulContentType({
    ...contentfulContentTypes.webshopCategoryDownload,
    limit: 1000,
    order: "-fields.publish_date,-sys.createdAt",
    inclusion: {
      "fields.category[in]": categoryIds,
      "fields.publish_date[lte]": moment().toISOString(),
      "fields.stock_name[in]": `Alle,${stockNameMapping?.[stockName] || ""}`,
    },
    withTotal: true,
  }).then((response: any) => {
    if (!response) {
      return Promise.reject(response);
    }

    return {
      items: response.items || [],
      total: response?.total || 0,
    } as WebshopCategoryDownloadsResponse;
  });

export default getWebshopCategoryDownloads;
