import { CancelTokenSource } from "axios";
import createSuggestItems from "../../components/suggest/createSuggestItems";
import getProductSuggestions from "../../api/products/getProductSuggestions";
import { SuggestItem } from "../../types/suggestItem";
import getPriceTagListSuggestions from "../../api/priceTagList/getPriceTagListSuggestions";
import SuggestionContainer from "../../components/suggest/SuggestionContainer";

interface CreateSuggestions {
  cancelTokenSource: CancelTokenSource;
  deliveryDate: string;
  query: string;
  checkIfAlreadyOnList?: (sku: string) => boolean;
  isAlreadyOnListWarning?: string;
  type?: SuggestItem;
  isHeaderSearch?: boolean;
  showSearchIntend?: boolean;
  showBrand?: boolean;
  showProductGroup?: boolean;
  showCompletion?: boolean;
  context?: "productlist" | "weekplanner";
  updateComponentStates?: (query: string) => void;
}

/**
 * create autocomplete list
 * @param {CancelTokenSource} cancelTokenSource
 * @param {function} checkIfAlreadyOnList
 * @param {string} deliveryDate
 * @param {string} isAlreadyOnListWarning
 * @param {string} query
 * @param {SuggestItem} type
 * @param {boolean} isHeaderSearch
 * @param {boolean} showSearchIntend
 * @param {boolean} showBrand
 * @param {boolean} showProductGroup
 * @param {boolean} showCompletion
 * @param {string} context
 * @param {function} updateComponentStates
 */
const createSuggestions = async ({
  cancelTokenSource,
  checkIfAlreadyOnList,
  deliveryDate,
  isAlreadyOnListWarning,
  query,
  type,
  isHeaderSearch = false,
  showBrand = false,
  showProductGroup = false,
  showCompletion = false,
  context = "productlist",
  updateComponentStates,
}: CreateSuggestions) => {
  if (query?.length > 2) {
    let foundProducts: Record<string, any>[] = [];
    let suggestions: any;
    if (type === "priceTagList") {
      suggestions = await getPriceTagListSuggestions(
        query,
        deliveryDate,
        cancelTokenSource
      );
    } else {
      suggestions = await getProductSuggestions(
        query,
        deliveryDate,
        cancelTokenSource
      );
    }
    foundProducts = suggestions?.products.length ? suggestions.products : null;
    if (isHeaderSearch && foundProducts) {
      return [
        {
          value: "suggestionContainer",
          label: SuggestionContainer({
            suggestions,
            query,
            context,
            deliveryDate,
            checkIfAlreadyOnList,
            isAlreadyOnListWarning,
            type,
            showBrand,
            showCompletion,
            showProductGroup,
            updateComponentStates,
          }),
        },
      ];
    }

    const suggestedProducts: any[] = [];

    if (foundProducts && !isHeaderSearch) {
      const suggestedProductsItems: any[] = foundProducts
        .slice(0, 5)
        .map((singleProductResponse: any) =>
          createSuggestItems({
            deliveryDate,
            productSuggestData: singleProductResponse,
            isAlreadyOnList: checkIfAlreadyOnList
              ? checkIfAlreadyOnList(singleProductResponse.sku)
              : false,
            isAlreadyOnListWarning,
            type,
          })
        )
        .sort((a: any, b: any) => {
          return a.disabled - b.disabled;
        });

      if (suggestedProductsItems && suggestedProductsItems.length > 0) {
        suggestedProducts.push(...suggestedProductsItems);
        return suggestedProducts;
      }
    }
  }

  return null;
};

export default createSuggestions;
