import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";

interface WeekplannerItemsHeaderDefaultProps {
  title: string;
  className?: string;
  style?: object;
}

const WeekplannerItemsHeaderDefault: React.FC<
  WeekplannerItemsHeaderDefaultProps
> = (props: WeekplannerItemsHeaderDefaultProps) => {
  const { title, className, style = {} } = props;

  return (
    <div
      className={clsx(
        "weekplanner-items-header-cell",
        "weekplanner-items-header-default",
        className
      )}
      style={style}
    >
      {title}
    </div>
  );
};

export default React.memo(WeekplannerItemsHeaderDefault, areEqual);
