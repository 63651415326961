import clsx from "clsx";

import React from "react";

import { areEqual } from "react-window";

interface WeekplannerItemEmptyProps {
  className?: string;
}

const WeekplannerItemEmpty: React.FC<WeekplannerItemEmptyProps> = (
  props: WeekplannerItemEmptyProps
) => {
  const { className } = props;

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-empty",
        className
      )}
    />
  );
};

export default React.memo(WeekplannerItemEmpty, areEqual);
