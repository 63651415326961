export interface OrderData {
  businessUnitKey: string;
  version: string;
  orderItemIndex: Record<string, Record<string, any>[]>;
}
const initialState: OrderData = {
  businessUnitKey: "",
  version: "0",
  orderItemIndex: null,
};

const alreadyOrdered = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "alreadyOrdered/set-order-item-index":
      return {
        ...state,
        ...payload,
      };
    case "alreadyOrdered/empty-order-item-index":
      return initialState;
    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default alreadyOrdered;
