/*
 * use indices of array for typecast
 * do not expose this variable outside this scope
 * index 0 = false = "Nein",
 * index 1 = true = "Ja",
 */
import { locationSearchQueryParameter } from "../../../appConfig";

const booleanFilterMapping = ["Nein", "Ja"];

/**
 * typecast for 0|1 boolean filter
 * @param {*} value
 * @returns {*|string}
 */
const parseBooleanFilterNumberToString = (value: any) => {
  const typecastedValue = Number(value);

  if (typecastedValue > 1 || Number.isNaN(typecastedValue)) {
    return value;
  }

  // make use of the array index 🤯
  return booleanFilterMapping[typecastedValue];
};

/**
 * parse a boolean named filter back to an integer based bool as a string
 * if the String is not found in the booleanFilterMapping, return the passed value
 * @param {string} value
 * @return {string}
 */
const parseHumanReadableBooleanFilterToIntegerBool = (value: string) => {
  const filterSeparator = locationSearchQueryParameter.filterConjunction;

  return (
    value
      // split the string of filter(s) on the conjunction
      .split(filterSeparator)
      // iterate over the single filter value and parse it if necessary
      .map((singleFilterValue) => {
        const booleanIndexValue =
          booleanFilterMapping.indexOf(singleFilterValue);

        // if the filter value is a boolean, typecast it ...
        if (booleanIndexValue > -1) {
          return String(booleanIndexValue);
        }

        // ... otherwise return just the value
        return singleFilterValue;
      })
      // rejoin them to a string
      .join(filterSeparator)
  );
};

export {
  parseBooleanFilterNumberToString,
  parseHumanReadableBooleanFilterToIntegerBool,
};
