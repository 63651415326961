import React, { forwardRef } from "react";

import clsx from "clsx";
import { Col, Collapse, Empty, Result, Row, Spin } from "antd";
import { Asset } from "contentful";
import { ContentBlock } from "../../../atoms";

import { ReactComponent as Arrow } from "../../../../static/svg/arrow.svg";
import useGetSupportTutorials from "../../../../hooks/useGetSupportTutorials";
import renderContentfulDocument from "../../../../utils/renderContentfulDocument";
import TutorialVideo from "./TutorialVideo";
import { itemsPerPage } from "../../../../appConfig";
import { ContentfulSupportTutorialEntry } from "../../../../types/contentfulSupportTutorialEntry";

const { Panel } = Collapse;

interface SupportTutorialsProps {
  className?: string;
}

type Ref = HTMLDivElement;

const SupportTutorials: React.FC<SupportTutorialsProps> = forwardRef<
  Ref,
  SupportTutorialsProps
>((props, ref) => {
  const { className } = props;

  const { isLoading, hasError, entries } = useGetSupportTutorials(
    itemsPerPage.supportTutorials,
    0
  );

  return (
    <div ref={ref} className={clsx("support-tutorials", className)}>
      <Spin spinning={isLoading} tip="Lade Video-Tutorials...">
        <ContentBlock title="Video-Tutorials">
          {!isLoading &&
            !hasError &&
            Array.isArray(entries) &&
            !!entries?.length && (
              <Collapse
                accordion
                expandIcon={({ isActive }) => (
                  <Arrow
                    style={{
                      transform: isActive ? "rotate(180deg)" : "rotate( 0deg)",
                    }}
                  />
                )}
                expandIconPosition="end"
                destroyInactivePanel
                ghost
              >
                {entries.map((entry: ContentfulSupportTutorialEntry) => (
                  <Panel
                    key={`support-tutorials_${entry.id}`}
                    header={entry.name}
                    extra={
                      entry?.description
                        ? renderContentfulDocument(entry.description)
                        : null
                    }
                  >
                    {Array.isArray(entry?.videos) && !!entry?.videos?.length && (
                      <Row gutter={[20, 20]}>
                        {entry?.videos?.map((video: Asset) => (
                          <Col
                            xs={12}
                            md={4}
                            key={`support-tutorials-video_${video?.sys?.id}`}
                          >
                            <TutorialVideo
                              title={video?.fields?.title || ""}
                              videoSrc={video?.fields?.file?.url || ""}
                            />
                          </Col>
                        ))}
                      </Row>
                    )}
                    {(!Array.isArray(entry?.videos) ||
                      !entry?.videos?.length) && (
                      <p>
                        Aktuell sind noch keine Tutorial-Videos für den Bereich{" "}
                        {entry.name} vorhanden.
                      </p>
                    )}
                  </Panel>
                ))}
              </Collapse>
            )}

          {!isLoading &&
            !hasError &&
            (!Array.isArray(entries) || !entries?.length) && (
              <Empty
                description="Aktuell sind keine Video-Tutorials verfügbar"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}

          {!isLoading && !!hasError && (
            <Result
              status="error"
              title="Fehler beim Laden der Video-Tutorials"
            />
          )}
        </ContentBlock>
      </Spin>
    </div>
  );
});

export default SupportTutorials;
