import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { getCompanyUserEndpoint } from "../endpoints";
import aggregateCompanyUserData from "../../utils/aggregateCompanyUserData";

/**
 * retrieve all needed user data
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise<AxiosResponse<any>>}
 */
const getCompanyUsersData = (cancelTokenSource: CancelTokenSource) => {
  // retrieve companyUser
  return axiosWithAuth()
    .get(getCompanyUserEndpoint, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response: any) => {
      if (
        !response?.data?.data ||
        !response?.data?.included ||
        response.status > 201
      ) {
        return [];
      }
      const { data, included } = response.data;

      if (data && included) {
        // create a new array which mimics the typical catalog-search request
        return aggregateCompanyUserData(data, included);
      }

      return [{}];
    });
};

export default getCompanyUsersData;
