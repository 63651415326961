/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Radio } from "antd";
import clsx from "clsx";
import React, { MouseEventHandler } from "react";
import { BusinessUnit } from "../../../../types/businessUnits";

interface BusinessUnitModalItemProps {
  businessUnit: BusinessUnit;
  selected: boolean;
  value: string;
}

const BusinessUnitModalItem: React.FC<BusinessUnitModalItemProps> = ({
  businessUnit,
  selected,
  value,
}: BusinessUnitModalItemProps) => {
  const onClick: MouseEventHandler<HTMLButtonElement> = () => {
    const radio: HTMLElement = document.querySelector(`[value="${value}"]`);
    return radio.click();
  };

  return (
    <button
      type="button"
      className={clsx(
        "business-unit-grid",
        selected && "business-unit-grid-selected"
      )}
      key={businessUnit.uuid}
      onClick={onClick}
    >
      <Radio value={value} />
      <div className="business-unit-tooltip-key">
        <div className="business-unit-tooltip-key-key">Nr.</div>
        <div className="business-unit-tooltip-key-value">
          {businessUnit.companyBusinessUnitKey}
        </div>
      </div>
      <div className="business-unit-tooltip-address">
        <div className="business-unit-tooltip-address-key">Adresse</div>
        <div className="business-unit-tooltip-address-address">
          {businessUnit?.companyName ? `${businessUnit.companyName} | ` : ""}
          {businessUnit?.address1 ? `${businessUnit.address1} | ` : ""}
          {businessUnit?.city ? `${businessUnit.city}` : ""}
        </div>
      </div>
    </button>
  );
};

export default BusinessUnitModalItem;
