import useGetDeliveryDate from "./useGetDeliveryDate";
import { routePathNames, locationSearchQueryParameter } from "../appConfig";
import { ProductData } from "../types/productData";

/**
 * hook for creating a PDP link for single product with needed parameters
 */
const useGetProductDetailLink = () => {
  const [deliveryDate] = useGetDeliveryDate();

  /**
   * create a PDP link for single product with needed parameters
   * @param {ProductData["sku"]} sku
   */
  return (sku: ProductData["sku"]) => {
    const deliveryDateAppendix = `?${locationSearchQueryParameter.deliveryDate}=${deliveryDate}`;

    return `${routePathNames.product}${sku}${deliveryDateAppendix}`;
  };
};

export default useGetProductDetailLink;
