import { useEffect, useState } from "react";

import axios from "axios";

import handleError from "../utils/handleError";
import getSupportTutorials from "../api/contentful/getSupportTutorials";
import { ContentfulSupportTutorialEntry } from "../types/contentfulSupportTutorialEntry";

const useGetSupportTutorials = (limit: number, skip: number) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<ContentfulSupportTutorialEntry[]>([]);
  const [total, setTotal] = useState<number>(null);

  useEffect(() => {
    setIsLoading(true);
    getSupportTutorials({ limit, skip })
      .then((response) => {
        setEntries(response.items);
        setTotal(response.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [limit, skip]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetSupportTutorials;
