/**
 *
 * @param array {Array}
 * @param valueToFind {string|number}
 * @param key {string}
 * @param nestedKeyName {string}
 */
const findNestedKeyInObject: Record<string, any> | null | any = (
  // category list
  array: [] = [],
  // category
  valueToFind: string | number = "",
  // parentCategoryKey
  key = "",
  // children
  nestedKeyName = ""
) =>
  array.reduce((acc: any, child: any) => {
    // our match has been found on the previous iteration, so pass it on
    if (acc) {
      return acc;
    }
    // ah! our current iteration is the one we are looking for!
    if (child[key] === valueToFind) {
      return child;
    }

    // damnit! not yet, but there is hope, this child has another object to look into
    if (child[nestedKeyName]) {
      return findNestedKeyInObject(
        child[nestedKeyName],
        valueToFind,
        key,
        nestedKeyName
      );
    }

    // oh snap! there was nothing in this iteration, next!
    return null;
  }, null);

export default findNestedKeyInObject;
