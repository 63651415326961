import React from "react";
import Picture from "../picture/Picture";

interface Props {
  banner: {
    image_desktop?: Record<string, any>;
    image_tablet?: Record<string, any>;
    image_mobile?: Record<string, any>;
    internal?: string;
    link?: string;
  };
}

/**
 * renders a simple news banner
 * used in conjunction with a contentful retrieval
 * @param banner
 * @constructor
 */
const NewsBanner: React.FC<Props> = ({ banner }: Props) => {
  const {
    image_desktop: imageDesktop,
    image_tablet: imageTablet,
    image_mobile: imageMobile,
    internal,
    link,
  } = banner;

  const protocol = "https://";
  const imageLink = link.includes(protocol) ? link : `${protocol}${link}`;

  return (
    <div className="newsBanner">
      <a href={imageLink} target="_blank" rel="noopener noreferrer">
        <Picture
          imageScreenSm={imageMobile?.fields?.file?.url}
          imageScreenMd={imageTablet?.fields?.file?.url}
          imageScreenLg={imageDesktop?.fields?.file?.url}
          alt={internal}
        />
      </a>
    </div>
  );
};

export default NewsBanner;
