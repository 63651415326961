import React from "react";
import moment from "moment";
import { CheapCartMetadata } from "../../../types/cheapCartMetadata";
import { ProductData } from "../../../types/productData";
import { fullDateFormat } from "../../../utils/dateFormats";
import { CartIncludingSkuInfo } from "../../../types/productModal";

interface ProductPreorderTooltipProps {
  sku: ProductData["sku"];
  openCarts?: Array<CheapCartMetadata>;
}

const ProductPreorderTooltip: React.FC<ProductPreorderTooltipProps> = (
  props
) => {
  const { sku, openCarts } = props;
  const cartsIncludingSku = openCarts
    .filter((currentCart) =>
      currentCart?.items.find((currentItem) => currentItem?.sku === sku)
    )
    .map((currentCart) => {
      const currentCartSKUInfo = currentCart?.items.find(
        (currentItem) => currentItem?.sku === sku
      );
      const cartIncludingSkuInfo: CartIncludingSkuInfo = {
        quantity: currentCartSKUInfo?.quantity,
        deliveryDate: currentCart?.deliveryDate,
      };
      return cartIncludingSkuInfo;
    });

  if (!cartsIncludingSku?.length) {
    return null;
  }

  const cartTooltip = (
    <>
      <div className="tooltip-preorder">
        Dieser Artikel ist bereits in folgenden Warenkörben:
        <ul className="productInfoPopup__list list--unstyled">
          {cartsIncludingSku.map((cart: any) => {
            return (
              <li
                className="productInfoPopupList__list-entry"
                key={cart?.deliveryDate}
              >
                {`${moment(cart.deliveryDate).format(fullDateFormat)} - ${
                  cart?.quantity
                }x`}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );

  return cartTooltip;
};

export default ProductPreorderTooltip;
