import { useCallback, useEffect, useState } from "react";

import axios, { CancelTokenSource } from "axios";

import getAllOrders, { OrderDataResponse } from "../api/order/getAllOrders";
import getCancelTokenSource from "../api/getCancelTokenSource";
import requestCatchHandler from "../api/requestCatchHandler";
import { OrderType, DocumentType } from "../types/orderAPI";

interface UseGetOrderProps {
  page: number;
  limit: number;
  sorting?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  orderType?: OrderType;
  documentType?: DocumentType;
  invoiceNumber?: string;
  cartNote?: string;
}

const useGetOrders = (props: UseGetOrderProps) => {
  const {
    page,
    limit,
    sorting,
    deliveryDateFrom,
    deliveryDateTo,
    orderType,
    documentType,
    invoiceNumber,
    cartNote,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [orders, setOrders] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>(0);

  const getOrders = useCallback(
    async (cancelTokenSource: CancelTokenSource) => {
      setIsLoading(true);

      const defaultParams = {
        page,
        limit,
        sorting,
        orderType,
        documentType,
        invoiceNumber,
        cartNote,
        cancelTokenSource,
      };

      const skipDateParams = !deliveryDateFrom && !deliveryDateTo;
      const params = skipDateParams
        ? defaultParams
        : {
            ...defaultParams,
            deliveryDates: [deliveryDateTo, deliveryDateFrom],
          };

      getAllOrders({ ...params })
        .then((orderData: OrderDataResponse) => {
          setOrders(orderData?.orders || []);
          setTotal(orderData?.total || 0);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setHasError(true);
            setIsLoading(false);
          }
          requestCatchHandler(error);
        });
    },
    [
      page,
      limit,
      sorting,
      deliveryDateFrom,
      deliveryDateTo,
      orderType,
      documentType,
      invoiceNumber,
      cartNote,
    ]
  );

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();
    getOrders(cancelTokenSource).catch();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [getOrders]);

  return {
    isLoading,
    hasError,
    total,
    orders,
    setTotal,
    setOrders,
  };
};

export default useGetOrders;
