import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import getAllNewsCategories from "../api/contentful/getAllNewsCategories";
import { sortArrayByOrderOfArray } from "../utils/sorting";
import requestCatchHandler from "../api/requestCatchHandler";
import getNewsCategoryOrder from "../api/contentful/getNewsCategoryOrder";

/**
 * retrieve news categories from contentful
 * store them to redux
 */
const useGetNewsCategories = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  /**
   * fetch news categories
   * @return {Promise}
   */
  const getCategoriesAndSetUpReduxState = useCallback(async () => {
    setIsLoading(true);

    return Promise.all([getAllNewsCategories(), getNewsCategoryOrder()])
      .then(([categories, categoryOrder]) => {
        if (!categories) {
          return Promise.reject();
        }

        /**
         * sort the categories
         * >> first by the order an editor assigned, if none present, ignore this
         * >> then make sure, that the main categories are ordered first
         */
        const sortedCategories = categories
          .sort((a: any, b: any) =>
            sortArrayByOrderOfArray(a.id, b.id, categoryOrder || [])
          )
          .sort(
            (a: any, b: any) =>
              Number(b?.mainCategory) - Number(a?.mainCategory)
          );

        dispatch({
          type: "news/set-categories",
          payload: sortedCategories,
        });

        setIsLoading(false);

        return sortedCategories;
      })
      .catch((error) => {
        setIsLoading(false);

        requestCatchHandler(error);

        return error;
      });
  }, [dispatch]);

  /**
   * fetch data, should only trigger if the calling component mounts
   */
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getCategoriesAndSetUpReduxState();
    }

    return () => {
      isMounted = false;
    };
  }, [getCategoriesAndSetUpReduxState]);

  // return only loading state to operate e.g. spinner etc.
  return isLoading;
};
export default useGetNewsCategories;
