import React from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";

import getProcessedProductAttributeValue from "./getProcessedProductAttributeValue";
import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import useGetProductDetailCalcNumberEntriesLastRow from "../../hooks/useGetProductDetailCalcNumberEntriesLastRow";
import { sortArrayByOrderOfArray } from "../../utils/sorting";
import { productAttributes } from "../../api/productAttributes";
import { ProductData } from "../../types/productData";
import { ReactComponent as DnrLogo } from "../../static/svg/dnr-logo-colored.svg";

/*
 * helper array to check which attributes shout be listed
 * only use one leergut string to represent corner case for this one
 */
const visibleInfoPopupAttributes = [
  productAttributes.shoppingArguments,
  productAttributes.tasteDescriptionShort,
  productAttributes.productDescriptionShort,
  productAttributes.cultivationInfo,
  productAttributes.packageFruits,
  productAttributes.cheeseMilk,
  productAttributes.animalTypeMilk,
  productAttributes.temperingId,
  productAttributes.rawMilk,
  productAttributes.rennetCheese,
  productAttributes.wineTaste,
  productAttributes.grapeVariety,
  productAttributes.geographic,
  productAttributes.animalType,
  productAttributes.meatExcludingNps,
  productAttributes.qualityText,
];

// List of attributes shown in info popup that do not require a label to be visible
const noLabelInfoPopupAttributes = [
  productAttributes.shoppingArguments,
  productAttributes.tasteDescriptionShort,
  productAttributes.productDescriptionShort,
];

// Map to use for casting bool to human-readable text
const castBoolToTextMap: Record<string, any[]> = {
  Ja: ["1", 1],
  Nein: ["0", 0],
};

// List of attributes which should be converted to human-readable values
const castBoolToTextAttributes = [productAttributes.weighingArticle];

// TypeScript Props interface
interface Props {
  attributes: ProductData["attributes"];
  className?: string;
  hasColon?: boolean;
  filterType?: "infoPopup" | "detailPageList";
  attributesLength?: any;
}

const ProductInfoList: React.FC<Props> = ({
  attributes,
  className,
  hasColon = false,
  filterType,
  attributesLength,
}: Props) => {
  const { attributeNames } = useSelector(
    (state: any) => state.productsMetaData
  );
  const cartDate = useSelector(getCartDeliveryDateSlice);

  const attributeLengthLastRow =
    useGetProductDetailCalcNumberEntriesLastRow(attributesLength);

  /*
   * determine the applied filter for the attribute list
   */
  const visibleAttributesFilter = (currentAttribute: any) => {
    switch (filterType) {
      case "infoPopup":
        return visibleInfoPopupAttributes.indexOf(currentAttribute) > -1;

      default:
        return true;
    }
  };
  /*
   * determine the applied sorting for the attribute list
   */
  const visibleAttributesSorting = (a: any, b: any) => {
    switch (filterType) {
      // sort the array alphabetically based on the values of attributeNames
      case "infoPopup":
        return sortArrayByOrderOfArray(a, b, visibleInfoPopupAttributes);

      default:
        return 0;
    }
  };

  /**
   * create attribute List dynamically based on the provided list
   * @see {visibleAttributesFilter()} for filtering
   * @returns {null|React.Element}
   */
  const createAttributeList = () => {
    if (!attributes) {
      return null;
    }

    return (
      <>
        {Object.keys(attributes)
          // remove the attributes that should not be shown
          .filter(visibleAttributesFilter)
          .sort(visibleAttributesSorting)
          .map((currentAttribute, index) => {
            // show no entry if there is no match for attributes
            if (!attributeNames?.[currentAttribute]) {
              return null;
            }

            // if name is something "technical", just let it empty
            const name = attributeNames[currentAttribute].startsWith(
              "product.attribute."
            )
              ? ""
              : attributeNames[currentAttribute];

            // get the string value or a value from an date based object
            let value = getProcessedProductAttributeValue({
              attribute: attributes[currentAttribute],
              deliveryDate: cartDate,
            });

            // show brix when brixDaily is empty
            if (
              value &&
              currentAttribute === productAttributes.brix &&
              attributes.brixaktuell
            ) {
              return null;
            }

            // Cast bool values to text
            // TODO Maybe remove logic here and use NAV values as is
            if (castBoolToTextAttributes.includes(currentAttribute)) {
              Object.entries(castBoolToTextMap).find(([val, values]) => {
                const shouldCastValue = values.includes(value);
                if (shouldCastValue) {
                  value = val;
                }
                return shouldCastValue;
              });
            }

            // check if the value is not an empty
            if (
              !value ||
              (filterType === "detailPageList" && !name) ||
              (filterType === "infoPopup" &&
                !name &&
                !noLabelInfoPopupAttributes.includes(currentAttribute))
            ) {
              return null;
            }

            const key = `${name}_${value}_${currentAttribute}`;

            if (hasColon) {
              return (
                <li
                  key={key}
                  className={clsx(
                    name === "Anbau" && "lastInfoItem",
                    "productInfoPopupList__list-entry"
                  )}
                >
                  {name && `${name}: `} {value}
                </li>
              );
            }

            return (
              <li
                className={clsx(
                  "productInfoListItem",
                  name === "Anbau" && "lastInfoItem"
                )}
                key={key}
              >
                {name && <span className="text-bold text-base">{name} </span>}
                <span className="text-base boxSize">{value}</span>
                <span
                  className={
                    index >= attributesLength - attributeLengthLastRow
                      ? ""
                      : "breakLine"
                  }
                />
              </li>
            );
          })}

        {!!attributes?.[productAttributes?.eanCode] &&
          filterType === "infoPopup" && (
            <li
              key={`eanCode_${attributes[productAttributes?.eanCode]}`}
              className="productInfoPopupList__list-entry"
            >
              <span className="text-base boxSize">
                EAN: {attributes[productAttributes?.eanCode]}
              </span>
            </li>
          )}

        {!!attributes?.[productAttributes?.bioId] &&
          filterType === "infoPopup" && (
            <li
              key={`dnr-logo_${attributes[productAttributes?.deprecatedSku]}`}
              className="productInfoPopupList__list-entry"
            >
              <span className="text-base boxSize">
                <DnrLogo className="dnr-logo" />
                DNR-Daten vorhanden
              </span>
            </li>
          )}
      </>
    );
  };

  return (
    <>
      <ul
        className={clsx(
          "productInfoList flex",
          filterType === "infoPopup" && "flex-col",
          className
        )}
      >
        {createAttributeList()}
      </ul>
    </>
  );
};

export default ProductInfoList;
