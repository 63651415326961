import { store } from "../../store";

const clearStore = () => {
  // remove storages
  const ccm19 = localStorage.getItem("ccm_consent");
  localStorage.clear();
  sessionStorage.clear();

  localStorage.setItem("ccm_consent", ccm19);

  // reset redux
  store.dispatch({
    type: "store/clear",
  });
};

export default clearStore;
