import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { termsAndConditionsConsentEndpoint } from "../endpoints";
import getLocaleTimeStamp from "../../utils/timeStamps";

/**
 * send a new timestamp
 * the timestamp is passed in UNIX epoch format, so in Seconds, not Milliseconds
 * @param cancelTokenSource {CancelTokenSource}
 */
const setUserTermsAndConditionsState = (
  cancelTokenSource: CancelTokenSource
) => {
  const unixTimeStamp = getLocaleTimeStamp().timeStamp.unixTime;

  return axiosWithAuth()
    .post(
      termsAndConditionsConsentEndpoint,
      {
        data: {
          type: "agb-consent",
          attributes: {
            agbConsentDateTime: unixTimeStamp.toString(),
          },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (!response?.data?.data) {
        return Promise.reject(response);
      }

      return response;
    });
};

export default setUserTermsAndConditionsState;
