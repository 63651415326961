import React, { useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";

import handleError from "../../../utils/handleError";
import getOrderingInformation from "../../../api/contentful/getOrderingInformation";
import renderContentfulDocument from "../../../utils/renderContentfulDocument";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";
import TrackingHelmet from "../../Matomo/TrackingHelmet";

const OrderingInformation: React.FC = () => {
  const [contentfulData, setContentfulData] = useState<any>({});

  useEffect(() => {
    getOrderingInformation()
      .then((orderingInformationContent: any) => {
        if (orderingInformationContent?.fields) {
          setContentfulData({ ...orderingInformationContent.fields });
        }

        return orderingInformationContent;
      })
      .catch(handleError);
  }, []);

  return (
    <>
      <TrackingHelmet
        title={pageTitles.orderingInformation}
        suffix={pageTitleSuffix}
      />

      <Layout className="container-layout--inner">
        <Row>
          <Col xs={12} lg={{ span: 8, offset: 2 }}>
            {contentfulData?.content &&
              renderContentfulDocument(contentfulData.content)}
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default OrderingInformation;
