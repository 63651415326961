import axios from "axios";
import { mainURL, XHRheader, apiLevel } from "./endpoints";
import { store } from "../store";
import { routePathNames } from "../appConfig";
import getApiLevelHeader from "../utils/getApiLevelHeader";

/**
 * redirect to a maintenance page
 */
const redirectToMaintenance = () =>
  window.location.assign(
    `${window.location.origin}${routePathNames.maintenance}?referrer=${window.location.pathname}${window.location.search}`
  );

/**
 * axios instance wrapper to make use of credentials
 * TODO should be refactored to hook, to make use of react-router <Redirect>
 */
const axiosWithAuth = () => {
  const { tokenType, accessToken } = store.getState().auth;

  const axiosInstance = axios.create({
    baseURL: mainURL,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": XHRheader.AcceptLanguage,
      Authorization: `${tokenType} ${accessToken}`,
    },
  });

  /*
   * intercept all requests before they are sent,
   * check if an API level was set at deployment time, if not, return to maintenance page
   * @see {@link https://axios-http.com/docs/interceptors}
   */
  axiosInstance.interceptors.request.use((config) => {
    if (!apiLevel) {
      return redirectToMaintenance();
    }

    return config;
  });

  /*
   * intercept the response and also check the API Level, if there is a mismatch,
   * redirect to maintenance page
   * @see {@link https://axios-http.com/docs/interceptors}
   */
  axiosInstance.interceptors.response.use((response) => {
    const serverApiLevel = getApiLevelHeader(response?.headers);

    // if there is no apiLevel, ignore the version
    if (!serverApiLevel || apiLevel === "null") {
      return response;
    }

    // both parties have a level, but it's a mismatch 🔥
    if (serverApiLevel !== apiLevel) {
      return redirectToMaintenance();
    }

    return response;
  });

  return axiosInstance;
};

export default axiosWithAuth;
