import findNestedKeyInObject from "../../utils/findNestedKeyInObject";

/**
 * adds to every children node an parentCategoryKey key
 * the key contains the categoryKey of the parent object
 * @param children {Object}
 * @param categoryKey {number}
 */
const setParentIdToNavigationChildNode = ({ children, categoryKey = 0 }: any) =>
  children.map((child: any) => {
    const newChild = {
      ...child,
      parentCategoryKey: categoryKey,
    };

    // add parentCategoryKeys to sub children
    if (newChild.children) {
      newChild.children = setParentIdToNavigationChildNode(newChild);
    }

    return newChild;
  });

/**
 * find parent node by id
 * always returns an array with a node
 * @param nodeData {Object}
 * @param lookupCategoryKey {number}
 * @returns {Array}
 */
const getNavigationNodeByIdKey: any = (
  nodeData: any,
  lookupCategoryKey: number
) => {
  const { children, categoryKey } = nodeData;

  if (categoryKey === lookupCategoryKey) {
    return nodeData;
  }

  if (children?.length) {
    return findNestedKeyInObject(
      children,
      lookupCategoryKey,
      "categoryKey",
      "children"
    );
  }

  return {};
};

export { setParentIdToNavigationChildNode, getNavigationNodeByIdKey };
