import moment from "moment";
import { dateFormat, dayFormat, apiDateFormat } from "./dateFormats";

export interface WeekdayData {
  apiDate: string;
  calenderWeek: number;
  dateString: string;
  day: string;
  dayShort: string;
  isPastDate: boolean;
  isToday: boolean;
  minWeek: string;
  maxWeek: string;
}

/**
 * create an weekDay set
 * starts mondays
 * excludes sundays
 * includes info if the date is in the past
 * @param startDate {moment.Moment}
 * @return {Record<string: any>[]}
 */
const createWeekplannerDates = (
  startDate: moment.Moment = null
): WeekdayData[] => {
  const weekDays: WeekdayData[] = [];
  let start = startDate || moment();
  const today = moment();
  const isoMonday = 1;
  const startIsoDay = start.isoWeekday();

  // start with the next week if it is sunday
  if (startIsoDay === 7) {
    start = start.add(1, "days");
  } else if (startIsoDay > isoMonday) {
    start = start.subtract(startIsoDay - isoMonday, "days");
  }

  const calenderWeek = start.week();

  for (let i = 0; i < 7; i += 1) {
    const day = moment(start).add(i, "days");
    const currentIsoDay = day.isoWeekday();
    const dayName = day.format(dayFormat);

    // exclude sundays
    if (currentIsoDay !== 7) {
      weekDays.push({
        apiDate: day.format(apiDateFormat),
        calenderWeek,
        dateString: day.format(dateFormat),
        day: dayName,
        dayShort: dayName.slice(0, 2),
        isPastDate: day.diff(today) <= 0, // startIsoDay is also disabled
        isToday: day.isSame(today, "day"),
        minWeek: moment().startOf("isoWeek").format(apiDateFormat),
        maxWeek: moment()
          .add(1, "year")
          .startOf("isoWeek")
          .format(apiDateFormat),
      });
    }
  }

  return weekDays;
};

export default createWeekplannerDates;
