import { useCallback } from "react";
import { CancelTokenSource } from "axios";
import useUpdateCartItemQuantity from "../../hooks/useUpdateCartItemQuantity";

interface ProductUpdate {
  deliveryDate: string;
  cartId: string;
  sku: string;
  cancelTokenSource: CancelTokenSource;
}

/**
 * custom hook to update items in cart by scan
 */
const useAddScannedProduct = () => {
  const updateCartItemQuantity = useUpdateCartItemQuantity();

  /**
   * the update function is excluded from any component
   * there is a bug (?) which leads to the fact, that the
   * component gets the correct redux state, but this function
   * did not update correctly, while it was part of the <Modal> Component
   * Why this happens? I have no clue...
   * @param deliveryDate {string}
   * @param cartId {string}
   * @param sku {string}
   * @param cancelTokenSource {CancelTokenSource}
   */
  const addScannedItem = useCallback(
    ({ deliveryDate, cartId, sku, cancelTokenSource }: ProductUpdate) => {
      return updateCartItemQuantity({
        deliveryDate,
        cartId,
        sku,
        // increase quantity by one for every scan
        quantity: 1,
        cancelTokenSource,
      });
    },
    [updateCartItemQuantity]
  );

  return addScannedItem;
};

export default useAddScannedProduct;
