import React from "react";

import getCssVariable from "../../utils/getCssVariable";
import useMedia from "../../hooks/useMedia";
import ProductNutritionalValuesList from "./ProductNutritionalValuesList";
import { productAttributes } from "../../api/productAttributes";
import { ProductData } from "../../types/productData";

interface ProductIngredientsListProps {
  attributes: ProductData["attributes"];
  ingredientsLegend?: string;
  nutritionalAttributes?: ProductData["attributes"];
}
const ProductIngredientsList: React.FC<ProductIngredientsListProps> = (
  props: ProductIngredientsListProps
) => {
  const {
    attributes = {},
    ingredientsLegend,
    nutritionalAttributes = {},
  } = props;

  const ingredients = attributes?.[productAttributes.ingredients];

  const isLargeScreen = useMedia(`(min-width: ${getCssVariable("screen-lg")})`);

  const hasNutritionalAttributes =
    Object.keys(nutritionalAttributes).length > 0;

  return (
    <div className="ingredients">
      <div className="ingredientsArea">
        <span className="text-base ingredientsDirectory">{ingredients}</span>
        <div className="ingredientsLegend">
          <h4>LEGENDE</h4>
          <span className="text-base">{ingredientsLegend}</span>
        </div>
      </div>

      {!!hasNutritionalAttributes && (
        <div className="nutritionalValues">
          {!isLargeScreen && <h2>Nährwerte</h2>}
          <ProductNutritionalValuesList attributes={nutritionalAttributes} />
        </div>
      )}
    </div>
  );
};

export default ProductIngredientsList;
