import React from "react";

import clsx from "clsx";
import leafImage from "../../../static/images/leaf_cherry.png";

interface ExpertAdviceNavLinkProps {
  className?: string;
}

const ExpertAdviceNavLink: React.FC<ExpertAdviceNavLinkProps> = (
  props: ExpertAdviceNavLinkProps
) => {
  const { className } = props;

  return (
    <a
      className={clsx("expert-advice-nav-link", className)}
      href="https://www.weiling.de/verkauf-beratung/regional-und-fachberatung"
      target="_blank"
      rel="noreferrer noopener"
    >
      <span className="expert-advice-nav-link__link-text">
        zum Regional- und
        <br />
        Fachaußendienst
      </span>
      <img src={leafImage} alt="Regional- und Fachaußendienst" />
    </a>
  );
};

export default ExpertAdviceNavLink;
