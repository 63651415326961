import { useMemo } from "react";
import { useSelector } from "react-redux";

import { locationSearchQueryParameter } from "../appConfig";
import { RootState } from "../types/rootState";

const selectedFiltersHiddenFilterNames = [
  "category",
  locationSearchQueryParameter.searchFilter,
  locationSearchQueryParameter.deliveryDate,
  locationSearchQueryParameter.deliveryDateFrom,
  locationSearchQueryParameter.deliveryDateTo,
  locationSearchQueryParameter.sku,
  locationSearchQueryParameter.sortBy,
  locationSearchQueryParameter.sortDirection,
  locationSearchQueryParameter.page,
];

const useGetSelectedFiltersCount = (excludeFilterNames: string[] = []) => {
  const { filterStates } = useSelector(
    (state: RootState) => state?.productsMetaData?.filters
  );

  const selectedFiltersCount: number = useMemo(() => {
    return (
      Object.keys(filterStates || {}).filter(
        (filterStateName: string) =>
          !selectedFiltersHiddenFilterNames.includes(filterStateName) &&
          !excludeFilterNames.includes(filterStateName)
      )?.length || 0
    );
  }, [filterStates, excludeFilterNames]);

  return {
    selectedFiltersCount,
  };
};

export default useGetSelectedFiltersCount;
