import React, { useMemo } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { useNavigate } from "react-router-dom";

import { areEqual } from "react-window";
import aggregateCategoriesArray from "../../../../../utils/aggregateCategoriesArray";
import findNestedKeyInObject from "../../../../../utils/findNestedKeyInObject";
import { routePathNames } from "../../../../../appConfig";
import { Category } from "../../../../../types/category";
import { RootState } from "../../../../../types/rootState";
import { ScrollableContent } from "../../../../molecules";

interface WeekplannerHeaderNavigationProps {
  currentCategoryKey: string;
  isLoading: boolean;
  className?: string;
}

const WeekplannerHeaderNavigation: React.FC<
  WeekplannerHeaderNavigationProps
> = (props: WeekplannerHeaderNavigationProps) => {
  const { currentCategoryKey = "1", isLoading, className } = props;

  const navigate = useNavigate();

  const { categories = {} } = useSelector(
    (state: RootState) => state?.categoryNavigation || {}
  );

  const currentCategory = useMemo((): Category => {
    return findNestedKeyInObject(
      categories.children,
      currentCategoryKey,
      "categoryKey",
      "children"
    );
  }, [currentCategoryKey, categories]);

  const currentCategoryPath = useMemo((): Array<Category> => {
    return aggregateCategoriesArray(
      currentCategory,
      categories.children,
      []
    ).reverse();
  }, [currentCategory, categories]);

  const firstLevelChildCategories = currentCategoryPath?.[0]?.children || [];
  const secondLevelChildCategories = currentCategoryPath?.[1]?.children || [];

  const changeCategory = (e: React.BaseSyntheticEvent, category: Category) => {
    const newCategoryKey =
      category.categoryKey === currentCategoryKey
        ? category?.parentCategoryKey || category.categoryKey
        : category.categoryKey;
    e.currentTarget.blur();
    navigate(
      `${routePathNames.weekPlanner}${routePathNames.category}${newCategoryKey}`
    );
  };

  if (!categories || !firstLevelChildCategories?.length) {
    return null;
  }

  return (
    <Space
      className={clsx("weekplanner-header-navigation", className)}
      direction="vertical"
    >
      {!!firstLevelChildCategories?.length && (
        <ScrollableContent>
          <Space className="weekplanner-header-navigation__child-categories-first-level">
            {firstLevelChildCategories.map((childCategory: Category) => {
              return (
                <Button
                  key={`${childCategory.categoryKey}`}
                  onClick={(e) => changeCategory(e, childCategory)}
                  className={clsx(
                    childCategory?.categoryKey === currentCategoryKey &&
                      "is-active"
                  )}
                  title={childCategory?.name ?? ""}
                  disabled={isLoading}
                >
                  {childCategory?.name ?? ""}
                </Button>
              );
            })}
          </Space>
        </ScrollableContent>
      )}

      {!!secondLevelChildCategories?.length && (
        <ScrollableContent>
          <Space className="weekplanner-header-navigation__child-categories-second-level">
            {secondLevelChildCategories.map((childCategory: Category) => {
              return (
                <Button
                  key={`${childCategory.categoryKey}`}
                  onClick={(e) => changeCategory(e, childCategory)}
                  className={clsx(
                    childCategory?.categoryKey === currentCategoryKey &&
                      "is-active"
                  )}
                  size="small"
                  title={childCategory?.name ?? ""}
                  disabled={isLoading}
                >
                  {childCategory?.name ?? ""}
                </Button>
              );
            })}
          </Space>
        </ScrollableContent>
      )}
    </Space>
  );
};

export default React.memo(WeekplannerHeaderNavigation, areEqual);
