import { useEffect, useState } from "react";

import axios from "axios";

import handleError from "../utils/handleError";
import getWebshopProductInformation from "../api/contentful/getWebshopProductInformation";
import { ContentfulWebshopProductInformationEntry } from "../types/contentfulWebshopProductInformationEntry";
import { StockType } from "../types/stock";

const useGetWebshopProductInformation = (
  limit: number,
  skip: number,
  stockName: StockType
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<
    ContentfulWebshopProductInformationEntry[]
  >([]);
  const [total, setTotal] = useState<number>(null);

  useEffect(() => {
    setIsLoading(true);
    getWebshopProductInformation({ limit, skip, stockName })
      .then((response) => {
        setEntries(response.items);
        setTotal(response.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [limit, skip, stockName]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetWebshopProductInformation;
