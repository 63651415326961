import { CancelTokenSource } from "axios";
import axiosWithAuth from "./axiosWithAuth";
import { checkoutEndpoint } from "./endpoints";
import { CheckoutData } from "../types/checkoutData";

/**
 * create checkout order
 * after success, setup a new cart for the current date
 * @param data {CheckoutData}
 * @param cancelTokenSource {CancelTokenSource}
 */
const postCheckout = async (
  data: CheckoutData,
  cancelTokenSource: CancelTokenSource
) => {
  const requestBody = {
    data: {
      type: "checkout",
      attributes: {
        customer: data.customer,
        idCart: data.cartId,
        payments: data.payments,
        shipment: data.shipment,
        partialOrderSkus: data.partialOrderSkus,
        companyBusinessUnitKey: data.companyBusinessUnitKey,
      },
    },
  };

  return axiosWithAuth().post(checkoutEndpoint, requestBody, {
    cancelToken: cancelTokenSource.token,
  });
};

export default postCheckout;
