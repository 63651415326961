import React from "react";

import clsx from "clsx";

import { useSelector } from "react-redux";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";

import useGetCategories from "../../../hooks/useGetCategories";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import {
  WebshopTopTeaserWidget,
  WebshopProductInformationWidget,
  WebshopOffersWidget,
  AdWebshopDashboard,
} from "../../molecules";
import { WebshopDashboardWidgetBlock } from "../../organisms";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { RootState } from "../../../types/rootState";

interface WebshopDashboardProps {
  className?: string;
}

const WebshopDashboard: React.FC<WebshopDashboardProps> = (
  props: WebshopDashboardProps
) => {
  const { className } = props;

  const stockName = useSelector((state: RootState) => state.userData.stockName);

  useGetCategories({}, stockName);

  return (
    <div className={clsx("webshop-dashboard", className)}>
      <TrackingHelmet title={pageTitles.shop} suffix={pageTitleSuffix} />

      <ShopCategoryNavigation />

      <WebshopTopTeaserWidget />

      <WebshopOffersWidget />

      <AdWebshopDashboard />

      <WebshopProductInformationWidget />

      <WebshopDashboardWidgetBlock />
    </div>
  );
};

export default WebshopDashboard;
