import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";

import ProductAlreadyOrderedBatch from "../../../../../../product/ProductAlreadyOrderedBatch";

interface WeekplannerItemExpiredProps {
  count: number;
  className?: string;
}

const WeekplannerItemExpired: React.FC<WeekplannerItemExpiredProps> = (
  props: WeekplannerItemExpiredProps
) => {
  const { count, className } = props;

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-expired",
        className
      )}
    >
      <div>Bestellt</div>
      <ProductAlreadyOrderedBatch count={count} />
    </div>
  );
};

export default React.memo(WeekplannerItemExpired, areEqual);
