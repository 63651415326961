import axios from "axios";
import { passwordResetEndpoint, XHRheader } from "../endpoints";
import handleError from "../../utils/handleError";

/**
 * requests an email to reset user password
 * @param username {string}
 */
const postResetUserPassword = async (username: string) =>
  axios
    .post(
      passwordResetEndpoint,
      {
        data: {
          type: "customer-forgotten-password",
          attributes: {
            email: username,
          },
        },
      },
      {
        headers: {
          "Accept-Language": XHRheader.AcceptLanguage,
        },
      }
    )
    .then((res) => {
      return res.status;
    })
    .catch(handleError);

export default postResetUserPassword;
