interface AvailabilityState {
  isUnavailable: boolean;
  explanation: string;
  hasNextAvailability: boolean;
}

const getAvailabilityState = (
  availabilities: Array<any>,
  weekDaysData: Array<any>
): AvailabilityState => {
  let isUnavailable = false;
  let explanation = null;
  let hasNextAvailability = false;

  if (availabilities && availabilities.length > 0 && weekDaysData) {
    isUnavailable = availabilities.every((availabilityEntry: any) => {
      const isAvailable =
        availabilityEntry?.isAvailable || availabilityEntry?.isNeverOutOfStock;
      const currentDayData = weekDaysData.find(
        (weekdayData: any) =>
          weekdayData.apiDate === availabilityEntry.deliveryDate
      );

      return !isAvailable && !currentDayData?.isPastDate;
    });

    hasNextAvailability =
      !!availabilities.find(
        (availabilityEntry: any) => !!availabilityEntry?.nextAvailability
      )?.nextAvailability || false;

    explanation =
      (availabilities.find(
        (availabilityEntry: any) => availabilityEntry.explanation
      )?.explanation as string) || null;
  }

  return {
    isUnavailable,
    explanation,
    hasNextAvailability,
  };
};

export default getAvailabilityState;
