import findNestedKeyInObject from "./findNestedKeyInObject";

const aggregateCategoriesArray = (
  category: any,
  categoryList: any,
  list: any
): Array<any> => {
  list.push(category);
  if (category?.parentCategoryKey === "1") {
    return list;
  }

  const parentCategory = findNestedKeyInObject(
    categoryList,
    category?.parentCategoryKey,
    "categoryKey",
    "children"
  );

  if (parentCategory) {
    return aggregateCategoriesArray(parentCategory, categoryList, list);
  }

  return list;
};

export default aggregateCategoriesArray;
