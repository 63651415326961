import React, { useState } from "react";

import clsx from "clsx";

import { Dropdown, Menu, Space } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/hooks/useItems";

interface DropdownButtonProps {
  items: ItemType[];
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
}

const DropdownButton: React.FC<DropdownButtonProps> = (props) => {
  const { items, disabled = false, className, fullWidth = false } = props;
  const button = items?.[0] as any;
  const dropdownItems: ItemType[] = items.slice(1);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  if (!button) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        "dropdown-button",
        fullWidth && "dropdown-button--full-width",
        className
      )}
    >
      <Space wrap>
        <Dropdown.Button
          size="large"
          overlay={
            <Menu
              onClick={() => setIsDropdownOpen(false)}
              items={dropdownItems}
            />
          }
          disabled={disabled}
          onClick={button.onClick}
          placement="bottomLeft"
          icon={isDropdownOpen ? <UpOutlined /> : <DownOutlined />}
          trigger={["click"]}
          onVisibleChange={() => setIsDropdownOpen(!isDropdownOpen)}
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          {button.label}
        </Dropdown.Button>
      </Space>
    </div>
  );
};

export default DropdownButton;
