import React, { useMemo } from "react";

import clsx from "clsx";

import { Col, Layout, Row } from "antd";

import useGetAllCartsCheapMetadata from "../../../hooks/useGetAllCartsCheapMetadata";
import {
  OpenCartsWidget,
  QuickLinksWidget,
  WebshopBottomTeaserWidget,
} from "../../molecules";

interface WebshopDashboardWidgetBlockProps {
  className?: string;
}

const WebshopDashboardWidgetBlock: React.FC<
  WebshopDashboardWidgetBlockProps
> = (props) => {
  const { className } = props;

  const { isLoading, hasError, openCarts } = useGetAllCartsCheapMetadata(5);

  const openCartsWithDetails: any[] = useMemo(
    () => openCarts.slice(0, 2),
    [openCarts]
  );
  const openCartsWithoutDetails: any[] = useMemo(
    () => openCarts.slice(2, 5),
    [openCarts]
  );

  return (
    <Layout
      className={clsx(
        "webshop-dashboard-widget-block container-layout container-layout--inner mb-l",
        className
      )}
    >
      <Row>
        <Col xs={12} lg={6} className="open-cart-widget-col">
          <OpenCartsWidget
            openCarts={openCartsWithDetails}
            isLoading={isLoading}
            hasError={hasError}
            showCartDetails
          />
        </Col>

        <Col xs={12} lg={6} className="quick-links-widget-col">
          <QuickLinksWidget />
        </Col>

        <Col xs={12} lg={6} className="other-open-cart-widget-col">
          {!!openCartsWithoutDetails.length && (
            <OpenCartsWidget
              openCarts={openCartsWithoutDetails}
              isLoading={isLoading}
              hasError={hasError}
              showHeader={false}
              showDividerOnTop
              className="other-open-cart-widget"
            />
          )}
        </Col>

        <Col xs={12} lg={6} className="teaser-widget-col">
          <WebshopBottomTeaserWidget className="teaser-widget" />
        </Col>
      </Row>
    </Layout>
  );
};

export default WebshopDashboardWidgetBlock;
