const initialState = {};

const dailyOrdersReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "orders/set-orders":
      return {
        ...state,
        ...payload,
      };
    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default dailyOrdersReducer;
