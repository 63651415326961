import axiosWithAuth from "../axiosWithAuth";
import { favouriteListEndpoint } from "../endpoints";
import { PostFavouriteList } from "../../types/favouriteList";

/**
 * add a favourite list
 * @param {CancelTokenSource} cancelTokenSource
 * @param {string} caption
 * @param {string} notes
 * @return {Promise<AxiosResponse>}
 */
const postAddFavouriteList = ({
  cancelTokenSource,
  caption = "Favoritenliste",
  notes = "",
}: PostFavouriteList) =>
  axiosWithAuth()
    .post(
      favouriteListEndpoint,
      {
        data: {
          type: "favourite-lists",
          attributes: {
            caption,
            notes,
          },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (!response || response.status > 201) {
        Promise.reject(response);
      }

      return response;
    });

export default postAddFavouriteList;
