import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import clsx from "clsx";
import moment from "moment";
import { ReactComponent as Back } from "../../static/svg/back.svg";
import disabledDatePickerDates from "../../utils/disabledDatePickerDates";
import { fullDateFormat, apiDateFormat } from "../../utils/dateFormats";
import isButtonDisabled, { updateCurrentDate } from "../../utils/datePicker";

interface DateSwitcherProps {
  deliveryDate: string;
  label: string;
  dateSwitchCallback?: (newDate: string) => any;
  className?: string;
  isDisabled?: boolean;
}

/**
 * generic date switcher with arrows to skip for- and backwards
 * @param {string} deliveryDate
 * @param {string} label
 * @param {(newDate: string) => any} dateUpdateCallback
 * @param {string} className
 * @param {boolean} isDisabled
 * @constructor
 */
const DateSwitcher: React.FC<DateSwitcherProps> = function DateSwitcher({
  deliveryDate,
  label,
  dateSwitchCallback,
  className = "",
  isDisabled = false,
}: DateSwitcherProps) {
  const [currentDate, setCurrentDate] = useState(deliveryDate);

  /**
   * trigger the actual update of component
   * fires callback if set
   * @param {moment.Moment} newMomentDate
   */
  const updateDate = (newMomentDate: moment.Moment) => {
    const newDate = newMomentDate.format(apiDateFormat);
    setCurrentDate(newDate);

    if (dateSwitchCallback) {
      dateSwitchCallback(newDate);
    }
  };

  /**
   * update the date and respect disabled dates
   * @param {"prev" | "next"} direction
   */
  const switchDate = (direction: "prev" | "next") => {
    updateCurrentDate({
      date: currentDate,
      direction,
      callback: (date) => {
        updateDate(date);
      },
    });
  };

  /**
   * on change handler
   * @param {moment.Moment} date
   */
  const onChange = (date: moment.Moment) => {
    if (date?.isValid() && !date.isSame(moment(currentDate))) {
      updateDate(date);
    }
  };

  // update input on external change
  useEffect(() => {
    setCurrentDate(deliveryDate);
  }, [deliveryDate]);

  return (
    <div
      className={clsx(
        "datepickerContainer",
        className,
        isDisabled && "disabled"
      )}
    >
      <button
        type="button"
        className="datepickerArrowPrev"
        disabled={isButtonDisabled({
          date: currentDate,
          direction: "prev",
          unit: "days",
        })}
        onClick={() => switchDate("prev")}
      >
        <Back className="icon iconArrow" />
      </button>

      <div className="datepickerInner">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="datepickerLabel alignLabel" htmlFor={label}>
          {label}
        </label>

        <DatePicker
          allowClear={false}
          className={clsx("datepicker", isDisabled && "disabled")}
          defaultValue={moment(currentDate)}
          disabled={isDisabled}
          disabledDate={disabledDatePickerDates}
          dropdownClassName="datePickerCalendar"
          format={fullDateFormat}
          id={label}
          inputReadOnly
          onChange={onChange}
          size="small"
          value={moment(currentDate)}
        />
      </div>

      <button
        type="button"
        className="datepickerArrowNext"
        disabled={isButtonDisabled({
          date: currentDate,
          direction: "next",
          unit: "year",
        })}
        onClick={() => switchDate("next")}
      >
        <Back className="icon iconArrow" />
      </button>
    </div>
  );
};

export default DateSwitcher;
