import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Checkbox, ConfigProvider, Input, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { RefSelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Bubble from "../Bubble";
import { ReactComponent as Arrow } from "../../../static/svg/arrow.svg";

import { Filter } from "../../../types/filters";
import { parseBooleanFilterNumberToString } from "../../products/ProductsFilter/typecastBooleanFilters";

import { updateFilterStates } from "../../products/ProductsFilter/filterFunctions";
import { RootState } from "../../../types/rootState";

interface SelectFilterProps extends Filter {
  display?: "inline" | "filter-bar" | "sidebar";
  view?: string;
}

interface SelectOptionProps {
  label: string;
  value: string;
}

const SelectFilter: React.FC<SelectFilterProps> = (props) => {
  const {
    name,
    activeValue,
    localizedName,
    values,
    display = "inline",
    view = "",
  } = props;

  const navigate = useNavigate();
  const { search } = useLocation();
  const { locale } = useContext(ConfigProvider.ConfigContext);

  const uniqueFilterId = Math.floor(Math.random() * 10000);

  const ref = useRef<RefSelectProps>();
  const preventClosingRef = useRef<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any[]>(
    activeValue ?? []
  );
  const [optionQuery, setOptionQuery] = useState<string>("");

  const filterState = useSelector(
    (state: RootState) =>
      state.productsMetaData.filters.filterStates?.[name] || null
  );

  const bordered = useMemo(
    () => display === "inline" || display === "sidebar",
    [display]
  );
  const optionCount = useMemo(() => values?.length, [values]);

  const availableValues = useMemo(
    () => values.map((singleValue) => singleValue.value),
    [values]
  );

  const availableSelectedValues = useMemo(() => {
    return selectedValues.filter((singleValue) =>
      availableValues.includes(singleValue)
    );
  }, [selectedValues, availableValues]);

  const allSelected = useMemo<boolean>(
    () => availableSelectedValues.length === optionCount,
    [availableSelectedValues, optionCount]
  );

  const someSelected = useMemo<boolean>(
    () => !allSelected && availableSelectedValues.length > 0,
    [availableSelectedValues, allSelected]
  );

  const getPopupContainer = () =>
    document.getElementById(
      "filter-bar__dropdown"
      // `select-filter--${localizedName}-${uniqueFilterId}`
    );

  const { trackEvent } = useMatomo();

  const options = useMemo<SelectOptionProps[]>(() => {
    const collator = new Intl.Collator(locale?.locale || "de", {
      sensitivity: "base",
    });

    return values
      .map((singleValue) => {
        return {
          value: singleValue?.value,
          label: parseBooleanFilterNumberToString(
            singleValue?.label ?? singleValue?.value
          ),
        };
      })
      .filter((optionEntry) => {
        if (!optionQuery) {
          return true;
        }
        return (
          optionEntry.label.toLowerCase().includes(optionQuery.toLowerCase()) ||
          optionEntry.value.toLowerCase().includes(optionQuery.toLowerCase())
        );
      })
      .sort((a, b) => collator.compare(a?.label || "", b?.label || ""));
  }, [values, optionQuery, locale]);

  useEffect(() => {
    setIsClosing(false);
    preventClosingRef.current = false;
  }, [open]);

  const acceptChanges = () => {
    if (filterState || !!selectedValues.length) {
      updateFilterStates(
        navigate,
        name,
        selectedValues.filter((singleValue) =>
          availableValues.includes(singleValue)
        ),
        search
      );
    }
  };

  const openSelect = () => {
    setOpen(true);
  };

  const closeSelect = () => {
    acceptChanges();
    setOpen(false);
  };

  const toggleAllOptions = () => {
    if (allSelected) {
      setSelectedValues([]);
      return;
    }

    setSelectedValues(availableValues);
  };

  const checkboxChanged = () => {
    const changeValues = allSelected
      ? []
      : values.map((singleValue) => singleValue.value);

    updateFilterStates(navigate, name, changeValues, search);
  };

  const onChange = (changeValues: string[]) => {
    setSelectedValues(
      availableValues.filter((singleValue) =>
        changeValues.includes(singleValue)
      )
    );
  };

  const onSearch = (searchQuery: string) => {
    setOptionQuery(searchQuery);
  };

  const onBlur = () => {
    setTimeout(() => setOptionQuery(""), 500);
  };

  const { Option } = Select;

  function Dropdown(menu: React.ReactElement) {
    const showSearchField = display === "filter-bar";

    return (
      <>
        {showSearchField && (
          <div className={clsx("select-filter__dropdown-search-container")}>
            <Input
              className={clsx("select-filter__dropdown-search")}
              placeholder="Durchsuchen"
              defaultValue={optionQuery}
              onChange={(changeEvent) =>
                setOptionQuery(changeEvent.target.value)
              }
            />
          </div>
        )}

        {display === "filter-bar" && options.length > 1 && (
          <Button
            onClick={toggleAllOptions}
            className={clsx("select-filter__dropdown-select-all")}
          >
            {allSelected ? "Alles abwählen" : "Alles auswählen"}
          </Button>
        )}

        {menu}
      </>
    );
  }

  function SelectHead() {
    if (display === "sidebar") {
      return <></>;
    }

    if (display === "inline") {
      return (
        <Checkbox
          checked={allSelected}
          onChange={checkboxChanged}
          indeterminate={someSelected}
        >
          <span className={clsx("select-filter__checkbox-label")}>
            {localizedName}
          </span>
        </Checkbox>
      );
    }

    return (
      <button
        type="button"
        className={clsx(
          "select-filter__filter-bar-item",
          "buttonAsset",
          "buttonText",
          "buttonTextDecoration--never"
        )}
        onClick={() => {
          if (open) {
            closeSelect();
          } else {
            openSelect();
          }
          if (view === "weekplanner") {
            trackEvent({
              category: "filter",
              action: `filter activity in ${display}`,
              name: `${localizedName}`,
            });
          }
        }}
      >
        <div className={clsx("select-filter__filter-bar-label")}>
          {localizedName}
        </div>
        {(allSelected || someSelected) && (
          <Bubble
            value={
              selectedValues.filter((singleValue) =>
                availableValues.includes(singleValue)
              ).length
            }
            className={clsx("select-filter__filter-bar-bubble")}
          />
        )}
        <Arrow
          className={clsx(
            "select-filter__filter-bar-arrow",
            `select-filter__filter-bar-arrow--${open ? "down" : "up"}`
          )}
        />
      </button>
    );
  }

  return (
    <div className={clsx("select-filter", `select-filter--${display}`)}>
      <SelectHead />

      <div
        className={clsx("select-filter__container")}
        id={`select-filter--${localizedName}-${uniqueFilterId}`}
        onMouseLeave={() => {
          if (open) {
            setIsClosing(true);
          }
          setTimeout(() => {
            if (preventClosingRef.current === false) {
              closeSelect();
              ref?.current?.blur();
            }
            preventClosingRef.current = false;
          }, 500);
        }}
        onMouseEnter={() => {
          if (open && isClosing) {
            preventClosingRef.current = true;
          }
        }}
      >
        <Select
          className="select-filter__select"
          ref={ref}
          mode="multiple"
          bordered={bordered}
          maxTagCount="responsive"
          size="large"
          placeholder={
            display !== "sidebar" ? "Auswahl / Suchen" : localizedName
          }
          getPopupContainer={getPopupContainer}
          dropdownClassName="select-filter__dropdown"
          open={open}
          filterOption={(input, option) =>
            option.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onFocus={() => {
            if (view === "weekplanner") {
              trackEvent({
                category: "filter",
                action: `filter activity in ${display}`,
                name: `${localizedName}`,
              });
            }
            openSelect();
          }}
          menuItemSelectedIcon={<></>}
          defaultValue={selectedValues}
          value={selectedValues}
          onSearch={onSearch}
          onChange={onChange}
          onBlur={onBlur}
          /* eslint-disable-next-line react/jsx-no-bind */
          dropdownRender={Dropdown}
        >
          {options.map(({ value, label }, index) => (
            <Option value={value} label={label} key={index}>
              <div
                className={clsx(
                  "select-filter__option",
                  selectedValues.includes(value) &&
                    "select-filter__option--selected"
                )}
              >
                {selectedValues.includes(value) && (
                  <div className={clsx("select-filter__option-check")}>
                    <CheckOutlined />
                  </div>
                )}
                <div className={clsx("select-filter__option-label")}>
                  {label}
                </div>
              </div>
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default SelectFilter;
