import { NotificationModal } from "../../types/notificationModal";

const initialState: NotificationModal = {
  isHidden: false,
};

const notificationModalReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "notification-modal/set-is-hidden": {
      const { isHidden } = payload;

      return { ...state, isHidden };
    }

    default:
      return state;
  }
};

export default notificationModalReducer;
