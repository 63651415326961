import React from "react";
import { Tooltip } from "antd";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { ReactComponent as GraduatedPrices } from "../../static/svg/graduated-prices.svg";
import Price from "../price/Price";
import { PriceSet } from "../../types/productData";
import { RootState } from "../../types/rootState";

interface Props {
  volumePrices: any[];
  basePriceQuantity?: string;
  className?: string;
  view?: string;
  getContainer?: () => HTMLElement;
}

/**
 * renders icon and tooltip for volume prices
 * @param volumePrices {Array}
 * @param basePriceQuantity
 * @param className {String}
 * @param view {String}
 * @param getContainer
 */
function ProductInfoVolumePricesPopup({
  volumePrices,
  basePriceQuantity = "1",
  className,
  view,
  getContainer,
}: Props) {
  const hidePrices = useSelector(
    (state: RootState) => state?.appSettings?.hidePrices || false
  );

  const tooltipContent = (
    <div className="productInfoPopup">
      <div className="productInfoPopup__info">
        <h5>Staffelpreise</h5>

        <ul className="productInfoPopup__list list--unstyled">
          {volumePrices.map(({ price, quantity }: PriceSet) => {
            return (
              <li className="productInfoPopupList__list-entry" key={price}>
                Ab {quantity}:{" "}
                <Price
                  price={price / parseFloat(basePriceQuantity)}
                  className="text-bold"
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  const hasValidVolumePrices =
    volumePrices?.length > 0 &&
    !volumePrices.every(
      ({ quantity: volumePriceQuantity }: PriceSet) => volumePriceQuantity <= 1
    );

  if (!hasValidVolumePrices) {
    return null;
  }

  return view === "weekplanner" ? (
    <div className={clsx("volume-prices", hidePrices && "blurred")}>
      {!!volumePrices?.[1] && (
        <div className="text-bold secondVolumePrice">
          {`[${volumePrices[1].quantity}] `}
          <Price
            price={volumePrices[1].price / parseFloat(basePriceQuantity)}
          />
        </div>
      )}

      {volumePrices?.length > 2 && (
        <Tooltip
          title={hidePrices ? null : tooltipContent}
          trigger="click"
          getTooltipContainer={getContainer}
          destroyTooltipOnHide
        >
          <button
            disabled={hidePrices}
            type="button"
            className={clsx(
              "text-bold productInfoIcon allVolumePrices",
              className
            )}
          >
            ...
          </button>
        </Tooltip>
      )}
    </div>
  ) : (
    <Tooltip
      placement="topRight"
      title={tooltipContent}
      trigger={["hover", "click"]}
      getTooltipContainer={getContainer}
      destroyTooltipOnHide
    >
      <button type="button" className={clsx("productInfoIcon", className)}>
        <GraduatedPrices className="icon iconInfo" />
      </button>
    </Tooltip>
  );
}

export default ProductInfoVolumePricesPopup;
