import React from "react";

import { Col, Modal, Row } from "antd";
import { Entry } from "contentful";

import { ContentfulWebshopCategoryVideoEntry } from "../../../../types/contentfulWebshopCategoryVideoEntry";

interface WebshopCategoryVideoModalProps {
  webshopCategoryVideo: Entry<ContentfulWebshopCategoryVideoEntry>;
  videoUrl: string;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const WebshopCategoryVideoModal: React.FC<WebshopCategoryVideoModalProps> = (
  props: WebshopCategoryVideoModalProps
) => {
  const { webshopCategoryVideo, videoUrl, isVisible, setIsVisible } = props;
  const { title } = webshopCategoryVideo.fields;

  return (
    <Modal
      visible={isVisible}
      title={title}
      okText="Schließen"
      onOk={() => setIsVisible(false)}
      onCancel={() => setIsVisible(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ className: "button buttonPrimary button--rounded" }}
      destroyOnClose
      wrapClassName="webshop-category-video-modal"
      width={600}
    >
      <Row gutter={[20, 10]} className="mt-xl">
        <Col span={12}>
          <h4>{title}</h4>
          <div className="webshop-category-video-modal__video-wrapper">
            <iframe
              title={title}
              width="560"
              height="315"
              src={videoUrl}
              frameBorder="0"
              allowFullScreen
              sandbox="allow-same-origin allow-scripts allow-popups"
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default WebshopCategoryVideoModal;
