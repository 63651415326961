import React from "react";
import { Layout } from "antd";
import HeaderLogoOnly from "../navigation/HeaderLogoOnly";
import Footer from "../navigation/Footer";

function PublicPageLayoutWrapper({ children }: any) {
  return (
    <Layout className="container-layout">
      <Layout.Header className="container-layout container-layout--header">
        <HeaderLogoOnly />
      </Layout.Header>

      <Layout.Content className="container-layout container-layout--content">
        {children}
      </Layout.Content>

      <Layout.Footer className="container-layout container-layout--footer">
        <Footer />
      </Layout.Footer>
    </Layout>
  );
}

export default PublicPageLayoutWrapper;
