import axios, { CancelTokenSource } from "axios";
import { message } from "antd";
import deleteCartMessage from "../../api/cart/deleteCartMessage";
import storeDispatch from "./storeDispatch";
import { messageData } from "../../appConfig";

interface DeleteCartMessageAlternative {
  deliveryDate: string;
  messageId: string | string[];
  sku: string | string[];
  cancelTokenSource: CancelTokenSource;
}

const deleteCartMessageAlternative = ({
  deliveryDate,
  messageId,
  sku,
  cancelTokenSource,
}: DeleteCartMessageAlternative) =>
  deleteCartMessage({
    deliveryDate,
    messageId,
    cancelTokenSource,
  })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        message.error(messageData.error.cart.deleteMessage);
      }
    })
    .then(() => {
      /*
       * remove local stored alternative
       * is not further needed
       */
      storeDispatch("cart/delete-alternative", sku);

      /*
       * remove local stored message
       * is used to update view, because the response is a 204
       */
      storeDispatch("cartMeta/delete-message", sku);
    });

export default deleteCartMessageAlternative;
