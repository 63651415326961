import React, { useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { BackTop, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { ListOnScrollProps, VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";

import { useSelector } from "react-redux";
import WeekplannerGroupHeader from "./WeekplannerGroupHeader";
import { ProductDetailModal } from "../../../molecules";
import { itemsPerPage } from "../../../../appConfig";
import WeekplannerItem from "./WeekplannerItem";
import WeekplannerProductDetailModalContext from "../../../../contexts/WeekplannerProductDetailModalContext";
import { WeekplannerItemData } from "../../../../types/weekplanner";
import { ReactComponent as Arrow } from "../../../../static/svg/arrow.svg";
import { RootState } from "../../../../types/rootState";

interface WeekplannerItemsProps {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: Array<WeekplannerItemData>;
  loadNextPage: () => void;
  categoryKey: string;
  className?: string;
  onScroll: (scrollProps: ListOnScrollProps) => void;
  isHeaderVisible: boolean;
  defaultItemSize: number;
}

const WeekplannerItems: React.FC<WeekplannerItemsProps> = (
  props: WeekplannerItemsProps
) => {
  const {
    hasNextPage,
    isNextPageLoading,
    items,
    loadNextPage,
    categoryKey,
    className,
    onScroll = () => {},
    isHeaderVisible,
    defaultItemSize,
  } = props;

  const { search } = useLocation();

  const [productSku, setProductSku] = useState<string>(null);
  const [isProductDetailModalVisible, setIsProductDetailModalVisible] =
    useState<boolean>(false);

  const { weekplannerUseSimpleMode: useSimpleMode = false } = useSelector(
    (state: RootState) => state?.userData || {}
  );
  const { companyBusinessUnitKey } = useSelector(
    (state: RootState) => state?.userData?.businessUnit || {}
  );

  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const listRef = useRef(null);
  const hasMountedRef = useRef(false);

  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const getItemSize = useCallback(
    (index: any) => (index === 0 ? 25 : items[index]?.size ?? defaultItemSize),
    [items, defaultItemSize]
  );
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: any) => !hasNextPage || index < items.length;

  useEffect(() => {
    if (hasMountedRef.current) {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true);
      }

      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
      }
    }
    hasMountedRef.current = true;
  }, [categoryKey, search, companyBusinessUnitKey]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [isHeaderVisible, useSimpleMode]);

  return (
    <div className={clsx("weekplanner-items", className)}>
      <AutoSizer>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {({ height, width }) => (
          <InfiniteLoader
            ref={infiniteLoaderRef}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            isItemLoaded={isItemLoaded}
            threshold={Math.floor(itemsPerPage.products / 2)}
          >
            {({ onItemsRendered, ref }) => (
              <List
                ref={(node) => {
                  if (typeof ref === "function") {
                    ref(node);
                  }
                  listRef.current = node;
                }}
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                height={height}
                width={width}
                itemSize={getItemSize}
                onScroll={(scrollProps: ListOnScrollProps) => {
                  if (typeof onScroll === "function") {
                    onScroll(scrollProps);
                  }
                }}
                style={{ overflowY: "scroll" }}
                overscanCount={5}
              >
                {({ index, style }) =>
                  isItemLoaded(index) ? (
                    <>
                      {items[index]?.type === "header" ? (
                        <WeekplannerGroupHeader
                          title={items[index]?.data}
                          style={style}
                        />
                      ) : (
                        <WeekplannerItem
                          productData={items?.[index]?.data}
                          style={style}
                          onClick={(sku: string) => {
                            setProductSku(sku);
                            setIsProductDetailModalVisible(true);
                          }}
                          className={items?.[index]?.className ?? ""}
                        />
                      )}
                    </>
                  ) : (
                    <div style={style} className="weekplanner-items__body">
                      {isNextPageLoading && (
                        <div className="weekplanner-items__body--loading">
                          <Spin />
                          Lade Artikel...
                        </div>
                      )}
                      {!isNextPageLoading && !items?.length && (
                        <div className="weekplanner-items__body--empty">
                          Keine Artikel gefunden.
                        </div>
                      )}
                    </div>
                  )
                }
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
      <WeekplannerProductDetailModalContext.Provider value>
        <ProductDetailModal
          sku={productSku}
          type="scan"
          visible={productSku && isProductDetailModalVisible}
          onCancel={() => {
            setIsProductDetailModalVisible(false);
          }}
          onOk={() => {
            setIsProductDetailModalVisible(false);
          }}
        />
      </WeekplannerProductDetailModalContext.Provider>
      <BackTop
        visible={!isHeaderVisible}
        className="customBackTop"
        onClick={() => {
          if (listRef.current) {
            listRef.current.scrollTo(0);
          }
        }}
      >
        <div className="buttonBackToTop">
          <Arrow className="icon" />
        </div>
      </BackTop>
    </div>
  );
};

export default WeekplannerItems;
