import React from "react";
import clsx from "clsx";
import HrDivider from "../divider/Divider";
import { ReactComponent as Close } from "../../static/svg/close.svg";
import { ReactComponent as Mail } from "../../static/svg/mail.svg";
import { ReactComponent as Phone } from "../../static/svg/phone.svg";

interface Props {
  contactPerson?: Record<string, any>;
  setIsContactPersonVisible: (advisorImageURL: boolean) => void;
  isFooterComponent?: boolean;
}

const ContactPerson: React.FC<Props> = ({
  setIsContactPersonVisible,
  contactPerson,
  isFooterComponent = false,
}: Props) => {
  return (
    <div className="contactPerson">
      <div
        className={clsx(
          "headerContactPerson",
          isFooterComponent && "headerContactPersonMobile"
        )}
      >
        <h3 className="contactPersonTitle">Wie kann ich helfen?</h3>
        <button
          onClick={() => {
            setIsContactPersonVisible(false);
          }}
          className="button buttonPrimary buttonPrimary--inverted closeContactPerson"
          type="button"
        >
          <Close className="closeIcon" />
        </button>
      </div>
      <HrDivider
        className="divider"
        size={1}
        spacingTop="xs"
        spacingBottom="l"
      />
      <div className="contactPersonWrapper">
        <div
          className={clsx(
            "imageWrapper",
            isFooterComponent && "contactPersonFooter"
          )}
        >
          {contactPerson?.picture ? (
            <img
              className="contactPersonImage"
              src={contactPerson?.picture}
              alt="advisor"
            />
          ) : (
            <Phone className="iconCustomerConsultant" />
          )}
        </div>
        <nav className="contactPersonData">
          <ul className="flex flex-col p-0 mb-0">
            <li className="contactPersonItem">{contactPerson?.name}</li>
            {contactPerson?.phone && (
              <li className="contactPersonItem flex align-center">
                <Phone className="listIcon" />
                <a href={`tel:${contactPerson.phone}`}>{contactPerson.phone}</a>
              </li>
            )}
            {contactPerson?.email && (
              <li className="contactPersonItem">
                <Mail className="listIcon email" />
                <a href={`mailto:${contactPerson.email}`} className="emailTag">
                  {contactPerson.email}
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default ContactPerson;
