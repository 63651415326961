import { useEffect, useState } from "react";

import axios from "axios";

import getSupportFaq from "../api/contentful/getSupportFaq";
import handleError from "../utils/handleError";
import { ContentfulSupportFaqEntry } from "../types/contentfulSupportFaqEntry";

const useGetSupportFaq = (limit: number, skip: number) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<ContentfulSupportFaqEntry[]>([]);
  const [total, setTotal] = useState<number>(null);

  useEffect(() => {
    setIsLoading(true);
    getSupportFaq({ limit, skip })
      .then((response) => {
        setEntries(response.items);
        setTotal(response.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [limit, skip]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetSupportFaq;
