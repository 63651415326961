import {
  PriceSet,
  ProductAlternatives,
  ProductData,
} from "../../types/productData";

interface DeliveryDateBasedAttributes {
  deliveryDate: ProductData["deliveryDate"];
  availabilities?: ProductData["availabilities"];
  prices?: ProductData["prices"];
  productAlternatives?: ProductData["productAlternatives"];
}

interface DefaultAvailabilities {
  availability: null | boolean;
  explanation: null | string;
  isNeverOutOfStock: null | boolean;
  nextAvailability: null | string;
  quantity: null | number;
  showAvailability: null | boolean;
  isAvailable: null | boolean;
}

interface DefaultPrices {
  defaultPrice: null | number;
  price: null | number;
  rrCalcPrice: null;
  rrPrice: null;
  volumePrices: null | PriceSet[];
}

/**
 * helper to retrieve the attributes based on the deliveryDate
 * @param deliveryDate {ProductData["deliveryDate"]}
 * @param availabilities {ProductData["availabilities"]}
 * @param prices {ProductData["prices"]}
 * @param productAlternatives {ProductData["productAlternatives"]}
 */
const getDeliveryDateBasedAttributes = ({
  deliveryDate,
  availabilities,
  prices,
  productAlternatives,
}: DeliveryDateBasedAttributes) => {
  // fallbacks for destructuring
  const defaultAvailabilities: DefaultAvailabilities = {
    availability: null,
    isNeverOutOfStock: null,
    explanation: null,
    nextAvailability: null,
    quantity: null,
    showAvailability: null,
    isAvailable: null,
  };

  const defaultPrices: DefaultPrices = {
    defaultPrice: null,
    price: null,
    rrCalcPrice: null,
    rrPrice: null,
    volumePrices: null,
  };

  const dateBasedAvailabilities =
    availabilities?.length &&
    availabilities
      ?.sort((a, b) => Number(b.availability) - Number(a.availability))
      ?.find(
        (availabilitySet) => availabilitySet?.deliveryDate === deliveryDate
      );

  // set availabilities
  const {
    availability,
    isNeverOutOfStock,
    explanation,
    nextAvailability,
    quantity,
    showAvailability,
    isAvailable,
  } = dateBasedAvailabilities || defaultAvailabilities;

  /**
   * if the attribute isNeverOutOfStock is true, then the article should always be listed as available
   * if there are no date-based availabilities, the item is always unavailable
   * otherwise test, if the availability is exactly false, not null or any other falsy value
   */
  const isUnavailable = (() => {
    if (isNeverOutOfStock || isAvailable) {
      return false;
    }

    if (!dateBasedAvailabilities) {
      return true;
    }

    return availability === false;
  })();

  // get all prices for deliveryDate volumePrices
  const { rrCalcPrice, rrPrice, volumePrices } =
    (prices?.length &&
      prices?.find((priceSet) => priceSet?.deliveryDate === deliveryDate)) ||
    defaultPrices;

  // the volume price with a quantity = 1 is the default
  const { defaultPrice, price } =
    volumePrices?.find((priceSet) => priceSet?.quantity === 1) || defaultPrices;

  // get productAlternatives data
  const dailyProductAlternatives: ProductAlternatives["productAlternatives"] =
    (
      productAlternatives?.length &&
      productAlternatives?.find(
        (productAlternativeSet) =>
          productAlternativeSet?.deliveryDate === deliveryDate
      )
    )?.productAlternatives || null;

  return {
    availability,
    isUnavailable,
    explanation,
    nextAvailability,
    quantity,
    showAvailability,
    defaultPrice,
    price,
    rrCalcPrice,
    rrPrice,
    volumePrices,
    strikePrice: defaultPrice > price ? defaultPrice : 0,
    dailyProductAlternatives,
    isAvailable,
  };
};

export default getDeliveryDateBasedAttributes;
