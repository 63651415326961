import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { ProductImage } from "../product";
import { routePathNames } from "../../appConfig";
import { ProductData } from "../../types/productData";
import getDeliveryDateBasedAttributes from "../product/getDeliveryDateBasedAttributes";
import getDefaultImageUrlSet from "../../utils/getDefaultImageUrlSet";
import { SuggestItem } from "../../types/suggestItem";

interface Props {
  productSuggestData: ProductData;
  type?: SuggestItem;
  deliveryDate: string;
  isAlreadyOnList?: boolean;
  isAlreadyOnListWarning?: string;
}

const createSuggestItems = ({
  productSuggestData,
  deliveryDate,
  isAlreadyOnList,
  isAlreadyOnListWarning,
  type = "search",
}: Props) => {
  const { availabilities, name, sku, imageSets, brand, vpeQuantity, vpeText } =
    productSuggestData;

  const { isUnavailable, nextAvailability } = getDeliveryDateBasedAttributes({
    availabilities,
    deliveryDate,
  });

  const isProductUnavailable = isUnavailable && type !== "priceTagList";

  const key = `${name}_${sku}`;
  const quickOrderIsOnList = isAlreadyOnList && type !== "search";

  const suggestItemProductBrand = `${brand ? ` | ${brand}` : ""}`;
  const suggestItemProductVpe = `${
    vpeQuantity || vpeText
      ? ` | ${vpeQuantity ? `${vpeQuantity}x ` : ""}${vpeText || ""}`
      : ""
  }`;

  const innerSuggestItem = (
    <div className="suggestItemProductInfo">
      {quickOrderIsOnList ? (
        <span className="suggestItemCartInfo">
          {sku} {isAlreadyOnListWarning}
        </span>
      ) : (
        <>
          <span className="suggestItemProductName">{name}</span>
          <span className="suggestItemProductDetails">
            {`${sku}${suggestItemProductBrand}${suggestItemProductVpe}`}
          </span>
        </>
      )}
    </div>
  );

  return {
    disabled: isProductUnavailable || quickOrderIsOnList,
    value: sku,
    key,
    label: (
      <div
        className="suggestItem"
        key={key}
        aria-disabled={isProductUnavailable}
      >
        {type === "search" ? (
          <Link
            to={`${routePathNames.product}${sku}`}
            className="suggestItemLink"
          >
            <ProductImage
              imageSet={getDefaultImageUrlSet(imageSets)}
              className={clsx({
                isUnavailable: isProductUnavailable && !nextAvailability,
              })}
              imageType="suggestion"
            />

            {innerSuggestItem}
          </Link>
        ) : (
          <span className="suggestItemLink">
            <ProductImage
              imageSet={getDefaultImageUrlSet(imageSets)}
              className={clsx({
                isUnavailable: isProductUnavailable && !nextAvailability,
              })}
              imageType="suggestion"
            />

            {innerSuggestItem}
          </span>
        )}
      </div>
    ),
  };
};

export default createSuggestItems;
