import objectHasKey from "../../utils/objectHasKey";

/**
 * helper to retrieve the main attributes object in any catalog search request
 * @param data {Array<any>}
 */
const getCatalogSearchAttributesData = (data: any[]) => {
  const { attributes } = data?.find((entry: any) =>
    objectHasKey(entry, "attributes")
  ) || { attributes: {} };

  return attributes;
};

export default getCatalogSearchAttributesData;
