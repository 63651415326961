/**
 * parse prices to cent or euro
 * @param {number} price
 * @param {boolean} shiftToCent
 */
const priceShiftCurrencyUnit = (price: number, shiftToCent = false) => {
  const numberPrice = Number(price);

  if (shiftToCent) {
    return numberPrice * 100;
  }

  return numberPrice / 100;
};

export default priceShiftCurrencyUnit;
