import React, { useMemo, useState } from "react";

import clsx from "clsx";
import axios from "axios";
import { Button, message, Popconfirm, Table, Tooltip } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import moment from "moment/moment";

import ReorderModal from "../../order/OrderDetail/ReorderModal";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import deleteCart from "../../../api/cart/deleteCart";
import requestCatchHandler from "../../../api/requestCatchHandler";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import { dateFormat } from "../../../utils/dateFormats";
import { messageData } from "../../../appConfig";
import { ReactComponent as Cart } from "../../../static/svg/cart.svg";
import { ReactComponent as Delete } from "../../../static/svg/delete.svg";
import { ReactComponent as DeliveryDate } from "../../../static/svg/deliverydate.svg";
import { ReactComponent as Arrow } from "../../../static/svg/arrow.svg";
import { ProductData } from "../../../types/productData";

interface ExpiredCartLineProps {
  cartId: string;
  deliveryDate: moment.Moment;
  items: any[];
  productData: ProductData[];
  totalItems: number;
  className?: string;
  lineType?: "" | "not-empty";
  totalCarts?: number;
  onDeleteCart?: () => void;
}

const ExpiredCartLine: React.FC<ExpiredCartLineProps> = (props) => {
  const {
    cartId,
    deliveryDate,
    items = [],
    productData = [],
    totalItems = 0,
    className,
    lineType,
    totalCarts = 1,
    onDeleteCart,
  } = props;

  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-md")})`
  );

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isReorderModalVisible, setIsReorderModalVisible] =
    useState<boolean>(false);
  const [isCartDeleting, setIsCartDeleting] = useState<boolean>(false);

  const reorderModalCartItems: ProductData[] = useMemo(
    () =>
      items.map((item: any) => {
        return { sku: item?.sku, quantity: item?.quantity } as ProductData;
      }),
    [items]
  );

  const tableData = useMemo(() => {
    return items.map((data: any) => {
      return {
        key: `${deliveryDate}_${data?.sku}`,
        sku: data?.sku,
        quantity: data?.quantity,
        name:
          productData.find((item: any) => item?.sku === data?.sku)?.name ?? "-",
      };
    });
  }, [items, deliveryDate, productData]);

  const tableColumns: ColumnsType<any> = [
    { title: "", dataIndex: "sku", className: "expired-cart-line__table__sku" },
    {
      title: "Artikelbezeichnung",
      dataIndex: "name",
      ellipsis: true,
      width: "60%",
    },
    {
      title: "Anzahl",
      dataIndex: "quantity",
      align: "right",
      className: "expired-cart-line__table__amount",
    },
  ];

  const handleDeleteCart = () => {
    const cancelTokenSource = getCancelTokenSource();
    setIsCartDeleting(true);
    deleteCart(cartId, cancelTokenSource)
      .then(() => {
        setIsCartDeleting(false);
        if (typeof onDeleteCart === "function") {
          onDeleteCart();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsCartDeleting(false);
          message.error(messageData.error.cart.deleteMessage);
        }
        requestCatchHandler(error);
      });
  };

  return (
    <>
      <div
        className={clsx(
          "expired-cart-line",
          lineType && `expired-cart-line--${lineType}`,
          className
        )}
      >
        <div
          className={clsx(
            "expired-cart-line__content",
            className,
            totalCarts === 1 && "expired-cart-line--single"
          )}
        >
          <Cart />

          <div className="expired-cart-line__delivery-date">
            <span className="expired-cart-line__label">Lieferdatum</span>
            <span className="expired-cart-line__value">
              {deliveryDate ? deliveryDate.format(`${dateFormat} YY`) : "-"}
            </span>
          </div>

          <div className="expired-cart-line__amount">
            <span className="expired-cart-line__label">Lieferumfang</span>
            <span className="expired-cart-line__value">
              {totalItems} Artikel
            </span>
          </div>

          <div className="expired-cart-line-actions">
            {browserIsDesktop && (
              <Tooltip title="Warenkorb verschieben">
                <Button
                  icon={<DeliveryDate />}
                  type="text"
                  disabled={!items || !items?.length}
                  onClick={() => {
                    setIsReorderModalVisible(true);
                  }}
                />
              </Tooltip>
            )}

            {browserIsDesktop && (
              <Popconfirm
                title="Soll dieser Warenkorb wirklich unwiderruflich gelöscht werden?"
                onConfirm={handleDeleteCart}
                okText="Ja"
                cancelText="Nein"
                arrowPointAtCenter
                placement="bottom"
                overlayClassName="expired-cart-line-delete-cart-popconfirm"
              >
                <Tooltip title="Warenkorb löschen">
                  <Button
                    icon={<Delete />}
                    type="text"
                    loading={isCartDeleting}
                  />
                </Tooltip>
              </Popconfirm>
            )}

            <Tooltip
              title={`Artikel ${!isCollapsed ? "ausblenden" : "einblenden"}`}
            >
              <Button
                icon={<Arrow className={clsx(isCollapsed && "collapsed")} />}
                type="text"
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}
                disabled={!items || !items?.length}
              />
            </Tooltip>

            {!browserIsDesktop && (
              <Tooltip
                placement="left"
                arrowPointAtCenter
                title={
                  <ul className="list list--unstyled">
                    <li className="listEntry">
                      <Button
                        type="text"
                        disabled={!items || !items?.length}
                        onClick={() => {
                          setIsReorderModalVisible(true);
                        }}
                      >
                        Warenkorb verschieben
                      </Button>
                    </li>

                    <Popconfirm
                      title="Soll dieser Warenkorb wirklich unwiderruflich gelöscht werden?"
                      onConfirm={handleDeleteCart}
                      okText="Ja"
                      cancelText="Nein"
                      arrowPointAtCenter
                      placement="bottom"
                      overlayClassName="expired-cart-line-delete-cart-popconfirm"
                    >
                      <Button type="text" loading={isCartDeleting}>
                        Warenkorb löschen
                      </Button>
                    </Popconfirm>
                  </ul>
                }
                trigger="click"
              >
                <Button
                  type="text"
                  icon={<MoreOutlined />}
                  // className="button buttonText buttonText--menu color-primary"
                />
              </Tooltip>
            )}
          </div>

          {!isCollapsed && (
            <div className="expired-cart-line-table">
              <Table
                dataSource={tableData}
                columns={tableColumns}
                pagination={false}
                size="small"
              />
            </div>
          )}
        </div>
      </div>

      <ReorderModal
        productItems={reorderModalCartItems}
        isModalVisible={isReorderModalVisible}
        setIsModalVisible={setIsReorderModalVisible}
        modalQuestion="Möchten Sie diese Artikel dem Warenkorb hinzufügen?"
        onReorderFinish={() => {
          const cancelTokenSource = getCancelTokenSource();
          deleteCart(cartId, cancelTokenSource).catch((error) => {
            requestCatchHandler(error);
          });
        }}
      />
    </>
  );
};

export default ExpiredCartLine;
