import { useEffect } from "react";
import setCartAndUserListsToStore from "../state/actions/setCartAndUserListsToStore";
import useGlobalNetworkState from "./useGlobalNetworkState";
import useGetDeliveryDate from "./useGetDeliveryDate";
import getCancelTokenSource from "../api/getCancelTokenSource";

/**
 * get the deliveryDate which should be used and update cart if necessary
 */
const useGetDeliveryDateAndUpdateCartAccordingly = () => {
  const [requestDate, deliveryDate] = useGetDeliveryDate();
  const [, setComponentIsLoading] = useGlobalNetworkState("component", false);

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();

    if (requestDate !== deliveryDate) {
      setCartAndUserListsToStore({
        deliveryDate: requestDate,
        cancelTokenSource,
        setComponentIsLoading,
      });
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [deliveryDate, requestDate, setComponentIsLoading]);

  return requestDate;
};

export default useGetDeliveryDateAndUpdateCartAccordingly;
