import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { useForm } from "antd/lib/form/Form";
import axios, { CancelTokenSource } from "axios";
import { Button, Col, message, Modal, Popconfirm, Row } from "antd";

import deleteStocktakingItem from "../../../../api/stocktaking/deleteStocktakingItem";
import StocktakingForm from "../StocktakingForm";
import patchStocktakingItem from "../../../../api/stocktaking/patchStocktakingItem";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import useCancelAxiosOnUnmount from "../../../../hooks/useCancelAxiosOnUnmount";
import {
  getPriceFromFormValue,
  getQuantityFromFormValue,
} from "../StocktakingForm/utils";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../../api/getCancelTokenSource";
import { messageData } from "../../../../appConfig";
import { StocktakingItemData } from "../../../../types/stocktaking";
import { ReactComponent as Delete } from "../../../../static/svg/delete.svg";
import { ReactComponent as Edit } from "../../../../static/svg/edit.svg";
import { productAttributes } from "../../../../api/productAttributes";

interface StocktakingItemPatchModalProps {
  stocktakingItem: StocktakingItemData;
  className?: string;
  canEdit?: boolean;
  onUpdate?: () => void;
  onDelete?: () => void;
}

const StocktakingItemPatchModal: React.FC<StocktakingItemPatchModalProps> = (
  props: StocktakingItemPatchModalProps
) => {
  const {
    stocktakingItem,
    className,
    canEdit = true,
    onUpdate = () => {},
    onDelete = () => {},
  } = props;

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [form] = useForm();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [quantitySuffix, setQuantitySuffix] = useState<string>("");

  const handlePatch = () => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );
    setHasErrors(false);
    setIsUpdating(true);
    patchStocktakingItem({
      stocktakingItemId: stocktakingItem.idStocktakingItem,
      label: form.getFieldValue("label"),
      quantity:
        getQuantityFromFormValue(form.getFieldValue("quantity")) || undefined,
      unitPrice:
        getPriceFromFormValue(form.getFieldValue("unitPrice")) || undefined,
      taxRate: form.getFieldValue("taxRate") || undefined,
      producer: form.getFieldValue("producer"),
      sku: form.getFieldValue("sku"),
      cancelTokenSource: cancelTokenSource.current,
    })
      .then(() => {
        setIsUpdating(false);
        setIsVisible(false);
        onUpdate();
        message.success({
          ...messageData.success.stocktakingItems.updateSuccess,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsUpdating(false);
          // setIsVisible(false);
          message.error({ ...messageData.error.stocktakingItems.updateError });
        }
        requestCatchHandler(error);
      });
  };

  const handleDelete = () => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );
    setIsDeleting(true);
    deleteStocktakingItem({
      stocktakingItemId: stocktakingItem.idStocktakingItem,
      cancelTokenSource: cancelTokenSource.current,
    })
      .then(() => {
        setIsDeleting(false);
        setIsVisible(false);
        onDelete();
        message.success({
          ...messageData.success.stocktakingItems.deleteSuccess,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsDeleting(false);
          setIsVisible(false);
          message.error({ ...messageData.error.stocktakingItems.deleteError });
        }
        requestCatchHandler(error);
      });
  };

  useEffect(() => {
    const { [productAttributes.priceUnitText]: priceUnitText } =
      stocktakingItem?.product?.attributes || {};
    setQuantitySuffix(priceUnitText);

    form.setFields([
      { name: "label", value: stocktakingItem?.label },
      {
        name: "quantity",
        value: Number(stocktakingItem?.quantity) || undefined,
      },
      {
        name: "unitPrice",
        value: stocktakingItem.unitPrice
          ? parseInt(stocktakingItem.unitPrice, 10) / 100
          : null,
      },
      { name: "taxRate", value: stocktakingItem?.taxRate },
      { name: "producer", value: stocktakingItem?.producer },
      { name: "sku", value: stocktakingItem?.sku },
    ]);
  }, [form, stocktakingItem]);

  return (
    <Row>
      <Col span={12} className="flex items-center justify-end">
        <Modal
          forceRender
          className={clsx("stocktaking-item-update-modal", className)}
          visible={isVisible}
          title="Artikel bearbeiten"
          onCancel={() => setIsVisible(false)}
          destroyOnClose
          width={800}
          key={`modal_stocktaking_update_${stocktakingItem?.eanCode || ""}`}
          footer={
            <Row>
              <Col
                span={12}
                className="flex items-center justify-end sm-flex-col"
              >
                <Button
                  className="button buttonPrimary buttonText sm-with-full sm-mb-m mb-0 mr-s sm-mr-0"
                  onClick={() => setIsVisible(false)}
                >
                  Abbrechen
                </Button>
                <Popconfirm
                  title="Soll dieser Artikel wirklich aus der Inventur gelöscht werden?"
                  onConfirm={handleDelete}
                  okText="Ja"
                  cancelText="Nein"
                  arrowPointAtCenter
                  placement="top"
                  overlayClassName="stocktaking-popconfirm"
                  getPopupContainer={(triggerNode: HTMLElement) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    icon={<Delete className="icon" />}
                    loading={isDeleting}
                    className="button buttonPrimary buttonWithIcon buttonWithSpin sm-with-full sm-mb-m mb-0 mr-0 sm-mr-0"
                  >
                    Löschen
                  </Button>
                </Popconfirm>
                <Button
                  loading={isUpdating}
                  disabled={hasErrors}
                  className="button buttonPrimary buttonWithSpin sm-with-full sm-mb-m mb-0 sm-mr-0"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Speichern
                </Button>
              </Col>
            </Row>
          }
        >
          <StocktakingForm
            formKey={`form_stocktaking_update_${stocktakingItem?.eanCode}`}
            form={form}
            isVisible={isVisible}
            isUpdateMode
            onFinish={handlePatch}
            onFinishFailed={() => {
              setHasErrors(true);
            }}
            onFieldsChange={() => {
              setHasErrors(
                !!form.getFieldsError()?.find((field) => {
                  return !!field.errors.length;
                })
              );
            }}
            quantitySuffix={quantitySuffix}
          />
        </Modal>
        <Button
          className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText"
          onClick={() => setIsVisible(!isVisible)}
          disabled={!canEdit}
        >
          <Edit className="icon" />
        </Button>
      </Col>
    </Row>
  );
};

export default StocktakingItemPatchModal;
