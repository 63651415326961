import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";

import { ProductData } from "../../../../../../types/productData";
import {
  AttributeName,
  productAttributes,
} from "../../../../../../api/productAttributes";

interface WeekplannerItemAttributeProps {
  productData: ProductData;
  attributeName: AttributeName;
  otherAttributeName?: AttributeName;
  attributeSeparator?: string;
  className?: string;
}

const WeekplannerItemAttribute: React.FC<WeekplannerItemAttributeProps> = (
  props: WeekplannerItemAttributeProps
) => {
  const {
    productData,
    attributeName,
    otherAttributeName = null,
    attributeSeparator = "x",
    className,
  } = props;

  const { attributes } = productData || {};

  const {
    [productAttributes?.[attributeName]]: attribute = "",
    [productAttributes?.[otherAttributeName]]: otherAttribute = "",
  } = attributes;

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-attribute",
        className
      )}
    >
      {!otherAttribute
        ? attribute
        : `${attribute} ${attributeSeparator} ${otherAttribute}`}
    </div>
  );
};

export default React.memo(WeekplannerItemAttribute, areEqual);
