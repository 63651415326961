import React from "react";
import { Drawer } from "antd";
import { ReactComponent as Close } from "../../static/svg/close.svg";

interface MobilePopupMenuProps {
  children: React.ReactNode;
  onClose: () => void;
  title: string;
  visible: boolean;
  hasResetButton?: boolean;
  onReset?: () => void;
}

/**
 * mobile menu for multiple purposes
 * e.g. sort of products, product button menu, etc.
 * @param {React.ReactNode} children
 * @param {() => void} onClose
 * @param {string} title
 * @param {boolean} visible
 * @param {boolean} hasResetButton
 * @param {() => void} onReset
 * @constructor
 */
const MobilePopupMenu = function MobilePopupMenu({
  children,
  onClose,
  title,
  visible,
  hasResetButton = false,
  onReset = () => true,
}: MobilePopupMenuProps) {
  return (
    <Drawer
      title={title}
      placement="bottom"
      onClose={onClose}
      visible={visible}
      key="bottom"
      className="popupMenu hidden-md-up"
      height="auto"
      closable={false}
      extra={
        <div className="drawerExtraWrapper">
          {hasResetButton && (
            <button
              type="button"
              title="Zurücksetzen"
              className="buttonText popupMenuTextButton"
              onClick={() => onReset()}
            >
              <span>Zurücksetzen</span>
            </button>
          )}

          <button
            type="button"
            title="Schließen"
            className="button buttonPlain buttonWithIcon buttonWithIcon--withoutText"
            onClick={() => onClose()}
          >
            <Close className="closeIcon closeIcon--large" />
          </button>
        </div>
      }
    >
      <ul className="menuItemsList">{children}</ul>
    </Drawer>
  );
};

export default MobilePopupMenu;
