import { Entry } from "contentful";
import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes, routePathNames } from "../../appConfig";

/**
 * get a possible Entry for sorting news categories
 * @return {Promise}
 */
const getNewsCategoryOrder = async () =>
  getContentfulContentType({
    ...contentfulContentTypes.contentOrder,
    inclusion: {
      "fields.slug[in]": routePathNames.news.replace("/", ""),
    },
  }).then((response: any) => {
    if (!response) {
      Promise.reject(response);
    }

    /*
     * due to the uniqueness of the slug, there must be only one entry in the array
     * @return an array of linked entry ids (which are the IDs of the actual categories)
     */
    return response?.[0]?.fields?.entryOrder.map(
      (categoryOrderData: Entry<any>) => {
        return categoryOrderData?.sys?.id;
      }
    );
  });

export default getNewsCategoryOrder;
