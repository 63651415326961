import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import Price from "../price/Price";
import getNearestVolumePrice from "./getNearestVolumePrice";
import { RootState } from "../../types/rootState";

interface Props {
  volumePrices: any[];
  basePriceQuantity: string;
  availability: boolean | null;
  totalOrderQuantity: number;
  className?: string;
}

/**
 * create an array of volume price buttons
 * @param volumePrices {Array}
 * @param basePriceQuantity {number|string}
 * @param availability {boolean | null}
 * @param totalOrderQuantity {Number}
 * @param addToCart {Function}
 * @constructor
 * @returns {JSX.Element}
 */
function ProductVolumePrices({
  volumePrices,
  basePriceQuantity = "1",
  availability,
  totalOrderQuantity,
  className,
}: Props) {
  const hidePrices = useSelector(
    (state: RootState) => state?.appSettings?.hidePrices || false
  );

  if (!volumePrices || !availability) {
    return null;
  }

  const hideVolumePrices =
    !volumePrices?.length ||
    (volumePrices.length === 1 && volumePrices[0]?.quantity === 1);

  if (hideVolumePrices) {
    return null;
  }

  // get the nearest volume price quantity
  const nearestVolumePriceQuantity: any = getNearestVolumePrice(
    volumePrices,
    totalOrderQuantity,
    true
  );

  // create the elements
  const volumePricesVisuals = volumePrices.map((currentPrice: any) => {
    const { quantity, price } = currentPrice;

    /*
     * check if the total ordered quantity is at least the nearest volume price quantity
     * and the current quantity of the volume price matches the nearest found
     */
    const volumePriceActive =
      totalOrderQuantity >= nearestVolumePriceQuantity &&
      nearestVolumePriceQuantity === quantity &&
      quantity > 1;

    return (
      <span
        className={clsx("productVolumePrice", hidePrices && "blurred", {
          volumePriceActive,
        })}
        key={`pBox-${quantity}`}
      >
        [{quantity}]&nbsp;
        <Price price={price / parseFloat(basePriceQuantity)} />
      </span>
    );
  });

  return (
    <div
      className={clsx(
        "productVolumePrices",
        hidePrices && "blurred",
        className
      )}
    >
      {volumePricesVisuals}
    </div>
  );
}

export default ProductVolumePrices;
