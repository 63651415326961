import { CancelTokenSource } from "axios";
import getDeliveryDateQuery from "../getDeliveryDateQuery";
import axiosWithAuth from "../axiosWithAuth";
import { catalogSearchSuggestEndpoint } from "../endpoints";
import requestCatchHandler from "../requestCatchHandler";

/**
 *
 * @param query {string}
 * @param deliveryDate {string}
 * @param cancelTokenSource {CancelTokenSource}
 */
const getProductSuggestions = async (
  query: string,
  deliveryDate: string,
  cancelTokenSource: CancelTokenSource
) => {
  const deliveryDateAppendix = getDeliveryDateQuery(deliveryDate);

  return axiosWithAuth()
    .get(
      `${catalogSearchSuggestEndpoint}${encodeURIComponent(
        query
      )}${deliveryDateAppendix}`,
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (!response?.data?.data) {
        return Promise.reject(response);
      }

      const { data } = response.data;

      return {
        products: data?.[0]?.attributes?.concreteProducts || [],
        searchIntend: data?.[0]?.attributes?.searchIntend || {},
        brand: data?.[0]?.attributes?.brand || null,
        productGroup: data?.[0]?.attributes?.productGroup || null,
        completion:
          (data?.[0]?.attributes?.completion || null).filter(
            (item: any) => item !== query
          ) || null,
      };
    })
    .catch((error) => {
      requestCatchHandler(error);
    });
};

export default getProductSuggestions;
