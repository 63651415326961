import React, { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Splide, SplideSlide } from "@splidejs/react-splide";
import axios, { CancelTokenSource } from "axios";
import { message } from "antd";
import getAlternativeProducts from "../../api/products/getAlternativeProducts";
import requestCatchHandler from "../../api/requestCatchHandler";
import ProductSliderImage from "./ProductSliderImage";
import getCssVariable from "../../utils/getCssVariable";
import getDisplayProducts from "../../api/products/getDisplayProducts";
import { messageData } from "../../appConfig";
import useGetDeliveryDate from "../../hooks/useGetDeliveryDate";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import { ProductData } from "../../types/productData";
import isProductADisplay from "../../utils/isProductADisplay";

// TypeScript Props interface
type TypeOfProducts = "alternative" | "bestseller" | "display";

interface Props {
  attributes: ProductData["attributes"];
  referenceSku: ProductData["sku"];
}

const ProductSlider: React.FC<Props> = ({
  referenceSku,
  attributes,
}: Props) => {
  const [deliveryDate] = useGetDeliveryDate();
  const [sliderProducts, setSliderProducts] = useState([]);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isDisplayProduct = isProductADisplay(attributes);
  const typeOfProducts: TypeOfProducts = isDisplayProduct
    ? "display"
    : "alternative";

  /**
   * fetch products and set title depending of the view
   * of this instance
   * @param cancelTokenSource {CancelTokenSource}
   */
  const retrieveProducts = useCallback(
    (cancelTokenSource: CancelTokenSource) => {
      let productRequest: Promise<any[]>;
      let productTitle = "";

      switch (typeOfProducts) {
        case "alternative":
          productTitle = "Alternative Produkte";
          productRequest = getAlternativeProducts(
            referenceSku,
            deliveryDate,
            cancelTokenSource
          );
          break;

        case "display":
          productTitle = "Display Einzelbestandteile";
          productRequest = getDisplayProducts(
            referenceSku,
            deliveryDate,
            cancelTokenSource
          );

          break;

        /*  case "bestseller":
        productRequest = getBestsellerProducts(...);
        break;
        */
        default:
          productRequest = Promise.resolve(null);
          break;
      }

      return productRequest.then((productData) => ({
        productData,
        productTitle,
      }));
    },
    [referenceSku, typeOfProducts, deliveryDate]
  );

  useEffect(() => {
    // create token for cleanup cancel
    const requestCancelTokenSource = getCancelTokenSource();

    setIsLoading(true);

    Promise.all([retrieveProducts(requestCancelTokenSource)])
      .then(([{ productData, productTitle }]) => {
        setTitle(productTitle);
        setSliderProducts(productData);

        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);

          message.error(messageData.error.unexpected);
        }

        requestCatchHandler(error);
      });

    return () => {
      requestCancelTokenSource.cancel();
    };
  }, [deliveryDate, retrieveProducts, setIsLoading]);

  return !isLoading && sliderProducts.length > 0 ? (
    <div className="productSliderWrapper">
      <header className="productSliderHeading">
        <h2 className="productSliderTitle">{title}</h2>
        <span className="productSliderQuantity">
          {sliderProducts.length} Artikel
        </span>
      </header>

      <Splide
        options={{
          type: "slide",
          rewind: false,
          pagination: false,
          trimSpace: true,
          perPage: 6,
          // determine if arrows should be visible or not
          arrows: sliderProducts.length > 6,
          gap: 30,
          drag: false,
          keyboard: "focused",
          focus: 0,
          breakpoints: {
            [getCssVariable("screen-xs", true)]: {
              perPage: 2,
              perMove: 1,
              // set focus centered only if enough items are visible
              focus: sliderProducts.length > 2 ? "center" : 0,
              drag: true,
            },
            [getCssVariable("screen-sm", true)]: {
              perPage: 3,
              perMove: 1,
              // set focus centered only if enough items are visible
              focus: sliderProducts.length > 3 ? "center" : 0,
              drag: true,
            },
            [getCssVariable("screen-lg", true)]: {
              perPage: 4,
              perMove: 1,
              // set focus centered only if enough items are visible
              focus: sliderProducts.length > 4 ? "center" : 0,
              drag: true,
            },
          },
        }}
      >
        {sliderProducts.map((productData: any) => (
          <SplideSlide key={productData.id}>
            <ProductSliderImage productData={productData} />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  ) : null;
};

export default ProductSlider;
