import React from "react";

interface ScannerMessageContent {
  name: string;
  sku: string;
  children?: React.ReactNode;
}

function ScannerMessage({ name, sku, children }: ScannerMessageContent) {
  return (
    <span className="scannerMessageContent">
      <span className="scannerMessageContentProduct">
        <span className="scannerMessageContentName">{name}</span>
        <span className="scannerMessageContentSku">{sku}</span>
      </span>

      {children}
    </span>
  );
}

export default ScannerMessage;
