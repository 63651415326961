import { Button, Dropdown, Radio, RadioChangeEvent } from "antd";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ReactComponent as Arrow } from "../../../static/svg/arrow.svg";
import { ReactComponent as SortAsc } from "../../../static/svg/sort_asc.svg";
import { ReactComponent as SortDesc } from "../../../static/svg/sort_desc.svg";

import sortCriteria from "./sortCriteria";

import useUpdateUrlFragments, {
  SortByValue,
} from "../../../hooks/useUpdateUrlFragments";

interface SortDropdownProps {
  type?: "default" | "mobile";
  className?: string;
}
const SortDropdown: React.FC<SortDropdownProps> = (props) => {
  const { type = "default", className = "" } = props;
  const uniqueFilterId = Math.floor(Math.random() * 10000);

  const setUpdateUrlFragments = useUpdateUrlFragments();
  const { search } = useLocation();

  const { sortBy: sortByParam, sortDirection: sortDirectionParam } =
    queryString.parse(search);

  const [open, setOpen] = useState<boolean>(false);

  const initSortBy = (sortByParam as SortByValue) || "default";
  const [sortBy, setSortBy] = useState<SortByValue>(initSortBy);

  const initSortAscending =
    sortBy === "default" || sortDirectionParam === "asc";
  const [sortAscending, setSortAscending] =
    useState<boolean>(initSortAscending);

  const getPopupContainer = () =>
    document.getElementById(`sort-dropdown--${uniqueFilterId}`);

  const onChangeSortDirection = () => {
    setSortAscending(!sortAscending);
    setUpdateUrlFragments({
      context: "sort",
      parameters: {
        sortBy,
        sortDirection: !sortAscending ? "asc" : "desc",
      },
    });
  };

  const onChangeSortSettings = (changeEvent: RadioChangeEvent) => {
    if (sortBy === changeEvent.target.value) {
      onChangeSortDirection();
      return;
    }

    setOpen(false);
    setSortBy(changeEvent.target.value);
    setSortAscending(true);

    setUpdateUrlFragments({
      context: "sort",
      parameters: {
        sortBy: changeEvent.target.value,
        sortDirection: "asc",
      },
    });
  };

  useEffect(() => {
    setSortBy(
      sortByParam !== undefined ? (sortByParam as SortByValue) : "default"
    );
  }, [sortByParam]);

  useEffect(() => {
    setSortAscending(
      sortDirectionParam !== undefined ? sortDirectionParam === "asc" : true
    );
  }, [sortDirectionParam]);

  function SortMenu() {
    return (
      <Radio.Group
        value={sortBy}
        className={clsx("sort-dropdown__radio-group")}
        onChange={onChangeSortSettings}
      >
        {sortCriteria.map((singleSortCriteria, index) => (
          <Radio
            key={index}
            className={clsx("sort-dropdown__radio-element")}
            value={singleSortCriteria.key}
            name={singleSortCriteria.title}
          >
            <div className={clsx("sort-dropdown__radio-label")}>
              <span className={clsx("sort-dropdown__radio-label-text")}>
                {singleSortCriteria.title}
              </span>

              {sortBy === singleSortCriteria.key && sortBy !== "default" && (
                <button
                  type="button"
                  className={clsx(
                    "sort-dropdown__radio-label-sort-icon",
                    "buttonAsset",
                    "buttonText",
                    "buttonTextDecoration--never"
                  )}
                  onClick={onChangeSortDirection}
                >
                  {sortAscending ? <SortAsc /> : <SortDesc />}
                </button>
              )}
            </div>
          </Radio>
        ))}
      </Radio.Group>
    );
  }

  return (
    <div
      className={clsx("sort-dropdown", className)}
      id={`sort-dropdown--${uniqueFilterId}`}
    >
      <Dropdown
        overlay={<SortMenu />}
        getPopupContainer={getPopupContainer}
        className="sort-dropdown__container"
        trigger={["hover"]}
        visible={open}
        onVisibleChange={setOpen}
      >
        <Button
          type="default"
          shape={type === "default" ? "default" : "round"}
          className={clsx(
            type === "default" &&
              "buttonAsset buttonText buttonTextDecoration--never sort-dropdown__button"
          )}
        >
          <span
            className={clsx(
              "sort-dropdown__label",
              sortBy !== "default" ? "sort-dropdown__label--active" : ""
            )}
          >
            Sortieren nach
          </span>
          <Arrow
            className={clsx(
              "icon",
              "arrowDown",
              "sort-dropdown__arrow",
              `sort-dropdown__arrow--${open ? "down" : "up"}`
            )}
          />
        </Button>
      </Dropdown>
    </div>
  );
};

export default SortDropdown;
