import { ProductData } from "../types/productData";

interface GetCartItemQuantity {
  cartItems: ProductData[];
  lookupSku: string;
}

/**
 * get quantity of a lookup sku in an array of productData
 * @param cartItems {ProductData[]}
 * @param lookupSku {string}
 * @return {number}
 */
const getCartItemQuantity = ({ cartItems, lookupSku }: GetCartItemQuantity) =>
  cartItems?.find((cartItem: Record<string, any>) => cartItem.sku === lookupSku)
    ?.quantity || 0;

export default getCartItemQuantity;
