import React from "react";
import clsx from "clsx";

interface Props {
  emailAddress?: string;
  subject?: string;
  body?: string;
  url?: string;
  children: React.ReactNode;
  className: string;
}

/**
 * mailToLink component, creating an anchor with all mailto properties
 * @param emailAddress {any}
 * @param subject {string}
 * @param body {string}
 * @param url {string}
 * @param children {React.ReactNode}
 * @param className {string}
 * @constructor
 */
function MailToLink({
  emailAddress = "",
  subject = "",
  body = "",
  url = "",
  children,
  className,
}: Props) {
  /**
   * if browser supports native share API
   * use this approach
   */
  if (navigator?.share) {
    return (
      <button
        type="button"
        className={clsx("button buttonText", className)}
        onClick={() => {
          navigator.share({
            url,
            text: body,
            title: subject,
          });
        }}
      >
        {children}
      </button>
    );
  }

  // otherwise use old but gold approach
  let params = "";

  if (subject || body) {
    params = "?";
  }

  if (subject) {
    params += `subject=${encodeURIComponent(subject)}`;
  }

  if (body) {
    params += `${subject && "&"}body=${encodeURIComponent(body)}${url}`;
  }

  return (
    <a className={className} href={`mailto:${emailAddress}${params}`}>
      {children}
    </a>
  );
}

export default MailToLink;
