import { CancelTokenSource } from "axios";
import storeDispatch from "./storeDispatch";
import getPriceTagList from "../../api/priceTagList/getPriceTagList";

/**
 * get user's price tag list and update redux
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise}
 */
const setPriceTagList = async (cancelTokenSource: CancelTokenSource) =>
  getPriceTagList(cancelTokenSource).then((priceTagListResponse) => {
    storeDispatch("priceTagList/set-list", priceTagListResponse);

    return priceTagListResponse;
  });

export default setPriceTagList;
