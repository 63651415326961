import { CancelTokenSource } from "axios";
import { useEffect } from "react";

/**
 * wrapper to cancel axios calls with passed token source
 * cancels the request if the component unmounts and or the token changes
 * use a React Reference for the CancelTokenSource to prevent cancel on every render ;)
 * @param cancelTokenSource {CancelTokenSource}
 */
const useCancelAxiosOnUnmount = (cancelTokenSource: CancelTokenSource) => {
  /**
   * API cancelation on unmount
   */
  useEffect(() => {
    const currentTokenSource = cancelTokenSource;

    return () => {
      currentTokenSource.cancel();
    };
  }, [cancelTokenSource]);
};

export default useCancelAxiosOnUnmount;
