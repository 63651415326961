import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { stocktakingFileEndpoint } from "../endpoints";

export type StocktakingFileFormat = "excel" | "biooffice";

interface GetStocktakingFileProps {
  stocktakingId: number;
  referenceDate: string;
  format: StocktakingFileFormat;
  cancelTokenSource: CancelTokenSource;
}

const getStocktakingFile = async (props: GetStocktakingFileProps) => {
  const {
    stocktakingId,
    referenceDate,
    format = "excel",
    cancelTokenSource,
  } = props;

  const queryString = `?isBioOfficeFormat=${format === "biooffice" ? 1 : 0}`;

  return axiosWithAuth()
    .get(`${stocktakingFileEndpoint}/${stocktakingId}${queryString}`, {
      responseType: "blob",
      cancelToken: cancelTokenSource.token,
      validateStatus: (status: number) => {
        return status === 200;
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        format === "biooffice"
          ? "UmsTable.txt"
          : `Inventur_${referenceDate}_${stocktakingId}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export default getStocktakingFile;
