import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import { ConfigProvider, Table } from "antd";
import type { ColumnsType } from "antd/es/table";

import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import getProcessedProductAttributeValue from "./getProcessedProductAttributeValue";
import { sortArrayByOrderOfArrayWithNotFoundLast } from "../../utils/sorting";
import { ProductData } from "../../types/productData";
import { RootState } from "../../types/rootState";

interface ProductNutritionalValuesListProps {
  attributes: ProductData["attributes"];
}

interface DataType {
  key: React.Key;
  property: string;
  value: string;
}

const nutritionalValuesOrder = [
  "nwae_energie_brennwert_kj",
  "nwae_energie_brennwert_kcal",
  "nwae_fett",
  "nwae_gesaettigte_fettsaeuren",
  "nwae_kohlenhydrate",
  "nwae_zucker",
  "nwae_ballaststoffe",
  "nwae_eiweiss",
  "nwae_salz",
];

const getNutritionalValueDisplayValue = (
  key: string,
  value: string,
  referenceSizeUnit: string
): string => {
  switch (key) {
    case "nwae_energie_brennwert_kj":
      return `${value} kJ`;

    case "nwae_energie_brennwert_kcal":
      return `${value} kcal`;

    case "nwae_ballaststoffe":
      return `${value} ${referenceSizeUnit}`;

    case "nwae_salz":
      return `${value} ${referenceSizeUnit}`;

    default:
      return value;
  }
};

const ProductNutritionalValuesList: React.FC<
  ProductNutritionalValuesListProps
> = (props: ProductNutritionalValuesListProps) => {
  const { attributes = {} } = props;

  const { attributeNames } = useSelector(
    (state: RootState) => state.productsMetaData
  );

  const {
    nwae_bezugsgroesse_id: referenceSize = "",
    nwae_portion_bezugsgroesse_id: referenceSizeUnit = "",
  } = attributes;

  const cartDate = useSelector(getCartDeliveryDateSlice);

  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "property",
      width: "50%",
    },
    {
      title: referenceSize ? `je ${referenceSize}` : "",
      dataIndex: "value",
      width: "50%",
    },
  ];

  const rows = useMemo(() => {
    return Object.keys(attributes)
      .filter((key: string) => nutritionalValuesOrder.includes(key))
      .sort((a, b) =>
        sortArrayByOrderOfArrayWithNotFoundLast(a, b, nutritionalValuesOrder)
      )
      .map((key: string, index: number) => {
        const name = attributeNames[key].startsWith("product.attribute.")
          ? ""
          : attributeNames[key];

        const value = getProcessedProductAttributeValue({
          attribute: attributes[key],
          deliveryDate: cartDate,
        });

        return {
          key: index,
          property: name,
          value: getNutritionalValueDisplayValue(key, value, referenceSizeUnit),
        } as DataType;
      });
  }, [attributeNames, attributes, cartDate, referenceSizeUnit]);

  return (
    <div className="nutritionalValuesList">
      <ConfigProvider
        renderEmpty={() => <span>Keine Nährwerte vorhanden</span>}
      >
        <Table columns={columns} dataSource={rows} pagination={false} />
      </ConfigProvider>
    </div>
  );
};

export default ProductNutritionalValuesList;
