import React from "react";

import clsx from "clsx";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import FilterSlot from "../FiltersDesktop/FilterSlot";
import { resetFilterStates } from "../../products/ProductsFilter/filterFunctions";
import { Filter } from "../../../types/filters";

interface FiltersSidebarProps {
  filters: Filter[];
  slots: any[];
  className?: string;
}

const FiltersSidebar: React.FC<FiltersSidebarProps> = (props) => {
  const { filters, slots = [], className } = props;

  const navigate = useNavigate();

  if (Array.isArray(filters) && !filters.length) {
    return (
      <div className={clsx("filters-sidebar--reset-filters", className)}>
        <p>Für diese Anfrage sind keine weiteren Filter verfügbar.</p>
        <Button
          type="primary"
          shape="round"
          onClick={() => resetFilterStates(navigate)}
        >
          Filter zurücksetzen
        </Button>
      </div>
    );
  }

  if (!filters || !slots) {
    return <></>;
  }

  return (
    <div className={clsx("filters-sidebar", className)}>
      {slots.map((slot, index) => (
        <FilterSlot
          key={index}
          slot={slot}
          filters={filters.filter(
            (filter: Filter) => filter.config.slot === slot.slot
          )}
          display="sidebar"
          columnsToShow={-1}
        />
      ))}
    </div>
  );
};

export default FiltersSidebar;
