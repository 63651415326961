import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { customerEndpoint } from "../endpoints";
import aggregateCompanyUserData from "../../utils/aggregateCompanyUserData";

/**
 * retrieve all needed user data
 * @param cancelTokenSource {CancelTokenSource}
 * @param id {string}
 * @return {Promise<AxiosResponse<any>>}
 */
const getSingleCompanyUserData = (
  id: string,
  cancelTokenSource: CancelTokenSource
) => {
  // retrieve companyUser
  return axiosWithAuth()
    .get(`${customerEndpoint}/${id}`, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response: any) => {
      if (!response?.data?.data || response.status > 201) {
        return [{}];
      }
      const { data } = response.data;

      if (data) {
        // create a new array with needed userdate
        return aggregateCompanyUserData([data], {})[0];
      }

      return [{}];
    });
};

export default getSingleCompanyUserData;
