import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { allProductsOnPriceTagListEndpoint } from "../endpoints";
import getCatalogSearchAttributesData from "../products/getCatalogSearchAttributesData";
import { PriceTagListsEntry } from "../../types/priceTagList";

/**
 * retrieves the user based price tag list (currently only one) and returns it
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise<Array|undefined>|Promise<AxiosResponse<any>>}
 */
const getPriceTagList = async (cancelTokenSource: CancelTokenSource) => {
  return axiosWithAuth()
    .get(allProductsOnPriceTagListEndpoint, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response?.data?.data || response.status > 200) {
        Promise.reject(response);
      }

      const attributes = getCatalogSearchAttributesData(response?.data?.data);

      return {
        priceTags: attributes?.priceTagLists?.find(
          (priceTagList: PriceTagListsEntry) => priceTagList.isDefault
        )?.priceTags,
      };
    });
};

export default getPriceTagList;
