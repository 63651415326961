import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import axios from "axios";

import { message } from "antd";
import { moodleTokenEndpoint } from "../api/endpoints";
import { messageData } from "../appConfig";

const useWorkshops = (): boolean => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  // @TODO: Refactor (just moved it here)
  const getMoodleData = useCallback(async () => {
    // get podcast token
    axios
      .post(moodleTokenEndpoint, null, {
        timeout: 3000,
      })
      // get podcast data
      .then((tokenRes) => {
        if (!tokenRes.data.errorcode) {
          localStorage.setItem("moodleToken", tokenRes.data.token);

          const moodleToken = tokenRes.data.token;
          // Moodle categoriees
          axios
            .post(
              `https://e.weiling-akademie.de/webservice/rest/server.php?wstoken=${moodleToken}&wsfunction=core_course_get_categories&moodlewsrestformat=json`,
              null,
              {
                timeout: 3000,
              }
            )
            .then((res) => {
              dispatch({
                type: "SET_COURSE_CATEGORIES",
                payload: res.data,
              });
            })
            .catch(() => {
              message.warning(messageData.warning.moodle.noCategories);
            });

          // Moodle courses
          axios
            .post(
              `https://e.weiling-akademie.de/webservice/rest/server.php?wstoken=${moodleToken}&wsfunction=core_course_get_courses&moodlewsrestformat=json`,
              null,
              {
                timeout: 3000,
              }
            )
            .then((res) => {
              dispatch({
                type: "SET_COURSES",
                payload: res.data,
              });
            })
            .catch(() => {
              message.warning(messageData.warning.moodle.noCourses);
            });
        } else {
          dispatch({
            type: "SET_COURSE_CATEGORIES",
            payload: tokenRes.data,
          });
        }
      })
      .catch(() => {
        message.warning(messageData.warning.moodle.auth);
      });
  }, [dispatch]);

  // Checks wether the users auth is valid and sets userData
  useEffect(() => {
    setIsLoading(true);
    getMoodleData().finally(() => {
      setIsLoading(false);
    });
    return () => {};
  }, [getMoodleData]);

  return isLoading;
};

export default useWorkshops;
