/**
 * get a CSS variable name's value
 * omit "--"
 * @param variableName {string}
 * @param omitUnit {boolean}
 * @returns {string}
 * @example getCssVariable('screen-xs')
 */
const getCssVariable = (variableName: string, omitUnit = false) => {
  const variableValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${variableName}`);

  if (omitUnit) {
    return variableValue.replace(/\D+/gim, "");
  }

  return variableValue;
};

export default getCssVariable;
