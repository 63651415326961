import React, { forwardRef } from "react";
import clsx from "clsx";
import { Badge } from "antd";
import { ReactComponent as Cart } from "../../static/svg/cart.svg";
import { ReactComponent as CartPreorder } from "../../static/svg/cart_unavailable.svg";

interface Props {
  type?: "default" | "filled" | "preorder";
  withBadge: boolean;
  badgeOverflowCount?: number;
  isDisabled?: boolean;
  onClick?: (() => void) | ((e: any) => void);
  badgeCount?: number | string;
  className?: string;
  title?: string;
}

type Ref = React.LegacyRef<HTMLButtonElement>;

/**
 * button component for button with cart icon and badge
 * @param type {string}
 * @param className {string}
 * @param isDisabled {boolean}
 * @param onClick {Function}
 * @param withBadge {boolean}
 * @param badgeCount {number|string}
 * @param badgeOverflowCount {number}
 * @param ref {React.LegacyRef}
 * @param title {string}
 * @constructor
 */
function ButtonAddToCart(
  {
    type = "default",
    isDisabled = false,
    onClick = () => true,
    withBadge,
    badgeOverflowCount = 999,
    badgeCount,
    className,
    title,
  }: Props,
  ref: Ref
) {
  const CartIcon =
    type === "preorder" ? (
      <CartPreorder className="icon iconCart iconCart--preorder" />
    ) : (
      <Cart className="icon iconCart" />
    );

  return (
    <button
      type="button"
      title="In den Warenkorb"
      className={clsx(
        "buttonAddToCart buttonActionForInput",
        type === "filled" && "buttonActionForInput--filled",
        type === "preorder" && "buttonActionForInput--preorder",
        className
      )}
      disabled={isDisabled}
      onClick={onClick}
      ref={ref}
    >
      {withBadge ? (
        <Badge
          overflowCount={badgeOverflowCount}
          count={badgeCount}
          // be sure that the ant class is the last one due to breaking the component behavior otherwise
          scrollNumberPrefixCls={clsx(
            type === "preorder" ? "badgeCountPreorder" : "bgColor-secondary",
            "ant-scroll-number"
          )}
          className="badge-cart"
          title={title || String(badgeCount)}
        >
          {CartIcon}
        </Badge>
      ) : (
        CartIcon
      )}
    </button>
  );
}

export default forwardRef(ButtonAddToCart);
