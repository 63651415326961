import React from "react";
import ProductTileInner from "./ProductTileInner";
import ProductTileWrapper from "./ProductTileWrapper";
import AddToCartDispatch from "./ProductTileAddToCartContext";
import { ProductTileProps } from "../../../types/productTileProps";

/**
 * Product Tile without the alternatives Modal
 * @param {string} deliveryDate
 * @param {ProductData} productData
 * @param {Function} addToCartCallback
 * @param {number} defaultQuantity
 * @constructor
 */
const ProductTileWithoutModal: React.FC<ProductTileProps> =
  function ProductTileWithModal({
    deliveryDate,
    productData,
    addToCartCallback = () => true,
    defaultQuantity,
  }: ProductTileProps) {
    return (
      <AddToCartDispatch.Provider value={addToCartCallback}>
        <ProductTileWrapper
          deliveryDate={deliveryDate}
          productData={productData}
        >
          <ProductTileInner
            deliveryDate={deliveryDate}
            productData={productData}
            defaultQuantity={defaultQuantity}
          />
        </ProductTileWrapper>
      </AddToCartDispatch.Provider>
    );
  };

export default ProductTileWithoutModal;
