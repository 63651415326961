import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

interface TrackingHelmetProps {
  title?: string;
  suffix?: string;
}

const TrackingHelmet: React.FC = (props: TrackingHelmetProps) => {
  const { title = "", suffix = "" } = props;
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ documentTitle: title });
  }, [title, trackPageView]);

  return (
    <Helmet>
      <title>
        {title} {suffix}
      </title>
    </Helmet>
  );
};

export default TrackingHelmet;
