import React, { useState } from "react";

import clsx from "clsx";
import { Button, Collapse, message, Tooltip } from "antd";

import getCancelTokenSource from "../../api/getCancelTokenSource";
import getDnrDataSheet from "../../api/products/getDnrDataSheet";
import { ReactComponent as Arrow } from "../../static/svg/arrow.svg";
import { ReactComponent as DnrLogo } from "../../static/svg/dnr-logo-colored.svg";
import { messageData } from "../../appConfig";

const { Panel } = Collapse;

interface ProductDetailDownloadsProps {
  sku: string;
  className?: string;
}

const ProductDetailDownloads: React.FC<ProductDetailDownloadsProps> = (
  props: ProductDetailDownloadsProps
) => {
  const { sku, className } = props;

  const [isDnrDataSheetDownloading, setIsDnrDataSheetDownloading] =
    useState<boolean>(false);

  const downloadDnrDataSheet = () => {
    const cancelTokenSource = getCancelTokenSource();
    setIsDnrDataSheetDownloading(true);
    getDnrDataSheet({ sku, cancelTokenSource })
      .then(() => {
        message.success(
          messageData.success.products.dnrDataSheetDownloadSuccess
        );
        setIsDnrDataSheetDownloading(false);
      })
      .catch(() => {
        message.error(messageData.error.products.dnrDataSheetNotAvailableError);
        setIsDnrDataSheetDownloading(false);
      });
  };

  return (
    <Collapse
      className={clsx("product-detail-downloads", className)}
      defaultActiveKey="product-detail-downloads"
      ghost
      expandIcon={({ isActive }) => (
        <Arrow
          style={{ transform: isActive ? "rotate(180deg)" : "rotate( 0deg)" }}
        />
      )}
      expandIconPosition="end"
    >
      <Panel key="product-detail-downloads" header={<h2>PDF Downloads</h2>}>
        <Tooltip
          title="DataNatuRe Datenblatt herunterladen"
          placement="right"
          destroyTooltipOnHide
        >
          <Button
            icon={<DnrLogo />}
            className="button buttonText"
            loading={isDnrDataSheetDownloading}
            onClick={downloadDnrDataSheet}
            type="text"
          >
            DataNatuRe Datenblatt
          </Button>
        </Tooltip>
      </Panel>
    </Collapse>
  );
};

export default ProductDetailDownloads;
