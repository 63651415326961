import React, { useEffect } from "react";

import clsx from "clsx";
import { NumericFormat } from "react-number-format";
import { Form, FormInstance, Input, Select } from "antd";
import { PercentageOutlined } from "@ant-design/icons";

interface StocktakingFormProps {
  form: FormInstance;
  onFinish: () => void;
  onFinishFailed: () => void;
  onFieldsChange: () => void;
  isVisible?: boolean;
  isUpdateMode?: boolean;
  hasProduct?: boolean;
  className?: string;
  formKey: string;
  quantitySuffix?: string;
}

const StocktakingForm: React.FC<StocktakingFormProps> = (
  props: StocktakingFormProps
) => {
  const {
    form,
    onFinish = () => {},
    onFinishFailed = () => {},
    onFieldsChange = () => {},
    isVisible = false,
    isUpdateMode = false,
    hasProduct = false,
    className,
    formKey = "",
    quantitySuffix = "",
  } = props;

  useEffect(() => {
    if (isVisible) {
      form.validateFields().catch(() => {});
    }
  }, [form, isVisible]);

  useEffect(() => {
    const quantityInput: HTMLInputElement = document.querySelector(
      ".stocktaking-item-post-modal .quantity-input"
    );
    if (isVisible && quantityInput) {
      setTimeout(() => {
        quantityInput.focus();
      }, 10);
    }
  }, [isVisible]);

  return (
    <Form
      key={formKey}
      form={form}
      labelCol={{ span: 2 }}
      wrapperCol={{ span: 10 }}
      style={{ maxWidth: 600 }}
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onFieldsChange={onFieldsChange}
      className={clsx(className)}
    >
      <Form.Item
        label="Name"
        name="label"
        rules={[
          { required: isUpdateMode, message: "Bitte Artikelnamen eingeben" },
          { type: "string" },
          { min: 1 },
          { max: 255 },
        ]}
        className="xs-mb-0"
      >
        <Input disabled={hasProduct} />
      </Form.Item>
      <Form.Item
        label="Menge"
        name="quantity"
        rules={[{ required: true, message: "Bitte Menge eingeben" }]}
        className="xs-mb-0"
        extra={!!quantitySuffix && <span>Einheit ist {quantitySuffix}</span>}
      >
        <NumericFormat
          className="ant-input quantity-input"
          allowNegative={false}
          decimalScale={2}
          thousandSeparator="."
          decimalSeparator=","
          fixedDecimalScale
          pattern="[0-9]*"
          style={{ width: "90px" }}
          inputMode="tel"
        />
      </Form.Item>
      <Form.Item
        label="Einzelpreis"
        name="unitPrice"
        rules={[
          {
            required: isUpdateMode,
            message: "Bitte Einzelpreis eingeben",
          },
        ]}
        className="xs-mb-0"
      >
        <NumericFormat
          className="ant-input"
          allowNegative={false}
          decimalScale={2}
          thousandSeparator="."
          decimalSeparator=","
          fixedDecimalScale
          suffix=" €"
          pattern="[0-9]*"
          style={{ width: "90px" }}
          disabled={hasProduct}
          inputMode="tel"
        />
      </Form.Item>
      <Form.Item
        label="MWSt."
        name="taxRate"
        rules={[
          {
            required: isUpdateMode,
            message: "Bitte Mehrwertsteuersatz eingeben",
            type: "enum",
            enum: [19, 7],
          },
        ]}
        className="xs-mb-0"
      >
        <Select
          placeholder="Bitte MWSt. auswählen..."
          allowClear={false}
          disabled={hasProduct}
          options={[{ value: 19 }, { value: 7 }]}
          suffixIcon={<PercentageOutlined />}
        />
      </Form.Item>
      <Form.Item
        label="Art.-Nr."
        name="sku"
        pattern="[0-9]*"
        rules={[
          { required: isUpdateMode, message: "Bitte Artikelnummer eingeben" },
          { type: "string" },
          { min: 1 },
          { max: 25 },
        ]}
        className="xs-mb-0"
      >
        <Input disabled={hasProduct} inputMode="numeric" />
      </Form.Item>
      <Form.Item
        label="Hersteller"
        name="producer"
        rules={[
          { required: isUpdateMode, message: "Bitte Hersteller eingeben" },
          { type: "string" },
          { min: 1 },
          { max: 255 },
        ]}
        className="xs-mb-0"
      >
        <Input disabled={hasProduct} />
      </Form.Item>
    </Form>
  );
};

export default StocktakingForm;
