const initialState = {
  activeFolder: <Record<string, any>>[],
  tree: <Record<string, any>>[],
  expirationTimestamp: <number>null,
};

const infoCenterReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "infoCenter/set-folders":
      return {
        ...state,
        tree: payload,
      };
    case "infoCenter/set-active": {
      return {
        ...state,
        activeFolder: payload,
      };
    }
    case "infoCenter/set-expiration-timestamp": {
      return {
        ...state,
        expirationTimestamp: payload,
      };
    }
    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default infoCenterReducer;
