import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";

interface NewsByCategoryTypes {
  id: string;
  limit?: number;
  skip?: number;
}

/**
 * wrapper to fetch all news filtered by the parent category id
 */
const getAllNewsByCategory = async ({ id, limit, skip }: NewsByCategoryTypes) =>
  getContentfulContentType({
    ...contentfulContentTypes.newsEntry,
    limit,
    skip,
    order: "-fields.publish_date",
    inclusion: id
      ? {
          "fields.category.sys.id[in]": id,
        }
      : {},
    withTotal: true,
  }).then((response: any) => {
    if (!response) {
      Promise.reject(response);
    }

    return response;
  });

export default getAllNewsByCategory;
