import React from "react";
import { Layout, Row, Col, Result } from "antd";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { routePathNames } from "../../appConfig";

function NoNewsCategorySelected() {
  const { id } = useSelector((state: any) => state.userData);

  return (
    <Layout className="container-layout container-layout--inner">
      <Row>
        <Col span={12}>
          <Result
            title="Du kannst deine Präferenzen in deinem Konto pflegen."
            extra={
              <NavLink
                to={`${routePathNames.userProfileEdit}${id}`}
                className="button buttonPrimary"
              >
                Zu meinem Konto
              </NavLink>
            }
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default NoNewsCategorySelected;
