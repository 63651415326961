import { AppSettings } from "../../types/appSettings";

const initialState: AppSettings = {
  hidePrices: false,
};

const appSettingsReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "app-settings/set-hide-prices": {
      const { hidePrices } = payload;

      return { ...state, hidePrices };
    }

    default:
      return state;
  }
};

export default appSettingsReducer;
