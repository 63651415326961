import React from "react";
import { useSelector } from "react-redux";

import clsx from "clsx";
import { Button } from "antd";

import FilterSlot from "./FilterSlot";
import { RootState } from "../../../types/rootState";
import { ReactComponent as Close } from "../../../static/svg/close.svg";

interface FiltersDesktopProps {
  showFilters?: boolean;
  hideFilters?: () => void;
  columnsToShow?: number;
  display?: "default" | "weekplanner";
}

const FiltersDesktop: React.FC<FiltersDesktopProps> = (props) => {
  const {
    showFilters,
    hideFilters,
    columnsToShow = 3,
    display = "default",
  } = props;

  const { availableFilters, slots } = useSelector(
    (state: RootState) => state.productsMetaData.filters
  );

  if (!availableFilters || !slots || !showFilters) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        "filters-desktop",
        "hidden-sm-down",
        `filters-desktop--${display}`
      )}
    >
      <button
        className={clsx("filters-desktop__close-icon")}
        type="button"
        onClick={hideFilters}
      >
        <Close />
      </button>
      <div className="filters-desktop__slots">
        {slots.map((singleSlot, index) => (
          <FilterSlot
            key={index}
            slot={singleSlot}
            filters={availableFilters.filter(
              (filter) => filter.config.slot === singleSlot.slot
            )}
            columnsToShow={columnsToShow}
            display={display}
          />
        ))}
      </div>
      <Button
        className={clsx("filters-desktop__close")}
        type="primary"
        icon={<Close />}
        onClick={hideFilters}
      >
        Filter schließen
      </Button>
    </div>
  );
};

export default FiltersDesktop;
