import { CancelTokenSource } from "axios";
import setPriceTagList from "./setPriceTagList";
import setFavouriteLists from "./setFavouriteLists";

interface SetOrdersAndUserList {
  cancelTokenSource: CancelTokenSource;
}

/**
 * set daily orders, price tag and favourite lists
 * @param {CancelTokenSource} cancelTokenSource
 * @returns {Promise}
 */
const setUserLists = ({ cancelTokenSource }: SetOrdersAndUserList) => {
  return Promise.all([
    setPriceTagList(cancelTokenSource),
    setFavouriteLists(cancelTokenSource),
  ]);
};

export default setUserLists;
