import { createClient, ContentfulClientApi } from "contentful";

const {
  REACT_APP_CONTENTFUL_ENV,
  REACT_APP_CONTENTFUL_SPACE,
  REACT_APP_CONTENTFUL_DELIVERY,
  // REACT_APP_CONTENTFUL_PREVIEW,
} = process.env;

let client: ContentfulClientApi;

/**
 * create a client instance
 * @see {@link https://contentful.github.io/contentful.js/contentful/latest/contentful.html} for more possible parameters
 * @constructor
 */
const createContentfulClient = (): ContentfulClientApi => {
  client = createClient({
    space: REACT_APP_CONTENTFUL_SPACE,
    environment: REACT_APP_CONTENTFUL_ENV,
    accessToken: REACT_APP_CONTENTFUL_DELIVERY,
  });

  return client;
};

/**
 * get contentful client, if none present init first
 */
const getContentfulClient = (): ContentfulClientApi => {
  if (!client) {
    return createContentfulClient();
  }

  return client;
};

/**
 * reset client
 */
const resetClient = () => {
  window.sessionStorage.clear();
};

export default getContentfulClient;
export { resetClient };
