import axiosWithAuth from "../axiosWithAuth";
import { updateProductOnPriceTagListEndpoint } from "../endpoints";
import { PriceTagListApi } from "../../types/userLists";

/**
 * add a product sku to the priceTagList
 * @param cancelTokenSource {CancelTokenSource}
 * @param sku {string[]}
 * @return {Promise<AxiosResponse>}
 */
const postAddProductToPriceTagList = ({
  cancelTokenSource,
  sku,
}: PriceTagListApi) =>
  axiosWithAuth()
    .post(
      updateProductOnPriceTagListEndpoint,
      {
        data: {
          type: "tags",
          attributes: {
            skuList: [...sku],
          },
        },
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    )
    .then((response) => {
      if (!response || response.status > 201) {
        Promise.reject(response);
      }

      return response;
    });

export default postAddProductToPriceTagList;
