import React, { BaseSyntheticEvent, RefObject } from "react";

import clsx from "clsx";
import { Col, Row } from "antd";

import { PictureTile, QuickLink } from "../../../atoms";
import Picture from "../../../picture/Picture";
import SupportVideoImage from "../../../../static/images/support-video.jpg";
import SupportFAQImage from "../../../../static/images/support-faq-alternative.png";
import SupportClientImage from "../../../../static/images/support-client.jpg";
import SupportContactImage from "../../../../static/images/support-kontakt.png";

interface SupportIntroProps {
  tutorialsRef: RefObject<HTMLDivElement>;
  faqRef: RefObject<HTMLDivElement>;
  clientRef: RefObject<HTMLDivElement>;
  contactRef: RefObject<HTMLDivElement>;
  className?: string;
}

const SupportIntro: React.FC<SupportIntroProps> = (props) => {
  const { tutorialsRef, faqRef, clientRef, contactRef, className } = props;

  return (
    <div className={clsx("support-intro", className)}>
      <Row gutter={[20, 20]}>
        <Col xs={12} md={6}>
          <PictureTile
            className="video-tutorials"
            title="Video-Tutorials"
            picture={
              <Picture
                imageScreenXs={SupportVideoImage}
                imageScreenSm={SupportVideoImage}
              />
            }
            link={
              <QuickLink
                label="Ansehen"
                href="#"
                linkType="primary--inverted"
                onClick={(e: BaseSyntheticEvent) => {
                  e.preventDefault();
                  tutorialsRef?.current?.scrollIntoView();
                }}
              />
            }
          />
        </Col>
        <Col xs={12} md={6}>
          <PictureTile
            className="faq"
            title="Häufig gestellte Fragen (FAQ)"
            picture={
              <Picture
                imageScreenXs={SupportFAQImage}
                imageScreenSm={SupportFAQImage}
              />
            }
            link={
              <QuickLink
                label="Ansehen"
                href="#"
                linkType="primary--inverted"
                onClick={(e: BaseSyntheticEvent) => {
                  e.preventDefault();
                  faqRef?.current?.scrollIntoView();
                }}
              />
            }
          />
        </Col>
        <Col xs={12} md={6}>
          <PictureTile
            className="client"
            title="RustDesk-Support"
            picture={
              <Picture
                imageScreenXs={SupportClientImage}
                imageScreenSm={SupportClientImage}
              />
            }
            link={
              <QuickLink
                label="Starten"
                href="#"
                linkType="primary--inverted"
                onClick={(e: BaseSyntheticEvent) => {
                  e.preventDefault();
                  clientRef?.current?.scrollIntoView();
                }}
              />
            }
          />
        </Col>
        <Col xs={12} md={6}>
          <PictureTile
            className="contact"
            title="Kontakt"
            picture={
              <Picture
                imageScreenXs={SupportContactImage}
                imageScreenSm={SupportContactImage}
              />
            }
            link={
              <QuickLink
                label="E-Mail"
                href="#"
                linkType="primary--outlined"
                onClick={(e: BaseSyntheticEvent) => {
                  e.preventDefault();
                  contactRef?.current?.scrollIntoView();
                }}
              />
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default SupportIntro;
