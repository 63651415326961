import React, { useState } from "react";

import clsx from "clsx";
import { Collapse, Row, Col } from "antd";

import getCssVariable from "../../utils/getCssVariable";
import useMedia from "../../hooks/useMedia";
import { sortArrayByOrderOfArray } from "../../utils/sorting";
import HrDivider from "../divider/Divider";
import ProductAllergyList from "../product/ProductAllergyList";
import ProductInfoList from "../product/ProductInfoList";
import ProductIngredientsList from "../product/ProductIngredientsList";
import { ReactComponent as Arrow } from "../../static/svg/arrow.svg";
import { ProductData } from "../../types/productData";
import { productAttributes } from "../../api/productAttributes";

// visible attributes for list of productDetail page
const visibleDetailPageAttributes = [
  productAttributes.brand,
  productAttributes.brandText,
  productAttributes.qualityText,
  productAttributes.bioControlId,
  productAttributes.bioConform,
  productAttributes.originLong,
  productAttributes.originCountry,
  productAttributes.bioId,
  productAttributes.eanCode,
  productAttributes.netWeight,
  productAttributes.basePriceUnit,
  productAttributes.basePriceFactor,
  productAttributes.recommendedRetailPrice,
  productAttributes.drainWeight,
  productAttributes.vatGroup,
  productAttributes.bnnAroma,
  productAttributes.bnnId,
  productAttributes.conventionalAvailable,
  productAttributes.conventionalRange,
  productAttributes.remedy,
  productAttributes.animalType,
  productAttributes.teaOfferId,
  productAttributes.animalFood,
  productAttributes.empties,
  productAttributes.emptiesNr,
  productAttributes.varietyFeature,
  productAttributes.cultivationInfo,
  productAttributes.caliber,
  productAttributes.brix,
  productAttributes.enduserPackage,
  productAttributes.cosmeticsInci,
  productAttributes.cosmeticsIngredients,
  productAttributes.packageFruits,
  productAttributes.qualityNorm,
  productAttributes.fat,
  productAttributes.fatContent,
  productAttributes.fatStage,
  productAttributes.cheeseGroup,
  productAttributes.rennetCheese,
  productAttributes.ripeningTime,
  productAttributes.ripeningDays,
  productAttributes.ripening,
  productAttributes.crust,
  productAttributes.saltType,
  productAttributes.cheeseMilk,
  productAttributes.temperingFinal,
  productAttributes.temperingId,
  productAttributes.homogenized,
  productAttributes.rawMilk,
  productAttributes.animalTypeMilk,
  productAttributes.geographic,
  productAttributes.landId,
  productAttributes.producer,
  productAttributes.grapeVariety,
  productAttributes.traditional,
  productAttributes.alcohol,
  productAttributes.wineTaste,
  productAttributes.wineTasteShort,
  productAttributes.wineTasteLong,
  productAttributes.decantTime,
  productAttributes.champagne,
  productAttributes.residualSugar,
  productAttributes.acidity,
  productAttributes.temperatureMin,
  productAttributes.temperatureMax,
  productAttributes.sulphur,
  productAttributes.histamine,
  productAttributes.taste,
  productAttributes.bestBefore,
  productAttributes.sanType,
  productAttributes.weighingArticle,
  productAttributes.vpeWidth,
  productAttributes.vpeHeight,
  productAttributes.vpeDepth,
];

const { Panel } = Collapse;

// TypeScript Props interface
interface Props {
  attributes: ProductData["attributes"];
  ingredientsLegend?: string;
  herbalNotice?: string;
}

const ProductDetailInfo: React.FC<Props> = ({
  attributes,
  ingredientsLegend,
  herbalNotice,
}: Props) => {
  const productIsHerb = !!attributes?.[productAttributes.remedy];

  const allergyAttributes = Object.keys(attributes)
    .filter(
      (key) =>
        key.startsWith("allg_") &&
        (attributes[key].startsWith("Ja") || attributes[key].startsWith("Kann"))
    )
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: attributes[key] });
    }, {});
  const allergyAttributesLength = Object.keys(allergyAttributes).length;

  const ingredientsAttributesLength =
    attributes?.[productAttributes.ingredients]?.length;

  const nutritionalValueAttributes = Object.keys(attributes)
    .filter((key) => key.startsWith("nwae_"))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: attributes[key] });
    }, {});

  const visibleAttributesFilter = (currentAttribute: any) => {
    return visibleDetailPageAttributes.indexOf(currentAttribute) > -1;
  };

  const filterType = "detailPageList";

  /*
   * determine the applied sorting for the attribute list
   */
  const visibleAttributesSorting = (a: any, b: any) => {
    switch (filterType) {
      // sort the array based on the order of listed attributes
      case "detailPageList":
        return sortArrayByOrderOfArray(a, b, visibleDetailPageAttributes);

      default:
        return 0;
    }
  };

  const informationAttributes = Object.keys(attributes)
    .filter(visibleAttributesFilter)
    .sort(visibleAttributesSorting)
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: attributes[key] });
    }, {});

  const informationAttributesLength = Object.keys(informationAttributes).length;

  const [ingredientsCollapseOpen, setIngredientsCollapseOpen] = useState(true);

  const isLargeScreen = useMedia(`(min-width: ${getCssVariable("screen-lg")})`);

  const hasNutritionalAttributes =
    Object.keys(nutritionalValueAttributes).length > 0;

  return (
    <>
      <Collapse
        className="productDetailInfo"
        defaultActiveKey={["1"]}
        ghost
        expandIcon={({ isActive }) => (
          <Arrow
            style={{ transform: isActive ? "rotate(180deg)" : "rotate( 0deg)" }}
          />
        )}
        expandIconPosition="end"
      >
        <Panel header={<h2>Informationen</h2>} key="1">
          <ProductInfoList
            attributes={informationAttributes}
            filterType="detailPageList"
            attributesLength={informationAttributesLength}
          />
          {!!herbalNotice && productIsHerb && (
            <p className="text-default mt-s mb-0">
              <strong>°</strong> {herbalNotice}
            </p>
          )}
        </Panel>
      </Collapse>

      {!!ingredientsAttributesLength && (
        <Row>
          <Col xs={12}>
            <HrDivider spacingTop="xl" spacingBottom="xl" size={2} />
          </Col>
        </Row>
      )}

      {!!ingredientsAttributesLength && (
        <Collapse
          className="productDetailInfo"
          defaultActiveKey={["1"]}
          ghost
          expandIcon={({ isActive }) => (
            <Arrow
              style={{
                transform: isActive ? "rotate(180deg)" : "rotate( 0deg)",
              }}
            />
          )}
          expandIconPosition="end"
          onChange={() => setIngredientsCollapseOpen(!ingredientsCollapseOpen)}
        >
          <Panel
            header={
              <span
                className={clsx(
                  (isLargeScreen || ingredientsCollapseOpen) &&
                    "ingredients-collapse-header"
                )}
              >
                <h2>Zutatenverzeichnis</h2>
                {!!hasNutritionalAttributes && (
                  <h2
                    className={clsx(
                      (isLargeScreen || ingredientsCollapseOpen) &&
                        "largeScreen"
                    )}
                  >
                    Nährwerte
                  </h2>
                )}
              </span>
            }
            key="1"
          >
            <ProductIngredientsList
              attributes={attributes}
              ingredientsLegend={ingredientsLegend}
              nutritionalAttributes={nutritionalValueAttributes}
            />
          </Panel>
        </Collapse>
      )}

      {!!allergyAttributesLength && (
        <Row>
          <Col xs={12}>
            <HrDivider spacingTop="xl" spacingBottom="xl" size={2} />
          </Col>
        </Row>
      )}

      {!!allergyAttributesLength && (
        <Collapse
          className="productDetailInfo"
          defaultActiveKey={["1"]}
          ghost
          expandIcon={({ isActive }) => (
            <Arrow
              style={{
                transform: isActive ? "rotate(180deg)" : "rotate( 0deg)",
              }}
            />
          )}
          expandIconPosition="end"
        >
          <Panel header={<h2>Allergene</h2>} key="1">
            <ProductAllergyList attributes={allergyAttributes} />
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default ProductDetailInfo;
