import React, { useEffect, useMemo, useState } from "react";
import { Col, Layout, Row } from "antd";
import { Navigate, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import handleError from "../../utils/handleError";
import getContentfulContentType from "../../api/contentful/getContentfulContentType";
import {
  contentfulContentTypes,
  pageTitles,
  pageTitleSuffix,
  routePathNames,
  routeProductCategoryKeys,
} from "../../appConfig";
import HrDivider from "../divider/Divider";
import LinkToCart from "../../Router/LinkToCart";
import renderContentfulDocument from "../../utils/renderContentfulDocument";
import { OpenCartsWidget } from "../molecules";
import useGetAllCartsCheapMetadata from "../../hooks/useGetAllCartsCheapMetadata";
import ShopCategoryNavigation from "../navigation/ShopCategoryNavigation";
import { Checkmark } from "../../static/svg";
import TrackingHelmet from "../Matomo/TrackingHelmet";

interface OrderConfirmationState {
  remainingItemsInCart?: number;
  redirectToShop?: boolean;
}

const OrderConfirmation: React.FC<OrderConfirmationState> = () => {
  const location = useLocation();

  const [contentfulData, setContentfulData] = useState<any>({});
  const { deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );
  const { isLoading, hasError, openCarts } = useGetAllCartsCheapMetadata(5);

  const openCartsWithDetails: any[] = useMemo(
    () => openCarts.slice(0, 2),
    [openCarts]
  );
  const { remainingItemsInCart, redirectToShop = true } =
    (location.state as OrderConfirmationState) || {};

  useEffect(() => {
    getContentfulContentType(contentfulContentTypes.orderConfirmation)
      .then((orderConfirmationTexts: any) => {
        if (
          orderConfirmationTexts?.length &&
          orderConfirmationTexts[0]?.fields
        ) {
          setContentfulData(orderConfirmationTexts[0].fields);
        }

        return orderConfirmationTexts;
      })
      .catch(handleError);
  }, []);

  if (redirectToShop) {
    return <Navigate to={routePathNames.shop} />;
  }
  return (
    <>
      <TrackingHelmet
        title={pageTitles.orderConfirmation}
        suffix={pageTitleSuffix}
      />

      <ShopCategoryNavigation />
      <Layout className="container-layout container-layout--inner">
        <div className="orderConfirmation">
          <Checkmark />
          {contentfulData?.content &&
            renderContentfulDocument(contentfulData.content)}

          <Row gutter={[10, 10]}>
            <Col xs={12} sm={6}>
              <NavLink
                to={routePathNames.shop}
                className="button buttonPrimary buttons"
              >
                zurück zur Startseite
              </NavLink>
            </Col>
            <Col xs={12} sm={6}>
              <NavLink
                to={`/wochenplaner/kategorie/${routeProductCategoryKeys.fruitsAndVeggies}`}
                className="button buttonPrimary buttons"
              >
                zum OG-Wochenplaner
              </NavLink>
            </Col>
          </Row>
          <OpenCartsWidget
            title="Ihre offenen Warenkörbe"
            openCarts={openCartsWithDetails}
            isLoading={isLoading}
            hasError={hasError}
            showCartDetails
            singleColMode={false}
          />
          {remainingItemsInCart > 0 && (
            <>
              <HrDivider spacingTop="2xl" spacingBottom="2xl" size={2} />

              <h3>
                Du hast noch {remainingItemsInCart} Artikel in deinem Warenkorb.
              </h3>

              <p>
                <LinkToCart deliveryDate={deliveryDate}>
                  Artikel im Warenkorb ansehen
                </LinkToCart>
              </p>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

export default OrderConfirmation;
