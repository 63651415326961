import getContentfulContentType from "./getContentfulContentType";

interface GeneratorProps {
  contentType: string;
  limit: number;
}

/**
 * async generator function to retrieve all data due to API limits
 * this generator aggregates all items, so a "for await ... of" loop will execute only once
 * @param contentType {string}
 * @param limit {number}
 * @async
 * @generator
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*}
 */
export default async function* getAllContentTypeEntriesGenerator({
  contentType,
  limit,
}: GeneratorProps) {
  let fetchedEntries: any[] = [];
  let runtimeCount = 0;
  let currentIterationEntryLength = -1;

  /*
   * request data as long as all entries are fetched
   * while the entries length is at least equal to the limit we have to make sure
   * to reach out for possible more items
   */
  while (runtimeCount === 0 || currentIterationEntryLength >= limit) {
    /* eslint-disable-next-line no-await-in-loop */
    const newItems = await getContentfulContentType({
      contentType,
      limit,
      skip: limit * runtimeCount,
      select: "fields",
    });

    // add the new items to the list
    const parsedItems = [].concat(newItems);

    // updated counters
    currentIterationEntryLength = parsedItems.length;
    runtimeCount += 1;

    // aggregate previously fetched and new items
    fetchedEntries = [...fetchedEntries, ...parsedItems];

    yield fetchedEntries;
  }
}
