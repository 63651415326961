const initialState = {
  scroll: {
    x: 0,
    y: 0,
  },
  pathname: <null | string>null,
};

const pageReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "page/set-scroll": {
      return {
        ...state,
        scroll: {
          ...state.scroll,
          ...payload,
        },
      };
    }
    case "page/set-pathname": {
      return { ...state, pathname: payload };
    }

    default:
      return state;
  }
};

export default pageReducer;
