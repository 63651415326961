import React, { useMemo } from "react";

import clsx from "clsx";
import { Col, Layout, Row } from "antd";

import useGetAllCartsCheapMetadata from "../../../hooks/useGetAllCartsCheapMetadata";
import useGetImpulsaktionen from "../../../hooks/useGetImpulsaktionen";
import { routePathNames, routeProductCategoryKeys } from "../../../appConfig";
import { OpenCartsWidget, QuickLinksWidget } from "../../molecules";
import { ContentBlock, PictureTile, QuickLink } from "../../atoms";
import Picture from "../../picture/Picture";
import HrDivider from "../../divider/Divider";
import MonthlyPromotion from "../../../static/images/monthly-promotion-@x1.jpg";
import MonthlyPromotionSmall from "../../../static/images/monthly-promotion-small-@x1.jpg";
import TeaserEventImage from "../../../static/images/teaser-event-@x1.jpg";
import ImpulseTeaser from "../../../static/images/impulse-teaser-@x1.jpg";

interface DashboardWidgetBlockProps {
  className?: string;
}

const DashboardWidgetBlock: React.FC<DashboardWidgetBlockProps> = (props) => {
  const { className } = props;

  const { isLoading, hasError, openCarts } = useGetAllCartsCheapMetadata(5);
  const { entries: impulsaktionen = [] } = useGetImpulsaktionen(1, 0);

  const openCartsWithDetails: any[] = useMemo(
    () => openCarts.slice(0, 2),
    [openCarts]
  );
  const openCartsWithoutDetails: any[] = useMemo(
    () => openCarts.slice(2, 5),
    [openCarts]
  );

  return (
    <Layout
      className={clsx(
        "dashboard-widget-block container-layout container-layout--inner",
        className
      )}
    >
      <Row>
        <Col xs={12} lg={6} className="open-cart-widget-col">
          <OpenCartsWidget
            openCarts={openCartsWithDetails}
            isLoading={isLoading}
            hasError={hasError}
            showCartDetails
          />
        </Col>

        <Col xs={12} lg={6} className="quick-links-widget-col">
          <QuickLinksWidget />
        </Col>

        {!!openCartsWithoutDetails.length && (
          <Col xs={12} lg={6} className="other-open-cart-widget-col">
            <OpenCartsWidget
              openCarts={openCartsWithoutDetails}
              isLoading={isLoading}
              hasError={hasError}
              showHeader={false}
              showDividerOnTop
              className="other-open-cart-widget"
              footer={
                <PictureTile
                  title="Unsere aktuellen Aktionsprodukte"
                  overlayPosition="right"
                  picture={
                    <Picture
                      imageScreenXs={MonthlyPromotionSmall}
                      imageScreenSm={MonthlyPromotionSmall}
                    />
                  }
                  link={
                    <QuickLink
                      label="Entdecken"
                      href={`${routePathNames.products}${routePathNames.category}${routeProductCategoryKeys.promotion}`}
                      linkType="primary--outlined"
                    />
                  }
                />
              }
            />
          </Col>
        )}

        {!openCartsWithoutDetails.length && (
          <Col xs={12} lg={6} className="other-teaser-widget-col">
            <ContentBlock showHeader={false} className="other-teaser-widget">
              <HrDivider size={2} />
              <Row>
                <Col xs={12}>
                  {impulsaktionen?.length > 0 ? (
                    <PictureTile
                      title="Jetzt die Impulse-Daten herunterladen"
                      picture={
                        <Picture
                          imageScreenXs={ImpulseTeaser}
                          imageScreenSm={ImpulseTeaser}
                        />
                      }
                      link={
                        <QuickLink
                          label="Zu den Impulsen"
                          href={`${routePathNames.impulse}`}
                          linkType="primary--outlined"
                        />
                      }
                    />
                  ) : (
                    <PictureTile
                      title="Unsere aktuellen Aktionsprodukte"
                      picture={
                        <Picture
                          imageScreenXs={MonthlyPromotion}
                          imageScreenSm={MonthlyPromotion}
                        />
                      }
                      link={
                        <QuickLink
                          label="Entdecken"
                          href={`${routePathNames.products}${routePathNames.category}${routeProductCategoryKeys.promotion}`}
                          linkType="primary--outlined"
                        />
                      }
                    />
                  )}
                </Col>
              </Row>
            </ContentBlock>
          </Col>
        )}

        <Col xs={12} lg={6} className="teaser-widget-col">
          <ContentBlock showHeader={false} className="teaser-widget">
            <HrDivider size={2} />
            <Row>
              <Col xs={12}>
                <PictureTile
                  title="Wir laden Sie herzlich ein"
                  picture={
                    <Picture
                      imageScreenXs={TeaserEventImage}
                      imageScreenSm={TeaserEventImage}
                    />
                  }
                  link={
                    <QuickLink
                      label="Jetzt kostenlos anmelden"
                      href="https://www.weiling.de/ueber-uns/weiling-messe"
                      target="_blank"
                      linkType="primary--outlined"
                    />
                  }
                />
              </Col>
            </Row>
          </ContentBlock>
        </Col>
      </Row>
    </Layout>
  );
};

export default DashboardWidgetBlock;
