interface Props {
  top?: number;
  left?: number;
  elementToScroll?: typeof globalThis | HTMLElement;
}

/**
 * wrapper scroll to helper to respect users reduce motion behavior
 * @param top {number}
 * @param left {number}
 * @param elementToScroll {typeof globalThis | HTMLElement | VirtualizedTableRef}
 */
const elementScrollToPosition = ({
  top = 0,
  left = 0,
  elementToScroll = window,
}: Props) => {
  const prefersReducedMotion = window.matchMedia(
    "(prefers-reduced-motion: reduce)"
  );
  const behavior = prefersReducedMotion.matches ? "auto" : "smooth";

  elementToScroll?.scrollTo({
    top,
    left,
    behavior,
  });
};

export default elementScrollToPosition;
