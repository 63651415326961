/**
 * create a set of data for weekplanner redux
 * @param currentCartData {Record<string, any>}
 * @return {Object<CartData>}
 */
const createFilterString = (requestFilters?: Record<string, any>) => {
  let filterString = "";
  if (requestFilters) {
    Object.entries(requestFilters).forEach(
      ([filterName, filterValue]: [string, any]) => {
        if (!filterName || !filterValue) {
          return;
        }
        // bool filter true, omit falsy
        if (typeof filterValue === "boolean" && filterValue) {
          filterString += `&${filterName}=1`;
        } else if (Array.isArray(filterValue)) {
          // if not a bool filter, create iterable query string
          filterValue.forEach((filterArrayValue: string) => {
            filterString += `&${filterName}[]=${encodeURIComponent(
              filterArrayValue
            )}`;
          });
        }
      }
    );
  }
  return filterString;
};

export default createFilterString;
