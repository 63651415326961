import useMedia from "./useMedia";
import getCssVariable from "../utils/getCssVariable";

const useGetProductDetailCalcNumberEntriesLastRow = (attributesLength: any) => {
  const isLargeScreen = useMedia(`(min-width: ${getCssVariable("screen-lg")})`);
  const isMediumScreen = useMedia(
    `(min-width: ${getCssVariable("screen-md")})`
  );
  const isSmallScreen = useMedia(`(min-width: ${getCssVariable("screen-sm")})`);

  let attributeLengthLastRow;

  if (isLargeScreen && attributesLength - 4 >= 0) {
    attributeLengthLastRow = (attributesLength - 4) % 4;
  } else if (isMediumScreen && attributesLength - 3 >= 0) {
    attributeLengthLastRow = (attributesLength - 3) % 3;
  } else if (isSmallScreen && attributesLength - 2 >= 0) {
    attributeLengthLastRow = (attributesLength - 2) % 2;
  } else {
    attributeLengthLastRow = (attributesLength - 1) % 1;
  }

  if (attributeLengthLastRow === 0) {
    if (isLargeScreen) {
      attributeLengthLastRow = 4;
    } else if (isMediumScreen) {
      attributeLengthLastRow = 3;
    } else if (isSmallScreen) {
      attributeLengthLastRow = 2;
    } else {
      attributeLengthLastRow = 1;
    }
  }

  return attributeLengthLastRow;
};

export default useGetProductDetailCalcNumberEntriesLastRow;
