import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CancelTokenSource } from "axios";

import deleteCategoryFromCategoryData from "../utils/deleteCategoryFromCategoryData";
import getCategories from "../api/getCategories";
import getCancelTokenSource from "../api/getCancelTokenSource";
import getAllWebshopCategories from "../api/contentful/getAllWebshopCategories";
import requestCatchHandler from "../api/requestCatchHandler";
import { setParentIdToNavigationChildNode } from "../components/products/setAndGetNavigationNodeIds";
import { routeProductCategoryKeys } from "../appConfig";
import { RootState } from "../types/rootState";

export interface CategoryProps {
  name: string;
  children: any[];
}

/**
 * get product categories
 * @async
 */
const useGetCategories = (
  {
    setActiveCategoryData,
    setIsLoading,
  }: {
    setActiveCategoryData?: (category: CategoryProps) => void;
    setIsLoading?: (loading: boolean) => void;
  },
  stockName?: string
) => {
  const dispatch = useDispatch();

  const { abo: isAboCustomer = false } = useSelector(
    (state: RootState) => state.userData?.businessUnit || {}
  );

  const getProductCategories = useCallback(
    async (cancelTokenSource: CancelTokenSource) => {
      Promise.all([
        getCategories(cancelTokenSource, stockName),
        getAllWebshopCategories(),
      ])
        .then(([categoriesResponse, webshopCategories]) => {
          if (categoriesResponse) {
            const { data, status } = categoriesResponse;

            // Setting Product Data to Redux
            if (status === 200) {
              const mutatedCategoryData = { ...data.attributes };

              if (!isAboCustomer && !!routeProductCategoryKeys.abo) {
                deleteCategoryFromCategoryData(
                  mutatedCategoryData,
                  routeProductCategoryKeys.abo
                );
              }

              const categoryChildrenWithParentId =
                setParentIdToNavigationChildNode(mutatedCategoryData);

              mutatedCategoryData.children = categoryChildrenWithParentId.map(
                (categoryChildren: any) => {
                  const webshopCategoryEntry =
                    webshopCategories?.find(
                      (webshopCategory) =>
                        webshopCategory.category.id ===
                        categoryChildren.categoryKey
                    ) || {};
                  return {
                    ...categoryChildren,
                    webshopCategoryEntry,
                  };
                }
              );

              mutatedCategoryData.name = "Sortiment";

              dispatch({
                type: "categories/set-categories",
                payload: mutatedCategoryData,
              });

              if (setActiveCategoryData) {
                setActiveCategoryData(mutatedCategoryData);
              }

              if (setIsLoading) {
                setIsLoading(false);
              }
            }
          }
        })
        .catch((responseError) => {
          requestCatchHandler(responseError);
        });
    },
    [dispatch, setActiveCategoryData, setIsLoading, stockName, isAboCustomer]
  );

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();
    getProductCategories(cancelTokenSource);
    return () => {
      cancelTokenSource.cancel();
    };
  }, [getProductCategories]);
};

export default useGetCategories;
