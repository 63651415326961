import { AxiosResponse } from "axios";

interface GetCartErrorMessage {
  response: AxiosResponse;
  sku: string;
}

/**
 * try to find a matching error object in the response of cart calls
 * @param response {AxiosResponse}
 * @param sku {string}
 */
const getCartErrorMessage = ({ response, sku }: GetCartErrorMessage) => {
  const cartErrors = response?.data?.data?.attributes?.cartErrors;

  if (!cartErrors?.length) {
    return null;
  }

  return cartErrors.find(
    (errorMessage: Record<string, any>) => errorMessage.sku === sku
  );
};

export default getCartErrorMessage;
