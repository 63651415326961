import React, { forwardRef } from "react";
import { ReactComponent as Search } from "../../static/svg/search.svg";

interface Props {
  isDisabled?: boolean;
  onClick?: () => void;
}

type Ref = React.LegacyRef<HTMLButtonElement>;

/**
 * search button
 * @param isDisabled {boolean}
 * @param onClick {Function}
 * @param ref {React.LegacyRef}
 * @constructor
 */
function ButtonSearch({ isDisabled, onClick = () => true }: Props, ref: Ref) {
  return (
    <button
      type="button"
      title="Produkte Suchen"
      className="buttonActionForInput buttonInput"
      disabled={isDisabled}
      onClick={onClick}
      ref={ref}
    >
      <Search className="iconButtonInput iconSearch" />
    </button>
  );
}

export default forwardRef(ButtonSearch);
