import React from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { Entry } from "contentful";

import useGetCategoryIds from "../../../hooks/useGetCategoryIds";
import useGetWebshopCategoryVideos from "../../../hooks/useGetWebshopCategoryVideos";
import { WebshopCategoryVideo } from "../../atoms";
import { ContentfulWebshopCategoryVideoEntry } from "../../../types/contentfulWebshopCategoryVideoEntry";
import { RootState } from "../../../types/rootState";

interface WebshopCategoryVideosProps {
  categoryKey: string;
  limit?: number;
  className?: string;
}

const WebshopCategoryVideos: React.FC<WebshopCategoryVideosProps> = (
  props: WebshopCategoryVideosProps
) => {
  const { categoryKey, limit = 1, className } = props;

  const { stockName } = useSelector((state: RootState) => state?.userData);

  const { categoryIds } = useGetCategoryIds({ categoryKey });

  const { entries: webshopCategoryVideos, isLoading } =
    useGetWebshopCategoryVideos({
      categoryIds,
      stockName,
      limit,
    });

  if (isLoading || !webshopCategoryVideos?.length) {
    return null;
  }

  return (
    <div className={clsx("webshop-category-videos", className)}>
      {webshopCategoryVideos.map(
        (webshopCategoryVideo: Entry<ContentfulWebshopCategoryVideoEntry>) => (
          <WebshopCategoryVideo
            webshopCategoryVideo={webshopCategoryVideo}
            key={webshopCategoryVideo.sys.id}
          />
        )
      )}
    </div>
  );
};

export default React.memo(WebshopCategoryVideos);
