import React from "react";
import clsx from "clsx";

import CCM from "../../../utils/ccm";

interface ShowCookiesTriggerProps {
  underline?: boolean;
}

const ShowCookiesTrigger: React.FC<ShowCookiesTriggerProps> = (props) => {
  const { underline = true } = props;

  return (
    <button
      type="button"
      onClick={() => CCM.openWidget()}
      className={clsx(
        "button",
        "buttonPlain",
        "show-cookie-trigger",
        `show-cookie-trigger--${underline ? "underline" : "no-underline"}`
      )}
    >
      Cookies bearbeiten
    </button>
  );
};

export default ShowCookiesTrigger;
