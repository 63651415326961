import axios from "axios";
import { passwordResetConfirmEndpoint, XHRheader } from "../endpoints";
import handleError from "../../utils/handleError";
import { UserPassword } from "../../types/userPassword";

/**
 * update user password for existing user
 * @param token {string}
 * @param password {string}
 * @param confirmPassword {string}
 */
const patchSetNewUserPassword = async ({
  token,
  password,
  confirmPassword,
}: UserPassword) =>
  axios
    .patch(
      `${passwordResetConfirmEndpoint}${token}`,
      {
        data: {
          type: "customer-restore-password",
          attributes: {
            restorePasswordKey: token,
            password,
            confirmPassword,
          },
        },
      },
      {
        headers: {
          "Accept-Language": XHRheader.AcceptLanguage,
        },
      }
    )
    .then((res) => {
      return res.status;
    })
    .catch(handleError);

export default patchSetNewUserPassword;
