import React, { ReactNode, useMemo } from "react";
import { Tag } from "antd";
import clsx from "clsx";

interface FilterChipProps {
  text: string;
  label?: string;
  className?: string;
  closable?: boolean;
  onClose?: () => void;
  closeIcon?: ReactNode;
  variant?: "normal" | "reset";
}

const FilterChip: React.FC<FilterChipProps> = (props) => {
  const {
    text,
    label,
    variant = "normal",
    className,
    closable = true,
    onClose,
    closeIcon,
  } = props;

  const isReset = useMemo<boolean>(() => variant === "reset", [variant]);

  return (
    <Tag
      onClose={onClose}
      closable={!isReset ? closable : false}
      className={clsx("filter-chip", `filter-chip--${variant}`, className)}
      closeIcon={!isReset ? closeIcon : null}
      onClick={isReset ? onClose : null}
    >
      <div className={clsx("filter-chip__content")}>
        {label && (
          <span className={clsx("filter-chip__label")} title={label}>
            {label}
          </span>
        )}
        <span className={clsx("filter-chip__text")} title={text}>
          {text}
        </span>
      </div>
    </Tag>
  );
};

export default FilterChip;
