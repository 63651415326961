import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import useDebounceUpdateItemQuantity from "../../../hooks/useDebounceUpdateItemQuantity";
import { ProductCount } from "../../product";
import { messageData } from "../../../appConfig";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getCartErrorMessage from "../../../utils/getCartErrorMessage";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import getAlreadyOrderedStorage from "../../../state/actions/getAlreadyOrderedStorage";
import { RootState } from "../../../types/rootState";

interface CartItemQuantityTypes {
  quantity: number;
  sku: string;
}

/**
 * Wrapper to encapsulate hooks and selectors
 * due to the fact, that the CartItemTableChildRow is just an Object and no JSX.Element
 * @param quantity {number}
 * @param sku {string}
 * @constructor
 */
const CartItemQuantity: React.FC<CartItemQuantityTypes> = ({
  quantity,
  sku,
}: CartItemQuantityTypes) => {
  const { id: cartId, deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );
  const [selectedNumber, setSelectedNumber] = useState(quantity);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  // check if the product is part of an already ordered cart
  const pastOrderedProduct = useSelector((state: RootState) => {
    return getAlreadyOrderedStorage(state, sku, deliveryDate);
  });
  const setItemQuantity = useDebounceUpdateItemQuantity({
    deliveryDate,
    cartId,
    initialQuantity: quantity,
    sku,
    setIsLoading: () => {
      // start animation, block input
      setIsLoading(true);
    },
    resolve: (response) => {
      setIsLoading(false);

      const cartErrorMessage = getCartErrorMessage({
        response,
        sku,
      });

      if (cartErrorMessage) {
        setAlertMessage(cartErrorMessage.reason);
        setSelectedNumber(cartErrorMessage.quantityAdjusted);
      }
    },
    reject: (error) => {
      if (!axios.isCancel(error)) {
        setIsLoading(false);

        setAlertMessage(
          error?.response?.data?.errors[0]?.detail ||
            messageData.error.unexpected.content
        );
      }
    },
    cancelTokenSource: cancelTokenSource.current,
  });

  /**
   * update component and fire api call which will update redux
   * @param value {number}
   */
  const handleChange = async (value: number) => {
    setSelectedNumber(value);
    setItemQuantity(value);
  };

  useEffect(() => {
    setSelectedNumber(quantity);
  }, [quantity]);

  return (
    <div className="productCountContainer">
      <ProductCount
        value={selectedNumber}
        isLoading={isLoading}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        handleChange={handleChange}
        pastOrderedProduct={pastOrderedProduct}
      />
    </div>
  );
};

export default CartItemQuantity;
