import { useCallback, useEffect, useState } from "react";

import axios, { CancelTokenSource } from "axios";

import getStocktakings from "../api/stocktaking/getStocktakings";
import getCancelTokenSource from "../api/getCancelTokenSource";
import requestCatchHandler from "../api/requestCatchHandler";
import {
  GetStocktakingsAttributesResponse,
  StocktakingData,
} from "../types/stocktaking";

interface UseGetStocktakingsProps {
  limit?: number;
  page?: number;
}

const useGetStocktakings = (props: UseGetStocktakingsProps) => {
  const { limit = 10, page = 1 } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [stocktakings, setStocktakings] = useState<StocktakingData[]>([]);
  const [total, setTotal] = useState<number>(0);

  const fetchStocktakings = useCallback(
    async (cancelTokenSource: CancelTokenSource) => {
      setIsLoading(true);

      const defaultParams = {
        limit,
        page,
        cancelTokenSource,
      };

      getStocktakings({ ...defaultParams })
        .then(
          (
            stocktakingsAttributesResponse: GetStocktakingsAttributesResponse
          ) => {
            setStocktakings(stocktakingsAttributesResponse?.stocktakings || []);
            setTotal(stocktakingsAttributesResponse?.totalResults || 0);
            setIsLoading(false);
          }
        )
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setHasError(true);
            setIsLoading(false);
          }
          requestCatchHandler(error);
        });
    },
    [limit, page]
  );

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();
    fetchStocktakings(cancelTokenSource).catch();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [fetchStocktakings]);

  return {
    isLoading,
    hasError,
    total,
    stocktakings,
    setTotal,
    setStocktakings,
  };
};

export default useGetStocktakings;
