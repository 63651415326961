import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Col, Layout, Row } from "antd";

import PublicPageLayoutWrapper from "../pages/PublicPageLayoutWrapper";
import requestCatchHandler from "../../api/requestCatchHandler";
import getApiLevelHeader from "../../utils/getApiLevelHeader";
import getUrlReferrerString from "../../utils/getUrlReferrerString";
import { apiLevel, statusEndpoint } from "../../api/endpoints";
import { pageTitles, pageTitleSuffix } from "../../appConfig";
import MaintenanceVideo from "../../static/video/maintenance_1920.mp4";
import TrackingHelmet from "../Matomo/TrackingHelmet";

/**
 * maintenance page for when ...
 * ... backend and frontend are out of sync (versioning)
 * ... some deployments are running
 * @constructor
 */
const Maintenance = function Maintenance() {
  const { search } = useLocation();
  const navigate = useNavigate();

  /**
   * check every 5 seconds if the apiLevel matches the FE level
   * and redirect to the referrer url
   */
  useEffect(() => {
    const urlReferrer = getUrlReferrerString(search);

    const interval = setInterval(() => {
      if (!urlReferrer) {
        return;
      }

      axios
        .get(statusEndpoint)
        .then(({ headers }) => {
          const serverApiLevel = getApiLevelHeader(headers);

          if (serverApiLevel === apiLevel || apiLevel === "null") {
            navigate(urlReferrer);
            return;
          }

          if (serverApiLevel !== apiLevel) {
            window.location.reload();
          }
        })
        .catch(requestCatchHandler);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [search, navigate]);

  return (
    <>
      <TrackingHelmet title={pageTitles.maintenance} suffix={pageTitleSuffix} />

      <PublicPageLayoutWrapper>
        <Layout className="container-layout container-layout--inner">
          <div className="maintenanceWrapper">
            <video
              autoPlay
              loop
              controls={false}
              muted
              preload="auto"
              className="maintenanceBackground"
            >
              <source src={MaintenanceVideo} type="video/mp4" />
            </video>

            <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
              <Col span={12}>
                <div className="maintenanceContent">
                  <h1 className="maintenanceTitle">
                    <span className="maintenanceTitleTop text-3xl">
                      Fleißig
                    </span>
                    <span>wie die Bienen</span>
                  </h1>

                  <h2 className="maintenanceSubtitle like-h3">
                    Das Weiling-Portal wird gerade aktualisiert.
                  </h2>

                  <p className="maintenanceText">
                    Wir sind gerade ganz besonders fleißig, damit Sie bald
                    wieder bestellen können.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Layout>
      </PublicPageLayoutWrapper>
    </>
  );
};

export default Maintenance;
