import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import { Progress, Skeleton } from "antd";
import moment, { Moment } from "moment/moment";

import useGetCart from "../../../hooks/useGetCart";
import aggregateProductData from "../../../utils/aggregateProductData";
import { parsePriceValueToLocalPrice } from "../../../utils/localizedNumberFormat";
import { apiDateFormat } from "../../../utils/dateFormats";
import { RootState } from "../../../types/rootState";
import {
  AttributeName,
  productAttributes,
} from "../../../api/productAttributes";

interface DeadlineItem {
  group: string;
  deadline: number;
}

interface DeficitItem {
  deficit: number;
  threshold: number;
  fee: number;
  sumPriceTotal: number;
  deliveryDate: string;
  label: string;
  isWildcard: boolean;
}

interface OpenCartLineDetailsProps {
  deliveryDate: moment.Moment;
}

const OpenCartLineDetails: React.FC<OpenCartLineDetailsProps> = (props) => {
  const { deliveryDate } = props;

  const { cart, isLoading } = useGetCart(deliveryDate.format(apiDateFormat));

  const { stockName } = useSelector((state: RootState) => state.userData);

  /**
   * Get (enriched) cart items from raw cart data API response
   * @return {Any[]}
   */
  const cartItems = useMemo(
    () => aggregateProductData(cart?.included || []),
    [cart]
  );

  /**
   * Get deadlines for all cart items
   * @return {DeadlineItem[]}
   */
  const deadlineItems: DeadlineItem[] = useMemo(() => {
    const dict: any = {};
    cartItems.forEach((product: any) => {
      const groupName = `productGroup_${stockName}` as AttributeName;
      const group = product.attributes[productAttributes[groupName]];
      dict[group] = product.deadline;
    });
    return Object.keys(dict).map(
      (group): DeadlineItem => ({ group, deadline: dict[group] })
    );
  }, [cartItems, stockName]);

  /**
   * Get the most urgent deadline (earliest deadline)
   * @return {number} Deadline as unix timestamp
   */
  const minDeadlineValue: number = useMemo(
    () => Math.min(...deadlineItems.map((item: DeadlineItem) => item.deadline)),
    [deadlineItems]
  );

  /**
   * Get the most urgent deadline group name
   * @return {string} Deadline group name
   */
  const minDeadlineGroupName: string = useMemo(() => {
    const minDeadlineItem = deadlineItems.find(
      (deadlineItem: DeadlineItem) => deadlineItem.deadline === minDeadlineValue
    );
    let groupName = minDeadlineItem?.group || "";
    if (groupName.length > 1) {
      groupName = groupName.toLowerCase();
      groupName = `${groupName.charAt(0).toUpperCase()}${groupName.slice(1)}`;
    }
    return groupName;
  }, [deadlineItems, minDeadlineValue]);

  /**
   * Get the least urgent deadline (latest deadline)
   * @return {number} Deadline as unix timestamp
   */
  const maxDeadlineValue: number = useMemo(
    () => Math.max(...deadlineItems.map((item: DeadlineItem) => item.deadline)),
    [deadlineItems]
  );

  /**
   * @return {string} Deadline as human-readable string
   */
  const deadlineString: string = useMemo(() => {
    const deadline: Moment = moment.unix(minDeadlineValue);

    if (deadline.isSame(moment(), "day")) {
      return `Heute`;
    }

    if (deadline.isSame(moment().add(1, "d"), "day")) {
      return `Morgen`;
    }

    return `${deadline.format("DD. MMMM")}`;
  }, [minDeadlineValue]);

  /**
   * Get (main) deficit for cart (=> "Min. Lieferwert" of entire order)
   * @return {DeficitItem}
   */
  const totalDeficit: DeficitItem = useMemo(
    () =>
      (cart?.data?.attributes?.mlwGroupDeficits || []).find(
        (deficit: DeficitItem) => deficit.isWildcard && deficit.fee === 0
      ),
    [cart]
  );

  /**
   * Get total cart value
   * @return {number}
   */
  const getTotalCartValue: number = useMemo(
    () =>
      cartItems
        .map((product: any) => product?.calculations?.sumPrice || 0)
        .reduce((total: number, value: number) => total + value, 0),
    [cartItems]
  );

  /**
   * Get deficit in percent
   * @param {DeficitItem} deficit
   * @return {number}
   */
  const getDeficitPercentage = (deficit: DeficitItem): number => {
    if (!deficit?.threshold) {
      return 100;
    }
    return Math.round((deficit.sumPriceTotal / deficit.threshold) * 100);
  };

  /**
   * Get formatted price (human-readable)
   * @param {number} price
   * @param {boolean} showCurrencySymbol
   * @return {string}
   */
  const getLocalPrice = (price: number, showCurrencySymbol = true): string =>
    parsePriceValueToLocalPrice(price, "EUR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: showCurrencySymbol ? "currency" : "decimal",
    });

  /**
   * @param {DeficitItem} deficit
   * @return {string}
   */
  const getProgressText = (deficit: DeficitItem): string =>
    `${getLocalPrice(deficit?.sumPriceTotal, false)}/${getLocalPrice(
      deficit?.threshold
    )}`;

  if (isLoading) {
    return <Skeleton active paragraph={{ rows: 0 }} />;
  }

  if (!isLoading && (!stockName || !cartItems || !cart)) {
    return null;
  }

  return (
    <>
      <div className="open-cart-line-details__deadline">
        {!!minDeadlineValue && Number.isFinite(minDeadlineValue) && (
          <div className="ant-statistic-content">
            {moment.unix(minDeadlineValue).isAfter(moment(), "minute") ? (
              <>
                {/* Earliest deadline NOT expired */}
                Bestellen bis {deadlineString}
              </>
            ) : (
              <>
                {/* Earliest deadline expired */}

                {/* Multiple deadlines available => earliest and latest deadlines expired (all) */}
                {deadlineItems.length > 1 &&
                  moment.unix(minDeadlineValue).isBefore(moment(), "day") &&
                  moment.unix(maxDeadlineValue).isBefore(moment(), "day") && (
                    <>Bestellfristen abgelaufen</>
                  )}

                {/* Multiple deadlines available => 1+ deadlines expired. Latest deadline not expired */}
                {deadlineItems.length > 1 &&
                  moment.unix(minDeadlineValue).isBefore(moment(), "day") &&
                  moment.unix(maxDeadlineValue).isAfter(moment(), "day") && (
                    <>Bestellfrist {minDeadlineGroupName} abgelaufen</>
                  )}

                {/* One deadline available => deadline expired */}
                {deadlineItems.length === 1 &&
                  moment.unix(minDeadlineValue).isBefore(moment(), "day") && (
                    <>Bestellfrist abgelaufen</>
                  )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="open-cart-line-details__deficit">
        <Progress
          percent={getDeficitPercentage(totalDeficit)}
          format={(percent) => {
            if (percent === 100) {
              return getLocalPrice(getTotalCartValue);
            }
            return getProgressText(totalDeficit);
          }}
        />
      </div>
    </>
  );
};

export default OpenCartLineDetails;
