import React, { BaseSyntheticEvent, useMemo, useState } from "react";

import clsx from "clsx";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Modal,
  Row,
  Space,
  Tooltip,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Asset, Entry } from "contentful";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import isAbsoluteUrl from "../../../../utils/isAbsoluteUrl";
import downloadContenfulFile from "../../../../utils/downloadContenfulFile";
import ImpulseTeaser from "../ImpulseTeaser";
import {
  ContentfulImpulsaktionEntry,
  ContentfulImpulsaktionMainTeaserEntry,
} from "../../../../types/contentfulImpulsaktion";
import { ReactComponent as OrderIcon } from "../../../../static/svg/my-order.svg";
import { ReactComponent as VKFIcon } from "../../../../static/svg/vkf.svg";
import { ReactComponent as DownloadAlt2Icon } from "../../../../static/svg/download-alternative-2.svg";
import { getTargetFromAsset, getTooltipTextFromAsset } from "../utils";

interface ImpulseMainTeaserProps {
  impulsaktion: Entry<ContentfulImpulsaktionEntry>;
  hiddenTeaser?: Entry<ContentfulImpulsaktionMainTeaserEntry>;
  className?: string;
  variant?: "small";
  colProps?: object;
}

const ImpulseMainTeaser: React.FC<ImpulseMainTeaserProps> = (
  props: ImpulseMainTeaserProps
) => {
  const {
    impulsaktion,
    hiddenTeaser,
    className,
    variant = "",
    colProps = { xs: 12, lg: 6 },
  } = props;

  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [activeTeaser, setActiveTeaser] =
    useState<Entry<ContentfulImpulsaktionMainTeaserEntry>>(null);

  const activeMonth = useMemo(
    () => moment(impulsaktion?.fields?.start_date).format("MMMM"),
    [impulsaktion]
  );

  const mainTeasers = useMemo(
    () =>
      impulsaktion?.fields?.main_teaser?.filter(
        (teaser: Entry<ContentfulImpulsaktionMainTeaserEntry>) =>
          teaser?.sys?.id !== hiddenTeaser?.sys?.id
      ) || [],
    [impulsaktion, hiddenTeaser]
  );

  const otherTeasers = useMemo(
    () => impulsaktion?.fields?.other_teaser || [],
    [impulsaktion]
  );

  const opportunitiesTeasers = useMemo(
    () => impulsaktion?.fields?.opportunities_teaser || [],
    [impulsaktion]
  );

  if (mainTeasers?.length < 1) {
    return null;
  }

  return (
    <div
      className={clsx(
        "impulse-main-teaser",
        variant && `impulse-main-teaser--${variant}`,
        className
      )}
    >
      <Row gutter={[40, 30]}>
        {mainTeasers?.map(
          (teaser: Entry<ContentfulImpulsaktionMainTeaserEntry>, i: number) => {
            const isExternalLink = isAbsoluteUrl(teaser?.fields?.link);

            return (
              <Col
                key={`impulse-main-teaser_${teaser?.sys?.id}_${i}`}
                {...colProps}
              >
                <Card
                  title={teaser?.fields?.headline}
                  cover={
                    <img
                      src={teaser?.fields?.image?.fields?.file?.url}
                      alt={teaser?.fields?.headline}
                    />
                  }
                >
                  {variant !== "small" && !!teaser?.fields?.description && (
                    <p className="impulse-main-teaser__description">
                      {teaser?.fields?.description}
                    </p>
                  )}
                  {variant === "small" && <Divider />}
                  <Space>
                    {!!teaser?.fields?.link && !isExternalLink && (
                      <Button
                        className="button buttonSecondary width-full"
                        icon={<OrderIcon />}
                        onClick={() => navigate(teaser?.fields?.link)}
                      >
                        {teaser?.fields?.link_text || ""}
                      </Button>
                    )}

                    {!!teaser?.fields?.link && isExternalLink && (
                      <Button
                        className="button buttonSecondary width-full"
                        icon={<LinkOutlined />}
                        href={teaser?.fields?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {teaser?.fields?.link_text || ""}
                      </Button>
                    )}

                    <Button
                      className="button buttonPrimary width-full"
                      icon={<VKFIcon />}
                      onClick={() => {
                        setActiveTeaser(teaser);
                        setIsModalVisible(true);
                      }}
                    >
                      Übersicht der VKF-Materialien
                    </Button>
                  </Space>
                </Card>
              </Col>
            );
          }
        )}
      </Row>
      <Modal
        onCancel={() => {
          setIsModalVisible(false);
        }}
        width={1200}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        visible={isModalVisible}
        title={activeTeaser?.fields?.headline}
        wrapClassName="impulse-main-teaser__modal"
        destroyOnClose
      >
        <h4>{activeTeaser?.fields?.subject}</h4>
        <p className="impulse-main-teaser__modal__description">
          {activeTeaser?.fields?.description}
        </p>
        {!!activeTeaser?.fields?.images?.length && (
          <div className="impulse-main-teaser__modal__images">
            {activeTeaser?.fields?.images?.map((image: Asset, i: number) => (
              <Image
                src={image?.fields?.file?.url}
                alt={`${activeTeaser?.fields?.headline} Bild ${i}`}
                key={`impulse-main-teaser_images_${image?.sys?.id}_${i}`}
              />
            ))}
          </div>
        )}
        <Space className="mt-xl mb-xl">
          {!!activeTeaser?.fields?.link &&
            !isAbsoluteUrl(activeTeaser?.fields?.link) && (
              <Button
                className="button buttonSecondary width-full"
                icon={<OrderIcon />}
                onClick={() => navigate(activeTeaser?.fields?.link)}
              >
                {activeTeaser?.fields?.link_text || ""}
              </Button>
            )}

          {!!activeTeaser?.fields?.link &&
            isAbsoluteUrl(activeTeaser?.fields?.link) && (
              <Button
                className="button buttonSecondary width-full"
                icon={<LinkOutlined />}
                href={activeTeaser?.fields?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {activeTeaser?.fields?.link_text || ""}
              </Button>
            )}

          {!!activeTeaser?.fields?.download_file?.fields?.file?.url && (
            <Tooltip
              title={getTooltipTextFromAsset(
                activeTeaser?.fields?.download_file
              )}
            >
              <Button
                className="button buttonPrimary width-full"
                icon={<DownloadAlt2Icon />}
                onClick={(e: BaseSyntheticEvent) => {
                  downloadContenfulFile(
                    e,
                    activeTeaser.fields?.download_file,
                    getTargetFromAsset(activeTeaser.fields?.download_file)
                  );
                }}
              >
                {activeTeaser?.fields?.download_cta || ""}
              </Button>
            </Tooltip>
          )}
        </Space>
        <Divider />
        <ImpulseMainTeaser
          impulsaktion={impulsaktion}
          hiddenTeaser={activeTeaser}
          variant="small"
          colProps={{ xs: 12, lg: 4 }}
        />
        <ImpulseTeaser
          title={`Weitere Themen im ${activeMonth}`}
          teasers={otherTeasers}
          className="mb-l mt-2xl"
          variant="small"
          colProps={{ xs: 12, md: 6, xl: 4 }}
        />
        <ImpulseTeaser
          title="Ganzjährige Verkaufschancen"
          teasers={opportunitiesTeasers}
          className="mb-l mt-2xl"
          variant="small"
          colProps={{ xs: 12, md: 6, xl: 4 }}
        />
      </Modal>
    </div>
  );
};

export default ImpulseMainTeaser;
